import gql from 'graphql-tag';

export const COMMUNITY_NAME = gql`
  query CommunityName($id: ID!) {
    community(id: $id) {
      id
      name
    }
  }
`;
