import gql from 'graphql-tag';

export const OFFER_DETAILS_QUERY = gql`
  query FetchOfferAndSingleUnitDetails($id: ID!) {
    offer(id: $id) {
      id
      numberId
      startsIn
      endsIn
      rentAmount
      timezone
      contact {
        id
        lastName
        firstName
        email
        phone {
          code
          number
        }
      }
      bid(sort: { rentAmount: DESC }, first: 1) {
        count
        items {
          id
          rentAmount
          createdAt
        }
      }
      status
      securityDeposite
      termsOfLease
      moveinDate
      secureNowAmount
      historyOfferSingleUnit {
        id
        name
        type
        address {
          street1
          street2
          city
          state
          zip
        }
        bedrooms
        bathrooms
        squareFt
        community {
          id
          name
        }
        website
        phone {
          code
          number
        }
        singleUnitHasMedia(first: 3, sort: { order: ASC }) {
          items {
            id
            altName
            order
            media {
              id
              name
              file {
                id
                fileId
                filename
                downloadUrl
                meta
              }
            }
          }
        }
      }
    }
  }
`;
