import styled from '@emotion/styled';

// TODO: use colours from theme
export const Container = styled.div`
  display: flex;
  .ant-avatar {
    border: 1px solid #f37a22;
  }
  .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
  .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
    border-radius: 8px;
    overflow: hidden;
  }
  .ant-skeleton-content {
    vertical-align: middle;
  }
  .ant-skeleton-header {
    padding-right: 9px;
  }
  .ant-skeleton-header .ant-skeleton-avatar-lg {
    height: 32px;
    width: 32px;
  }
`;

export const UserName = styled.div`
  color: #f37a22;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  display: flex;
  align-items: center;

  &:hover {
    color: #ff9d4d;
  }
`;

export const Inner = styled.div`
  display: flex;
  align-items: center;
`;
export const AvatarContainer = styled.div`
  margin-right: 9px;
`;

export const MenuContainer = styled.div`
  min-width: 145px;
`;
