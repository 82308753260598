import gql from 'graphql-tag';

export const COMMUNITY_CARD = gql`
  query CommunityCard($id: ID!, $first: Int, $skip: Int, $filter: SingleUnitFilter, $sort: [SingleUnitSort!]) {
    community(id: $id) {
      id
      communityId
      name
      logo {
        id
        downloadUrl
        meta
      }
      communityHasMedia(sort: { order: ASC }) {
        items {
          id
          altName
          order
          media {
            id
            name
            file {
              id
              fileId
              filename
              downloadUrl
              meta
            }
          }
        }
      }
      singleUnit(first: $first, skip: $skip, filter: $filter, sort: $sort) {
        count
        items {
          id
          name
          bedrooms
          bathrooms
          squareFt
          status
          activeOffer {
            id
            status
          }
        }
      }
      address {
        country
        street1
        street2
        zip
        city
        state
      }
      phoneNumber {
        code
        number
      }
      website
    }
  }
`;
