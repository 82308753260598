import { useCurrentUser } from '@/shared/hooks/useCurrentUser';

export const useAllowedSingleUnits = () => {
  const { user } = useCurrentUser();

  const userSingleUnitsList = user?.singleUnitAgent?.items || [];

  const allowedSingleUnits = userSingleUnitsList.filter(Boolean);

  return allowedSingleUnits;
};
