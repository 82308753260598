import React from 'react';
import { Modal, Icon } from 'antd';
import styled from '@emotion/styled';
import { useQuery } from 'react-apollo';
import { toast } from 'react-toastify';

import { ModalFooter, Loader } from '@/components';
import { RedAlert } from '@/static/icons';
import { Offer } from '@/shared/types/graphql';

import { OFFER_AWARD_QUERY } from './queries';
import { useMutationSafe } from '@/shared/hooks';
import { EventTypes, MUTATION_EVENT } from '@/shared/graphql/eventResolver';

const Container = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  padding: 24px 76px 8px 76px;
  height: 150px;
`;

type DialogProps = {
  refetchQueries?: Array<string>;
  close: () => void;
  isVisible: boolean;
  offerId?: string;
  bidId?: string;
};

export const Dialog: React.FC<DialogProps> = ({ isVisible, close, offerId, bidId, refetchQueries }) => {
  const { data: offerData, loading: offerLoading } = useQuery<{ offer: Offer }>(OFFER_AWARD_QUERY, {
    variables: { id: offerId },
    skip: !offerId,
  });

  const currentOfferId = offerData?.offer.offerId;

  const [awardLease, { loading: offerUpdateLoading }] = useMutationSafe(MUTATION_EVENT, {
    refetchQueries: refetchQueries,
    onCompleted: () => {
      toast.success('Successfully awarded');
      close();
    },
  });

  const handleOfferAward = async () => {
    await awardLease({
      variables: {
        event: {
          type: EventTypes.AWARD_LEASE,
          payload: { bidId },
        },
      },
    });
  };

  const title = (
    <div>
      <Icon
        component={RedAlert}
        css={{ marginRight: '8px', fontSize: '20px', lineHeight: '22px', verticalAlign: 'middle' }}
      />
      Award Lease
    </div>
  );

  return (
    <Modal
      title={title}
      closable={!offerUpdateLoading}
      maskClosable={!offerUpdateLoading}
      className="livo-modal"
      visible={isVisible}
      onCancel={close}
      width="100%"
      style={{ maxWidth: 400 }}
      centered
      footer={
        <ModalFooter
          cancelProps={{
            onClick: close,
            disabled: offerUpdateLoading,
          }}
          okProps={{
            type: 'danger',
            text: 'Yes, Award',
            disabled: offerUpdateLoading,
            loading: offerUpdateLoading,
            onClick: handleOfferAward,
          }}
        />
      }
    >
      {offerLoading ? (
        <Loader height="150px" stretch />
      ) : (
        <Container>Are you sure you want to Award Transaction {currentOfferId}?</Container>
      )}
    </Modal>
  );
};
