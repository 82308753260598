import styled from '@/theme/styled';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 182px 1fr;
  grid-template-rows: 23px 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 39px;
  grid-template-areas: 'Img Title' 'Img Steps';
`;

// TODO: take colours values from theme
// TODO: take font sizes values from theme
export const StepsWrapper = styled.div`
  grid-area: Steps;
  .ant-steps-small .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
    font-size: 28px;
    line-height: 28px;
  }
  .ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
    top: 2px;
    padding: 24px 0 0 2px;
  }
  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon,
  .ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    color: #939ea7;
  }
  .ant-steps-vertical .ant-steps-item-content {
    padding-top: 5px;
  }
  .ant-steps-vertical .ant-steps-item-icon {
    margin-right: 11px;
  }
  .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after,
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after,
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #939ea7;
  }
`;

export const ImgPosition = styled.div`
  grid-area: Img;
`;
// TODO: take colours values from theme
export const Title = styled.div`
  grid-area: Title;
  display: flex;
  align-items: flex-end;
  color: #929da9;
  font-size: 13px;
  line-height: 20px;
  padding-left: 3px;
  letter-spacing: 0;
`;

export const StepTitleContainer = styled.div`
  display: flex;
`;
// TODO: take colours values from theme
export const TitleText = styled.div<{ isCurrent: boolean; isDone: boolean }>`
  opacity: ${({ isCurrent, isDone }) => (isCurrent || isDone ? '1' : '0.45')};
  color: #323c47;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin-right: 8px;
`;
// TODO: take colours values from theme
export const TextDone = styled.div`
  color: #3ac430;
  font-size: 13px;
  line-height: 20px;
`;

export const IconWrapper = styled.div`
  color: ${({ theme }) => theme.vars.primaryColor};
  line-height: 1;
  .anticon {
    font-size: 17px;
    & + span {
      margin-left: 7px;
      font-size: 12px;
      letter-spacing: 0.2px;
    }
  }
`;
