import React from 'react';
import styled from '@emotion/styled';
import { Button } from 'antd';

const Container = styled.div`
  padding: 24px;
  display: flex;
  justify-content: flex-end;
`;

const LeftActionLink = styled.button<{ disabled: boolean; color?: string }>`
  color: ${({ disabled, color }) => (disabled ? '#7D828C' : color ? color : '#3ac430')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-right: auto;
  background-color: transparent;
`;

type CancelProps = {
  text?: string;
  size?: 'small' | 'default' | 'large';
  htmlType?: 'button' | 'submit';
  disabled?: boolean;
  loading?: boolean;
  onClick?: (event: any) => void;
};

type LeftActionProps = {
  text?: string;
  disabled?: boolean;
  onClick?: (event: any) => void;
  color?: string;
  'data-e2e-id'?: string;
};

export type OkProps = {
  text: string;
  size?: 'small' | 'default' | 'large';
  type: 'link' | 'default' | 'ghost' | 'primary' | 'dashed' | 'danger';
  htmlType?: 'button' | 'submit';
  loading?: boolean;
  disabled?: boolean;
  onClick?: (event: any) => void;
};

type ModalFooterProps = {
  cancelProps?: CancelProps;
  okProps?: OkProps;
  leftActionProps?: LeftActionProps;
  showLeftAction?: boolean;
};

export const ModalFooter = ({ showLeftAction = true, cancelProps, okProps, leftActionProps }: ModalFooterProps) => {
  const cancelSize = cancelProps?.size || 'large';
  const cancelHtmlType = cancelProps?.htmlType || 'button';
  const cancelDisabled = cancelProps?.disabled;
  const cancelLoading = cancelProps?.loading;
  const cancelText = cancelProps?.text || 'Cancel';
  const onClickCancel = cancelProps?.onClick;

  const okType = okProps?.type;
  const okSize = okProps?.size || 'large';
  const okHtmlType = okProps?.htmlType || 'button';
  const okDisabled = okProps?.disabled;
  const okLoading = okProps?.loading;
  const okText = okProps?.text;
  const onClickOk = okProps?.onClick;

  const draftText = leftActionProps?.text ?? 'Save as Draft';

  return (
    <Container>
      {leftActionProps && showLeftAction && (
        <LeftActionLink
          onClick={leftActionProps?.onClick}
          disabled={!!leftActionProps?.disabled}
          color={leftActionProps?.color}
          data-e2e-id={
            leftActionProps['data-e2e-id']
              ? `common.modal.footer.${leftActionProps['data-e2e-id']}`
              : 'common.modal.footer.saveAsDraft'
          }
        >
          {draftText}
        </LeftActionLink>
      )}
      {cancelProps && (
        <Button
          type="default"
          size={cancelSize}
          htmlType={cancelHtmlType}
          disabled={cancelDisabled}
          loading={cancelLoading}
          onClick={onClickCancel}
          data-e2e-id="common.modal.footer.cancel"
        >
          {cancelText}
        </Button>
      )}
      {okProps && (
        <Button
          type={okType}
          size={okSize}
          htmlType={okHtmlType}
          disabled={okDisabled}
          loading={okLoading}
          onClick={onClickOk}
          data-e2e-id="common.modal.footer.ok"
        >
          {okText}
        </Button>
      )}
    </Container>
  );
};
