import React from 'react';
import { useQuery } from 'react-apollo';
import { validateEntityDataByDraft } from '@/shared/utils';
import { Modal } from 'antd';
import { Grid, InputField, ModalFooter, SelectField } from '@/components';
import { Field, Form } from '8base-react-sdk';
import { AmenityGroupListResponse } from '@/shared/types/graphql';
import { NEW_AMENITY_GROUPS_LABELS } from '@/shared/constants';
import { toast } from 'react-toastify';
import { AmenitySchemaValidation } from '@/schemaValidators';
import { useMutationSafe } from '@/shared/hooks';

import { AMENITY_GROUPS_QUERY, DEFAULT_AMENITY_CREATE_MUTATION } from './queries';

interface DialogProps {
  closeWithConfirm: () => void;
  close: () => void;
  isVisible: boolean;
  refetchQueries?: Array<string>;
  currentAmenityGroup?: string;
}

export const Dialog: React.FC<DialogProps> = ({ close, closeWithConfirm, isVisible, ...props }) => {
  const { data: amenityGroupsData, loading: amenityGroupsLoading } = useQuery<{
    amenityGroupsList: AmenityGroupListResponse;
  }>(AMENITY_GROUPS_QUERY, {
    variables: {
      filter: {
        masterAmenity: {
          id: {
            is_not_empty: true,
          },
        },
      },
    },
  });

  const refetchQueries = props.refetchQueries || [];
  const currentAmenityGroup = props?.currentAmenityGroup;

  const [defaultAmenityCreate] = useMutationSafe(DEFAULT_AMENITY_CREATE_MUTATION, {
    refetchQueries: [...refetchQueries, 'DefaultAmenitiesList'],
    onCompleted: () => {
      close();
      toast.success('Successfully Created');
    },
  });

  const groupsList = amenityGroupsData?.amenityGroupsList?.items || [];

  const options = React.useMemo(
    () => groupsList.map((el: any) => ({ value: el?.id, label: NEW_AMENITY_GROUPS_LABELS[el?.key] })),
    [groupsList],
  );
  const defaultValue = options.filter((el: any) => el?.label === NEW_AMENITY_GROUPS_LABELS[currentAmenityGroup || '']);

  const onSubmit = async (data: any) => {
    await defaultAmenityCreate({
      variables: {
        data,
      },
    });
  };

  return (
    <Form
      type="CREATE"
      tableSchemaName="DefaultAmenity"
      onSubmit={onSubmit}
      validate={values => validateEntityDataByDraft(AmenitySchemaValidation, values)}
    >
      {({ handleSubmit, pristine, submitting }) => (
        <Modal
          title="Create Amenity"
          visible={isVisible}
          onCancel={() => (pristine ? close() : closeWithConfirm())}
          width="100%"
          style={{ maxWidth: 500 }}
          footer={
            <ModalFooter
              cancelProps={{
                onClick: () => (pristine ? close() : closeWithConfirm()),
                disabled: submitting,
              }}
              okProps={{
                disabled: pristine,
                loading: submitting,
                onClick: event => handleSubmit(event),
                type: 'primary',
                text: 'Create',
              }}
            />
          }
          centered
        >
          <Grid.Layout gap="25px">
            <Grid.Box>
              <Field label="Label" name="label" component={InputField} stretch />
            </Grid.Box>
            <Grid.Box>
              <Field
                label="Group"
                initialValue={defaultValue[0]?.value}
                options={options}
                name="category"
                disabled={amenityGroupsLoading}
                loading={amenityGroupsLoading}
                component={SelectField}
                stretch
              />
            </Grid.Box>
          </Grid.Layout>
        </Modal>
      )}
    </Form>
  );
};
