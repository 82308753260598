import React from 'react';
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming';
import { Global } from '@emotion/core';

import { theme } from '@/theme';
import { globalStyles } from '@/theme/globalStyles';

export const ThemeProvider = ({ children }: { children: React.ReactNode[] }) => (
  <EmotionThemeProvider theme={theme}>
    <Global styles={globalStyles(theme)} />
    {children}
  </EmotionThemeProvider>
);
