import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import qs, { ParsedQuery } from 'query-string';
import throttle from 'lodash.throttle';
import { isEmpty } from 'ramda';

export type SetQueryParamsHandler = (queryParams: Record<string, any>, replace?: boolean) => void;

export const useQueryParams = <T = ParsedQuery>(delay?: number, initialValues?: T): [T, SetQueryParamsHandler] => {
  const location = useLocation();
  const history = useHistory();
  const params: any = qs.parse(location.search);

  React.useEffect(() => {
    if (isEmpty(params) && !isEmpty(initialValues) && !!initialValues) {
      history.replace({
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        //@ts-ignore
        search: qs.stringify(initialValues),
      });
    }
  }, [params, initialValues, history]);

  const setQueryParams = (value: Record<string, any>, replace?: boolean) => {
    history.replace({
      search: qs.stringify(
        replace
          ? value
          : {
              ...params,
              ...value,
            },
      ),
    });
  };

  const throttledSetQueryParams = React.useCallback(throttle(setQueryParams, delay || 1000), [delay]);

  return [params, throttledSetQueryParams];
};
