import React from 'react';
import styled from '@/theme/styled';
import { DateTime } from 'luxon';
import pluralize from 'pluralize';

import { Offer, Maybe } from '@/shared/types/graphql';
import { Grid, FlexLayout, PhoneLink } from '@/components';
import { InformationSection, EntityOption, OfferStatusTag } from '@/pro';
import { formatDateTimeToOfferTimezone } from 'livo-shared';
import { NOT_AVALIABLE, OFFER_STATUSES } from '@/shared/constants';
import { formatDiffDate, stringifyRentAmount, getFullName, stringifyNA, formatAmount } from '@/shared/utils';

const OfferSectionHeader = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.vars.black};
`;

type OfferDetailsProps = {
  offer?: Maybe<Offer>;
};

export const OfferDetails: React.FC<OfferDetailsProps> = ({ offer }) => {
  const timeZone = offer?.timezone as string;
  const status = offer?.status as string;
  const endsInRaw = DateTime.fromISO(offer?.endsIn);
  const startsInRaw = DateTime.fromISO(offer?.startsIn);
  const endsIn = `${endsInRaw.toFormat('D - tt')}`;
  const diff = formatDiffDate(endsInRaw);
  const endsInDiff = `${endsIn} ${status !== OFFER_STATUSES.live ? '' : `- ${diff}`}`;
  const startsIn = `${startsInRaw.toFormat('D - ttt')}`;
  const bidders = `${offer?.bid?.count}` || NOT_AVALIABLE;
  const termsOfLease = offer?.termsOfLease;
  const highestBid = offer?.bid?.items[0] ? `$${formatAmount(offer?.bid?.items[0].rentAmount)}` : NOT_AVALIABLE;
  const moveinDate = offer?.moveinDate
    ? formatDateTimeToOfferTimezone(offer.moveinDate, timeZone).toFormat('LLL dd, yyyy')
    : NOT_AVALIABLE;
  const securityDeposit = offer?.securityDeposite ? `$${formatAmount(offer?.securityDeposite)}` : NOT_AVALIABLE;
  const secureNowAmount = offer?.secureNowAmount ? `$${formatAmount(offer?.secureNowAmount)}` : NOT_AVALIABLE;

  const contact = offer?.contact || null;
  const contactName = getFullName(contact?.firstName, contact?.lastName, false);
  const contactEmail = contact?.email;
  const contactPhone = contact?.phone;

  return (
    <InformationSection
      header={
        <OfferSectionHeader>
          <FlexLayout align="center">
            <span>Livo Transaction Details</span>
            <OfferStatusTag status={status} />
          </FlexLayout>
        </OfferSectionHeader>
      }
    >
      <Grid.Layout gap="24px">
        <Grid.Layout gap="20px" columns="repeat(2, 1fr)">
          <Grid.Layout gap="20px">
            {status === OFFER_STATUSES.pending && (
              <EntityOption value={startsIn} label="Listing start date" type="uppercase-label" />
            )}
            <EntityOption value={endsInDiff} label="Listing end date" type="uppercase-label" />
            <EntityOption value={stringifyRentAmount(offer?.rentAmount)} label="Rent amount" type="uppercase-label" />
            <EntityOption value={highestBid} label="Current highest bid" type="uppercase-label" />
            <EntityOption value={securityDeposit} label="Security deposit" type="uppercase-label" />
          </Grid.Layout>
          <Grid.Layout>
            <EntityOption value={bidders} label="Prospects" type="uppercase-label" />
            <EntityOption
              value={termsOfLease ? `${termsOfLease} ${pluralize('month', termsOfLease)}` : NOT_AVALIABLE}
              label="Terms of lease"
              type="uppercase-label"
            />
            <EntityOption value={moveinDate} label="Move-in date" type="uppercase-label" />
            <EntityOption value={secureNowAmount} label="SecureNow™ rent amount" type="uppercase-label" />
          </Grid.Layout>
        </Grid.Layout>
        {contact && (
          <InformationSection
            header={
              <OfferSectionHeader>
                <span>Contact info</span>
              </OfferSectionHeader>
            }
          >
            <Grid.Layout gap="20px">
              <EntityOption label="Name" value={stringifyNA(contactName)} type="uppercase-label" />
              <EntityOption label="Email" value={stringifyNA(contactEmail)} type="uppercase-label" />
              <EntityOption label="Phone" value={<PhoneLink phone={contactPhone} />} type="uppercase-label" />
            </Grid.Layout>
          </InformationSection>
        )}
      </Grid.Layout>
    </InformationSection>
  );
};
