import {
  User,
  CommunityListResponse,
  SingleUnitListResponse,
  OfferListResponse,
  ReportListResponse,
} from '@/shared/types/graphql';
import { useCompanyTypeCheck, usePermissions } from '@/shared/hooks';
import { COMPANY_TYPES } from '@/shared/constants/company';

export type GettingStartedStepsData = {
  user: User;
  communitiesList: CommunityListResponse;
  singleUnitsList: SingleUnitListResponse;
  offersList: OfferListResponse;
  reportsList: ReportListResponse;
};

export type GettingStartedStepsInfo = {
  hasCompany: boolean;
  hasProperty: boolean;
  hasOffer: boolean;
  hasReport: boolean;
  stepNumber: number;
};

export const getGettingStartedCardData = (data?: GettingStartedStepsData): GettingStartedStepsInfo => {
  const response = {
    hasCompany: false,
    hasProperty: false,
    hasOffer: false,
    hasReport: false,
    stepNumber: 0,
  };

  if (!data) {
    return response;
  }

  // check - Complete companies Profile
  const companyId = data?.user?.company?.id;
  if (companyId) {
    response.hasCompany = true;
  }

  // check - Add a Property to your Portfolio
  const singleUnitsCount = data?.singleUnitsList?.count;
  if (singleUnitsCount) {
    response.hasProperty = true;
  }
  // check - Add a Property to your Portfolio
  const communitiesCount = data?.communitiesList?.count;
  if (communitiesCount) {
    response.hasProperty = true;
  }

  // check - Add a Livo Rental
  const offersCount = data.offersList.count;
  if (offersCount) {
    response.hasOffer = true;
  }

  // check - Create a Report
  const reportsCount = data?.reportsList.count;
  if (reportsCount) {
    response.hasReport = true;
  }

  // check - which is current step?
  if (!response.hasCompany) {
    response.stepNumber = 0;
  } else if (!response.hasProperty) {
    response.stepNumber = 1;
  } else if (!response.hasOffer) {
    response.stepNumber = 2;
  } else if (!response.hasReport) {
    response.stepNumber = 3;
  } else {
    response.stepNumber = 4;
  }

  return response;
};

export const useUserFilterPermissions = () => {
  const { isAdmin, isCompanyOwner, allowedCommunities, allowedSingleUnits } = usePermissions();
  const isMdu = useCompanyTypeCheck(COMPANY_TYPES.mdu);

  const allowedCommunitiesIds = allowedCommunities.map(({ id }) => id);
  const allowedSingleUnitsIds = allowedSingleUnits.map(({ id }) => id);

  return {
    allowedCommunitiesIds,
    allowedSingleUnitsIds,
    isAdmin,
    isCompanyOwner,
    isMdu,
  };
};
