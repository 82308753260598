import React from 'react';
import * as R from 'ramda';
import { useQuery } from 'react-apollo';
import { Avatar, Icon } from 'antd';
import { Button } from 'antd';

import { Maybe, Bid, SmartPhone, User, File } from '@/shared/types/graphql';
import { AsyncContent, Grid, FlexLayout, WebsiteLink, PhoneLink } from '@/components';
import {
  getFullName,
  stringifyNA,
  getInitials,
  getIconByMimetype,
  cropFileName,
  getDocumentTypeByMimetype,
} from '@/shared/utils';
import { EntityOption, InformationSection } from '@/pro';
import { stringifyHref } from '@/shared/utils';

import { AGENT_INFORMATION_BID_QUERY } from './queries';

type AgentInformationProps = {
  bidId?: Maybe<string>;
};

const packInfo = ({
  firstName,
  lastName,
  email,
  phone,
  avatarUrl,
}: {
  firstName: string | null | undefined;
  lastName?: string | null | undefined;
  email: string | null | undefined;
  phone: SmartPhone | null | undefined;
  avatarUrl?: string;
}) => {
  let first = firstName;
  let last = lastName;

  if (!lastName) {
    [first, last] = firstName?.split(' ') || [];
  }

  const name = getFullName(first, last, false);
  const initials = getInitials(first, last);

  return name && (email || phone)
    ? {
        name,
        email,
        phone,
        initials,
        avatarUrl,
      }
    : null;
};

const getUserInfo = (user: User | null | undefined) => {
  const email = user?.email;
  const phone = user?.phone;
  return packInfo({
    firstName: user?.firstName,
    lastName: user?.lastName,
    email,
    phone,
    avatarUrl: user?.avatar?.downloadUrl || undefined,
  });
};

const getAgentInfo = (bid?: Maybe<Bid>) => {
  const offer = bid?.offer;
  const singleUnit = offer?.historyOfferSingleUnit;
  const community = singleUnit?.community;
  const company = singleUnit?.company;

  const offerContactInfo = getUserInfo(offer?.contact);
  const singleUnitAgentInfo = getUserInfo(singleUnit?.agent);
  const communityContactInfo = packInfo({
    firstName: community?.managerName,
    email: community?.managerEmail,
    phone: community?.managerPhoneNumber,
  });

  const communityManagerInfo = getUserInfo(community?.communityManager?.items?.[0]);
  const regionalManagerInfo = getUserInfo(community?.regionalManager?.items?.[0]);

  const companyContactInfo = packInfo({
    firstName: company?.contactName,
    email: company?.contactEmail,
    phone: company?.contactPhoneNumber,
  });

  return (
    offerContactInfo ||
    singleUnitAgentInfo ||
    communityContactInfo ||
    communityManagerInfo ||
    regionalManagerInfo ||
    companyContactInfo
  );
};

export const AgentInformation = ({ bidId }: AgentInformationProps) => {
  const { data, loading } = useQuery<{ bid: Bid }>(AGENT_INFORMATION_BID_QUERY, {
    variables: {
      id: bidId,
    },
    skip: !bidId,
  });

  const offer = data?.bid?.offer;
  const singleUnit = offer?.historyOfferSingleUnit;
  const company = singleUnit?.company;

  const agentInfo = getAgentInfo(data?.bid);
  const leaseContract = offer?.leaseContract;
  const leaseExternalUrl = offer?.leaseLink;

  const DownloadUrl = ({ leaseContract }: { leaseContract: File | null | undefined }) => {
    if (!R.isEmpty(leaseContract)) {
      return null;
    }
    const file = leaseContract as File;

    return (
      <Grid.Layout gap="20px">
        <FlexLayout>
          <Icon component={getIconByMimetype(file)} style={{ marginRight: 8, fontSize: '2rem' }} />
          <span>
            {cropFileName({
              name: stringifyNA(file.filename),
              type: getDocumentTypeByMimetype(file),
              length: 18,
            })}
          </span>
          <a href={file.downloadUrl as string} target="_blank" rel="noopener noreferrer">
            Download
          </a>
        </FlexLayout>
      </Grid.Layout>
    );
  };

  const ExternalUrl = ({ leaseContract }: { leaseContract: string | null | undefined }) => {
    if (!Boolean(leaseContract)) {
      return null;
    }

    const link = stringifyHref(leaseContract);

    return (
      <Button type="primary" size="large" onClick={() => window.open(link)} css={{ marginTop: '15px;' }}>
        Open Lease Agreement
      </Button>
    );
  };

  const isShowLeasDocument = leaseContract || leaseExternalUrl;

  return (
    <AsyncContent loading={loading}>
      <Grid.Layout gap="20px" columns="1fr 1fr">
        <Avatar src={agentInfo?.avatarUrl} size={188}>
          {agentInfo?.initials}
        </Avatar>
        <Grid.Layout gap="20px">
          <InformationSection header="Information">
            <Grid.Layout gap="20px">
              <EntityOption label="Company Name" value={stringifyNA(company?.name)} type="uppercase-label" />
              <EntityOption
                label="Company Website"
                value={<WebsiteLink website={company?.website} />}
                type="uppercase-label"
              />
              <EntityOption label="Agent Name" value={stringifyNA(agentInfo?.name)} type="uppercase-label" />
              <EntityOption label="Email" value={stringifyNA(agentInfo?.email)} type="uppercase-label" />
              <EntityOption label="Phone #1" value={<PhoneLink phone={agentInfo?.phone} />} type="uppercase-label" />
              <EntityOption label="Phone #2" value={stringifyNA(null)} type="uppercase-label" />
              <EntityOption label="Fax" value={stringifyNA(null)} type="uppercase-label" />
            </Grid.Layout>
          </InformationSection>
          {isShowLeasDocument && (
            <InformationSection header="Lease Document">
              <DownloadUrl leaseContract={leaseContract} />
              <ExternalUrl leaseContract={leaseExternalUrl} />
            </InformationSection>
          )}
        </Grid.Layout>
      </Grid.Layout>
    </AsyncContent>
  );
};
