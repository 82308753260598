import * as variables from './variablesModule';
import { checkScrollTrackWidth } from '@/shared/utils';

type Variables = {
  primaryColor: string;
  errorColor: string;
  grayTextColor: string;

  btnDangerBg: string;
  btnDangerBorder: string;

  primaryBorderColor: string;

  inputHeightBase: string;

  btnHeightSm: string;
  btnFontSizeLg: string;
  btnFontWeight: number;

  radioSize: string;

  green: string;
  black: string;
  black3: string;

  headerHeight: string;
  poweredByHeight: string;

  breakpoints: {
    xs: {
      min: string;
      max: string;
    };
    sm: {
      min: string;
      max: string;
    };
    md: {
      min: string;
      max: string;
    };
    lg: {
      min: string;
      max: string;
    };
    xl: {
      min: string;
    };
  };
  scrollTrackWidth?: number;
};

const vars: Variables = { ...variables, scrollTrackWidth: checkScrollTrackWidth() };

export default vars;
