import styled from '@emotion/styled';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 560px 1fr;
  grid-gap: 24px;

  @media (max-width: 767px) {
    display: block;
  }
`;

export const Info = styled.div``;

export const Details = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-row-gap: 20px;
`;
