import React from 'react';
import { NOT_AVALIABLE } from '@/shared/constants';
import { Link } from '@/components';
import { Maybe } from '@/shared/types/graphql';

type EmailLinkProps = {
  email?: Maybe<string>;
};

export const EmailLink: React.FC<EmailLinkProps> = ({ email }) => {
  if (!email) {
    return <>{NOT_AVALIABLE}</>;
  }

  return (
    <Link href={`mailto:${email}`} target="_blank">
      {email}
    </Link>
  );
};
