import React from 'react';
import * as R from 'ramda';
import { useQuery } from 'react-apollo';
import { Tag } from 'antd';

import { USERS_LIST_QUERY, INVITATION_CANCEL_MUTATION } from '../queries';
import {
  UseTableStateResponse,
  useTableState,
  useCurrentCompanyId,
  usePermissions,
  useMutationSafe,
} from '@/shared/hooks';
import { ColumnProps } from 'antd/lib/table';
import { stringifyUserName, getApplicationRole } from '@/shared/utils';
import { User, UserListResponse } from '@/shared/types/graphql';
import { TOAST_SUCCESS_MESSAGE, NOT_AVALIABLE } from '@/shared/constants';
import { EmailLink, Table } from '@/components';
import { OptionItem } from '@/components/SelectField/SelectField';
import { getSort } from '../helpers';
import { TeamTableActions } from './TeamTableActions';
import { ColumnAssignedProperties } from './ColumnAssignedProperties';
import { getFilter } from './helpers';

const STATUSES_MAP = {
  active: { color: 'green', label: 'Active' },
  inactive: { color: '', label: 'Inactive' },
  invitationPending: { color: 'blue', label: 'Invited' },
};

type TeamTableProps = {
  query: string;
  rolesOptions?: any;
  propertiesOptions?: Array<OptionItem>;
};

export const TeamTable = ({ query, rolesOptions, propertiesOptions }: TeamTableProps) => {
  const [{ page, sortBy, sortOrder, pageSize, pageSizeOptions }, onChange]: UseTableStateResponse = useTableState({
    query,
  });

  const { companyId } = useCurrentCompanyId();
  const { isAdmin, isCompanyOwner, applicationRole } = usePermissions();

  const [invitationCancel, { loading: invitationCancelLoading }] = useMutationSafe(INVITATION_CANCEL_MUTATION, {
    context: {
      [TOAST_SUCCESS_MESSAGE]: 'Invitation Successfully Canceled',
    },
    refetchQueries: ['UsersList'],
    awaitRefetchQueries: true,
  });

  const { data, loading } = useQuery<{ usersList: UserListResponse }>(USERS_LIST_QUERY, {
    variables: {
      filter: getFilter({ companyId, query }),
      sort: getSort({ sortOrder, sortBy, page, pageSize }),
      first: pageSize,
      skip: (page - 1) * pageSize,
    },
  });

  const dataSource = data?.usersList?.items ?? [];
  const total = data?.usersList?.count ?? pageSize;
  const pagination = { current: page, pageSize, total, showSizeChanger: true, pageSizeOptions };

  const columns: ColumnProps<User>[] = React.useMemo(
    () => [
      {
        title: 'Team Member Name',
        dataIndex: 'name',
        sorter: dataSource?.length !== 0,
        width: '20%',
        ellipsis: true,
        render: (value, record) => stringifyUserName(record),
        sortOrder: sortBy === 'name' ? sortOrder : undefined,
      },
      {
        title: 'Email Address',
        dataIndex: 'email',
        sorter: dataSource?.length !== 0,
        width: '30%',
        ellipsis: true,
        sortOrder: sortBy === 'email' ? sortOrder : undefined,
        render: function renderEmail(value, { email }) {
          return <EmailLink email={email} />;
        },
      },
      {
        title: 'Status',
        width: '200px',
        dataIndex: 'status',
        sorter: dataSource?.length !== 0,
        ellipsis: true,
        sortOrder: sortBy === 'status' ? sortOrder : undefined,
        render: function StatusTag(value: string) {
          const color = R.pathOr('', [value, 'color'], STATUSES_MAP);
          const label = R.pathOr(value, [value, 'label'], STATUSES_MAP);

          return <Tag color={color}>{label}</Tag>;
        },
      },
      {
        title: 'Role',
        dataIndex: 'role',
        width: '30%',
        ellipsis: true,
        render: (value, record) => getApplicationRole(record)?.name || NOT_AVALIABLE,
      },
      {
        title: 'Assigned Properties',
        dataIndex: '',
        width: '40%',
        ellipsis: true,
        render: function renderAssignedProperties(value: string, record: User) {
          return (
            <ColumnAssignedProperties
              record={record}
              rolesOptions={rolesOptions}
              propertiesOptions={propertiesOptions}
            />
          );
        },
      },
      {
        title: '',
        dataIndex: '',
        width: '43px',
        render: function UserActions(value: string, record: User) {
          return (
            <TeamTableActions
              value={value}
              record={record}
              isAdmin={isAdmin}
              isCompanyOwner={isCompanyOwner}
              rolesOptions={rolesOptions}
              applicationRole={applicationRole}
              propertiesOptions={propertiesOptions}
              invitationCancel={invitationCancel}
            />
          );
        },
      },
    ],
    [
      dataSource,
      sortBy,
      sortOrder,
      rolesOptions,
      propertiesOptions,
      isAdmin,
      isCompanyOwner,
      applicationRole,
      invitationCancel,
    ],
  );

  return (
    <Table
      rowKey="id"
      loading={loading || invitationCancelLoading}
      dataSource={dataSource}
      columns={columns}
      onChange={onChange}
      pagination={pagination}
    />
  );
};
