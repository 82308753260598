import React from 'react';
import { getCommunityAmenities, getSingleUnitAmenities } from '@/shared/utils';
import { ButtonLink, FlexLayout, FormField, SelectField } from '@/components';
import { NEW_AMENITY_GROUPS_LABELS } from '@/shared/constants';
import { Dropdown, Icon, Menu } from 'antd';
import { DeleteIcon } from '@/static/icons';
import { useAmenityGroups } from '@/shared/hooks';
import { FieldArray, Field } from '8base-react-sdk';
import { styled } from '@/theme';
import CollapsePanel from 'antd/es/collapse/CollapsePanel';
import Collapse from 'antd/es/collapse';

const DeleteButton = styled.button`
  max-width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: 5px;
  margin-left: 5px;
  flex-basis: 10%;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid gray;
`;

export const AmenitiesSelectField = ({ input, label, companyType, unitType }: any) => {
  const [isDropdownVisible, changeDropdownVisible] = React.useState(false);
  const { groupedOptions: amenityGroupedOptions, loading: amenityLoading, amenityMap } = useAmenityGroups();
  const values = input?.value;

  return (
    <Collapse>
      <CollapsePanel key={1} header={label}>
        <FormField>
          <FieldArray name={input.name}>
            {({ fields }: any) => {
              const options =
                unitType !== 'community'
                  ? getSingleUnitAmenities(amenityGroupedOptions, companyType, values.type, amenityMap, values)
                  : getCommunityAmenities(amenityGroupedOptions);

              return (
                <React.Fragment>
                  {fields.map((name: any, index: number) => {
                    const currentGroupKey = values[index]?.groupKey;
                    const currentOptions = options?.filter(el => el?.groupKey === currentGroupKey);
                    return (
                      <FlexLayout key={index} stretch align={'center'} css={{ marginBottom: 15 }}>
                        <Field
                          label={NEW_AMENITY_GROUPS_LABELS[values[index]?.groupKey]}
                          name={`${name}.amenity`}
                          component={SelectField}
                          options={currentOptions[0]?.options || []}
                          css={{ flexBasis: '90%' }}
                          validate={value => (value?.length !== 0 ? undefined : `Can't be empty`)}
                          stretch
                          mode="tags"
                          disabled={amenityLoading}
                          format={(value: any) => value && value.map((el: Record<string, any>) => (el.id ? el.id : el))}
                        />
                        <DeleteButton onClick={() => fields.remove(index)} css={{ flexBasis: '10%' }}>
                          <Icon component={DeleteIcon} />
                        </DeleteButton>
                      </FlexLayout>
                    );
                  })}
                  <Dropdown
                    trigger={['click']}
                    visible={isDropdownVisible}
                    onVisibleChange={changeDropdownVisible}
                    disabled={options.length === fields.length}
                    overlay={
                      <Menu>
                        {options.map(group => {
                          const groupLabel = group?.groupName;
                          const groupKey = group?.groupKey;

                          return (
                            <Menu.Item
                              onClick={() => {
                                changeDropdownVisible(false);
                                return fields.push({ groupKey, amenity: [] });
                              }}
                              key={groupKey}
                              disabled={values?.length !== 0 && values?.some((el: any) => el?.groupKey === groupKey)}
                            >
                              {groupLabel}
                            </Menu.Item>
                          );
                        })}
                      </Menu>
                    }
                  >
                    <ButtonLink text="Add new category" iconProps={{ type: 'plus-circle' }} />
                  </Dropdown>
                </React.Fragment>
              );
            }}
          </FieldArray>
        </FormField>
      </CollapsePanel>
    </Collapse>
  );
};
