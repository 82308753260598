import React from 'react';
import gql from 'graphql-tag';
import { Modal } from 'antd';
import { Form, Field } from '@8base-react/forms';

import {
  Grid,
  InputField,
  SelectField,
  TextAreaField,
  ModalFooter,
  AddressFields,
  LogoInputField,
  HasMediaInputField,
  InputNumberField,
  AmenitiesSelectField,
} from '@/components';
import {
  COMMUNITY_STATUSES,
  US_STATES_LIST,
  TOAST_SUCCESS_MESSAGE,
  COMMUNITY_TYPE_LABELS,
  PMS_LIST,
} from '@/shared/constants';
import { addAmenities, normalizePhone, validateEntityDataByDraft } from '@/shared/utils';
import { CommunitySchemaValidation } from '@/schemaValidators';
import { useMutationSafe } from '@/shared/hooks';

const COMMUNITY_CREATE_MUTATION = gql`
  mutation CommunityCreate($data: CommunityCreateInput!) {
    communityCreate(data: $data) {
      id
    }
  }
`;

const INITIAL_VALUES = {
  address: { country: 'United States' },
  phoneNumber: { code: '1' },
  managerPhoneNumber: { code: '1' },
};

type CommunityCreateModalProps = {
  closeWithConfirm: () => void;
  close: () => void;
  isVisible: boolean;
  options?: {
    refetchQueries?: Array<string>;
    awaitRefetchQueries?: boolean;
  };
  companyId?: string;
};

export const Dialog: React.FC<CommunityCreateModalProps> = ({
  options = {},
  companyId,
  close,
  closeWithConfirm,
  isVisible,
}) => {
  const pmsList = React.useMemo(() => PMS_LIST.map(el => ({ value: el, label: el })), []);

  const [communityCreate] = useMutationSafe(COMMUNITY_CREATE_MUTATION, {
    onCompleted: close,
    context: {
      [TOAST_SUCCESS_MESSAGE]: 'Property Successfully Created',
    },
    ...options,
  });

  const coercedStatesOfUSA = React.useMemo(
    () => Object.entries(US_STATES_LIST).map(([value, label]) => ({ value, label: `${value} - ${label}` })),
    [],
  );

  const coercedCommunityTypes = React.useMemo(
    () => Object.entries(COMMUNITY_TYPE_LABELS).map(([value, label]) => ({ value, label })),
    [],
  );

  const onSubmit = React.useCallback(
    async (data: any) => {
      await communityCreate({
        ...options,
        variables: {
          data: {
            ...data,
            company: {
              connect: {
                id: companyId,
              },
            },
          },
          companyId,
        },
      });
    },
    [communityCreate, companyId, options],
  );

  const onFormat = (data: any) => {
    const resultValues = addAmenities(data, 'community');
    return { ...resultValues };
  };

  return (
    <Form
      onSubmit={onSubmit as any}
      tableSchemaName="Community"
      type="CREATE"
      initialValues={INITIAL_VALUES}
      beforeFormatDataForMutation={onFormat}
      validate={values => validateEntityDataByDraft(CommunitySchemaValidation, values)}
    >
      {({ handleSubmit, submitting, pristine, form, values }) => (
        <Modal
          title="Add New Property"
          visible={isVisible}
          onCancel={pristine ? close : closeWithConfirm}
          afterClose={form.reset}
          centered
          footer={
            <ModalFooter
              leftActionProps={{
                disabled: pristine || submitting,
                onClick: event => {
                  form.change('status', COMMUNITY_STATUSES.draft);

                  return handleSubmit(event);
                },
              }}
              cancelProps={{
                onClick: pristine ? close : closeWithConfirm,
              }}
              okProps={{
                text: 'Add Property',
                type: 'primary',
                htmlType: 'submit',
                loading: submitting,
                disabled: pristine,
                onClick: event => {
                  form.change('status', COMMUNITY_STATUSES.active);
                  return handleSubmit(event);
                },
              }}
            />
          }
        >
          <Grid.Layout data-e2e-id="modal.community.create.body">
            <Grid.Box>
              <Field
                name="name"
                label="Property Name*"
                autoComplete="disabled"
                component={InputField}
                data-e2e-id="modal.community.create.name"
              />
            </Grid.Box>
            <Grid.Box>
              <Field
                name="numberOfUnits"
                label="Number of Units"
                autoComplete="disabled"
                component={InputNumberField}
                placeholder="e.g. 250"
                stretch
                data-e2e-id="modal.community.create.numberOfUnits"
              />
            </Grid.Box>
            <Grid.Box>
              <Field
                name="managementCompany"
                label="Management Company"
                autoComplete="disabled"
                component={InputField}
                data-e2e-id="modal.community.create.managementCompany"
              />
            </Grid.Box>
            <Grid.Box>
              <Field
                name="managerName"
                label="Contact Name*"
                autoComplete="disabled"
                component={InputField}
                data-e2e-id="modal.community.create.managerName"
              />
            </Grid.Box>
            <Grid.Box>
              <Field
                name="managerTitle"
                label="Contact Title"
                autoComplete="disabled"
                component={InputField}
                data-e2e-id="modal.community.create.managerTitle"
              />
            </Grid.Box>
            <Grid.Box>
              <Field
                name="managerEmail"
                label="Contact Email"
                autoComplete="disabled"
                component={InputField}
                data-e2e-id="modal.community.create.managerEmail"
              />
            </Grid.Box>
            <Grid.Box>
              <Field
                name="managerPhoneNumber.number"
                label="Contact Phone Number*"
                autoComplete="disabled"
                placeholder="(415) 842-2731"
                mask="(999) 999-9999"
                width={13}
                component={InputField}
                addonBefore={`+1`}
                parse={normalizePhone}
                data-e2e-id="modal.community.create.managerPhoneNumber"
              />
            </Grid.Box>
            <Grid.Box>
              <Field
                name="type"
                label="Type*"
                options={coercedCommunityTypes}
                component={SelectField}
                stretch
                data-e2e-id="modal.community.create.type"
              />
            </Grid.Box>
            <Grid.Box>
              <Field
                name="logo"
                label="Logo"
                buttonText="Select File (PNG, JPEG, SVG)"
                accept={['.png', '.jpg', '.jpeg', '.svg']}
                data-e2e-id="modal.community.create.logo"
                component={LogoInputField}
              />
            </Grid.Box>
            <Grid.Box>
              <Field name="description" label="Description" component={TextAreaField} />
            </Grid.Box>
            <Grid.Box>
              <Field name="amenities" label="Amenities" component={AmenitiesSelectField} unitType="community" stretch />
            </Grid.Box>
            <Grid.Box>
              <AddressFields
                street1="address.street1"
                street2="address.street2"
                city="address.city"
                state="address.state"
                zip="address.zip"
                country="address.country"
                stateOptions={coercedStatesOfUSA}
                addressNote="At this time Livo.io only supports U.S. based companies."
                data-e2e-id="modal.community.create.address"
              />
            </Grid.Box>
            <Grid.Box>
              <Field
                label="Phone Number*"
                name="phoneNumber.number"
                autoComplete="disabled"
                placeholder="(415) 842-2731"
                mask="(999) 999-9999"
                width={13}
                component={InputField}
                addonBefore={`+1`}
                parse={normalizePhone}
                data-e2e-id="modal.community.create.phone"
              />
            </Grid.Box>
            <Grid.Box>
              <Field
                label="Website*"
                autoComplete="disabled"
                name="website"
                placeholder="e.g. https://www.livo.io, http://livo.com, etc."
                component={InputField}
                data-e2e-id="modal.community.create.website"
              />
            </Grid.Box>
            <Grid.Box>
              <Field
                label="Property Management Software System"
                name="communityManagementSoftwareSystem"
                placeholder="Select"
                autoComplete="disabled"
                options={pmsList}
                component={SelectField}
                mode="multiple"
                stretch
              />
            </Grid.Box>
            <Grid.Box>
              <Field
                name="communityHasMedia"
                label="Photos"
                buttonText="Select files (PNG, JPEG, SVG)"
                maxFiles={100}
                component={HasMediaInputField}
                accept={['.png', '.jpg', '.jpeg', '.svg']}
                previewWidth="1/2"
                isPublic
              />
            </Grid.Box>
          </Grid.Layout>
        </Modal>
      )}
    </Form>
  );
};
