export const COMPANY_TYPE_LABELS = {
  selfManaged: 'Self Managed',
  singleFamily: 'Single Family',
  mdu: 'Multi-Family',
};

export type CompanyTypes = keyof typeof COMPANY_TYPE_LABELS;

export const COMPANY_TYPES: Record<string, keyof typeof COMPANY_TYPE_LABELS> = {
  selfManaged: 'selfManaged',
  singleFamily: 'singleFamily',
  mdu: 'mdu',
};

export const COMPANY_STATUS_LABELS = {
  draft: 'Draft',
  active: 'Active',
  inactive: 'Inactive',
  blocked: 'Blocked',
};

export type CompanyStatuses = keyof typeof COMPANY_STATUS_LABELS;
