import React from 'react';
import { OFFER_COMBINED_STATUSES, BID_STATUSES } from '@/shared/constants';
import { Tag } from '@/components';
import pluralize from 'pluralize';
import { Offer } from '@/shared/types/graphql';
import { useOfferBidCombinedStatus } from '@/shared/hooks';

interface StatusTagPresentationProps {
  offer: Offer;
}

export const StatusTagPresentation: React.FC<StatusTagPresentationProps> = ({ offer }) => {
  const offerBidCombinedStatus = useOfferBidCombinedStatus(offer);
  const bidsList = offer?.bid?.items || [];
  const filteredBids = bidsList.filter(bid => bid?.status !== BID_STATUSES.rejected);

  return React.useMemo(() => {
    switch (offerBidCombinedStatus.kind) {
      case OFFER_COMBINED_STATUSES.rejected:
        return (
          <Tag color="red" type="raised">
            Your offer was rejected
          </Tag>
        );
      case OFFER_COMBINED_STATUSES.awarded:
        return (
          <Tag color="green" type="raised">
            Property Awarded
          </Tag>
        );
      case OFFER_COMBINED_STATUSES.leased:
        return (
          <Tag color="red" type="raised">
            Property Leased
          </Tag>
        );
      case OFFER_COMBINED_STATUSES.canceled:
        return (
          <Tag color="red" type="raised">
            Auction canceled
          </Tag>
        );
      case OFFER_COMBINED_STATUSES['you-accept']:
      case OFFER_COMBINED_STATUSES['you-won']:
      case OFFER_COMBINED_STATUSES['you-secured']:
        return (
          <Tag color="green" type="raised">
            You won
          </Tag>
        );
      case OFFER_COMBINED_STATUSES['ended']:
        return (
          <Tag color="red" type="raised">
            Auction Ended
          </Tag>
        );
      default:
        return (
          <Tag color="green" type="raised">
            {filteredBids.length} {pluralize('offer', filteredBids.length)}
          </Tag>
        );
    }
  }, [offerBidCombinedStatus, filteredBids]);
};
