import gql from 'graphql-tag';
import { COMPANY_FRAGMENT } from '@/shared/graphql/company';

export const COMPANY_UPDATE_INFORMATION_QUERY = gql`
  query CompanyUpdateInformation($id: ID!) {
    company(id: $id) {
      id
      ...Company
    }

    propertyManagementSoftwareSystemsList {
      items {
        id
        name
      }
    }

    listingServicesList {
      items {
        id
        name
      }
    }
  }

  ${COMPANY_FRAGMENT}
`;
