import React from 'react';
import { useAuth } from '8base-react-sdk';
import { Button } from 'antd';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';
import { toast } from 'react-toastify';

export const ButtonForChangePassword = () => {
  const { authClient } = useAuth();
  const { auth0 } = authClient;
  const { user } = useCurrentUser();

  const { REACT_APP_AUTH0_DATABASE: connection } = process.env;
  const [message, setMessage] = React.useState('');

  const changePassword = React.useCallback(
    data =>
      new Promise<string>((resolve, reject) => {
        auth0.changePassword(data, (err: any, message: string) => {
          if (err) {
            return reject(err);
          }
          return resolve(message);
        });
      }),
    [auth0],
  );

  const onSendLinkForChange = React.useCallback(async () => {
    try {
      const message = await changePassword({
        connection,
        email: user?.email,
      });

      setMessage(message);
    } catch (e) {
      const message = e?.description || 'Something went wrong during change password';
      toast.error(message);
    }
  }, [changePassword, connection, user]);

  if (Boolean(message)) {
    return <p style={{ marginTop: 20 }}>Password reset link has been sent to your email account</p>;
  }
  return (
    <Button size="small" style={{ marginTop: 20 }} onClick={onSendLinkForChange}>
      Change password
    </Button>
  );
};
