import React, { useState } from 'react';
import { useAuth } from '8base-react-sdk';
import { useHistory } from 'react-router-dom';
import { Menu, Dropdown, Icon, Avatar, Skeleton } from 'antd';

import { ChevronUp, ChevronDown } from '@/static/icons';
import {
  Container,
  UserName,
  Inner,
  AvatarContainer,
  MenuContainer,
  MenuHeader,
  CompanyType,
  CompanyLogo,
} from './styled';
import { getInitials } from '@/shared/utils';
import { COMPANY_TYPE_LABELS } from 'livo-shared';
import { useCurrentCompanyId, useCurrentUser, usePermissions } from '@/shared/hooks';

const HeaderDropdownMenu = ({ closeDropdown }: { closeDropdown: () => void }) => {
  const auth = useAuth();
  const history = useHistory();
  const { isAdmin, isCompanyUser } = usePermissions();

  const { companyId } = useCurrentCompanyId();
  const { user } = useCurrentUser();

  const companyType = user?.company?.type ?? '';
  const companyLogo = user?.company?.companyLogo?.downloadUrl ?? '';

  return (
    <MenuContainer>
      {(companyLogo || companyType) && (
        <MenuHeader>
          {companyLogo && <CompanyLogo src={companyLogo} alt="company logo" />}
          {companyType && <CompanyType>{(COMPANY_TYPE_LABELS as any)[companyType]}</CompanyType>}
        </MenuHeader>
      )}
      <Menu>
        <Menu.Item
          key="1"
          onClick={() => {
            closeDropdown();
            if (isAdmin) {
              history.replace(`/management/settings/account`);
            } else {
              history.replace(`/management/${companyId}/settings/account`);
            }
          }}
        >
          My Account
        </Menu.Item>
        {!isAdmin && !isCompanyUser && (
          <Menu.Item
            key="2"
            onClick={() => {
              closeDropdown();
              history.replace(`/management/${companyId}/profile`);
            }}
          >
            Company Profile
          </Menu.Item>
        )}
        <Menu.Item
          key="3"
          onClick={async () => {
            await auth.authClient.logout();

            await auth.authClient.purgeState();

            closeDropdown();
          }}
        >
          Logout
        </Menu.Item>
      </Menu>
    </MenuContainer>
  );
};

export const UserInfo = () => {
  const [isOpen, setDropdown] = useState(false);

  const { user, loading } = useCurrentUser();

  const firstName = user?.firstName ?? '';
  const lastName = user?.lastName ?? '';
  const avatarSrc = user?.avatar?.downloadUrl ?? '';

  const initials = getInitials(firstName, lastName);
  const fullName = `${firstName}${firstName ? ` ${lastName}` : lastName}`;

  return (
    <Container>
      <Dropdown
        overlay={<HeaderDropdownMenu closeDropdown={() => setDropdown(false)} />}
        trigger={['click']}
        visible={isOpen}
        onVisibleChange={() => setDropdown(!isOpen)}
      >
        <Inner data-e2e-id="user-dropdown">
          {!loading && (
            <AvatarContainer>
              <Avatar src={avatarSrc}>{initials}</Avatar>
            </AvatarContainer>
          )}
          <UserName>
            {loading ? (
              <Skeleton active={true} avatar={true} title={false} paragraph={{ width: 65, rows: 1 }} />
            ) : (
              fullName
            )}
            {<Icon component={isOpen ? ChevronUp : ChevronDown} style={{ marginLeft: '5px' }} />}
          </UserName>
        </Inner>
      </Dropdown>
    </Container>
  );
};
