import gql from 'graphql-tag';

export const OFFER_CONTACT_QUERY = gql`
  query OfferContactQuery($id: ID!) {
    offer(id: $id) {
      id
      contact {
        id
        lastName
        firstName
        email
        phone {
          code
          number
        }
        avatar {
          id
          downloadUrl
        }
        company {
          id
          name
          type
          website
          companyLogo {
            id
            downloadUrl
          }
        }
      }
    }
  }
`;
