import { Maybe } from '@/shared/types/graphql';
import * as R from 'ramda';

import { NEW_AMENITY_LABELS, NEW_AMENITY_GROUPS, NEW_AMENITY_GROUP_LISTS, COMPANY_TYPES } from '@/shared/constants';
import { AmenityGroupOptionsList, AmenityGroupOptionItem } from '@/shared/hooks/useAmenityGroups';
import { AmenityMap } from '@/shared/hooks/useAmenityGroups';

export const getSingleUnitAmenities = (
  groupedOptions: AmenityGroupOptionsList,
  companyType?: Maybe<string>,
  singleUnitType?: Maybe<string>,
  amenityMap?: AmenityMap,
  currentAmenities?: Array<any>,
  singleGroupKey?: Maybe<string>,
): AmenityGroupOptionsList => {
  let allowedGroupList: Array<string> = [];

  if (companyType === COMPANY_TYPES.singleFamily) {
    allowedGroupList = [...NEW_AMENITY_GROUP_LISTS.community, ...NEW_AMENITY_GROUP_LISTS.singleUnit];
  }

  if (companyType === COMPANY_TYPES.mdu) {
    allowedGroupList = NEW_AMENITY_GROUP_LISTS.singleUnit;
  }

  if (companyType !== COMPANY_TYPES.singleFamily && companyType !== COMPANY_TYPES.mdu) {
    allowedGroupList = [...NEW_AMENITY_GROUP_LISTS.community, ...NEW_AMENITY_GROUP_LISTS.singleUnit];
  }

  const filteredAmenityGroupedOptions = groupedOptions.filter(({ groupKey: currentGroupKey }) => {
    if (singleGroupKey) {
      return allowedGroupList.filter(el => el === singleGroupKey).some(groupKey => groupKey === currentGroupKey);
    } else return allowedGroupList.some(groupKey => groupKey === currentGroupKey);
  });

  const aditionalGroupedOptionsMap: Record<string, AmenityGroupOptionItem> = {};

  if (!R.isEmpty(currentAmenities) && amenityMap) {
    currentAmenities?.forEach(entity => {
      const id = entity?.id ? entity?.id : entity;
      if (amenityMap[id]) {
        const currentGroupKey = amenityMap[id].groupKey;
        const amenityKey = amenityMap[id].key;

        const isAllowedGroupedKey = allowedGroupList.some(groupKey => groupKey === currentGroupKey);

        if (!isAllowedGroupedKey) {
          if (aditionalGroupedOptionsMap[currentGroupKey]) {
            aditionalGroupedOptionsMap[currentGroupKey].options.push({
              label: NEW_AMENITY_LABELS[amenityKey],
              value: id,
            });
          } else {
            aditionalGroupedOptionsMap[currentGroupKey] = {
              groupKey: currentGroupKey,
              groupName: NEW_AMENITY_GROUPS[currentGroupKey].label,
              options: [
                {
                  label: NEW_AMENITY_LABELS[amenityKey],
                  value: id,
                },
              ],
            };
          }
        }
      }
    });
  }

  const additionalGroupedOptionsList = Object.entries(aditionalGroupedOptionsMap).map(([value, data]) => data);

  return [...filteredAmenityGroupedOptions, ...additionalGroupedOptionsList];
};
