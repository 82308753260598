import React from 'react';
import * as R from 'ramda';

import { Link } from '@/components';
import { stringifyNA } from '@/shared/utils';
import { Maybe } from '@/shared/types/graphql';

type ConditionalLinkProps = {
  to: string;
  condition: boolean;
  text?: Maybe<string | number>;
  ellipsis?: boolean;
};

export const ConditionalLink: React.FC<ConditionalLinkProps> = ({ children, condition, to, text, ellipsis }) => {
  return !R.isNil(text) && condition ? (
    <Link
      css={ellipsis ? { textOverflow: 'ellipsis', maxWidth: '95%', display: 'block', overflow: 'hidden' } : {}}
      ellipsis={false}
      to={to}
    >
      {text}
    </Link>
  ) : (
    <span css={ellipsis ? { textOverflow: 'ellipsis', maxWidth: '95%', display: 'block', overflow: 'hidden' } : {}}>
      {stringifyNA(text)}
    </span>
  );
};
