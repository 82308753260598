import React from 'react';
import styled from '@emotion/styled';
import * as R from 'ramda';

import { SummaryItemProps } from './types';

const Container = styled.div`
  display: grid;
  grid-template-rows: 66px 38px;
`;
// TODO: take colours values from theme
const Value = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f37a22;
  font-size: 50px;
  padding-top: 5px;
`;
// TODO: take colours values from theme
const Description = styled.div`
  color: #929da9;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0;
  text-align: center;
  padding: 0 13px;
`;

const getValue = (value: number | null, isPercentage = false): number | string => {
  if (R.isNil(value)) {
    return 0;
  }
  if (isPercentage) {
    return `${value > 0 ? '+' : ''}${value}%`;
  }
  return value;
};

export const SummaryItem = ({ value, description, isPercentage }: SummaryItemProps) => {
  return (
    <Container>
      <Value>{getValue(value, isPercentage)}</Value>
      <Description>{description}</Description>
    </Container>
  );
};
