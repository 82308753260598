import styled from '@emotion/styled';

export const Container = styled.div`
  display: grid;

  .ant-card-body {
    padding: 32px 24px;
  }

  .ant-avatar {
    border: 2px solid #f37a22;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 240px repeat(2, 1fr);
  grid-template-areas: 'companyLogo info1 info2' 'companyLogo info3 info4' 'companyLogo info5 info5';
  grid-column-gap: 84px;
  grid-row-gap: 50px;
`;

export const AvatarContainer = styled.div`
  grid-area: companyLogo;
`;

export const InformationContainer = styled.div<{ area?: string }>`
  grid-area: ${({ area }) => area && area};
`;

// TODO: use colours from theme
export const InformationTitle = styled.div`
  margin-bottom: 20px;
  color: #313c48;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
`;

export const InformationList = styled.div`
  display: grid;
  grid-row-gap: 20px;
`;

export const InformationListItem = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 10px;
`;

export const InformationListItemLabel = styled.div`
  color: #939ea7;
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 16px;
  font-weight: 500;
  text-transform: uppercase;
`;

export const InformationListItemValue = styled.div`
  color: #323c47;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
`;

export const InformationDocuments = styled.div`
  display: grid;
  grid-gap: 1.6rem;
  grid-template-columns: repeat(auto-fit, 278px);
`;
