import gql from 'graphql-tag';

const SINGLE_UNIT_OFFER_FRAGMENT = gql`
  fragment SingleUnitOfferFragment on Offer {
    id
    status
    numberId
    incrementedBidAmount
    securityDeposite
    securityDepositeEditable
    termsOfLease
    rentAmount
    moveinDate
    moveinDateEditable
    startsIn
    endsIn
    secureNow
    secureNowAmount
    calendarLink
    historyOfferSingleUnit {
      id
      name
      type
      description
      bedrooms
      bathrooms
      squareFt
      singleUnitAmenity {
        id
        sports
        swimSpa
        children
        family
        pets
        leisureSocial
        creative
        laundry
        transportation
        security
        onsite
        technology
        common
        misc
        accessibility
      }
      address {
        country
        street1
        street2
        zip
        city
        state
      }
      phone {
        code
        number
      }
      website
      virtualTour
      singleUnitHasMedia(sort: { order: ASC }) {
        items {
          id
          altName
          order
          media {
            id
            name
            file {
              id
              fileId
              filename
              downloadUrl
              meta
            }
          }
        }
      }
      community {
        id
        name
        phoneNumber {
          code
          number
        }
        communityAmenity {
          id
          sports
          swimSpa
          children
          family
          pets
          leisureSocial
          creative
          laundry
          transportation
          security
          onsite
          technology
          common
          misc
          accessibility
        }
        communityHasMedia(sort: { order: ASC }) {
          items {
            id
            altName
            order
            media {
              id
              name
              file {
                id
                fileId
                filename
                downloadUrl
                meta
              }
            }
          }
        }
        logo {
          id
          downloadUrl
          previewUrl
        }
      }
      company {
        id
        companyLogo {
          id
          downloadUrl
        }
      }
    }
    bid(sort: [{ rank: DESC }, { updatedAt: ASC }]) {
      items {
        id
        status
        rentAmount
        termsOfLease
        moveinDate
        offerAccepted
        applicationFormComplete
        screeningComplete
        leaseSigned
        depositCheck
        offer {
          id
          status
        }
        rank
        renter {
          id
          user {
            id
            firstName
            lastName
          }
        }
      }
      count
    }
  }
`;

export const SINGLE_UNIT_QUERY = gql`
  query SingleUnitQuery($id: ID!) {
    singleUnit(id: $id) {
      id
      name
      type
      description
      bedrooms
      bathrooms
      squareFt
      singleUnitAmenity {
        id
        sports
        swimSpa
        children
        family
        pets
        leisureSocial
        creative
        laundry
        transportation
        security
        onsite
        technology
        common
        misc
        accessibility
      }
      address {
        country
        street1
        street2
        zip
        city
        state
      }
      phone {
        code
        number
      }
      website
      virtualTour
      singleUnitHasMedia(sort: { order: ASC }) {
        items {
          id
          altName
          order
          media {
            id
            name
            file {
              id
              fileId
              filename
              downloadUrl
              meta
            }
          }
        }
      }
      activeOffer {
        ...SingleUnitOfferFragment
      }
      community {
        id
        name
        phoneNumber {
          code
          number
        }
        communityAmenity {
          id
          sports
          swimSpa
          children
          family
          pets
          leisureSocial
          creative
          laundry
          transportation
          security
          onsite
          technology
          common
          misc
          accessibility
        }
        communityHasMedia(sort: { order: ASC }) {
          items {
            id
            altName
            order
            media {
              id
              name
              file {
                id
                fileId
                filename
                downloadUrl
                meta
              }
            }
          }
        }
        logo {
          id
          downloadUrl
          previewUrl
        }
      }
      company {
        id
        companyLogo {
          id
          downloadUrl
        }
      }
    }
  }
  ${SINGLE_UNIT_OFFER_FRAGMENT}
`;

export const OFFER_QUERY = gql`
  query OfferQuery($id: ID!) {
    offer(id: $id) {
      ...SingleUnitOfferFragment
    }
  }
  ${SINGLE_UNIT_OFFER_FRAGMENT}
`;

export const USER_UPDATE_MUTATION = gql`
  mutation UserUpdate($data: UserUpdateInput!, $filter: UserKeyFilter) {
    userUpdate(data: $data, filter: $filter) {
      id
    }
  }
`;
