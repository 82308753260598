import React from 'react';
import { Modal, Icon, Row } from 'antd';
import styled from '@emotion/styled';

import { ModalFooter } from '@/components';
import { RedAlert } from '@/static/icons';
import { TOAST_SUCCESS_MESSAGE } from '@/shared/constants';

import { COMMUNITY_DELETE_MUTATION } from './queries';
import { useMutationSafe, useDialogState } from '@/shared/hooks';

const Container = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  padding: 24px 76px 8px 76px;
  height: 150px;
`;

export const COMMUNITY_DELETE_DIALOG = 'COMMUNITY_DELETE_DIALOG';

export const CommunityDeleteDialog = () => {
  const { close, isVisible, props } = useDialogState(COMMUNITY_DELETE_DIALOG);

  const id = props?.id;

  const [communityDelete, { loading: deleting }] = useMutationSafe(COMMUNITY_DELETE_MUTATION, {
    onCompleted: close,
    context: {
      [TOAST_SUCCESS_MESSAGE]: 'Property successfully deleted',
    },
    ...(props?.options || {}),
  });

  const onDelete = React.useCallback(
    () =>
      communityDelete({
        variables: {
          id,
        },
      }),
    [communityDelete, id],
  );

  const title = (
    <Row>
      <Icon
        component={RedAlert}
        css={{ marginRight: '8px', fontSize: '20px', lineHeight: '22px', verticalAlign: 'middle' }}
      />
      Property Delete
    </Row>
  );

  if (!isVisible) {
    return null;
  }

  return (
    <Modal
      title={title}
      closable={!deleting}
      maskClosable={!deleting}
      className="livo-modal"
      visible={isVisible}
      onCancel={close}
      width="100%"
      style={{ maxWidth: 400 }}
      centered
      footer={
        <ModalFooter
          cancelProps={{
            onClick: close,
            disabled: deleting,
          }}
          okProps={{
            type: 'danger',
            text: 'Yes, Delete',
            disabled: deleting,
            loading: deleting,
            onClick: onDelete,
          }}
        />
      }
    >
      <Container>Are you sure you want to delete property?</Container>
    </Modal>
  );
};
