import React from 'react';
import { Button } from 'antd';

import { Maybe, Bid, Offer } from '@/shared/types/graphql';
import { Grid } from '@/components';
import { dialogsContext } from '@/providers';
import { AnnouncingTitle } from '../styled';
import { OfferChecklistView } from '@/pro';
import { stringifyHref } from '@/shared/utils';
import { AGENT_CONTACT_DIALOG } from '@/dialogs';

export const OfferAccepted = ({ bid, offer }: { bid?: Maybe<Bid>; offer?: Maybe<Offer> }) => {
  const { openDialog } = React.useContext(dialogsContext);

  const handleContactAgent = () => {
    if (bid?.id) {
      openDialog({ name: AGENT_CONTACT_DIALOG, props: { bidId: bid?.id } });
    }
  };

  const leaseExternalUrl = offer?.leaseLink || '';
  const leaseDownloadUrl = offer?.leaseContract?.downloadUrl || '';

  const ButtonUrl = ({ buttonText, leaseContract }: { buttonText: string; leaseContract: string }) => {
    if (!Boolean(leaseContract)) {
      return null;
    }

    const link = stringifyHref(leaseContract);

    return (
      <Button type="primary" size="large" onClick={() => window.open(link)} css={{ marginRight: '10px;' }}>
        {buttonText}
      </Button>
    );
  };

  return (
    <Grid.Layout gap="32px">
      <AnnouncingTitle>Let&apos;s make it official!</AnnouncingTitle>

      <OfferChecklistView bid={bid} />

      <Grid.Box direction="row">
        <ButtonUrl buttonText="Download Lease Agreement" leaseContract={leaseDownloadUrl} />
        <ButtonUrl buttonText="Open Lease Agreement" leaseContract={leaseExternalUrl} />

        <Button size="large" onClick={handleContactAgent}>
          Contact Agent
        </Button>
      </Grid.Box>
    </Grid.Layout>
  );
};
