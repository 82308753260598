import { USER_ROLES } from 'livo-shared';

interface GetFilterProps {
  companyId: string;
  query: string;
}
export const getFilter = ({ companyId, query }: GetFilterProps) => {
  const filterAND = [];

  filterAND.push({
    roles: {
      every: {
        id: {
          not_equals: USER_ROLES.RENTER.value,
        },
      },
    },
  });

  filterAND.push({
    company: {
      id: {
        equals: companyId,
      },
    },
  });

  if (query) {
    filterAND.push({
      OR: [
        {
          firstName: {
            contains: query,
          },
        },
        {
          lastName: {
            contains: query,
          },
        },
        {
          email: {
            contains: query,
          },
        },
      ],
    });
  }

  return {
    AND: filterAND,
  };
};
