import * as yup from 'yup';
import { urlRegexp } from '@/schemaValidators/common';

export const SchemaValidation = yup.object().shape(
  {
    leaseContract: yup
      .mixed()
      .nullable()
      .when('leaseLink', {
        is: value => !value,
        then: yup
          .mixed()
          .nullable()
          .required('Lease Contract is required'),
        otherwise: yup.mixed().notRequired(),
      }),

    leaseLink: yup
      .string()
      .nullable()
      .when('leaseContract', {
        is: value => !value,
        then: yup
          .string()
          .nullable()
          .required('Lease Link is required')
          .matches(urlRegexp, 'Not a valid URL'),
        otherwise: yup.string().notRequired(),
      }),
  },
  [['leaseContract', 'leaseLink']],
);
