import { DateTime, Interval } from 'luxon';

export const getTimeToApproveOffer = (offer: any) => {
  const approveOfferStartTime = offer?.approveOfferStartTime;

  const daysToAcceptOffer = offer?.timeframeToSignContract;

  if (approveOfferStartTime && daysToAcceptOffer) {
    const lastDay = DateTime.fromISO(approveOfferStartTime).plus({ days: daysToAcceptOffer });
    const interval = Interval.fromDateTimes(DateTime.local(), lastDay);
    const daysLeft = Math.round(interval.length('days'));
    const hoursLeft = Math.round(interval.length('hours'));
    if (isNaN(daysLeft) || isNaN(hoursLeft)) {
      return '0 days';
    } else return daysLeft > 1 || hoursLeft === 0 ? `${daysLeft} days` : `${hoursLeft} hours`;
  } else return '0 days';
};
