import {
  DogPark,
  BusStop,
  LaundryService,
  CommunityCenter,
  PartyRoom,
  FamilyAmenities,
  GatedAccess,
  HighendKitchen,
  PatioBalcony,
  CableSattelite,
  PetFriendly,
  HighSpeedInternet,
  FitnessCenter,
  Spa,
  Playground,
  SharedCar,
  SecurityGuard,
  Fireplace,
  HighendCounter,
  PedestrainFriendly,
  CommunityEvents,
} from '@/static/icons';

export const NEW_AMENITY_GROUPS: Record<
  string,
  {
    label: string;
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  }
> = {
  mduCommunityPet: { label: 'Pet', icon: DogPark },
  mduCommunityTransportationAndParking: { label: 'Transportation and Parking', icon: BusStop },
  mduCommunityLaundry: { label: 'Laundry', icon: LaundryService },
  mduCommunityKitchen: { label: 'Kitchen', icon: HighendKitchen },
  mduCommunityRecreation: { label: 'Recreation', icon: PartyRoom },
  mduCommunityFamily: { label: 'Family', icon: FamilyAmenities },
  mduCommunityMiscellaneous: { label: 'Miscellaneous', icon: GatedAccess },
  mduUnitUnit: { label: 'Unit', icon: PatioBalcony },
  mduUnitTech: { label: 'Tech', icon: CableSattelite },
  singleFamilyPet: { label: 'Pet', icon: PetFriendly },
  singleFamilyNeighborhoodCommunity: { label: 'Neighborhood/Community', icon: CommunityCenter },
  singleFamilyHome: { label: 'Home', icon: FamilyAmenities },
  singleFamilyTech: { label: 'Tech', icon: HighSpeedInternet },
  //new
  sports: { label: 'Sports, Fitness & Recreational Amenities', icon: FitnessCenter },
  swimSpa: { label: 'Swim & Spa', icon: Spa },
  children: { label: 'Children', icon: Playground },
  family: { label: 'Family Amenities', icon: FamilyAmenities },
  pets: { label: 'Pets', icon: DogPark },
  leisureSocial: { label: 'Leisure & Social', icon: CommunityCenter },
  creative: { label: 'Creative', icon: CommunityEvents },
  laundry: { label: 'Laundry Amenities', icon: LaundryService },
  transportation: { label: 'Transportation and Parking', icon: SharedCar },
  security: { label: 'Security', icon: SecurityGuard },
  onsite: { label: 'Onsite', icon: PartyRoom },
  accessibility: { label: 'Accessibility', icon: PedestrainFriendly },
  technology: { label: 'Technology', icon: CableSattelite },
  common: { label: 'Living Space Amenities', icon: HighendCounter },
  misc: { label: 'Misc', icon: Fireplace },
};

export const AMENITY_GROUP_KEYS: Record<string, string> = {
  mduCommunityPet: 'mduCommunityPet',
  mduCommunityTransportationAndParking: 'mduCommunityTransportationAndParking',
  mduCommunityLaundry: 'mduCommunityLaundry',
  mduCommunityKitchen: 'mduCommunityKitchen',
  mduCommunityRecreation: 'mduCommunityRecreation',
  mduCommunityFamily: 'mduCommunityFamily',
  mduCommunityMiscellaneous: 'mduCommunityMiscellaneous',
  mduUnitUnit: 'mduUnitUnit',
  mduUnitTech: 'mduUnitTech',
  singleFamilyPet: 'singleFamilyPet',
  singleFamilyNeighborhoodCommunity: 'singleFamilyNeighborhoodCommunity',
  singleFamilyHome: 'singleFamilyHome',
  singleFamilyTech: 'singleFamilyTech',
};

export const AMENITY_GROUPS: Record<
  string,
  {
    label: string;
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  }
> = {
  mduCommunityPet: { label: 'Pet', icon: DogPark },
  mduCommunityTransportationAndParking: { label: 'Transportation and Parking', icon: BusStop },
  mduCommunityLaundry: { label: 'Laundry', icon: LaundryService },
  mduCommunityKitchen: { label: 'Kitchen', icon: HighendKitchen },
  mduCommunityRecreation: { label: 'Recreation', icon: PartyRoom },
  mduCommunityFamily: { label: 'Family', icon: FamilyAmenities },
  mduCommunityMiscellaneous: { label: 'Miscellaneous', icon: GatedAccess },
  mduUnitUnit: { label: 'Unit', icon: PatioBalcony },
  mduUnitTech: { label: 'Tech', icon: CableSattelite },
  singleFamilyPet: { label: 'Pet', icon: PetFriendly },
  singleFamilyNeighborhoodCommunity: { label: 'Neighborhood/Community', icon: CommunityCenter },
  singleFamilyHome: { label: 'Home', icon: FamilyAmenities },
  singleFamilyTech: { label: 'Tech', icon: HighSpeedInternet },
};

export const NEW_AMENITY_GROUPS_LABELS: any = {
  sports: 'Sports, Fitness & Recreational Amenities',
  swimSpa: 'Swim & Spa',
  children: 'Children',
  family: 'Family Amenities',
  pets: 'Pets',
  leisureSocial: 'Leisure & Social',
  creative: 'Creative',
  laundry: 'Laundry Amenities',
  transportation: 'Transportation and Parking',
  security: 'Security',
  onsite: 'Onsite',
  accessibility: 'Accessibility',
  technology: 'Technology',
  common: 'Living Space Amenities',
  misc: 'Misc',
};

export const AMENITY_GROUP_LISTS = {
  community: [
    AMENITY_GROUP_KEYS.mduCommunityPet,
    AMENITY_GROUP_KEYS.mduCommunityTransportationAndParking,
    AMENITY_GROUP_KEYS.mduCommunityLaundry,
    AMENITY_GROUP_KEYS.mduCommunityKitchen,
    AMENITY_GROUP_KEYS.mduCommunityRecreation,
    AMENITY_GROUP_KEYS.mduCommunityFamily,
    AMENITY_GROUP_KEYS.mduCommunityMiscellaneous,
  ],
  communityUnit: [AMENITY_GROUP_KEYS.mduUnitUnit, AMENITY_GROUP_KEYS.mduUnitTech],
  singleFamily: [
    AMENITY_GROUP_KEYS.singleFamilyPet,
    AMENITY_GROUP_KEYS.singleFamilyNeighborhoodCommunity,
    AMENITY_GROUP_KEYS.singleFamilyHome,
    AMENITY_GROUP_KEYS.singleFamilyTech,
  ],
};

export const NEW_AMENITY_GROUPS_KEYS = {
  sports: 'sports',
  swimSpa: 'swimSpa',
  children: 'children',
  family: 'family',
  pets: 'pets',
  leisureSocial: 'leisureSocial',
  creative: 'creative',
  laundry: 'laundry',
  transportation: 'transportation',
  security: 'security',
  onsite: 'onsite',
  accessibility: 'accessibility',
  technology: 'technology',
  common: 'common',
  misc: 'misc',
};

export const NEW_AMENITY_GROUP_LISTS = {
  community: [
    NEW_AMENITY_GROUPS_KEYS.sports,
    NEW_AMENITY_GROUPS_KEYS.swimSpa,
    NEW_AMENITY_GROUPS_KEYS.children,
    NEW_AMENITY_GROUPS_KEYS.family,
    NEW_AMENITY_GROUPS_KEYS.pets,
    NEW_AMENITY_GROUPS_KEYS.leisureSocial,
    NEW_AMENITY_GROUPS_KEYS.creative,
    NEW_AMENITY_GROUPS_KEYS.laundry,
    NEW_AMENITY_GROUPS_KEYS.transportation,
    NEW_AMENITY_GROUPS_KEYS.security,
    NEW_AMENITY_GROUPS_KEYS.onsite,
    NEW_AMENITY_GROUPS_KEYS.technology,
    NEW_AMENITY_GROUPS_KEYS.accessibility,
  ],
  singleUnit: [NEW_AMENITY_GROUPS_KEYS.common, NEW_AMENITY_GROUPS_KEYS.misc],
};

export const AMENITY_KEYS = {
  // Community amenities
  mduCommunityPetFriendly: 'mduCommunityPetFriendly',
  mduCommunityDogPark: 'mduCommunityDogPark',
  mduCommunityDogWashingStation: 'mduCommunityDogWashingStation',
  mduCommunityLargeDogAllowed: 'mduCommunityLargeDogAllowed',
  mduCommunityMultiplePetsAllowed: 'mduCommunityMultiplePetsAllowed',
  mduCommunityGarages: 'mduCommunityGarages',
  mduCommunityCoveredParking: 'mduCommunityCoveredParking',
  mduCommunityAssignedParkingSpaces: 'mduCommunityAssignedParkingSpaces',
  mduCommunityBikeStorageLockers: 'mduCommunityBikeStorageLockers',
  mduCommunitySharedCarServices: 'mduCommunitySharedCarServices',
  mduCommunityElectricCarChargingStations: 'mduCommunityElectricCarChargingStations',
  mduCommunityPedestrianFriendly: 'mduCommunityPedestrianFriendly',
  mduCommunityBusStop: 'mduCommunityBusStop',
  mduCommunitySharedLaundryRoom: 'mduCommunitySharedLaundryRoom',
  mduCommunityWashersAndDryers: 'mduCommunityWashersAndDryers',
  mduCommunityLaundryService: 'mduCommunityLaundryService',
  mduCommunityDishwasher: 'mduCommunityDishwasher',
  mduCommunityHighEndKitchenAppliances: 'mduCommunityHighEndKitchenAppliances',
  mduCommunityHighEndCountertops: 'mduCommunityHighEndCountertops',
  mduCommunityEnergyEfficientAppliances: 'mduCommunityEnergyEfficientAppliances',
  mduCommunityFitnessCenter: 'mduCommunityFitnessCenter',
  mduCommunitySpa: 'mduCommunitySpa',
  mduCommunityYogaDace: 'mduCommunityYogaDace',
  mduCommunityPool: 'mduCommunityPool',
  mduCommunityPlayground: 'mduCommunityPlayground',
  mduCommunityCommunityCenter: 'mduCommunityCommunityCenter',
  mduCommunityMediaRoom: 'mduCommunityMediaRoom',
  mduCommunityPartyRoom: 'mduCommunityPartyRoom',
  mduCommunityCommunityEventsAndClasses: 'mduCommunityCommunityEventsAndClasses',
  mduCommunityOutdoorAreas: 'mduCommunityOutdoorAreas',
  mduCommunityJoggingWalkingBikePath: 'mduCommunityJoggingWalkingBikePath',
  mduCommunityFamilyPlayground: 'mduCommunityFamilyPlayground',
  mduCommunityCloseToSchools: 'mduCommunityCloseToSchools',
  mduCommunityBabysittingAfterschool: 'mduCommunityBabysittingAfterschool',
  mduCommunityStorage: 'mduCommunityStorage',
  mduCommunitySecurityCameras: 'mduCommunitySecurityCameras',
  mduCommunitySecurityGuard: 'mduCommunitySecurityGuard',
  mduCommunityDoorman: 'mduCommunityDoorman',
  mduCommunityGatedAccess: 'mduCommunityGatedAccess',
  mduCommunityValetTrash: 'mduCommunityValetTrash',
  mduCommunityRecyclingCenter: 'mduCommunityRecyclingCenter',
  mduCommunityDoorstepRecyclingCollection: 'mduCommunityDoorstepRecyclingCollection',
  // Unit Amenities in Community
  mduUnitAirConditioning: 'mduUnitAirConditioning',
  mduUnitFireplace: 'mduUnitFireplace',
  mduUnitPatioOrBalcony: 'mduUnitPatioOrBalcony',
  mduUnitWoodFlooring: 'mduUnitWoodFlooring',
  mduUnitStorage: 'mduUnitStorage',
  mduUnitChargingOutletsWithUsbPorts: 'mduUnitChargingOutletsWithUsbPorts',
  mduUnitSmartControlsForHeatingCooling: 'mduUnitSmartControlsForHeatingCooling',
  mduUnitWifi: 'mduUnitWifi',
  mduUnitHighSpeedInternet: 'mduUnitHighSpeedInternet',
  mduUnitCableOrSatelliteTv: 'mduUnitCableOrSatelliteTv',
  mduUnitOnlineOptionsForLeasingRequests: 'mduUnitOnlineOptionsForLeasingRequests',
  // Single Unit Amenities in Single Family company
  singleFamilyPetFriendly: 'singleFamilyPetFriendly',
  singleFamilyDogPark: 'singleFamilyDogPark',
  singleFamilyLargeDogAllowed: 'singleFamilyLargeDogAllowed',
  singleFamilyMultiplePetsAllowed: 'singleFamilyMultiplePetsAllowed',
  singleFamilyGarage: 'singleFamilyGarage',
  singleFamilyElectricCarChargingStations: 'singleFamilyElectricCarChargingStations',
  singleFamilyPedestrianFriendly: 'singleFamilyPedestrianFriendly',
  singleFamilyDishwasher: 'singleFamilyDishwasher',
  singleFamilyHighEndKitchenAppliances: 'singleFamilyHighEndKitchenAppliances',
  singleFamilyHighEndCountertops: 'singleFamilyHighEndCountertops',
  singleFamilyEnergyEfficientAppliances: 'singleFamilyEnergyEfficientAppliances',
  singleFamilyFitnessCenter: 'singleFamilyFitnessCenter',
  singleFamilySpa: 'singleFamilySpa',
  singleFamilyYogaDace: 'singleFamilyYogaDace',
  singleFamilyPool: 'singleFamilyPool',
  singleFamilyPlayground: 'singleFamilyPlayground',
  singleFamilyCommunityCenter: 'singleFamilyCommunityCenter',
  singleFamilyMediaRoom: 'singleFamilyMediaRoom',
  singleFamilyJoggingWalkingBikePath: 'singleFamilyJoggingWalkingBikePath',
  singleFamilyCloseToSchools: 'singleFamilyCloseToSchools',
  singleFamilyGatedAccess: 'singleFamilyGatedAccess',
  singleFamilyRecyclingCenter: 'singleFamilyRecyclingCenter',
  singleFamilyDoorstepRecyclingCollection: 'singleFamilyDoorstepRecyclingCollection',
  singleFamilyStainlessSteeleKitchenAppliances: 'singleFamilyStainlessSteeleKitchenAppliances',
  singleFamilykitchenIsland: 'singleFamilykitchenIsland',
  singleFamilyBreakfastNook: 'singleFamilyBreakfastNook',
  singleFamilyOpenFloorplan: 'singleFamilyOpenFloorplan',
  singleFamilyCoveredPorch: 'singleFamilyCoveredPorch',
  singleFamilyVaultedCeilings: 'singleFamilyVaultedCeilings',
  singleFamilyLaminateFlooring: 'singleFamilyLaminateFlooring',
  singleFamilyWalkInClosets: 'singleFamilyWalkInClosets',
  singleFamilyWashersAndDryerIncluded: 'singleFamilyWashersAndDryerIncluded',
  singleFamilyFencedYard: 'singleFamilyFencedYard',
  singleFamilyPatio: 'singleFamilyPatio',
  singleFamilyFinishedBasement: 'singleFamilyFinishedBasement',
  singleFamilyTwoCarGarage: 'singleFamilyTwoCarGarage',
  singleFamilyLandscapingIncluded: 'singleFamilyLandscapingIncluded',
  singleFamilySmartHomeControls: 'singleFamilySmartHomeControls',
  singleFamilySecurityServices: 'singleFamilySecurityServices',
  singleFamilyBusStop: 'singleFamilyBusStop',
  singleFamilyAirConditioning: 'singleFamilyAirConditioning',
  singleFamilyFireplace: 'singleFamilyFireplace',
  singleFamilyPatioOrBalcony: 'singleFamilyPatioOrBalcony',
  singleFamilyWoodFlooring: 'singleFamilyWoodFlooring',
  singleFamilyStorage: 'singleFamilyStorage',
  singleFamilyChargingOutletsWithUsbPorts: 'singleFamilyChargingOutletsWithUsbPorts',
  singleFamilyWifi: 'singleFamilyWifi',
  singleFamilyHighSpeedInternet: 'singleFamilyHighSpeedInternet',
  singleFamilyCableOrSatelliteTv: 'singleFamilyCableOrSatelliteTv',
};

export const AMENITY_LABELS: Record<string, string> = {
  // Community amenities
  mduCommunityPetFriendly: 'Pet-Friendly',
  mduCommunityDogPark: 'Dog Park',
  mduCommunityDogWashingStation: 'Dog washing station',
  mduCommunityLargeDogAllowed: 'Large dogs allowed',
  mduCommunityMultiplePetsAllowed: 'Multiple pets allowed',
  mduCommunityGarages: 'Garages',
  mduCommunityCoveredParking: 'Covered parking',
  mduCommunityAssignedParkingSpaces: 'Assigned Parking spaces',
  mduCommunityBikeStorageLockers: 'Bike storage lockers',
  mduCommunitySharedCarServices: 'Shared car services',
  mduCommunityElectricCarChargingStations: 'Electric car charging stations',
  mduCommunityPedestrianFriendly: 'Pedestrian-friendly - Walk Score',
  mduCommunityBusStop: 'Bus stop/Public transportation access',
  mduCommunitySharedLaundryRoom: 'Shared laundry room',
  mduCommunityWashersAndDryers: 'Washers and dryers in the units',
  mduCommunityLaundryService: 'Laundry service',
  mduCommunityDishwasher: 'Dishwasher in unit',
  mduCommunityHighEndKitchenAppliances: 'High-end kitchen appliances',
  mduCommunityHighEndCountertops: 'High-end countertops and finishes',
  mduCommunityEnergyEfficientAppliances: 'Energy-efficient appliances',
  mduCommunityFitnessCenter: 'Fitness center',
  mduCommunitySpa: 'Spa',
  mduCommunityYogaDace: 'Yoga/dance studio',
  mduCommunityPool: 'Pool',
  mduCommunityPlayground: 'Playground',
  mduCommunityCommunityCenter: 'Community Center',
  mduCommunityMediaRoom: 'Media room',
  mduCommunityPartyRoom: 'Party room',
  mduCommunityCommunityEventsAndClasses: 'Community events and classes',
  mduCommunityOutdoorAreas: 'Outdoor areas',
  mduCommunityJoggingWalkingBikePath: 'Jogging/walking/bike path or access to one nearby',
  mduCommunityFamilyPlayground: 'Playground',
  mduCommunityCloseToSchools: 'Close to schools',
  mduCommunityBabysittingAfterschool: 'Babysitting/Afterschool services',
  mduCommunityStorage: 'Storage',
  mduCommunitySecurityCameras: 'Security Cameras',
  mduCommunitySecurityGuard: 'Security gurad',
  mduCommunityDoorman: 'Doorman',
  mduCommunityGatedAccess: 'Gated access',
  mduCommunityValetTrash: 'Valet trash',
  mduCommunityRecyclingCenter: 'Recycling center',
  mduCommunityDoorstepRecyclingCollection: 'Doorstep recycling collection',
  //   Community Unit Amenities
  mduUnitAirConditioning: 'Air-conditioning',
  mduUnitFireplace: 'Fireplace',
  mduUnitPatioOrBalcony: 'Patio or balcony',
  mduUnitWoodFlooring: 'Wood flooring',
  mduUnitStorage: 'Storage in unit',
  mduUnitChargingOutletsWithUsbPorts: 'Charging outlets with USB ports',
  mduUnitSmartControlsForHeatingCooling: 'Smart Controls for Heating/Cooling',
  mduUnitWifi: 'Wifi',
  mduUnitHighSpeedInternet: 'High-Speed Internet',
  mduUnitCableOrSatelliteTv: 'Cable or Satellite TV',
  mduUnitOnlineOptionsForLeasingRequests: 'Online Options for leasing, paying rent, and making maintenance requests',
  // Single Unit Amenities in Single Family company
  singleFamilyPetFriendly: 'Pet-Friendly',
  singleFamilyLargeDogAllowed: 'Large dogs allowed',
  singleFamilyMultiplePetsAllowed: 'Multiple pets allowed',
  singleFamilyDogPark: 'Dog Park',
  singleFamilyPedestrianFriendly: 'Pedestrian-friendly',
  singleFamilyFitnessCenter: 'Community Fitness center',
  singleFamilySpa: 'Spa',
  singleFamilyYogaDace: 'Yoga/dance studio',
  singleFamilyPool: 'Community pool',
  singleFamilyPlayground: 'Playground',
  singleFamilyCommunityCenter: 'Community Center',
  singleFamilyMediaRoom: 'Media room',
  singleFamilyJoggingWalkingBikePath: 'Jogging/walking/bike path',
  singleFamilyCloseToSchools: 'Close to schools',
  singleFamilyGatedAccess: 'Gated access',
  singleFamilyRecyclingCenter: 'Recycling center',
  singleFamilyDoorstepRecyclingCollection: 'Doorstep recycling collection',
  singleFamilySecurityServices: 'Security Services',
  singleFamilyBusStop: 'Bus stop/Public transportation access',
  singleFamilyGarage: 'Garage',
  singleFamilyDishwasher: 'Dishwasher',
  singleFamilyHighEndKitchenAppliances: 'High-end kitchen appliances',
  singleFamilyHighEndCountertops: 'High-end countertops and finishes',
  singleFamilyStainlessSteeleKitchenAppliances: 'Stainless Steele kitchen appliances',
  singleFamilykitchenIsland: 'Kitchen Island',
  singleFamilyBreakfastNook: 'Breakfast Nook',
  singleFamilyOpenFloorplan: 'Open Floorplan',
  singleFamilyCoveredPorch: 'Covered Porch',
  singleFamilyVaultedCeilings: 'Vaulted Ceilings',
  singleFamilyLaminateFlooring: 'Laminate Flooring',
  singleFamilyWalkInClosets: 'Walk-in closets',
  singleFamilyWashersAndDryerIncluded: 'Washers and Dryer included',
  singleFamilyFencedYard: 'Fenced Yard',
  singleFamilyPatio: 'Patio',
  singleFamilyFinishedBasement: 'Finished Basement',
  singleFamilyTwoCarGarage: '2 Car Garage',
  singleFamilyLandscapingIncluded: 'Landscaping Included',
  singleFamilyAirConditioning: 'Air-conditioning',
  singleFamilyFireplace: 'Fireplace',
  singleFamilyPatioOrBalcony: 'Patio or balcony',
  singleFamilyWoodFlooring: 'Wood flooring',
  singleFamilyStorage: 'Storage',
  singleFamilyElectricCarChargingStations: 'Electric car charging stations',
  singleFamilyEnergyEfficientAppliances: 'Energy-efficient appliances',
  singleFamilySmartHomeControls: 'Smart Home Controls',
  singleFamilyChargingOutletsWithUsbPorts: 'Charging outlets with USB ports',
  singleFamilyWifi: 'Wifi',
  singleFamilyHighSpeedInternet: 'High-Speed Internet',
  singleFamilyCableOrSatelliteTv: 'Cable or Satellite TV',
};

export const MASTER_AMENITIES_GROUP_KEYS = {
  grounds: 'grounds',
  livingSpace: 'livingSpace',
};

export const MASTER_AMENITIES_GROUP_LABELS: Record<string, string> = {
  grounds: 'Grounds / Property Amenities',
  livingSpace: 'Living Space Amenities',
};

export const NEW_AMENITY_KEYS = {
  //sports
  fitnessCenter: 'fitnessCenter',
  tennisCourt: 'tennisCourt',
  basketballCourt: 'basketballCourt',
  indoorAthleticField: 'indoorAthleticField',
  outdoorAthleticField: 'outdoorAthleticField',
  squashCourt: 'squashCourt',
  rockClimbingWall: 'rockClimbingWall',
  skatepark: 'skatepark',
  golfSimulator: 'golfSimulator',
  //swimSpa
  swimmingPool: 'swimmingPool',
  lapPool: 'lapPool',
  splashPool: 'splashPool',
  kidsPool: 'kidsPool',
  sunDeck: 'sunDeck',
  //children
  playground: 'playground',
  toddlersPlayArea: 'toddlersPlayArea',
  kidsPartyRoom: 'kidsPartyRoom',
  closeToSchools: 'closeToSchools',
  babysittingAfterschoolServices: 'babysittingAfterschoolServices',
  //pets
  petFriendly: 'petFriendly',
  dogPark: 'dogPark',
  dogWashingStation: 'dogWashingStation',
  dogWashingSalon: 'dogWashingSalon',
  dogTrainingStudio: 'dogTrainingStudio',
  largeDogsAllowed: 'largeDogsAllowed',
  multiplePetsAllowed: 'multiplePetsAllowed',
  //leisureSocial
  clubHouse: 'clubHouse',
  communityCenter: 'communityCenter',
  clubRoom: 'clubRoom',
  enclosedCateringKitchen: 'enclosedCateringKitchen',
  bowlingAlley: 'bowlingAlley',
  //creative
  artStudio: 'artStudio',
  musicStudio: 'musicStudio',
  audioVisualStudio: 'audioVisualStudio',
  videoAndPhotographyStudio: 'videoAndPhotographyStudio',
  gardeningStudio: 'gardeningStudio',
  //laundry
  sharedLaundryRoom: 'sharedLaundryRoom',
  washersAndDryersInTheUnits: 'washersAndDryersInTheUnits',
  laundryService: 'laundryService',
  //transportation
  controlledAccessParking: 'controlledAccessParking',
  bikeParking: 'bikeParking',
  valetParking: 'valetParking',
  garages: 'garages',
  coveredParking: 'coveredParking',
  //security
  securityAccess: 'securityAccess',
  fullTimeSecurity: 'fullTimeSecurity',
  gatedProperty: 'gatedProperty',
  gatedCommunity: 'gatedCommunity',
  motionSensorLighting: 'motionSensorLighting',
  //onsite
  onSiteRestaurants: 'onSiteRestaurants',
  onSiteRetailSpaces: 'onSiteRetailSpaces',
  clubhouseWithLoungeSeating: 'clubhouseWithLoungeSeating',
  conferenceRooms: 'conferenceRooms',
  resortStylePools: 'resortStylePools',
  //accessibility
  handicapAccessible: 'handicapAccessible',
  extraWideDoorways: 'extraWideDoorways',
  ramps: 'ramps',
  grabBars: 'grabBars',
  walkInTubsAndShowers: 'walkInTubsAndShowers',
  lowerCounterHeights: 'lowerCounterHeights',
  //technology
  intelligentLivingReady: 'intelligentLivingReady',
  smartHomeReady: 'smartHomeReady',
  smartLocks: 'smartLocks',
  nestThermostat: 'nestThermostat',
  smartThermostat: 'smartThermostat',
  //common
  usbOutlets: 'usbOutlets',
  keylessElectronicUnitEntry: 'keylessElectronicUnitEntry',
  keylessEntryDoorLocks: 'keylessEntryDoorLocks',
  wifiIncluded: 'wifiIncluded',
  oversizedBalconies: 'oversizedBalconies',
  //misc
  storage: 'storage',
  securityCameras: 'securityCameras',
  securityGuard: 'securityGuard',
  doorman: 'doorman',
  gatedAccess: 'gatedAccess',
};

export const NEW_AMENITY_LABELS: Record<string, string> = {
  //sports
  fitnessCenter: 'Fitness Cente',
  tennisCourt: 'Tennis Court',
  basketballCourt: 'Basketball Court',
  indoorAthleticField: 'Indoor Athletic Field',
  outdoorAthleticField: 'Outdoor Athletic Field',
  squashCourt: 'Squash Court',
  rockClimbingWall: 'Rock Climbing Wal',
  skatepark: 'Skatepark',
  golfSimulator: 'Golf Simulator',
  //swimSpa
  swimmingPool: 'Swimming Pool',
  lapPool: 'Lap Poo',
  splashPool: 'Splash Pool',
  kidsPool: "Kid's Pool",
  sunDeck: 'Sun Deck',
  //children
  playground: 'Playground',
  toddlersPlayArea: 'Toddler’s Play Area',
  kidsPartyRoom: 'Kids Party Room',
  //family
  closeToSchools: 'Close to schools',
  babysittingAfterschoolServices: 'Babysitting/Afterschool services',
  //pets
  petFriendly: 'Pet-Friendly',
  dogPark: 'Dog park',
  dogWashingStation: 'Dog washing station',
  dogWashingSalon: 'Dog Washing Salon',
  dogTrainingStudio: 'Dog Training Studio',
  largeDogsAllowed: 'Large dogs allowed',
  multiplePetsAllowed: 'Multiple pets allowed',
  //leisureSocial
  clubHouse: 'Club House',
  communityCenter: 'Community Center',
  clubRoom: 'Club Room',
  enclosedCateringKitchen: 'Enclosed Catering Kitchen',
  bowlingAlley: 'Bowling Alley',
  //creative
  artStudio: 'Art Studio',
  musicStudio: 'Music Studio',
  audioVisualStudio: 'Audio/Visual Studio',
  videoAndPhotographyStudio: 'Video and Photography Studio',
  gardeningStudio: 'Gardening Studio',
  //laundry
  sharedLaundryRoom: 'Shared laundry room',
  washersAndDryersInTheUnits: 'Washers and dryers in the units',
  laundryService: 'Laundry service',
  //transportation
  controlledAccessParking: 'Controlled Access Parking',
  bikeParking: 'Bike Parking',
  valetParking: 'Valet Parking',
  garages: 'Garages',
  coveredParking: 'Covered parking',
  //security
  securityAccess: 'Security Access',
  fullTimeSecurity: '24/7 Security',
  gatedProperty: 'Gated Property',
  gatedCommunity: 'Gated Community',
  motionSensorLighting: 'Motion Sensor Lighting',
  //onsite
  onSiteRestaurants: 'On-Site Restaurants',
  onSiteRetailSpaces: 'On-Site Retail Spaces',
  clubhouseWithLoungeSeating: 'Clubhouse with Lounge Seating',
  conferenceRooms: 'Conference Rooms',
  resortStylePools: 'Resort-Style Pools',
  //accessibility
  handicapAccessible: 'Handicap Accessible',
  extraWideDoorways: 'Extra-wide doorways',
  ramps: 'Ramps',
  grabBars: 'Grab bars',
  walkInTubsAndShowers: 'Walk-in tubs and showers',
  lowerCounterHeights: 'Lower counter heights',
  //technology
  intelligentLivingReady: 'Intelligent Living Ready',
  smartHomeReady: 'Smart Home Ready',
  smartLocks: 'Smart Locks',
  nestThermostat: 'Nest Thermostat',
  smartThermostat: 'Smart thermostat',
  //common
  usbOutlets: 'USB Outlets',
  keylessElectronicUnitEntry: 'Keyless Electronic Unit Entry',
  keylessEntryDoorLocks: 'Keyless Entry Door Locks',
  wifiIncluded: 'Wi-Fi Included',
  oversizedBalconies: 'Oversized Balconies',
  //misc
  storage: 'Storage',
  securityCameras: 'Security cameras',
  securityGuard: 'Security guard',
  doorman: 'Doorman',
  gatedAccess: 'Gated access',
};
