import gql from 'graphql-tag';

export const BIDDER_AWARD_MODAL_BID_MUTATION = gql`
  mutation BidderAwardModalBidMutation($filter: BidKeyFilter, $data: BidUpdateInput!) {
    bidUpdate(filter: $filter, data: $data) {
      id
    }
  }
`;

export const BIDDER_AWARD_MODAL_BID_QUERY = gql`
  query BidderAwardModalBidQuery($id: ID!) {
    bid(id: $id) {
      id
      offer {
        id
        historyOfferSingleUnit {
          id
          name
        }
      }
      rentAmount
      moveinDate
      termsOfLease
      renter {
        id
        phone {
          code
          number
        }
        address {
          street1
          street2
          state
          country
          city
          zip
        }
        user {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;
