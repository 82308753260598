import React from 'react';
import * as R from 'ramda';
import { Result } from 'antd';

import { ApolloError } from 'apollo-client';
import errorCodes from '@8base/error-codes';

import { Routes } from '@/routes';
import { Loader } from '@/components';
import { useMobileDetect } from 'livo-components/src/hooks';
import { useCurrentUserBlockedSub } from '@/shared/hooks';
import { Grid } from '@/components';

const { REACT_APP_PROD, REACT_APP_PROD_MOBILE } = process.env as any;

export const ApplicationContent = ({ appLoading, appError }: { appLoading: boolean; appError?: ApolloError }) => {
  // Fetch data now and store them to cache to use them later
  const { userData } = useCurrentUserBlockedSub();

  const { isMobile } = useMobileDetect();

  const { loading: userLoading } = userData;

  const loading = appLoading || userLoading;

  if (R.path(['graphQLErrors', 0, 'code'], appError) === errorCodes.InternalErrorCode) {
    return (
      <Grid.Layout stretch alignItems="center">
        <Result
          status="500"
          title="Internal Error"
          subTitle="Oops, an error has occurred! Don't worry, we've been reported about that."
        />
      </Grid.Layout>
    );
  }

  const redirectToMobile = isMobile() && Boolean(REACT_APP_PROD) && Boolean(REACT_APP_PROD_MOBILE);
  if (isMobile()) {
    try {
      if (Boolean(REACT_APP_PROD) && Boolean(REACT_APP_PROD_MOBILE)) {
        // eslint-disable-next-line no-console
        console.warn('mobile-prod, trying to redirect');
        const tail = (window.location.pathname + window.location.search || '/').substring(1);

        try {
          window.location.replace(REACT_APP_PROD_MOBILE + tail);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          window.location = REACT_APP_PROD_MOBILE + tail;
        }
      } else {
        // eslint-disable-next-line no-console
        console.warn('prod-variables are not defined');
      }
    } catch {
      // eslint-disable-next-line no-console
      console.warn('mobile-prod, redirect failed');
    }
  } else {
    // eslint-disable-next-line no-console
    console.warn('isnt-mobile', navigator.userAgent);
  }

  return loading || redirectToMobile ? <Loader stretch /> : <Routes />;
};
