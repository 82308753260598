import { useQuery } from 'react-apollo';
import { SingleUnit, Maybe } from '@/shared/types/graphql';
import { USER_STATUSES } from '@/shared/constants';
import { PROPERTY_INFO_SINGLE_UNIT_QUERY } from '@/shared/graphql';

export const useDataSingleUnit = ({ singleUnitId }: { singleUnitId?: Maybe<string> }) => {
  const { data, loading } = useQuery<{ singleUnit: SingleUnit }>(PROPERTY_INFO_SINGLE_UNIT_QUERY, {
    skip: !singleUnitId,
    variables: {
      id: singleUnitId,
      userFilter: {
        status: {
          not_equals: USER_STATUSES.invitationPending,
        },
      },
    },
  });

  return { singleUnit: data?.singleUnit as SingleUnit, singleUnitLoading: loading };
};
