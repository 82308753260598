import { Maybe } from '@/shared/types/graphql';

export const parsePhone = (value?: Maybe<string>) => {
  if (!value) {
    return;
  }

  const phoneNumber = value.replace(/[^0-9+]+/g, '');

  return {
    code: '1',
    number: phoneNumber.startsWith('+1') ? phoneNumber.substring(2) : phoneNumber,
  };
};
