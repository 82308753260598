import React from 'react';
import styled from '@emotion/styled';

import { DotsProps } from './types';

const Container = styled.div`
  display: grid;
  grid-gap: 7px;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  padding-top: 4px;
`;

// TODO: use colours from theme
const Dot = styled.button<{ isCurrent: boolean }>`
  height: 9px;
  width: 9px;
  border: 1px solid #f37a22;
  background-color: ${({ isCurrent }) => (isCurrent ? '#f37a22' : '#ffffff')};
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  transform: scale(${({ isCurrent }) => (isCurrent ? '1.3' : '1')});
  transition: 0.4s;
`;

export const Dots = ({ dotsFunctions, currentStepNumber }: DotsProps) => {
  return (
    <Container>
      {dotsFunctions.map((dotFunction, dotNumber) => (
        <Dot key={dotNumber} isCurrent={dotNumber === currentStepNumber} onClick={dotFunction} />
      ))}
    </Container>
  );
};
