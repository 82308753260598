import React from 'react';
import { CompanyWizard } from '@/components';
import { initialValues, steps } from './constants';
import { StepProps } from '@/components/CompanyWizard/types';

type CreateCompanyWizardRenderProps = {
  stepNumber: number;
  setStepNumber: (callback: (stepNumber: number) => number) => void;
  formProps: any;
  steps: StepProps[];
};

type CreateCompanyWizardProps = {
  onSubmit: (data: any) => void;

  children: (props: CreateCompanyWizardRenderProps) => React.ReactNode;
};

export const CreateCompanyWizard: React.FC<CreateCompanyWizardProps> = props => {
  return (
    <CompanyWizard {...props} type="CREATE" tableSchemaName="Company" initialValues={initialValues} steps={steps} />
  );
};
