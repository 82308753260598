import React from 'react';
import { AsyncContent, CustomCard } from '@/components';
import { OfferCardGrid } from '@/routes/rentals/PageCompanyLiveOffers/components/OfferCardGrid';
import { useQuery } from 'react-apollo';
import { RENTAL_OFFERS_LIST_QUERY } from '@/routes/rentals/PageCompanyLiveOffers/queries';
import { useParams } from 'react-router-dom';
import { OfferListResponse } from '@/shared/types/graphql';
import { useQueryParams } from '@/shared/hooks';
import CustomCardHeader from '@/routes/rentals/PageCompanyLiveOffers/components/CustomCardHeader';

export const PageCompanyLiveTransactions = () => {
  const { id: companyId } = useParams<{ id: string }>();
  const [queryParams, setQueryParams] = useQueryParams<{
    sortBy: string;
    sortOrder: string;
  }>(100, {
    sortBy: 'createdAt',
    sortOrder: 'DESC',
  });

  const { data, loading } = useQuery<{ offersList: OfferListResponse }>(RENTAL_OFFERS_LIST_QUERY, {
    fetchPolicy: 'network-only',
    skip: !queryParams?.sortBy,
    variables: {
      sort: {
        [queryParams.sortBy]: queryParams.sortOrder,
      },
      filter: { activeOfferSingleUnit: { company: { id: { equals: companyId } } }, status: { equals: 'live' } },
    },
  });

  const offers = data?.offersList?.items || [];

  return (
    <AsyncContent loading={loading}>
      <CustomCard header={<CustomCardHeader queryParams={queryParams} setQueryParams={setQueryParams} />}>
        <OfferCardGrid offers={offers} />
      </CustomCard>
    </AsyncContent>
  );
};
