import React, { ChangeEvent } from 'react';

import { CustomCard, TableHeader } from '@/components';
import { useSearch } from '@/shared/hooks';

import { CommunitiesTable } from './CommunitiesTable';

export const Communities = () => {
  const [{ search, throttledSearch }, setSearch] = useSearch();

  const onChange = React.useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearch(event.currentTarget.value);
    },
    [setSearch],
  );

  return (
    <CustomCard
      padding="none"
      header={
        <TableHeader
          title="Properties"
          searchProps={{ query: search, placeholder: 'Search by Property Name', onChange }}
        />
      }
    >
      <CommunitiesTable query={throttledSearch} />
    </CustomCard>
  );
};
