import gql from 'graphql-tag';

export const PORTFOLIO_COMMUNITIES_QUERY = gql`
  query RentalCommunitiesList($first: Int, $skip: Int, $filter: CommunityFilter, $sort: [CommunitySort!]) {
    communitiesList(first: $first, skip: $skip, filter: $filter, sort: $sort) {
      items {
        id
        name
        website
        updatedAt
        createdBy {
          id
          firstName
          lastName
        }
        status

        singleUnit(filter: { activeOffer: { OR: [{ status: { equals: "live" } }, { status: { equals: "ended" } }] } }) {
          items {
            id
            name
            activeOffer {
              id
              status
            }
          }
          count
        }
      }
      count
    }
  }
`;
