import React from 'react';
import { useDialogState } from '@/shared/hooks';
import { Dialog } from './Dialog';

export const BIDDER_AWARD_DIALOG = 'BIDDER_AWARD_DIALOG';

export const BidderAwardDialog = () => {
  const { close, isVisible, props } = useDialogState(BIDDER_AWARD_DIALOG);

  if (!isVisible) {
    return null;
  }

  return <Dialog {...props} close={close} isVisible={isVisible} />;
};
