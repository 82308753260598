import styled from '@/theme/styled';
import { css } from '@emotion/core';

import { LEFT_PADDING } from './constants';

const SidebarContainer = styled.aside<{ isSidebarOpen?: boolean }>`
  flex-basis: ${({ isSidebarOpen }) => (isSidebarOpen ? '240px' : '80px')};
  max-width: ${({ isSidebarOpen }) => (isSidebarOpen ? '240px' : '80px')};
  background-color: ${props => props.theme.vars.black};
  overflow-x: hidden;
  transition: 0.3s;

  &::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #aaa;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.vars.primaryColor};
  }

  .livo-sidebar {
    .ant-menu-item > a {
      color: #fff;

      .anticon + span {
        display: inline-block;
        transform: translateY(-2px);
      }
    }

    .ant-menu-submenu {
      .ant-menu-submenu-title {
        .anticon + span {
          display: inline-block;
          transform: translateY(-2px);
        }
      }
    }
  }

  .livo-sidebar > .ant-menu-item:active,
  .livo-sidebar > .ant-menu-item:hover {
    background-color: ${({ theme }) => theme.vars.black3};
  }

  .livo-sidebar {
    > .ant-menu-submenu {
      .ant-menu-item:active,
      .ant-menu-item:hover {
        background-color: transparent;

        > a {
          color: ${({ theme }) => theme.vars.primaryColor};
        }
      }
    }
  }

  .livo-sidebar.ant-menu {
    background-color: transparent;
    color: #fff;
  }

  .livo-sidebar.ant-menu-inline,
  .livo-sidebar.ant-menu-vertical {
    border-right: none;
  }

  .livo-sidebar.ant-menu-inline,
  .livo-sidebar.ant-menu-vertical {
    .ant-menu-item:nth-child(n) {
      margin: 0;
    }
  }

  .livo-sidebar.ant-menu-inline,
  .livo-sidebar.ant-menu-vertical {
    .ant-menu-submenu-title {
      margin: 0;
      color: #fff;
    }
  }

  .livo-sidebar.ant-menu-inline,
  .livo-sidebar.ant-menu-vertical {
    > .ant-menu-item {
      height: 53px;
      line-height: 53px;
    }
  }

  .livo-sidebar.ant-menu-inline,
  .livo-sidebar.ant-menu-vertical {
    > .ant-menu-submenu {
      > .ant-menu-submenu-title {
        height: 53px;
        line-height: 53px;
      }
    }
  }

  .livo-sidebar.ant-menu:not(.ant-menu-horizontal) {
    .ant-menu-item-selected {
      background-color: ${({ theme }) => theme.vars.black3};
    }
  }

  .livo-sidebar .ant-menu-item-selected {
    > a {
      color: #fff;
    }

    .ant-menu-item-selected {
      > a:hover {
        color: #fff;
      }
    }
  }

  .livo-sidebar.ant-menu-inline {
    .ant-menu-submenu-open {
      background-color: ${({ theme }) => theme.vars.black3};

      .ant-menu-submenu-title {
        border-bottom: 1px solid #979797;
      }
    }
  }

  .livo-sidebar.ant-menu-inline,
  .livo-sidebar.ant-menu-vertical {
    .ant-menu-submenu-open {
      .ant-menu-sub {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    .ant-menu-submenu-selected,
    .ant-menu-item-selected {
      .ant-menu-submenu-title {
        position: relative;

        &:after {
          content: none;
        }
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          display: block;
          width: 4px;
          height: 100%;
          border: none;
          border-radius: 5px;
          background-color: #fff;
        }
      }
    }

    .ant-menu-sub .ant-menu-item-selected,
    .ant-menu-sub-item-selected .ant-menu-submenu-title {
      &:after {
        content: none;
      }
      &:before {
        content: none;
      }

      a,
      a:hover {
        color: ${({ theme }) => theme.vars.primaryColor};
      }
    }

    .ant-menu-first-level-item.ant-menu-sub-item-selected .ant-menu-submenu-title,
    .ant-menu-first-level-item.ant-menu-item-selected {
      &:after {
        content: none;
      }
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 4px;
        height: 100%;
        border: none;
        border-radius: 5px;
        background-color: #fff;
      }
    }
  }

  .livo-sidebar {
    > .ant-menu-item {
      padding: 0 30px !important;
    }
  }

  .livo-sidebar {
    .ant-menu-submenu-title {
      padding: 0 !important;
    }
  }

  .livo-sidebar.ant-menu-inline,
  .livo-sidebar.ant-menu-vertical {
    .ant-menu-item-selected:after {
      transform: none;
      opacity: 1;
    }
  }

  .livo-sidebar {
    .ant-menu-submenu {
      > .ant-menu {
        background-color: transparent;
      }
    }
  }

  .livo-sidebar {
    .ant-menu-submenu-selected,
    .ant-menu-sub-item-selected {
      background-color: ${({ theme }) => theme.vars.black3};
    }
  }

  .livo-sidebar {
    .ant-menu-submenu-title {
      &:active {
        background-color: inherit;
      }
    }
  }

  .livo-sidebar.ant-menu-inline-collapsed {
    > .ant-menu-item .anticon + span {
      max-width: none;
    }

    > .ant-menu-submenu {
      > .ant-menu-submenu-title {
        .anticon + span {
          max-width: none;
        }
      }
    }
  }

  .livo-sidebar {
    .ant-menu-item {
      .anticon {
        margin-right: 15px;
      }
    }

    .ant-menu-submenu-title {
      .anticon {
        margin-right: 15px;
      }
    }
  }
`;

const LogoSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding-left: ${LEFT_PADDING}px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;

  > svg {
    position: absolute;
  }
`;

const NavParagraph = styled.p<{ isSidebarOpen?: boolean }>`
  line-height: 42px;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #929ea8;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  transform: translateX(30px);
  transition: 0.2s;
  max-width: 185px;

  ${({ isSidebarOpen }) =>
    !isSidebarOpen &&
    css`
      max-width: 50px;
      transform: translateX(18px);
    `}
`;

const CountTag = styled.div<{ isActive?: boolean; isSidebarOpen?: boolean }>`
  width: 20px;
  height: 15px;
  line-height: 15px;
  margin-left: auto;
  margin-right: ${({ isSidebarOpen }) => isSidebarOpen && '20px'};
  text-align: center;
  border-radius: 5px;
  font-size: 1rem;
  background-color: ${({ theme, isActive }) => (isActive ? theme.vars.primaryColor : '#fff')};
  color: ${({ theme }) => theme.vars.black};

  ${({ isSidebarOpen, theme, isActive }) =>
    isSidebarOpen
      ? css`
          background-color: ${isActive ? theme.vars.primaryColor : '#fff'};
          color: ${isActive ? '#fff' : theme.vars.black};
        `
      : css`
          background-color: ${isActive ? theme.vars.primaryColor : theme.vars.black};
          color: #fff;
        `}
`;

const CommunityCountTag = styled.span`
  width: 20px;
  height: 15px;
  display: inline-block;
  line-height: 15px;
  margin-left: 15px;
  margin-right: auto;
  text-align: center;
  border-radius: 5px;
  font-size: 1rem;
  color: ${({ theme }) => theme.vars.black};
  background-color: #fff;
  vertical-align: 0.27em;
`;

export { LEFT_PADDING, SidebarContainer, LogoSection, NavParagraph, CountTag, CommunityCountTag };
