import React from 'react';

interface LoadingContextProps {
  loading: boolean;
  setLoading: (value: boolean) => void;
}

const loadingContext = React.createContext<LoadingContextProps>(undefined!);

type LoadingProviderProps = {
  children: React.ReactChild | React.ReactChild[];
};

const LoadingProvider: React.FC<LoadingProviderProps> = ({ children }) => {
  const [loading, setLoading] = React.useState(false);

  return <loadingContext.Provider value={{ loading, setLoading }}>{children}</loadingContext.Provider>;
};

LoadingProvider.displayName = 'LoadingProvider';

export { LoadingProvider, loadingContext };
