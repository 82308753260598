import { matchPath } from 'react-router';
import { GetBreadcrumbs } from './types';

export const getPaths = (pathname: string): [string] =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  //@ts-ignore
  pathname
    .replace(/\/$/, '')
    .split('/')
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    //@ts-ignore
    .reduce((result, path, index) => [...result, index < 2 ? `/${path}` : `${result[result.length - 1]}/${path}`], []);

export const getBreadcrumbs: GetBreadcrumbs = (pathname, routes) => {
  const paths = getPaths(pathname);
  const breadcrumbs = paths.reduce((result: any, path) => {
    const matchedRoute = routes.find(route => matchPath(path, { path: route.path, ...(route.matchOptions || {}) }));

    if (matchedRoute) {
      const match = matchPath(path, { path: matchedRoute.path, ...(matchedRoute.matchOptions || {}) });

      result = [...result, { ...matchedRoute, originalPath: path, match }];
    }

    return result;
  }, []);

  return breadcrumbs;
};
