import React from 'react';
import styled from '@emotion/styled';

const Section = styled.section``;

const Header = styled.header`
  margin-bottom: 24px;
  font-size: 1.4rem;
  font-weight: 500;
  color: #323c47;
`;

type InformationSection = {
  header: React.ReactNode;
  children: React.ReactNode;
};

export const InformationSection = ({ header, children }: InformationSection) => (
  <Section>
    {typeof header === 'string' ? <Header>{header}</Header> : header}
    {children}
  </Section>
);
