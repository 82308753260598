import React from 'react';
import { Tabs } from 'antd';
import { CustomCard, Grid, Heading } from '@/components';
import { WesternReporting } from '@/routes/management/company/entity/settings/integrations/components';

const { TabPane } = Tabs;

export const Integrations = () => {
  const activeTab = 'westernReporting';

  const tabs = {
    westernReporting: 'westernReporting',
  };

  const tabsLabels = {
    westernReporting: 'Western Reporting',
  };

  return (
    <Tabs className="livo-tabs" animated={false} defaultActiveKey={tabs.westernReporting} activeKey={activeTab}>
      <TabPane tab="Western Reporting" key={tabs.westernReporting}>
        <CustomCard
          header={
            <Grid.Layout gap="24px" columns="1fr" alignItems="center" stretch>
              <Grid.Box>
                <Heading css={{ textAlign: 'center' }} type="h1">
                  {tabsLabels.westernReporting} Integration
                </Heading>
              </Grid.Box>
            </Grid.Layout>
          }
        >
          <WesternReporting />
        </CustomCard>
      </TabPane>
    </Tabs>
  );
};
