import { FieldValidator } from 'final-form';

export const required: FieldValidator<any> = (value: any) => (value ? undefined : 'Required');

export const trimSpaces = (value: any) => (value !== undefined && value !== null ? value.trim().length !== 0 : true);

export const emailRegexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;

export const email: FieldValidator<string> = value =>
  value && !emailRegexp.test(value) ? 'Invalid email address' : null;

export const phone: FieldValidator<string> = value =>
  value && !/^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/im.test(value)
    ? 'Invalid phone number'
    : null;

export const phoneNumber: FieldValidator<string> = value =>
  value && !/^[0-9]{10}$/.test(value) ? 'Invalid phone number' : null;

const urlRegexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/i;

export const url: FieldValidator<string> = value => (value && !urlRegexp.test(value) ? 'Invalid url' : null);

export const urlRegexpCalendly = /^(?:https?:\/\/)?(?:www\.)?calendly.com([\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)/i;

export const urlCalendly: FieldValidator<string> = value =>
  value && !urlRegexpCalendly.test(value) ? 'Invalid Calendly url' : null;

export const composeValidators = (...validators: FieldValidator<any>[]): FieldValidator<any> => (
  value,
  allValues,
  meta,
) => validators.reduce((error, validator) => error || validator(value, allValues, meta), undefined);
