import * as yup from 'yup';
import { trimSpaces } from '@/shared/validators';

export const AddressSchema = yup
  .object({
    country: yup
      .string()
      .nullable()
      .required('Country is required'),
    street1: yup
      .string()
      .test({
        test: trimSpaces,
        message: "Can't be empty",
      })
      .nullable()
      .required('Address is required'),
    street2: yup
      .string()
      .test({
        test: trimSpaces,
        message: "Can't be empty",
      })
      .nullable(),
    zip: yup
      .string()
      .nullable()
      .test({
        test: trimSpaces,
        message: "Can't be empty",
      })
      .required('Zip code is required'),
    city: yup
      .string()
      .test({
        test: trimSpaces,
        message: "Can't be empty",
      })
      .nullable()
      .required('City is required'),
    state: yup
      .string()
      .nullable()
      .required('State is required'),
  })
  .nullable()
  .required();

export const PhoneSchemaRequired = yup
  .object({
    code: yup
      .string()
      .length(1)
      .nullable()
      .required('Code is required'),
    number: yup
      .string()
      .matches(/^[0-9]{10}$/, { message: 'Number is invalid' })
      .nullable()
      .required('Number is required'),
  })
  .nullable()
  .required('Phone is required');

export const urlRegexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/i;

export const urlRegexpCalendly = /^(?:https?:\/\/)?(?:www\.)?calendly.com([\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)/i;

export const UrlSchema = yup
  .string()
  .nullable()
  .matches(urlRegexp, { message: 'Url is invalid' });
