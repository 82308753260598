import gql from 'graphql-tag';

export const OFFER_AWARD_QUERY = gql`
  query OfferAwardQuery($id: ID!) {
    offer(id: $id) {
      id
      offerId
    }
  }
`;
