import gql from 'graphql-tag';

export const COMMUNITY_DELETE_MUTATION = gql`
  mutation CommunityDelete($id: ID!) {
    communityDelete(filter: { id: $id }, force: true) {
      success
    }
  }
`;

export const SINGLE_UNIT_DELETE_MUTATION = gql`
  mutation SingleUnitDelete($id: ID!) {
    singleUnitDelete(filter: { id: $id }, force: true) {
      success
    }
  }
`;
