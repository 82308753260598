import React from 'react';
import { Select, Avatar } from 'antd';

import { FormField, FlexLayout } from '@/components';
import { InputType, MetaType } from '@/shared/types';
import { getInitials } from '@/shared/utils';
import { User } from '@/shared/types/graphql';

const { Option, OptGroup } = Select;

type OptionItem = {
  label: string;
  value: string | number;
  initials: string;
  downloadUrl?: string | null;
  disabled?: boolean;
};

type GroupOptionItem = {
  groupName: string;
  options: Array<OptionItem>;
};

type SelectFieldProps = {
  label?: React.ReactNode;
  note?: string;
  options?: Array<OptionItem>;
  groupedOptions?: Array<GroupOptionItem>;
  placeholder?: string;
  stretch?: boolean;
  showSearch?: boolean;
  width?: string | number;
  loading?: boolean;
  mode?: 'default' | 'multiple' | 'tags';
  input: InputType;
  meta?: MetaType;
  disabled?: boolean;
  allowClear?: boolean;
  'data-e2e-id'?: string;
};

const renderOptions = (options: Array<OptionItem>, testId?: string) => {
  return options?.map(({ label, value, initials, downloadUrl, disabled = false }) => (
    <Option key={value} value={value} data-e2e-id={`${testId}[${label}]`} disabled={disabled}>
      <FlexLayout align="center">
        <Avatar style={{ transform: 'scale(0.8)' }} src={downloadUrl || undefined}>
          {initials}
        </Avatar>
        <div>{label}</div>
      </FlexLayout>
    </Option>
  ));
};

const SelectField = ({
  options,
  groupedOptions,
  placeholder,
  label,
  note,
  stretch,
  showSearch,
  width,
  loading,
  mode,
  input,
  meta,
  disabled,
  allowClear,
  ...rest
}: SelectFieldProps) => {
  const { value, onChange } = input;

  const selectWidth = stretch ? '100%' : width ? width : 150;

  return (
    <FormField label={label} note={note} meta={meta}>
      <Select
        onChange={onChange}
        showSearch={showSearch}
        placeholder={placeholder}
        value={value || undefined}
        loading={loading}
        mode={mode}
        style={{ width: selectWidth }}
        disabled={disabled}
        allowClear={allowClear}
        {...rest}
      >
        {options && renderOptions(options, rest['data-e2e-id'])}
        {groupedOptions &&
          groupedOptions.map((groupedOption, index) => {
            return (
              <OptGroup key={`${index}${groupedOption.groupName}`} label={groupedOption.groupName}>
                {renderOptions(groupedOption.options, rest['data-e2e-id'])}
              </OptGroup>
            );
          })}
      </Select>
    </FormField>
  );
};

const mapUserToOptions = (user: User): OptionItem => {
  const id = user.id;
  const firstName = user?.firstName || '';
  const lastName = user?.lastName || '';
  const initials = getInitials(firstName, lastName);
  const downloadUrl = user.avatar?.downloadUrl;
  return {
    label: `${firstName} ${lastName}`,
    value: `${id}, ${firstName} ${lastName}`,
    initials,
    downloadUrl,
  };
};

export const SelectUser = (
  props: {
    defaultUser?: User;
    users: Array<User>;
    input: InputType;
  } & any,
) => {
  const { defaultUser, users, input, ...rest } = props;

  const formattedInput = React.useMemo(
    function reformatInputValueToString() {
      // input.value is either
      // - string (selector' value)
      // - or User (final-form initial value)
      const selectedValue = input?.value
        ? typeof input.value === 'string'
          ? (input.value as string)
          : mapUserToOptions(input?.value).value
        : null;

      // and we want it to be a string (selector compliant value)
      return { ...input, value: selectedValue };
    },
    [input],
  );

  const options: Array<{ label: string; value: string }> = React.useMemo(
    // same with options:
    // users are reduced to strings
    function reduceUsersToSelectCompliantOptions() {
      return users.map(mapUserToOptions);
    },
    [users],
  );

  React.useEffect(
    function trySettingDefaultUser() {
      let update: number | undefined = undefined;
      if (defaultUser && formattedInput?.onChange && formattedInput?.value === null) {
        const defaultUserValue = mapUserToOptions(defaultUser).value;
        const isTeamMember = options.find(o => o.value === defaultUserValue) !== undefined;
        if (isTeamMember) {
          // the update wont be applied
          // unless form is rendered and visible!
          // hence the timeout... is there a better way?
          update = setTimeout(() => {
            formattedInput.onChange(defaultUserValue);
          });
        }
      }
      return () => {
        clearTimeout(update);
      };
    },
    [defaultUser, formattedInput, options],
  );

  return <SelectField input={formattedInput} options={options} {...rest} />;
};
