import React from 'react';
import { useQuery } from 'react-apollo';
import { Button } from 'antd';
import { DateTime } from 'luxon';
import pluralize from 'pluralize';
import * as R from 'ramda';

import { dialogsContext } from '@/providers';
import { Maybe, BidListResponse } from '@/shared/types/graphql';
import { Empty } from 'livo-components/src/components';
import { Loader, ButtonLink } from '@/components';
import { formatAmount, getFullName, getTimeToApproveOffer } from '@/shared/utils';
import { OFFER_STATUSES, BID_STATUSES } from '@/shared/constants';
import { checkOfferChecklistComplete } from '@/shared/utils';
import { usePermissions } from '@/shared/hooks';
import { Grid } from '@/components';

import {
  BiddersListContainer,
  BidderContainer,
  BidderInformation,
  BidderNameAndAmount,
  BidderLine,
  BidderAmount,
  InformationRow,
  SecureNowInformationText,
  TimerInformationText,
  InformationTextContainer,
} from './styled';
import { BIDS_LIST_BY_OFFER_QUERY } from './queries';
import {
  BIDDER_AWARD_DIALOG,
  BIDDER_REJECT_DIALOG,
  RENTER_DETAILS_WITH_BID_DIALOG,
  OFFER_AWARD_DIALOG,
} from '@/dialogs';

type BiddersListProps = {
  offerId?: Maybe<string>;
  refetchQueries?: string[];
};

export const BiddersList = ({ offerId, refetchQueries = [] }: BiddersListProps) => {
  const { openDialog } = React.useContext(dialogsContext);

  const { applicationRole } = usePermissions();
  const isAllowedToAward =
    applicationRole?.employeeRole || applicationRole?.clientRole || applicationRole?.companyOwner;

  const { data: bidsListResponse, loading: bidsListLoading } = useQuery<{ bidsList: BidListResponse }>(
    BIDS_LIST_BY_OFFER_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      skip: !offerId,
      variables: {
        filter: {
          AND: [
            {
              offer: {
                id: {
                  equals: offerId,
                },
              },
            },
            {
              status: {
                not_equals: BID_STATUSES.rejected,
              },
            },
          ],
        },
        sort: {
          rank: 'DESC',
        },
        first: 3,
      },
    },
  );

  const bidsList = bidsListResponse?.bidsList?.items;
  const refetchQueriesList = [...refetchQueries, 'BidsListByOfferQuery'];

  return (
    <BiddersListContainer>
      {bidsListLoading ? (
        <Loader stretch height="100%" />
      ) : R.isEmpty(bidsList) ? (
        <Empty description="No prospects" stretch />
      ) : (
        bidsList?.map((bid, i) => {
          const withActions =
            bid?.offer?.status === OFFER_STATUSES.live ||
            bid?.offer?.status === OFFER_STATUSES.ended ||
            bid?.offer?.status === OFFER_STATUSES.closed ||
            bid?.offer?.status === OFFER_STATUSES.secured;

          const checklistComplete = checkOfferChecklistComplete(bid);

          const isWinner =
            bid?.status === BID_STATUSES.winner ||
            bid?.status === BID_STATUSES.secured ||
            (bid?.offer?.status === OFFER_STATUSES.secured && i === 0) ||
            (bid?.offer?.status === OFFER_STATUSES.closed && i === 0) ||
            (bid?.offer?.status === OFFER_STATUSES.ended && i === 0);
          const openBidderModal = () => {
            if (checklistComplete) {
              openDialog({
                name: BIDDER_AWARD_DIALOG,
                props: {
                  bidderId: bid?.id,
                  refetchQueries: refetchQueriesList,
                },
              });
            } else {
              openDialog({
                name: RENTER_DETAILS_WITH_BID_DIALOG,
                props: {
                  isWinner,
                  renterId: bid?.renter?.id,
                  offerId: bid?.offer?.id,
                  refetchQueries: refetchQueriesList,
                },
              });
            }
          };
          const timeToApproveOffer = getTimeToApproveOffer(bid?.offer);

          return (
            <BidderContainer key={bid.id as string}>
              <BidderInformation>
                <BidderNameAndAmount isFirst={i === 0}>
                  <ButtonLink
                    style={{
                      width: 128,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      display: 'block',
                      textAlign: 'left',
                    }}
                    text={`${i + 1}. ${getFullName(bid?.renter?.user?.firstName, bid?.renter?.user?.lastName)}`}
                    onClick={openBidderModal}
                  />
                  <BidderLine />
                  <BidderAmount>${formatAmount(bid?.rentAmount)}</BidderAmount>
                </BidderNameAndAmount>
                <InformationRow>
                  <InformationTextContainer>
                    {bid?.status === BID_STATUSES.secured && (
                      <SecureNowInformationText>SecureNow&trade; option executed</SecureNowInformationText>
                    )}
                    {bid?.status === BID_STATUSES.winner && <SecureNowInformationText>Winner</SecureNowInformationText>}
                    <If condition={i === 0}>
                      <TimerInformationText>{`${timeToApproveOffer} left`}</TimerInformationText>
                    </If>
                  </InformationTextContainer>
                  <span>
                    {bid?.termsOfLease} {pluralize('Month', bid?.termsOfLease as number | undefined)}
                  </span>
                </InformationRow>
                <InformationRow>{DateTime.fromISO(bid?.moveinDate).toFormat('D')} Move-in</InformationRow>
              </BidderInformation>
              {withActions && (
                <Grid.Layout columns={'repeat(3, 1fr)'} gap={'24px'} css={{ marginRight: 24 }}>
                  {isWinner && (
                    <Button type="primary" size="large" onClick={openBidderModal}>
                      Send Lease Contract
                    </Button>
                  )}
                  {isWinner && isAllowedToAward && (
                    <Button
                      type="default"
                      size="large"
                      onClick={() =>
                        openDialog({
                          name: OFFER_AWARD_DIALOG,
                          props: {
                            offerId,
                            bidId: bid?.id,
                            refetchQueries: refetchQueriesList,
                          },
                        })
                      }
                    >
                      Award Lease
                    </Button>
                  )}
                  <Button
                    type="default"
                    size="large"
                    onClick={() =>
                      openDialog({
                        name: BIDDER_REJECT_DIALOG,
                        props: {
                          bidderId: bid?.id,
                          refetchQueries: refetchQueriesList,
                        },
                      })
                    }
                  >
                    Reject Renter
                  </Button>
                </Grid.Layout>
              )}
            </BidderContainer>
          );
        })
      )}
    </BiddersListContainer>
  );
};
