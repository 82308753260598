import React from 'react';
import * as R from 'ramda';
import moment, { Moment } from 'moment';
import { Modal } from 'antd';
import { toast } from 'react-toastify';
import { useQuery } from 'react-apollo';
import { Field, Form } from '8base-react-sdk';

import { InputNumberField, InputField, DatePickerField, ModalFooter, Scrollable, Loader } from '@/components';

import { BID_CREATE_MUTATION, BID_UPDATE_MUTATION, RENTER_QUERY, RENTER_UPDATE } from './queries';
import { FormContainer } from './styled';
import { useCurrentUser, useMutationSafe } from '@/shared/hooks';
import { normalizePhone } from '@/shared/utils';
import { EventTypes, QUERY_EVENT } from '@/shared/graphql/eventResolver';
import { User } from '@/shared/types/graphql';
import { TERMS_OF_LEASE_MIN_AMOUNT, TERMS_OF_LEASE_MAX_AMOUNT } from '@/shared/constants';

type BidFormValues = {
  rentAmount: string;
  termsOfLease: number;
  moveinDate: string;
  phone?: {
    code: string;
    number?: string;
  };
  securityDeposite: number;
};

type ValidateData = {
  minAmount: number;
  maxAmount?: number;
  minimumLeaseLength: number;
  maximumLeaseLength: number;
  incrimentAmount: number;
  withPhone: boolean;
  securityDeposite: number;
};

const validateBidData = (values: BidFormValues, validateData: ValidateData) => {
  const {
    minAmount,
    maxAmount,
    incrimentAmount,
    withPhone,
    securityDeposite,
    minimumLeaseLength,
    maximumLeaseLength,
  } = validateData;

  let errors: any = {};

  const rentAmount = Number(values?.rentAmount);

  if (rentAmount < minAmount) {
    errors.rentAmount = `Bid rentAmount should be greater than $${minAmount}`;
    return errors;
  }

  if (maxAmount && rentAmount > maxAmount) {
    errors.rentAmount = `Bid rentAmount should be less than $${maxAmount}`;
    return errors;
  }

  if (rentAmount !== maxAmount && (rentAmount - minAmount) % incrimentAmount !== 0) {
    errors.rentAmount = `Bid rentAmount should be match offer incremented bid rentAmount $${incrimentAmount}.`;
    return errors;
  }

  if (values.securityDeposite < securityDeposite) {
    errors.securityDeposite = `Security deposit should be greater than ${securityDeposite}`;
    return errors;
  }

  if (values.termsOfLease < minimumLeaseLength) {
    errors.termsOfLease = `Lease Terms should be equal or higher than ${minimumLeaseLength}`;
    return errors;
  }

  if (values.termsOfLease > maximumLeaseLength) {
    errors.termsOfLease = `Lease Terms should be equal or less than ${maximumLeaseLength}`;
    return errors;
  }

  if (withPhone && (!values.phone || !values.phone.number)) {
    errors = {
      ...errors,
      phone: {
        number: 'Phone should be provided',
      },
    };
    return errors;
  }
};

type DialogProps = {
  close: () => void;
  isVisible: boolean;
  rentAmount?: any;
  minimumLeaseLength?: number;
  maximumLeaseLength?: number;
  options?: any;
  offerId?: string;
  title?: string;
  userBidId?: string;
  offerMoveinDate?: any;
  termsOfLease: number;
  secureNow?: any;
  incrementedBidAmount?: any;
};
export const Dialog: React.FC<DialogProps> = props => {
  const { user } = useCurrentUser();
  const {
    rentAmount,
    offerMoveinDate,
    offerId,
    options = {},
    termsOfLease,
    title,
    secureNow,
    incrementedBidAmount,
    userBidId,
    isVisible,
    close,
  } = props;

  const { data: renterData, loading: renterLoading } = useQuery<{ user: User }>(RENTER_QUERY, {
    variables: {
      id: user?.id,
    },
    skip: !user?.id,
  });

  const { data: offerData, loading: offerLoading } = useQuery(QUERY_EVENT, {
    variables: {
      event: {
        type: EventTypes.GET_OFFER,
        payload: {
          id: offerId,
        },
      },
    },
  });

  const minAmount = R.pathOr(1, ['rentAmount'], offerData?.eventResolverQuery?.inner);
  const maxAmount = R.pathOr(undefined, ['secureNowAmount'], offerData?.eventResolverQuery?.inner);
  const moveinDate = R.pathOr('', ['moveinDate'], offerData?.eventResolverQuery?.inner);
  const timezone = R.pathOr('', ['timezone'], offerData?.eventResolverQuery?.inner);
  const moveinDateEditable = R.pathOr(false, ['moveinDateEditable'], offerData?.eventResolverQuery?.inner);
  const securityDeposite = R.pathOr(1, ['securityDeposite'], offerData?.eventResolverQuery?.inner);
  const securityDepositeEditable = R.pathOr(false, ['securityDepositeEditable'], offerData?.eventResolverQuery?.inner);
  const minimumLeaseLength = R.pathOr(
    TERMS_OF_LEASE_MIN_AMOUNT,
    ['minimumLeaseLength'],
    offerData?.eventResolverQuery?.inner,
  );
  const maximumLeaseLength = R.pathOr(
    TERMS_OF_LEASE_MAX_AMOUNT,
    ['maximumLeaseLength'],
    offerData?.eventResolverQuery?.inner,
  );

  const renter = renterData?.user?.renter;
  const renterPhone = renter?.phone;

  const [bidMutate] = useMutationSafe(userBidId ? BID_UPDATE_MUTATION : BID_CREATE_MUTATION, {
    onError: () => {
      options.onError && options.onError();
    },
    onCompleted: () => {
      close();
      toast.success('Offer successfully created');
      options.onCompleted && options.onCompleted();
    },
  });

  const [renterMutate] = useMutationSafe(RENTER_UPDATE);

  const onSubmit = async (data: BidFormValues) => {
    if (data.moveinDate) {
      data.moveinDate = moment(data.moveinDate).format('YYYY-MM-DD');
    }

    const { phone, ...bidData } = data;
    if (!renterPhone) {
      await renterMutate({
        variables: {
          data: {
            id: renter?.id,
            phone,
          },
        },
      });
    }

    if (userBidId) {
      await bidMutate({
        ...options,
        variables: { data: { ...bidData, id: userBidId } },
      });
    } else {
      await bidMutate({
        ...options,
        refetchQueries: [...options.refetchQueries, 'RenterQuery'],
        variables: {
          data: {
            ...bidData,
            renter: {
              connect: {
                id: renter?.id,
              },
            },
            offer: {
              connect: {
                id: offerId,
              },
            },
          },
        },
      });
    }
  };

  const getSubmitButtonText = (): string => {
    if (secureNow) {
      return 'SecureNow™';
    }

    return userBidId ? 'Update Offer' : 'Create Offer';
  };

  const initialValues: BidFormValues = React.useMemo(
    () => ({
      rentAmount: rentAmount || minAmount,
      termsOfLease: termsOfLease || minimumLeaseLength || 12,
      securityDeposite: securityDeposite,
      moveinDate,
      ...(!renterPhone && {
        phone: {
          code: '1',
        },
      }),
    }),
    [rentAmount, minAmount, termsOfLease, securityDeposite, moveinDate, renterPhone, minimumLeaseLength],
  );

  return (
    <Form
      tableSchemaName="Bid"
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={values =>
        validateBidData(values, {
          incrimentAmount: incrementedBidAmount,
          minimumLeaseLength,
          maximumLeaseLength,
          minAmount,
          maxAmount,
          withPhone: renterPhone ? false : true,
          securityDeposite,
        })
      }
    >
      {({ handleSubmit: onSubmit, form, submitting }) => (
        <Modal
          title={title || (userBidId ? 'Update Offer' : 'Create Custom Offer')}
          className="livo-modal"
          visible={isVisible}
          onCancel={close}
          afterClose={form.reset}
          centered
          footer={
            <ModalFooter
              cancelProps={{
                disabled: submitting,
                onClick: close,
              }}
              okProps={{
                text: getSubmitButtonText(),
                type: 'primary',
                htmlType: 'submit',
                disabled: submitting,
                loading: submitting,
                onClick: onSubmit,
              }}
            />
          }
        >
          <Scrollable>
            {renterLoading || offerLoading ? (
              <Loader stretch />
            ) : (
              <FormContainer onSubmit={onSubmit}>
                {secureNow && (
                  <span css={{ fontSize: 13, color: '#919DAA' }}>
                    Please review the terms carefully.
                    <br /> <span css={{ color: '#323C47' }}>Note:</span> Once you confirm your offer, this Livo
                    transaction will end immediately. You will be contacted to begin the rental process.
                  </span>
                )}
                <Field
                  label="Rent*"
                  name="rentAmount"
                  component={InputNumberField}
                  stretch
                  min={minAmount}
                  max={maxAmount}
                  step={incrementedBidAmount}
                  prefix="$"
                  prefixPadding={19}
                  isAmount
                  note={!secureNow && `The bid incremental amount is set to $${incrementedBidAmount}`}
                  disabled={secureNow}
                  tabIndex={-1}
                  data-e2e-id="dialog.bid.rentAmount"
                />
                <Field
                  label="Lease Terms*"
                  name="termsOfLease"
                  component={InputNumberField}
                  max={maximumLeaseLength}
                  note={!secureNow && `Max amount of months is ${maximumLeaseLength}`}
                  min={minimumLeaseLength}
                  disabled={secureNow}
                  prefix="Months:"
                  prefixPadding={70}
                  stretch
                  data-e2e-id="modal.offer.create.termsOfLease"
                />
                <Field
                  label="Security Deposit*"
                  name="securityDeposite"
                  component={InputNumberField}
                  stretch
                  min={securityDeposite}
                  prefix="$"
                  disabled={!securityDepositeEditable || secureNow}
                  prefixPadding={19}
                />
                <Field
                  label="Move-in Date*"
                  name="moveinDate"
                  component={DatePickerField}
                  stretch
                  timezone={timezone}
                  placeholder="MM/DD/YYYY"
                  displayFormat="MM/DD/YYYY"
                  disabled={!moveinDateEditable || secureNow}
                  disabledDate={(dt: Moment) =>
                    !!offerMoveinDate ? dt.isBefore(moment(offerMoveinDate)) : dt.isBefore(moment(moveinDate))
                  }
                />
                {!renterPhone && (
                  <Field
                    label="Phone Number*"
                    name="phone.number"
                    autoComplete="disabled"
                    placeholder="(415) 842-2731"
                    mask="(999) 999-9999"
                    width={13}
                    component={InputField}
                    addonBefore={`+1`}
                    parse={normalizePhone}
                  />
                )}
              </FormContainer>
            )}
          </Scrollable>
        </Modal>
      )}
    </Form>
  );
};
