import React from 'react';
import { styled } from '@/theme';
import { DateTime, Duration } from 'luxon';
import { Icon } from 'antd';
import { TimeIcon } from '@/static/icons';
import { RowFlex } from '@/components';
import { useInterval } from '@/shared/hooks';

const TimerTag = styled(RowFlex)`
  display: inline-flex;
  font-size: 16px;
  height: 34px;
  font-weight: 500;
  border: 1px solid #d0d7dd;
  white-space: nowrap;
  padding: 4px 8px;
`;

const TimerText = styled.span<{ isRed: boolean }>`
  color: ${props => (props.isRed ? '#FF3100' : '#7d828c')};
`;

type TimerProps = {
  endsDate: DateTime | string;
};

export const Timer: React.FC<TimerProps> = ({ endsDate }) => {
  const date = typeof endsDate === 'string' ? DateTime.fromISO(endsDate) : endsDate;
  const [diff, setDiff]: [Duration, (arg: Duration) => void] = React.useState<Duration>(date.diffNow());

  useInterval(() => {
    setDiff(date.diffNow());
  }, 1000);

  return (
    <TimerTag align="center">
      <Icon component={TimeIcon} />
      <TimerText isRed={(diff as any).shiftTo('hours').hours < 24}>
        {(diff as any).toObject().milliseconds > 0
          ? diff.toFormat(`d 'd', h 'h', m 'm' left`)
          : diff.negate().toFormat(`d 'd', h 'h', m 'm' ago`)}
      </TimerText>
    </TimerTag>
  );
};
