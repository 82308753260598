import React from 'react';
import { Field } from '8base-react-sdk';

import { Grid, InputField, SelectField } from '@/components';

type AddressFieldsProps = {
  country: string;
  street1: string;
  street2?: string;
  zip: string;
  city: string;
  state: string;
  stateOptions: Record<string, any>;
  isRequired?: boolean;
  addressNote?: string;
  'data-e2e-id'?: string;
};

export const AddressFields = ({
  country,
  street1,
  street2,
  zip,
  city,
  state,
  stateOptions,
  addressNote,
  isRequired = true,
  ...rest
}: AddressFieldsProps) => (
  <Grid.Layout gap="16px" data-e2e-id={rest['data-e2e-id']}>
    <Grid.Box>
      <Field
        label={`Address${isRequired ? '*' : ''}`}
        name={street1}
        placeholder="Street 1"
        autoComplete="disabled"
        component={InputField}
        data-e2e-id={`${rest['data-e2e-id']}.street1`}
      />
    </Grid.Box>
    {street2 && (
      <Grid.Box>
        <Field
          name={street2}
          placeholder="Street 2"
          autoComplete="disabled"
          component={InputField}
          data-e2e-id={`${rest['data-e2e-id']}.street2`}
        />
      </Grid.Box>
    )}
    <Grid.Box>
      <Grid.Layout columns="repeat(3, 1fr)" gap="16px" inline>
        <Grid.Box>
          <Field
            name={city}
            placeholder="City"
            autoComplete="disabled"
            component={InputField}
            data-e2e-id={`${rest['data-e2e-id']}.city`}
          />
        </Grid.Box>
        <Grid.Box>
          <Field
            name={state}
            placeholder="State"
            autoComplete="disabled"
            component={SelectField}
            options={stateOptions}
            showSearch
            stretch
            data-e2e-id={`${rest['data-e2e-id']}.state`}
          />
        </Grid.Box>
        <Grid.Box>
          <Field
            name={zip}
            placeholder="Zip"
            autoComplete="disabled"
            component={InputField}
            data-e2e-id={`${rest['data-e2e-id']}.zip`}
          />
        </Grid.Box>
      </Grid.Layout>
    </Grid.Box>
    <Grid.Box>
      <Field
        name={country}
        placeholder="Country"
        autoComplete="disabled"
        note={addressNote}
        component={InputField}
        disabled
        data-e2e-id="common.address.country"
      />
    </Grid.Box>
  </Grid.Layout>
);
