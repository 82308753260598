import React from 'react';
import { styled } from '@/theme';
import { DateTime } from 'luxon';
import pluralize from 'pluralize';

import { Offer } from '@/shared/types/graphql';
import { CalendarIcon, TimeIcon, RentIcon, SecureNow, SecurityDeposit } from '@/static/icons';
import { NOT_AVALIABLE, BID_STATUSES } from '@/shared/constants';
import { Tag, Info } from '@/components';
import { EntityOption } from '@/pro';
import { LeftTime } from '../LeftTime';
import { formatAmount } from '@/shared/utils';

const PropertyOfferDetailsContainer = styled.div`
  margin-bottom: 30px;
`;

const PropertyOfferDetailsTag = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;

  @media (max-width: ${({ theme }) => theme.vars.breakpoints.sm.max}) {
    grid-template-columns: none;
    grid-gap: 20px;
  }
`;

const PropertyOfferHeaderTag = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;

  @media (max-width: ${({ theme }) => theme.vars.breakpoints.sm.max}) {
    justify-content: space-between;
  }
`;

const OfferHeading = styled.div`
  color: #323c47;
  font-weight: 600;
  font-size: 14px;
  margin-right: 16px;
  line-height: 22px;
`;

type PropertyOfferDetailsProps = {
  offer: Offer;
  withoutOfferId?: boolean;
};

export const PropertyOfferDetails: React.FC<PropertyOfferDetailsProps> = ({ offer, withoutOfferId }) => {
  const securityDeposit = formatAmount(offer.securityDeposite);
  const termsOfLease = offer.termsOfLease;
  const rentAmount = formatAmount(offer.rentAmount);
  const moveinDate = offer.moveinDate;
  const endsIn = offer.endsIn;
  const secureNowAmount = formatAmount(offer.secureNowAmount);

  const bids = offer.bid?.items || [];
  const filteredBids = bids.filter(bid => bid?.status !== BID_STATUSES.rejected);
  const bidsCount = filteredBids.length;

  return (
    <PropertyOfferDetailsContainer>
      <PropertyOfferHeaderTag>
        {withoutOfferId && <OfferHeading>Live Offfer #1</OfferHeading>}
        {endsIn && <LeftTime endsDate={DateTime.fromISO(endsIn)} />}
        <Tag color="green" type="raised" css={{ marginLeft: 30 }}>
          {bidsCount} {pluralize('offer', bidsCount)}
        </Tag>
      </PropertyOfferHeaderTag>
      <PropertyOfferDetailsTag>
        <EntityOption
          type="uppercase-label"
          icon={RentIcon}
          label="Rent amount"
          value={rentAmount ? `$${rentAmount}/month` : NOT_AVALIABLE}
        />
        <EntityOption
          type="uppercase-label"
          icon={TimeIcon}
          label="Preferred Terms"
          value={termsOfLease ? `${termsOfLease} ${pluralize('month', termsOfLease)}` : NOT_AVALIABLE}
        />
        <EntityOption
          type="uppercase-label"
          icon={CalendarIcon}
          label="Preferred Move-in Date"
          value={moveinDate ? DateTime.fromISO(moveinDate).toFormat('DD') : NOT_AVALIABLE}
        />
        <EntityOption
          type="uppercase-label"
          icon={SecurityDeposit}
          label="Security Deposit"
          value={securityDeposit ? `$${securityDeposit}` : NOT_AVALIABLE}
        />
        <If condition={offer.secureNow === true}>
          <EntityOption
            type="uppercase-label"
            icon={SecureNow}
            label="SecureNow™"
            value={
              <>
                {secureNowAmount ? `$${secureNowAmount}` : NOT_AVALIABLE}
                <Info title="SecureNow™ is the quickest way to secure your new home. If you would like the bidding process to end and be awarded the lease, simply click SecureNow™ and start the leasing signing process." />
              </>
            }
          />
        </If>
      </PropertyOfferDetailsTag>
    </PropertyOfferDetailsContainer>
  );
};
