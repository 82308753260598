import React from 'react';
import { Modal } from 'antd';
import { Form, Field } from '8base-react-sdk';
import { isNil } from 'ramda';
import { useQuery } from 'react-apollo';

import {
  Grid,
  InputField,
  InputNumberField,
  SelectField,
  ModalFooter,
  Scrollable,
  AddressFields,
  HasMediaInputField,
  AmenitiesSelectField,
  DescriptionEditorField,
  Info,
} from '@/components';
import { addAmenities, normalizePhone, validateEntityDataByDraft } from '@/shared/utils';
import {
  US_STATES_LIST,
  SINGLE_UNIT_TYPE_LABELS,
  SINGLE_UNIT_STATUSES,
  SINGLE_UNIT_MDU_TYPE_LABELS,
  TOAST_SUCCESS_MESSAGE,
  COMPANY_TYPES,
  SHOWING_TYPE_LABELS,
} from '@/shared/constants';
import { Community } from '@/shared/types/graphql';
import { SingleUnitSchemaValidation, MduSingleUnitSchemaValidation } from '@/schemaValidators';
import { CREATE_SINGLE_COMMUNITY_QUERY, CREATE_SINGLE_UNIT_MUTATION } from './queries';
import { useMutationSafe, useDialogState } from '@/shared/hooks';
import { validateShowingTypeValue } from '@/dialogs/singleUnit/SingleUnitCreateDialog/utils';

const INITIAL_VALUES = {
  phone: {
    code: '1',
  },
  address: {
    country: 'United States',
  },
  amenities: [],
};

export const SINGLE_UNIT_CREATE_DIALOG = 'SINGLE_UNIT_CREATE_DIALOG';

export const SingleUnitCreateDialog = () => {
  const { close, closeWithConfirm, isVisible, props } = useDialogState(SINGLE_UNIT_CREATE_DIALOG);

  const isMdu = COMPANY_TYPES.mdu === props.companyType;

  const coercedSingleUnitTypes = React.useMemo(
    () =>
      isMdu
        ? Object.entries(SINGLE_UNIT_MDU_TYPE_LABELS).map(([value, label]) => ({ value, label }))
        : Object.entries(SINGLE_UNIT_TYPE_LABELS).map(([value, label]) => ({ value, label })),
    [isMdu],
  );

  const coercedStatesOfUSA = React.useMemo(
    () => Object.entries(US_STATES_LIST).map(([value, label]) => ({ value, label: `${value} - ${label}` })),
    [],
  );

  const coercedShowingTypes = React.useMemo(
    () => Object.entries(SHOWING_TYPE_LABELS).map(([value, label]) => ({ value, label })),
    [],
  );

  const { data } = useQuery<{ community: Community }>(CREATE_SINGLE_COMMUNITY_QUERY, {
    variables: {
      id: props.communityId,
    },
    skip: !props.communityId,
  });

  const [createSingleUnit] = useMutationSafe(CREATE_SINGLE_UNIT_MUTATION, {
    context: {
      [TOAST_SUCCESS_MESSAGE]: 'Successfully Created',
    },
    onCompleted: data => {
      if (!isNil(data)) {
        close();
      }
    },
    refetchQueries: props.refetchQueries,
    awaitRefetchQueries: true,
  });

  const onSubmit = async (data: any) => {
    await createSingleUnit({
      variables: {
        data: {
          ...data,
          ...(props.communityId
            ? {
                community: {
                  connect: {
                    id: props.communityId,
                  },
                },
              }
            : {}),
        },
        companyType,
      },
    });
  };

  const title = !!props.communityId ? `Add New to ${data?.community?.name}` : 'Add New';

  const companyType = props?.companyType;

  const onFormat = (data: any) => {
    const resultValues = addAmenities(data, 'singleUnit');
    return { ...resultValues };
  };

  if (!isVisible) {
    return null;
  }

  const isMduCompany = companyType === COMPANY_TYPES.mdu;
  const validationSchema = isMduCompany ? MduSingleUnitSchemaValidation : SingleUnitSchemaValidation;

  return (
    <Form
      type="CREATE"
      tableSchemaName="SingleUnit"
      initialValues={INITIAL_VALUES}
      ignoreNonTableFields={true}
      beforeFormatDataForMutation={onFormat}
      onSubmit={onSubmit}
      validate={values => {
        return validateEntityDataByDraft(validationSchema, values);
      }}
    >
      {({ handleSubmit, submitting, pristine, form, values }) => (
        <Modal
          visible={isVisible}
          closable={props.closable}
          onCancel={pristine ? close : closeWithConfirm}
          afterClose={form.reset}
          width="100%"
          style={{ maxWidth: 620 }}
          maskClosable={false}
          title={title}
          className="livo-modal"
          footer={
            <ModalFooter
              leftActionProps={{
                text: 'Save as Draft',
                disabled: pristine || submitting,
                onClick: event => {
                  form.change('status', SINGLE_UNIT_STATUSES.draft);

                  return handleSubmit(event);
                },
              }}
              cancelProps={{
                onClick: pristine ? close : closeWithConfirm,
                disabled: submitting,
              }}
              okProps={{
                type: 'primary',
                htmlType: 'submit',
                text: 'Add',
                loading: submitting,
                disabled: pristine,
                onClick: event => {
                  form.change('status', SINGLE_UNIT_STATUSES.active);

                  return handleSubmit(event);
                },
              }}
            />
          }
          centered
        >
          <Scrollable height="75vh">
            <Grid.Layout gap="16px" data-e2e-id="modal.singleUnit.create.body">
              <Grid.Box>
                <Field
                  name="name"
                  label="Name*"
                  autoComplete="disabled"
                  component={InputField}
                  data-e2e-id="modal.singleUnit.create.name"
                />
              </Grid.Box>
              <Grid.Box>
                <Field
                  name="type"
                  label="Type*"
                  component={SelectField}
                  options={coercedSingleUnitTypes}
                  stretch
                  data-e2e-id="modal.singleUnit.create.type"
                />
              </Grid.Box>
              <Grid.Box>
                <Field
                  name="description"
                  label="Description*"
                  component={DescriptionEditorField}
                  data-e2e-id="modal.singleUnit.create.description"
                />
              </Grid.Box>
              <Grid.Box>
                <Field
                  name="amenities"
                  label="Amenities"
                  companyType={props?.companyType}
                  unitType="singleUnit"
                  component={AmenitiesSelectField}
                  stretch
                />
              </Grid.Box>
              <Grid.Box>
                <Grid.Layout gap="16px" columns="1fr 1fr 1fr" inline>
                  <Grid.Box>
                    <Field
                      name="bedrooms"
                      component={InputNumberField}
                      prefix="Beds:"
                      prefixPadding={55}
                      stretch
                      min={0}
                      data-e2e-id="modal.singleUnit.create.bedrooms"
                    />
                  </Grid.Box>
                  <Grid.Box>
                    <Field
                      name="bathrooms"
                      component={InputNumberField}
                      prefix="Baths:"
                      prefixPadding={58}
                      stretch
                      min={0}
                      step={0.5}
                      data-e2e-id="modal.singleUnit.create.bathrooms"
                    />
                  </Grid.Box>
                  <Grid.Box>
                    <Field
                      name="squareFt"
                      component={InputNumberField}
                      prefix="Square ft:"
                      prefixPadding={83}
                      min={1}
                      stretch
                      data-e2e-id="modal.singleUnit.create.squareFt"
                    />
                  </Grid.Box>
                </Grid.Layout>
              </Grid.Box>
              <Grid.Box>
                <Field
                  name="phone.number"
                  label="Phone number*"
                  autoComplete="disabled"
                  component={InputField}
                  mask="(999) 999-9999"
                  addonBefore={`+${values.phone.code}`}
                  parse={normalizePhone}
                  data-e2e-id="modal.singleUnit.create.phoneNumber"
                />
              </Grid.Box>
              <Grid.Box>
                <AddressFields
                  street1="address.street1"
                  street2="address.street2"
                  city="address.city"
                  state="address.state"
                  zip="address.zip"
                  country="address.country"
                  stateOptions={coercedStatesOfUSA}
                  addressNote="At this time Livo.io only supports U.S. based companies."
                  data-e2e-id="modal.singleUnit.create.address"
                />
              </Grid.Box>
              {!props.communityId && (
                <Grid.Box>
                  <Field
                    label="Website"
                    name="website"
                    autoComplete="disabled"
                    placeholder="e.g. https://www.livo.io, http://livo.com, etc."
                    component={InputField}
                    data-e2e-id="modal.singleUnit.create.website"
                  />
                </Grid.Box>
              )}
              <Grid.Box>
                <Field
                  name="showingType"
                  label="Select Showing Type(s)*"
                  component={SelectField}
                  options={coercedShowingTypes}
                  validate={(value, allValues: any) => {
                    if (allValues.status && allValues.status !== SINGLE_UNIT_STATUSES.draft) {
                      return validateShowingTypeValue(value);
                    }
                  }}
                  mode="multiple"
                  stretch
                  data-e2e-id="modal.singleUnit.create.showingType"
                />
              </Grid.Box>
              <Grid.Box>
                <Field
                  label="Virtual Tour Link (YouTube)"
                  note="Please add the link for your virtual tour"
                  name="virtualTour"
                  placeholder="e.g. https://www.youtube.com/watch?v=jNQXAC9IVRa"
                  autoComplete="disabled"
                  component={InputField}
                  stretch
                />
              </Grid.Box>
              <Grid.Box>
                <Field
                  label={
                    <span>
                      Media (Floor plans / site plans / pictures / drawings / videos)
                      <Info title="If there are no photos attached, a placeholder will be shown instead." />
                    </span>
                  }
                  name="singleUnitHasMedia"
                  buttonText="Select multiple files* (PNG, JPEG, JPG)"
                  accept={['.png', '.jpg', '.jpeg']}
                  maxFiles={100}
                  component={HasMediaInputField}
                  previewWidth="1/3"
                  data-e2e-id="modal.singleUnit.create.singleUnitHasMedia"
                  isPublic
                />
              </Grid.Box>
            </Grid.Layout>
          </Scrollable>
        </Modal>
      )}
    </Form>
  );
};
