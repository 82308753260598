import React from 'react';
import { Renter, Maybe, Bid } from '@/shared/types/graphql';
import { InformationSection, EntityOption, BidStatusTag } from '@/pro';
import { NOT_AVALIABLE } from '@/shared/constants';
import { Grid, PhoneLink, EmailLink, WebsiteLink } from '@/components';
import {
  getFullName,
  stringifyAddress,
  stringifyDate,
  stringifyRentAmount,
  stringifyTermsOfLease,
} from '@/shared/utils';
import { checkWesternReportingStatus } from '@/shared/utils';
import { WR_STATUS_CHECK, WR_STATUS_UPDATE } from '@/pro/RenterInformation/queries';
import { toast } from 'react-toastify';
import { getTimeToApproveOffer } from '@/shared/utils';
import { useMutationSafe } from '@/shared/hooks';

type RenterInformationProps = {
  renter?: Maybe<Renter>;
  bid?: Maybe<Bid>;
};

export const RenterInformation = ({ renter, bid }: RenterInformationProps) => {
  const [westernReportingStatusCheck, { data: westernReportingStatusCheckResponse }] = useMutationSafe(
    WR_STATUS_CHECK,
    {
      refetchQueries: [
        'RenterDetailsWithBidModalRenterQuery',
        'RenterDetailsModalRenterQuery',
        'RenterDetailsWithBidsListModalRenterQuery',
      ],
    },
  );

  const [updateWesternReportingInfo] = useMutationSafe(WR_STATUS_UPDATE, {
    onCompleted: () => toast.success('Successfully Updated'),
    onError: () => toast.error('Something went wrong. Please, try again'),
    refetchQueries: [
      'RenterDetailsWithBidModalRenterQuery',
      'RenterDetailsModalRenterQuery',
      'RenterDetailsWithBidsListModalRenterQuery',
    ],
  });

  const westernReportingStatusCheckXml = westernReportingStatusCheckResponse?.westernReportingStatusCheck?.result;

  const email = renter?.user?.email || NOT_AVALIABLE;

  const firstName = renter?.user?.firstName;
  const lastName = renter?.user?.lastName;
  const fullName = getFullName(firstName, lastName);
  const screeningFromBid = renter?.bid?.items[0]?.renter?.westernReportingScreenings?.items[0];
  const screeningFromRenter = renter?.westernReportingScreenings?.items[0];
  const livoManagesScreening = bid?.offer?.livoManagesScreening;
  const thirdPartyScreeningLink = bid?.offer?.screeningLink;
  const timeToApproveOffer = getTimeToApproveOffer(bid?.offer);

  const screening = bid ? screeningFromBid : screeningFromRenter;

  // eslint-disable-next-line
  const onScreeningStatusCheck = async () => {
    const orderId = screening?.screeningID;

    await westernReportingStatusCheck({
      variables: {
        data: {
          orderId,
        },
      },
    });

    if (westernReportingStatusCheckXml) {
      const responseData: any = checkWesternReportingStatus(westernReportingStatusCheckXml);
      await updateWesternReportingInfo({
        variables: {
          data: responseData,
          idFilter: {
            screeningID: responseData?.screeningID,
          },
        },
      });
    }
  };

  return (
    <Grid.Layout gap="32px">
      <InformationSection header="Information">
        <Grid.Layout gap="20px">
          <EntityOption type="uppercase-label" label="Name" value={fullName} />
          <EntityOption type="uppercase-label" label="Email" value={<EmailLink email={email} />} />
          <EntityOption type="uppercase-label" label="Address" value={stringifyAddress(renter?.address)} />
          <EntityOption type="uppercase-label" label="Phone" value={<PhoneLink phone={renter?.phone} />} />
        </Grid.Layout>
      </InformationSection>
      <If condition={!!bid}>
        <InformationSection header="Details">
          <Grid.Layout gap="20px">
            <EntityOption type="uppercase-label" label="Rent amount" value={stringifyRentAmount(bid?.rentAmount)} />
            <EntityOption type="uppercase-label" label="Move in date" value={stringifyDate(bid?.moveinDate)} />
            <EntityOption type="uppercase-label" label="Lease" value={stringifyTermsOfLease(bid?.termsOfLease)} />
            <EntityOption type="uppercase-label" label="Status" value={<BidStatusTag status={bid?.status} />} />
            <EntityOption type="uppercase-label" label="Time to approve" value={`${timeToApproveOffer} left`} />
          </Grid.Layout>
        </InformationSection>
      </If>
      {/*<If condition={!!screening && !!livoManagesScreening}>*/}
      {/*  <FlexLayout justify="between" align="start">*/}
      {/*    <InformationSection header={!!bid ? 'Screening Info' : 'Latest screening'}>*/}
      {/*      <Grid.Layout gap="20px">*/}
      {/*        <EntityOption type="uppercase-label" label="Id" value={screening?.screeningID} />*/}
      {/*        <EntityOption*/}
      {/*          type="uppercase-label"*/}
      {/*          label="Status"*/}
      {/*          value={<WesternReportingStatusTag status={screening?.status} />}*/}
      {/*        />*/}
      {/*        <EntityOption type="uppercase-label" label="Created At" value={stringifyDate(screening?.createdAt)} />*/}
      {/*        {screening?.reportURL && (*/}
      {/*          <EntityOption*/}
      {/*            type="uppercase-label"*/}
      {/*            label="Report"*/}
      {/*            value={<WebsiteLink website={screening?.reportURL} text="Open link" />}*/}
      {/*          />*/}
      {/*        )}*/}
      {/*      </Grid.Layout>*/}
      {/*    </InformationSection>*/}
      {/*    <Button*/}
      {/*      icon={!westernReportingStatusCheckLoading && SyncIcon}*/}
      {/*      loading={westernReportingStatusCheckLoading}*/}
      {/*      size="small"*/}
      {/*      onClick={onScreeningStatusCheck}*/}
      {/*    >*/}
      {/*      Refresh*/}
      {/*    </Button>*/}
      {/*  </FlexLayout>*/}
      {/*</If>*/}
      <If condition={!livoManagesScreening && !!thirdPartyScreeningLink}>
        <InformationSection header={'Screening Info'}>
          <EntityOption
            type="uppercase-label"
            label="Link"
            value={<WebsiteLink website={thirdPartyScreeningLink} text="Open link" />}
          />
        </InformationSection>
      </If>
    </Grid.Layout>
  );
};
