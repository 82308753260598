import React, { useEffect } from 'react';
import { useAuth } from '8base-react-sdk';
import { Loader } from '@/components';
import { popStoredVisitedOffers } from '@/shared/utils/visitedUnits';

const { REACT_APP_API_SUFFIX } = process.env as any;

export const AuthContainer = () => {
  const auth = useAuth();

  useEffect(() => {
    auth.authClient.authorize({
      type: 'renter',
      api_suffix: REACT_APP_API_SUFFIX,
      offers: popStoredVisitedOffers(),
    });
  }, [auth.authClient]);

  return <Loader stretch />;
};
