import { styled } from '@/theme';
import { css } from '@emotion/core';

export const ErrorIconStyles = css`
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 20px;
  line-height: 22px;
  opacity: 0.5;
  transition: 0.3s;

  &:hover {
    opacity: 1;
  }
`;

export const PreviewTableContainer = styled.div`
  margin: 0px 24px 16px 24px;
  overflow-y: hidden;
  overflow-x: scroll;
  height: calc(100% - 140px);
  display: grid;
  grid-template-rows: 52px auto;

  &::before {
    content: '';
    display: block;
    height: 299px;
    width: 1px;
    background-color: #e8e8e8;
    position: absolute;
    left: 25px;
    bottom: 158px;
    z-index: 3;
  }

  &::after {
    content: '';
    display: block;
    height: 299px;
    width: 1px;
    background-color: #e8e8e8;
    position: absolute;
    right: 24px;
    bottom: 158px;
    z-index: 3;
  }

  .importTableBody {
    border-top: 1px solid #e8e8e8;

    .ant-table-thead > tr > th .ant-table-header-column {
      width: 300px;
      font-size: 0;
    }
    .ant-table-thead > tr > th.cutomFirsCell .ant-table-header-column {
      width: 50px;
    }
    .ant-table-bordered .ant-table-body > table {
      border: none;
    }
    .ant-table-bordered .ant-table-thead > tr > th {
      padding: 0;
      line-height: 0;
      border: none;
    }
    .ant-table-column-title {
      font-size: 0;
    }
    .ant-spin-nested-loading {
      position: initial;
    }
    .ant-spin-container {
      position: initial;
    }
    .ant-table-pagination.ant-pagination {
      border-top: 1px solid #e8e8e8;
      max-width: calc(100% - 49px);
      position: absolute;
      bottom: 89px;
      left: 25px;
      background-color: #fff;
      padding: 16px 0;
      margin: 0;
    }
    .ant-table-bordered .ant-table-tbody > tr > td:last-child {
      border-right: 0px;
    }
    .ant-table-bordered .ant-table-tbody > tr > td.validation-error {
      background-color: #fad9d7;
      border: 1px solid #eb4235;
      position: relative;
    }
  }

  .importTableHeader {
    padding-right: ${({ theme }) =>
      `${theme.vars.scrollTrackWidth ? theme.vars.scrollTrackWidth - 2 : theme.vars.scrollTrackWidth}px`};
    background-color: #fafafa;
    border-top: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
    border-right: ${({ theme }) => `${theme.vars.scrollTrackWidth ? 1 : 0}px solid #e8e8e8`};
    z-index: 2;

    .ant-table-thead > tr > th .ant-table-header-column {
      width: 266px;
    }
    .ant-table-thead > tr > th.cutomFirsCell .ant-table-header-column {
      width: 17px;
    }
    .ant-table-bordered .ant-table-body > table {
      border: none;
      border-radius: 0px;
    }
    .ant-table-bordered.ant-table-empty .ant-table-placeholder {
      display: none;
    }
    .ant-table-thead > tr:first-child > th:first-child,
    .ant-table-thead > tr:first-child > th:last-child {
      border-radius: 0px;
    }
  }
`;

export const LoaderContainer = styled.div`
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 410px;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
`;

export const PreviewTableBodyContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  border-left: 1px solid #e8e8e8;
`;
