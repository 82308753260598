import React from 'react';
import { Radio } from 'antd';
import { Field } from '@8base-react/forms';

import { RadioGroupField } from '@/components';

export const Compliance = () => {
  return (
    <Field label="Does it use MITS?*" name="mits" component={RadioGroupField}>
      <Radio value={true}>Yes</Radio>
      <Radio value={false}>No</Radio>
    </Field>
  );
};
