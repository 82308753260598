import React, { ChangeEvent } from 'react';
import { Input } from 'antd';
import { useParams } from 'react-router-dom';

import { CustomCard, ButtonLink, Grid, Heading } from '@/components';
import { useSearch, usePermissions } from '@/shared/hooks';
import { dialogsContext } from '@/providers';

import { CommunitiesTable } from './CommunitiesTable';
import { TrashIcon } from '@/static/icons';
import { AddProperties } from './components';
import { BULK_DELETE_DIALOG, COMMUNITIES_IMPORT_DIALOG, COMMUNITY_CREATE_DIALOG } from '@/dialogs';

export const Communities = () => {
  const [{ search, throttledSearch }, setSearch] = useSearch();
  const { companyId } = useParams();
  const { openDialog } = React.useContext(dialogsContext);

  const { isAdmin, isCompanyOwner, isCompanyUser } = usePermissions();
  const allowAddProperties = isAdmin || isCompanyOwner || isCompanyUser;

  const [selectedRecords, setSelectedRecords] = React.useState<string[]>([]);

  const openCommunityCreateModal = () =>
    openDialog({
      name: COMMUNITY_CREATE_DIALOG,
      props: {
        options: {
          refetchQueries: ['PortfolioCommunitiesList', 'NavigationCommunitiesList'],
          awaitRefetchQueries: true,
        },
        companyId,
      },
    });

  const openCommunitiesImportModal = () =>
    openDialog({
      name: COMMUNITIES_IMPORT_DIALOG,
      props: {
        options: {
          refetchQueries: ['PortfolioCommunitiesList', 'NavigationCommunitiesList'],
          awaitRefetchQueries: true,
        },
        companyId,
      },
    });

  const getSelectedRecords = (records: Array<string>) => {
    setSelectedRecords(records);
  };

  const onBulkDelete = () => {
    openDialog({
      name: BULK_DELETE_DIALOG,
      props: {
        records: selectedRecords,
        recordType: 'property',
        options: {
          refetchQueries: ['PortfolioCommunitiesList', 'NavigationCommunitiesList'],
          awaitRefetchQueries: true,
        },
      },
    });
    setSelectedRecords([]);
  };

  const onChange = React.useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSelectedRecords([]);
      setSearch(event.currentTarget.value);
    },
    [setSearch],
  );

  const header = (
    <Grid.Layout gap="20px" columns="auto 400px 1fr 2fr" alignItems="center" justifyItems="flex-start" stretch>
      <Heading type="h1">Properties</Heading>
      <Input value={search} placeholder="Search by Property Name, ZIP code" onChange={onChange} />
      <Grid.Layout columns="auto auto" css={{ marginLeft: '20px' }}>
        <If condition={!isCompanyUser && selectedRecords.length > 0}>
          <ButtonLink onClick={onBulkDelete} text={`Delete Selected`} iconProps={{ component: TrashIcon }} />
        </If>
      </Grid.Layout>
      <If condition={allowAddProperties}>
        <AddProperties openImportModal={openCommunitiesImportModal} openCreateModal={openCommunityCreateModal} />
      </If>
    </Grid.Layout>
  );

  return (
    <CustomCard padding="none" data-e2e-id="profile.communities" header={header}>
      <CommunitiesTable
        setSelectedRecords={selectedRecords}
        getSelectedRecords={getSelectedRecords}
        query={throttledSearch}
      />
    </CustomCard>
  );
};
