import { useAuth } from '8base-react-sdk';
import { useCurrentUser } from '@/shared/hooks';
import { UserAuthStates } from '@/shared/constants';

export const useAuthState = (): UserAuthStates => {
  const { isAuthorized, authClient } = useAuth();
  const { isEmailVerified } = authClient.getState();
  const { user } = useCurrentUser();

  if (user?.isBlocked) {
    return UserAuthStates.Blocked;
  }

  if (isAuthorized) {
    const status = isEmailVerified ? UserAuthStates.Authorized : UserAuthStates.AuthorizedNonVerified;
    return status;
  }

  return UserAuthStates.Guest;
};
