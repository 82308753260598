import * as yup from 'yup';

export const AmenitySchemaValidation = yup.object({
  label: yup
    .string()
    .nullable()
    .required('Label is required'),
  category: yup
    .string()
    .nullable()
    .required('Choose Category'),
});
