import { TableState } from '@/shared/hooks';

export const getSort = (queryParams: TableState) => {
  let sort: Record<string, any>[] = [];

  if (queryParams.sortBy && queryParams.sortOrder) {
    const field = queryParams.sortBy;
    const direction = queryParams.sortOrder === 'ascend' ? 'ASC' : 'DESC';

    if (field === 'name') {
      sort = [
        {
          firstName: direction,
        },
        {
          lastName: direction,
        },
      ];
    } else if (field && field !== 'role') {
      sort = [
        {
          [field]: direction,
        },
      ];
    }
  }

  return sort;
};
