import React from 'react';
import { useDialogState } from '@/shared/hooks';
import { Dialog } from './Dialog';

export const RENTER_DETAILS_WITH_BID_DIALOG = 'RENTER_DETAILS_WITH_BID_DIALOG';

export const RenterDetailsWithBidDialog = () => {
  const { close, isVisible, props } = useDialogState(RENTER_DETAILS_WITH_BID_DIALOG);

  if (!isVisible) {
    return null;
  }

  return <Dialog {...props} close={close} isVisible={isVisible} />;
};
