import React from 'react';
import { Radio } from 'antd';
import { useTheme } from 'emotion-theming';

import { FormField } from '@/components';
import { InputType, MetaType } from '@/shared/types';

type RadioGroupFieldProps = {
  label?: React.ReactNode;
  note?: string;
  input: InputType;
  meta: MetaType;
  children: React.ReactNode;
  'data-e2e-id'?: string;
};

export const RadioGroupField = ({ label, note, input, meta, children, ...rest }: RadioGroupFieldProps) => {
  const { value, onChange } = input;
  const {
    vars: { inputHeightBase },
  } = useTheme();

  return (
    <FormField label={label} note={note} meta={meta} data-e2e-id={rest['data-e2e-id']}>
      <Radio.Group onChange={onChange} value={value} css={{ height: inputHeightBase, lineHeight: inputHeightBase }}>
        {children}
      </Radio.Group>
    </FormField>
  );
};
