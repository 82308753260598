import React from 'react';
import { useDialogState } from '@/shared/hooks';
import { Dialog } from './Dialog';

export const TEAM_MEMBER_UPDATE_DIALOG = 'TEAM_MEMBER_UPDATE_DIALOG';

export const TeamMemberUpdateDialog = () => {
  const { close, isVisible, props } = useDialogState(TEAM_MEMBER_UPDATE_DIALOG);

  if (!isVisible) {
    return null;
  }

  return <Dialog {...props} close={close} isVisible={isVisible} />;
};
