import styled from '@emotion/styled';
import { css } from '@emotion/core';

export const Container = styled.div<{ alignItemsCenter: boolean }>`
  min-height: 400px;
  margin-bottom: 24px;

  ${({ alignItemsCenter }) =>
    alignItemsCenter &&
    css`
      display: grid;
      align-items: center;
    `}
`;

export const List = styled.ul``;

export const ListItem = styled.li`
  display: grid;
  grid-template-columns: 200px 1fr 37px;
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;

  &:not(:first-child) {
    padding-top: 15px;
  }
`;

export const Column = styled.div``;
