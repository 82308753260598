import React from 'react';
import { DatePicker, TimePicker } from 'antd';
import { DatePickerProps } from 'antd/lib/date-picker/interface';
import { TimePickerProps } from 'antd/lib/time-picker';
import moment, { Moment } from 'moment';
import styled from '@emotion/styled';
import { Maybe } from '@/shared/types/graphql';

import { FormField } from '@/components';
import { MetaType, InputType } from '@/shared/types';
import { omit } from 'ramda';
import * as helpers from './helpers';
import { formatDatetimeForPicker } from '@/shared/utils';

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 11px;
  width: 100%;
`;

type GeneralProps = {
  label?: React.ReactNode;
  note?: string;
  width?: string | number;
  stretch?: boolean;
};

type DateAndTimePickerFieldProps = {
  input: InputType;
  meta: MetaType;
  datePickerProps: DatePickerProps & GeneralProps;
  timePickerProps: TimePickerProps & GeneralProps;
  disabledAfterDate?: string;
  disabledBeforeDate?: string;
  minutesCorrection?: number;
  timezone?: string;
  'data-e2e-id'?: string;
};

export const DateAndTimePickerField = ({
  input,
  meta,
  datePickerProps,
  timePickerProps,
  disabledAfterDate,
  disabledBeforeDate,
  minutesCorrection = 10,
  timezone = moment.tz.guess(),
  ...rest
}: DateAndTimePickerFieldProps) => {
  const { name, value, onChange } = input;

  const [datetime, setDatetime] = React.useState(formatDatetimeForPicker(value, timezone));
  const DatePickerWidth = datePickerProps.stretch ? '100%' : datePickerProps.width ? datePickerProps.width : 150;
  const TimePickerWidth = timePickerProps.stretch ? '100%' : timePickerProps.width ? timePickerProps.width : 150;

  React.useEffect(() => {
    if (value.length > 0) {
      setDatetime(formatDatetimeForPicker(value, timezone));
    }
  }, [timezone, value]);

  const handleChange = (date: Moment | null) => {
    const currentDate = date ? date.clone().seconds(0) : date;
    if (!value && currentDate && disabledAfterDate) {
      const afterDate = moment(disabledAfterDate);
      currentDate.hours(afterDate.hours());
      currentDate.minutes(afterDate.minutes() - minutesCorrection);
    }

    if (!value && currentDate && disabledBeforeDate) {
      const beforeDate = moment(disabledBeforeDate);
      currentDate.hours(beforeDate.hours());
      currentDate.minutes(beforeDate.minutes() + minutesCorrection);
    }

    const newValue = currentDate ? currentDate.toISOString() : currentDate;

    setDatetime(currentDate as Moment);

    onChange && onChange(newValue);
  };

  const { label: datePickerLabel, note: datePickerNote, ...datePickerRest } = datePickerProps;
  const { label: timePickerLabel, note: timePickerNote, ...timePickerRest } = timePickerProps;

  const disabledProps = { value, disabledAfterDate, disabledBeforeDate, minutesCorrection };
  const disabledDate = (current: Maybe<Moment>) => helpers.disabledDate({ currentDate: current, ...disabledProps });
  const disabledHours = () => helpers.disabledHours({ ...disabledProps });
  const disabledMinutes = (selectedHour: number) => helpers.disabledMinutes({ selectedHour, ...disabledProps });

  return (
    <Container {...omit(['data-e2e-id'], rest)}>
      <FormField label={datePickerLabel} note={datePickerNote} meta={meta} data-e2e-id={`${rest['data-e2e-id']}.date`}>
        <DatePicker
          style={{ width: DatePickerWidth }}
          name={name}
          value={datetime}
          onChange={handleChange}
          {...datePickerRest}
          disabledDate={disabledDate}
        />
      </FormField>
      <FormField label={timePickerLabel} note={timePickerNote} data-e2e-id={`${rest['data-e2e-id']}.time`}>
        <TimePicker
          style={{ width: TimePickerWidth }}
          value={datetime}
          onChange={handleChange}
          {...timePickerRest}
          disabled={!Boolean(datetime?.toISOString())}
          disabledHours={disabledHours}
          disabledMinutes={disabledMinutes}
        />
      </FormField>
    </Container>
  );
};
