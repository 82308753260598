import React from 'react';
import { toast } from 'react-toastify';
import { useQuery } from 'react-apollo';
import { Dropdown, Icon, Menu, Divider } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import { styled } from '@/theme';
import { TableState, UseTableStateResponse, useTableState, usePermissions, useMutationSafe } from '@/shared/hooks';
import { RenterListResponse, Renter, SmartPhone, Maybe } from '@/shared/types/graphql';
import { getFullName } from '@/shared/utils';
import { LeadInformation, AllBidsList } from '@/pro';
import { Grid, EmailLink, Table, PhoneLink } from '@/components';
import { dialogsContext } from '@/providers';

import { MUTATION_EVENT, UserEventTypes } from '@/shared/graphql/eventResolver';
import { ALL_LEADS_RENTERS_LIST_QUERY } from './queries';
import { RENTER_DETAILS_WITH_BIDS_LIST_DIALOG } from '@/dialogs';

type UseLeadsRentersListQueryFilterParams = {
  query: string;
};
const useLeadsRentersListQueryFilter = ({ query }: UseLeadsRentersListQueryFilterParams) => {
  const { isAdmin, isCompanyOwner, allowedCommunities } = usePermissions();

  const all: any = [
    {
      bid: {
        some: {
          offer: {
            historyOfferSingleUnit: {
              id: {
                is_not_empty: true,
              },
            },
          },
        },
      },
    },
    !(isAdmin || isCompanyOwner) && {
      bid: {
        some: {
          offer: {
            historyOfferSingleUnit: {
              community: {
                id: {
                  in: allowedCommunities.map(({ id }: any) => id),
                },
              },
            },
          },
        },
      },
    },
    query && {
      OR: [
        {
          user: {
            firstName: {
              contains: query,
            },
          },
        },
        {
          user: {
            lastName: {
              contains: query,
            },
          },
        },
        {
          user: {
            email: {
              contains: query,
            },
          },
        },
      ],
    },
  ].filter(Boolean);

  return {
    AND: all,
  };
};

const BidsListContainer = styled.div`
  padding: 19px 24px 31px 52px;
  max-height: 285px;
  overflow-y: scroll;
`;

const getSort = (queryParams: TableState) => {
  let sort: Record<string, any>[] = [];

  if (queryParams.sortBy && queryParams.sortOrder) {
    const field = queryParams.sortBy;
    const direction = queryParams.sortOrder === 'ascend' ? 'ASC' : 'DESC';

    if (field === 'name') {
      sort = [
        {
          user: {
            firstName: direction,
          },
        },
        {
          user: {
            lastName: direction,
          },
        },
      ];
    } else if (field === 'email') {
      sort = [
        {
          user: {
            email: direction,
          },
        },
      ];
    } else if (field) {
      sort = [
        {
          [field]: direction,
        },
      ];
    }
  }

  return sort;
};

const LeadsTableActions = ({ renter, sendEvent }: { renter: Renter; sendEvent: any }) => {
  const { openDialog } = React.useContext(dialogsContext);

  const archiveUser = () => {
    sendEvent({
      variables: {
        event: {
          type: UserEventTypes.ARCHIVE,
          payload: { id: renter?.user?.id },
        },
      },
    });
  };

  const reviveUser = () => {
    sendEvent({
      variables: {
        event: {
          type: UserEventTypes.REVIVE,
          payload: { id: renter?.user?.id },
        },
      },
    });
  };

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() =>
          openDialog({
            name: RENTER_DETAILS_WITH_BIDS_LIST_DIALOG,
            props: {
              renterId: renter?.id,
            },
          })
        }
      >
        Details
      </Menu.Item>
      <If condition={renter?.user?.isBlocked === true}>
        <Menu.Item onClick={reviveUser}>Revive</Menu.Item>
      </If>
      <If condition={renter?.user?.isBlocked === false}>
        <Menu.Item onClick={archiveUser}>Archive</Menu.Item>
      </If>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
      <Icon type="ellipsis" />
    </Dropdown>
  );
};

const ExpandableRow = (renter: Renter) => {
  return (
    <Grid.Layout columns="minmax(auto, 600px) auto minmax(390px, 1fr)" gap="0" css={{ margin: -16, maxHeight: 285 }}>
      <LeadInformation renter={renter} />
      <Divider type="vertical" style={{ height: '100%', margin: 0 }} />
      <BidsListContainer>
        <AllBidsList renterId={renter?.id} />
      </BidsListContainer>
    </Grid.Layout>
  );
};

type LeadsTableProps = {
  query: string;
};

export const AllLeadsTable = ({ query }: LeadsTableProps) => {
  const [{ page, sortBy, sortOrder, pageSize, pageSizeOptions }, onChange]: UseTableStateResponse = useTableState({
    query,
  });

  const leadsRentersListQueryFilter = useLeadsRentersListQueryFilter({ query });

  const [sendEvent, { loading: ongoingEvent }] = useMutationSafe(MUTATION_EVENT, {
    onCompleted: data => {
      const eventType = data?.eventResolver?.inner?.__eventType;
      const msg =
        eventType === UserEventTypes.ARCHIVE
          ? 'Archivation successful'
          : eventType === UserEventTypes.REVIVE
          ? 'User successfully revived'
          : 'Event successfully processed';
      toast.success(msg);
    },
    refetchQueries: ['LeadsRentersListQuery'],
  });

  const { data, loading } = useQuery<{ rentersList: RenterListResponse }>(ALL_LEADS_RENTERS_LIST_QUERY, {
    variables: {
      filter: leadsRentersListQueryFilter,
      sort: getSort({ sortOrder, sortBy, page, pageSize }),
      first: pageSize,
      skip: (page - 1) * pageSize,
    },
  });

  const dataSource = data?.rentersList?.items || [];
  const total = data?.rentersList?.count || pageSize;

  const pagination = { current: page, pageSize, total, showSizeChanger: true, pageSizeOptions };

  const columns: ColumnProps<Renter>[] = React.useMemo(
    () => [
      {
        title: 'Lead Name',
        dataIndex: 'name',
        sorter: dataSource?.length !== 0,
        ellipsis: true,
        sortOrder: sortBy === 'name' ? sortOrder : undefined,
        render: (value, { user }) => getFullName(user?.firstName, user?.lastName),
      },
      {
        title: 'Phone Number',
        dataIndex: 'phone',
        sorter: false,
        ellipsis: true,
        sortOrder: sortBy === 'phone' ? sortOrder : undefined,
        render: function renderPhoneNumber(phone: Maybe<SmartPhone>) {
          return <PhoneLink phone={phone} />;
        },
      },
      {
        title: 'Email Address',
        dataIndex: 'email',
        sorter: dataSource?.length !== 0,
        ellipsis: true,
        sortOrder: sortBy === 'email' ? sortOrder : undefined,
        render: function renderEmail(value, { user }) {
          return <EmailLink email={user?.email} />;
        },
      },
      {
        title: '',
        dataIndex: '',
        width: '43px',
        render: function renderLeadsTableActions(record) {
          return <LeadsTableActions renter={record} sendEvent={sendEvent} />;
        },
      },
    ],
    [dataSource, sortBy, sortOrder, sendEvent],
  );

  return (
    <Table
      rowKey="id"
      loading={loading || ongoingEvent}
      dataSource={dataSource}
      columns={columns}
      onChange={onChange}
      pagination={pagination}
      expandedRowRender={({ ...props }) => <ExpandableRow {...props} />}
    />
  );
};
