import React from 'react';
import { Switch } from 'antd';

import { Maybe, Offer } from '@/shared/types/graphql';
import { DRAFT_STATUS, OFFER_STATUSES } from '@/shared/constants';
import { PureQueryOptions } from 'apollo-client';
import { OFFER_CREATE_DIALOG, OFFER_UPDATE_DIALOG, OFFER_END_DIALOG, OFFER_CANCEL_CONFIRM_DIALOG } from '@/dialogs';
import { dialogsContext } from '@/providers';

type CreateOfferSwitchProps = {
  singleUnit: {
    id?: Maybe<string>;
    status?: Maybe<string>;
    activeOffer?: Maybe<{
      id?: Maybe<string>;
      status?: Maybe<string>;
    }>;
  };
  refetchQueries?: Array<string | PureQueryOptions>;
};

export const isOfferSwitchChecked = (offer?: Maybe<Offer>) =>
  !!offer?.id && (offer?.status === OFFER_STATUSES.pending || offer?.status === OFFER_STATUSES.live);

export const CreateOfferSwitch = ({ singleUnit, refetchQueries }: CreateOfferSwitchProps) => {
  const { openDialog } = React.useContext(dialogsContext);

  const activeOffer = singleUnit?.activeOffer;

  if (singleUnit?.status === DRAFT_STATUS) {
    return null;
  }

  const checked = isOfferSwitchChecked(singleUnit?.activeOffer);

  const handleSwitchToggle = (untoggled: boolean) => {
    if (untoggled) {
      if (activeOffer?.status === OFFER_STATUSES.draft) {
        openDialog({
          name: OFFER_UPDATE_DIALOG,
          props: { singleUnitId: singleUnit?.id, offerId: activeOffer?.id, refetchQueries },
        });
      } else if (
        activeOffer?.status === OFFER_STATUSES.ended ||
        activeOffer?.status === OFFER_STATUSES.closed ||
        activeOffer?.status === OFFER_STATUSES.secured
      ) {
        openDialog({
          name: OFFER_CANCEL_CONFIRM_DIALOG,
          props: {
            offerId: activeOffer?.id,
            singleUnitId: singleUnit?.id,
            status: activeOffer?.status,
            refetchQueries: Array.from(
              new Set([
                ...(refetchQueries || []),
                'PortfolioSingleUnitsList',
                'CommunitySingleUnitList',
                'SingleUnit',
                'OfferDetails',
              ]),
            ),
          },
        });
      } else {
        openDialog({ name: OFFER_CREATE_DIALOG, props: { singleUnitId: singleUnit?.id, refetchQueries } });
      }
    } else {
      openDialog({
        name: OFFER_END_DIALOG,
        props: {
          offerId: activeOffer?.id,
          refetchQueries: Array.from(
            new Set([
              ...(refetchQueries || []),
              'PortfolioSingleUnitsList',
              'CommunitySingleUnitList',
              'SingleUnit',
              'OfferDetails',
            ]),
          ),
        },
      });
    }
  };

  return <Switch checked={checked} onChange={handleSwitchToggle} data-e2e-id="switch-toggle" />;
};
