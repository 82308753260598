import React from 'react';
import { Tag as AntTag } from 'antd';
import { TagProps as AntTagProps } from 'antd/lib/tag';
import styled from '@/theme/styled';

type TagProps = AntTagProps & {
  color?: 'green' | 'red' | 'orange';
  type?: 'outlined' | 'raised';
};

const RAISED_COLORS = {
  green: '#3AC430',
  red: '#FF3100',
  orange: '#FA8C16',
};

const OutlinedTag = styled(AntTag)`
  margin-right: 0;
`;

const RaisedTag = styled(AntTag)`
  margin-right: 0;
  color: #fff;
  font-weight: 600;
`;

export const Tag: React.FC<TagProps> = ({ color, children, type = 'outlined', ...rest }) => {
  if (type === 'outlined') {
    return (
      <OutlinedTag {...rest} color={color}>
        {children}
      </OutlinedTag>
    );
  } else {
    return (
      <RaisedTag {...rest} color={color && RAISED_COLORS[color]}>
        {children}
      </RaisedTag>
    );
  }
};
