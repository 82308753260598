import { styled } from '@/theme';

export const LoaderContainer = styled.div`
  height: 500px;
`;

export const FormContainer = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 11px;
  grid-row-gap: 16px;
`;

export const FullRowBlock = styled.div`
  grid-column: 1 / -1;
`;

export const FormTitle = styled.div`
  color: #f37a22;
  font-family: Poppins;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.4rem;
`;

export const FieldWrapper = styled.div`
  margin-bottom: 12px;
`;
