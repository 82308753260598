import gql from 'graphql-tag';

export const USER_ACCOUNT_UPDATE = gql`
  mutation UserAccountUpdate($data: UserUpdateInput!, $filter: UserKeyFilter) {
    userUpdate(data: $data, filter: $filter) {
      id
      firstName
      lastName
      email
      timezone
      phone {
        code
        number
      }
      avatar {
        id
        downloadUrl
      }
      company {
        id
        name
        type
        companyLogo {
          id
          downloadUrl
        }
      }
      calendarLink
    }
  }
`;
