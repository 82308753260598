// @flow

import React from 'react';

import { FlexLayoutTag, FlexLayoutProps, FlexLayoutCommonProps } from './FlexLayout.styles';

const FlexLayout: React.FC<FlexLayoutProps> = props => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return <FlexLayoutTag {...props} />;
};

FlexLayout.defaultProps = {
  tagName: 'div',
  gap: 'sm',
  direction: 'row',
  justify: 'start',
  align: 'start',
  flexWrap: 'nowrap',
  offset: 'none',
  cursor: 'inherit',
  stretch: false,
  grow: false,
  growChildren: false,
};

const RowFlex: React.FC<FlexLayoutCommonProps> = props => <FlexLayout {...props} direction="row" />;

const ColumnFlex: React.FC<FlexLayoutCommonProps> = props => <FlexLayout {...props} direction="column" />;

export { RowFlex, ColumnFlex, FlexLayout };
