import { useAuth } from '8base-react-sdk';
import { useQuery, useSubscription } from 'react-apollo';

import { User } from '@/shared/types/graphql';
import { CURRENT_USER_QUERY, USER_BLOCK_SUB } from '@/shared/graphql/user';

export const useCurrentUser = () => {
  const { isAuthorized } = useAuth();
  const { data, ...rest } = useQuery<{ user: User }>(CURRENT_USER_QUERY, { skip: !isAuthorized });

  const user = data?.user;

  return { user, ...rest };
};

export const useCurrentUserBlockedSub = () => {
  const userData = useCurrentUser();
  const subReply = useSubscription(USER_BLOCK_SUB, { skip: userData.loading });

  const isBlocked = (function reduceIsBlocked() {
    const isBlockedRep = subReply?.data?.Users?.node?.isBlocked;

    if (typeof isBlockedRep === 'boolean') {
      return isBlockedRep;
    }

    // this may happen if you don't have field-read permission
    if (isBlockedRep === null) {
      const isBlockedPrev = subReply?.data?.Users?.previousValues?.isBlocked;
      const isBlockedUpdated: string = (subReply?.data?.Users?.updatedFields || []).find(
        (field: string) => field === 'isBlocked',
      );
      if (isBlockedUpdated && typeof isBlockedPrev === 'boolean') {
        return !isBlockedPrev;
      }
    }

    return userData?.user?.isBlocked || false;
  })();

  /**
   * Shomehow graphQL refetches/invalidates the currentUser on its-own after sub message!
   * Clever girl!
   */

  // (function invalidateIsBlocked() {
  //   const globalRef = window as any;
  //   const userIsBlocked = userData?.user?.isBlocked;
  //   const blockDesync =
  //     (userIsBlocked === false && isBlocked === true) || (userIsBlocked === true && isBlocked === false);

  //   if (blockDesync && globalRef._desyncedBlock === undefined) {
  //     globalRef._desyncedBlock = true;
  //     alert(isBlocked ? 'User has been blocked!' : 'User has been pardoned!');
  //     window.location.reload(true);
  //   }
  // })();

  return { userData, subReply, isBlocked };
};
