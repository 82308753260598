import gql from 'graphql-tag';

export const CREATE_SINGLE_UNIT_MUTATION = gql`
  mutation createSingleUnit($data: SingleUnitCreateInput!) {
    singleUnitCreate(data: $data) {
      id
      name
      type
      features
      address {
        state
        street1
        street2
        zip
        country
        city
      }
      updatedAt
      amenity {
        items {
          id
        }
      }
    }
  }
`;

export const CREATE_SINGLE_COMMUNITY_QUERY = gql`
  query CreateSingleCommunity($id: ID!) {
    community(id: $id) {
      id
      name
    }
  }
`;
