import { StepsData } from './types';

import Onboarding1 from '@/static/Onboarding1.svg';
import Onboarding2 from '@/static/Onboarding2.png';
import Onboarding3 from '@/static/Onboarding3.png';
import Onboarding4 from '@/static/Onboarding4.png';

export const ONBOARDING_STEPS: StepsData = [
  {
    title: 'Welcome to Livo',
    description:
      "Welcome! Thanks for choosing Livo. Livo's set-up is easy and simple to use. Simply follow our onboarding steps and start using Livo today!",
    image: Onboarding1,
    step: 0,
  },
  {
    title: 'Rental Inventory',
    description:
      'Easily delineate between your standard and Livo Transactions. Rentals can be moved back and forth from standard to Livo Transactions.',
    image: Onboarding2,
    step: 1,
  },
  {
    title: 'Manage your showings, applications, screenings, offers, and lease execution all with LivoSecure™',
    description: 'We are here to help and can answer any questions you may have. QUESTIONS? CALL: 833.333.LIVO (5486)',
    image: Onboarding3,
    step: 2,
  },
  {
    title: 'Capture the power of your newly generated LivoLeads™. Let’s Get started.',
    image: Onboarding4,
    step: 3,
  },
];
