import React from 'react';
import { Modal } from 'antd';
import { MutationHandleSendNotificationArgs, SuccessResponse } from '@/shared/types/graphql';
import { ModalFooter, SelectField } from '@/components';
import { Form, Field } from '@8base-react/forms';
import { HandleSendNotificationMutation } from '@/shared/graphql';
import { toast } from 'react-toastify';
import { emailRegexp } from '@/shared/validators';
import { useMutationSafe, useDialogState } from '@/shared/hooks';

export const INVITE_RENTERS_DIALOG = 'INVITE_RENTERS_DIALOG';

export const InviteRentersDialog = () => {
  const { close, closeWithConfirm, isVisible, props } = useDialogState(INVITE_RENTERS_DIALOG);

  const offerId = props?.offerId;

  const [handleSendNotification, { loading }] = useMutationSafe<
    { handleSendNotification: SuccessResponse },
    MutationHandleSendNotificationArgs
  >(HandleSendNotificationMutation);

  const onSubmit = async (data: any) => {
    try {
      await handleSendNotification({
        variables: {
          payload: {
            notificationType: 'inviteMultipleRenters',
            notificationTypeData: {
              offerId,
              emailList: data?.emails,
            },
          },
        },
      });
      close();
      return toast.success('Successfully sent!');
    } catch (e) {
      toast.error('Something went wrong during an email invitation');
    }
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Form type="UPDATE" onSubmit={onSubmit}>
      {({ handleSubmit, pristine }) => (
        <Modal
          title="Invite Renters"
          visible={isVisible}
          onCancel={pristine ? close : closeWithConfirm}
          width="100%"
          style={{ maxWidth: 400 }}
          footer={
            <ModalFooter
              cancelProps={{
                onClick: pristine ? close : closeWithConfirm,
              }}
              okProps={{
                disabled: pristine || loading,
                loading: false,
                onClick: event => handleSubmit(event),
                type: 'primary',
                text: 'Invite',
              }}
            />
          }
          centered
        >
          <Field
            name="emails"
            label="Emails"
            note="You can add multiple emails at once"
            showDropdown={false}
            component={SelectField}
            parse={values => values?.map((emailValue: string) => emailValue?.trim())}
            format={value => value?.map((emailValue: string) => (emailValue?.trim().length !== 0 ? emailValue : ''))}
            validate={value => (value?.some((el: any) => !emailRegexp.test(el)) ? 'Invalid email address' : undefined)}
            mode="tags"
            stretch
          />
        </Modal>
      )}
    </Form>
  );
};
