import styled from '@/theme/styled';

export const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(400px, 560px) minmax(520px, 1fr);
  grid-gap: 100px;
  padding: 3.2rem 2.4rem;

  @media (max-width: ${({ theme }) => theme.vars.breakpoints.lg.max}) {
    grid-gap: 20px;
  }

  @media (max-width: ${({ theme }) => theme.vars.breakpoints.md.max}) {
    grid-template-columns: 100%;
  }

  @media (max-width: ${({ theme }) => theme.vars.breakpoints.sm.max}) {
    padding: 0;
  }
`;

export const AppartmentProperties = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
`;

export const AppartmentOptions = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 16px;
  }

  @media (max-width: ${({ theme }) => theme.vars.breakpoints.sm.max}) {
    display: grid;
    grid-gap: 20px;
  }
`;

export const PropertyBackButton = styled.div`
  margin-bottom: 20px;
`;

export const PropertyType = styled.span`
  text-transform: uppercase;
  font-weight: 600;
  margin-right: 25px;
  color: #929ea8;
`;

export const PropertyName = styled.span`
  text-transform: uppercase;
  color: ${props => props.theme.vars.black};
  font-size: 18px;
  line-height: 27px;
`;

export const OfferSubTitle = styled.span`
  @media (max-width: 992px) {
    text-align: center;
    width: 100%;
  }
`;

export const OfferTitle = styled.span<{ isPrimaryColor?: boolean }>`
  color: ${props => (props.isPrimaryColor ? props.theme.vars.primaryColor : props.theme.vars.black)};
  font-size: 20px;
  font-weight: 600;

  @media (max-width: 992px) {
    font-size: 26px;
    line-height: 32px;
    text-align: center;
    font-weight: 500;
    width: 100%;
  }
`;

export const OfferPropertyInfo = styled.div`
  @media (max-width: ${({ theme }) => theme.vars.breakpoints.sm.max}) {
    padding: 0 1.5rem;
  }
`;

export const OfferAuctionContainer = styled.div`
  margin-top: 20px;

  @media (max-width: ${({ theme }) => theme.vars.breakpoints.sm.max}) {
    padding: 0 1.5rem 2rem;
  }
`;

export const AnnouncingTitle = styled.div`
  color: ${({ theme }) => theme.vars.primaryColor};
  font-weight: 500;
  font-size: 50px;
  letter-spacing: 0.78px;
  line-height: 58px;

  @media (max-width: ${({ theme }) => theme.vars.breakpoints.sm.max}) {
    font-size: 18px;
    line-height: 27px;
  }
`;
