import React from 'react';
import { WizardNavigation } from '@/components/CompanyWizard/WizardNavigation';
import styled from '@/theme/styled';
import { StepProps } from '@/components/CompanyWizard/types';

type CompanyWizardBodyProps = {
  steps: StepProps[];
  stepNumber: number;
  formProps: any;
};

const StepContent = styled.div`
  flex: 1;
  margin-left: 100px;
`;

const Container = styled.div`
  display: flex;
`;

export const CompanyWizardBody: React.FC<CompanyWizardBodyProps> = ({ steps, stepNumber, formProps }) => {
  const { component: Component } = steps[stepNumber];

  return (
    <Container>
      <WizardNavigation steps={steps} stepNumber={stepNumber} />
      <StepContent>
        <Component values={formProps.values} change={formProps.form.change} initialValues={formProps.initialValues} />
      </StepContent>
    </Container>
  );
};
