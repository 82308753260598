import React from 'react';
import { Form } from '8base-react-sdk';
import { StepProps } from './types';
import { COMPANY_STATUSES } from '@/shared/constants';
import { validateEntityDataByDraft } from '@/shared/utils';

type CompanyWizardRenderProps = {
  stepNumber: number;
  setStepNumber: (callback: (stepNumber: number) => number) => void;
  formProps: any;
  steps: StepProps[];
};

type CompanyWizardProps = {
  type: 'CREATE' | 'UPDATE';
  tableSchemaName: string;
  steps: StepProps[];
  initialValues?: any;
  onSubmit: (data: any) => void;

  children: (props: CompanyWizardRenderProps) => React.ReactNode;
};

export const CompanyWizard = ({
  type,
  tableSchemaName,
  steps,
  initialValues = {},
  onSubmit,
  children,
  ...rest
}: CompanyWizardProps) => {
  const [stepNumber, setStepNumber] = React.useState(0);

  const maxStepNumber = steps.length - 1;
  const isLastStepNumber = stepNumber === maxStepNumber;

  const incrementStep = React.useCallback(() => {
    setStepNumber(s => s + 1);
  }, []);

  const onFormSubmit = async (data: any) => {
    if (isLastStepNumber || data.status === COMPANY_STATUSES.draft) {
      await onSubmit(data);
    } else {
      incrementStep();
    }
  };

  return (
    <Form
      type={type}
      tableSchemaName={tableSchemaName}
      onSubmit={onFormSubmit}
      initialValues={initialValues}
      validate={async values => {
        const { schema } = steps[stepNumber];

        return await validateEntityDataByDraft(schema, values);
      }}
    >
      {formProps =>
        children({
          steps,
          formProps,
          stepNumber,
          setStepNumber,
        })
      }
    </Form>
  );
};
