import React from 'react';
import { Dropdown, Icon, Menu } from 'antd';

import { INVITATION_RESEND_MUTATION } from '../queries';
import { useMutationSafe } from '@/shared/hooks';
import { getApplicationRole } from '@/shared/utils';
import { User } from '@/shared/types/graphql';
import { APPLICATION_ROLE_KEYS, TOAST_SUCCESS_MESSAGE } from '@/shared/constants';
import { dialogsContext } from '@/providers';
import { OptionItem } from '@/components/SelectField/SelectField';
import { TEAM_MEMBER_CONFIRM_DIALOG, TEAM_MEMBER_DELETE_DIALOG, TEAM_MEMBER_UPDATE_DIALOG } from '@/dialogs';

interface TeamTableActionsProps {
  value: string;
  record: User;
  isAdmin: boolean;
  isCompanyOwner: boolean;
  rolesOptions?: any;
  applicationRole?: any;
  propertiesOptions?: Array<OptionItem>;
  invitationCancel: any;
}
export const TeamTableActions: React.FC<TeamTableActionsProps> = props => {
  const { record, isAdmin, rolesOptions, propertiesOptions, applicationRole, invitationCancel } = props;

  const [invitationResend] = useMutationSafe(INVITATION_RESEND_MUTATION, {
    context: {
      [TOAST_SUCCESS_MESSAGE]: 'Invitation Successfully Resent',
    },
  });

  const { openDialog } = React.useContext(dialogsContext);

  const teamMemberRole = getApplicationRole(record);

  const rolesAllowedToSet = isAdmin
    ? rolesOptions.filter(
        (role: any) =>
          role.value !== APPLICATION_ROLE_KEYS.livoAdmin && role.value !== APPLICATION_ROLE_KEYS.livoEmployee,
      )
    : rolesOptions;

  const allowedToEdit = !isAdmin
    ? applicationRole?.allowedToInvite?.some((allowedRoleKey: any) => allowedRoleKey === teamMemberRole?.key)
    : rolesOptions?.some((role: any) => role?.value === teamMemberRole?.key);

  const onResend = React.useCallback(
    () =>
      openDialog({
        name: TEAM_MEMBER_CONFIRM_DIALOG,
        props: { type: 'Resend', confirmAction: () => invitationResend({ variables: { id: record?.invitation?.id } }) },
      }),

    [openDialog, invitationResend, record],
  );

  const onCancel = React.useCallback(
    () =>
      openDialog({
        name: TEAM_MEMBER_CONFIRM_DIALOG,
        props: { type: 'Cancel', confirmAction: () => invitationCancel({ variables: { id: record?.invitation?.id } }) },
      }),

    [openDialog, invitationCancel, record],
  );

  const onDelete = React.useCallback(
    () =>
      openDialog({
        name: TEAM_MEMBER_DELETE_DIALOG,
        props: { userId: record?.id, firstName: record?.firstName, lastName: record?.lastName },
      }),
    [openDialog, record],
  );

  const onEdit = React.useCallback(
    () =>
      openDialog({
        name: TEAM_MEMBER_UPDATE_DIALOG,
        props: { userId: record?.id, companyType: record?.company?.type, rolesAllowedToSet, propertiesOptions },
      }),

    [openDialog, record, rolesAllowedToSet, propertiesOptions],
  );

  const statusIsInvitationPending = Boolean(record.status === 'invitationPending');

  const menu = (
    <Menu>
      {allowedToEdit && <Menu.Item onClick={onEdit}>Edit</Menu.Item>}
      {!statusIsInvitationPending && <Menu.Item onClick={onDelete}>Delete</Menu.Item>}
      {statusIsInvitationPending && <Menu.Item onClick={onResend}>Resend</Menu.Item>}
      {statusIsInvitationPending && <Menu.Item onClick={onCancel}>Cancel</Menu.Item>}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
      <Icon type="ellipsis" />
    </Dropdown>
  );
};
