import gql from 'graphql-tag';

export const RENTER_DETAILS_WITH_BID_MODAL_RENTER_QUERY = gql`
  query RenterDetailsWithBidModalRenterQuery($renterId: ID!, $offerId: ID!) {
    renter(id: $renterId) {
      id
      address {
        street1
        street2
        zip
        city
        state
      }
      phone {
        code
        number
      }
      user {
        id
        firstName
        lastName
        email
        avatar {
          id
          downloadUrl
        }
      }
      bid(filter: { offer: { id: { equals: $offerId } } }) {
        items {
          id
          status
          rentAmount
          termsOfLease
          moveinDate
          offerAccepted
          applicationFormComplete
          screeningComplete
          leaseSigned
          depositCheck
          renter {
            id
            westernReportingScreenings {
              items {
                id
                status
                reportURL
                screeningID
                createdAt
              }
            }
          }
          offer {
            id
            status
            screeningLink
            livoManagesScreening
            approveOfferStartTime
            timeframeToSignContract
            historyOfferSingleUnit {
              id
              company {
                id
              }
            }
            leaseContract {
              id
              fileId
              filename
              downloadUrl
              meta
            }
            leaseLink
          }
        }
      }
    }
  }
`;
