import React from 'react';
import styled from '@/theme/styled';
import { Icon } from 'antd';

import { DeletePreviewButton } from '@/components';
import { cropFileName, getIconByMimetype, getDocumentTypeByMimetype, createFilePreviewLink } from '@/shared/utils';

// TODO: use theme
const DocumentPreviewContainer = styled.div`
  border: 2px solid #cfd7de;
  border-radius: 5px;
  overflow: hidden;
`;

const DocumentPreviewHeading = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  height: 39px;
  padding: 0 15px;
  border-bottom: 2px solid #cfd7de;
`;

const DocumentPreviewName = styled.span`
  color: #878c93;
  font-size: 1.3rem;
`;

const DocumentPreviewBody = styled.div`
  height: 190px;
  padding-top: 23px;
  text-align: center;
`;

const DocumentPreviewImg = styled.img`
  position: relative;
  top: 23px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
`;

/*
  why are there two places to check for mimetype?
  because this is how file object looks when we just upload new file
  {
    fileId: "as12ldk91fj3al3sd",
    downloadUrl: "https://...",
    filename: "awesomefile.jpg",
    mimetype: "image/jpg"
  }
  and this is how it looks when you fetch it from 8base
  {
    id: "as12ldk91fj3al3sd",
    downloadUrl: "https://...",
    filename: "awesomefile.jpg",
    meta: {
      ...,
      mimetype: "image/jpg",
    }
  }
  we can only access it through "meta" property
*/

type DocumentPreviewProps = {
  document: Record<string, any>;
  handleDelete?: (id: string) => void;
  previewProps: Record<string, any>;
};

export const DocumentPreview = ({ document, handleDelete, previewProps }: DocumentPreviewProps) => (
  <DocumentPreviewContainer key={document.downloadUrl}>
    <DocumentPreviewHeading>
      <Icon component={getIconByMimetype(document)} style={{ marginRight: 8, fontSize: '2rem' }} />
      <a href={document.downloadUrl} target="_blank" rel="noopener noreferrer">
        <DocumentPreviewName>
          {cropFileName({
            name: document.filename,
            type: getDocumentTypeByMimetype(document),
            length: 18,
          })}
        </DocumentPreviewName>
      </a>
      {handleDelete && <DeletePreviewButton onClick={() => handleDelete(document.fileId)} />}
    </DocumentPreviewHeading>
    <DocumentPreviewBody>
      <DocumentPreviewImg src={createFilePreviewLink(document.downloadUrl, previewProps)} />
    </DocumentPreviewBody>
  </DocumentPreviewContainer>
);
