import React from 'react';
import styled from '@emotion/styled';
import { Steps, Icon } from 'antd';

import { StepProps } from './types';

const { Step } = Steps;

export const List = styled.div`
  max-width: 392px;
  width: 100%;

  .ant-steps-item.ant-steps-item-wait,
  .ant-steps-item.ant-steps-item-finish {
    .ant-steps-item-content {
      padding-bottom: 45px;
    }
  }
`;

type WizardNavigationProps = {
  steps: StepProps[];
  stepNumber: number;
};

export const WizardNavigation = ({ steps, stepNumber }: WizardNavigationProps) => {
  return (
    <List>
      <Steps direction="vertical" current={stepNumber}>
        {steps.map(({ title, icon: StepIcon, description }, i) => (
          <Step
            key={title}
            title={title}
            description={stepNumber === i && description}
            icon={<Icon component={StepIcon} />}
          />
        ))}
      </Steps>
    </List>
  );
};
