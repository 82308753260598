import React from 'react';
import { AvatarContainer, Content, InformationDocuments } from '@/routes/management/company/entity/profile/styles';
import { Avatar } from 'antd';
import { css } from '@emotion/core';
import { CompanyStatusTag, EntityOption, InformationSection } from '@/pro';
import { DocumentPreview, EmailLink, Grid, PhoneLink, WebsiteLink } from '@/components';
import {
  BILLING_TYPES,
  COMPANY_STATUSES,
  MDU_TYPES,
  ONLINE_APPLICATION_PROCESS,
  SCREENING_PROCESS,
  US_STATES_LIST,
} from '@/shared/constants';
import { COMPANY_TYPE_LABELS } from 'livo-shared';
import { stringifyVirtualShowings } from '@/shared/utils';
import { Scalars } from '@/shared/types/graphql';
import { stringifyIlsOrPms } from '@/shared/utils';
import { stringifyScreeningProcess } from '@/shared/utils';
import { stringifyOnlineApplicationProcess } from '@/shared/utils';
type CompanyProfileInfoProps = {
  company: any;
};

export const CompanyProfileInfo: React.FC<CompanyProfileInfoProps> = ({ company }) => {
  const companyLogo = company?.companyLogo?.downloadUrl ?? '';

  // corporate information
  const id = company?.clientId ?? '';
  const name = company?.name ?? '';
  const status = company?.status ?? '';
  const type = company?.type ?? '';
  const mduType = company?.mdu?.type ?? '';
  const companySize = company?.companySize ?? 0;
  const billing = company?.mdu?.billing ?? '';

  // contact information
  const contactName = company?.contactName ?? '';
  const contactEmail = company?.contactEmail ?? '';
  const address = `${company?.primaryAddress?.street1 ?? ''} ${company?.primaryAddress?.street2 ?? ''}`.trim();
  const zip = company?.primaryAddress?.zip ?? '';
  const city = company?.primaryAddress?.city ?? '';
  const state = company?.primaryAddress?.state ?? '';

  // software and services
  const isUsingIls = company?.isUsingIls ?? false;
  const isUsingPms = company?.isUsingPms ?? false;
  const pms = company?.companyPropertyManagementSoftwareSystems;
  const ils = company?.companyListingServices;
  const onlineApplicationProcess = company?.onlineApplicationProcess ?? '';
  const screeningProcess = company?.screeningProcess ?? '';
  const virtualShowings = company?.virtualShowings ?? '';

  // compliance
  const mits = company?.mits;

  // documents
  const documents = company?.documents?.items ?? [];

  return (
    <Content>
      <AvatarContainer>
        <Avatar
          size={240}
          src={companyLogo}
          alt={name}
          css={css`
            img {
              position: absolute;
              left: 50%;
              top: 50%;
              object-fit: contain;
              width: 70%;
              height: 70%;
              transform: translate(-50%, -50%);
            }
          `}
        >
          {name}
        </Avatar>
      </AvatarContainer>
      <InformationSection header="Corporate Information">
        <Grid.Layout gap="20px">
          <EntityOption type="uppercase-label" label={type === 'mdu' ? 'Name' : 'Company name'} value={name} />
          <EntityOption type="uppercase-label" label="id" value={id} />
          <EntityOption
            type="uppercase-label"
            label="Status"
            value={<CompanyStatusTag status={COMPANY_STATUSES[status]} />}
          />
          <EntityOption type="uppercase-label" label="Market" value={type && (COMPANY_TYPE_LABELS as any)[type]} />
          {mduType && <EntityOption type="uppercase-label" label="Multi-Family Type" value={MDU_TYPES[mduType]} />}
          <EntityOption type="uppercase-label" label="Website" value={<WebsiteLink website={company?.website} />} />
          <EntityOption
            type="uppercase-label"
            label={type === 'mdu' ? 'Number of rental units' : 'Number of Doors Managed'}
            value={companySize}
          />
          {type === 'mdu' && (
            <EntityOption
              type="uppercase-label"
              label="Number of Properties Managed"
              value={company?.mdu?.numberOfPropertiesManaged}
            />
          )}
          {billing && <EntityOption type="uppercase-label" label="Billing" value={BILLING_TYPES[billing].label} />}
        </Grid.Layout>
      </InformationSection>
      <InformationSection header="Contact Information">
        <Grid.Layout gap="20px">
          <EntityOption type="uppercase-label" label="Address" value={address} />
          <EntityOption type="uppercase-label" label="City" value={city} />
          <EntityOption type="uppercase-label" label="Zip" value={zip} />
          <EntityOption type="uppercase-label" label="State" value={US_STATES_LIST[state]} />
          <EntityOption
            type="uppercase-label"
            label="Primary Phone Number"
            value={<PhoneLink phone={company?.primaryPhoneNumber} />}
          />
          <EntityOption type="uppercase-label" label="CONTACT NAME" value={contactName} />
          <EntityOption type="uppercase-label" label="CONTACT EMAIL" value={<EmailLink email={contactEmail} />} />
          <EntityOption
            type="uppercase-label"
            label="CONTACT PHONE NUMBER"
            value={<PhoneLink phone={company?.contactPhoneNumber} />}
          />
        </Grid.Layout>
      </InformationSection>
      <InformationSection header="Software & Services">
        <Grid.Layout gap="20px">
          <EntityOption type="uppercase-label" label="Pms" value={isUsingPms ? stringifyIlsOrPms(pms) : 'No'} />
          <EntityOption type="uppercase-label" label="ILS/MLS" value={isUsingIls ? stringifyIlsOrPms(ils) : 'No'} />
          <EntityOption
            type="uppercase-label"
            label="Online Application Process"
            value={stringifyOnlineApplicationProcess(onlineApplicationProcess)}
          />
          <If condition={onlineApplicationProcess === ONLINE_APPLICATION_PROCESS.thirdParty}>
            <EntityOption
              type="uppercase-label"
              label="Third-Party Online Application Name"
              value={company?.thirdPartyOnlineApplicationName}
            />
          </If>
          <EntityOption
            type="uppercase-label"
            label="Screening Process"
            value={stringifyScreeningProcess(screeningProcess)}
          />
          <If condition={screeningProcess === SCREENING_PROCESS.thirdParty}>
            <EntityOption
              type="uppercase-label"
              label="Third-Party screening company name"
              value={company?.thirdPartyScreeningCompanyName}
            />
          </If>
          <EntityOption
            type="uppercase-label"
            label="Virtual Showings"
            value={stringifyVirtualShowings(virtualShowings)}
          />
        </Grid.Layout>
      </InformationSection>
      <InformationSection header="Compliance">
        <Grid.Layout gap="20px">
          <EntityOption type="uppercase-label" label="Does it use MITS?" value={mits ? 'Yes' : 'No'} />
        </Grid.Layout>
      </InformationSection>
      <InformationSection header="Documents">
        <Grid.Layout gap="20px">
          <InformationDocuments>
            {documents &&
              documents.map((document: any) => {
                return (
                  <DocumentPreview
                    key={document.id as Scalars['ID']}
                    document={document}
                    previewProps={{
                      output: { f: 'jpg', p: 1 },
                      resize: { width: 150 },
                    }}
                  />
                );
              })}
          </InformationDocuments>
        </Grid.Layout>
      </InformationSection>
    </Content>
  );
};
