import gql from 'graphql-tag';

export const COMMUNITY_SINGLE_UNIT_LIST_QUERY = gql`
  query CommunitySingleUnitList($first: Int, $skip: Int, $filter: SingleUnitFilter, $sort: [SingleUnitSort!]) {
    singleUnitsList(first: $first, skip: $skip, filter: $filter, sort: $sort) {
      items {
        id
        singleUnitId
        name
        status
        leaseStart
        leaseEnd
        leaseRent
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
        company {
          id
          name
        }
        activeOffer {
          id
          status
          bid(sort: { rentAmount: DESC }, first: 1) {
            count
            items {
              id
              rentAmount
              createdAt
            }
          }
        }
        community {
          id
        }
      }
      count
    }
  }
`;
