import React from 'react';
import { Modal, Icon, Typography } from 'antd';
import { toast } from 'react-toastify';

import { ModalFooter } from '@/components';
import { OfferStatusTag } from '@/pro';
import { END_OFFER_TYPES, OFFER_STATUSES } from '@/shared/constants';
import { RedAlert } from '@/static/icons';

import { MUTATION_EVENT, OfferUpdateByEventTypes } from '@/shared/graphql/eventResolver';
import { useMutationSafe, useDialogState } from '@/shared/hooks';
import { dialogsContext } from '@/providers';
import { OFFER_CREATE_DIALOG } from '@/dialogs';

const { Paragraph } = Typography;

export const OFFER_CANCEL_CONFIRM_DIALOG = 'OFFER_CANCEL_CONFIRM_DIALOG';

export const OfferCancelConfirmDialog = () => {
  const { close, isVisible, props } = useDialogState(OFFER_CANCEL_CONFIRM_DIALOG);
  const { openDialog } = React.useContext(dialogsContext);

  const openCreateOfferModal = React.useCallback(() => {
    openDialog({
      name: OFFER_CREATE_DIALOG,
      props: {
        singleUnitId: props.singleUnitId,
        refetchQueries: props.refetchQueries,
      },
    });
  }, [openDialog, props.refetchQueries, props.singleUnitId]);

  const [cancelOffer, { loading }] = useMutationSafe(MUTATION_EVENT, {
    variables: {
      event: {
        type: OfferUpdateByEventTypes.OFFER_END_CLOSE,
        payload: {
          id: props.offerId,
          status: OFFER_STATUSES.canceled,
          endingReason: END_OFFER_TYPES.unavailable,
          otherReason: '',
        },
      },
    },
    onCompleted: () => {
      toast.success('Offer successfully canceled');
      close();
      openCreateOfferModal();
    },
    refetchQueries: props?.refetchQueries || [],
  });

  const title = React.useMemo(
    () => (
      <>
        <Icon
          component={RedAlert}
          css={{ marginRight: '8px', fontSize: '20px', lineHeight: '22px', verticalAlign: 'middle' }}
        />
        Cancel Livo Offer
      </>
    ),
    [],
  );

  if (!isVisible) {
    return null;
  }

  return (
    <Modal
      title={title}
      visible={isVisible}
      onCancel={close}
      footer={
        <ModalFooter
          cancelProps={{
            onClick: close,
            disabled: loading,
          }}
          okProps={{
            onClick: cancelOffer,
            disabled: loading,
            type: 'danger',
            text: 'Cancel Offer',
          }}
        />
      }
      centered
    >
      <Paragraph>
        There is already an <OfferStatusTag status={props.status} /> offer for this property.
      </Paragraph>
      <Paragraph>Are you sure you want to cancel existing offer and create another one?</Paragraph>
    </Modal>
  );
};
