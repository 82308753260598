import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';
import * as R from 'ramda';
import { styled } from '@/theme';
import queryString from 'query-string';

import { PORTFOLIO_COMMUNITIES_QUERY, PORTFOLIO_OFFERS_BY_COMMUNITY_QUERY } from './queries';
import { UseTableStateResponse, useCurrentCompanyId, useTableState } from '@/shared/hooks';
import { stringifyNA, stringifyAddress } from '@/shared/utils';
import { ColumnProps } from 'antd/lib/table';
import {
  Community,
  CommunityListResponse,
  SingleUnitListResponse,
  OfferListResponse,
  SmartAddress,
  Maybe,
  SmartPhone,
  SingleUnit,
} from '@/shared/types/graphql';
import { Table, PhoneLink, EmailLink } from '@/components';
import { ConditionalLink, CommunityStatusTag } from '@/pro';
import { DRAFT_STATUS, OFFER_STATUSES } from '@/shared/constants';
import { useCommunitiesQueryFilter, getSort } from './helpers';
import { CommunityActions } from './components';

const PropertyNameContainer = styled.span`
  display: grid;
  grid-template-columns: 300px auto;
  grid-auto-flow: column;
`;

type CommunitiesTableProps = {
  query: string;
  getSelectedRecords: (records: Array<string>) => void;
  setSelectedRecords: Array<string>;
};

export const CommunitiesTable = ({ query, getSelectedRecords, setSelectedRecords }: CommunitiesTableProps) => {
  const [{ page, sortBy, sortOrder, pageSize, pageSizeOptions }, onChange]: UseTableStateResponse = useTableState({
    query,
  });
  const { companyId } = useCurrentCompanyId();
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  useEffect(() => setSelectedRowKeys(setSelectedRecords), [selectedRowKeys, setSelectedRecords]);

  const communitiesQueryFilter = useCommunitiesQueryFilter({ query, companyId });

  const { data: communitiesResponse, loading: communitiesLoading } = useQuery<{
    communitiesList: CommunityListResponse;
  }>(PORTFOLIO_COMMUNITIES_QUERY, {
    variables: {
      filter: communitiesQueryFilter,
      sort: getSort({ sortBy, sortOrder, page, pageSize }),
      first: pageSize,
      skip: (page - 1) * pageSize,
    },
    fetchPolicy: 'network-only',
  });

  const communitiesList = communitiesResponse?.communitiesList?.items ?? [];

  const { data: offersResponse, loading: offersLoading } = useQuery<{ offersList: OfferListResponse }>(
    PORTFOLIO_OFFERS_BY_COMMUNITY_QUERY,
    {
      variables: {
        filter: {
          AND: [
            {
              activeOfferSingleUnit: {
                community: {
                  id: {
                    in: communitiesList.map(community => community?.id),
                  },
                },
              },
            },
            { OR: [{ status: { equals: 'live' } }, { status: { equals: 'pending' } }] },
          ],
        },
        skip: R.isEmpty(communitiesList),
      },
    },
  );

  const offersGroups: Array<any> = offersResponse?.offersList?.groups ?? [];
  const total = communitiesResponse?.communitiesList?.count;

  const dataSource = communitiesList.map(community => {
    const livoRentalsCount =
      offersGroups.find(({ communityId }: any) => communityId === community?.id)?.offersCount || 0;

    return {
      ...community,
      livoRentalsCount,
    };
  });

  const pagination = { current: page, pageSize, total, showSizeChanger: true, pageSizeOptions };

  const onSelectChange = (selectedRowKeys: any) => {
    getSelectedRecords(selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    onChange: onSelectChange,
    selectedRowKeys,
    getCheckboxProps: (record: any) => ({
      disabled: !record.id,
      name: record.name,
    }),
  };

  const columns: ColumnProps<
    Community & {
      livoRentalsCount: number;
    }
  >[] = React.useMemo(
    () => [
      {
        title: 'id',
        dataIndex: 'communityId',
        sorter: dataSource?.length !== 0,
        width: 150,
        ellipsis: true,
        fixed: 'left',
        sortOrder: sortBy === 'communityId' ? sortOrder : undefined,
      },
      {
        title: 'Property Name',
        dataIndex: 'name',
        sorter: dataSource?.length !== 0,
        width: 400,
        className: 'truncated',
        fixed: 'left',
        ellipsis: true,
        sortOrder: sortBy === 'name' ? sortOrder : undefined,
        render: function renderCommunityLink(name: string, record) {
          return (
            <PropertyNameContainer>
              <ConditionalLink
                ellipsis={true}
                to={`/management/${companyId}/communities/${record.id}/single-units`}
                condition={record.status !== DRAFT_STATUS}
                text={name}
              />
              {record.status === DRAFT_STATUS && (
                <CommunityStatusTag css={{ marginLeft: '10px', textAlign: 'center' }} status={record?.status} />
              )}
            </PropertyNameContainer>
          );
        },
      },
      {
        title: 'Manager Name',
        dataIndex: 'managerName',
        sorter: dataSource?.length !== 0,
        width: 200,
        ellipsis: true,
        sortOrder: sortBy === 'managerName' ? sortOrder : undefined,
        render: function renderManagerName(managerName: Maybe<string>) {
          return stringifyNA(managerName);
        },
      },
      {
        title: 'Manager Email',
        dataIndex: 'managerEmail',
        width: 200,
        ellipsis: true,
        render: function renderManagerEmail(managerEmail: Maybe<string>) {
          return <EmailLink email={managerEmail} />;
        },
      },
      {
        title: 'Manager Phone',
        dataIndex: 'managerPhoneNumber',
        width: 200,
        ellipsis: true,
        render: function renderManagerPhoneNumber(managerPhoneNumber: Maybe<SmartPhone>) {
          return <PhoneLink phone={managerPhoneNumber} />;
        },
      },
      {
        title: 'Property Phone',
        dataIndex: 'phoneNumber',
        width: 200,
        ellipsis: true,
        render: function renderManagerName(phoneNumber: Maybe<SmartPhone>) {
          return <PhoneLink phone={phoneNumber} />;
        },
      },
      {
        title: 'Address',
        dataIndex: 'address',
        width: 250,
        ellipsis: true,
        render: function renderManagerName(address: Maybe<SmartAddress>) {
          return stringifyAddress(address);
        },
      },
      {
        title: 'Units',
        dataIndex: 'singleUnit',
        width: 100,
        ellipsis: true,
        render: function CommunityRentalCell(singleUnits: SingleUnitListResponse, record) {
          const countWithoutDraft = R.compose(
            R.length,
            R.filter<SingleUnit>(singleUnit => singleUnit.status !== DRAFT_STATUS),
            R.pathOr([], ['items']),
          )(singleUnits);
          return (
            <ConditionalLink
              to={`/management/${companyId}/communities/${record.id}/single-units`}
              condition={record.status !== DRAFT_STATUS}
              text={countWithoutDraft}
            />
          );
        },
      },
      {
        title: 'Livo Transactions',
        dataIndex: 'livoRentalsCount',
        width: 150,
        ellipsis: true,
        render: function LivoRentalsCell(livoRentalsCount: number, record) {
          const params = queryString.stringify({
            filter_status: OFFER_STATUSES.live,
            filter_communityId: record?.id,
          });
          return (
            <ConditionalLink
              condition={record.status !== DRAFT_STATUS}
              to={`/management/${companyId}/rentals/single-units?${params}`}
              text={livoRentalsCount}
            />
          );
        },
      },
      {
        title: '',
        dataIndex: 'id',
        width: 43,
        render: function Actions(id: string, record) {
          return <CommunityActions id={id} record={record} />;
        },
      },
    ],
    [dataSource, sortBy, sortOrder, companyId],
  );

  return (
    <Table
      rowKey="id"
      loading={communitiesLoading || offersLoading}
      rowSelection={{ type: 'checkbox', ...rowSelection }}
      dataSource={dataSource}
      columns={columns}
      onChange={onChange}
      pagination={pagination}
      scroll={{ x: 1600 }}
    />
  );
};
