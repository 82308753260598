import React, { ChangeEvent } from 'react';
import { CustomCard, TableHeader } from '@/components';
import { AllLeadsTable } from './AllLeadsTable';
import { useSearch } from '@/shared/hooks';

export const AllLeads = () => {
  const [{ search, throttledSearch }, setSearch] = useSearch();

  const onChange = React.useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearch(event.currentTarget.value);
    },
    [setSearch],
  );

  return (
    <CustomCard
      padding="none"
      header={
        <TableHeader
          title="LivoLeads™"
          searchProps={{ query: search, placeholder: 'Search by name, email', onChange }}
        />
      }
    >
      <AllLeadsTable query={throttledSearch} />
    </CustomCard>
  );
};
