import React from 'react';
import { Icon } from 'antd';
import styled from '@/theme/styled';

import { DeletePreviewButton } from '@/components';
import { cropFileName, getImageTypeByMimetype, getImageIconByMimetype, getCompressedImageUrl } from '@/shared/utils';

const ImagePreviewContainer = styled.div`
  border: 2px solid #cfd7de;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  overflow: hidden;
`;

const ImagePreviewHeading = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  height: 39px;
  padding: 0 15px;
  border-bottom: 2px solid #cfd7de;
`;

const ImagePreviewBody = styled.div`
  height: 125px;
`;

const ImagePreviewImg = styled.img`
  height: 125px;
  width: 100%;
  display: block;
  object-fit: contain;
  pointer-events: none;
`;

const ImagePreviewName = styled.p`
  color: #878c93;
  font-size: 1.3rem;
`;

type ImagePreviewProps = {
  value: Record<string, any>;
  handleDelete: (id: string) => void;
};

export const ImagePreview = ({ value, handleDelete }: ImagePreviewProps) => {
  const compressedImageUrl = getCompressedImageUrl(value?.downloadUrl);
  return (
    <ImagePreviewContainer>
      <ImagePreviewHeading>
        <Icon component={getImageIconByMimetype(value)} style={{ marginRight: 8, fontSize: '2rem' }} />
        <a href={value.downloadUrl} target="_blank" rel="noopener noreferrer">
          <ImagePreviewName>
            {cropFileName({
              name: value.filename,
              type: getImageTypeByMimetype(value),
              length: 10,
            })}
          </ImagePreviewName>
        </a>
        {handleDelete && <DeletePreviewButton onClick={() => handleDelete(value.fileId)} />}
      </ImagePreviewHeading>
      <ImagePreviewBody>
        <ImagePreviewImg src={compressedImageUrl} />
      </ImagePreviewBody>
    </ImagePreviewContainer>
  );
};
