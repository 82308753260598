import { useQueryParams } from '@/shared/hooks/useQueryParams';
import React from 'react';
import * as R from 'ramda';
// eslint-disable-next-line @typescript-eslint/ban-types
export const useFilters = <T extends {}>(): [
  T,
  {
    setFilter: (filterName: keyof T, filterValue: string) => void;
    removeFilter: (filterName: keyof T) => void;
    setAllFilters: (filters: T) => void;
  },
] => {
  const [queryParams, setQueryParams] = useQueryParams();

  const filters: T = Object.keys(queryParams).reduce((accum: any, queryKey) => {
    if (/filter_/.test(queryKey)) {
      const filterKey = queryKey.replace('filter_', '');
      accum[filterKey] = queryParams[queryKey];
    }

    return accum;
  }, {});

  const setFilter = React.useCallback(
    (filterName: keyof T, filterValue: string) => {
      setQueryParams({
        [`filter_${filterName}`]: filterValue,
      });
    },
    [setQueryParams],
  );

  const formatFilters = React.useCallback((filters: T) => {
    return R.pipe(
      R.keys,
      R.reduce((accum: any, filterName) => {
        accum[`filter_${filterName}`] = (filters as any)[filterName];

        return accum;
      }, {}),
    )(filters);
  }, []);

  const setAllFilters = React.useCallback(
    (filters: T) => {
      const queryFilters = formatFilters(filters);

      setQueryParams(queryFilters);
    },
    [formatFilters, setQueryParams],
  );

  const removeFilter = React.useCallback(
    (filterName: keyof T) => {
      setQueryParams({ ...queryParams, [`filter_${filterName}`]: undefined });
    },
    [queryParams, setQueryParams],
  );

  return [filters, { setFilter, removeFilter, setAllFilters }];
};
