import React from 'react';
import { useQuery } from 'react-apollo';
import { toast } from 'react-toastify';

import { AsyncContent, ModalFooter, Scrollable } from '@/components';
import { COMPANY_UPDATE_MUTATION } from '@/shared/graphql';
import { Company, ListingServiceListResponse } from '@/shared/types/graphql';
import { LoadingProvider } from '@/providers';
import { useCurrentUser, useMutationSafe, usePermissions } from '@/shared/hooks';
import { COMPANY_STATUSES } from '@/shared/constants';
import { validateEntityDataByDraft } from '@/shared/utils';
import { CompanySchemaValidation } from '@/schemaValidators';
import { Modal } from 'antd';
import { Form } from '8base-react-sdk';
import { DemographicInformation, CorporateAssets, SoftwareAndServices, Compliance } from './components';
import { COMPANY_UPDATE_INFORMATION_QUERY } from './queries';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const merge = require('deepmerge');

interface DialogProps {
  closeWithConfirm: () => void;
  close: () => void;
  isVisible: boolean;
  companyId?: string;
  refetchQueries?: Array<string>;
}

export const Dialog: React.FC<DialogProps> = ({ close, closeWithConfirm, isVisible, ...props }) => {
  const { user } = useCurrentUser();
  const { isAdmin } = usePermissions();

  const companyId = props.companyId;
  const [companyUpdate] = useMutationSafe(COMPANY_UPDATE_MUTATION, {
    awaitRefetchQueries: true,
    onCompleted: () => {
      toast.success('Client was successfully updated');
      close();
    },
    onError: error => {
      toast.error('Something went wrong');
    },
    refetchQueries: props.refetchQueries,
  });

  const { data, loading } = useQuery<{
    company: Company;
    listingServicesList: ListingServiceListResponse;
  }>(COMPANY_UPDATE_INFORMATION_QUERY, {
    variables: {
      id: companyId || user?.company?.id,
    },
  });

  let INITIAL_VALUES = data?.company ?? {};
  if (!INITIAL_VALUES?.contactPhoneNumber?.code) {
    INITIAL_VALUES = merge(INITIAL_VALUES, {
      contactPhoneNumber: {
        code: '1',
      },
    });
  }
  if (!INITIAL_VALUES?.primaryPhoneNumber?.code) {
    INITIAL_VALUES = merge(INITIAL_VALUES, {
      primaryPhoneNumber: {
        code: '1',
      },
    });
  }
  const isDraft = INITIAL_VALUES.status === COMPANY_STATUSES.draft;

  const onSubmit = React.useCallback(
    async (data: any) => {
      const variables: any = { data };

      if (INITIAL_VALUES.status === COMPANY_STATUSES.draft) {
        variables.force = true;
      }

      await companyUpdate({
        variables,
      });
    },
    [INITIAL_VALUES.status, companyUpdate],
  );

  return (
    <LoadingProvider>
      <Form
        onSubmit={onSubmit}
        tableSchemaName="Company"
        type="UPDATE"
        initialValues={INITIAL_VALUES}
        validate={values => validateEntityDataByDraft(CompanySchemaValidation, values)}
      >
        {({ handleSubmit, submitting, values, form, pristine }) => (
          <Modal
            title="Edit Client"
            visible={isVisible}
            onCancel={pristine ? close : closeWithConfirm}
            afterClose={form.reset}
            centered
            className="livo-modal"
            width="100%"
            style={{ maxWidth: 610 }}
            footer={
              <ModalFooter
                showLeftAction={isDraft && !loading && isAdmin}
                leftActionProps={{
                  disabled: (pristine && !isDraft) || submitting,
                  onClick: event => {
                    form.change('status', COMPANY_STATUSES.draft);

                    return handleSubmit(event);
                  },
                }}
                cancelProps={{
                  onClick: pristine ? close : closeWithConfirm,
                }}
                okProps={{
                  text: isDraft ? 'Add Client' : 'Save Changes',
                  type: 'primary',
                  htmlType: 'submit',
                  disabled: pristine && !isDraft,
                  loading: submitting,
                  onClick: event => {
                    form.change('status', COMPANY_STATUSES.active);

                    return handleSubmit(event);
                  },
                }}
              />
            }
          >
            <Scrollable height="75vh">
              <AsyncContent loading={loading}>
                <DemographicInformation values={values} change={form.change} initialValues={INITIAL_VALUES} />
                <CorporateAssets />
                <SoftwareAndServices values={values} />
                <Compliance />
              </AsyncContent>
            </Scrollable>
          </Modal>
        )}
      </Form>
    </LoadingProvider>
  );
};
