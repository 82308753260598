import React from 'react';
import { OperationVariables } from 'react-apollo';

import { WebsiteLink, Text } from '@/components';
import { Company, File, CommunityListResponse, SingleUnitListResponse } from '@/shared/types/graphql';
import { CompanyStatusTag, ConditionalLink } from '@/pro';
import { COMPANY_STATUSES, DRAFT_STATUS, NOT_AVALIABLE } from '@/shared/constants';
import { css } from '@emotion/core';
import { CompaniesTableActions } from '../ActionsClients';

type CompaniesColumnProps = Company & {
  livoRentalsCount: number;
};

export const renderCompanyLink = (name: string, record: CompaniesColumnProps) => {
  return (
    <div css={{ width: '190px', display: 'flex', alignItems: 'center' }}>
      <Text ellipsis={true}>
        <ConditionalLink to={`/management/${record.id}`} condition={record.status !== DRAFT_STATUS} text={name} />
      </Text>
    </div>
  );
};

export const renderCompanyActions = (
  id: string,
  record: CompaniesColumnProps,
  sendEvent: any,
  variablesForRefetch: OperationVariables,
) => {
  return <CompaniesTableActions company={record} sendEvent={sendEvent} variablesForRefetch={variablesForRefetch} />;
};

export const renderCompanyStatus = (status: string) => {
  return <CompanyStatusTag status={COMPANY_STATUSES[status]} />;
};

export const renderCompanyLogo = (companyLogo: File, record: CompaniesColumnProps) => {
  return (
    companyLogo?.downloadUrl && (
      <div
        css={css`
          margin: -16px;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <img
          css={css`
            max-width: 100px;
            height: 35px;
            object-fit: contain;
          `}
          src={companyLogo?.downloadUrl}
          alt={record.name || ''}
        />
      </div>
    )
  );
};

export const renderWebsite = (link: string) => {
  return <WebsiteLink website={link} />;
};

export const renderClientId = (clientId: any) => {
  return clientId || NOT_AVALIABLE;
};

export const renderProperties = (communities: CommunityListResponse, record: CompaniesColumnProps) => {
  return `${communities?.count}${
    !!record?.mdu?.numberOfPropertiesManaged ? `/${record?.mdu?.numberOfPropertiesManaged}` : ''
  }`;
};

export const renderTotalUnits = (singleUnits: SingleUnitListResponse, record: CompaniesColumnProps) => {
  return `${singleUnits?.count}${!!record?.companySize ? `/${record?.companySize}` : ''}`;
};
