import gql from 'graphql-tag';

export const USER_RENTER_ACCOUNT = gql`
  query UserRenterAccount {
    user {
      id
      avatar {
        id
        downloadUrl
      }
      firstName
      lastName
      email
      renter {
        id
        address {
          country
          street1
          street2
          zip
          city
          state
        }
        phone {
          code
          number
        }
      }
    }
  }
`;

export const UPDATE_USER_RENTER_ACCOUNT = gql`
  mutation UpdateUserRenterAccount($data: UserUpdateInput!, $filter: UserKeyFilter) {
    userUpdate(data: $data, filter: $filter) {
      id
    }
  }
`;
