import { useParams, useHistory } from 'react-router-dom';
import { useCallback } from 'react';
import { useCurrentUser, usePermissions } from '@/shared/hooks';

export const useCurrentCompanyId = () => {
  const { companyId: paramCompanyId = '' } = useParams<{ companyId: string }>();
  const history = useHistory();
  const { isAdmin } = usePermissions();

  const { user } = useCurrentUser();
  const currentUserCompanyId = user?.company?.id || '';

  const changeCompany = useCallback(
    (newCompanyId: string) => {
      if (isAdmin) {
        const newPath = history.location.pathname.replace(`management/${paramCompanyId}`, `management/${newCompanyId}`);

        history.push(newPath + history.location.search);
      }
    },
    [history, isAdmin, paramCompanyId],
  );

  return { companyId: isAdmin ? paramCompanyId : currentUserCompanyId, changeCompany };
};
