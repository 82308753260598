import React from 'react';
import styled from '@emotion/styled';
import { CustomCard, TableHeader } from '@/components';
import { useHistory } from 'react-router-dom';
import { TableWithClients } from '@/shared/pages';

const Container = styled.div`
  grid-area: Content;
  display: grid;
  .ant-card-body {
    &::before,
    &::after {
      display: none;
    }
    display: grid;
    grid-auto-flow: columns;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 104px;
    justify-content: space-between;
    grid-template-areas: 'Table Table';
  }
`;

export const ClientsTable = () => {
  const history = useHistory();

  const viewAllClients = () => {
    history.replace(`/management/companies`);
  };

  const hasColumns = {
    website: true,
  };

  return (
    <Container>
      <CustomCard
        padding="none"
        header={
          <TableHeader
            title="Clients"
            actionProps={{ text: 'View All', icon: { type: 'none' }, onClick: viewAllClients }}
          />
        }
      >
        <TableWithClients hasColumns={hasColumns} scrollX={1200} count={10} filters={{ status: 'active' }} />
      </CustomCard>
    </Container>
  );
};
