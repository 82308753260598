import React from 'react';
import { Modal } from 'antd';
import { Field, Form } from '@8base-react/forms';

import {
  Grid,
  InputField,
  Loader,
  ModalFooter,
  SelectField,
  TextAreaField,
  AddressFields,
  LogoInputField,
  HasMediaInputField,
  InputNumberField,
  AmenitiesSelectField,
} from '@/components';
import { useQuery } from 'react-apollo';
import {
  COMMUNITY_STATUSES,
  DRAFT_STATUS,
  US_STATES_LIST,
  TOAST_SUCCESS_MESSAGE,
  COMMUNITY_TYPE_LABELS,
  PMS_LIST,
} from '@/shared/constants';
import { addAmenities, normalizePhone, validateEntityDataByDraft } from '@/shared/utils';
import { Community, CommunityUpdateInput } from '@/shared/types/graphql';
import { CommunitySchemaValidation } from '@/schemaValidators';
import { COMMUNITY_QUERY, COMMUNITY_UPDATE_MUTATION } from './queries';
import { omit } from 'ramda';
import { useMutationSafe } from '@/shared/hooks';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const merge = require('deepmerge');

interface DialogProps {
  closeWithConfirm: () => void;
  close: () => void;
  isVisible: boolean;
  refetchQueries?: Array<string>;
  id?: string;
  options?: any;
}

export const Dialog: React.FC<DialogProps> = ({ close, closeWithConfirm, isVisible, ...props }) => {
  const id = props?.id;

  const { data, loading } = useQuery<{ community: Community }>(COMMUNITY_QUERY, {
    variables: { id },
    skip: !id,
  });

  const pmsList = React.useMemo(() => PMS_LIST.map(el => ({ value: el, label: el })), []);

  const [communityUpdate] = useMutationSafe(COMMUNITY_UPDATE_MUTATION, {
    onCompleted: close,
    context: {
      [TOAST_SUCCESS_MESSAGE]: 'Property Successfully Edited',
    },
  });

  const coercedStatesOfUSA = React.useMemo(
    () => Object.entries(US_STATES_LIST).map(([value, label]) => ({ value, label: `${value} - ${label}` })),
    [],
  );

  const coercedCommunityTypes = React.useMemo(
    () => Object.entries(COMMUNITY_TYPE_LABELS).map(([value, label]) => ({ value, label })),
    [],
  );

  const onSubmit = React.useCallback(
    async (data: CommunityUpdateInput) => {
      await communityUpdate({
        ...(props.options || {}),
        variables: { data },
      });
    },
    [communityUpdate, props.options],
  );

  const isDraft = data?.community?.status === DRAFT_STATUS;

  let INITIAL_VALUES = omit(['numberId'], data?.community || {});

  if (!INITIAL_VALUES?.managerPhoneNumber?.code) {
    INITIAL_VALUES = merge(INITIAL_VALUES, {
      managerPhoneNumber: {
        code: '1',
      },
    });
  }

  const amenitiesValues: any = data?.community?.communityAmenity || [];

  const getGroupedAmenities = () => {
    return (
      Object.entries(amenitiesValues)?.map(([groupKey, amenity]) => {
        return {
          groupKey,
          amenity,
        };
      }) || []
    );
  };

  const allAmenities: any = getGroupedAmenities() || [];
  const filteredAmenities = allAmenities.filter((el: any) => el?.amenity && Array.isArray(el?.amenity));

  const afterFormatQueryData = (values: any) => {
    const formattedValues: any = values;
    formattedValues.amenities = filteredAmenities ? filteredAmenities : [];
    return formattedValues;
  };

  const onFormat = (data: any) => {
    const resultValues = addAmenities(data, 'community');
    return { ...resultValues };
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Form
      onSubmit={onSubmit as any}
      tableSchemaName="Community"
      type="UPDATE"
      initialValues={INITIAL_VALUES}
      beforeFormatDataForMutation={onFormat}
      afterFormatQueryData={afterFormatQueryData}
      validate={values => {
        return validateEntityDataByDraft(CommunitySchemaValidation, values);
      }}
    >
      {({ handleSubmit, submitting, pristine, form, values }) => (
        <Modal
          title="Edit Property"
          visible={isVisible}
          onCancel={pristine ? close : closeWithConfirm}
          afterClose={form.reset}
          centered
          footer={
            <ModalFooter
              showLeftAction={isDraft && !loading}
              leftActionProps={{
                disabled: (pristine && !isDraft) || submitting,
                onClick: event => {
                  form.change('status', COMMUNITY_STATUSES.draft);

                  return handleSubmit(event);
                },
              }}
              cancelProps={{
                onClick: close,
              }}
              okProps={{
                text: isDraft ? 'Add Property' : 'Save Changes',
                type: 'primary',
                htmlType: 'submit',
                disabled: pristine && !isDraft,
                loading: submitting,
                onClick: event => {
                  form.change('status', COMMUNITY_STATUSES.active);

                  return handleSubmit(event);
                },
              }}
            />
          }
        >
          {loading ? (
            <Loader stretch />
          ) : (
            <Grid.Layout data-e2e-id="modal.community.update.body">
              <Grid.Box>
                <Field
                  name="name"
                  label="Property Name*"
                  autoComplete="disabled"
                  component={InputField}
                  data-e2e-id="modal.community.update.name"
                />
              </Grid.Box>
              <Grid.Box>
                <Field
                  label="Number of Units"
                  name="numberOfUnits"
                  component={InputNumberField}
                  placeholder="e.g. 250"
                  stretch
                  data-e2e-id="modal.community.update.numberOfUnits"
                />
              </Grid.Box>
              <Grid.Box>
                <Field
                  name="managementCompany"
                  label="Management Company"
                  autoComplete="disabled"
                  component={InputField}
                  data-e2e-id="modal.community.update.managementCompany"
                />
              </Grid.Box>
              <Grid.Box>
                <Field
                  name="managerName"
                  label="Contact Name*"
                  autoComplete="disabled"
                  component={InputField}
                  data-e2e-id="modal.community.update.managerName"
                />
              </Grid.Box>
              <Grid.Box>
                <Field
                  name="managerTitle"
                  label="Contact Title"
                  autoComplete="disabled"
                  component={InputField}
                  data-e2e-id="modal.community.update.managerTitle"
                />
              </Grid.Box>
              <Grid.Box>
                <Field
                  name="managerEmail"
                  label="Contact Email"
                  autoComplete="disabled"
                  component={InputField}
                  data-e2e-id="modal.community.update.managerName"
                />
              </Grid.Box>
              <Grid.Box>
                <Field
                  name="managerPhoneNumber.number"
                  label="Contact Phone Number*"
                  autoComplete="disabled"
                  placeholder="(415) 842-2731"
                  mask="(999) 999-9999"
                  width={13}
                  component={InputField}
                  addonBefore={`+1`}
                  parse={normalizePhone}
                  data-e2e-id="modal.community.update.managerPhoneNumber"
                />
              </Grid.Box>
              <Grid.Box>
                <Field
                  name="type"
                  label="Type*"
                  options={coercedCommunityTypes}
                  component={SelectField}
                  stretch
                  data-e2e-id="modal.community.update.type"
                />
              </Grid.Box>
              <Grid.Box>
                <Field
                  name="logo"
                  label="Logo"
                  buttonText="Select File (PNG, JPEG, SVG)"
                  accept={['.png', '.jpg', '.jpeg', '.svg']}
                  component={LogoInputField}
                />
              </Grid.Box>
              <Grid.Box>
                <Field
                  name="description"
                  label="Description"
                  component={TextAreaField}
                  data-e2e-id="modal.community.update.description"
                />
              </Grid.Box>
              <Grid.Box>
                <Field
                  name="amenities"
                  label="Amenities"
                  component={AmenitiesSelectField}
                  unitType="community"
                  stretch
                />
              </Grid.Box>
              <Grid.Box>
                <AddressFields
                  street1="address.street1"
                  street2="address.street2"
                  city="address.city"
                  state="address.state"
                  zip="address.zip"
                  country="address.country"
                  stateOptions={coercedStatesOfUSA}
                  addressNote="At this time Livo.io only supports U.S. based companies."
                  data-e2e-id="modal.community.update.address"
                />
              </Grid.Box>
              <Grid.Box>
                <Field
                  label="Phone Number*"
                  name="phoneNumber.number"
                  autoComplete="disabled"
                  placeholder="(415) 842-2731"
                  mask="(999) 999-9999"
                  width={13}
                  component={InputField}
                  addonBefore={`+1`}
                  parse={normalizePhone}
                  data-e2e-id="modal.community.update.phoneNumber"
                />
              </Grid.Box>
              <Grid.Box>
                <Field
                  label="Website*"
                  name="website"
                  autoComplete="disabled"
                  placeholder="e.g. https://www.livo.io, http://livo.com, etc."
                  component={InputField}
                  data-e2e-id="modal.community.update.website"
                />
              </Grid.Box>
              <Grid.Box>
                <Field
                  label="Property Management Software System"
                  name="communityManagementSoftwareSystem"
                  placeholder="Select"
                  autoComplete="disabled"
                  options={pmsList}
                  component={SelectField}
                  mode="multiple"
                  stretch
                  format={value => value && value.map((el: string) => el)}
                />
              </Grid.Box>
              <Grid.Box>
                <Field
                  name="communityHasMedia"
                  label="Photos"
                  buttonText="Select files (PNG, JPEG, SVG)"
                  maxFiles={100}
                  component={HasMediaInputField}
                  accept={['.png', '.jpg', '.jpeg', '.svg']}
                  previewWidth="1/2"
                  isPublic
                />
              </Grid.Box>
            </Grid.Layout>
          )}
        </Modal>
      )}
    </Form>
  );
};
