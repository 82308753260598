import gql from 'graphql-tag';

export const BIDS_LIST_BY_OFFER_QUERY = gql`
  query BidsListByOfferQuery($first: Int, $filter: BidFilter, $sort: [BidSort!]) {
    bidsList(first: $first, sort: $sort, filter: $filter) {
      items {
        id
        rank
        status
        rentAmount
        termsOfLease
        moveinDate
        offerAccepted
        applicationFormComplete
        screeningComplete
        leaseSigned
        depositCheck
        offer {
          id
          status
          timeframeToSignContract
          approveOfferStartTime
        }
        renter {
          id
          user {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;
