import React from 'react';
import * as R from 'ramda';
import { Divider, Radio } from 'antd';

import { Field } from '8base-react-sdk';

import { TIMEZONE_OPTIONS } from 'livo-shared';
import { SingleUnit } from '@/shared/types/graphql';
import { useCurrentUser } from '@/shared/hooks';

import {
  InputNumberField,
  SelectField,
  SelectUser,
  DatePickerField,
  SwitchField,
  Loader,
  PropertyInfo,
  DateAndTimePickerField,
  Scrollable,
  RadioGroupField,
  Info,
  InputField,
} from '@/components';
import { LoaderContainer, FormContainer, FormTitle, FullRowBlock, FieldWrapper } from '../OfferCreateDialog/styled';

interface ModalBodyProps {
  loading: boolean;
  singleUnit: SingleUnit;
  values: any;
  form: any;
}

export const ModalBody: React.FC<ModalBodyProps> = props => {
  const { loading, singleUnit, values, form } = props;

  const { user } = useCurrentUser();
  const offerCompanyUsers = React.useMemo(() => singleUnit?.company?.user?.items || [], [singleUnit]);

  if (loading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  return (
    <Scrollable data-e2e-id="modal.offer.create.body">
      <PropertyInfo singleUnit={singleUnit} />
      <Divider />
      <FormContainer>
        <FullRowBlock>
          <FormTitle>Create Livo Transaction</FormTitle>
        </FullRowBlock>
        <Field
          label="Rent Amount*"
          name="rentAmount"
          component={InputNumberField}
          stretch
          min={1}
          prefix="$"
          prefixPadding={19}
          data-e2e-id="modal.offer.create.rentAmount"
        />
        <Field
          label="Incremental Bid Amount*"
          name="incrementedBidAmount"
          component={InputNumberField}
          stretch
          min={1}
          prefix="$"
          prefixPadding={19}
          data-e2e-id="modal.offer.create.incrementedBidAmount"
        />
        <Field
          label="Security Deposit*"
          name="securityDeposite"
          component={InputNumberField}
          stretch
          min={1}
          prefix="$"
          prefixPadding={19}
          data-e2e-id="modal.offer.create.securityDeposite"
        />
        <Field
          label="Allow Renter to increase Security Deposit"
          name="securityDepositeEditable"
          component={RadioGroupField}
          stretch
        >
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Field>
        <FieldWrapper>
          <Field
            label="Move-in Date*"
            name="moveinDate"
            timezone={values.timezone}
            component={DatePickerField}
            stretch
            placeholder="MM/DD/YYYY"
            displayFormat="MM/DD/YYYY"
            data-e2e-id="modal.offer.create.moveinDate"
          />
        </FieldWrapper>
        <Field
          label="Allow Renter to change Move-in Date"
          name="moveinDateEditable"
          component={RadioGroupField}
          stretch
          data-e2e-id="modal.offer.create.moveinDateEditable"
        >
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Field>
        <Field
          label="Timezone"
          name="timezone"
          component={SelectField}
          options={TIMEZONE_OPTIONS}
          showSearch
          stretch
          data-e2e-id="modal.offer.create.timezone"
        />
        <Field
          label="Lease Terms*"
          name="termsOfLease"
          component={InputNumberField}
          min={1}
          prefix="Months:"
          prefixPadding={70}
          stretch
          data-e2e-id="modal.offer.create.termsOfLease"
        />
        <Field
          label="Minimum length of lease*"
          name="minimumLeaseLength"
          component={InputNumberField}
          min={1}
          prefix="Months:"
          prefixPadding={70}
          stretch
          data-e2e-id="modal.offer.create.minimumLeaseLength"
        />
        <Field
          label="Maximum length of lease*"
          name="maximumLeaseLength"
          component={InputNumberField}
          min={1}
          prefix="Months:"
          prefixPadding={70}
          stretch
          data-e2e-id="modal.offer.create.maximumLeaseLength"
        />
        <FullRowBlock>
          <Field
            label="Livo Transaction Start Date*"
            name="startImmediate"
            stretch
            data-e2e-id="modal.offer.create.immediate"
            render={({ input, ...restProps }) => {
              const handleOnChange = ({ target: { value } }: any) => {
                if (value) {
                  form.change('startsIn', undefined);
                }
                input.onChange(value);
              };
              return (
                <RadioGroupField {...restProps} input={{ ...input, onChange: handleOnChange }}>
                  <Radio value={true}>Immediate</Radio>
                  <Radio value={false}>Set Livo Transaction Start Date</Radio>
                </RadioGroupField>
              );
            }}
          />
        </FullRowBlock>
        <If condition={!values?.startImmediate}>
          <FullRowBlock>
            <Field
              name="startsIn"
              component={DateAndTimePickerField}
              data-e2e-id="modal.offer.create.startsIn"
              timezone={values.timezone}
              datePickerProps={{
                label: 'Livo Transaction Start Date*',
                stretch: true,
                placeholder: 'MM/DD/YYYY',
                format: 'MM/DD/YYYY',
              }}
              timePickerProps={{
                label: 'Livo Transaction Start Time*',
                stretch: true,
                use12Hours: true,
                format: 'h:mm A',
              }}
            />
          </FullRowBlock>
        </If>
        <FullRowBlock>
          <Field
            name="endsIn"
            component={DateAndTimePickerField}
            data-e2e-id="modal.offer.create.endsIn"
            timezone={values.timezone}
            datePickerProps={{
              label: 'Livo Transaction End Date*',
              stretch: true,
              placeholder: 'MM/DD/YYYY',
              format: 'MM/DD/YYYY',
            }}
            timePickerProps={{
              label: 'Livo Transaction End Time*',
              stretch: true,
              use12Hours: true,
              format: 'h:mm A',
            }}
          />
        </FullRowBlock>
        <Field
          label={
            <span css={{ marginTop: '15px', display: 'block' }}>
              SecureNow™
              <Info title="SecureNow™ is the quickest way to secure your new home. If you would like the bidding process to end and be awarded the lease, simply click SecureNow™ and start the leasing signing process." />
            </span>
          }
          stretch
          name="secureNow"
          component={SwitchField}
          data-e2e-id="modal.offer.create.secureNow"
        />
        <Field
          label={
            <span css={{ marginTop: '15px', display: 'block' }}>
              Re-enable Transaction
              <Info title="Re-enable automatically if no bids have been placed" />
            </span>
          }
          stretch
          name="reenableOffer"
          component={SwitchField}
          data-e2e-id="modal.offer.create.reenableOffer"
        />
        <If condition={values.secureNow}>
          <FullRowBlock>
            <Field
              label="SecureNow™ Rent Amount*"
              name="secureNowAmount"
              component={InputNumberField}
              stretch
              min={1}
              prefix="$"
              prefixPadding={19}
              data-e2e-id="modal.offer.create.secureNowAmount"
            />
          </FullRowBlock>
        </If>
        <FullRowBlock>
          <FormTitle css={{ marginTop: '19px' }}>Renter screening</FormTitle>
        </FullRowBlock>
        <FullRowBlock>
          <Field
            label="Let Livo manage the rental application and screening process?"
            name="livoManagesScreening"
            component={RadioGroupField}
            stretch
            data-e2e-id="modal.offer.create.livoManagesScreening"
            initialValue={true}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No, I want to use my own screening</Radio>
          </Field>
        </FullRowBlock>
        <FullRowBlock>
          <Field
            name="applicationLink"
            label="Application Form link*"
            autoComplete="disabled"
            component={InputField}
            placeholder="e.g. https://www.livo.io, http://livo.com, etc."
            data-e2e-id="modal.offer.create.applicationLink"
          />
        </FullRowBlock>
        <FullRowBlock>
          <FormTitle css={{ marginTop: '19px' }}>Contact</FormTitle>
        </FullRowBlock>
        <FullRowBlock>
          <Field
            label="Please select responsible contact person*"
            name="contact"
            placeholder="Select contact"
            data-e2e-id="modal.offer.create.contact"
            stretch
            showSearch
            render={({ input, ...rest }) => {
              const setValue = input.onChange;

              const handleOnChange = (value: string) => {
                setValue(value);

                const [userId] = value.split(',');
                const user = R.find(R.propEq('id', userId), offerCompanyUsers as Array<any>);
                const calendarLink = R.pathOr('', ['calendarLink'], user);

                form.change('calendarLink', calendarLink);
              };

              input.onChange = handleOnChange;

              return <SelectUser input={input} defaultUser={user} users={offerCompanyUsers} {...rest} />;
            }}
          />
        </FullRowBlock>

        <If condition={values.contact}>
          <FullRowBlock>
            <FormTitle css={{ marginTop: '19px' }}>Calendar Link (to schedule showings)</FormTitle>
          </FullRowBlock>
          <FullRowBlock>
            <Field name="calendarLink" autoComplete="disabled" component={InputField} />
          </FullRowBlock>
        </If>

        <Field
          label="Timeframe for Renter to sign lease*"
          name="timeframeToSignContract"
          component={InputNumberField}
          stretch
          min={1}
          defaultValue={5}
          prefix="Days:"
          prefixPadding={55}
          data-e2e-id="modal.offer.timeframeToSignContract"
        />
      </FormContainer>
    </Scrollable>
  );
};
