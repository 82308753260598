import React from 'react';
import * as R from 'ramda';

import { useAuth } from '8base-react-sdk';
import { useHistory } from 'react-router-dom';

import { storageType, storageKey, redirectType } from '@/shared/types/store';
import Store from '@/shared/utils/Store';

import { Loader } from '@/components';
import { styled } from '@/theme';

const { REACT_APP_WORKSPACE_ID, REACT_APP_API_SUFFIX } = process.env;

type AuthData = {
  isEmailVerified: boolean;
  email: string;
  idToken: string;
};

const ErrorTag = styled.div`
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
`;

export const CallbackContainer = () => {
  const [errorMessage, setErrorMessage] = React.useState(null);

  const store = new Store({ storageType: storageType.Session, storageKey: storageKey.Redirect });
  const redirectAfterAuthCallback = store.get(redirectType.afterAuthCallback);
  const redirectAfterAuthCallbackUrl = R.pathOr('/', ['pathname'], redirectAfterAuthCallback);
  store.drop(redirectType.afterAuthCallback);

  const history = useHistory();
  const auth = useAuth();

  const authenticate = React.useCallback(async () => {
    try {
      const authData: AuthData = await auth.authClient.getAuthorizedData();

      const { isEmailVerified, email, idToken } = authData;

      auth.authClient.setState({
        token: idToken,
        email,
        isEmailVerified,
        workspaceId: REACT_APP_WORKSPACE_ID,
        environmentName: REACT_APP_API_SUFFIX?.slice(1),
      });

      const replaceUrl = !isEmailVerified ? '/auth/confirmation' : redirectAfterAuthCallbackUrl;
      history.replace(replaceUrl);
    } catch (e) {
      setErrorMessage(e.errorDescription);
    }
  }, [auth.authClient, history, redirectAfterAuthCallbackUrl]);

  React.useEffect(() => {
    authenticate();
  }, [authenticate]);

  const onLogout = React.useCallback(async () => {
    await auth.authClient.logout();

    await auth.authClient.purgeState();
  }, [auth]);

  return errorMessage ? (
    <ErrorTag>
      {errorMessage}
      <br />
      <span css={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={onLogout}>
        Try login to another account
      </span>
    </ErrorTag>
  ) : (
    <Loader stretch />
  );
};
