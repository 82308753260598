import React, { ChangeEvent } from 'react';

import { CustomCard, TableHeader } from '@/components';
import { useSearch } from '@/shared/hooks';

import { LeadsTable } from './LeadsTable';

export const Leads = () => {
  const [{ search, throttledSearch }, setSearch] = useSearch({ sortBy: 'name' });

  const onChange = React.useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearch(event.currentTarget.value);
    },
    [setSearch],
  );

  return (
    <CustomCard
      padding="none"
      header={
        <TableHeader
          title="LivoLeads™"
          searchProps={{ query: search, placeholder: 'Search by name, email, status', onChange }}
        />
      }
    >
      <LeadsTable query={throttledSearch} />
    </CustomCard>
  );
};
