// eslint-disable-next-line livo/no-useMutation
import { useMutation } from 'react-apollo';
import { OperationVariables } from '@apollo/react-common';
import { DocumentNode } from 'graphql';
import { MutationHookOptions, MutationTuple } from '@apollo/react-hooks/lib/types';
import { toast } from 'react-toastify';
import { ApolloError } from 'apollo-client';

export const useMutationSafe = <TData = any, TVariables = OperationVariables>(
  mutation: DocumentNode,
  options?: MutationHookOptions<TData, TVariables>,
  ignoreError = false,
): MutationTuple<TData, TVariables> => {
  const hasErrorHandler = options?.onError;
  let formattedOptions = { ...options };
  if (!hasErrorHandler) {
    // If there is no onError handler app may crash on backend error, so we add it by default
    formattedOptions = { ...formattedOptions, onError: (error: ApolloError) => toast.error(error) };
  }
  // We have to disable no-useMutation for this file only
  // eslint-disable-next-line livo/no-useMutation
  return useMutation(mutation, {
    ...formattedOptions,
  });
};
