import React from 'react';
import { NOT_AVALIABLE, COMPANY_STATUSES } from '@/shared/constants';
import { Tag } from '@/components';

const COLOR_BY_TYPE: Record<keyof typeof COMPANY_STATUSES, string> = {
  draft: '',
  active: 'green',
};

type CompanyStatusTagProps = {
  status?: string;
};

export const CompanyStatusTag: React.FC<CompanyStatusTagProps> = ({ status, ...rest }) => {
  if (!status || !(COMPANY_STATUSES as any)[status]) {
    return <>{NOT_AVALIABLE}</>;
  }

  return (
    <Tag {...rest} color={(COLOR_BY_TYPE as any)[status]}>
      {(COMPANY_STATUSES as any)[status]}
    </Tag>
  );
};
