import React from 'react';

import { COMPANY_CREATE_MUTATION } from '@/shared/graphql';
import { LoadingProvider } from '@/providers';
import { COMPANY_STATUSES } from '@/shared/constants';
import { Modal } from 'antd';
import { CreateCompanyWizard } from '@/pro';
import { CompanyWizardBody, CompanyWizardFooter } from '@/components/CompanyWizard';
import styled from '@emotion/styled';
import { Company } from '@/shared/types/graphql';
import { useMutationSafe } from '@/shared/hooks';
import { toast } from 'react-toastify';
import { CompanyCreateModalState } from '@/shared/types';

const FormContainer = styled.form``;

const FormStep = styled.div`
  overflow: auto;
  padding: 24px;
`;

const FormFooter = styled.div`
  position: relative;
  padding: 24px;
  border-top: 1px solid #e8e8e8;

  button + button {
    margin-left: 10px;
  }
`;

interface DialogProps {
  closeWithConfirm: () => void;
  close: () => void;
  isVisible: boolean;
  companyId?: string;
  refetchQueries?: Array<string>;
  onCompleted?: any;
  closable?: boolean;
}

export const Dialog: React.FC<DialogProps> = ({ close, closeWithConfirm, isVisible, ...props }) => {
  const [companyCreateModalState, setCompanyCreateModalState] = React.useState<CompanyCreateModalState>(() => ({
    step: 0,
    pristine: true,
  }));

  const refetchQueries = props.refetchQueries || [];

  const [companyCreate] = useMutationSafe(COMPANY_CREATE_MUTATION, {
    refetchQueries: ['gettingStartedStepsInfo', 'CompaniesSelector', ...refetchQueries],
    awaitRefetchQueries: true,
    onCompleted: (data: { companyCreate: Company }) => {
      toast.success('Client was successfully created');
      close();
      props.onCompleted && props.onCompleted(data?.companyCreate);
    },
    onError: error => {
      toast.error('Something went wrong');
    },
  });

  const onSubmit = async (data: any) => {
    if (!data.status) {
      data.status = COMPANY_STATUSES.active;
    }

    await companyCreate({
      variables: {
        data,
      },
    });
  };

  const isWizardFormpristine = companyCreateModalState.step > 0 ? false : companyCreateModalState.pristine;

  return (
    <LoadingProvider>
      <Modal
        visible={isVisible}
        title="Add New Client"
        width="100%"
        className="livo-modal"
        style={{ maxWidth: 1110 }}
        closable={props?.closable}
        maskClosable={false}
        footer={null}
        keyboard={false}
        onCancel={isWizardFormpristine ? close : closeWithConfirm}
        centered
      >
        <CreateCompanyWizard onSubmit={onSubmit}>
          {({ formProps, stepNumber, setStepNumber, steps }) => {
            return (
              <FormContainer onSubmit={formProps.handleSubmit}>
                <FormStep>
                  <CompanyWizardBody formProps={formProps} steps={steps} stepNumber={stepNumber} />
                </FormStep>
                <FormFooter>
                  <CompanyWizardFooter
                    formProps={formProps}
                    stepNumber={stepNumber}
                    steps={steps}
                    setStepNumber={stepNumber => {
                      setStepNumber(stepNumber);
                    }}
                    submitButtonText="Add Client"
                    setCompanyCreateModalState={setCompanyCreateModalState}
                  />
                </FormFooter>
              </FormContainer>
            );
          }}
        </CreateCompanyWizard>
      </Modal>
    </LoadingProvider>
  );
};
