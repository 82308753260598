import gql from 'graphql-tag';

export const COMMUNITIES_IMPORT_MODAL_MUTATION = gql`
  mutation CommunitiesImportModalMutation($data: [CommunityCreateManyInput]!, $validationOnly: Boolean) {
    communityImport(data: $data, validationOnly: $validationOnly) {
      validationSuccess
      importSuccess
    }
  }
`;
