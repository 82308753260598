import { SmartPhone, Maybe } from '../types/graphql';

export const beautifyPhone = (phone?: Maybe<SmartPhone>) => {
  if (!phone || !phone?.number) {
    return 'N/A';
  }

  const { code, number } = phone;

  const part1 = number.slice(0, 3);
  const part2 = number.slice(3, 6);
  const part3 = number.slice(6);

  const phoneNumber = `(${part1}) ${part2}-${part3}`;

  return code ? `+${code} ${phoneNumber}` : phoneNumber;
};
