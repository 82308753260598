import { styled } from '@/theme';

export const Resume = styled.div`
  display: flex;
  margin-bottom: 25px;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 25px;
  }

  @media (max-width: ${({ theme }) => theme.vars.breakpoints.sm.max}) {
    display: grid;
    gap: 15px;
  }
`;

export const UppercaseLabel = styled.span`
  text-transform: uppercase;
  font-weight: 600;
  color: #929ea8;
  font-size: 12px;
`;

export const SectionHeading = styled.h4`
  margin-bottom: 25px;
  font-size: 1.4rem;
  font-weight: 600;
  color: #323c47;
`;

export const SectionInfo = styled.section`
  display: grid;
  grid-template-columns: 50% 50%;

  @media (max-width: ${({ theme }) => theme.vars.breakpoints.sm.max}) {
    grid-template-columns: none;
    gap: 20px;
  }
`;

export const SectionInfoHalf = styled.div`
  display: grid;
  align-self: flex-start;
  gap: 20px;
`;

export const PropertyLogo = styled.img`
  height: 30px;
  max-width: 150px;
  object-fit: cover;
`;
