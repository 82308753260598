import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { hot } from 'react-hot-loader/root';
import { AppProvider, PermissionsProvider } from '8base-react-sdk';
import { ToastContainer, toast } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider, DialogsProvider } from '@/providers';
import { authClient } from '@/shared/auth';

import fragmentTypes from '@/shared/graphql/fragmentTypes.json';
import tablesListData from '@/shared/graphql/tableSchema.json';

import './overrideAntd';

import { ApplicationContent } from './ApplicationContent';
import { companyIdLink, onRequestError, onRequestSuccess } from './helpers';

const { REACT_APP_API_ENDPOINT } = process.env as any;

const NotHotApp = () => {
  // eslint-disable-next-line no-console
  console.warn('DESKTOP APP');

  const tablesList = tablesListData?.system?.tablesList?.items;

  return (
    <BrowserRouter>
      <DndProvider backend={HTML5Backend}>
        <ThemeProvider>
          <AppProvider
            uri={REACT_APP_API_ENDPOINT}
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            //@ts-ignore
            authClient={authClient}
            onRequestSuccess={onRequestSuccess}
            onRequestError={onRequestError}
            extendLinks={links => [companyIdLink, ...links]}
            withSubscriptions
            introspectionQueryResultData={fragmentTypes}
            tablesList={tablesList as any}
          >
            {({ loading, error }) => (
              <PermissionsProvider type="user">
                <DialogsProvider>
                  <ApplicationContent appLoading={loading} appError={error} />
                </DialogsProvider>
              </PermissionsProvider>
            )}
          </AppProvider>
          <ToastContainer position={toast.POSITION.BOTTOM_LEFT} />
        </ThemeProvider>
      </DndProvider>
    </BrowserRouter>
  );
};

export const App = hot(NotHotApp);
