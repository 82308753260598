import React, { ChangeEvent } from 'react';
import * as R from 'ramda';
import { useQuery } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import { useAuth } from '8base-react-sdk';

import { CustomCard, TableHeader } from '@/components';
import { useSearch } from '@/shared/hooks';
import { PropertyCards } from './components';
import { OFFERS_LIST_QUERY } from './queries';
import { OfferListResponse } from '@/shared/types/graphql';
import { DRAFT_STATUS } from '@/shared/constants';
import Store from '@/shared/utils/Store';
import { storageType, storageKey, redirectType } from '@/shared/types/store';

export const PageProperties = () => {
  const [{ search, throttledSearch }, setSearch] = useSearch();
  const history = useHistory();
  const store = new Store({ storageType: storageType.Session, storageKey: storageKey.Redirect });
  const auth = useAuth();

  const { isEmailVerified } = auth.authClient.getState();
  const redirect = store.get(redirectType.afterLogin);
  const redirectUrl = R.pathOr(null, ['pathname'], redirect);

  if (isEmailVerified && redirectUrl) {
    store.drop(redirectType.afterLogin);
    history.replace(redirectUrl);
  }

  const { data, loading } = useQuery<{ offersList: OfferListResponse }>(OFFERS_LIST_QUERY, {
    variables: {
      filter: {
        AND: [
          {
            visitedRenters: {
              some: {
                user: {
                  id: {
                    equals: '__loggedInUserId',
                  },
                },
              },
            },
          },
          {
            status: {
              not_equals: DRAFT_STATUS,
            },
          },
          {
            historyOfferSingleUnit: {
              name: {
                contains: throttledSearch,
              },
            },
          },
          {
            bid: {
              every: {
                renter: {
                  user: {
                    id: {
                      not_equals: '__loggedInUserId',
                    },
                  },
                },
              },
            },
          },
        ],
      },
    },
  });

  const onChange = React.useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearch(event.currentTarget.value);
    },
    [setSearch],
  );

  return (
    <CustomCard
      header={<TableHeader title="Welcome" searchProps={{ query: search, placeholder: 'Search', onChange }} />}
    >
      <PropertyCards offers={data?.offersList?.items || []} loading={loading} />
    </CustomCard>
  );
};
