import React from 'react';
import { Button } from 'antd';
import styled from '@emotion/styled';
import { COMPANY_STATUSES, DRAFT_STATUS } from '@/shared/constants';
import { StepProps } from '@/components/CompanyWizard/types';
import { CompanyCreateModalState } from '@/shared/types';
import { usePermissions } from '@/shared/hooks';
import { loadingContext } from '@/providers';

type CompanyWizardFooterProps = {
  steps: StepProps[];
  stepNumber: number;
  setStepNumber: (callback: (stepNumber: number) => number) => void;
  formProps: any;
  submitButtonText?: string;
  setCompanyCreateModalState: (stepNumber: CompanyCreateModalState) => void;
};

const FormFooter = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  position: relative;
  text-align: right;

  button + button {
    margin-left: 10px;
  }
`;

const DraftLink = styled.button<{ disabled: boolean }>`
  display: flex;
  align-items: center;

  color: ${props => (props.disabled ? '#7D828C' : '#3ac430')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-right: auto;
  background-color: transparent;
`;

export const CompanyWizardFooter: React.FC<CompanyWizardFooterProps> = ({
  formProps,
  stepNumber,
  setStepNumber,
  steps,
  submitButtonText,
  setCompanyCreateModalState,
}) => {
  const { isAdmin } = usePermissions();

  const { loading } = React.useContext(loadingContext);

  const maxStepNumber = steps.length - 1;
  const isFirstStepNumber = stepNumber === 0;
  const isLastStepNumber = stepNumber === maxStepNumber;

  const decrementStep = React.useCallback(() => {
    setStepNumber(s => s - 1);
  }, [setStepNumber]);

  const { pristine } = formProps;

  React.useEffect(() => {
    setCompanyCreateModalState({
      pristine: pristine,
      step: stepNumber,
    });
    // eslint-disable-next-line
  }, [pristine, stepNumber]);

  return (
    <FormFooter>
      {isAdmin && (!formProps.initialValues?.status || formProps.initialValues?.status === DRAFT_STATUS) && (
        <DraftLink
          disabled={formProps.pristine || formProps.submitting || formProps.stepNumber > 0}
          onClick={() => {
            formProps.form.change('status', COMPANY_STATUSES.draft);

            return formProps.handleSubmit();
          }}
        >
          Save as Draft
        </DraftLink>
      )}
      {isFirstStepNumber || (
        <Button type="default" size="large" disabled={formProps.submitting} onClick={decrementStep}>
          Back
        </Button>
      )}
      <Button
        type="primary"
        size="large"
        loading={formProps.submitting}
        disabled={loading}
        onClick={() => {
          formProps.form.change('status', COMPANY_STATUSES.active);

          return formProps.handleSubmit();
        }}
        data-e2e-id="client.submit"
      >
        {isLastStepNumber ? submitButtonText : 'Continue'}
      </Button>
    </FormFooter>
  );
};
