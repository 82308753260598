import { Community } from '@/shared/types/graphql';
import { NavItemType, NavListProps, NavItemTypeRaw } from './types';
import { GearIcon, HouseIcon, PortfolioIcon, Bidders, SpeedMeterIcon } from '@/static/icons';
import { OFFER_STATUS_LABELS, OFFER_STATUSES, DICTIONARY_BY_COMPANY_TYPE, COMPANY_TYPES } from '@/shared/constants';
import { USER_ROLES } from 'livo-shared/src/constants/roles';
import { getHasOneOfRoles } from '@/shared/utils/roles';
import { getCommunitiesLinks } from './helpers';

const filterNav = (nav: NavItemTypeRaw[]): NavItemType[] => {
  return nav
    .filter(({ showItem = true }) => showItem)
    .map(({ showItem, ...navItem }) => {
      return {
        ...navItem,
        subNav: navItem.subNav && navItem.subNav.filter(({ showItem = true }) => showItem),
      };
    });
};

export const getSelfManagedNavList = ({
  companyId,
  roles,
  unitCount,
}: NavListProps & { unitCount: number }): NavItemType[] => {
  const hasOneOfRoles = getHasOneOfRoles(roles);

  const { singleUnit } = DICTIONARY_BY_COMPANY_TYPE[COMPANY_TYPES.selfManaged];

  return filterNav([
    {
      name: 'Dashboard',
      icon: SpeedMeterIcon,
      path: `/management/${companyId}/dashboard`,
    },
    {
      name: singleUnit.plural,
      icon: PortfolioIcon,
      count: unitCount,
      path: `/management/${companyId}/single-units`,
    },
    {
      name: `Livo Transactions`,
      icon: HouseIcon,
      path: `/management/${companyId}/rentals/single-units`,
    },
    {
      name: `LivoLeads™`,
      path: `/management/${companyId}/leads`,
      icon: Bidders,
    },
    {
      name: `Settings`,
      icon: GearIcon,
      showItem: hasOneOfRoles([
        USER_ROLES.ADMIN_LIVO,
        USER_ROLES.EMPLOYEE_LIVO,
        USER_ROLES.COMPANY_ADMIN,
        USER_ROLES.COMPANY_MANAGER,
      ]),
      subNav: [
        {
          name: `Team Members`,
          path: `/management/${companyId}/settings/team`,
        },
        {
          showItem: hasOneOfRoles([USER_ROLES.COMPANY_ADMIN]),
          name: `My Account`,
          path: `/management/${companyId}/settings/account`,
        },
        // {
        //   name: `Integrations`,
        //   path: `/management/${companyId}/settings/integrations`,
        // },
      ],
    },
  ]);
};

export const getSingleFamilyNavList = ({
  companyId,
  roles,
  unitCount,
  counts,
}: NavListProps & { counts: Record<string, number>; unitCount: number }): NavItemType[] => {
  const hasOneOfRoles = getHasOneOfRoles(roles);

  const { singleUnit } = DICTIONARY_BY_COMPANY_TYPE[COMPANY_TYPES.singleFamily];

  return filterNav([
    {
      name: 'Dashboard',
      icon: SpeedMeterIcon,
      path: `/management/${companyId}/dashboard`,
    },
    {
      name: singleUnit.plural,
      icon: PortfolioIcon,
      path: `/management/${companyId}/single-units`,
      count: unitCount,
    },
    {
      name: `Livo Transactions`,
      'data-e2e-id': 'sidebar.client.LivoRentals',
      icon: HouseIcon,
      path: `/management/${companyId}/rentals/single-units`,
      rentalsSubNav: true,
      subNav: [
        {
          name: OFFER_STATUS_LABELS.draft,
          count: counts.draft,
          status: OFFER_STATUSES.draft,
          path: `/management/${companyId}/rentals/single-units?filter_status=${OFFER_STATUSES.draft}`,
        },
        {
          name: OFFER_STATUS_LABELS.pending,
          count: counts.pending,
          status: OFFER_STATUSES.pending,
          path: `/management/${companyId}/rentals/single-units?filter_status=${OFFER_STATUSES.pending}`,
        },
        {
          name: OFFER_STATUS_LABELS.live,
          count: counts.live,
          status: OFFER_STATUSES.live,
          path: `/management/${companyId}/rentals/single-units?filter_status=${OFFER_STATUSES.live}`,
        },
        {
          name: OFFER_STATUS_LABELS.ended,
          count: counts.ended,
          status: OFFER_STATUSES.ended,
          path: `/management/${companyId}/rentals/single-units?filter_status=${OFFER_STATUSES.ended}`,
        },
        {
          name: OFFER_STATUS_LABELS.closed,
          count: counts.closed,
          status: OFFER_STATUSES.closed,
          path: `/management/${companyId}/rentals/single-units?filter_status=${OFFER_STATUSES.closed}`,
        },
        {
          name: OFFER_STATUS_LABELS.canceled,
          count: counts.canceled,
          status: OFFER_STATUSES.canceled,
          path: `/management/${companyId}/rentals/single-units?filter_status=${OFFER_STATUSES.canceled}`,
        },
        {
          name: OFFER_STATUS_LABELS.secured,
          count: counts.secured,
          status: OFFER_STATUSES.secured,
          path: `/management/${companyId}/rentals/single-units?filter_status=${OFFER_STATUSES.secured}`,
        },
        {
          name: OFFER_STATUS_LABELS.leased,
          count: counts.leased,
          status: OFFER_STATUSES.leased,
          path: `/management/${companyId}/rentals/single-units?filter_status=${OFFER_STATUSES.leased}`,
        },
      ],
    },
    {
      name: `LivoLeads™`,
      path: `/management/${companyId}/leads`,
      icon: Bidders,
    },
    {
      name: `Settings`,
      icon: GearIcon,
      'data-e2e-id': 'sidebar.singleCompany.Settings',
      showItem: hasOneOfRoles([
        USER_ROLES.ADMIN_LIVO,
        USER_ROLES.EMPLOYEE_LIVO,
        USER_ROLES.COMPANY_ADMIN,
        USER_ROLES.COMPANY_MANAGER,
      ]),
      subNav: [
        {
          name: `Team Members`,
          path: `/management/${companyId}/settings/team`,
        },
        {
          name: `My Account`,
          showItem: hasOneOfRoles([USER_ROLES.COMPANY_ADMIN]),
          path: `/management/${companyId}/settings/account`,
        },
        // {
        //   name: `Integrations`,
        //   path: `/management/${companyId}/settings/integrations`,
        // },
      ],
    },
  ]);
};

export const getMultiFamilyNavList = ({
  companyId,
  roles,
  communities,
  communitiesCount,
  counts,
}: NavListProps & {
  communities: Community[];
  communitiesCount: any;
  counts: Record<string, number>;
}): NavItemType[] => {
  const hasOneOfRoles = getHasOneOfRoles(roles);

  return filterNav([
    {
      name: 'Dashboard',
      icon: SpeedMeterIcon,
      path: `/management/${companyId}/dashboard`,
    },
    {
      name: `Properties`,
      icon: PortfolioIcon,
      count: communitiesCount,
      path: `/management/${companyId}/communities`,
      subNav: getCommunitiesLinks({ companyId, communities }),
    },
    {
      name: `Livo Transactions`,
      icon: HouseIcon,
      path: `/management/${companyId}/rentals/single-units`,
      rentalsSubNav: true,
      subNav: [
        {
          name: OFFER_STATUS_LABELS.draft,
          count: counts.draft,
          status: OFFER_STATUSES.draft,
          path: `/management/${companyId}/rentals/single-units?filter_status=${OFFER_STATUSES.draft}`,
        },
        {
          name: OFFER_STATUS_LABELS.pending,
          count: counts.pending,
          status: OFFER_STATUSES.pending,
          path: `/management/${companyId}/rentals/single-units?filter_status=${OFFER_STATUSES.pending}`,
        },
        {
          name: OFFER_STATUS_LABELS.live,
          count: counts.live,
          status: OFFER_STATUSES.live,
          path: `/management/${companyId}/rentals/single-units?filter_status=${OFFER_STATUSES.live}`,
        },
        {
          name: OFFER_STATUS_LABELS.ended,
          count: counts.ended,
          status: OFFER_STATUSES.ended,
          path: `/management/${companyId}/rentals/single-units?filter_status=${OFFER_STATUSES.ended}`,
        },
        {
          name: OFFER_STATUS_LABELS.closed,
          count: counts.closed,
          status: OFFER_STATUSES.closed,
          path: `/management/${companyId}/rentals/single-units?filter_status=${OFFER_STATUSES.closed}`,
        },
        {
          name: OFFER_STATUS_LABELS.canceled,
          count: counts.canceled,
          status: OFFER_STATUSES.canceled,
          path: `/management/${companyId}/rentals/single-units?filter_status=${OFFER_STATUSES.canceled}`,
        },
        {
          name: OFFER_STATUS_LABELS.secured,
          count: counts.secured,
          status: OFFER_STATUSES.secured,
          path: `/management/${companyId}/rentals/single-units?filter_status=${OFFER_STATUSES.secured}`,
        },
        {
          name: OFFER_STATUS_LABELS.leased,
          count: counts.leased,
          status: OFFER_STATUSES.leased,
          path: `/management/${companyId}/rentals/single-units?filter_status=${OFFER_STATUSES.leased}`,
        },
      ],
    },
    {
      name: `LivoLeads™`,
      path: `/management/${companyId}/leads`,
      icon: Bidders,
    },
    {
      name: `Settings`,
      icon: GearIcon,
      showItem: hasOneOfRoles([
        USER_ROLES.ADMIN_LIVO,
        USER_ROLES.EMPLOYEE_LIVO,
        USER_ROLES.COMPANY_ADMIN,
        USER_ROLES.COMPANY_MANAGER,
      ]),
      subNav: [
        {
          name: `Team Members`,
          path: `/management/${companyId}/settings/team`,
        },
        {
          name: `My Account`,
          showItem: hasOneOfRoles([USER_ROLES.COMPANY_ADMIN]),
          path: `/management/${companyId}/settings/account`,
        },
      ],
    },
  ]);
};
