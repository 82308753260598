import React from 'react';
import { Menu, Icon } from 'antd';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';

import { NAV_ADMIN_LIST, NAV_EMPLOYEE_LIST } from './constants';
import { NavLink, NavMenuItem } from '../../components/Nav/components';
import { NavParagraph } from '../../styled';
import { managementLayoutContext } from '@/providers';

const { SubMenu } = Menu;

export const AdminNavContainer = styled.div`
  border-bottom: 1px solid #535a62;
`;

export const AdminNav = () => {
  const { pathname } = useLocation();
  const { isSidebarOpen, isAdmin, isSuperAdmin } = React.useContext(managementLayoutContext);

  const navList = isSuperAdmin ? NAV_ADMIN_LIST : NAV_EMPLOYEE_LIST;

  if (!isAdmin) {
    return null;
  }

  return (
    <AdminNavContainer>
      <NavParagraph isSidebarOpen={isSidebarOpen}>Admin</NavParagraph>
      <Menu className="livo-sidebar" selectedKeys={[pathname]} mode="inline" inlineCollapsed={!isSidebarOpen}>
        {navList.map(navItem =>
          navItem.subNav && !!navItem.subNav.length ? (
            <SubMenu
              key={navItem.name}
              className={
                navItem.path === pathname
                  ? 'ant-menu-sub-item-selected ant-menu-first-level-item'
                  : 'ant-menu-first-level-item'
              }
              title={
                <NavMenuItem path={navItem.path}>
                  <Icon component={navItem.icon} style={{ fontSize: '2rem', color: '#fff' }} />
                  <span>{navItem.name}</span>
                </NavMenuItem>
              }
            >
              {navItem.subNav.map(
                (subNavItem: any) =>
                  subNavItem && (
                    <Menu.Item key={subNavItem.path}>
                      <NavLink
                        to={subNavItem.path}
                        css={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          display: 'flex !important',
                          alignItems: 'center',
                        }}
                      >
                        {subNavItem.name}
                      </NavLink>
                    </Menu.Item>
                  ),
              )}
            </SubMenu>
          ) : (
            navItem && (
              <Menu.Item key={navItem.path}>
                <NavLink to={navItem.path ?? ''}>
                  <>
                    <Icon component={navItem.icon} style={{ fontSize: '2rem' }} />
                    <span>{navItem.name}</span>
                  </>
                </NavLink>
              </Menu.Item>
            )
          ),
        )}
      </Menu>
    </AdminNavContainer>
  );
};
