import { Role, Maybe, User } from '@/shared/types/graphql';
import { ApplicationRole, APPLICATION_ROLES } from 'livo-shared/src';

export const getHasOneOfRoles = (roles: Role[]) => (roleConstants: Array<{ roleName: string }>) =>
  roles.find(({ name }: any) => roleConstants.find(({ roleName }) => name === roleName)) !== undefined;

export const getHasAllOfRoles = (roles: Role[]) => (roleConstants: Array<{ roleName: string }>) =>
  roleConstants.every(({ roleName }) => roles.some(({ name }) => name === roleName));

export const getApplicationRole = (user?: Maybe<User>): Maybe<ApplicationRole> => {
  const userRoles = user?.roles?.items || [];

  if (userRoles.length === 0) {
    return null;
  }

  const hasAllOfRoles = getHasAllOfRoles(userRoles);

  for (const key in APPLICATION_ROLES) {
    if (hasAllOfRoles(APPLICATION_ROLES[key].rolesList)) {
      return APPLICATION_ROLES[key];
    }
  }

  return null;
};
