import React from 'react';

import { FiltersType } from './helpers';

import { TableWithClients } from '@/shared/pages';

type CompaniesTableProps = {
  query: string;
  filters: FiltersType;
};

export const CompaniesTable = ({ query, filters }: CompaniesTableProps) => {
  const hasColumns = {
    ID: true,
    properties: true,
    totalUnits: true,
    screeningProcess: true,
    virtualShowings: true,
  };
  return (
    <TableWithClients hasColumns={hasColumns} scrollX={1800} hasPagination={true} query={query} filters={filters} />
  );
};
