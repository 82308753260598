import React from 'react';
import { dialogsContext } from '@/providers';
import { CLOSING_CONFIRM_DIALOG } from '@/dialogs';

interface DialogState {
  close: () => void;
  closeWithConfirm: () => void;
  isVisible: boolean;
  props: any;
}

export const useDialogState = (name: string): DialogState => {
  const { isDialogVisible, closeDialog, getDialogProps, openDialog } = React.useContext(dialogsContext);

  const close = () => closeDialog(name);
  const closeWithConfirm = () => openDialog({ name: CLOSING_CONFIRM_DIALOG, props: { confirmAction: close } });

  const isVisible = isDialogVisible(name);
  const props = getDialogProps(name);

  const state = {
    close,
    closeWithConfirm,
    isVisible,
    props,
  };

  return state;
};
