import React from 'react';
import { Modal, Icon, Typography } from 'antd';
import { ModalFooter } from '@/components';
import { RedAlert } from '@/static/icons';
import { useDialogState } from '@/shared/hooks';

export const DELETE_CONFIRM_DIALOG = 'DELETE_CONFIRM_DIALOG';

export const DeleteConfirmDialog = () => {
  const { close, isVisible, props } = useDialogState(DELETE_CONFIRM_DIALOG);

  const entityName = props?.entityName;
  const confirmAction = props?.confirmAction;

  const title = React.useMemo(
    () => (
      <>
        <Icon
          component={RedAlert}
          css={{ marginRight: '8px', fontSize: '20px', lineHeight: '22px', verticalAlign: 'middle' }}
        />
        {`Delete ${entityName}`}
      </>
    ),
    [entityName],
  );

  if (!isVisible) {
    return null;
  }

  return (
    <Modal
      title={title}
      visible={isVisible}
      onCancel={close}
      footer={
        <ModalFooter
          cancelProps={{
            onClick: close,
          }}
          okProps={{
            disabled: !confirmAction,
            onClick: () => {
              confirmAction && confirmAction();
              close();
            },
            type: 'danger',
            text: 'Ok',
          }}
        />
      }
      centered
    >
      <Typography.Paragraph>{`Are you sure you want to delete ${entityName}`}?</Typography.Paragraph>
    </Modal>
  );
};
