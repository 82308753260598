import { toast } from 'react-toastify';
import { authClient } from '@/shared/auth';

const ERROR_CODES = {
  UserNotFoundError: 'UserNotFoundError',
};

export const errorResolver = async (graphqlError: any) => {
  const errorCode = graphqlError.code;
  switch (errorCode) {
    case ERROR_CODES.UserNotFoundError:
      return await authClient.logout();
    default:
      return toast.error(graphqlError.message);
  }
};
