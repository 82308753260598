import React from 'react';
import { css } from '@emotion/core';

import { Offer } from '@/shared/types/graphql';
import { OfferCard } from './OfferCard';
import { ReactComponent as LivoSecureLogo } from '@/static/LivoSecureColor.svg';
import { AsyncContent } from '@/components';
import styled from '@/theme/styled';

const PropertyCardsTag = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: min-content;
  grid-gap: 20px;
  position: relative;
  height: 100%;

  @media (max-width: ${props => props.theme.vars.breakpoints.lg.max}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${props => props.theme.vars.breakpoints.sm.max}) {
    grid-template-columns: 1fr;
  }
`;

const OffersCardsEmptyTag = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #929ea8;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  flex-direction: column;
`;

const OffersCardsEmptyTitle = styled.div`
  max-width: 215px;
  text-align: center;
`;

const OffersCardsEmptyLogo = css`
  margin-bottom: 30px;
  width: 228px;
  height: 52px;
  filter: grayscale(100%);
`;

type OfferCardGridProps = {
  offers: Offer[];
  loading?: boolean;
};

export const OfferCardGrid: React.FC<OfferCardGridProps> = ({ offers, loading }) => {
  return (
    <AsyncContent loading={loading}>
      <PropertyCardsTag>
        {offers.length === 0 ? (
          <OffersCardsEmptyTag>
            <LivoSecureLogo css={OffersCardsEmptyLogo} />
            <OffersCardsEmptyTitle>Sorry! We have no rentals to show you at this moment</OffersCardsEmptyTitle>
          </OffersCardsEmptyTag>
        ) : (
          offers.map((offer, i) => <OfferCard key={offer.id || i} offer={offer} />)
        )}
      </PropertyCardsTag>
    </AsyncContent>
  );
};
