import React, { ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { Input } from 'antd';

import { CustomCard, Grid, Heading, ButtonLink } from '@/components';
import { useSearch, useCurrentCompany, useDictionary, usePermissions } from '@/shared/hooks';

import { CommunitySingleUnitsTable } from './CommunitySingleUnitsTable';
import { dialogsContext } from '@/providers';
import { Community } from '@/shared/types/graphql';
import { NOT_AVALIABLE } from '@/shared/constants';
import { COMMUNITY_NAME } from '@/shared/graphql';
import { TrashIcon } from '@/static/icons';
import { SINGLE_UNIT_CREATE_DIALOG, SINGLE_UNITS_IMPORT_DIALOG, BULK_DELETE_DIALOG } from '@/dialogs';

export const CommunitySingleUnits = () => {
  const [{ search, throttledSearch }, setSearch] = useSearch();

  const { openDialog } = React.useContext(dialogsContext);

  const { communityId } = useParams<{ communityId: string }>();
  const { company } = useCurrentCompany();
  const { singleUnit: singleUnitName } = useDictionary();
  const [selectedRecords, setSelectedRecords] = React.useState<string[]>([]);

  const { isAdmin, isCompanyOwner, isCompanyUser } = usePermissions();
  const allowAddUnits = isAdmin || isCompanyOwner || isCompanyUser;

  const companyType = company?.type;

  const { loading, data } = useQuery<{ community: Community }>(COMMUNITY_NAME, {
    variables: { id: communityId },
    skip: !communityId,
  });

  const communityName = data?.community?.name || NOT_AVALIABLE;

  const openSingleUnitCreateModal = React.useCallback(() => {
    openDialog({
      name: SINGLE_UNIT_CREATE_DIALOG,
      props: {
        closable: true,
        refetchQueries: ['CommunitySingleUnitList', 'NavigationSingleUnitsList', 'NavigationCommunitiesList'],
        communityId,
        companyType,
      },
    });
  }, [communityId, companyType, openDialog]);

  const onChange = React.useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSelectedRecords([]);
      setSearch(event.currentTarget.value);
    },
    [setSearch],
  );

  const openSingleUnitsImportModal = () =>
    openDialog({
      name: SINGLE_UNITS_IMPORT_DIALOG,
      props: {
        communityId,
        options: {
          refetchQueries: ['CommunitySingleUnitList'],
          awaitRefetchQueries: true,
        },
      },
    });

  const getSelectedRecords = (records: Array<string>) => {
    setSelectedRecords(records);
  };

  const onBulkDelete = () => {
    openDialog({
      name: BULK_DELETE_DIALOG,
      props: {
        records: selectedRecords,
        recordType: 'singleUnit',
        options: {
          refetchQueries: ['CommunitySingleUnitList', 'NavigationSingleUnitsList', 'NavigationCommunitiesList'],
          awaitRefetchQueries: true,
        },
      },
    });
    setSelectedRecords([]);
  };

  const header = (
    <Grid.Layout gap="20px" columns="auto 400px 1fr 1fr" alignItems="center" justifyItems="flex-start" stretch>
      <Heading type="h1">{loading ? '...' : communityName}</Heading>
      <Input value={search} placeholder={`Search by ${singleUnitName.single} Name`} onChange={onChange} />
      <Grid.Layout columns="auto auto" css={{ marginLeft: '20px' }}>
        <If condition={!isCompanyUser && selectedRecords.length > 0}>
          <ButtonLink onClick={onBulkDelete} text={`Delete Selected`} iconProps={{ component: TrashIcon }} />
        </If>
      </Grid.Layout>
      {allowAddUnits && (
        <Grid.Layout columns="auto auto" css={{ marginLeft: 'auto' }}>
          <ButtonLink
            onClick={openSingleUnitsImportModal}
            text={`Import ${singleUnitName.plural}`}
            iconProps={{ type: 'plus-circle' }}
          />
          <ButtonLink
            onClick={openSingleUnitCreateModal}
            text={`Add New ${singleUnitName.single}`}
            iconProps={{ type: 'plus-circle' }}
          />
        </Grid.Layout>
      )}
    </Grid.Layout>
  );

  return (
    <CustomCard padding="none" header={header}>
      <CommunitySingleUnitsTable
        setSelectedRecords={selectedRecords}
        getSelectedRecords={getSelectedRecords}
        query={throttledSearch}
      />
    </CustomCard>
  );
};
