import * as yup from 'yup';
import { SHOWING_TYPE } from '@/shared/constants';

const schemaShowingType = yup
  .array()
  .of(yup.string().oneOf(Object.keys(SHOWING_TYPE), 'There is no such type'))
  .nullable()
  .required('Showing Type is required');

export const validateShowingTypeValue = async (value?: string[]) => {
  return await schemaShowingType
    .validate(value)
    .then(() => undefined)
    .catch(validationError => validationError.errors[0]);
};
