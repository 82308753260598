import gql from 'graphql-tag';

export const ALL_OFFERS_LIST_QUERY = gql`
  query AllRentalsOffersList($first: Int, $skip: Int, $filter: OfferFilter, $sort: [OfferSort!]) {
    offersList(first: $first, skip: $skip, filter: $filter, sort: $sort) {
      items {
        id
        offerId
        numberId
        status
        activeOfferSingleUnit {
          activeOffer {
            id
            status
          }
        }
        historyOfferSingleUnit {
          id
          name
          bedrooms
          bathrooms
          squareFt
          address {
            street1
            street2
            state
            country
            zip
            city
          }
          phone {
            code
            number
          }
          company {
            id
            numberId
            clientId
            virtualShowings
            companyLogo {
              id
              downloadUrl
            }
            name
            type
            status
            website
            singleUnits {
              count
            }
            communities {
              count
            }
            updatedAt
            companySize
            mdu {
              id
              numberOfPropertiesManaged
            }
          }
        }
        secureNow
        secureNowAmount
        incrementedBidAmount
        livoManagesScreening
        screeningLink
        status
        moveinDate
        rentAmount
        securityDeposite
        startsIn
        endsIn
        termsOfLease
        bid(first: 1, sort: [{ rentAmount: DESC }], filter: { status: { not_equals: "rejected" } }) {
          items {
            id
            rentAmount
          }
          count
        }
        updatedAt
      }
      count
    }
  }
`;
