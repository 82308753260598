import React from 'react';
import { useDialogState } from '@/shared/hooks';
import { Dialog } from './Dialog';

export const OFFER_CREATE_DIALOG = 'OFFER_CREATE_DIALOG';

export const OfferCreateDialog = () => {
  const { close, closeWithConfirm, isVisible, props } = useDialogState(OFFER_CREATE_DIALOG);

  if (!isVisible) {
    return null;
  }

  return <Dialog {...props} close={close} closeWithConfirm={closeWithConfirm} isVisible={isVisible} />;
};
