import gql from 'graphql-tag';

export const TEAM_MEMBER_DELETE_MODAL_MUTATION = gql`
  mutation DeleteTeamMember($userId: String!) {
    deleteTeamMember(userId: $userId) {
      success
    }
  }
`;

export const TEAM_MEMBER_DELETE_MODAL_QUERY = gql`
  query UserTeamMember($id: ID) {
    user(id: $id) {
      id
      firstName
      lastName
    }
  }
`;
