import React, { useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { useAllowedRoles } from '8base-react-sdk';
import styled from '@emotion/styled';

import { useCurrentUser, usePermissions } from '@/shared/hooks';
import { BID_STATUSES } from '@/shared/constants';
import { USER_ROLES } from 'livo-shared';
import { dialogsContext } from '@/providers';

import { RENTALS_AMOUNT_QUERY } from '@/layouts/ManagementLayout/Sidebar/components/Nav/queries';
import { offersGroupArrayToObject } from '@/layouts/ManagementLayout/Sidebar/components/Nav/utils';
import { ADMIN_DASHBOARD_INFO_QUERY, DASHBOARD_LAST_MONTH_COMPANIES_QUERY } from './queries';
import { DateTime } from 'luxon';
import { ONBOARDING_DIALOG } from '@/dialogs';
import { AdminSummary, ClientsTable } from './Components';

const Container = styled.div`
  display: grid;
  grid-template-areas: 'Summary' 'Content';
  grid-gap: 24px;
  grid-template-columns: 1fr;
  grid-template-rows: 212px 1fr;
`;

const useRentalsAmountQueryFilter = () => {
  const { isAdmin, isCompanyOwner, allowedCommunities } = usePermissions();

  const all: any = [
    !(isAdmin || isCompanyOwner) && {
      activeOfferSingleUnit: {
        community: {
          id: {
            in: allowedCommunities.map(({ id }) => id),
          },
        },
      },
    },
  ].filter(Boolean);

  return {
    AND: all,
  };
};

const useBidsDashboardFilter = () => {
  const { isAdmin, isCompanyOwner, allowedCommunities } = usePermissions();

  const all: any = [
    { status: { equals: BID_STATUSES.winner } },
    !(isAdmin || isCompanyOwner) && {
      offer: {
        activeOfferSingleUnit: {
          community: {
            id: {
              in: allowedCommunities.map(({ id }) => id),
            },
          },
        },
      },
    },
  ].filter(Boolean);

  return {
    AND: all,
  };
};

const nowDate = DateTime.local()
  .minus({ months: 1 })
  .toISO();

const useDateFilter = () => {
  const all: any = [
    {
      createdAt: {
        gte: nowDate,
      },
    },
  ].filter(Boolean);

  return {
    AND: all,
  };
};

export const AdminDashboard = () => {
  const isAdmin = useAllowedRoles([USER_ROLES.ADMIN_LIVO.roleName]);
  const { user } = useCurrentUser();
  const { openDialog } = React.useContext(dialogsContext);

  useEffect(() => {
    if (!isAdmin && user?.firstTimer) {
      openDialog({ name: ONBOARDING_DIALOG });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  const dateFilter = useDateFilter();
  const bidsDashboardFilter = useBidsDashboardFilter();

  const { data: dashboardInfo } = useQuery(ADMIN_DASHBOARD_INFO_QUERY, {
    variables: {
      bidsFilter: bidsDashboardFilter,
    },
  });

  const { data: lastMonthClients } = useQuery(DASHBOARD_LAST_MONTH_COMPANIES_QUERY, {
    variables: {
      dateFilter: dateFilter,
    },
  });

  const rentalsAmountQueryFilter = useRentalsAmountQueryFilter();

  const { data: offersData } = useQuery(RENTALS_AMOUNT_QUERY, {
    variables: {
      filter: rentalsAmountQueryFilter,
    },
  });

  const offersResultData = offersGroupArrayToObject(offersData?.offersList?.groups || []);

  return (
    <Container>
      <AdminSummary
        offersResultData={offersResultData}
        dashboardInfo={dashboardInfo}
        lastMonthClients={lastMonthClients}
      />

      <ClientsTable />
    </Container>
  );
};
