import { styled } from '@/theme';
import { Typography } from 'antd';

const { Title } = Typography;

export const PropertyHeading = styled(Title)`
  color: #323c47;
  font-weight: 500 !important;
  font-size: 32px;
  letter-spacing: 0.5px;
  line-height: 32px;
  margin-bottom: 20px;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(400px, 560px) minmax(480px, 1fr);
  grid-gap: 100px;
  padding: 3.2rem 2.4rem;

  button.slick-arrow {
    background-color: transparent !important;
  }

  button.slick-arrow::before {
    color: white !important;
  }

  @media (max-width: ${({ theme }) => theme.vars.breakpoints.lg.max}) {
    grid-gap: 20px;
  }

  @media (max-width: ${({ theme }) => theme.vars.breakpoints.md.max}) {
    grid-template-columns: 100%;
  }

  @media (max-width: ${({ theme }) => theme.vars.breakpoints.sm.max}) {
    padding: 0;
  }
`;

export const ActionsContainer = styled.div`
  display: grid;
  grid-template-columns: 180px 150px;
  grid-gap: 10px;

  @media (max-width: ${({ theme }) => theme.vars.breakpoints.sm.max}) {
    grid-template-columns: none;
    grid-gap: 12px;
  }
`;

export const Info = styled.div`
  padding: 0 1.5rem 2rem;
`;
