import styled from '@/theme/styled';

export const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(400px, 560px) minmax(500px, 1fr);
  grid-gap: 24px;

  @media (max-width: ${({ theme }) => theme.vars.breakpoints.md.max}) {
    display: block;
  }
`;

export const Info = styled.div``;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const SwitchLabel = styled.div`
  font-size: 1.2rem;
  line-height: 1.9rem;
`;
