import React from 'react';
import { Editor, EditorState } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

type DescriptionEditor = {
  editorState: EditorState;
  onEditorStateChange: (EditorState: EditorState) => void;
  onBlur: (event: React.FocusEvent<HTMLElement>) => void;
  onFocus: (event: React.FocusEvent<HTMLElement>) => void;
  handlePastedText: (text: string, html: string, editorState: EditorState) => boolean;
};

const styles = {
  editor: {
    height: '240px',
    padding: '4px 11px',
  },
  toolbar: {
    border: 0,
    borderBottom: '1px solid #d9d9d9',
    margin: 0,
  },
};

const TOOLBAR_SETTINGS = {
  options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link', 'colorPicker'],
  inline: {
    options: ['bold', 'italic', 'underline', 'strikethrough'],
  },
};

export const DescriptionEditor: React.FC<DescriptionEditor> = ({
  editorState,
  onEditorStateChange,
  onBlur,
  onFocus,
  handlePastedText,
}) => {
  return (
    <Editor
      onBlur={onBlur}
      onFocus={onFocus}
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      editorStyle={styles.editor}
      toolbarStyle={styles.toolbar}
      toolbar={TOOLBAR_SETTINGS}
      handlePastedText={handlePastedText}
    />
  );
};
