import gql from 'graphql-tag';

export const EMPLOYEE_DELETE_MODAL_MUTATION = gql`
  mutation DeleteEmployee($userId: String!) {
    deleteEmployee(userId: $userId) {
      success
    }
  }
`;

export const EMPLOYEE_DELETE_MODAL_QUERY = gql`
  query UserEmployee($id: ID) {
    user(id: $id) {
      id
      firstName
      lastName
    }
  }
`;
