import { NEW_AMENITY_GROUP_LISTS } from '@/shared/constants';
import { AmenityGroupOptionsList } from '@/shared/hooks/useAmenityGroups';
import Maybe from 'graphql/tsutils/Maybe';

export const getCommunityAmenities = (
  groupedOptions: AmenityGroupOptionsList,
  singleGroupKey?: Maybe<string>,
): AmenityGroupOptionsList => {
  return groupedOptions.filter(({ groupKey: currentGroupKey }) => {
    if (singleGroupKey) {
      return NEW_AMENITY_GROUP_LISTS.community
        .filter(el => el === singleGroupKey)
        .some(groupKey => groupKey === currentGroupKey);
    } else return NEW_AMENITY_GROUP_LISTS.community.some(groupKey => groupKey === currentGroupKey);
  });
};
