import React from 'react';
import { NOT_AVALIABLE, BID_STATUSES_LABELS } from '@/shared/constants';
import { Tag } from '@/components';
import { Maybe } from '@/shared/types/graphql';

const COLOR_BY_TYPE: Record<keyof typeof BID_STATUSES_LABELS, string> = {
  bidder: 'orange',
  winner: 'green',
  rejected: 'red',
  secured: 'orange',
  canceled: 'red',
};

type BidStatusTagProps = {
  status?: Maybe<string>;
  colors?: boolean;
};

export const BidStatusTag: React.FC<BidStatusTagProps> = ({ status, colors = true }) => {
  if (!status || !(COLOR_BY_TYPE as any)[status]) {
    return <>{NOT_AVALIABLE}</>;
  }

  return <Tag color={colors && (COLOR_BY_TYPE as any)[status]}>{(BID_STATUSES_LABELS as any)[status]}</Tag>;
};
