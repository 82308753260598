import React from 'react';

import { ColumnFlex, Tag } from '@/components';
import { OfferSubTitle, OfferTitle } from '../styled';
import { Offer, Bid, Maybe } from '@/shared/types/graphql';
import { useCurrentUser, useOfferBidCombinedStatus } from '@/shared/hooks';
import { BidOfferActions } from './BidOfferActions';
import { BidOffersTimeline } from '@/pro';
import { OFFER_STATUSES, BID_STATUSES, OFFER_COMBINED_STATUSES } from '@/shared/constants';
import { OfferAccepted } from './OfferAccepted';
import { OfferSignLeaseContract } from './OfferSignLeaseContract';

type PropertyOfferTimelineProps = {
  offer?: Maybe<Offer>;
};

const formPlace = (place: number) => {
  switch (place) {
    case 1:
      return `${place}st`;
    case 2:
      return `${place}nd`;
    case 3:
      return `${place}rd`;
    default:
      return `${place}th`;
  }
};

const auctionEndedText = ({
  offer,
  isWinner,
  isOfferAccepted,
  bid,
}: {
  offer?: Maybe<Offer>;
  isWinner: boolean;
  bid?: Maybe<Bid>;
  isOfferAccepted?: Maybe<boolean>;
}) => {
  if (isWinner) {
    if (offer?.status === OFFER_STATUSES.leased) {
      return <OfferTitle isPrimaryColor>Congratulations! You won this rental!</OfferTitle>;
    }
    if (isOfferAccepted) {
      return <OfferAccepted bid={bid} offer={offer} />;
    }

    return <OfferSignLeaseContract bid={bid} offer={offer} />;
  }

  switch (offer?.status) {
    case OFFER_STATUSES.pending: {
      return <OfferTitle>Auction Has Not Started Yet</OfferTitle>;
    }
    case OFFER_STATUSES.secured:
    case OFFER_STATUSES.closed:
    case OFFER_STATUSES.ended: {
      return <OfferTitle>Auction Has Already Ended</OfferTitle>;
    }
    default: {
      return <OfferTitle>Auction Is Not Active</OfferTitle>;
    }
  }
};

export const PropertyOfferTimeline: React.FC<PropertyOfferTimelineProps> = ({ offer }) => {
  const offerBidCombinedStatus = useOfferBidCombinedStatus(offer);
  const { user } = useCurrentUser();
  const currentUserId = user?.id;

  const bids = offer?.bid?.items || [];
  const isUserRejected = bids.some(
    bid => bid?.status === BID_STATUSES.rejected && bid?.renter?.user?.id === currentUserId,
  );

  if (isUserRejected) {
    return (
      <Tag color="red" type="raised">
        Your offer was rejected
      </Tag>
    );
  }

  const filteredBids = bids.filter(bid => bid?.status !== BID_STATUSES.rejected);

  const userBidIndex = filteredBids.findIndex(bid => bid?.renter?.user?.id === user?.id);
  const userBid = filteredBids[userBidIndex];
  const userBidPlace = userBidIndex + 1;

  const isWinner =
    offerBidCombinedStatus.kind === OFFER_COMBINED_STATUSES['you-won'] ||
    offerBidCombinedStatus.kind === OFFER_COMBINED_STATUSES['you-accept'] ||
    offerBidCombinedStatus.kind === OFFER_COMBINED_STATUSES['you-secured'] ||
    offerBidCombinedStatus.kind === OFFER_COMBINED_STATUSES.awarded;

  if (offer?.status !== OFFER_STATUSES.live) {
    return auctionEndedText({
      offer,
      isWinner,
      isOfferAccepted: userBid?.offerAccepted,
      bid: userBid,
    });
  }

  const getHeaderTitle = () => {
    if (isWinner) {
      return null;
    }

    return (
      <React.Fragment>
        {userBidPlace >= 1 ? (
          <OfferTitle>You are in {formPlace(userBidPlace)} place!</OfferTitle>
        ) : (
          <OfferTitle>Current Top Offers</OfferTitle>
        )}
        {userBidPlace > 1 ? <OfferSubTitle>Make another offer to get back on the top</OfferSubTitle> : null}
        <br />
      </React.Fragment>
    );
  };

  return (
    <ColumnFlex>
      {getHeaderTitle()}
      <BidOffersTimeline
        offer={offer}
        bids={filteredBids}
        isUserRejected={isUserRejected}
        actions={({ userBidPlace, activeItemId, offer, bid }) => (
          <BidOfferActions
            userBidPlace={userBidPlace}
            userBid={userBid}
            activeItemId={activeItemId}
            offer={offer}
            bid={bid}
          />
        )}
      />
    </ColumnFlex>
  );
};
