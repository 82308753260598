import React, { useEffect } from 'react';
import Papa from 'papaparse';
import { toast } from 'react-toastify';
import { Modal, Divider, Checkbox } from 'antd';

import { ModalFooter, TableCsvPreview } from '@/components';
import { DropzoneContainer, PreviewContainer, PreviewSettings, DropArea, FileInput } from './styles';
import { useDictionary } from '@/shared/hooks';
import Dropzone from 'react-dropzone';
import { MDU_IMPORT_TABLE_COLUMNS, IMPORT_TABLE_COLUMNS } from './constants';
import { prepareImportData, useImportUnits } from './helpers';
import { PrepareImportDataArgs } from './types';
import { checkParsedCSVDataIsEmpty } from '@/shared/utils';

interface DialogProps {
  closeWithConfirm: () => void;
  close: () => void;
  isVisible: boolean;
  communityId?: string;
  options?: {
    refetchQueries?: Array<string>;
    awaitRefetchQueries?: boolean;
  };
}

export const Dialog: React.FC<DialogProps> = ({ close, closeWithConfirm, isVisible, ...props }) => {
  const tableColumns = props?.communityId ? MDU_IMPORT_TABLE_COLUMNS : IMPORT_TABLE_COLUMNS;
  const options = props?.options || {};

  const [fileCSV, setFileCSV] = React.useState(null);
  const [importingUnitsLength, setImportingUnitsLength] = React.useState(0);
  const [parsed, setParsed]: [
    Papa.ParseResult<any> | undefined,
    (arg: Papa.ParseResult<any> | undefined) => void,
  ] = React.useState();
  const [hasHeader, setHasHeader] = React.useState<boolean>(true);

  const { singleUnit: singleUnitName } = useDictionary();
  const { singleUnitImport, importedUnits, importErrors, singleUnitImportLoading } = useImportUnits();

  useEffect(() => {
    if (importedUnits && importingUnitsLength === importedUnits) {
      close();
      toast.success(`${singleUnitName.plural} Successfully Created`);
    }
  }, [close, importedUnits, importingUnitsLength, singleUnitName, singleUnitName.plural]);

  const onChangeCSV = (files: any) => {
    const fileCSV = Array.isArray(files) ? files[0] : files;
    if (fileCSV) {
      setFileCSV(fileCSV);
    }
  };

  const resetImportDialogData = () => {
    setFileCSV(null);
    setParsed(undefined);
  };

  const onSubmit = React.useCallback(
    async (importData: PrepareImportDataArgs & { validationOnly?: boolean }) => {
      const { hasHeader, parsed, tableColumns, communityId, validationOnly } = importData;

      const data = prepareImportData({
        hasHeader,
        parsed,
        tableColumns,
        communityId,
      });
      const splitArr = (array: any, lengthOfChunk: number) =>
        array?.reduce((acc: any, currentValue: any, i: any) => {
          i % lengthOfChunk === 0 && acc.push([]);
          acc[acc.length - 1].push(currentValue);
          return acc;
        }, []);
      const formattedArray = data.length > 20 ? splitArr(data, 10) : data;

      if (validationOnly) {
        await singleUnitImport({
          variables: {
            data,
            validationOnly,
          },
          ...options,
        });
      } else {
        setImportingUnitsLength(formattedArray?.length);
        await Promise.all(
          formattedArray.map(async (chunk: any) => {
            singleUnitImport({
              variables: {
                data: chunk,
                validationOnly,
              },
              ...options,
            });
          }),
        );
      }
    },
    [options, singleUnitImport],
  );

  const handleSetHasHeader = (e: any) => {
    const checked = !!e?.target?.checked;
    setHasHeader(checked);
    onSubmit({
      hasHeader: checked,
      parsed,
      tableColumns,
      communityId: props?.communityId,
      validationOnly: true,
    });
  };

  const onParseComplete = React.useCallback(
    (results?: Papa.ParseResult<any>) => {
      if (checkParsedCSVDataIsEmpty(results)) {
        resetImportDialogData();
      } else {
        setParsed(results);
        onSubmit({
          hasHeader,
          parsed: results,
          tableColumns,
          communityId: props?.communityId,
          validationOnly: true,
        });
      }
    },
    [hasHeader, onSubmit, props, tableColumns],
  );

  return (
    <Modal
      title={`${singleUnitName.plural} - Import Data from CSV`}
      className="livo-modal"
      visible={isVisible}
      onCancel={fileCSV ? closeWithConfirm : close}
      width="100%"
      css={{ maxWidth: parsed ? '1110px' : '618px' }}
      centered
      footer={
        <ModalFooter
          cancelProps={{
            disabled: singleUnitImportLoading,
            onClick: fileCSV ? closeWithConfirm : close,
          }}
          okProps={{
            text: 'Import',
            type: 'primary',
            htmlType: 'submit',
            loading: singleUnitImportLoading,
            disabled: singleUnitImportLoading || !!importErrors || !parsed,
            onClick: () =>
              onSubmit({
                hasHeader,
                parsed,
                tableColumns,
                communityId: props?.communityId,
              }),
          }}
        />
      }
    >
      <>
        <Choose>
          <When condition={!fileCSV}>
            <DropzoneContainer>
              <Dropzone accept=".csv" multiple={false} onDrop={data => onChangeCSV(data)}>
                {({ getRootProps, getInputProps }) => (
                  <DropArea {...getRootProps()}>
                    Select CSV file
                    <FileInput type="file" {...getInputProps()} />
                  </DropArea>
                )}
              </Dropzone>
            </DropzoneContainer>
          </When>
          <Otherwise>
            <PreviewContainer>
              <PreviewSettings>
                <Checkbox
                  checked={hasHeader}
                  onChange={handleSetHasHeader}
                  defaultChecked={hasHeader}
                  disabled={singleUnitImportLoading}
                >
                  Has Header Row
                </Checkbox>
              </PreviewSettings>
              <Divider />
              <TableCsvPreview
                hasHeader={hasHeader}
                fileCSV={fileCSV}
                importErrors={importErrors}
                tableColumns={tableColumns}
                onParseComplete={onParseComplete}
                loading={singleUnitImportLoading}
              />
            </PreviewContainer>
          </Otherwise>
        </Choose>
      </>
    </Modal>
  );
};
