import React from 'react';
import styled from '@emotion/styled';

type ContainerProps = {
  overflowX?: string;
  overflowY?: string;
  padding?: string;
  height?: string;
};

export const Container = styled.div<ContainerProps>`
  overflow-x: ${({ overflowX }) => overflowX || 'auto'};
  overflow-y: ${({ overflowY }) => overflowY || 'auto'};
  padding: ${({ padding }) => padding || '24px'};
  height: ${({ height }) => height};
`;

type ScrollableProps = ContainerProps & {
  children: React.ReactNode;
};

export const Scrollable: React.FC<ScrollableProps> = ({ children, ...rest }) => {
  return <Container {...rest}>{children}</Container>;
};
