import gql from 'graphql-tag';

export const OFFER_CHECKLIST_MUTATION = gql`
  mutation OfferChecklistMutation($filter: BidKeyFilter, $data: BidUpdateInput!) {
    bidUpdate(filter: $filter, data: $data) {
      id
    }
  }
`;

export const CHECK_SCREENING_LINK = gql`
  query CheckWrScreeningLink($renterFilter: WesternReportingScreeningFilter) {
    westernReportingScreeningsList(filter: $renterFilter) {
      items {
        id
        screeningLink
      }
    }
  }
`;
