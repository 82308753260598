import React from 'react';
import { Field, Form } from '8base-react-sdk';
import { omit } from 'ramda';

import { InputField, SelectField, CustomCard, AvatarField, Grid, ButtonForChangePassword } from '@/components';
import { composeValidators, phoneNumber, urlCalendly } from '@/shared/validators';
import { getInitials, normalizePhone } from '@/shared/utils';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';
import { COMPANY_TYPE_LABELS } from 'livo-shared';
import { USER_ACCOUNT_UPDATE } from './queries';
import { AccountFooter } from './components';
import { InformationSection } from '@/pro';
import { useMutationSafe } from '@/shared/hooks';

export const Account = () => {
  const { user } = useCurrentUser();

  const coercedCompanyTypes = React.useMemo(
    () => Object.entries(COMPANY_TYPE_LABELS).map(([value, label]) => ({ value, label })),
    [],
  );

  const [updateUser, { loading: updateUserLoading }] = useMutationSafe(USER_ACCOUNT_UPDATE);

  const firstName = user?.firstName;
  const lastName = user?.lastName;
  const initials = getInitials(firstName, lastName);

  const onSubmit = ({ company, email, ...rest }: any) => {
    updateUser({
      variables: {
        data: rest,
        filter: {
          id: user?.id,
        },
      },
    });
  };

  const initialValues = omit(['roles', 'regionalManager'], user);

  return (
    <Form type="UPDATE" tableSchemaName="Users" onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit: onSubmit, form, pristine }) => {
        return (
          <form onSubmit={onSubmit} css={{ display: 'grid' }}>
            <CustomCard
              header="My Account"
              footer={
                <AccountFooter loading={updateUserLoading} isPristine={pristine} resetForm={() => form.reset()} />
              }
            >
              <Grid.Layout columns="240px 1fr" gap="84px">
                <Field
                  name="avatar"
                  placeholder={initials}
                  component={AvatarField}
                  accept={['.png', '.jpg', '.jpeg', '.svg']}
                  loading={updateUserLoading}
                />
                <InformationSection header="Personal Information">
                  <Grid.Layout columns="repeat(2, 1fr)" gap="20px">
                    <Grid.Layout>
                      <Field label="Name" name="firstName" component={InputField} />
                      <Field label="Email Address" name="email" component={InputField} disabled />
                      <Field
                        label="Company Market"
                        name="company.type"
                        component={SelectField}
                        options={coercedCompanyTypes}
                        disabled
                        stretch
                      />
                      <Field
                        label="Calendar Link (to schedule showings)"
                        name="calendarLink"
                        component={InputField}
                        validate={composeValidators(urlCalendly)}
                      />
                    </Grid.Layout>
                    <Grid.Layout>
                      <Field label="Last Name" name="lastName" component={InputField} />
                      <Field label="Company Name" name="company.name" component={InputField} disabled />
                      <Field
                        label="Phone Number"
                        name="phone.number"
                        autoComplete="disabled"
                        placeholder="(999) 999-9999"
                        mask="(999) 999-9999"
                        component={InputField}
                        validate={composeValidators(phoneNumber)}
                        addonBefore={user?.phone?.code ? `+${user?.phone.code}` : '+1'}
                        parse={normalizePhone}
                      />
                    </Grid.Layout>
                  </Grid.Layout>
                  <ButtonForChangePassword />
                </InformationSection>
              </Grid.Layout>
            </CustomCard>
          </form>
        );
      }}
    </Form>
  );
};
