import React from 'react';
import { Input } from 'antd';
import InputMask from 'react-input-mask';

import { FormField } from '@/components';
import { MetaType, InputType } from '@/shared/types';

type InputFieldProps = {
  label?: React.ReactNode;
  note?: string;
  input: InputType;
  inputType: string;
  meta: MetaType;
  mask?: string;
  placeholder?: string;
  disabled?: boolean;
  addonBefore?: React.ReactNode;
  'data-e2e-id'?: string;
};

export const InputField = ({
  label,
  note,
  input,
  meta,
  mask,
  inputType,
  placeholder,
  disabled,
  addonBefore,
  ...rest
}: InputFieldProps) => {
  const { name, value, onChange, onBlur, onFocus } = input;

  return (
    <FormField label={label} note={note} meta={meta}>
      {mask ? (
        <InputMask
          {...rest}
          mask={!value?.length ? null : mask} // workaround for limitations of react-input-mask lib https://github.com/sanniassin/react-input-mask#autofill
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={disabled}
        >
          <Input name={name} placeholder={placeholder} addonBefore={addonBefore} />
        </InputMask>
      ) : (
        <Input
          {...rest}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          type={inputType}
          onFocus={onFocus}
          disabled={disabled}
          addonBefore={addonBefore}
        />
      )}
    </FormField>
  );
};
