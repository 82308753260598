import React from 'react';
import styled from '@emotion/styled';
import { Loader } from '@/components';

const AsyncContentTag = styled.div<{ height?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${props => (props.height ? props.height : '100%')};
`;

type AsyncContentProps = {
  loading?: boolean;
  height?: string;
};

export const AsyncContent: React.FC<AsyncContentProps> = ({ children, loading, height }) => {
  if (loading) {
    return (
      <AsyncContentTag height={height}>
        <Loader stretch />
      </AsyncContentTag>
    );
  }

  return <>{children}</>;
};
