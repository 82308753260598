import { DateTime } from 'luxon';

export const parseDatetimeFromISO = (datetime: string, timezone?: string) => {
  const parseDatetime = DateTime.fromISO(datetime);

  if (Boolean(timezone)) {
    return parseDatetime.setZone(timezone as string);
  }

  return parseDatetime;
};
