import React from 'react';
import styled from '@emotion/styled';
import { Modal, Carousel } from 'antd';

import { OnboardingFooter, OnboardingStep } from './components';
import { ONBOARDING_STEPS } from './constants';
import { FIRST_TIMER_MUTATION } from './queries';
import { useCurrentUser, useMutationSafe } from '@/shared/hooks';

const Container = styled.div`
  height: 650px;

  .ant-btn {
    padding: 0 32px;
    height: 40px;
    letter-spacing: 0;
    border-radius: 5px;
    span {
      vertical-align: middle;
    }
  }
`;

const CarouselContainer = styled.div`
  .ant-carousel .slick-slide {
    text-align: center;
    height: 562px;
    overflow: hidden;
  }
  .ant-carousel .slick-slide h3 {
    color: #fff;
  }
`;

type DialogProps = {
  close: () => void;
  isVisible: boolean;
};

export const Dialog = ({ isVisible, close }: DialogProps) => {
  const { user } = useCurrentUser();
  const [handleOnboardingClose] = useMutationSafe(FIRST_TIMER_MUTATION);

  const onSkip = async () => {
    close();
    await handleOnboardingClose({
      variables: {
        data: { firstTimer: false },
        filter: { id: user?.id },
      },
    });
  };

  // Carousel methods
  const carouselRef: any = React.useRef();
  const setNextStep = () => carouselRef.current.slick.slickNext();
  const setStep = (stepNumber: number) => carouselRef.current.slick.slickGoTo(stepNumber);

  // counter for Dots component
  const [currentStepNumber, setCurrentStepNumber] = React.useState(0);
  const beforeChange = (fromStep: number, toStep: number) => setCurrentStepNumber(toStep);

  // array of functions for Dots component
  const dotsFunctions = ONBOARDING_STEPS.map((__, stepNumber) => () => setStep(stepNumber));

  return (
    <Modal className="livo-modal" centered visible={isVisible} footer={null} closable={false} width={800}>
      <Container>
        <CarouselContainer>
          <Carousel ref={carouselRef} dots={false} beforeChange={beforeChange} infinite={false}>
            {ONBOARDING_STEPS.map((props, stepNumber) => (
              <OnboardingStep key={stepNumber} {...props} />
            ))}
          </Carousel>
        </CarouselContainer>
        <OnboardingFooter
          handleOnboardingClose={onSkip}
          dotsFunctions={dotsFunctions}
          currentStepNumber={currentStepNumber}
          setNextStep={setNextStep}
        />
      </Container>
    </Modal>
  );
};
