import React from 'react';

import { useQuery } from 'react-apollo';
import { Dropdown, Icon, Menu } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import { Empty } from 'livo-components/src/components';
import { ButtonLink, Grid, Link, PhoneLink, Table, ResumeNumbers } from '@/components';

import { CopyIcon } from '@/static/icons';
import { TableState, TableStateOnChange, useTableState, useDialogState } from '@/shared/hooks';
import { NOT_AVALIABLE, OFFER_STATUSES, DRAFT_STATUS, OfferStatus } from '@/shared/constants';
import { DateTime } from 'luxon';

import { stringifyAddress, formatDiffDate, stringifyNA, formatAmount } from '@/shared/utils';
import { SmartAddress, Offer, OfferListResponse } from '@/shared/types/graphql';
import { dialogsContext } from '@/providers';
import { BiddersList, OfferStatusTag, ConditionalLink, CompanyStatusTag } from '@/pro';

import {
  OfferInfo,
  OfferInfoTitle,
  OfferInfoSubtitle,
  OfferInfoText,
  OfferInfoLabel,
  OfferInfoContacts,
} from '../../company/entity/rentals/single-units/root/styled';

import { ALL_OFFERS_LIST_QUERY } from './queries';

import Maybe from 'graphql/tsutils/Maybe';
import pluralize from 'pluralize';
import copy from 'copy-to-clipboard';
import { getSingleRentalUrl } from '@/shared/constants/appRoutes';
import {
  OFFER_UPDATE_DIALOG,
  OFFER_DETAILS_DIALOG,
  OFFER_DELETE_DIALOG,
  INVITE_RENTERS_DIALOG,
  OFFER_END_DIALOG,
} from '@/dialogs';

export type FiltersType = {
  status: OfferStatus;
  filter_comminutyId: string;
};

type UseOffersListQueryFilterParams = {
  filters: FiltersType;
  query: string;
};

const useAllOffersListQueryFilter = ({ filters, query }: UseOffersListQueryFilterParams) => {
  const all: any = [
    filters.status && {
      status: {
        equals: filters.status,
      },
    },
    filters.filter_comminutyId && {
      filter_comminutyId: {
        equals: filters.filter_comminutyId,
      },
    },
    query && {
      OR: [
        {
          historyOfferSingleUnit: {
            address: {
              street1: {
                contains: query,
              },
            },
          },
        },
        {
          historyOfferSingleUnit: {
            address: {
              street2: {
                contains: query,
              },
            },
          },
        },
        {
          historyOfferSingleUnit: {
            address: {
              city: {
                contains: query,
              },
            },
          },
        },
        {
          historyOfferSingleUnit: {
            name: {
              contains: query,
            },
          },
        },
        {
          historyOfferSingleUnit: {
            company: {
              name: {
                contains: query,
              },
            },
          },
        },
        !isNaN(Number(query)) && {
          numberId: {
            equals: Number(query),
          },
        },
      ].filter(Boolean),
    },
  ].filter(Boolean);

  return {
    AND: all,
  };
};

const getSort = (queryParams: TableState) => {
  let sort: Record<string, any>[] = [];

  if (queryParams.sortBy && queryParams.sortOrder) {
    const field = queryParams.sortBy;
    const direction = queryParams.sortOrder === 'ascend' ? 'ASC' : 'DESC';

    if (field === 'name') {
      sort = [
        {
          historyOfferSingleUnit: {
            name: direction,
          },
        },
      ];
    } else if (field === 'clientName') {
      sort = [
        {
          historyOfferSingleUnit: {
            company: {
              name: direction,
            },
          },
        },
      ];
    } else if (field && !['highestBid', 'bidders'].includes(field)) {
      sort = [
        {
          [field]: direction,
        },
      ];
    }
  }

  return sort;
};

const CopyPublicLink = (offer: Offer) => {
  const offerId = offer?.id;

  const onCopy = React.useCallback(() => {
    copy(`${window.location.origin}${getSingleRentalUrl(offerId)}`);
  }, [offerId]);

  return (
    <ButtonLink
      onClick={onCopy}
      icon={<CopyIcon css={{ fill: 'currentColor' }} />}
      disabled={offer?.status === OFFER_STATUSES.draft}
    >
      Copy Public Link
    </ButtonLink>
  );
};

const AllRentalsTableActions = (offer: Offer) => {
  const { openDialog } = React.useContext(dialogsContext);
  const { props: inviteRentersProps } = useDialogState(INVITE_RENTERS_DIALOG);

  const offerId = offer?.id;
  const singleUnitId = offer?.historyOfferSingleUnit?.id;

  const bidsCount = offer?.bid?.items.length || 0;
  const noBids = bidsCount === 0;

  const openDeleteModal = React.useCallback(() => {
    openDialog({ name: OFFER_DELETE_DIALOG, props: { id: offer.id, refetchQueries: ['AllRentalsOffersList'] } });
  }, [openDialog, offer.id]);

  const openDetailsModal = React.useCallback(() => {
    openDialog({ name: OFFER_DETAILS_DIALOG, props: { offerId, singleUnitId } });
  }, [offerId, openDialog, singleUnitId]);

  const openUpdateModal = React.useCallback(() => {
    openDialog({ name: OFFER_UPDATE_DIALOG, props: { offerId, singleUnitId } });
  }, [offerId, openDialog, singleUnitId]);

  const menu = (
    <Menu>
      <If condition={offer?.status !== OFFER_STATUSES.draft}>
        <Menu.Item onClick={openDetailsModal}>Details</Menu.Item>
      </If>
      <If
        condition={
          (offer?.status === OFFER_STATUSES.live ||
            offer?.status === OFFER_STATUSES.pending ||
            offer?.status === OFFER_STATUSES.draft) &&
          noBids
        }
      >
        <Menu.Item onClick={openUpdateModal}>Edit</Menu.Item>
      </If>
      <If condition={offer?.status === OFFER_STATUSES.live}>
        <Menu.Item
          onClick={() =>
            openDialog({
              name: OFFER_END_DIALOG,
              props: { offerId: offer?.id, refetchQueries: ['AllRentalsOffersList'], awaitRefetch: true },
            })
          }
        >
          Disable
        </Menu.Item>
      </If>
      <If condition={offer?.status !== OFFER_STATUSES.draft}>
        <Menu.Item
          onClick={() =>
            openDialog({ name: INVITE_RENTERS_DIALOG, props: { ...inviteRentersProps, offerId: offer?.id } })
          }
        >
          Invite Renters
        </Menu.Item>
      </If>
      <Menu.Item onClick={openDeleteModal}>Delete</Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
      <Icon type="ellipsis" />
    </Dropdown>
  );
};

const ExpandableRow = (offer: Offer) => {
  const singleUnit = offer?.historyOfferSingleUnit;
  const companyId = singleUnit?.company?.id || '';
  const bedrooms: Maybe<number> = singleUnit?.bedrooms;
  const bathrooms: Maybe<number> = singleUnit?.bathrooms;
  const squareFt: Maybe<number> = singleUnit?.squareFt;

  const address: Maybe<SmartAddress> = singleUnit?.address;

  const rentAmount: Maybe<number | string> = formatAmount(offer.rentAmount);
  const securityDeposite: Maybe<number | string> = formatAmount(offer.securityDeposite);
  const termsOfLease: Maybe<number> = offer.termsOfLease;

  const endsInRaw = DateTime.fromISO(offer.endsIn);
  const endsIn = endsInRaw.toFormat('D - tt');
  const diff = formatDiffDate(endsInRaw);
  const isExpired = endsInRaw.diffNow().milliseconds < 0;
  const endsInDiff = `${endsIn}${isExpired ? '' : ` (${diff})`}`;

  const secureNowAmount: Maybe<number | string> = formatAmount(offer?.secureNowAmount);

  const isDraft = offer.status === OFFER_STATUSES.draft;

  if (isDraft) {
    return <Empty description="Drafts are unavailable" />;
  }

  return (
    <Grid.Layout columns="440px 1fr" css={{ margin: -16 }}>
      <OfferInfo>
        <OfferInfoTitle>Livo Transaction #{offer.offerId}</OfferInfoTitle>
        <OfferInfoSubtitle>
          <ResumeNumbers beds={bedrooms} baths={bathrooms} squareFt={squareFt} />
        </OfferInfoSubtitle>
        <OfferInfoContacts>
          {address ? stringifyAddress(address) : NOT_AVALIABLE}&nbsp;&nbsp;&nbsp;&nbsp;T:{' '}
          <PhoneLink phone={singleUnit?.phone} />
        </OfferInfoContacts>
        <OfferInfoText>
          <OfferInfoLabel>LIVO TRANSACTION END DATE:</OfferInfoLabel> {endsInDiff}
        </OfferInfoText>
        <OfferInfoText>
          <OfferInfoLabel>RENT AMOUNT:</OfferInfoLabel> ${rentAmount}&nbsp;&nbsp;&nbsp;&nbsp;
          <OfferInfoLabel>LEASE TERMS:</OfferInfoLabel>{' '}
          {termsOfLease ? `${termsOfLease} ${pluralize('month', termsOfLease)}` : NOT_AVALIABLE}
        </OfferInfoText>
        <OfferInfoText>
          <OfferInfoLabel>SECURITY DEPOSIT:</OfferInfoLabel> ${securityDeposite}&nbsp;&nbsp;&nbsp;&nbsp;
          <OfferInfoLabel>SECURENOW™ RENT AMOUNT:</OfferInfoLabel>{' '}
          {secureNowAmount ? `$${secureNowAmount}` : NOT_AVALIABLE}
        </OfferInfoText>
        <Link
          to={`/management/${companyId}/rentals/single-units/${offer.id}`}
          css={{ display: 'inline-block', marginTop: 15, fontSize: 12 }}
        >
          View Details
        </Link>
      </OfferInfo>
      <BiddersList offerId={offer?.id} refetchQueries={['AllRentalsOffersList']} />
    </Grid.Layout>
  );
};

const renderCompanyLink = (value: any, record: Offer) => {
  const company = record?.historyOfferSingleUnit?.company || null;
  const name = stringifyNA(company?.name);
  return (
    <span>
      <ConditionalLink to={`/management/${company?.id}`} condition={company?.status !== DRAFT_STATUS} text={name} />
      {company?.status === DRAFT_STATUS && <CompanyStatusTag css={{ marginLeft: '10px' }} status={company?.status} />}
    </span>
  );
};

const renderScreeningLink = (link: string) => {
  const screeningLink = link || 'Third-Party';

  return <span>{screeningLink}</span>;
};

type AllRentalsTableProps = {
  query: string;
  filters: FiltersType;
};

export const AllRentalsTable = ({ query, filters }: AllRentalsTableProps) => {
  const [{ page, sortBy, sortOrder, pageSize, pageSizeOptions }, onChange]: [
    TableState,
    TableStateOnChange,
  ] = useTableState();
  const offersListQueryFilter = useAllOffersListQueryFilter({ filters, query });
  const { data, loading, refetch } = useQuery<{ offersList: OfferListResponse }>(ALL_OFFERS_LIST_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      filter: offersListQueryFilter,
      sort: getSort({ sortOrder, sortBy, page, pageSize }),
      first: pageSize,
      skip: (page - 1) * pageSize,
    },
  });

  const dataSource = data?.offersList?.items ?? [];
  const total = data?.offersList?.count ?? pageSize;
  const pagination = { current: page, pageSize, total, showSizeChanger: true, pageSizeOptions };

  const columns: ColumnProps<Offer>[] = React.useMemo(
    () => [
      {
        title: 'id',
        dataIndex: 'offerId',
        sorter: dataSource?.length !== 0,
        width: '11%',
        ellipsis: true,
        sortOrder: sortBy === 'offerId' ? sortOrder : undefined,
      },
      {
        title: 'Client Name',
        dataIndex: 'clientName',
        sorter: dataSource?.length !== 0,
        sortOrder: sortBy === 'clientName' ? sortOrder : undefined,
        width: '10%',
        ellipsis: true,
        render: renderCompanyLink,
      },
      {
        title: 'Unit / Name',
        dataIndex: 'name',
        sorter: dataSource?.length !== 0,
        width: '8%',
        ellipsis: true,
        sortOrder: sortBy === 'name' ? sortOrder : undefined,
        render: function renderDetailsLink(value, { historyOfferSingleUnit, status, id }) {
          const companyId = historyOfferSingleUnit?.company?.id || '';

          return (
            <ConditionalLink
              condition={OFFER_STATUSES.draft !== status}
              text={historyOfferSingleUnit?.name}
              to={`/management/${companyId}/rentals/single-units/${id}`}
            />
          );
        },
      },
      {
        title: 'SecureNow™',
        dataIndex: 'secureNowAmount',
        sorter: dataSource?.length !== 0,
        width: '9%',
        ellipsis: true,
        sortOrder: sortBy === 'secureNow' ? sortOrder : undefined,
        render: value => (value ? `$${formatAmount(value)}` : NOT_AVALIABLE),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        sorter: dataSource?.length !== 0,
        ellipsis: true,
        width: '8%',
        sortOrder: sortBy === 'status' ? sortOrder : undefined,
        render: function renderStatus(status) {
          return <OfferStatusTag status={status} />;
        },
      },
      {
        title: 'Starts / Ends',
        dataIndex: 'endsIn',
        sorter: dataSource?.length !== 0,
        ellipsis: true,
        width: '9%',
        sortOrder: sortBy === 'endsIn' ? sortOrder : undefined,
        render: (value, offer) => {
          return (
            DateTime.fromISO(offer?.startsIn).toFormat('D') + ' - ' + DateTime.fromISO(offer?.endsIn).toFormat('D')
          );
        },
      },
      {
        title: 'Prospects',
        dataIndex: 'bidders',
        sorter: false,
        width: '7%',
        ellipsis: true,
        sortOrder: sortBy === 'bidders' ? sortOrder : undefined,
        render: function renderRentersLink(value, { id, historyOfferSingleUnit, bid }) {
          const companyId = historyOfferSingleUnit?.company?.id || '';

          if (bid && bid?.count > 0) {
            return (
              <Link to={`/management/${companyId}/rentals/single-units/${id}/renters`}>{bid ? bid.count : 0}</Link>
            );
          } else return bid ? bid.count : 0;
        },
      },
      {
        title: 'Highest Bid',
        dataIndex: 'highestBid',
        sorter: false,
        width: '7%',
        ellipsis: true,
        sortOrder: sortBy === 'highestBid' ? sortOrder : undefined,
        render: (value, { bid }) =>
          bid?.items && bid.items.length > 0 ? '$' + formatAmount(bid.items[0].rentAmount) : '-',
      },
      {
        title: 'Application / Screening',
        dataIndex: 'livoManagesScreening',
        sorter: false,
        width: '9%',
        sortOrder: sortBy === 'livoManagesScreening' ? sortOrder : undefined,
        render: value => (value ? 'Livo' : renderScreeningLink(value?.screeningLink)),
      },
      {
        title: 'Last Updated',
        dataIndex: 'updatedAt',
        sorter: dataSource?.length !== 0,
        ellipsis: true,
        width: '9%',
        sortOrder: sortBy === 'updatedAt' ? sortOrder : undefined,
        render: value => DateTime.fromISO(value).toFormat('D - t'),
      },
      {
        title: '',
        dataIndex: '',
        width: '10%',
        render: function renderCopyPublicLink(record) {
          return <CopyPublicLink {...record} />;
        },
      },
      {
        title: '',
        dataIndex: '',
        width: '43px',
        render: function renderAllRentalsTableActions(record) {
          return <AllRentalsTableActions {...record} />;
        },
      },
    ],
    [dataSource, sortBy, sortOrder],
  );

  return (
    <Table
      rowKey="id"
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      onChange={onChange}
      pagination={pagination}
      scroll={{ x: 1500 }}
      expandedRowRender={({ ...props }) => <ExpandableRow {...props} />}
      onExpand={expanded => expanded && refetch()}
    />
  );
};
