import React from 'react';
import { useDialogState } from '@/shared/hooks';
import { Dialog } from './Dialog';

export const DEFAULT_AMENITY_UPDATE_DIALOG = 'DEFAULT_AMENITY_UPDATE_DIALOG';

export const DefaultAmenityUpdateDialog = () => {
  const { close, closeWithConfirm, isVisible, props } = useDialogState(DEFAULT_AMENITY_UPDATE_DIALOG);

  if (!isVisible) {
    return null;
  }

  return <Dialog {...props} close={close} closeWithConfirm={closeWithConfirm} isVisible={isVisible} />;
};
