import React from 'react';
import { Form, Field } from '8base-react-sdk';
import { Button } from 'antd';

import { Maybe, Bid } from '@/shared/types/graphql';
import { Grid, CheckboxField } from '@/components';
import { InformationSection } from '@/pro';
import { styled } from '@/theme';
import { TOAST_SUCCESS_MESSAGE, OFFER_STATUSES } from '@/shared/constants';

import { OFFER_CHECKLIST_MUTATION } from './queries';
import { useMutationSafe } from '@/shared/hooks';

const Label = styled.span`
  color: #323c47;
`;

const Announcement = styled.div`
  font-size: 13px;
`;

const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.vars.primaryColor};
  border-radius: 4px;
  padding: 15px 22px;
  position: relative;
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;

  .ant-btn-round.ant-btn-sm {
    font-size: 10px;
    height: 26px;
    padding: 0 12px;
  }
`;

type OfferChecklistFormProps = { bid?: Maybe<Bid> };

export const OfferChecklistForm = ({ bid }: OfferChecklistFormProps) => {
  const [bidUpdate] = useMutationSafe(OFFER_CHECKLIST_MUTATION, {
    refetchQueries: ['RenterDetailsWithBidModalRenterQuery'],
    awaitRefetchQueries: true,
    context: {
      [TOAST_SUCCESS_MESSAGE]: 'Successfully Updated',
    },
  });

  const onSubmit = React.useCallback(
    async (data: any) => {
      await bidUpdate({
        variables: {
          data,
        },
      });
    },
    [bidUpdate],
  );

  const INITIAL_VALUES = {
    id: bid?.id,
    offerAccepted: bid?.offerAccepted,
    applicationFormComplete: bid?.applicationFormComplete,
    screeningComplete: bid?.screeningComplete,
    leaseSigned: bid?.leaseSigned,
    depositCheck: bid?.depositCheck,
    reportUrl: bid?.renter?.westernReportingScreenings?.items[0]?.reportURL,
    screeningStatus: bid?.renter?.westernReportingScreenings?.items[0]?.status,
    livoManagesScreening: bid?.offer?.livoManagesScreening,
    thirdPartyScreeningLink: bid?.offer?.screeningLink,
  };

  const offerStatus = bid?.offer?.status;

  const isFormEditable =
    offerStatus === OFFER_STATUSES.ended ||
    offerStatus === OFFER_STATUSES.closed ||
    offerStatus === OFFER_STATUSES.secured;
  const isFormDisabled = !isFormEditable && !bid?.offerAccepted;

  return (
    <Form type="UPDATE" tableSchemaName="Bid" initialValues={INITIAL_VALUES} onSubmit={onSubmit}>
      {({ handleSubmit, submitting, pristine }) => (
        <Container>
          <InformationSection header="Pending items checklist">
            <Grid.Layout gap="15px">
              <Announcement>
                <Label>Important:</Label> All items must be complete before the lease can be awarded to this renter
              </Announcement>
              <Grid.Layout gap="12px">
                <Field
                  disabled
                  name="offerAccepted"
                  component={CheckboxField}
                  className="livo-checkbox-circle"
                  text="Offer accepted"
                />
                <Field
                  name="applicationFormComplete"
                  disabled={isFormDisabled}
                  component={CheckboxField}
                  className="livo-checkbox-circle"
                  text="Application and Screening"
                />
                {/*<div css={{ display: 'flex' }}>*/}
                {/*  <Field*/}
                {/*    name="screeningComplete"*/}
                {/*    disabled={isFormDisabled}*/}
                {/*    component={CheckboxField}*/}
                {/*    className="livo-checkbox-circle"*/}
                {/*    text="Screening complete"*/}
                {/*  />*/}
                {/*  <If condition={!!INITIAL_VALUES.livoManagesScreening}>*/}
                {/*    <span css={{ marginLeft: 20 }}>*/}
                {/*      {INITIAL_VALUES.reportUrl ? (*/}
                {/*        <WebsiteLink website={INITIAL_VALUES.reportUrl} text="Open link" />*/}
                {/*      ) : (*/}
                {/*        <WesternReportingStatusTag status={INITIAL_VALUES.screeningStatus} />*/}
                {/*      )}*/}
                {/*    </span>*/}
                {/*  </If>*/}
                {/*</div>*/}
                {/*<Field*/}
                {/*  name="depositCheck"*/}
                {/*  disabled={isFormDisabled}*/}
                {/*  component={CheckboxField}*/}
                {/*  className="livo-checkbox-circle"*/}
                {/*  text="Deposit check"*/}
                {/*/>*/}
                <Field
                  name="leaseSigned"
                  disabled={isFormDisabled}
                  component={CheckboxField}
                  className="livo-checkbox-circle"
                  text="Lease signed"
                />
              </Grid.Layout>
            </Grid.Layout>
          </InformationSection>
          <If condition={!pristine}>
            <ButtonContainer>
              <Button
                type="primary"
                shape="round"
                size="small"
                ghost
                onClick={event => {
                  return handleSubmit(event);
                }}
                loading={submitting}
                disabled={submitting || pristine}
              >
                Save updates
              </Button>
            </ButtonContainer>
          </If>
        </Container>
      )}
    </Form>
  );
};
