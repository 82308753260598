import React from 'react';
import styled from '@emotion/styled';

const Description = styled.div`
  margin-bottom: 17px;
  color: #919daa;
  font-family: Poppins;
  font-size: 1.3rem;
  line-height: 1.5;
  word-break: break-word;

  ol,
  ul {
    padding-left: 10px;
    list-style: revert;
  }
`;

export const PropertyDescription: React.FC = ({ children, ...rest }) => <Description {...rest}>{children}</Description>;
