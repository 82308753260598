import { Auth, AUTH_STRATEGIES } from '@8base/auth';
const { REACT_APP_AUTH_DOMAIN, REACT_APP_AUTH_CLIENT_ID } = process.env;

export const authClient = Auth.createClient(
  {
    strategy: AUTH_STRATEGIES.WEB_AUTH0,
    subscribable: true,
  },
  {
    domain: REACT_APP_AUTH_DOMAIN,
    clientId: REACT_APP_AUTH_CLIENT_ID,
    redirectUri: `${window.location.origin}/auth/callback`,
    logoutRedirectUri: `${window.location.origin}/auth`,
  },
);
