import React from 'react';
import { Modal } from 'antd';
import { useQuery } from 'react-apollo';
import { Form, Field } from '8base-react-sdk';
import * as R from 'ramda';

import { Radio } from 'antd';
import { dialogsContext } from '@/providers';
import {
  AsyncContent,
  Grid,
  RadioGroupField,
  DocumentInputField,
  InputField,
  ModalFooter,
  Scrollable,
} from '@/components';
import { RenterInformation } from '@/pro';
import { BID_STATUSES, OFFER_STATUSES, TOAST_SUCCESS_MESSAGE } from '@/shared/constants';
import { getFullName } from '@/shared/utils';
import { Renter } from '@/shared/types/graphql';

import { RENTER_DETAILS_WITH_BID_MODAL_RENTER_QUERY } from './queries';
import { BIDDER_REJECT_DIALOG } from '@/dialogs/bidder/BidderRejectDialog';
import { FormContainer, FormTitle, FullRowBlock } from './styled';

import { validateEntityDataByDraft } from '@/shared/utils';
import { SchemaValidation } from './shema';

import { MUTATION_EVENT, EventTypes } from '@/shared/graphql/eventResolver';
import { useMutationSafe } from '@/shared/hooks';

type DialogProps = {
  refetchQueries?: Array<string>;
  close: () => void;
  isVisible: boolean;
  isWinner?: boolean;
  renterId?: string;
  offerId?: string;
};

export const Dialog: React.FC<DialogProps> = ({
  refetchQueries = [],
  isVisible,
  close,
  isWinner,
  renterId,
  offerId,
}) => {
  const { openDialog } = React.useContext(dialogsContext);

  const { data: renterData, loading: renterLoading } = useQuery<{ renter: Renter }>(
    RENTER_DETAILS_WITH_BID_MODAL_RENTER_QUERY,
    {
      fetchPolicy: 'network-only',
      skip: !renterId || !offerId,
      variables: {
        renterId: renterId,
        offerId: offerId,
      },
    },
  );

  const refetchQueriesList = [...refetchQueries, 'RenterDetailsWithBidModalRenterQuery'];

  const firstName = renterData?.renter?.user?.firstName;
  const lastName = renterData?.renter?.user?.lastName;
  const fullName = getFullName(firstName, lastName);

  const bid = renterData?.renter?.bid?.items[0];
  const bidId = bid?.id;
  const status = bid?.status || '';

  const offer = bid?.offer;
  const offerStatus = offer?.status;

  const withFooter =
    (offerStatus === OFFER_STATUSES.live ||
      offerStatus === OFFER_STATUSES.ended ||
      offerStatus === OFFER_STATUSES.closed ||
      offerStatus === OFFER_STATUSES.secured) &&
    (status === BID_STATUSES.bidder || status === BID_STATUSES.secured);

  const [sendLeaseContract, { loading: loadingSendLeaseContract }] = useMutationSafe(MUTATION_EVENT, {
    onCompleted: close,
    context: {
      [TOAST_SUCCESS_MESSAGE]: 'Lease Contract Successfully Sent',
    },
  });

  const onSubmit = async (data: any) => {
    await sendLeaseContract({
      variables: {
        event: {
          type: EventTypes.SEND_LEASE_CONTRACT,
          payload: { ...R.omit(['leaseContractSelect'], data), bidId, offerId },
        },
      },
    });
  };

  const initialValues = React.useMemo(
    () => ({
      leaseContractSelect: offer?.leaseLink ? 'link' : 'upload',
      leaseLink: offer?.leaseLink,
      leaseContract: offer?.leaseContract,
    }),
    [offer],
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={values => {
        return validateEntityDataByDraft(SchemaValidation, values);
      }}
    >
      {({ handleSubmit, form, pristine, values, submitting, errors }) => {
        return (
          <Modal
            title={fullName}
            className="livo-modal"
            visible={isVisible}
            onCancel={close}
            width={isWinner ? '100%' : '500px'}
            style={{ maxWidth: 1110 }}
            centered
            footer={
              withFooter ? (
                <ModalFooter
                  cancelProps={{
                    text: 'Reject Renter',
                    onClick: () =>
                      openDialog({
                        name: BIDDER_REJECT_DIALOG,
                        props: {
                          bidderId: bidId,
                          refetchQueries: refetchQueriesList,
                        },
                      }),
                  }}
                  okProps={{
                    text: 'Send Lease Contract',
                    type: 'primary',
                    disabled: pristine,
                    onClick: event => {
                      return handleSubmit(event);
                    },
                  }}
                />
              ) : null
            }
          >
            <AsyncContent loading={renterLoading || loadingSendLeaseContract} height="466px">
              <Scrollable padding="24px 54px">
                <Grid.Layout gap="58px" columns={isWinner ? 'auto auto' : 'auto'}>
                  <RenterInformation renter={renterData?.renter} bid={bid} />

                  {isWinner && (
                    <FormContainer>
                      <FullRowBlock>
                        <FormTitle css={{ marginTop: '19px' }}>Lease</FormTitle>
                      </FullRowBlock>
                      <FullRowBlock>
                        <Field
                          label="How do you want to attach lease contract?"
                          name="leaseContractSelect"
                          component={RadioGroupField}
                          stretch
                          data-e2e-id="modal.offer.create.leaseContractSelect"
                        >
                          <Radio value="upload">Upload File</Radio>
                          <Radio value="link">Provide Link</Radio>
                        </Field>
                      </FullRowBlock>
                      <If condition={values.leaseContractSelect === 'upload'}>
                        <Field
                          name="leaseContract"
                          label="Upload Lease Contract*"
                          buttonText="Select File (PDF, DOC, DOCX)"
                          maxFiles={1}
                          component={DocumentInputField}
                          accept={['.pdf', '.doc', '.docx']}
                          previewProps={{
                            output: { f: 'jpg', p: 1 },
                            resize: { width: 150 },
                          }}
                        />
                      </If>
                      <If condition={values.leaseContractSelect === 'link'}>
                        <Field
                          name="leaseLink"
                          label="Provide Lease Link*"
                          autoComplete="disabled"
                          component={InputField}
                          placeholder="lease link"
                          data-e2e-id="modal.offer.create.leaseLink"
                        />
                      </If>
                    </FormContainer>
                  )}
                </Grid.Layout>
              </Scrollable>
            </AsyncContent>
          </Modal>
        );
      }}
    </Form>
  );
};
