import React from 'react';
import { Modal, Icon, Typography } from 'antd';

import { ModalFooter } from '@/components';
import { RedAlert } from '@/static/icons';
import { useDialogState } from '@/shared/hooks';

export const TEAM_MEMBER_CONFIRM_DIALOG = 'TEAM_MEMBER_CONFIRM_DIALOG';

export const TeamMemberConfirmDialog = () => {
  const { close, isVisible, props } = useDialogState(TEAM_MEMBER_CONFIRM_DIALOG);

  const confirmAction = props?.confirmAction;
  const type = props?.type;

  const title = React.useMemo(
    () => (
      <>
        <Icon
          component={RedAlert}
          css={{ marginRight: '8px', fontSize: '20px', lineHeight: '22px', verticalAlign: 'middle' }}
        />
        {type} invitation
      </>
    ),
    [type],
  );

  if (!isVisible) {
    return null;
  }

  return (
    <Modal
      title={title}
      visible={isVisible}
      onCancel={close}
      footer={
        <ModalFooter
          cancelProps={{
            onClick: close,
          }}
          okProps={{
            disabled: !confirmAction,
            onClick: () => {
              confirmAction && confirmAction();
              close();
            },
            type: 'danger',
            text: 'Ok',
          }}
        />
      }
      centered
    >
      <Typography.Paragraph>Are you sure you want to {type} invitation?</Typography.Paragraph>
    </Modal>
  );
};
