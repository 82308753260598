import { JPGIcon, PNGIcon, SVGIcon, UnknownFileIcon } from '@/static/icons';

const JPG = 'image/jpeg';
const JPEG = 'image/jpeg';
const PNG = 'image/png';
const SVG = 'image/svg+xml';

type MimetypeDictionary = Record<string, any>;

type FileType = {
  mimetype?: string;
  meta?: {
    mimetype: string;
  };
};

const imageInfoByMimetype: MimetypeDictionary = {
  [JPG]: {
    type: 'jpg',
    icon: JPGIcon,
  },
  [JPEG]: {
    type: 'jpeg',
    icon: JPGIcon,
  },
  [PNG]: {
    type: 'png',
    icon: PNGIcon,
  },
  [SVG]: {
    type: 'svg',
    icon: SVGIcon,
  },
};

export const getImageTypeByMimetype = (document: FileType) => {
  return document.mimetype
    ? imageInfoByMimetype[document.mimetype].type
    : document?.meta?.mimetype
    ? imageInfoByMimetype[document.meta.mimetype].type
    : '';
};

export const getImageIconByMimetype = (document: FileType) => {
  return document.mimetype
    ? imageInfoByMimetype[document.mimetype].icon
    : document?.meta?.mimetype
    ? imageInfoByMimetype[document.meta.mimetype].icon
    : UnknownFileIcon;
};
