import styled from '@/theme/styled';
import React from 'react';
import { NavLink } from '@/layouts/ManagementLayout/Sidebar/components/Nav/components';

const NavMenuItemTag = styled.div`
  padding-left: 30px;
  margin-right: 40px;
`;

export const NavMenuItem: React.FC<{ path?: string }> = ({ path, children }) => {
  return !path ? (
    <NavMenuItemTag>{children}</NavMenuItemTag>
  ) : (
    <NavMenuItemTag
      onClick={event => {
        event.stopPropagation();
      }}
    >
      <NavLink to={path}>{children}</NavLink>
    </NavMenuItemTag>
  );
};
