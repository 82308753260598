import gql from 'graphql-tag';

export const UPDATE_OFFER_MODAL_OFFER_QUERY = gql`
  query UpdateOfferModalOffer($id: ID!) {
    offer(id: $id) {
      id
      numberId
      rentAmount
      termsOfLease
      securityDeposite
      securityDepositeEditable
      moveinDate
      incrementedBidAmount
      endsIn
      reenableOffer
      startsIn
      applicationLink
      secureNow
      secureNowAmount
      timezone
      calendarLink
      moveinDateEditable
      status
      timeframeToSignContract
      startImmediate
      minimumLeaseLength
      maximumLeaseLength
      leaseContract {
        id
        fileId
        filename
        downloadUrl
        meta
      }
      leaseLink
      screeningLink
      livoManagesScreening
      contact {
        id
        lastName
        firstName
      }
    }
  }
`;

export const UPDATE_OFFER_MODAL_OFFER_UPDATE = gql`
  mutation UpdateOfferModalOfferUpdate($filter: OfferKeyFilter, $data: OfferUpdateInput!) {
    offerUpdate(filter: $filter, data: $data) {
      id
      status
      rentAmount
      termsOfLease
      securityDeposite
      securityDepositeEditable
      moveinDate
      incrementedBidAmount
      endsIn
      startsIn
      secureNow
      secureNowAmount
      timezone
      moveinDateEditable
      timeframeToSignContract
      leaseContract {
        id
        fileId
        filename
        downloadUrl
        meta
      }
      screeningLink
      livoManagesScreening
      contact {
        id
        lastName
        firstName
      }
    }
  }
`;
