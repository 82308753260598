import gql from 'graphql-tag';

export const FIRST_TIMER_MUTATION = gql`
  mutation FirstTimerMutaton($data: UserUpdateInput!, $filter: UserKeyFilter) {
    userUpdate(data: $data, filter: $filter) {
      id
      firstTimer
    }
  }
`;
