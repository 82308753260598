import gql from 'graphql-tag';

export enum OfferUpdateByEventTypes {
  OFFER_END_CLOSE = 'offerEndClose',
}

export enum CompanyEventTypes {
  ARCHIVE = 'archive-company-by-id',
  REVIVE = 'revive-company-by-id',
}

export enum UserEventTypes {
  ARCHIVE = 'archive-user-by-id',
  REVIVE = 'revive-user-by-id',
}

export enum TeamMemberEventTypes {
  ADD = 'addTeamMember',
}

export const MUTATION_EVENT = gql`
  mutation MutationEvent($event: EventResolverInput!) {
    eventResolver(event: $event) {
      success
      inner
    }
  }
`;

export const QUERY_EVENT = gql`
  query QueryEvent($event: EventResolverInput!) {
    eventResolverQuery(event: $event) {
      success
      inner
    }
  }
`;

export enum EventTypes {
  GET_OFFER = 'getOffer',
  AWARD_LEASE = 'awardLease',
  SEND_LEASE_CONTRACT = 'sendLeaseContract',
}
