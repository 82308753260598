import React from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useQuery } from 'react-apollo';

import { useSearch } from '@/shared/hooks';
import { CustomCard, TableHeader, Loader } from '@/components';
import { SingleUnit, Offer } from '@/shared/types/graphql';

import { RENTERS_AMOUNT_QUERY, RENTERS_AMOUNT_BY_OFFER_QUERY } from '../queries';
import { BidsTable } from './Table';

type SingleUnitRentersProps = {
  redirectPath: string;
  queryOfferFirstId?: string | null;
};

export const SingleUnitRenters = (props: SingleUnitRentersProps) => {
  if (props.queryOfferFirstId) {
    return <OfferFirst {...props} />;
  } else {
    return <SingleUnitFirst {...props} />;
  }
};

function OfferFirst({ redirectPath, queryOfferFirstId }: SingleUnitRentersProps) {
  const { data, loading } = useQuery<{ offer: Offer }>(RENTERS_AMOUNT_BY_OFFER_QUERY, {
    variables: {
      id: queryOfferFirstId,
    },
    skip: !queryOfferFirstId,
  });

  const offer = data?.offer;
  const bidsCount = offer?.bid?.count;

  return <Renters loading={loading} offer={offer} bidsCount={bidsCount} redirectPath={redirectPath} />;
}

function SingleUnitFirst({ redirectPath }: SingleUnitRentersProps) {
  const { singleUnitId } = useParams();

  const { data, loading } = useQuery<{ singleUnit: SingleUnit }>(RENTERS_AMOUNT_QUERY, {
    variables: {
      id: singleUnitId,
    },
    skip: !singleUnitId,
  });

  const offer = data?.singleUnit.activeOffer;
  const bidsCount = offer?.bid?.count;

  return <Renters loading={loading} offer={offer} bidsCount={bidsCount} redirectPath={redirectPath} />;
}

function Renters({
  loading,
  offer,
  bidsCount,
  redirectPath,
}: {
  loading: boolean;
  offer?: Offer | null;
  bidsCount?: number;
  redirectPath: string;
}) {
  const [{ throttledSearch }, setSearch] = useSearch({ sortBy: 'rentAmount' });

  const onChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.currentTarget.value);
    },
    [setSearch],
  );

  if (loading) {
    return (
      <CustomCard>
        <Loader stretch />
      </CustomCard>
    );
  }

  if (!offer || bidsCount === 0) {
    return <Redirect to={redirectPath} />;
  }

  return (
    <CustomCard
      padding="none"
      header={
        <TableHeader
          title="Renters"
          searchProps={{ query: throttledSearch, placeholder: 'Search by Renters Name', onChange }}
        />
      }
    >
      <BidsTable query={throttledSearch} offerId={offer?.id} />
    </CustomCard>
  );
}
