import React from 'react';

import { useMobileDetect } from 'livo-components/src/hooks';
import { Link, RowFlex } from '@/components';
import { Notifications } from '@/pro';

import { UserInfo } from '../UserInfo';
import { Burger, MobileNavList } from './components';
import { AppRoutes } from '@/shared/constants/appRoutes';

type NavProps = {
  isRenter: boolean;
};

export const Navigation = ({ isRenter }: NavProps) => {
  const [navToggled, setNavToggled] = React.useState(false);
  const detectMobile = useMobileDetect();
  const isMobile = detectMobile.isMobile();

  const handleToggleNav = () => {
    setNavToggled(s => !s);
  };

  return isMobile ? (
    <React.Fragment>
      <Notifications />
      <Burger toggled={navToggled} onClick={handleToggleNav} />
      {navToggled && <MobileNavList isRenter={isRenter} handleToggleNav={handleToggleNav} />}
    </React.Fragment>
  ) : (
    <React.Fragment>
      {isRenter && (
        <RowFlex gap="xl">
          <Link to={AppRoutes.OFFERS}>My Rentals</Link>
        </RowFlex>
      )}
      {!isRenter && <Link to={AppRoutes.MANAGEMENT}>Management</Link>}

      <Notifications />
      <UserInfo />
    </React.Fragment>
  );
};
