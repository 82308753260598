import styled from '@/theme/styled';

export const Container = styled.div<{ isSidebarOpen: boolean }>`
  display: flex;
  height: 100vh;
  transition: 0.2s;
`;

export const Main = styled.main`
  flex: 1;
  display: grid;
  grid-template-rows: ${({ theme }) => `${theme.vars.headerHeight} calc(100vh - ${theme.vars.headerHeight})`};
  background-color: #f4f5f6;
`;

export const Content = styled.div`
  display: grid;
  padding: 24px 24px 0 24px;
  overflow: auto;
  grid-template-rows: 1fr ${({ theme }) => `${theme.vars.poweredByHeight}`};
`;

export const InnerContent = styled.div`
  display: grid;
  padding-bottom: 24px;
`;
