import React from 'react';
import { CustomCard, TableHeader } from '@/components';
import { RankingTable } from '@/routes/management/settings/entity/ranking/root/RankingTable';

export const Ranking = () => {
  return (
    <CustomCard padding="none" header={<TableHeader title="Ranking" />}>
      <RankingTable />
    </CustomCard>
  );
};
