import gql from 'graphql-tag';

export const NOTIFICATIONS_LIST_QUERY = gql`
  query NotificationsList {
    notifications {
      userPreparedNotificationsList(sort: { createdAt: DESC }, first: 100) {
        items {
          id
          title
          message
          read
          createdAt
        }
        count
      }
    }
    userNotificationsList(filter: { read: { equals: false } }) {
      count
    }
  }
`;

export const NOTIFICATIONS_SUBSCRIPTION = gql`
  subscription {
    UserNotifications {
      mutation
    }
  }
`;

export const NOTIFICATION_UPDATE_MUTATION = gql`
  mutation UserNotificationUpdate($data: UserNotificationUpdateInput!, $filter: UserNotificationKeyFilter!) {
    userNotificationUpdate(data: $data, filter: $filter) {
      id
      read
    }
  }
`;

export const NOTIFICATION_DELETE_MUTATION = gql`
  mutation UserNotificationDelete($filter: UserNotificationKeyFilter!) {
    userNotificationDelete(filter: $filter) {
      success
    }
  }
`;
