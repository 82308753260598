import React from 'react';
import Papa from 'papaparse';
import * as R from 'ramda';
import { toast } from 'react-toastify';
import { Modal, Divider, Checkbox } from 'antd';

import {
  CommunityCreateManyInput,
  Maybe,
  MutationCommunityImportArgs,
  CommunityImportResponse,
} from '@/shared/types/graphql';
import { TableCsvPreviewColumn } from '@/components/TableCsvPreview/TableCsvPreview';
import { ModalFooter, TableCsvPreview } from '@/components';
import {
  parsePhone,
  parseCommunityType,
  parseNumber,
  stringToUppercase,
  parseString,
  parseImportErrors,
  checkParsedCSVDataIsEmpty,
} from '@/shared/utils';
import { HIDE_TOAST_ERROR_MESSAGE, ERROR_CODES } from '@/shared/constants';
import { ACTIVE_STATUS } from 'livo-shared';
import { DropzoneContainer, PreviewContainer, PreviewSettings, DropArea, FileInput } from './styles';
import Dropzone from 'react-dropzone';
import { COMMUNITIES_IMPORT_MODAL_MUTATION } from './queries';
import { ImportValidationErrorsList, ImportValidationErrorDetails } from '@/shared/types';
import { useMutationSafe } from '@/shared/hooks';
import { dialogsContext } from '@/providers/DialogsProvider';
import { IMPORT_CONFIRM_DIALOG } from '@/dialogs';

const IMPORT_TABLE_COLUMNS: Array<TableCsvPreviewColumn> = [
  { key: 'name', title: 'Property Name' },
  { key: 'numberOfUnits', title: 'Units', parser: parseNumber },
  { key: 'type', title: 'Type', parser: parseCommunityType },
  { key: 'managementCompany', title: 'Management Company' },
  { key: 'managerName', title: 'Contact Name' },
  { key: 'managerTitle', title: 'Contact Title' },
  { key: 'managerEmail', title: 'Contact Email' },
  { key: 'managerPhoneNumber', title: 'Contact Mobile', parser: parsePhone },
  { key: 'address.street1', title: 'Address 1' },
  { key: 'address.street2', title: 'Address 2' },
  { key: 'address.city', title: 'City' },
  { key: 'address.state', title: 'State', parser: stringToUppercase },
  { key: 'address.zip', title: 'Zip' },
  { key: 'phoneNumber', title: 'Phone Number', parser: parsePhone },
  { key: 'website', title: 'Website' },
];

type DialogProps = {
  closeWithConfirm: () => void;
  close: () => void;
  isVisible: boolean;
  companyId?: string;
  options?: {
    refetchQueries?: Array<string>;
    awaitRefetchQueries?: boolean;
  };
};

type PrepareImportDataArgs = {
  hasHeader: boolean;
  parsed?: Papa.ParseResult<any>;
  tableColumns: Array<TableCsvPreviewColumn>;
  companyId?: string;
};

const prepareImportData = ({ hasHeader, parsed, tableColumns, companyId }: PrepareImportDataArgs) => {
  return parsed?.data.slice(hasHeader ? 1 : 0).map(value => {
    return tableColumns.reduce(
      (result, column, index) => {
        const { key, parser } = column;

        const path = key.split('.');

        const resultValue = parser ? parser(parseString(value[index])) : parseString(value[index]);

        return R.assocPath(path, resultValue, result);
      },
      {
        status: ACTIVE_STATUS,
        company: {
          connect: {
            id: companyId,
          },
        },
        address: { country: 'United States' },
      } as CommunityCreateManyInput,
    );
  }) as Maybe<CommunityCreateManyInput>[];
};

export const Dialog: React.FC<DialogProps> = ({ close, closeWithConfirm, isVisible, ...props }) => {
  const { openDialog } = React.useContext(dialogsContext);

  const [communityImport, { loading: communityImportLoading }] = useMutationSafe<
    { communityImport: CommunityImportResponse },
    MutationCommunityImportArgs
  >(COMMUNITIES_IMPORT_MODAL_MUTATION, {
    context: {
      [HIDE_TOAST_ERROR_MESSAGE]: true,
    },
    onCompleted: ({ communityImport }) => {
      const { validationSuccess, importSuccess } = communityImport;
      if (validationSuccess && !importSuccess) {
        setImportErrors(null);
        toast.success('Your CSV file successfully passed the validation');
      }
      if (importSuccess) {
        close();
        toast.success('Property Successfully Created');
      }
    },
    onError: error => {
      const errorCode = R.path(['graphQLErrors', 0, 'code'], error);
      if (errorCode === ERROR_CODES.ImportValidationErrorCode) {
        const details = R.path(['graphQLErrors', 0, 'details'], error) as ImportValidationErrorDetails | undefined;
        const importErrors = parseImportErrors(details);
        setImportErrors(importErrors);
        toast.error('Your CSV file has validation errors. Fix them and try again.');
      } else {
        toast.error('Your CSV file has an unknown errors.');
      }
    },
  });

  const [fileCSV, setFileCSV] = React.useState(null);
  const [parsed, setParsed]: [
    Papa.ParseResult<any> | undefined,
    (arg: Papa.ParseResult<any> | undefined) => void,
  ] = React.useState();
  const [importErrors, setImportErrors] = React.useState<Maybe<ImportValidationErrorsList> | undefined>(null);
  const [hasHeader, setHasHeader] = React.useState<boolean>(true);

  const onChangeCSV = (files: any) => {
    const fileCSV = Array.isArray(files) ? files[0] : files;

    if (fileCSV) {
      setFileCSV(fileCSV);
    }
  };

  const resetImportDialogData = () => {
    setFileCSV(null);
    setParsed(undefined);
  };

  const onSubmit = React.useCallback(
    async (importData: PrepareImportDataArgs & { validationOnly?: boolean }) => {
      const { hasHeader, parsed, tableColumns, validationOnly } = importData;

      const data = prepareImportData({
        hasHeader,
        parsed,
        tableColumns,
        companyId: props.companyId,
      });

      await communityImport({
        variables: {
          data,
          validationOnly,
        },
        ...(props?.options || {}),
      });
    },
    [communityImport, props],
  );

  const handleSetHasHeader = (e: any) => {
    const checked = !!e?.target?.checked;
    setHasHeader(checked);
    onSubmit({
      hasHeader: checked,
      parsed,
      tableColumns: IMPORT_TABLE_COLUMNS,
      validationOnly: true,
    });
  };

  const onParseComplete = React.useCallback(
    (results?: Papa.ParseResult<any>) => {
      if (checkParsedCSVDataIsEmpty(results)) {
        resetImportDialogData();
      } else {
        setParsed(results);
        onSubmit({
          hasHeader,
          parsed: results,
          tableColumns: IMPORT_TABLE_COLUMNS,
          validationOnly: true,
        });
      }
    },
    [hasHeader, onSubmit],
  );

  return (
    <Modal
      title="Properties - Import Data from CSV"
      className="livo-modal"
      visible={isVisible}
      onCancel={fileCSV ? closeWithConfirm : close}
      width="100%"
      css={{ maxWidth: parsed ? '1110px' : '618px' }}
      centered
      footer={
        <ModalFooter
          cancelProps={{
            disabled: communityImportLoading,
            onClick: fileCSV ? closeWithConfirm : close,
          }}
          okProps={{
            text: 'Import',
            type: 'primary',
            htmlType: 'submit',
            loading: communityImportLoading,
            disabled: communityImportLoading || !!importErrors || !parsed,
            onClick: () =>
              openDialog({
                name: IMPORT_CONFIRM_DIALOG,
                props: {
                  confirmAction: () =>
                    onSubmit({
                      hasHeader,
                      parsed,
                      tableColumns: IMPORT_TABLE_COLUMNS,
                    }),
                },
              }),
          }}
        />
      }
    >
      <>
        <Choose>
          <When condition={!fileCSV}>
            <DropzoneContainer>
              <Dropzone
                accept=".csv"
                multiple={false}
                onDrop={data => {
                  onChangeCSV(data);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <DropArea {...getRootProps()}>
                    Select CSV file
                    <FileInput type="file" {...getInputProps()} />
                  </DropArea>
                )}
              </Dropzone>
            </DropzoneContainer>
          </When>
          <Otherwise>
            <PreviewContainer>
              <PreviewSettings>
                <Checkbox
                  checked={hasHeader}
                  onChange={handleSetHasHeader}
                  defaultChecked={hasHeader}
                  disabled={communityImportLoading}
                >
                  Has Header Row
                </Checkbox>
              </PreviewSettings>
              <Divider />
              <TableCsvPreview
                hasHeader={hasHeader}
                fileCSV={fileCSV}
                importErrors={importErrors}
                tableColumns={IMPORT_TABLE_COLUMNS}
                onParseComplete={onParseComplete}
                loading={communityImportLoading}
              />
            </PreviewContainer>
          </Otherwise>
        </Choose>
      </>
    </Modal>
  );
};
