import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import styled from '@emotion/styled';

import { getBreadcrumbs } from './utils';
import { BreadcrumbsProps, BreadcrumbItem, Breadcrumbs as BreadcrumbsType } from './types';
import { useBreadcrumbsRoutes } from './constants';

const Container = styled.div<{ hasPaddingLeft: boolean }>`
  padding-left: ${({ hasPaddingLeft }) => hasPaddingLeft && '24px'};
`;

const itemRender = (route: BreadcrumbItem, params: any, routes: BreadcrumbsType) => {
  const isLast = routes.indexOf(route) === routes.length - 1;

  const breadcrumbNameComponent =
    typeof route.breadcrumbName === 'function'
      ? React.createElement(route.breadcrumbName as any, { match: route.match })
      : route.breadcrumbName;

  return isLast ? (
    <span>{breadcrumbNameComponent}</span>
  ) : (
    <Link to={route.originalPath || route.path}>{breadcrumbNameComponent}</Link>
  );
};

export const Breadcrumbs = ({ hasPaddingLeft }: BreadcrumbsProps) => {
  const { pathname } = useLocation();
  const BreadcrumbsRoutes = useBreadcrumbsRoutes();
  const breadcrumbs = getBreadcrumbs(pathname, BreadcrumbsRoutes);

  return (
    <Container hasPaddingLeft={hasPaddingLeft}>
      <Breadcrumb itemRender={itemRender} routes={breadcrumbs as any} separator=">" />
    </Container>
  );
};
