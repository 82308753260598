import gql from 'graphql-tag';

export const UPDATE_SINGLE_UNIT_MODAL_QUERY = gql`
  query UpdateSingleUnitModal($id: ID!) {
    singleUnit(id: $id) {
      id
      numberId
      name
      type
      description
      features
      bedrooms
      bathrooms
      squareFt
      phone {
        number
        code
      }
      address {
        country
        street1
        street2
        zip
        city
        state
      }
      website
      showingType
      virtualTour
      singleUnitHasMedia(sort: { order: ASC }) {
        items {
          id
          altName
          order
          media {
            id
            name
            file {
              id
              fileId
              filename
              downloadUrl
              meta
            }
          }
        }
      }
      status
      singleUnitAmenity {
        id
        sports
        swimSpa
        children
        family
        pets
        leisureSocial
        creative
        laundry
        transportation
        security
        onsite
        technology
        common
        misc
        accessibility
      }
      amenity(groupBy: { query: { group: { key: { as: "groupKey" } }, _group: { as: "amenityKeysList" } } }) {
        items {
          id
          key
        }
        groups {
          groupKey: String
          amenityKeysList: GroupIds
        }
      }
    }
  }
`;

export const UPDATE_SINGLE_UNIT_MUTATION = gql`
  mutation UpdateSingleUnit($data: SingleUnitUpdateInput!) {
    singleUnitUpdate(data: $data, force: true) {
      id
      name
      type
      description
      features
      bedrooms
      bathrooms
      squareFt
      phone {
        number
        code
      }
      address {
        country
        street1
        street2
        zip
        city
        state
      }
      website
      singleUnitHasMedia(sort: { order: ASC }) {
        items {
          id
          altName
          order
          media {
            id
            name
            file {
              id
              fileId
              filename
              downloadUrl
              meta
            }
          }
        }
      }
      status
      amenity {
        items {
          id
        }
      }
    }
  }
`;

export const UPDATE_SINGLE_COMMUNITY_QUERY = gql`
  query UpdateSingleCommunity($id: ID!) {
    community(id: $id) {
      id
      name
    }
  }
`;
