import gql from 'graphql-tag';

export const AGENT_INFORMATION_BID_QUERY = gql`
  query AgentInformationBidQuery($id: ID) {
    bid(id: $id) {
      id
      offer {
        id
        contact {
          id
          lastName
          firstName
          email
          phone {
            code
            number
          }
          avatar {
            id
            downloadUrl
          }
        }
        leaseContract {
          id
          fileId
          filename
          downloadUrl
          meta
        }
        leaseLink
        historyOfferSingleUnit {
          id
          company {
            id
            name
            website
            contactName
            contactEmail
            contactPhoneNumber {
              code
              number
            }
          }
          community {
            id
            managerName
            managerEmail
            managerPhoneNumber {
              code
              number
            }
            communityManager {
              items {
                id
                firstName
                lastName
                email
                phone {
                  code
                  number
                }
              }
            }
            regionalManager {
              items {
                id
                firstName
                lastName
                email
                phone {
                  code
                  number
                }
              }
            }
          }
        }
      }
    }
  }
`;
