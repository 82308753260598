import { useCurrentUser, useAllowedCommunities } from '@/shared/hooks';

import { getApplicationRole } from '@/shared/utils';
import { APPLICATION_ROLE_KEYS } from 'livo-shared/src';
import { Maybe, Community, SingleUnit } from '@/shared/types/graphql';
import { ApplicationRole } from 'livo-shared';
import { useAllowedSingleUnits } from '@/shared/hooks/useAllowedSingleUnits';

export type Permissions = {
  isAdmin: boolean;
  isSuperAdmin: boolean;
  isRenter: boolean;
  isCompanyUser: boolean;
  isCompanyOwner: boolean;
  isCompanyAdmin: boolean;
  isCompanyManager: boolean;
  applicationRole: Maybe<ApplicationRole>;
  allowedCommunities: Array<Community>;
  allowedSingleUnits: Array<SingleUnit>;
};

export const usePermissions = (): Permissions => {
  const { user } = useCurrentUser();
  const allowedCommunities = useAllowedCommunities();
  const allowedSingleUnits = useAllowedSingleUnits();

  const applicationRole = getApplicationRole(user);

  const isAdmin = !!applicationRole?.admin;
  const isSuperAdmin = !!applicationRole?.superAdmin;
  const isRenter = applicationRole?.key === APPLICATION_ROLE_KEYS.renter;
  const isCompanyOwner = !!applicationRole?.companyOwner;
  const isCompanyAdmin = applicationRole?.key === APPLICATION_ROLE_KEYS.companyAdmin;
  const isCompanyManager = applicationRole?.key === APPLICATION_ROLE_KEYS.companyManager;
  const isCompanyUser = applicationRole?.key === APPLICATION_ROLE_KEYS.companyUser;

  return {
    isAdmin,
    isSuperAdmin,
    isRenter,
    isCompanyUser,
    isCompanyOwner,
    isCompanyAdmin,
    isCompanyManager,
    applicationRole,
    allowedCommunities,
    allowedSingleUnits,
  };
};
