import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

import { AmenityGroupListResponse } from '@/shared/types/graphql';
import { NEW_AMENITY_GROUPS } from '@/shared/constants';
import { GroupOptionItem } from '@/components/SelectField/SelectField';

export const AMENITY_GROUPS_LIST_QUERY = gql`
  query AmenityGroupsList {
    amenityGroupsList {
      items {
        id
        key
        defaultAmenity {
          items {
            id
            label
          }
        }
        amenity {
          items {
            id
            key
          }
        }
      }
    }
  }
`;

// const DEFAULT_AMENITIES = gql`
//   query DefaultAmenities {
//     defaultAmenitiesList {
//       items {
//         id
//         label
//         category {
//           id
//           key
//         }
//       }
//     }
//   }
// `;

export type AmenityGroupOptionItem = GroupOptionItem & {
  groupKey: string;
};

export type AmenityGroupOptionsList = Array<AmenityGroupOptionItem>;

type AmenityGroupsListQuery = {
  amenityGroupsList: AmenityGroupListResponse;
};

export type AmenityMap = Record<
  string,
  {
    id: string;
    key: string;
    groupKey: string;
  }
>;

export const useAmenityGroups = (): {
  groupedOptions: any;
  amenityMap: AmenityMap;
  data?: AmenityGroupsListQuery;
  loading: boolean;
} => {
  const { data, loading } = useQuery<AmenityGroupsListQuery>(AMENITY_GROUPS_LIST_QUERY);

  const amenityMap: AmenityMap = {};

  const groupedOptions =
    data?.amenityGroupsList?.items.map(amenityGroup => {
      const groupKey = amenityGroup?.key || '';

      const defaultOptions =
        amenityGroup?.defaultAmenity?.items.map(amenity => {
          const value = amenity?.label;
          const label = amenity?.label;

          return {
            value,
            label,
          };
        }) || [];

      return {
        groupName: NEW_AMENITY_GROUPS[groupKey]?.label,
        groupKey,
        options: defaultOptions,
      };
    }) || [];
  const filteredOptions = groupedOptions.filter(el => el?.options?.length !== 0);

  return { groupedOptions: filteredOptions, amenityMap, data, loading };
};
