import gql from 'graphql-tag';

export const ADMIN_DASHBOARD_INFO_QUERY = gql`
  query CompanyQuery($bidsFilter: BidFilter) {
    companiesList {
      items {
        id
      }
      count
    }
    bidsList(filter: $bidsFilter) {
      items {
        id
        rentAmount
        offer {
          id
          rentAmount
        }
      }
      count
    }
    singleUnitsList {
      count
    }
  }
`;

export const DASHBOARD_LAST_MONTH_COMPANIES_QUERY = gql`
  query CompanyQuery($dateFilter: CompanyFilter) {
    companiesList(filter: $dateFilter) {
      items {
        id
      }
      count
    }
  }
`;
