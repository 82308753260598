import React from 'react';
import { useQuery } from 'react-apollo';
import { Modal, Avatar } from 'antd';
import { Offer } from '@/shared/types/graphql';
import { AsyncContent, Grid, WebsiteLink, Scrollable, PhoneLink } from '@/components';
import { stringifyNA, getInitials } from '@/shared/utils';
import { EntityOption, InformationSection } from '@/pro';
import { OFFER_CONTACT_QUERY } from './queries';

type DialogProps = {
  close: () => void;
  isVisible: boolean;
  offerId?: string;
};

export const Dialog: React.FC<DialogProps> = ({ isVisible, offerId, close }) => {
  const { data: offerResponse, loading: offerLoading } = useQuery<{ offer: Offer }>(OFFER_CONTACT_QUERY, {
    variables: { id: offerId },
  });

  const loading = offerLoading;
  const contact = offerResponse?.offer?.contact;

  return (
    <Modal
      title="To schedule a showing, please contact:"
      className="livo-modal"
      visible={isVisible}
      onCancel={close}
      width="100%"
      style={{ maxWidth: 600 }}
      centered
      footer={null}
    >
      <Scrollable padding="30px 60px">
        <AsyncContent loading={loading}>
          <Grid.Layout gap="20px" columns="1fr 1fr">
            <Avatar src={contact?.avatar?.downloadUrl || undefined} size={188}>
              {getInitials(contact?.firstName, contact?.lastName)}
            </Avatar>
            <Grid.Layout gap="20px">
              <InformationSection header="Information">
                <Grid.Layout gap="20px">
                  <EntityOption
                    label="Company Name"
                    value={stringifyNA(contact?.company?.name)}
                    type="uppercase-label"
                  />
                  <EntityOption
                    label="Company Website"
                    value={<WebsiteLink website={contact?.company?.website} />}
                    type="uppercase-label"
                  />
                  <EntityOption
                    label="Agent Name"
                    value={stringifyNA(`${contact?.firstName} ${contact?.lastName}`)}
                    type="uppercase-label"
                  />
                  <EntityOption label="Email" value={stringifyNA(contact?.email)} type="uppercase-label" />

                  <EntityOption label="Phone #1" value={<PhoneLink phone={contact?.phone} />} type="uppercase-label" />
                </Grid.Layout>
              </InformationSection>
            </Grid.Layout>
          </Grid.Layout>
        </AsyncContent>
      </Scrollable>
    </Modal>
  );
};
