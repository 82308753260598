import React, { useEffect } from 'react';
import { useAuth, gql } from '8base-react-sdk';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import queryString from 'query-string';

import { Loader } from '@/components';
import { HIDE_TOAST_ERROR_MESSAGE } from '@/shared/constants';
import { Invitation } from '@/shared/types/graphql';
import { popStoredVisitedOffers } from '@/shared/utils/visitedUnits';

const { REACT_APP_API_SUFFIX } = process.env as any;

export const SignUp = () => {
  const auth = useAuth();
  const location = useLocation();

  const { id, ...prefill } = queryString.parse(location.search);

  const { data, loading } = useQuery<{
    invitation: Invitation;
  }>(
    gql`
      query Invitation($id: ID) {
        invitation(id: $id) {
          id
        }
      }
    `,
    { variables: { id }, context: { [HIDE_TOAST_ERROR_MESSAGE]: true } },
  );

  useEffect(() => {
    if (!loading) {
      const offers = popStoredVisitedOffers();
      if (data?.invitation?.id) {
        auth.authClient.authorize({
          initial_screen: 'signUp',
          prefill,
          invitation_id: id,
          api_suffix: REACT_APP_API_SUFFIX,
          offers,
        });
      } else {
        auth.authClient.authorize({
          api_suffix: REACT_APP_API_SUFFIX,
          offers,
        });
      }
    }
  }, [auth.authClient, prefill, loading, id, data]);

  return <Loader stretch />;
};
