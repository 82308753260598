import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { Divider, Pagination, Input } from 'antd';

import { CustomCard, MediaGallery, PhoneLink, WebsiteLink } from '@/components';
import { InformationSection, EntityOption } from '@/pro';
import { Community, File } from '@/shared/types/graphql';
import { stringifyAddress } from '@/shared/utils';
import { useSearch } from '@/shared/hooks';

import { Container, Info, Details } from './styled';
import { SingleUnitsList } from './components';
import { COMMUNITY_CARD } from './queries';

const PAGE = 1;
const PAGE_SIZE = 10;

export const CommunityCard = () => {
  const [page, setPage] = React.useState(PAGE);
  const [size, setSize] = React.useState(PAGE_SIZE);
  const { communityId } = useParams();
  const [{ throttledSearch }, setSearch] = useSearch();

  const { loading, data } = useQuery<{ community: Community }>(COMMUNITY_CARD, {
    variables: {
      id: communityId,
      filter: {
        name: {
          contains: throttledSearch,
        },
      },
      first: size,
      skip: (page - 1) * size,
    },
    skip: !communityId,
  });

  const onSearch = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setSearch(value);
    },
    [setSearch],
  );

  const onChangePage = React.useCallback((page: number) => setPage(page), [setPage]);

  const onChangeSize = React.useCallback((current: number, size: number) => setSize(size), [setSize]);

  const community = data?.community;
  const name = community?.name;
  const id = community?.communityId;
  const communityMedia = community?.communityHasMedia?.items || [];
  const mediaList = React.useMemo(() => communityMedia.map(cm => cm.media?.file), [communityMedia]) as File[];
  const singleUnitsCount = community?.singleUnit?.count || 0;
  const address = stringifyAddress(community?.address);

  const units = community?.singleUnit?.items || [];

  const showPagination = singleUnitsCount > size;

  return (
    <CustomCard header={`${name} (${id})`}>
      <Container>
        <MediaGallery mediaList={mediaList} height={{ lg: '400px' }} withThumbs />
        <Info>
          <InformationSection header="Property Details">
            <Details>
              <EntityOption label="Units" type="uppercase-label" value={singleUnitsCount} />
              <EntityOption label="Address" type="uppercase-label" value={address} />
              <EntityOption label="Phone" type="uppercase-label" value={<PhoneLink phone={community?.phoneNumber} />} />
              <EntityOption
                label="Website"
                type="uppercase-label"
                value={<WebsiteLink website={community?.website} />}
              />
            </Details>
          </InformationSection>
          <Divider />
          <Input placeholder="Search Units by Name" onChange={onSearch} css={{ width: '100%', marginBottom: 24 }} />
          <SingleUnitsList communityId={communityId} loading={loading} units={units} />
          {showPagination && (
            <Pagination
              current={page}
              pageSize={size}
              total={singleUnitsCount}
              onChange={onChangePage}
              onShowSizeChange={onChangeSize}
              showSizeChanger
            />
          )}
        </Info>
      </Container>
    </CustomCard>
  );
};
