import React from 'react';
import { FileInput } from '8base-react-sdk';
import * as R from 'ramda';
import { Button } from 'antd';
import { css } from '@emotion/core';

import { FormField, LogoPreview } from '@/components';
import styled from '@/theme/styled';

export const LogoPreviewContainer = styled.div`
  margin-top: 16px;
`;

type LogoInputFieldProps = {
  label?: React.ReactNode;
  note?: string;
  input: any;
  buttonText: string;
  meta: any;
  isPublic?: boolean;
  accept?: string[];
  fromSources?: string[];
  previewProps?: Record<string, any>;
  'data-e2e-id'?: string;
};

export const LogoInputField = ({
  label,
  note,
  input,
  meta,
  buttonText,
  isPublic = true,
  accept = [],
  fromSources = ['local_file_system'],
  previewProps,
  ...rest
}: LogoInputFieldProps) => {
  const onChange = React.useCallback((data: any) => input.onChange(data), [input]);

  const onDelete = React.useCallback(() => input.onChange(undefined), [input]);

  return (
    <FormField label={label} note={note} meta={meta} data-e2e-id={rest['data-e2e-id']}>
      <>
        <input
          type="text"
          css={css`
            display: none;
          `}
          name={input.name}
          onChange={event => {
            onChange({ fileId: event.target.value });
          }}
        />
        <FileInput onChange={onChange} value={input.value} maxFiles={1} public={isPublic}>
          {({ pick, value }) => (
            <>
              <Button type="default" size="small" onClick={() => pick({ accept, fromSources })} block>
                {buttonText}
              </Button>
              {!R.isEmpty(value) && (
                <LogoPreviewContainer>
                  <LogoPreview value={input.value} handleDelete={onDelete} />
                </LogoPreviewContainer>
              )}
            </>
          )}
        </FileInput>
      </>
    </FormField>
  );
};
