import gql from 'graphql-tag';

export const PROPERTY_INFO_SINGLE_UNIT_QUERY = gql`
  query CreateOfferSingleUnitQuery($id: ID, $userFilter: UserFilter) {
    singleUnit(id: $id) {
      id
      name
      type
      bedrooms
      bathrooms
      squareFt
      description
      address {
        state
        street1
        street2
        zip
        country
        city
      }
      phone {
        code
        number
      }
      company {
        id
        user(filter: $userFilter) {
          items {
            id
            lastName
            firstName
            avatar {
              id
              downloadUrl
            }
            calendarLink
          }
        }
      }
      community {
        id
        name
        phoneNumber {
          code
          number
        }
        website
      }
      website
      singleUnitHasMedia(first: 3, sort: { order: ASC }) {
        items {
          id
          altName
          order
          media {
            id
            name
            file {
              id
              fileId
              filename
              downloadUrl
              meta
            }
          }
        }
      }
      activeOffer {
        id
        status
      }
    }
  }
`;
