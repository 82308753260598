import React from 'react';
import styled from '@/theme/styled';
import { css } from '@emotion/core';

import { DeletePreviewButton } from '@/components';
import { cropFileName, getImageTypeByMimetype } from '@/shared/utils';

const LogoPreviewContainer = styled.div`
  width: 140px;
`;

const LogoPreviewBody = styled.div`
  position: relative;
  height: 75px;
  padding: 10px;
  margin-bottom: 5px;
  border: 2px solid #f37a22;
  border-radius: 5px;
`;

const LogoPreviewImg = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const LogoPreviewName = styled.p`
  text-align: center;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.vars.primaryColor};
`;

type LogoPreviewProps = {
  value: Record<string, any>;
  handleDelete: () => void;
};

export const LogoPreview = ({ value, handleDelete }: LogoPreviewProps) => (
  <LogoPreviewContainer>
    <LogoPreviewBody>
      <LogoPreviewImg src={value.downloadUrl} />
      <DeletePreviewButton
        onClick={handleDelete}
        css={css`
          top: 10px;
          right: 10px;
        `}
      />
    </LogoPreviewBody>
    <a href={value.downloadUrl} target="_blank" rel="noopener noreferrer">
      <LogoPreviewName>
        {cropFileName({
          name: value.filename,
          type: getImageTypeByMimetype(value),
          length: 18,
        })}
      </LogoPreviewName>
    </a>
  </LogoPreviewContainer>
);
