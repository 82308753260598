import { styled } from '@/theme';
import { ColumnFlex } from '@/components';

export const CardWrapper = styled.div<{ disabled: boolean }>`
  position: relative;

  & > * {
    height: 100%;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    opacity: 0.8;

    display: ${props => (props.disabled ? 'block' : 'none')};
  }
`;

export const OfferHeaderTag = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr;
  grid-template-rows: minmax(120px, 1fr);
  background-color: #f4f7f9;

  border-bottom: 1px solid ${props => props.theme.vars.primaryBorderColor};

  @media (max-width: ${props => props.theme.vars.breakpoints.md.max}) {
    grid-template-columns: 100px 1fr;
    grid-template-rows: minmax(75px, 1fr);
  }
`;

export const OfferInfoColumnTag = styled(ColumnFlex)`
  padding: 16px;
  overflow: hidden;

  @media (max-width: 767px) {
    padding: 4px;
  }
`;

export const OfferImageWrapperTag = styled.div`
  position: relative;
`;

export const OffersCountPositionTag = styled.div`
  position: absolute;
  left: 8px;
  top: 8px;
  z-index: 1;
`;

export const OfferImageTag = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
