import { assocPath } from 'ramda';
import { ObjectSchema, ValidationError } from 'yup';

type ValidationObject = {
  [field: string]: string;
};
// eslint-disable-next-line @typescript-eslint/ban-types
export const validateDataByYup = async <T extends object | null | undefined = object>(
  yupSchema: ObjectSchema<T>,
  entityData: T,
) => {
  return await yupSchema
    .validate(entityData, {
      abortEarly: false,
    })
    .then(() => [])
    .catch(({ inner }) => {
      return inner.reduce((accum: ValidationObject, error: ValidationError) => {
        return assocPath(error.path.split('.'), error.errors[0], accum);
      }, {});
    });
};
