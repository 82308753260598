import React from 'react';
import { Select } from 'antd';

import { FormField } from '@/components';
import { InputType, MetaType } from '@/shared/types';

const { Option, OptGroup } = Select;

export type OptionItem = {
  label: string;
  value: string | number;
  disabled?: boolean;
};

export type GroupOptionItem = {
  groupName: string;
  options: Array<OptionItem>;
};

type SelectFieldProps = {
  label?: React.ReactNode;
  note?: string;
  options?: Array<OptionItem>;
  groupedOptions?: Array<GroupOptionItem>;
  placeholder?: string;
  stretch?: boolean;
  showSearch?: boolean;
  width?: string | number;
  loading?: boolean;
  mode?: 'default' | 'multiple' | 'tags';
  input: InputType;
  meta?: MetaType;
  disabled?: boolean;
  allowClear?: boolean;
  'data-e2e-id'?: string;
  showDropdown?: boolean;
};

const renderOptions = (options: Array<OptionItem>, testId?: string) => {
  return options?.map(({ label, value, disabled = false }) => (
    <Option key={value} value={value} data-e2e-id={`${testId}[${label}]`} disabled={disabled}>
      {label}
    </Option>
  ));
};

export const SelectField = ({
  options,
  groupedOptions,
  placeholder,
  label,
  note,
  stretch,
  showSearch,
  width,
  loading,
  mode,
  input,
  meta,
  disabled,
  allowClear,
  showDropdown = true,
  ...rest
}: SelectFieldProps) => {
  const { value, onChange } = input;

  const selectWidth = stretch ? '100%' : width ? width : 150;

  return (
    <FormField label={label} note={note} meta={meta} {...rest}>
      <Select
        onChange={onChange}
        showSearch={showSearch}
        placeholder={placeholder}
        value={value || undefined}
        loading={loading}
        mode={mode}
        dropdownClassName={!showDropdown ? 'ant-select-dropdown-hidden' : undefined}
        style={{ width: selectWidth }}
        disabled={disabled}
        allowClear={allowClear}
      >
        {options && renderOptions(options, rest['data-e2e-id'])}
        {groupedOptions &&
          groupedOptions.map((groupedOption, index) => {
            return (
              <OptGroup key={`${index}${groupedOption.groupName}`} label={groupedOption.groupName}>
                {renderOptions(groupedOption.options, rest['data-e2e-id'])}
              </OptGroup>
            );
          })}
      </Select>
    </FormField>
  );
};
