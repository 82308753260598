export const addAmenities = (data: any, type: any) => {
  const newData = { ...data };
  if (newData?.amenities) {
    const amenitiesList = newData?.amenities;
    const amenityArray: any = amenitiesList.reduce((acc: any, curr: any) => {
      return { ...acc, [curr?.groupKey]: curr?.amenity };
    }, {});

    if (type === 'community') {
      newData.communityAmenity = amenityArray;
      return { ...newData, communityAmenity: amenityArray };
    } else {
      newData.singleUnitAmenity = amenityArray;
      return { ...newData, singleUnitAmenity: amenityArray };
    }
  } else return data;
};
