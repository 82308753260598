import React from 'react';
import { Modal } from 'antd';
import { useQuery } from 'react-apollo';
import { styled } from '@/theme';

import { AsyncContent, Grid, Scrollable } from '@/components';
import { RenterInformation, BidsList } from '@/pro';
import { getFullName } from '@/shared/utils';
import { Renter } from '@/shared/types/graphql';

import { RENTER_DETAILS_WITH_BIDS_LIST_MODAL_RENTER_QUERY } from './queries';

const Container = styled.div`
  position: relative;
`;

const RenterInformationPostion = styled.div`
  position: sticky;
  top: 0;
`;

type DialogProps = {
  close: () => void;
  isVisible: boolean;
  renterId?: string;
};

export const Dialog: React.FC<DialogProps> = ({ isVisible, close, renterId }) => {
  const { data: renterData, loading: renterLoading } = useQuery<{ renter: Renter }>(
    RENTER_DETAILS_WITH_BIDS_LIST_MODAL_RENTER_QUERY,
    {
      skip: !renterId,
      variables: {
        id: renterId,
      },
    },
  );

  const firstName = renterData?.renter?.user?.firstName;
  const lastName = renterData?.renter?.user?.lastName;
  const fullName = getFullName(firstName, lastName);

  return (
    <Modal
      title={fullName}
      className="livo-modal"
      visible={isVisible}
      onCancel={close}
      width="100%"
      style={{ maxWidth: 1110 }}
      centered
      footer={null}
    >
      <AsyncContent loading={renterLoading} height="367px">
        <Scrollable padding="24px 54px" height="470px">
          <Container>
            <Grid.Layout gap="58px" columns="550px 412px" justifyContent="start">
              <div>
                <RenterInformationPostion>
                  <RenterInformation renter={renterData?.renter} />
                </RenterInformationPostion>
              </div>
              <BidsList renterId={renterId} />
            </Grid.Layout>
          </Container>
        </Scrollable>
      </AsyncContent>
    </Modal>
  );
};
