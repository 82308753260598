import { Maybe } from '@/shared/types/graphql';
import { NOT_AVALIABLE } from '@/shared/constants';

export const stringifyIlsOrPms = (ils?: Maybe<Array<string>>) => {
  if (!ils) {
    return NOT_AVALIABLE;
  }

  const value = ils.map(val => val).join(', ');

  if (!value) {
    return NOT_AVALIABLE;
  }

  return value;
};
