import React from 'react';
import styled from '@emotion/styled';
import { Button } from 'antd';

// TODO: maybe we need these styles on a global level?
const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  width: 100%;

  .ant-btn {
    padding: 0 32px;
    letter-spacing: 0;
    border-radius: 5px;
    font-weight: 500;
    span {
      vertical-align: middle;
    }
  }
`;

export const AccountFooter = ({
  resetForm,
  isPristine,
  loading,
}: {
  resetForm: () => void;
  isPristine: boolean;
  loading: boolean;
}) => {
  return (
    <Container>
      <Button size="large" disabled={isPristine || loading} onClick={resetForm}>
        Cancel
      </Button>
      <Button
        size="large"
        loading={loading}
        disabled={isPristine}
        style={{ marginLeft: 12 }}
        type="primary"
        htmlType="submit"
      >
        Save Changes
      </Button>
    </Container>
  );
};
