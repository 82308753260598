import React from 'react';
import { Modal } from 'antd';
import { useQuery } from 'react-apollo';
import { Field, Form } from '8base-react-sdk';

import { EnvironmentVariable, EnvironmentVariableUpdateInput, Maybe } from '@/shared/types/graphql';
import { ModalFooter, AsyncContent, InputField, InputNumberField } from '@/components';
import { dialogsContext } from '@/providers';
import { RANKING_LABELS, TOAST_SUCCESS_MESSAGE } from '@/shared/constants';
import { RANKING_UPDATE_MODAL_MUTATION, RANKING_UPDATE_MODAL_QUERY } from './queries';
import { styled } from '@/theme';
import { omit } from 'ramda';
import { validateEntityDataByDraft } from '@/shared/utils';
import { RankingSchemaValidation } from '@/schemaValidators';
import { useMutationSafe } from '@/shared/hooks';
import { RANKING_CONFIRM_DIALOG } from '../RankingConfirmDialog';

type DialogProps = {
  close: () => void;
  isVisible: boolean;
  constantId?: Maybe<string>;
  refetchQueries?: Array<string>;
};

export const FormContainer = styled.form`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-column-gap: 11px;
  grid-row-gap: 16px;
`;

export const FullRowBlock = styled.div`
  grid-column: 1 / -1;
`;

export const Dialog: React.FC<DialogProps> = ({ isVisible, close, constantId, refetchQueries = [] }) => {
  const { openDialog } = React.useContext(dialogsContext);

  const [updateRankingConstant, { loading: updateRankingConstantLoading }] = useMutationSafe(
    RANKING_UPDATE_MODAL_MUTATION,
    {
      refetchQueries: ['RankingConstants', ...refetchQueries],
      awaitRefetchQueries: true,
      context: {
        [TOAST_SUCCESS_MESSAGE]: 'Ranking Constant Successfully Updated',
      },
      onCompleted: close,
    },
  );

  const { data: rankingResponse, loading: rankingLoading } = useQuery<{
    environmentVariable: EnvironmentVariable;
  }>(RANKING_UPDATE_MODAL_QUERY, {
    variables: {
      id: constantId,
    },
  });
  const initialValues = rankingResponse?.environmentVariable;

  const onSubmit = async (data: EnvironmentVariableUpdateInput) => {
    const value = String(data?.value);
    const rawData = { ...data, value };
    const formattedData = omit(['__typename'], rawData);
    await openDialog({
      name: RANKING_CONFIRM_DIALOG,
      props: {
        name: data?.name,
        confirmAction: async () =>
          await updateRankingConstant({
            variables: {
              filter: {
                id: data?.id,
              },
              data: formattedData,
            },
          }),
      },
    });
  };

  return (
    <Form
      type="UPDATE"
      tableSchemaName="EnvironmentVariable"
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={values => validateEntityDataByDraft(RankingSchemaValidation, values)}
    >
      {({ handleSubmit, pristine }) => (
        <Modal
          title="Update Constant"
          visible={isVisible}
          onCancel={close}
          width="100%"
          style={{ maxWidth: 500 }}
          footer={
            <ModalFooter
              cancelProps={{
                onClick: close,
              }}
              okProps={{
                disabled: !constantId || updateRankingConstantLoading || pristine,
                loading: updateRankingConstantLoading,
                onClick: event => handleSubmit(event),
                type: 'primary',
                text: 'Update',
              }}
            />
          }
          centered
        >
          <AsyncContent loading={rankingLoading}>
            <FormContainer>
              <Field
                name="name"
                format={value => value && RANKING_LABELS[value]}
                stretch
                disabled
                component={InputField}
              />
              <Field name="value" min={0} max={100} stretch component={InputNumberField} />
            </FormContainer>
          </AsyncContent>
        </Modal>
      )}
    </Form>
  );
};
