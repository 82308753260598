import React, { useState } from 'react';
import {
  TimelineItemCircle,
  TimelineItemCirclesWrapper,
  TimelineItemSubTitle,
  TimelineItemTitle,
  TimelineWrapper,
  TimelineContent,
  TimelineItemCircleLine,
  TimelineItemCircleLineText,
  TimelineItemLine,
  TimelineContentLine,
  Size,
} from './styled';
import { isNil } from 'ramda';

type TimeLineItem = {
  id: string | number;
  title?: string;
  subTitle?: string;
  beforeText?: string;
  content?: React.ReactNode;
};

type ActiveTimelineState = {
  active?: string | number;
  defaultActive?: string | number;
  onActiveClick?: (activeId: string | number) => void;
};

type TimeLineProps = ActiveTimelineState & {
  items: TimeLineItem[];
  size?: Size;
};

const useActiveItemState = (props: ActiveTimelineState): [string | number, (id: string | number) => void] => {
  const [active, setActive] = useState<string | number>(props.defaultActive || '');

  if (!isNil(props.active)) {
    return [
      props.active,
      props.onActiveClick ||
        (() => {
          return;
        }),
    ];
  } else {
    return [active, setActive];
  }
};

export const TimeLine: React.FC<TimeLineProps> = ({ size = 'md', items, ...rest }) => {
  const [activeId, setActive] = useActiveItemState(rest);

  return (
    <TimelineWrapper size={size}>
      <TimelineItemCirclesWrapper>
        {items.map((item, index) => (
          <TimelineItemCircle
            size={size}
            active={item.id === activeId}
            key={item?.title || ''}
            onClick={() => {
              setActive(item.id);
            }}
          >
            {!!item.title && <TimelineItemTitle size={size}>{item.title}</TimelineItemTitle>}
            {!!item.subTitle && <TimelineItemSubTitle size={size}>{item.subTitle}</TimelineItemSubTitle>}
            {index !== items.length - 1 && <TimelineItemCircleLine size={size} />}
            {!!item.beforeText && (
              <TimelineItemCircleLineText size={size}>{item.beforeText}</TimelineItemCircleLineText>
            )}
            {activeId === item.id && <TimelineItemLine index={index} size={size} />}
          </TimelineItemCircle>
        ))}
      </TimelineItemCirclesWrapper>
      <TimelineContent size={size}>
        {items.filter(item => item.id === activeId)[0]?.content}
        <TimelineContentLine size={size} />
      </TimelineContent>
    </TimelineWrapper>
  );
};
