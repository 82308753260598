import React from 'react';
import { useQuery } from 'react-apollo';
import { useParams } from 'react-router-dom';
import { Divider, Button } from 'antd';

import { PropertyDetails, OfferDetails, PropertyAmenities } from '@/pro';
import { CustomCard, Loader, MediaGallery, Grid, Heading, CreateOfferSwitch } from '@/components';
import { dialogsContext } from '@/providers';
import { NOT_AVALIABLE, OFFER_STATUSES } from '@/shared/constants';
import { SingleUnit as SingleUnitType, Offer, File } from '@/shared/types/graphql';
import { useCurrentCompany, useDictionary } from '@/shared/hooks';

import { Container, Info, Footer, SwitchLabel } from './styled';
import { SINGLE_UNIT_QUERY, OFFERS_SINGLE_UNIT_QUERY } from '../queries';
import { SINGLE_UNIT_UPDATE_DIALOG } from '@/dialogs';

type DetailsProps = {
  unit?: SingleUnitType | null;
  offer?: Offer | null;
  loading: boolean;
};

export const SingleUnitDetails = ({ queryOfferFirstId }: { queryOfferFirstId?: string | null }) => {
  if (queryOfferFirstId) {
    return <OfferFirst id={queryOfferFirstId} />;
  } else {
    return <SingleUnitFirst />;
  }
};

function OfferFirst({ id }: { id: string }) {
  const { data, loading } = useQuery<{ offer: Offer }>(OFFERS_SINGLE_UNIT_QUERY, {
    variables: { id },
    skip: !id,
    fetchPolicy: 'no-cache',
  });

  return <Details unit={data?.offer?.historyOfferSingleUnit} offer={data?.offer} loading={loading} />;
}

function SingleUnitFirst() {
  const { singleUnitId } = useParams<{ singleUnitId: string }>();

  const { data, loading } = useQuery<{ singleUnit: SingleUnitType }>(SINGLE_UNIT_QUERY, {
    variables: { id: singleUnitId },
    skip: !singleUnitId,
    fetchPolicy: 'no-cache',
  });
  return <Details unit={data?.singleUnit} offer={data?.singleUnit?.activeOffer} loading={loading} />;
}

function Details({ unit: singleUnit, offer, loading }: DetailsProps) {
  const { openDialog } = React.useContext(dialogsContext);

  const { singleUnit: singleUnitName } = useDictionary();

  const bidsCount = offer?.bid?.count || 0;
  const noBids = bidsCount === 0;

  const { company } = useCurrentCompany();
  const companyType = company?.type;

  const singleUnitId = singleUnit?.id;

  const handleOpenUpdateSingleUnit = React.useCallback(
    () =>
      openDialog({
        name: SINGLE_UNIT_UPDATE_DIALOG,
        props: {
          singleUnitId,
          companyType,
          refetchQueries: ['NavigationSingleUnitsList', 'NavigationCommunitiesList'],
        },
      }),
    [companyType, openDialog, singleUnitId],
  );

  const name = singleUnit?.name || NOT_AVALIABLE;
  const id = singleUnit?.singleUnitId || NOT_AVALIABLE;
  const singleUnitHasMedia = singleUnit?.singleUnitHasMedia?.items || [];
  const singleUnitMediaList = React.useMemo(() => singleUnitHasMedia.map(sum => sum?.media?.file), [
    singleUnitHasMedia,
  ]) as File[];

  const communityHasMedia = singleUnit?.community?.communityHasMedia?.items || [];
  const communityMediaList = React.useMemo(() => communityHasMedia.map(cm => cm?.media?.file), [
    communityHasMedia,
  ]) as File[];

  const mediaList = [...singleUnitMediaList, ...communityMediaList];

  const virtualTour = singleUnit?.virtualTour;

  const hideOfferDetails =
    !offer?.id || offer?.status === OFFER_STATUSES.leased || offer?.status === OFFER_STATUSES.canceled;

  if (loading) {
    return (
      <CustomCard>
        <Loader stretch />
      </CustomCard>
    );
  }

  return (
    <CustomCard
      header={
        <Grid.Layout gap="24px" columns="minmax(auto, 560px) 1fr" alignItems="center" stretch>
          <Grid.Box>
            <Heading type="h1">{`${name} (${id})`}</Heading>
          </Grid.Box>
          <Grid.Box alignItems="flex-start">
            <Grid.Layout columns="auto auto" gap="5px">
              <Grid.Box>
                <SwitchLabel>Enable Livo Transaction</SwitchLabel>
              </Grid.Box>
              <Grid.Box>
                <CreateOfferSwitch
                  singleUnit={singleUnit as SingleUnitType}
                  refetchQueries={['SingleUnit', 'OffersSingleUnitQuery']}
                />
              </Grid.Box>
            </Grid.Layout>
          </Grid.Box>
        </Grid.Layout>
      }
      footer={
        <Footer>
          <Button type="primary" size="large" onClick={handleOpenUpdateSingleUnit} disabled={!noBids}>
            {`Edit ${singleUnitName.single} Details`}
          </Button>
        </Footer>
      }
    >
      <Container>
        <MediaGallery
          mediaList={mediaList}
          height={{ lg: '400px' }}
          virtualTour={virtualTour}
          offerId={offer?.id as string}
          withThumbs
        />
        <Info>
          <PropertyDetails singleUnit={singleUnit} />
          {!hideOfferDetails && (
            <>
              <Divider />
              <OfferDetails offer={offer} />
            </>
          )}
          <Divider />
          <PropertyAmenities singleUnit={singleUnit as SingleUnitType} />
        </Info>
      </Container>
    </CustomCard>
  );
}
