import React from 'react';
import { OFFER_COMBINED_STATUSES } from '@/shared/constants';
import { Offer } from '@/shared/types/graphql';
import { useOfferBidCombinedStatus } from '@/shared/hooks';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { getSingleRentalBiddingUrl } from '@/shared/constants/appRoutes';

interface ButtonCardProps {
  offer: Offer;
}

export const ButtonCard: React.FC<ButtonCardProps> = ({ offer }) => {
  const offerBidCombinedStatus = useOfferBidCombinedStatus(offer);

  const buttonText = React.useMemo(() => {
    switch (offerBidCombinedStatus.kind) {
      case OFFER_COMBINED_STATUSES['you-accept']:
        return 'Next Steps';
      case OFFER_COMBINED_STATUSES['you-won']:
      case OFFER_COMBINED_STATUSES['you-secured']:
        return 'View checklist';
      default:
        return 'View offer details';
    }
  }, [offerBidCombinedStatus]);

  return (
    <Link to={getSingleRentalBiddingUrl(offer?.id)}>
      <Button type="primary" size="large" data-e2e-id="offerCard.buttonAction">
        {buttonText}
      </Button>
    </Link>
  );
};
