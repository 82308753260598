import gql from 'graphql-tag';

export const DASHBOARD_COMPANY_QUERY = gql`
  query CompanyQuery(
    $communityFilter: CommunityFilter
    $bidsFilter: BidFilter
    $offersFilter: OfferFilter
    $singleUnitsFilter: SingleUnitFilter
    $first: Int
  ) {
    communitiesList(filter: $communityFilter, first: $first) {
      items {
        id
        name
        status
        numberOfUnits
        singleUnit {
          count
        }
        phoneNumber {
          code
          number
        }
        address {
          country
          street1
          street2
          zip
          city
          state
        }
      }
      count
    }
    bidsList(filter: $bidsFilter) {
      items {
        id
        rentAmount
        offer {
          id
          rentAmount
        }
      }
      count
    }
    offersList(filter: $offersFilter) {
      items {
        id
        secureNow
        startsIn
        endsIn
        activeOfferSingleUnit {
          id
          name
          status
          community {
            id
            name
          }
        }
      }
    }
    singleUnitsList(filter: $singleUnitsFilter, first: $first) {
      items {
        id
        name
        type
        address {
          country
          street1
          street2
          zip
          city
          state
        }
        phone {
          code
          number
        }
        website
      }
      count
    }
  }
`;
