import React from 'react';
import { NOT_AVALIABLE, SINGLE_UNIT_STATUS_LABELS } from '@/shared/constants';
import { Tag } from '@/components';

const COLOR_BY_TYPE: Record<keyof typeof SINGLE_UNIT_STATUS_LABELS, string> = {
  draft: '',
  active: 'green',
};

type SingleUnitStatusTagProps = {
  status?: string;
};

export const SingleUnitStatusTag: React.FC<SingleUnitStatusTagProps> = ({ status }) => {
  if (!status || !(SINGLE_UNIT_STATUS_LABELS as any)[status]) {
    return <>{NOT_AVALIABLE}</>;
  }

  return <Tag color={(COLOR_BY_TYPE as any)[status]}>{(SINGLE_UNIT_STATUS_LABELS as any)[status]}</Tag>;
};
