import { DRAFT_STATUS } from './common';

export type SingleUnitTypesType = keyof typeof SINGLE_UNIT_TYPES;
export type SingleUnitMduTypesType = keyof typeof SINGLE_UNIT_MDU_TYPES;

export const SINGLE_UNIT_STATUS_LABELS = {
  draft: 'Draft',
  active: 'Active',
};

export const SINGLE_UNIT_STATUSES: Record<string, keyof typeof SINGLE_UNIT_STATUS_LABELS> = {
  draft: DRAFT_STATUS,
  active: 'active',
};

export const SINGLE_UNIT_TYPES = {
  singleFamilyHome: 'singleFamilyHome',
  condominium: 'condominium',
  townhouse: 'townhouse',
  penthouse: 'penthouse',
  cooperative: 'cooperative',
  apartment: 'apartment',
  studio: 'studio',
  duplex: 'duplex',
  commercial: 'commercial',
};

export const SINGLE_UNIT_TYPE_LABELS: Record<SingleUnitTypesType, string> = {
  singleFamilyHome: 'Single Family Home',
  condominium: 'Condominium',
  townhouse: 'Townhouse',
  penthouse: 'Penthouse',
  cooperative: 'Cooperative',
  apartment: 'Apartment',
  studio: 'Studio',
  duplex: 'Duplex',
  commercial: 'Commercial',
};

export const SINGLE_UNIT_MDU_TYPES = {
  condominium: 'condominium',
  penthouse: 'penthouse',
  apartment: 'apartment',
  studio: 'studio',
};

export const SINGLE_UNIT_MDU_TYPE_LABELS: Record<SingleUnitMduTypesType, string> = {
  condominium: 'Condominium',
  penthouse: 'Penthouse',
  apartment: 'Apartment',
  studio: 'Studio',
};
