import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { Company } from '@/shared/types/graphql';
import { COMPANY_QUERY } from '@/routes/rentals/PageCompanyLiveOffers/queries';
import { Grid, Heading } from '@/components';
import { Select } from 'antd';
import { SORT_OPTIONS } from '@/routes/rentals/PageCompanyLiveOffers/constants';
import { SetQueryParamsHandler } from '@/shared/hooks';
import { ParsedQuery } from 'query-string';

type CustomCardHeaderProps = {
  setQueryParams: SetQueryParamsHandler;
  queryParams: ParsedQuery;
};

const CustomCardHeader: React.FC<CustomCardHeaderProps> = ({ setQueryParams, queryParams }) => {
  const { id: companyId } = useParams<{ id: string }>();

  const { data: companyData, loading } = useQuery<{ company: Company }>(COMPANY_QUERY, {
    skip: !companyId,
    variables: {
      id: companyId,
    },
  });

  const companyName = companyData?.company.name || '';

  const onChangeSortBy = React.useCallback(
    (value: any) => {
      setQueryParams({ ...queryParams, ...JSON.parse(value) });
    },
    [queryParams, setQueryParams],
  );

  const sortValue = JSON.stringify({
    sortBy: queryParams?.sortBy,
    sortOrder: queryParams?.sortOrder,
  });

  return (
    <Grid.Layout gap="none" columns="50% 50%" alignItems="center" justifyItems="space-between" stretch>
      <Heading type="h1">{companyName} Available Rentals</Heading>
      <Grid.Box justifySelf="flex-end">
        <Grid.Layout columns="200px">
          <Select onChange={onChangeSortBy} value={sortValue} loading={loading} placeholder="Sort by">
            {SORT_OPTIONS.map(option => (
              <Select.Option key={JSON.stringify(option.value)} value={JSON.stringify(option.value)}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </Grid.Layout>
      </Grid.Box>
    </Grid.Layout>
  );
};

export default CustomCardHeader;
