import React from 'react';
import { RouteProps, RouteComponentProps } from 'react-router-dom';

type RenderContentProps = RouteProps & {
  props: RouteComponentProps;
};

export const renderContent = ({ component: Component, render, children, props }: RenderContentProps) => {
  if (children) {
    return children;
  }

  if (Component) {
    return <Component {...props} />;
  }

  if (typeof render === 'function') {
    return render(props);
  }

  return null;
};
