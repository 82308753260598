import React from 'react';
import { Field } from '@8base-react/forms';
import { Radio } from 'antd';

import { InputField, InputNumberField, SelectField, Grid, RadioGroupField, AddressFields } from '@/components';
import { MDU_TYPES, US_STATES_LIST, BILLING_TYPES, COMPANY_STATUSES } from '@/shared/constants';
import { COMPANY_TYPE_LABELS } from 'livo-shared';
import { normalizePhone } from '@/shared/utils';
import { useCheckUserEmail } from '@/shared/hooks';

const existsAndEqualsTo = ({ etalon, item }: any) => item && etalon === item;

export const DemographicInformation = ({ values, change, initialValues }: any) => {
  const coercedCompanyTypes = React.useMemo(
    () => Object.entries(COMPANY_TYPE_LABELS).map(([value, label]) => ({ value, label })),
    [],
  );
  const coercedMduTypes = React.useMemo(
    () => Object.entries(MDU_TYPES).map(([value, label]) => ({ value, label })),
    [],
  );
  const coercedStatesOfUSA = React.useMemo(
    () => Object.entries(US_STATES_LIST).map(([value, label]) => ({ value, label: `${value} - ${label}` })),
    [],
  );

  React.useEffect(() => {
    if (values.mdu && values.type !== 'mdu') {
      change('mdu', undefined);
    }
  }, [values.type, change, values.mdu]);

  const { validateCheckEmail } = useCheckUserEmail();

  return (
    <Grid.Layout gap="16px" css={{ marginBottom: 16 }}>
      <Grid.Box>
        <Field
          label="Name / Company Name*"
          name="name"
          autoComplete="disabled"
          placeholder="e.g. GreyStar, Edgar Monroe, etc."
          component={InputField}
        />
      </Grid.Box>
      <Grid.Box>
        <Field
          label="Primary Phone Number*"
          name="primaryPhoneNumber.number"
          autoComplete="disabled"
          placeholder="(415) 842-2731"
          mask="(999) 999-9999"
          note="U.S. phone number required."
          width={13}
          component={InputField}
          addonBefore={`+${values?.primaryPhoneNumber?.code}`}
          parse={normalizePhone}
        />
      </Grid.Box>
      {/* TODO: additional phone numbers */}
      <Grid.Box>
        <Field
          label="Company Contact Name*"
          name="contactName"
          autoComplete="disabled"
          placeholder="John Doe"
          component={InputField}
        />
      </Grid.Box>
      <Grid.Box>
        <Field
          label="Contact Mobile Number*"
          name="contactPhoneNumber.number"
          autoComplete="disabled"
          placeholder="(415) 323-9320"
          mask="(999) 999-9999"
          width={13}
          component={InputField}
          parse={normalizePhone}
          addonBefore={`+${values?.contactPhoneNumber?.code}`}
        />
      </Grid.Box>
      <Grid.Box>
        <Field
          validate={(email?: string) => {
            if (values?.status === COMPANY_STATUSES.draft) {
              return validateCheckEmail(email as string);
            }
          }}
          name="contactEmail"
          label="Contact Email*"
          autoComplete="disabled"
          component={InputField}
        />
      </Grid.Box>
      <Grid.Box>
        <Field
          label="Market*"
          name="type"
          placeholder="Select Type"
          options={coercedCompanyTypes}
          component={SelectField}
          stretch
          showSearch
          disabled={initialValues.status !== COMPANY_STATUSES.draft}
        />
      </Grid.Box>
      {existsAndEqualsTo({ etalon: values.type, item: 'mdu' }) && (
        <Grid.Box>
          <Field
            label="Multi-Family Type*"
            name="mdu.type"
            placeholder="Select Multi-Family Type"
            options={coercedMduTypes}
            component={SelectField}
            stretch
            showSearch
            disabled={initialValues.status !== COMPANY_STATUSES.draft}
          />
        </Grid.Box>
      )}

      <Grid.Box>
        <AddressFields
          country="primaryAddress.country"
          street1="primaryAddress.street1"
          street2="primaryAddress.street2"
          zip="primaryAddress.zip"
          city="primaryAddress.city"
          state="primaryAddress.state"
          stateOptions={coercedStatesOfUSA}
          addressNote="At this time Livo.io only supports U.S. based companies."
        />
      </Grid.Box>
      {/* TODO: additional addresses */}
      <Grid.Box>
        <Field
          label="Website"
          name="website"
          placeholder="e.g. https://www.livo.io, http://livo.com, etc."
          component={InputField}
        />
      </Grid.Box>
      {existsAndEqualsTo({ etalon: values.type, item: 'mdu' }) && (
        <Grid.Box>
          <Field
            label="Number of Properties Managed*"
            name="mdu.numberOfPropertiesManaged"
            component={InputNumberField}
            placeholder="e.g. 250"
            stretch
          />
        </Grid.Box>
      )}
      <Grid.Box>
        <Field
          label="Number of Rental Units or Doors Managed*"
          name="companySize"
          placeholder="e.g. 250"
          component={InputNumberField}
          stretch
        />
      </Grid.Box>
      {existsAndEqualsTo({ etalon: values.type, item: 'mdu' }) && (
        <Grid.Box>
          <Field label="Billing*" name="mdu.billing" component={RadioGroupField}>
            <Radio value={BILLING_TYPES.corporate.value}>{BILLING_TYPES.corporate.label}</Radio>
            <Radio value={BILLING_TYPES.community.value}>{BILLING_TYPES.community.label}</Radio>
          </Field>
        </Grid.Box>
      )}
    </Grid.Layout>
  );
};
