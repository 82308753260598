import gql from 'graphql-tag';

export const RENTER_DETAILS_MODAL_RENTER_QUERY = gql`
  query RenterDetailsModalRenterQuery($id: ID!) {
    renter(id: $id) {
      id
      address {
        street1
        street2
        zip
        city
        state
      }
      phone {
        code
        number
      }
      westernReportingScreenings {
        items {
          id
          screeningID
          status
          reportURL
          createdAt
        }
      }
      user {
        id
        firstName
        lastName
        email
        avatar {
          id
          downloadUrl
        }
      }
    }
  }
`;
