import React from 'react';
import pluralize from 'pluralize';
import { DateTime } from 'luxon';
import { useParams, Redirect } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { Divider, Tag } from 'antd';
import DOMPurify from 'dompurify';

import { File, Offer } from '@/shared/types/graphql';
import {
  CustomCard,
  Loader,
  MediaGallery,
  ResumeNumbers,
  ColumnFlex,
  Responsive,
  PhoneLink,
  Link,
  FlexLayout,
} from '@/components';
import {
  Container,
  PropertyName,
  PropertyType,
  PropertyBackButton,
  AppartmentProperties,
  AppartmentOptions,
  OfferPropertyInfo,
  OfferAuctionContainer,
} from './styled';

import { EntityOption } from '@/pro/EntityOption';
import { PropertyOfferTimeline } from './components';

import { useOfferBidCombinedStatus } from '@/shared/hooks';
import { stringifyAddress, stringifySingleUnitType, stringifyNA } from '@/shared/utils';
import { BID_STATUSES, OFFER_COMBINED_STATUSES } from '@/shared/constants';
import { OFFER_SINGLE_UNIT_QUERY } from './queries';
import { getSingleRentalUrl } from '@/shared/constants/appRoutes';
import { DOMPURIFY_CONFIG } from 'livo-shared';
import { PropertyDescription } from '@/components';

export const PagePropertyBidding: React.FC = () => {
  const { id: offerId } = useParams<{ id: string }>();

  const { data: offerResponse, loading: offerLoading } = useQuery<{ offer: Offer }>(OFFER_SINGLE_UNIT_QUERY, {
    variables: { id: offerId },
    skip: !offerId,
    fetchPolicy: 'network-only',
  });

  const offer = offerResponse?.offer;
  const singleUnit = offer?.historyOfferSingleUnit;
  const description = singleUnit?.description;

  const sanitizedDescriptionHtml = React.useMemo(() => DOMPurify.sanitize(description as string, DOMPURIFY_CONFIG), [
    description,
  ]);

  const virtualTour = singleUnit?.virtualTour;
  const calendarLink = offer?.calendarLink;

  const singleUnitHasMedia = singleUnit?.singleUnitHasMedia?.items || [];
  const singleUnitMediaList = React.useMemo(() => singleUnitHasMedia.map(sum => sum?.media?.file), [
    singleUnitHasMedia,
  ]) as File[];

  const communityHasMedia = singleUnit?.community?.communityHasMedia?.items || [];
  const communityMediaList = React.useMemo(() => communityHasMedia.map(cm => cm?.media?.file), [
    communityHasMedia,
  ]) as File[];

  const mediaList = [...singleUnitMediaList, ...communityMediaList];
  const bids = offer?.bid?.items || [];
  const bidsCount = bids.filter(bid => bid?.status !== BID_STATUSES.rejected).length;

  const offerBidCombinedStatus = useOfferBidCombinedStatus(offer);

  // eslint-disable-next-line
  const isOfferEnded = DateTime.fromISO(offer?.endsIn).diffNow().milliseconds < 0;
  const isShouldRedirect =
    offerBidCombinedStatus.kind === OFFER_COMBINED_STATUSES.canceled ||
    offerBidCombinedStatus.kind === OFFER_COMBINED_STATUSES.rejected ||
    offerBidCombinedStatus.kind === OFFER_COMBINED_STATUSES.leased ||
    offerBidCombinedStatus.kind === OFFER_COMBINED_STATUSES.draft ||
    offerBidCombinedStatus.kind === OFFER_COMBINED_STATUSES.none;

  if (offerLoading) {
    return <Loader stretch />;
  }

  if (isShouldRedirect) {
    return <Redirect to={getSingleRentalUrl(offerId)} />;
  }

  return (
    <CustomCard padding="none">
      <Container>
        <div>
          <MediaGallery
            mediaList={mediaList}
            height={{ lg: '400px' }}
            virtualTour={virtualTour}
            calendarLink={calendarLink}
            offerId={offerId}
          />
          <OfferPropertyInfo>
            <ColumnFlex gap="md" css={{ marginTop: 20 }}>
              <AppartmentProperties>
                <FlexLayout>
                  <PropertyType>{stringifySingleUnitType(singleUnit?.type)}</PropertyType>
                  <ResumeNumbers
                    beds={singleUnit?.bedrooms}
                    baths={singleUnit?.bathrooms}
                    squareFt={singleUnit?.squareFt}
                  />
                </FlexLayout>
                <Tag color="green">
                  {bidsCount} {pluralize('offer', bidsCount)}
                </Tag>
              </AppartmentProperties>
              <PropertyName>{singleUnit?.name}</PropertyName>
              <Responsive.HideOn breakpoints={['xs', 'sm']}>
                <PropertyDescription>
                  <div dangerouslySetInnerHTML={{ __html: stringifyNA(sanitizedDescriptionHtml) }} />
                </PropertyDescription>
              </Responsive.HideOn>
              <AppartmentOptions>
                <EntityOption type="uppercase-label" label="Address" value={stringifyAddress(singleUnit?.address)} />
                <EntityOption
                  type="uppercase-label"
                  label="Phone Number"
                  value={<PhoneLink phone={singleUnit?.phone} />}
                />
              </AppartmentOptions>
            </ColumnFlex>
          </OfferPropertyInfo>
        </div>
        <OfferAuctionContainer>
          <Responsive.HideOn breakpoints={['lg']}>
            <Divider />
          </Responsive.HideOn>
          <PropertyBackButton>
            <Link to={getSingleRentalUrl(offerId)}>Go Back</Link>
          </PropertyBackButton>
          <PropertyOfferTimeline offer={offer} />
        </OfferAuctionContainer>
      </Container>
    </CustomCard>
  );
};
