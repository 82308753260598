import React from 'react';
import { useQueryParams, SetQueryParamsHandler } from './useQueryParams';
import { PaginationConfig, SorterResult, TableCurrentDataSource } from 'antd/lib/table';

const PAGE_SIZE = 25;
const PAGE_SIZE_OPTIONS = ['25', '50', '100'];

export type TableQueryParams = {
  page?: number;
  pageSize?: number;
  sortBy?: string;
  sortOrder?: 'ascend' | 'descend';
  query?: string;
};

export type TableState = {
  page: number;
  pageSize: number;
  sortBy?: string;
  sortOrder?: 'ascend' | 'descend';
  pageSizeOptions?: string[];
};

export type TableStateOnChange = (
  pagination: PaginationConfig,
  filters: Partial<Record<never, string[]>>,
  // eslint-disable-next-line @typescript-eslint/ban-types
  sorter: SorterResult<{}>,
  // eslint-disable-next-line @typescript-eslint/ban-types
  extra: TableCurrentDataSource<{}>,
) => void;

type UseTableStateOptions = void | {
  query?: string;
};

export type UseTableStateResponse = [TableState, TableStateOnChange];

export const useTableState = (params: UseTableStateOptions): UseTableStateResponse => {
  const [queryParams, setQueryParams]: [TableQueryParams, SetQueryParamsHandler] = useQueryParams();

  const page: number = queryParams.page ? parseInt(String(queryParams.page)) : 1;
  const pageSize: number = queryParams.pageSize ? parseInt(String(queryParams.pageSize)) : PAGE_SIZE;
  const pageSizeOptions: string[] = PAGE_SIZE_OPTIONS;

  const onChange = React.useCallback(
    (pagination, filters, sorter) => {
      let nextQueryParams: TableQueryParams = {
        ...queryParams,
        page: pagination.current,
        pageSize: pagination.pageSize,
      };

      if (sorter.field && sorter.order) {
        nextQueryParams = {
          ...nextQueryParams,
          sortBy: sorter.field,
          sortOrder: sorter.order,
        };
      } else if (sorter.field === queryParams.sortBy && !sorter.order) {
        delete nextQueryParams.sortBy;
        delete nextQueryParams.sortOrder;
      }

      setQueryParams(nextQueryParams, true);
    },
    [queryParams, setQueryParams],
  );

  const tableState: TableState = {
    page,
    sortBy: queryParams.sortBy,
    sortOrder: queryParams.sortOrder,
    pageSize,
    pageSizeOptions,
  };

  return [tableState, onChange];
};
