import React, { ChangeEvent } from 'react';
import { Input } from 'antd';

import { CustomCard, Grid, Heading, ButtonLink } from '@/components';
import { useSearch, useDictionary, useCurrentCompany, usePermissions } from '@/shared/hooks';

import { SingleUnitsTable } from './SingleUnitsTable';
import { dialogsContext } from '@/providers';
import { TrashIcon } from '@/static/icons';
import { SINGLE_UNIT_CREATE_DIALOG, SINGLE_UNITS_IMPORT_DIALOG, BULK_DELETE_DIALOG } from '@/dialogs';

export const SingleUnits = () => {
  const [{ search, throttledSearch }, setSearch] = useSearch();

  const { openDialog } = React.useContext(dialogsContext);

  const { company } = useCurrentCompany();
  const companyType = company?.type;
  const [selectedRecords, setSelectedRecords] = React.useState<string[]>([]);

  const { isAdmin, isCompanyOwner } = usePermissions();
  const allowAddUnits = isAdmin || isCompanyOwner;

  const openSingleUnitCreateModal = React.useCallback(() => {
    openDialog({
      name: SINGLE_UNIT_CREATE_DIALOG,
      props: {
        closable: true,
        refetchQueries: ['PortfolioSingleUnitsList', 'NavigationSingleUnitsList', 'NavigationCommunitiesList'],
        companyType,
      },
    });
  }, [companyType, openDialog]);

  const onChange = React.useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSelectedRecords([]);
      setSearch(event.currentTarget.value);
    },
    [setSearch],
  );

  const { singleUnit: singleUnitName } = useDictionary();

  const openSingleUnitsImportModal = () =>
    openDialog({
      name: SINGLE_UNITS_IMPORT_DIALOG,
      props: {
        options: {
          refetchQueries: ['PortfolioSingleUnitsList'],
          awaitRefetchQueries: true,
        },
      },
    });

  const getSelectedRecords = (records: Array<string>) => {
    setSelectedRecords(records);
  };

  const onBulkDelete = () => {
    openDialog({
      name: BULK_DELETE_DIALOG,
      props: {
        records: selectedRecords,
        recordType: 'singleUnit',
        options: {
          refetchQueries: ['PortfolioSingleUnitsList', 'NavigationSingleUnitsList', 'NavigationCommunitiesList'],
          awaitRefetchQueries: true,
        },
      },
    });
    setSelectedRecords([]);
  };

  const header = (
    <Grid.Layout gap="20px" columns="auto 400px 1fr 1fr" alignItems="center" justifyItems="flex-start" stretch>
      <Heading type="h1">{singleUnitName.plural}</Heading>
      <Input value={search} placeholder={`Search by ${singleUnitName.single} Name`} onChange={onChange} />
      <Grid.Layout columns="auto auto" css={{ marginLeft: '20px' }}>
        <If condition={selectedRecords.length > 0}>
          <ButtonLink onClick={onBulkDelete} text={`Delete Selected`} iconProps={{ component: TrashIcon }} />
        </If>
      </Grid.Layout>
      {allowAddUnits && (
        <Grid.Layout columns="auto auto" css={{ marginLeft: 'auto' }}>
          <ButtonLink
            onClick={openSingleUnitsImportModal}
            text={`Import ${singleUnitName.plural}`}
            iconProps={{ type: 'plus-circle' }}
          />
          <ButtonLink
            onClick={openSingleUnitCreateModal}
            text={`Add New ${singleUnitName.single}`}
            iconProps={{ type: 'plus-circle' }}
          />
        </Grid.Layout>
      )}
    </Grid.Layout>
  );

  return (
    <CustomCard padding="none" data-e2e-id="profile.singleUnits" header={header}>
      <SingleUnitsTable
        setSelectedRecords={selectedRecords}
        getSelectedRecords={getSelectedRecords}
        query={throttledSearch}
      />
    </CustomCard>
  );
};
