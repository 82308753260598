import React from 'react';
import styled from '@emotion/styled';
import { Card } from 'antd';

import { SummaryItem } from './SummaryItem';
import { Bid } from '@/shared/types/graphql';
import { useCompanyTypeCheck } from '@/shared/hooks';
import { COMPANY_TYPES } from '@/shared/constants';

const Container = styled.div`
  grid-area: Summary;
  display: grid;
  .ant-card-body {
    &::before,
    &::after {
      display: none;
    }
    display: grid;
    grid-auto-flow: columns;
    grid-template-columns: repeat(8, 142px);
    grid-auto-rows: 104px;
    justify-content: space-between;
  }
`;

export type SummaryItemProps = { value: number | null; description: string; isPercentage?: boolean };

export const Summary = (props: any) => {
  const dashboardInfo = props.dashboardInfo;
  const offersResultData = props.offersResultData;

  const isMdu = useCompanyTypeCheck(COMPANY_TYPES.mdu);

  const communitiesCount = isMdu ? dashboardInfo?.communitiesList?.count : dashboardInfo?.singleUnitsList?.count;
  const bidsResultData = dashboardInfo?.bidsList?.items || [];
  const bidsListItemsCount = dashboardInfo?.bidsList?.count || 1;
  const totalUnitsCount = isMdu
    ? dashboardInfo?.communitiesList?.items?.reduce((acc: any, value: any) => acc + value?.numberOfUnits, 0)
    : dashboardInfo?.singleUnitsList?.count;

  const bidsRentIncrease = bidsResultData.map((bid: Bid) => {
    if (bid?.rentAmount && bid?.offer?.rentAmount) {
      return {
        bidIncrease: Math.round(((bid?.rentAmount - bid?.offer?.rentAmount) / bid?.offer?.rentAmount) * 100),
      };
    } else return 0;
  });

  const calculateIncrease = (increase: any) => {
    let summ = 0;
    increase?.forEach((el: any) => (summ += el?.bidIncrease));

    return Math.round(summ / bidsListItemsCount);
  };

  const resultRentIncrease = calculateIncrease(bidsRentIncrease);

  const summaryData: Array<SummaryItemProps> = [
    { value: communitiesCount || null, description: 'Number of Properties' },
    { value: totalUnitsCount || null, description: 'Total Units' },
    { value: dashboardInfo?.singleUnitsList?.count || null, description: 'Active Units' },
    { value: offersResultData?.live || null, description: 'Active Livo Transactions' },
    { value: null, description: 'Active Applications' },
    { value: null, description: 'Active Screenings' },
    { value: null, description: 'Active Showings' },
    { value: resultRentIncrease || null, description: 'Average Rent Increase', isPercentage: true },
  ];

  return (
    <Container>
      <Card title="Dashboard Summary">
        {summaryData.map(SummaryItemData => (
          <SummaryItem key={SummaryItemData.description} {...SummaryItemData} />
        ))}
      </Card>
    </Container>
  );
};
