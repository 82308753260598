import gql from 'graphql-tag';

export const ADMIN_DASHBOARD_COMPANIES_QUERY = gql`
  query AdminCompanies($first: Int, $skip: Int, $filter: CompanyFilter, $sort: [CompanySort!]) {
    companiesList(first: $first, skip: $skip, filter: $filter, sort: $sort) {
      items {
        id
        numberId
        clientId
        screeningProcess
        isBlocked
        virtualShowings
        companyLogo {
          id
          downloadUrl
        }
        name
        type
        status
        website
        singleUnits {
          count
        }
        communities {
          count
        }
        updatedAt
        companySize
        mdu {
          id
          numberOfPropertiesManaged
        }
      }
      count
    }
  }
`;

export const DASHBOARD_OFFERS_BY_COMPANY_QUERY = gql`
  query PortfolioOffersByCommunity($filter: OfferFilter) {
    offersList(
      filter: $filter
      groupBy: {
        query: {
          id: { as: "offersCount", fn: { aggregate: COUNT } }
          activeOfferSingleUnit: { company: { id: { as: "companyId" } } }
        }
      }
    ) {
      groups {
        offersCount: Int
        companyId: String
      }
    }
  }
`;
