import gql from 'graphql-tag';

export const SELECTOR_COMPANIES_QUERY = gql`
  query CompaniesSelector($first: Int, $skip: Int, $filter: CompanyFilter, $sort: [CompanySort!]) {
    companiesList(first: $first, skip: $skip, filter: $filter, sort: $sort) {
      items {
        id
        name
      }
      count
    }
  }
`;
