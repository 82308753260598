import React from 'react';

import * as R from 'ramda';
import { useHistory } from 'react-router-dom';
import { useAuthState, useCurrentUser } from '@/shared/hooks';
import { useAuth } from '8base-react-sdk';
import { sendToSentry } from '@/shared/sentry';

import { UserAuthStates } from '@/shared/constants';
import { storageType, storageKey, redirectType } from '@/shared/types/store';
import Store from '@/shared/utils/Store';

export const useRedirects = () => {
  const { authClient } = useAuth();
  const { user } = useCurrentUser();
  const currentUserAuthState = useAuthState();

  const store = new Store({ storageType: storageType.Session, storageKey: storageKey.Redirect });
  const history = useHistory();

  React.useEffect(() => {
    switch (currentUserAuthState) {
      case UserAuthStates.Authorized:
        const redirectAfterLogin = store.get(redirectType.afterLogin);
        const redirectAfterLoginUrl = R.pathOr(null, ['pathname'], redirectAfterLogin);

        if (redirectAfterLoginUrl) {
          store.drop(redirectType.afterLogin);
          history.replace(redirectAfterLoginUrl);
        }

        const redirectToBidding = store.get(redirectType.toBidding);
        const redirectToBiddingUrl = R.pathOr(null, ['pathname'], redirectToBidding);

        if (redirectToBiddingUrl) {
          store.drop(redirectType.toBidding);
          history.replace(redirectToBiddingUrl);
        }
        break;
    }
  }, [currentUserAuthState, history, store]);

  React.useEffect(
    () => {
      const onStorageChange = () => {
        const authState = authClient.getState();
        const desynced = user && user.email !== authState.email;
        const globalRef = window as any;

        if (desynced && !globalRef._desynced) {
          globalRef._desynced = true;
          alert('User is desynchronized, session will be closed, please log-in in a minute');
          authClient.batch(() => {
            sendToSentry('User is desynchronized', {
              authEmail: authState.email,
              userEmail: user?.email,
            });
            authClient.purgeState();
            authClient.logout();
          });
        }
      };
      window.addEventListener('storage', onStorageChange);
      return () => window.removeEventListener('storage', onStorageChange);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user],
  );
};
