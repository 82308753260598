import React from 'react';
import { Collapse, Icon } from 'antd';
import { styled } from '@/theme';
import { useQuery } from 'react-apollo';

import { ChevronUp, ChevronDown } from '@/static/icons';
import { Grid, ButtonLink, AsyncContent } from '@/components';
import { EntityOption, OfferStatusTag, BidStatusTag } from '@/pro';
import { BidListResponse, Maybe, Bid } from '@/shared/types/graphql';
import { formatAmount, stringifyDate, stringifyEndsIn, stringifyTermsOfLease } from '@/shared/utils';
import { NOT_AVALIABLE } from '@/shared/constants';
import { dialogsContext } from '@/providers';
import { usePermissions } from '@/shared/hooks';
import { BIDS_LIST_BY_RENTER_QUERY } from './queries';
import { OFFER_DETAILS_DIALOG } from '@/dialogs';

type UseBidsListByRenterQueryParams = {
  renterId?: Maybe<string>;
};
const useBidsListByRenterQuery = ({ renterId }: UseBidsListByRenterQueryParams) => {
  const { isAdmin, isCompanyOwner, allowedCommunities } = usePermissions();

  const all: any = [
    {
      renter: {
        id: {
          equals: renterId,
        },
      },
    },
    {
      offer: {
        historyOfferSingleUnit: {
          id: {
            is_not_empty: true,
          },
        },
      },
    },
    !(isAdmin || isCompanyOwner) && {
      offer: {
        historyOfferSingleUnit: {
          community: {
            id: {
              in: allowedCommunities.map(({ id }) => id),
            },
          },
        },
      },
    },
  ].filter(Boolean);

  return {
    AND: all,
  };
};

const Container = styled.div`
  .ant-collapse-header {
    font-weight: 600;
    letter-spacing: 0;
    border-bottom: 1px solid #e8e8e8;
  }
  .ant-collapse-content-box {
    padding-bottom: 8px;
  }
  .ant-collapse-borderless {
    background-color: transparent;
  }
`;

const Content = styled.div`
  position: relative;
  padding-top: 20px;
`;

const customPanelStyle = {
  border: 0,
};

type BidsListProps = {
  renterId?: Maybe<string>;
};

export const AllBidsList = ({ renterId }: BidsListProps) => {
  const { openDialog } = React.useContext(dialogsContext);

  const bidsListByRenterQuery = useBidsListByRenterQuery({ renterId });

  const { data, loading } = useQuery<{ bidsList: BidListResponse }>(BIDS_LIST_BY_RENTER_QUERY, {
    skip: !renterId,
    variables: {
      filter: bidsListByRenterQuery,
    },
  });

  const bidsList = data?.bidsList?.items || ([] as Bid[]);

  return (
    <AsyncContent loading={loading}>
      <Container>
        <Collapse
          expandIconPosition="right"
          bordered={false}
          expandIcon={({ isActive }) => (
            <Icon
              component={isActive ? ChevronDown : ChevronUp}
              css={{ color: '#f37a22 !important', fontSize: '18px !important' }}
            />
          )}
          accordion
        >
          {bidsList.map((bid, index) => {
            const endsInDiff = stringifyEndsIn(bid?.offer?.endsIn);
            return (
              <Collapse.Panel
                header={
                  <div css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span css={{ marginRight: 10, flexBasis: '30%' }}>
                      {bid?.offer?.historyOfferSingleUnit?.name || NOT_AVALIABLE}
                    </span>
                    <OfferStatusTag css={{ flexBasis: '30%' }} status={bid?.offer?.status} withColors={false} />
                    <span css={{ marginLeft: 10, flexBasis: '30%', textAlign: 'right' }}>
                      {bid?.offer?.historyOfferSingleUnit?.company?.name || NOT_AVALIABLE}
                    </span>
                  </div>
                }
                key={bid?.id || index}
                style={customPanelStyle}
              >
                <Content>
                  <Grid.Layout gap="15px">
                    <EntityOption
                      type="uppercase-label"
                      label="Rent amount"
                      value={bid?.rentAmount ? `$${formatAmount(bid.rentAmount)}` : NOT_AVALIABLE}
                    />
                    <EntityOption type="uppercase-label" label="Move in date" value={stringifyDate(bid?.moveinDate)} />
                    <EntityOption
                      type="uppercase-label"
                      label="Lease"
                      value={stringifyTermsOfLease(bid?.termsOfLease)}
                    />
                    <EntityOption type="uppercase-label" label="Offer ends in" value={endsInDiff} />
                    <EntityOption type="uppercase-label" label="Status" value={<BidStatusTag status={bid?.status} />} />
                    <ButtonLink
                      text="View Offer Details"
                      onClick={() =>
                        openDialog({
                          name: OFFER_DETAILS_DIALOG,
                          props: {
                            offerId: bid?.offer?.id,
                            singleUnitId: bid?.offer?.historyOfferSingleUnit?.id,
                          },
                        })
                      }
                      css={{ fontSize: 12, position: 'absolute', bottom: '2px', right: 0 }}
                    />
                  </Grid.Layout>
                </Content>
              </Collapse.Panel>
            );
          })}
        </Collapse>
      </Container>
    </AsyncContent>
  );
};
