import React from 'react';
import { Row, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { dialogsContext } from '@/providers';
import { Offer, Bid, Maybe } from '@/shared/types/graphql';
import { formatAmount, stringifyHref } from '@/shared/utils';
import { AGENT_CONTACT_DIALOG, BID_CREATE_DIALOG } from '@/dialogs';
import { useBidCreateDialog, useBidDeleteDialog, useSharedDialogProps } from './helpers';
import { ActionButton } from './ActionButton';

const { Paragraph } = Typography;

type BidOfferActionsProps = {
  userBidPlace: number;
  activeItemId: number;
  offer?: Maybe<Offer>;
  bid: Bid;
  userBid?: Bid;
};

export const BidOfferActions: React.FC<BidOfferActionsProps> = props => {
  const { activeItemId, userBidPlace, offer, bid, userBid } = props;

  const { id: offerId } = useParams<{ id: string }>();
  const { openDialog } = React.useContext(dialogsContext);

  const isOwner = userBidPlace === activeItemId || (activeItemId === 4 && userBidPlace > 3);

  const applicationFormLink: any = bid?.offer?.applicationLink;

  const sharedModalProps = useSharedDialogProps({ bid, offer, userBid });

  const onImproveOffer = React.useCallback(
    () =>
      openDialog({
        name: BID_CREATE_DIALOG,
        props: {
          ...sharedModalProps,
          securityDepositeEditable: offer?.securityDepositeEditable,
          rentAmount: bid?.rentAmount,
          termsOfLease: bid?.termsOfLease,
          moveinDate: bid?.moveinDate,
          offerMoveinDate: offer?.moveinDate,
          moveinDateEditable: offer?.moveinDateEditable,
          title: 'Edit My Offer',
        },
      }),
    [openDialog, sharedModalProps, offer, bid],
  );

  const rentAmmoutForBeat = (bid?.rentAmount || 0) + (offer?.incrementedBidAmount || 0);

  const onSecureNow = React.useCallback(
    () =>
      openDialog({
        name: BID_CREATE_DIALOG,
        props: {
          ...sharedModalProps,
          securityDepositeEditable: offer?.securityDepositeEditable,
          rentAmount: offer?.secureNowAmount,
          termsOfLease: offer?.termsOfLease,
          moveinDate: offer?.moveinDate,
          moveinDateEditable: offer?.moveinDateEditable,
          secureNow: true,
          title: 'SecureNow™',
        },
      }),
    [offer, openDialog, sharedModalProps],
  );

  const onBeatOffer = useBidCreateDialog({
    sharedModalProps,
    offer,
    bid,
    rentAmount: rentAmmoutForBeat,
    offerMoveinDate: offer?.moveinDate,
    termsOfLease: bid?.termsOfLease as number,
  });

  const onMatchOffer = useBidCreateDialog({
    sharedModalProps,
    offer,
    bid,
    rentAmount: bid?.rentAmount as number,
    termsOfLease: offer?.termsOfLease as number,
  });
  const onWithdrawOffer = useBidDeleteDialog({ bid, offer, offerId });

  const onContactAgent = React.useCallback(
    () => openDialog({ name: AGENT_CONTACT_DIALOG, props: { bidId: bid?.id } }),
    [bid, openDialog],
  );

  const onOpenApplicationForm = React.useCallback(() => {
    const href = stringifyHref(applicationFormLink);
    return window.open(href);
  }, [applicationFormLink]);

  const isOfferSecureNow = offer?.secureNow as boolean;

  return (
    <>
      <Row gutter={[12, 12]}>
        <ActionButton text="Edit Your Offer" type="primary" isVisible={isOwner} onClick={onImproveOffer} />
        <ActionButton text="Beat This Offer!" type="primary" isVisible={!isOwner} onClick={onBeatOffer} />
        <ActionButton text="SecureNow™!" md={isOwner ? 24 : 12} isVisible={isOfferSecureNow} onClick={onSecureNow} />
        <ActionButton text="Match Offer" md={isOfferSecureNow ? 12 : 24} isVisible={!isOwner} onClick={onMatchOffer} />
        <ActionButton
          text="Start Application and Screening Process"
          withoutPaddings
          type="primary"
          onClick={onOpenApplicationForm}
          isVisible={applicationFormLink}
        />
        <ActionButton text="Contact Agent" onClick={onContactAgent} />
        <ActionButton text="Withdraw Offer" isVisible={isOwner} onClick={onWithdrawOffer} />
      </Row>

      {isOfferSecureNow && (
        <Paragraph
          css={{ fontSize: '1.25rem', textAlign: 'center', marginTop: 15, fontWeight: 'bold', color: '#f37a22' }}
        >
          *Secure amount is set at ${formatAmount(offer?.secureNowAmount)}
        </Paragraph>
      )}
    </>
  );
};
