import React from 'react';

import { CustomCard, Grid, Heading } from '@/components';
import { useQuery } from 'react-apollo';
import { RENTAL_OFFERS_LIST_QUERY } from './queries';
import { useQueryParams } from '@/shared/hooks';
import { OfferCardGrid } from './components';
import { OfferListResponse } from '@/shared/types/graphql';
import { Select } from 'antd';
import { SORT_OPTIONS, FILTER_OPTIONS } from './constants';
import { getStatusFIlter } from './helpers';

export const PageOffers = () => {
  const [queryParams, setQueryParams] = useQueryParams<{
    sortBy: string;
    sortOrder: string;
    filter: 'all' | 'leased' | 'active';
  }>(100, {
    sortBy: 'createdAt',
    sortOrder: 'DESC',
    filter: 'all',
  });

  const statusFilter = getStatusFIlter({ filter: queryParams?.filter });

  const { data, loading } = useQuery<{ offersList: OfferListResponse }>(RENTAL_OFFERS_LIST_QUERY, {
    skip: !queryParams?.sortBy,
    fetchPolicy: 'network-only',
    variables: {
      sort: {
        [queryParams.sortBy]: queryParams.sortOrder,
      },
      filter: {
        AND: [
          statusFilter,
          {
            historyOfferSingleUnit: {
              id: {
                is_not_empty: true,
              },
            },
          },
          {
            bid: {
              some: {
                renter: {
                  user: {
                    id: {
                      equals: '__loggedInUserId',
                    },
                  },
                },
              },
            },
          },
        ],
      },
    },
  });

  const onChangeSortBy = React.useCallback(
    (value: any) => {
      setQueryParams({ ...queryParams, ...JSON.parse(value) });
    },
    [queryParams, setQueryParams],
  );

  const onChangeFilter = React.useCallback(
    (value: any) => {
      setQueryParams({ ...queryParams, filter: value });
    },
    [queryParams, setQueryParams],
  );

  const sortValue = JSON.stringify({
    sortBy: queryParams?.sortBy,
    sortOrder: queryParams?.sortOrder,
  });

  const header = (
    <Grid.Layout gap="none" columns="50% 50%" alignItems="center" justifyItems="space-between" stretch>
      <Heading type="h1">My Rentals</Heading>
      <Grid.Box justifySelf="flex-end">
        <Grid.Layout columns="200px 200px">
          <Select onChange={onChangeFilter} value={queryParams?.filter} loading={loading} placeholder="Sort by">
            {FILTER_OPTIONS.map(option => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
          <Select onChange={onChangeSortBy} value={sortValue} loading={loading} placeholder="Sort by">
            {SORT_OPTIONS.map(option => (
              <Select.Option key={JSON.stringify(option.value)} value={JSON.stringify(option.value)}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </Grid.Layout>
      </Grid.Box>
    </Grid.Layout>
  );

  return (
    <CustomCard header={header}>
      <OfferCardGrid offers={data?.offersList?.items || []} loading={loading} />
    </CustomCard>
  );
};
