import React from 'react';
import { Modal, Icon, Typography } from 'antd';

import { Maybe } from '@/shared/types/graphql';
import { ModalFooter } from '@/components';
import { RedAlert } from '@/static/icons';
import { TOAST_SUCCESS_MESSAGE } from '@/shared/constants';
import { EMPLOYEE_DELETE_MODAL_MUTATION } from './queries';
import { useMutationSafe } from '@/shared/hooks';

type DialogProps = {
  visible?: boolean;
  userId?: Maybe<string>;
  firstName?: string;
  lastName?: string;
  refetchQueries?: Array<string>;
  close: () => void;
  isVisible: boolean;
};

export const Dialog: React.FC<DialogProps> = ({
  isVisible,
  close,
  userId,
  firstName,
  lastName,
  refetchQueries = [],
}) => {
  const [deleteEmployee, { loading: deleteEmployeeLoading }] = useMutationSafe(EMPLOYEE_DELETE_MODAL_MUTATION, {
    refetchQueries: ['EmployeesList', ...refetchQueries],
    awaitRefetchQueries: true,
    context: {
      [TOAST_SUCCESS_MESSAGE]: 'Employee Successfully Deleted',
    },
    onCompleted: close,
  });
  const userName = firstName || '';
  const userLastName = lastName || '';

  const title = React.useMemo(
    () => (
      <>
        <Icon
          component={RedAlert}
          css={{ marginRight: '8px', fontSize: '20px', lineHeight: '22px', verticalAlign: 'middle' }}
        />
        Delete Employee
      </>
    ),
    [],
  );

  return (
    <Modal
      title={title}
      visible={isVisible}
      onCancel={close}
      width="100%"
      style={{ maxWidth: 400 }}
      footer={
        <ModalFooter
          cancelProps={{
            onClick: close,
          }}
          okProps={{
            disabled: !userId || deleteEmployeeLoading,
            loading: deleteEmployeeLoading,
            onClick: () => {
              deleteEmployee({
                variables: {
                  userId,
                },
              });
            },
            type: 'danger',
            text: 'Delete',
          }}
        />
      }
      centered
    >
      <Typography.Paragraph>{`Are you sure you want to delete ${userName} ${userLastName} from employees?`}</Typography.Paragraph>
    </Modal>
  );
};
