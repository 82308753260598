import styled from '@emotion/styled';

export type PropSizes = 'noSet' | 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export type FlexLayoutCommonProps = {
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  justify?: 'start' | 'end' | 'center' | 'between' | 'around';
  align?: 'start' | 'center' | 'end' | 'baseline' | 'stretch';
  flexWrap?: 'wrap' | 'nowrap' | 'wrap-reverse';
  gap?: PropSizes;
  offset?: PropSizes;
  offsetX?: PropSizes;
  offsetY?: PropSizes;
  offsetTop?: PropSizes;
  offsetBottom?: PropSizes;
  offsetLeft?: PropSizes;
  offsetRight?: PropSizes;
  cursor?: 'pointer' | 'default' | 'inherit';
  stretch?: boolean;
  grow?: boolean;
  growChildren?: boolean;
  onClick?: ((event: MouseEvent) => void) | (() => void);
  tagName?: string;
  inline?: boolean;
};

export type FlexLayoutProps = FlexLayoutCommonProps & {
  direction?: 'row' | 'column';
};

export const paddingSizes: any = {
  none: '0',
  xs: '4px',
  sm: '8px',
  md: '16px',
  lg: '24px',
  xl: '32px',
  xxl: '48px',
};

export const gapSizes: any = {
  noSet: '',
  none: '0',
  xs: '4px',
  sm: '8px',
  md: '16px',
  lg: '24px',
  xl: '32px',
  xxl: '48px',
};

const justifyContentStyles: any = {
  start: 'flex-start',
  end: 'flex-end',
  center: 'center',
  between: 'space-between',
  around: 'space-around',
};

const alignItemsStyles: any = {
  start: 'flex-start',
  end: 'flex-end',
  center: 'center',
  baseline: 'baseline',
  stretch: 'stretch',
};

const getGapStyle = (props: FlexLayoutProps) => {
  if (props.gap !== 'noSet') {
    return props.direction === 'row'
      ? {
          '&:not(:last-child)': {
            marginRight: gapSizes[props.gap || 'noSet'],
          },
        }
      : {
          '&:not(:last-child)': {
            marginBottom: gapSizes[props.gap || 'noSet'],
          },
        };
  }

  return {};
};

const getGrowChildrenStyles = (props: FlexLayoutProps) => (props.growChildren ? { flexGrow: '1' } : {});

const getStretchStyles = (props: FlexLayoutProps) =>
  props.stretch
    ? {
        width: '100%',
        height: '100%',
      }
    : {};

const getInlineStyles = (props: FlexLayoutProps) =>
  props.inline
    ? {
        display: 'inline-flex',
      }
    : {};

const getFlexGrowStyles = (props: FlexLayoutProps) =>
  props.grow
    ? {
        flexGrow: '1',
      }
    : {};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const FlexLayoutTag = styled.div((props: any) => ({
  display: 'flex',
  flexDirection: props.direction,

  justifyContent: justifyContentStyles[props.justify],
  alignItems: alignItemsStyles[props.align],
  flexWrap: props.flexWrap,

  paddingLeft: paddingSizes[props.offsetX] ?? paddingSizes[props.offsetLeft] ?? paddingSizes[props.offset],
  paddingRight: paddingSizes[props.offsetX] ?? paddingSizes[props.offsetRight] ?? paddingSizes[props.offset],
  paddingTop: paddingSizes[props.offsetY] ?? paddingSizes[props.offsetTop] ?? paddingSizes[props.offset],
  paddingBottom: paddingSizes[props.offsetY] ?? paddingSizes[props.offsetBottom] ?? paddingSizes[props.offset],

  cursor: props.cursor,

  ...getStretchStyles(props),
  ...getInlineStyles(props),
  ...getFlexGrowStyles(props),

  '& > *': {
    ...getGapStyle(props),
    ...getGrowChildrenStyles(props),
  },
}));

export { FlexLayoutTag };
