import React from 'react';
import { FileInput, FileValue } from '8base-react-sdk';
import * as R from 'ramda';
import { Button } from 'antd';
import { css } from '@emotion/core';

import { FormField, ImagePreview } from '@/components';
import styled from '@/theme/styled';

const gapSize = 16;

const calculatePreviewWidth = (width: string) => {
  return width === '1/3' ? `calc(33.33% - ${(gapSize * 2) / 3}px)` : `calc(50% - ${gapSize / 2}px)`;
};

export const ImagePreviewContainer = styled.div<{ previewWidth?: string }>`
  display: grid;
  gap: ${gapSize}px;
  grid-template-columns: ${({ previewWidth }) =>
    previewWidth ? `repeat(auto-fit, ${calculatePreviewWidth(previewWidth)})` : 'repeat(auto-fit, 180px)'};
  margin-top: 16px;
`;

type ImageInputFieldProps = {
  label?: React.ReactNode;
  note?: string;
  input: any;
  buttonText: string;
  meta: any;
  maxFiles: number;
  isPublic?: boolean;
  accept?: string[];
  fromSources?: string[];
  previewProps?: Record<string, any>;
  previewWidth?: '1/3' | '1/2';
  'data-e2e-id'?: string;
};

export const ImageInputField = ({
  label,
  note,
  input,
  meta,
  buttonText,
  maxFiles,
  isPublic,
  accept = [],
  fromSources = ['local_file_system'],
  previewProps,
  previewWidth,
  ...rest
}: ImageInputFieldProps) => {
  const onChange = React.useCallback(
    (data: any) => {
      input.onChange([...data, ...input.value]);
    },
    [input],
  );

  const onDelete = React.useCallback(
    index => {
      input.onChange(R.remove(index, 1, input.value));
    },
    [input],
  );

  return (
    <FormField label={label} note={note} meta={meta} data-e2e-id={rest['data-e2e-id']}>
      <>
        <input
          type="text"
          css={css`
            display: none;
          `}
          name={input.name}
          onChange={event => onChange([{ fileId: event.target.value }])}
        />
        <FileInput onChange={onChange} value={input.value} maxFiles={maxFiles} public={isPublic}>
          {({ pick, value }) => (
            <>
              <Button type="default" size="small" onClick={() => pick({ accept, fromSources })} block>
                {buttonText}
              </Button>
              {!R.isEmpty(value) && (
                <ImagePreviewContainer previewWidth={previewWidth}>
                  {input.value.map(
                    (el: FileValue, index: number) =>
                      el.downloadUrl && (
                        <ImagePreview key={el.downloadUrl} value={el} handleDelete={onDelete.bind(null, index)} />
                      ),
                  )}
                </ImagePreviewContainer>
              )}
            </>
          )}
        </FileInput>
      </>
    </FormField>
  );
};
