import React from 'react';

export const DemographicStepDescription = () => {
  return (
    <div>
      Time to capture some of the client’s demographic information!{' '}
      <span css={{ color: '#f37a22', fontWeight: 'bold' }}>
        Pay special attention to the market option, since you won’t be able to change it later.
      </span>
    </div>
  );
};
