import React from 'react';
import { NOT_AVALIABLE } from '@/shared/constants';
import { stringifyPhone, beautifyPhone } from '@/shared/utils';
import { Link } from '@/components';
import { SmartPhone, Maybe } from '@/shared/types/graphql';

type PhoneLinkProps = {
  phone?: Maybe<SmartPhone>;
};

export const PhoneLink: React.FC<PhoneLinkProps> = ({ phone }) => {
  if (!phone || !phone?.number) {
    return <>{NOT_AVALIABLE}</>;
  }

  const href = stringifyPhone(phone);

  return (
    <Link href={`tel:${href}`} target="_blank">
      {beautifyPhone(phone)}
    </Link>
  );
};
