import { Modal } from 'antd';
import React from 'react';
import { Grid, Loader, Text } from '@/components';
import { Button } from 'livo-components/src/components';
import { styled } from '@/theme';
import { stringifyHref } from '@/shared/utils';

const Content = styled.div`
  padding: 50px;
`;

type DialogProps = {
  close: () => void;
  isVisible: boolean;
  screeningLink?: string;
};

export const Dialog: React.FC<DialogProps> = ({ isVisible, close, screeningLink }) => {
  if (!screeningLink) {
    return <Loader />;
  }

  return (
    <Modal
      title="Begin Screening"
      className="livo-modal"
      visible={isVisible}
      onCancel={close}
      width="100%"
      style={{ maxWidth: 600 }}
      centered
      footer={null}
    >
      <Content>
        {screeningLink ? (
          <Grid.Layout gap="50px">
            <Grid.Box>
              <Text css={{ textAlign: 'center' }} type="title">
                Your Screening Link is ready!
              </Text>
            </Grid.Box>
            <Grid.Box css={{ maxWidth: '300px', margin: 'auto' }}>
              <Button type="primary" size="large" onClick={() => window.open(stringifyHref(screeningLink))}>
                Click here to open form
              </Button>
            </Grid.Box>
          </Grid.Layout>
        ) : (
          <Grid.Layout gap="50px">
            <Grid.Box>
              <Text css={{ textAlign: 'center' }} type="title">
                Loading your Screening Link...
              </Text>
            </Grid.Box>
            <Grid.Box>
              <Loader />
            </Grid.Box>
          </Grid.Layout>
        )}
      </Content>
    </Modal>
  );
};
