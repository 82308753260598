import React from 'react';
import { Tabs } from 'antd';
import { useQuery } from 'react-apollo';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Loader } from '@/components';

import { COMPANY_TYPES } from '@/shared/constants';
import { useCurrentCompanyId, useCompanyTypeCheck } from '@/shared/hooks';
import { SingleUnit as SingleUnitType, Offer as OfferType } from '@/shared/types/graphql';

import { SingleUnitDetails } from './Details';
import { SingleUnitRenters } from './Renters';
import { RENTERS_AMOUNT_QUERY, UNIT_DETAILS_VIA_OFFER } from './queries';

const { TabPane } = Tabs;

const detailsTabKey = '/details';
const rentersTabKey = '/renters';

export const SingleUnit = () => {
  const { singleUnitId, offerId } = useParams<{ singleUnitId: string; offerId: string }>();

  switch (true) {
    // /management/:companyId/communities/:communityId/single-units/:singleUnitId/...
    // /management/:companyId/single-units/:singleUnitId/...
    case Boolean(singleUnitId): {
      return <UnitThenOffer />;
    }

    // /management/:companyId/rentals/single-units/:offerId/renters...
    case Boolean(offerId): {
      return <OfferThenUnit />;
    }

    // rout somehow doesnt have required params?
    // shouldnt be possible to get here
    default: {
      return null;
    }
  }
};

function UnitThenOffer() {
  const { singleUnitId, communityId } = useParams<{ singleUnitId: string; communityId: string }>();
  const { companyId } = useCurrentCompanyId();
  const isMdu = useCompanyTypeCheck(COMPANY_TYPES.mdu);

  const { data: singleUnitDetailsData, loading: singleUnitLoading } = useQuery<{ singleUnit: SingleUnitType }>(
    RENTERS_AMOUNT_QUERY,
    {
      variables: {
        id: singleUnitId,
      },
      skip: !singleUnitId,
    },
  );

  const offer = singleUnitDetailsData?.singleUnit?.activeOffer;
  const bids = offer?.bid?.count;

  return (
    <PageTabs
      kind="unitFirst"
      companyId={companyId}
      singleUnitId={singleUnitId}
      communityId={communityId}
      offer={offer}
      bids={bids}
      loading={singleUnitLoading}
      isMdu={isMdu}
    />
  );
}

function OfferThenUnit() {
  const { offerId } = useParams<{ offerId: string }>();

  const { data: offerDetailsData, loading: offerDetailsLoading } = useQuery<{ offer: OfferType }>(
    UNIT_DETAILS_VIA_OFFER,
    {
      variables: {
        id: offerId,
      },
      skip: !offerId,
    },
  );

  const singleUnit = offerDetailsData?.offer?.historyOfferSingleUnit;
  const singleUnitId = singleUnit?.id;
  const companyId = singleUnit?.company?.id as string;
  const communityId = singleUnit?.community?.id;
  const isMdu = singleUnit?.company?.type === COMPANY_TYPES.mdu;

  const offer = offerDetailsData?.offer;
  const bids = offer?.bid?.count;

  return (
    <PageTabs
      kind="offerFirst"
      companyId={companyId}
      singleUnitId={singleUnitId}
      communityId={communityId}
      offer={offer}
      bids={bids}
      loading={offerDetailsLoading}
      isMdu={isMdu}
    />
  );
}

function PageTabs({
  kind,
  companyId,
  singleUnitId,
  communityId,
  offer,
  bids,
  loading,
  isMdu,
}: (
  | {
      kind: 'offerFirst';
    }
  | {
      kind: 'unitFirst';
    }
) & {
  companyId: string;
  singleUnitId?: string | null;
  communityId?: string | null;
  offer?: OfferType | null;
  bids?: number | null;
  loading: boolean;
  isMdu: boolean;
}) {
  const location = useLocation();
  const history = useHistory();
  const pathname = location.pathname;
  const isRenters = pathname.endsWith(rentersTabKey);

  const handleTabChange = (activeKey: string) => {
    const linkBasedOnCompanyType = isMdu
      ? `/management/${companyId}/communities/${communityId}/single-units/${singleUnitId}${activeKey}`
      : `/management/${companyId}/single-units/${singleUnitId}${activeKey}`;
    history.push(linkBasedOnCompanyType);
  };

  const queryOfferFirstId = kind === 'offerFirst' ? offer?.id : undefined;
  const rentersDisabled = loading || !offer || bids === 0;

  if (loading) {
    return <Loader stretch />;
  }

  return (
    <Tabs
      className="livo-tabs"
      animated={false}
      defaultActiveKey={detailsTabKey}
      activeKey={isRenters ? rentersTabKey : detailsTabKey}
      onChange={handleTabChange}
    >
      <TabPane tab="Details" key={detailsTabKey}>
        <SingleUnitDetails queryOfferFirstId={queryOfferFirstId} />
      </TabPane>
      <TabPane tab="Renters" key={rentersTabKey} disabled={rentersDisabled}>
        <SingleUnitRenters
          queryOfferFirstId={queryOfferFirstId}
          redirectPath={
            isMdu
              ? `/management/${companyId}/communities/${communityId}/single-units/${singleUnitId}/details`
              : `/management/${companyId}/single-units/${singleUnitId}/details`
          }
        />
      </TabPane>
    </Tabs>
  );
}
