import styled from '@/theme/styled';
import { Theme } from '@/theme/types';

const ITEM_GAP = '40px';
const MOBILE_ITEM_GAP = '20px';

export type Size = 'sm' | 'md';

export const setSmStyles = (styles: string) => ({ size, theme }: { size: Size; theme: Theme }) => {
  if (size === 'sm') {
    return styles;
  }

  return `
    @media (max-width: ${theme.vars.breakpoints.sm.max}) {
      ${styles}
    }
  `;
};

export const TimelineWrapper = styled.div<{ size: Size }>`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 130px;

  ${setSmStyles(
    `
      grid-gap: 60px;
    `,
  )}

  @media (max-width: ${({ theme }) => theme.vars.breakpoints.md.max}) {
    margin: 0 auto;
  }
`;

export const TimelineItemCirclesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TimelineItemCircle = styled.div<{ active: boolean; size: Size }>`
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 70px;
  width: 70px;
  border: 2px solid ${props => props.theme.vars.primaryColor};
  background-color: ${props => (props.active ? props.theme.vars.primaryColor : '#ffffff')};
  color: ${props => (props.active ? '#ffffff' : props.theme.vars.primaryColor)};
  border-radius: 50%;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: ${ITEM_GAP};
  }

  ${setSmStyles(`
    height: 50px;
    width: 50px;

    &:not(:last-child) {
      margin-bottom: ${MOBILE_ITEM_GAP};
    }
  `)}
`;

export const TimelineItemCircleLine = styled.div<{ size: Size }>`
  position: absolute;
  cursor: default;
  pointer-events: none;
  top: 68px;
  height: ${ITEM_GAP};
  width: 1px;
  background-color: ${props => props.theme.vars.primaryColor};

  ${setSmStyles(
    `
    height: ${MOBILE_ITEM_GAP};
    top: 48px;
    `,
  )}
`;

export const TimelineItemLine = styled.div<{ index: number; size: Size }>`
  position: absolute;
  cursor: default;
  pointer-events: none;
  border-right: 1px solid #323c47;
  width: 50px;
  right: -70px;

  ${props =>
    props.index === 0
      ? `
    height: 45px;
    border-top: 1px solid #323c47;
    top: 50%;
    ` +
        setSmStyles(`
       width: 20px;
       right: -33px;
       height: 40px;
  `)(props)
      : `
    height: ${65 + (props.index - 1) * 110}px;
    border-bottom: 1px solid #323c47;
    bottom: 50%;
    ` +
        setSmStyles(`
       width: 20px;
       right: -33px;
       height: ${30 + (props.index - 1) * 70}px;
  `)(props)};
`;

export const TimelineContentLine = styled.div<{ size: Size }>`
  position: absolute;
  cursor: default;
  pointer-events: none;
  border-bottom: 1px solid #323c47;
  height: 1px;
  width: 50px;
  top: 20px;
  left: -62px;

  ${setSmStyles(`
     width: 20px;
     top: 45px;
     left: -30px;
  `)}
`;

export const TimelineItemCircleLineText = styled.div<{ size: Size }>`
  position: absolute;
  cursor: default;
  pointer-events: none;
  bottom: 80px;
  color: ${props => props.theme.vars.primaryColor};
  background-color: #ffffff;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;

  ${setSmStyles(`
    display: none;
  `)}
`;

export const TimelineItemTitle = styled.div<{ size: Size }>`
  font-size: 24px;
  line-height: 1;

  ${setSmStyles(`
    font-size: 16px;
  `)}
`;

export const TimelineItemSubTitle = styled.div<{ size: Size }>`
  font-size: 10px;

  ${setSmStyles(`
    font-size: 8px;
  `)}
`;

export const TimelineContent = styled.div<{ size: Size }>`
  position: relative;
  margin-top: 60px;

  ${setSmStyles(`
    margin-top: 20px;
  `)}
`;
