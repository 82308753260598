import React from 'react';
import { Switch, Redirect, Route, RouteProps, useParams } from 'react-router-dom';

import { COMPANY_TYPES } from '@/shared/constants';
import { ManagementLayout } from '@/layouts/ManagementLayout';

import { Dashboard } from './company/entity/dashboard/root';
import { CompanyProfile } from './company/entity/profile';
import { Account } from './company/entity/settings/account';
import { Team } from './company/entity/settings/team';
import { SingleUnits as PortfolioSingleUnits } from './company/entity/single-units/root';
import { CommunityCard } from './company/entity/communities/entity/card/root';
import { SingleUnit } from './company/entity/single-units/entity/root';
import { SingleUnits as RentalSingleUnits } from './company/entity/rentals/single-units/root';
import { Leads } from './company/entity/leads/root';
import { Communities as PortfolioCommunities } from './company/entity/communities/root';
import { CommunitySingleUnits as PortfolioCommunitySingleUnits } from './company/entity/communities/entity/single-units/root';
import { Communities as RentalCommunities } from './company/entity/rentals/communities/root';
import { Companies as AllCompanies } from './companies/root';
import { AllRentals } from './allrentals/root';
import { AllLeads } from './allleads/root';
import { Company } from './company/root';
import { useCurrentCompany, usePermissions } from '@/shared/hooks';
import { Loader } from '@/components';
import { Employees } from './settings/entity/employees/root';
import { Permissions } from '@/shared/hooks/usePermissions';
import { AdminDashboard } from './admindashboard/root';
import { Integrations } from './company/entity/settings/integrations';
import { Ranking } from './settings/entity/ranking/root';
import { Amenities } from './settings/entity/amenities/root';
import { AmenitiesTable } from './settings/entity/amenities/root/AmenitiesTable';

type ContentRoutes = RouteProps & {
  showRoute?: boolean;
};

type GetRoutesProps = {
  permissions: Permissions;
  companyType: string;
};

const filterRoutes = (routes: ContentRoutes[]): RouteProps[] =>
  routes.filter(({ showRoute = true }) => showRoute).map(({ showRoute, ...route }) => route);

const getRoutes = ({ permissions, companyType }: GetRoutesProps): RouteProps[] => {
  const { isAdmin, isSuperAdmin, isCompanyAdmin, isCompanyManager, isCompanyUser } = permissions;

  const isMultiFamily = companyType === COMPANY_TYPES.mdu;

  return filterRoutes([
    {
      exact: false,
      path: '/management/:companyId/dashboard',
      component: Dashboard,
      showRoute: isAdmin || isCompanyAdmin || isCompanyManager || isCompanyUser,
    },
    {
      exact: true,
      path: '/management/allrentals',
      component: AllRentals,
      showRoute: isAdmin,
    },
    {
      exact: true,
      path: '/management/allleads',
      component: AllLeads,
      showRoute: isAdmin,
    },
    {
      exact: true,
      path: '/management/dashboard',
      component: AdminDashboard,
      showRoute: isAdmin,
    },
    {
      exact: true,
      path: '/management/companies',
      component: AllCompanies,
      showRoute: isAdmin,
    },
    {
      exact: true,
      path: '/management/:companyId',
      component: Company,
      showRoute: isAdmin,
    },
    {
      exact: true,
      path: '/management/settings/employees',
      component: Employees,
      showRoute: isAdmin,
    },
    {
      exact: true,
      path: '/management/settings/account',
      component: Account,
      showRoute: isAdmin,
    },
    {
      exact: true,
      path: '/management/settings/ranking',
      component: Ranking,
      showRoute: isSuperAdmin,
    },
    {
      exact: true,
      path: '/management/settings/amenities',
      component: Amenities,
      showRoute: isAdmin,
    },
    {
      exact: true,
      path: '/management/settings/amenities/:amenityKey',
      component: AmenitiesTable,
      showRoute: isAdmin,
    },
    {
      exact: true,
      path: '/management/:companyId/settings/team',
      component: Team,
      showRoute: isAdmin || isCompanyAdmin || isCompanyManager,
    },
    {
      exact: true,
      path: '/management/:companyId/settings/integrations',
      component: Integrations,
      showRoute: isAdmin || isCompanyAdmin || isCompanyManager || isCompanyUser,
    },
    {
      exact: true,
      path: '/management/:companyId/communities',
      component: PortfolioCommunities,
      showRoute: isMultiFamily,
    },
    {
      exact: true,
      path: '/management/:companyId/communities/details/:communityId',
      component: CommunityCard,
      showRoute: isMultiFamily,
    },
    {
      exact: true,
      path: '/management/:companyId/communities/:communityId/single-units',
      component: PortfolioCommunitySingleUnits,
      showRoute: isMultiFamily,
    },
    {
      exact: true,
      path: '/management/:companyId/rentals/single-units/:offerId',
      component: SingleUnit,
      showRoute: isAdmin || isCompanyAdmin || isCompanyManager || isCompanyUser,
    },
    {
      exact: true,
      path: '/management/:companyId/rentals/single-units/:offerId/details',
      component: SingleUnit,
      showRoute: isAdmin || isCompanyAdmin || isCompanyManager || isCompanyUser,
    },
    {
      exact: true,
      path: '/management/:companyId/rentals/single-units/:offerId/renters',
      component: SingleUnit,
      showRoute: isAdmin || isCompanyAdmin || isCompanyManager || isCompanyUser,
    },
    {
      exact: true,
      path: '/management/:companyId/communities/:communityId/single-units/:singleUnitId/details',
      component: SingleUnit,
      showRoute: isMultiFamily,
    },
    {
      exact: true,
      path: '/management/:companyId/communities/:communityId/single-units/:singleUnitId/renters',
      component: SingleUnit,
      showRoute: isMultiFamily,
    },
    {
      exact: true,
      path: '/management/:companyId/rentals/communities',
      component: RentalCommunities,
      showRoute: isMultiFamily,
    },

    {
      exact: true,
      path: '/management/:companyId/profile',
      component: CompanyProfile,
      showRoute: isAdmin || isCompanyAdmin || isCompanyManager,
    },
    {
      exact: true,
      path: '/management/:companyId/single-units',
      component: PortfolioSingleUnits,
      showRoute: isAdmin || isCompanyAdmin || isCompanyManager || isCompanyUser,
    },
    {
      exact: true,
      path: '/management/:companyId/single-units/:singleUnitId/details',
      component: SingleUnit,
      showRoute: isAdmin || isCompanyAdmin || isCompanyManager || isCompanyUser,
    },
    {
      exact: true,
      path: '/management/:companyId/single-units/:singleUnitId/renters',
      component: SingleUnit,
      showRoute: isAdmin || isCompanyAdmin || isCompanyManager || isCompanyUser,
    },
    {
      exact: true,
      path: '/management/:companyId/rentals/single-units',
      component: RentalSingleUnits,
      showRoute: isAdmin || isCompanyAdmin || isCompanyManager || isCompanyUser,
    },
    {
      exact: true,
      path: '/management/:companyId/leads',
      component: Leads,
      showRoute: isAdmin || isCompanyAdmin || isCompanyManager || isCompanyUser,
    },
    {
      exact: true,
      path: '/management/:companyId/settings/account',
      component: Account,
      showRoute: isAdmin || isCompanyAdmin || isCompanyManager || isCompanyUser,
    },
  ]);
};

export const Management = () => {
  const permissions = usePermissions();
  const { isAdmin } = permissions;
  const { companyId } = useParams<{ companyId: string }>();
  const { company, loading: companyLoading } = useCurrentCompany();

  const routes = React.useMemo(() => {
    return getRoutes({ permissions, companyType: company?.type ?? '' });
  }, [company, permissions]);

  if (companyLoading) {
    return (
      <ManagementLayout>
        <Loader stretch />
      </ManagementLayout>
    );
  }

  if (!isAdmin && company?.id !== companyId) {
    return <Redirect to={`/management/${company?.id}/dashboard`} />;
  }

  return (
    <ManagementLayout>
      <Switch>
        {routes.map((routeConfig, i) => (
          <Route {...routeConfig} key={i} />
        ))}
      </Switch>
    </ManagementLayout>
  );
};
