import { toast } from 'react-toastify';

export const westernReportingXMLResponseHasNoErrors = (xml: string) => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xml, 'text/xml');
  const responseErrors = xmlDoc?.querySelectorAll('ErrorReport');
  if (responseErrors?.length !== 0) {
    responseErrors?.forEach(err => {
      const errorMessage = err.querySelector('ErrorDescription');
      toast.error('Western Reporting: ' + errorMessage?.innerHTML);
    });
    return false;
  } else {
    return true;
  }
};
