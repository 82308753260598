import React from 'react';
import pluralize from 'pluralize';

import { styled } from '@/theme';
import { formatAmount, stringifyDate } from '@/shared/utils';
import { EntityOption } from '@/pro';
import { Offer, Bid } from '@/shared/types/graphql';
import { ColumnFlex, Responsive, Timer } from '@/components';
import { setSmStyles, Size } from '@/components/TimeLine/styled';
import { OFFER_STATUSES } from '@/shared/constants';
import { css } from '@emotion/core';

const TimerGridContainer = styled.div<{ size: Size }>`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 8px;

  ${setSmStyles(`
    grid-template-columns: 1fr;
  `)}
`;

const InfoGridContainer = styled.div<{ size: Size }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;

  @media (max-width: ${props => props.theme.vars.breakpoints.sm.max}) {
    grid-template-columns: 1fr;
    grid-gap: 15px;
  }
`;

const Box = styled.div``;

const TitleTag = styled.div<{ size: Size }>`
  width: 100px;

  ${setSmStyles(`
    width: auto;
  `)}
`;

const TimerTag = styled.div<{ size: Size }>``;

type BidOfferInfoProps = {
  bids: Bid[];
  bid: Bid;
  offer?: Offer;
  userBidPlace: number;
  activeItemId: number;
  size: Size;
  isUserRejected: boolean;
  actions?: (args: {
    userBidPlace: number;
    activeItemId: number;
    bids: Bid[];
    bid: Bid;
    offer?: Offer;
  }) => React.ReactNode;
};

export const StepTitle = styled.span`
  color: ${props => props.theme.vars.green};
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1;
`;

export const BidOfferInfo = ({
  size,
  bid,
  bids,
  isUserRejected,
  offer,
  userBidPlace,
  activeItemId,
  actions,
}: BidOfferInfoProps) => {
  const isOwner = userBidPlace === activeItemId || (activeItemId === 4 && userBidPlace > 3);

  const showTimer = offer?.status === OFFER_STATUSES.live;

  return (
    <ColumnFlex
      gap="md"
      align="stretch"
      css={css`
        max-width: 360px;
      `}
    >
      <ColumnFlex align="stretch" css={{ marginBottom: 15 }}>
        <TimerGridContainer size={size}>
          <If condition={!isUserRejected}>
            <TitleTag size={size}>
              <StepTitle>{isOwner ? 'Your offer' : 'Beat or match this offer'}</StepTitle>
            </TitleTag>
          </If>
          <If condition={showTimer}>
            <TimerTag size={size}>
              <Timer endsDate={offer?.endsIn} />
            </TimerTag>
          </If>
        </TimerGridContainer>
        <InfoGridContainer size={size}>
          <Box>
            <EntityOption label="Rent amount" value={`$${formatAmount(bid?.rentAmount)}/mo`} />
          </Box>
          <Box>
            <EntityOption label="Move-In Date" value={stringifyDate(bid?.moveinDate)} />
          </Box>
          <Box>
            <EntityOption
              label="Preferred Terms"
              value={bid?.termsOfLease && bid?.termsOfLease + ' ' + pluralize('month', bid?.termsOfLease)}
            />
          </Box>
          <Box>
            <EntityOption
              label="Security Deposit"
              value={`$${formatAmount(bid?.securityDeposite || offer?.securityDeposite)}`}
            />
          </Box>
        </InfoGridContainer>
        <Responsive.HideOn breakpoints={['xs', 'sm']}>
          {actions && actions({ userBidPlace, activeItemId, bids, bid, offer })}
        </Responsive.HideOn>
      </ColumnFlex>
    </ColumnFlex>
  );
};
