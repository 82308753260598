import React, { ChangeEvent } from 'react';

import * as R from 'ramda';
import { Form, Field } from '8base-react-sdk';
import { Dropdown, Input } from 'antd';

import { ButtonLink, CustomCard, Grid, Heading, SelectField, RowFlex, Tag } from '@/components';

import { FilterIcon } from '@/static/icons';
import { useFilters, useSearch } from '@/shared/hooks';
import { OFFER_STATUS_LABELS } from '@/shared/constants';
import { FiltersType, AllRentalsTable } from './AllRentalsTable';

const STATUS_FILTER_OPTIONS = R.keys(OFFER_STATUS_LABELS).map(offerStatusValue => ({
  value: offerStatusValue,
  label: OFFER_STATUS_LABELS[offerStatusValue],
}));

export const AllRentals = () => {
  const [{ search, throttledSearch }, setSearch] = useSearch();
  const [filters, { setAllFilters, removeFilter }] = useFilters<FiltersType>();
  const [isFilterVisible, changeFilterVisible] = React.useState(false);

  const onChange = React.useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearch(event.currentTarget.value);
    },
    [setSearch],
  );

  const header = (
    <Grid.Layout gap="20px" columns="auto 400px 1fr" alignItems="center" justifyItems="flex-start" stretch>
      <Heading type="h1">Livo Transactions</Heading>
      <Input
        value={search}
        placeholder="Search by Client/Property name, Address, Livo transaction #"
        onChange={onChange}
      />

      <Grid.Layout gap="16px" columns="1fr auto" justifySelf="flex-end">
        <RowFlex>
          <Tag visible={!!filters.status} closable onClose={() => removeFilter('status')}>
            {`Offer status: ${OFFER_STATUS_LABELS[filters.status]}`}
          </Tag>
        </RowFlex>
        <Form onSubmit={setAllFilters} initialValues={filters}>
          {({ handleSubmit, form }) => (
            <Dropdown
              trigger={['click']}
              visible={isFilterVisible}
              onVisibleChange={changeFilterVisible}
              overlay={
                <CustomCard
                  padding="sm"
                  width="330px"
                  footer={
                    <RowFlex stretch justify="end" gap="md">
                      <ButtonLink
                        color="default"
                        onClick={() => {
                          changeFilterVisible(false);
                          form.reset();
                        }}
                      >
                        Cancel
                      </ButtonLink>
                      <ButtonLink
                        onClick={async () => {
                          await handleSubmit();
                          changeFilterVisible(false);
                          form.reset();
                        }}
                      >
                        Apply filters
                      </ButtonLink>
                    </RowFlex>
                  }
                >
                  <Grid.Layout gap="20px">
                    <Grid.Box>
                      <Field
                        stretch
                        label="Offer status"
                        name="status"
                        placeholder="Filter by"
                        options={STATUS_FILTER_OPTIONS}
                        component={SelectField}
                      />
                    </Grid.Box>
                  </Grid.Layout>
                </CustomCard>
              }
            >
              <ButtonLink
                text="Filters"
                icon={<FilterIcon css={{ fill: !!filters.status ? 'currentColor' : 'none' }} />}
              />
            </Dropdown>
          )}
        </Form>
      </Grid.Layout>
    </Grid.Layout>
  );

  return (
    <CustomCard padding="none" header={header} data-e2e-id="rentals.singleUnits">
      <AllRentalsTable query={throttledSearch} filters={filters} />
    </CustomCard>
  );
};
