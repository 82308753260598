import React from 'react';

import { ManagementLayoutProvider } from '@/providers';
import { Layout } from './Layout';

interface ManagementLayoutProps {
  children: React.ReactChild;
}
export const ManagementLayout: React.FC<ManagementLayoutProps> = ({ children }) => {
  return (
    <ManagementLayoutProvider>
      <Layout>{children}</Layout>
    </ManagementLayoutProvider>
  );
};
