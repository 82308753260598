import styled from '@/theme/styled';

export const BiddersListContainer = styled.div`
  border-left: 1px solid #e8eff5;
`;

export const BidderContainer = styled.div<{ isFirst?: boolean }>`
  display: grid;
  grid-template-columns: minmax(auto, 350px) 1fr;
  grid-gap: 47px;
  padding-left: 19px;

  &:first-child {
    border-bottom: 1px solid #e8eff5;
    padding-top: 24px;
    padding-bottom: 24px;
    margin-bottom: 14px;
  }

  &:not(:first-child) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

export const BidderAvatarContainer = styled.div`
  grid-row: 1/-1;
  margin-top: 5px;
`;
export const BidderInformation = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 10px;
  grid-template-rows: 23px 16px 16px;
`;

export const BidderNameAndAmount = styled.div<{ isFirst?: boolean }>`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  font-size: ${({ isFirst }) => (isFirst ? '16px' : '13px')};

  .ant-btn {
    font-size: ${({ isFirst }) => (isFirst ? '16px' : '13px')};
  }
`;

export const BidderLine = styled.div`
  position: relative;
  margin-left: 20px;
  margin-right: 20px;
  border-top: 1px dashed #d1d1d5;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: calc(50% - 5px);
    width: 10px;
    height: 10px;
    border: 1px solid #d1d1d5;
    border-radius: 50%;
  }

  &::before {
    right: 100%;
  }

  &::after {
    left: 100%;
  }
`;

export const BidderAmount = styled.div``;

export const InformationRow = styled.div`
  color: #939ea7;
  font-size: 1.2rem;
  line-height: 1.8rem;
  text-align: right;
  display: flex;
  justify-content: flex-end;
`;

export const SecureNowInformationText = styled.span`
  color: #3ac430;
  margin-right: auto;
`;

export const TimerInformationText = styled.span`
  color: #3ac430;
  margin-right: auto;
  display: block;
`;

export const InformationTextContainer = styled.div`
  margin-right: auto;
  display: block;
  text-align: left;
`;
