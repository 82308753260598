import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

export const Link = ({ children, href, ...rest }: any) => {
  if (href) {
    return (
      <a className="ant-dropdown-link" href={href} {...rest}>
        {children}
      </a>
    );
  }
  return (
    <RouterLink className="ant-dropdown-link" {...rest}>
      {children}
    </RouterLink>
  );
};
