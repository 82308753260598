import React from 'react';
import { Empty as AntdEmpty } from 'antd';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

const EmptyContainer = styled.div<{ stretch?: boolean }>`
  ${({ stretch }) =>
    stretch &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    `}
`;

type EmptyProps = {
  description?: string;
  stretch?: boolean;
};

export const Empty = ({ description, stretch, ...rest }: EmptyProps) => {
  return (
    <EmptyContainer {...rest} stretch={stretch}>
      <AntdEmpty image={AntdEmpty.PRESENTED_IMAGE_SIMPLE} description={description} />
    </EmptyContainer>
  );
};
