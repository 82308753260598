import React from 'react';
import { Button, Result } from 'antd';
import { Form, Field } from '8base-react-sdk';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { toast } from 'react-toastify';

import { Grid, InputField, Loader } from '@/components';
import { validateEntityDataByDraft } from '@/shared/utils';
import { westernReportingSchemaValidation } from '@/schemaValidators/integrations/westernReporting';
import { useCurrentCompanyId, useMutationSafe } from '@/shared/hooks';

export const WesternReporting = () => {
  const WR_INTEGRATION_QUERY = gql`
    query CompanyWRInfo($id: ID!) {
      company(id: $id) {
        id
        companyWesternReportingAccount {
          id
          isUsingWesternReporting
          password
          login
        }
      }
    }
  `;

  const COMPANY_CREATE_WR_ACC = gql`
    mutation CompanyWesternReportingAccountCreate($data: CompanyWesternReportingAccountCreateInput!) {
      companyWesternReportingAccountCreate(data: $data) {
        id
      }
    }
  `;

  const COMPANY_DELETE_WR_ACC = gql`
    mutation CompanyWesternReportingAccountDelete($filter: CompanyWesternReportingAccountKeyFilter) {
      companyWesternReportingAccountDelete(filter: $filter) {
        success
      }
    }
  `;

  const CHECK_WR_ACCOUNT = gql`
    mutation checkWesternReportingAccount($data: CheckWesternReportingAccountInput!) {
      checkWesternReportingAccount(data: $data) {
        result
      }
    }
  `;

  const { companyId } = useCurrentCompanyId();

  const { data: companyData, loading: companyDataLoading } = useQuery(WR_INTEGRATION_QUERY, {
    variables: {
      id: companyId,
    },
  });

  const [companyWesternReportingAccountCreate, { loading: companyWesternReportingAccountLoading }] = useMutationSafe(
    COMPANY_CREATE_WR_ACC,
    {
      awaitRefetchQueries: true,
      onCompleted: () => {
        toast.success('Integration was successfully connected');
      },
      refetchQueries: ['CompanyWRInfo'],
    },
  );

  const [companyWesternReportingAccountDelete, { loading: companyWesternReportingDeleteLoading }] = useMutationSafe(
    COMPANY_DELETE_WR_ACC,
    {
      awaitRefetchQueries: true,
      onCompleted: () => {
        toast.success('Integration was successfully disconnected');
      },
      refetchQueries: ['CompanyWRInfo'],
    },
  );

  const companyWRAccountResponse = companyData?.company?.companyWesternReportingAccount;
  const isUsingWR = companyWRAccountResponse?.isUsingWesternReporting || false;

  const onSuccessLogin = async (data: any) => {
    await companyWesternReportingAccountCreate({
      variables: {
        data: {
          isUsingWesternReporting: true,
          login: data?.login,
          password: data?.password,
          packageType: data?.packageType,
          company: {
            connect: {
              id: companyId,
            },
          },
        },
      },
    });
  };

  const onDisconnect = async () => {
    await companyWesternReportingAccountDelete({
      variables: {
        filter: {
          id: companyWRAccountResponse?.id,
        },
      },
    });
  };

  const [checkWesternReportingAccount] = useMutationSafe(CHECK_WR_ACCOUNT);

  const onSubmit = async (accData: any) => {
    const xmlData = await checkWesternReportingAccount({
      variables: {
        data: accData,
      },
    });
    return parseXML(accData, xmlData);
  };

  const parseXML = (accData: { login: string; password: string }, xmlData: any) => {
    const parser = new DOMParser();
    if (xmlData) {
      const xmlDoc = parser.parseFromString(xmlData?.checkWesternReportingAccount?.result, 'text/xml');
      const responseErrors = xmlDoc?.querySelectorAll('ErrorReport');
      if (responseErrors?.length !== 0) {
        responseErrors?.forEach(err => {
          const errorMessage = err.querySelector('ErrorDescription');
          toast.error('Western Reporting: ' + errorMessage?.innerHTML);
        });
      } else {
        onSuccessLogin(accData);
      }
    } else toast.error('Something went wrong, try again');
  };

  if (companyDataLoading) {
    return <Loader />;
  }

  return (
    <>
      <If condition={!companyWRAccountResponse && !isUsingWR}>
        <Grid.Layout
          gap="20px"
          columns="1fr"
          alignItems="center"
          css={{ maxWidth: '640px', width: '80%', margin: 'auto' }}
        >
          <Grid.Box alignItems="center">
            <img
              src="https://428n70o3lpj28btdq22brf3r-wpengine.netdna-ssl.com/wp-content/themes/western-reporting-2018/images/western-reporting-inc.png"
              alt="Western Reporting"
              css={{ maxWidth: '100%', width: '235px', height: '55px' }}
            />
          </Grid.Box>
          <Grid.Box alignItems="center">
            <span>You are not connected</span>
          </Grid.Box>
          <Grid.Box alignItems="center">
            <span>
              Use <b>User ID</b>, <b>User Password</b> and <b>Package Type</b> from your Western Reporting account
            </span>
          </Grid.Box>
          <Grid.Box>
            <Form
              type="CREATE"
              onSubmit={onSubmit}
              validate={values => validateEntityDataByDraft(westernReportingSchemaValidation, values)}
            >
              {({ handleSubmit, submitting }) => {
                return (
                  <Grid.Layout gap="25px" columns="1fr" alignItems="center">
                    <Grid.Box>
                      <Field label="XML User ID*" name="login" component={InputField} text="Login" />
                    </Grid.Box>
                    <Grid.Box>
                      <Field
                        label="XML User Password*"
                        name="password"
                        component={InputField}
                        inputType="password"
                        text="Password"
                      />
                    </Grid.Box>
                    <Grid.Box>
                      <Field
                        name="packageType"
                        label="Package Type"
                        component={InputField}
                        placeholder="e.g. CREDIT/MULTISTATE EVIC + OPT RES VER/EMP VER"
                      />
                    </Grid.Box>
                    <Grid.Box>
                      <Button
                        size="large"
                        loading={submitting}
                        disabled={submitting || companyWesternReportingAccountLoading}
                        type="primary"
                        onClick={event => handleSubmit(event)}
                        htmlType="submit"
                      >
                        Connect
                      </Button>
                    </Grid.Box>
                  </Grid.Layout>
                );
              }}
            </Form>
          </Grid.Box>
        </Grid.Layout>
      </If>
      <If condition={companyWRAccountResponse && isUsingWR}>
        <Grid.Layout>
          <Grid.Box alignItems="center">
            <img
              src="https://428n70o3lpj28btdq22brf3r-wpengine.netdna-ssl.com/wp-content/themes/western-reporting-2018/images/western-reporting-inc.png"
              alt="Western Reporting"
              css={{ maxWidth: '100%', width: '235px', height: '55px' }}
            />
          </Grid.Box>
          <Grid.Box>
            <Result
              status="success"
              title="Connected"
              extra={
                <Button
                  type="danger"
                  size="large"
                  loading={companyWesternReportingDeleteLoading}
                  onClick={onDisconnect}
                  disabled={companyWesternReportingDeleteLoading}
                >
                  Disconnect
                </Button>
              }
            />
          </Grid.Box>
        </Grid.Layout>
      </If>
    </>
  );
};
