import { DRAFT_STATUS } from './common';

export const COMMUNITY_STATUS_LABELS = {
  draft: 'Draft',
  active: 'Active',
};

export const COMMUNITY_STATUSES: Record<keyof typeof COMMUNITY_STATUS_LABELS, keyof typeof COMMUNITY_STATUS_LABELS> = {
  draft: DRAFT_STATUS,
  active: 'active',
};

export const COMMUNITY_TYPE_LABELS = {
  classA: 'Class A',
  classB: 'Class B',
  classC: 'Class C',
  commercial: 'Commercial',
  affordableHousing: 'Affordable Housing',
  section8: 'Section 8',
  luxury: 'Luxury',
  other: 'Other',
};

export type CommunityTypes = keyof typeof COMMUNITY_TYPE_LABELS;

export const COMMUNITY_TYPES: Record<string, CommunityTypes> = {
  classA: 'classA',
  classB: 'classB',
  classC: 'classC',
  commercial: 'commercial',
  affordableHousing: 'affordableHousing',
  section8: 'section8',
  luxury: 'luxury',
  other: 'other',
};
