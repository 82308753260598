import React from 'react';
import { useQuery } from 'react-apollo';
import { css } from '@emotion/core';
import { Button } from 'antd';

import { Loader, CustomCard } from '@/components';
import { dialogsContext } from '@/providers';
import { COMPANY_QUERY } from '@/shared/graphql';
import { Company as CompanyType } from '@/shared/types/graphql';
import { useCurrentCompanyId } from '@/shared/hooks';
import Text from 'antd/es/typography/Text';
import { CompanyProfileInfo } from '@/pro/CompanyProfileInfo';
import { COMPANY_UPDATE_DIALOG } from '@/dialogs';

export const Company = () => {
  const { openDialog } = React.useContext(dialogsContext);
  const { companyId } = useCurrentCompanyId();

  const { loading, data } = useQuery<{ company: CompanyType }>(COMPANY_QUERY, {
    variables: {
      id: companyId,
    },
  });

  if (loading) {
    return <Loader />;
  }

  const company = data?.company;
  const id = company?.clientId ?? '';
  const name = company?.name ?? '';

  return (
    <CustomCard
      header={
        <>
          <h1
            css={css`
              font-size: 1.8rem;
              font-weight: 400;
              letter-spacing: 0.5px;
              color: #323c47;
            `}
          >
            {name}
            <Text type="secondary"> ({id})</Text>
          </h1>
        </>
      }
      footer={
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            width: 100%;
          `}
        >
          <Button
            type="primary"
            size="large"
            onClick={() => {
              openDialog({
                name: COMPANY_UPDATE_DIALOG,
                props: { closable: true, companyId },
              });
            }}
          >
            Edit Client Information
          </Button>
        </div>
      }
      css={css`
        .ant-avatar {
          border: 2px solid #f37a22;
        }
      `}
    >
      <CompanyProfileInfo company={company} />
    </CustomCard>
  );
};
