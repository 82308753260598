import { styled } from '@/theme';

export const DropzoneContainer = styled.div`
  height: 400px;
  padding: 33px 24px;
`;

export const PreviewContainer = styled.div`
  height: 491px;
  border-left: 1px solid #e8e8e8;
  margin-bottom: 44px;
`;

export const PreviewSettings = styled.div`
  padding: 33px 24px 0 24px;
`;

export const DropArea = styled.div`
  position: relative;
  height: 160px;
  border: 2px dashed #e9eff4;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const FileInput = styled.input`
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
`;
