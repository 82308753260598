import React from 'react';
import moment from 'moment';
import { Modal } from 'antd';
import { toast } from 'react-toastify';
import { Form } from '8base-react-sdk';
import { DateTime } from 'luxon';

import { TERMS_OF_LEASE_MIN_AMOUNT, MINIMUM_LEASE_LENGTH, MAXIMUM_LEASE_LENGTH } from '@/shared/constants';
import { TIMEZONE_OPTIONS } from 'livo-shared';
import { Maybe, OfferCreateInput } from '@/shared/types/graphql';
import { useMutationSafe } from '@/shared/hooks';

import { CREATE_OFFER_SINGLE_UNIT_MUTATION } from './queries';
import { validateEntityDataByDraft } from '@/shared/utils';
import { OfferSchemaValidation } from '@/schemaValidators';
import { Footer, ModalBody } from '../components';
import { useDataSingleUnit } from '../helpers';
import * as R from 'ramda';

const initialDatetime = DateTime.utc()
  .setZone(TIMEZONE_OPTIONS[0].value)
  .toString();

const initialMoveinDateDatetime = DateTime.utc()
  .setZone(TIMEZONE_OPTIONS[0].value)
  .plus({ days: 1 })
  .toString();

const INITIAL_VALUES = {
  secureNow: false,
  termsOfLease: TERMS_OF_LEASE_MIN_AMOUNT,
  minimumLeaseLength: MINIMUM_LEASE_LENGTH,
  maximumLeaseLength: MAXIMUM_LEASE_LENGTH,
  securityDepositeEditable: false,
  moveinDateEditable: true,
  timezone: TIMEZONE_OPTIONS[0].value,
  startImmediate: true,
  endsIn: initialDatetime,
  moveinDate: initialMoveinDateDatetime,
  contact: '',
  calendarLink: '',
  reenableOffer: true,
  timeframeToSignContract: 5,
  livoManagesScreening: true,
};

interface DialogProps {
  closeWithConfirm: () => void;
  close: () => void;
  isVisible: boolean;
  refetchQueries?: Array<string>;
  singleUnitId?: Maybe<string>;
}

const prepareData = (data: OfferCreateInput) => {
  const res = R.clone(data);

  if (res.moveinDate) {
    res.moveinDate = moment(res.moveinDate).format('YYYY-MM-DD');
  }

  if (res.startImmediate) {
    delete res.startsIn;
  }

  if (!res.secureNow) {
    res.secureNowAmount = null;
  }

  if (res?.contact?.connect?.id) {
    const [userId] = res.contact.connect.id.split(',');
    res.contact.connect.id = userId;
  } else {
    delete res.contact;
  }

  return res;
};

export const Dialog: React.FC<DialogProps> = ({ close, closeWithConfirm, isVisible, ...props }) => {
  const singleUnitId = props?.singleUnitId;
  const refetchQueries = props?.refetchQueries;

  const [offerCreate] = useMutationSafe(CREATE_OFFER_SINGLE_UNIT_MUTATION, {
    refetchQueries,
    awaitRefetchQueries: true,
    onCompleted: () => {
      close();
      toast.success('Successfully created');
    },
  });

  const { singleUnit, singleUnitLoading } = useDataSingleUnit({ singleUnitId });

  const onSubmit = React.useCallback(
    async (data: OfferCreateInput) => {
      const preparedData = prepareData(data);

      await offerCreate({
        variables: {
          data: {
            ...preparedData,
            activeOfferSingleUnit: {
              connect: {
                id: singleUnitId,
              },
            },
            historyOfferSingleUnit: {
              connect: {
                id: singleUnitId,
              },
            },
          },
        },
      });
    },
    [offerCreate, singleUnitId],
  );

  return (
    <Form
      type="CREATE"
      tableSchemaName="Offer"
      onSubmit={onSubmit}
      initialValues={INITIAL_VALUES}
      validate={values => {
        return validateEntityDataByDraft(OfferSchemaValidation, values);
      }}
    >
      {({ handleSubmit, form, pristine, values, submitting }) => {
        return (
          <Modal
            title="Enable Livo Transaction"
            className="livo-modal"
            visible={isVisible}
            onCancel={pristine ? close : closeWithConfirm}
            width="635px"
            afterClose={form.reset}
            centered
            footer={
              <Footer
                form={form}
                close={close}
                closeWithConfirm={closeWithConfirm}
                handleSubmit={handleSubmit}
                pristine={pristine}
                submitting={submitting}
              />
            }
          >
            <ModalBody loading={singleUnitLoading} values={values} form={form} singleUnit={singleUnit} />
          </Modal>
        );
      }}
    </Form>
  );
};
