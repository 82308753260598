import { TableState } from '@/shared/hooks';

import { Company, CompanyListResponse, OfferListResponse } from '@/shared/types/graphql';

import { COMPANY_STATUSES } from '@/shared/constants';

import { useQuery } from 'react-apollo';
import * as R from 'ramda';
import { ADMIN_DASHBOARD_COMPANIES_QUERY, DASHBOARD_OFFERS_BY_COMPANY_QUERY } from './queries';
import { CompanyStatuses, CompanyTypes } from '@/shared/constants/company';

export type FiltersType = {
  type: CompanyTypes;
  status: CompanyStatuses;
};

export const getSort = (queryParams: TableState) => {
  let sort: Record<string, any>[] = [];

  if (queryParams.sortBy && queryParams.sortOrder) {
    const field = queryParams.sortBy;
    const direction = queryParams.sortOrder === 'ascend' ? 'ASC' : 'DESC';

    if (field === 'address') {
      sort = [
        {
          address: {
            street1: direction,
          },
        },
      ];
    } else if (field) {
      sort = [
        {
          [field]: direction,
        },
      ];
    }
  }

  return sort;
};

export const getQueryVariablesCompanyList = (query: string, filters: FiltersType, tableState: TableState) => {
  const { page, sortBy, sortOrder, pageSize } = tableState;

  const filterQueryAND = {
    type: {
      equals: filters.type,
    },
    status: {
      equals: filters.status,
    },
    isBlocked: {
      equals: false,
    },
  };

  const blockedStatusQueryAND = {
    type: {
      equals: filters.type,
    },
    isBlocked: {
      equals: true,
    },
  };

  return {
    filter: {
      AND: [
        filters.status === COMPANY_STATUSES.blocked ? blockedStatusQueryAND : filterQueryAND,
        query !== ''
          ? {
              name: {
                contains: query,
              },
            }
          : {},
      ],
    },
    sort: getSort({ sortOrder, sortBy, page, pageSize }),
    first: pageSize,
    skip: (page - 1) * pageSize,
  };
};

export const useCompaniesList = ({ query, filters, sortOrder, sortBy, page, pageSize }: any) => {
  const { data, loading } = useQuery<{ companiesList: CompanyListResponse }>(ADMIN_DASHBOARD_COMPANIES_QUERY, {
    variables: getQueryVariablesCompanyList(query, filters, { page, sortBy, sortOrder, pageSize }),
  });

  const companiesList: Company[] = R.pathOr([], ['companiesList', 'items'], data);
  const companiesListCount = R.pathOr(0, ['companiesList', 'count'], data);

  return { companiesList, companiesListCount, loadingCompaniesList: loading };
};

export const useOffersList = ({ companiesList }: { companiesList: Company[] }) => {
  const { data, loading } = useQuery<{ offersList: OfferListResponse }>(DASHBOARD_OFFERS_BY_COMPANY_QUERY, {
    variables: {
      filter: {
        AND: [
          {
            activeOfferSingleUnit: {
              company: {
                id: {
                  in: companiesList.map(community => community?.id),
                },
              },
            },
          },
          { OR: [{ status: { equals: 'live' } }, { status: { equals: 'pending' } }] },
        ],
      },
      skip: R.isEmpty(companiesList),
    },
  });

  const offersGroups: Array<any> = R.pathOr([], ['offersList', 'groups'], data);

  return { offersGroups, loadingOfferList: loading };
};
