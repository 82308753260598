export const createFilePreviewLink = (fileUrl = '', options: Record<string, any> = {}) => {
  const url = new URL(fileUrl);

  /*
    coerce this
    {
      first: { second: 1, third: 2 },
      fourth: { fifth: 3, sixth: 4 },
    }
    to this
    'first=second:1,third:2/fourth=fifth:3,sixth:4
  */
  const optionsToPathname = Object.entries(options)
    .map(
      ([optionKey, optionValues]) =>
        `${optionKey}=${Object.entries(optionValues)
          .map(([key, value]) => `${key}:${value}`)
          .join(',')}`,
    )
    .join('/');

  url.pathname = optionsToPathname + url.pathname;

  return url.toString();
};
