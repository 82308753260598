import * as yup from 'yup';

export const westernReportingSchemaValidation = yup
  .object({
    login: yup
      .string()
      .nullable()
      .required('User ID is required'),

    password: yup
      .string()
      .nullable()
      .required('Password is required'),

    packageType: yup
      .string()
      .nullable()
      .required('Package Type is required'),
  })
  .nullable()
  .required();
