import gql from 'graphql-tag';

export const INVITATIONS_COMMUNITIES_QUERY = gql`
  query InvitationsCommunitiesList($first: Int, $skip: Int, $filter: CommunityFilter, $sort: [CommunitySort!]) {
    communitiesList(first: $first, skip: $skip, filter: $filter, sort: $sort) {
      items {
        id
        name
      }
    }
  }
`;

export const INVITATIONS_SINGLE_UNITS_QUERY = gql`
  query InvitationsSingleUnitsList($first: Int, $skip: Int, $filter: SingleUnitFilter, $sort: [SingleUnitSort!]) {
    singleUnitsList(first: $first, skip: $skip, filter: $filter, sort: $sort) {
      items {
        id
        name
      }
    }
  }
`;

export const USERS_LIST_QUERY = gql`
  query UsersList($first: Int, $skip: Int, $filter: UserFilter, $sort: [UserSort!]) {
    usersList(first: $first, skip: $skip, filter: $filter, sort: $sort) {
      items {
        id
        firstName
        lastName
        email
        status
        roles {
          items {
            id
            name
          }
        }
        invitation {
          id
        }
        company {
          id
          type
        }
        communityManager {
          items {
            id
            name
          }
        }
        singleUnitAgent {
          items {
            id
            name
          }
        }
      }
      count
    }
  }
`;

export const INVITATION_SEND_MUTATION = gql`
  mutation InvitationSendSecure($user: UserCreateInput!, $invitationRole: String!) {
    invitationSendSecure(user: $user, invitationRole: $invitationRole) {
      invitationId
    }
  }
`;

export const ADD_TEAM_MEMBER_MUTATION = gql`
  mutation AddTeamMember($event: EventResolverInput!) {
    eventResolver(event: $event) {
      success
    }
  }
`;

export const INVITATION_RESEND_MUTATION = gql`
  mutation InvitationResendSecure($id: ID!) {
    invitationResendSecure(id: $id) {
      success
    }
  }
`;

export const INVITATION_CANCEL_MUTATION = gql`
  mutation InvitationCancelSecure($id: ID!) {
    invitationCancelSecure(id: $id) {
      success
    }
  }
`;
