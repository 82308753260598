import React from 'react';
import { useDialogState } from '@/shared/hooks';
import { Dialog } from './Dialog';

export const CLOSING_CONFIRM_DIALOG = 'CLOSING_CONFIRM_DIALOG';

export const ClosingConfirmDialog = () => {
  const { close, isVisible, props } = useDialogState(CLOSING_CONFIRM_DIALOG);

  if (!isVisible) {
    return null;
  }

  return <Dialog {...props} close={close} isVisible={isVisible} />;
};
