import gql from 'graphql-tag';

export const SINGLE_UNIT_NAME = gql`
  query SingleUnitName($id: ID!) {
    singleUnit(id: $id) {
      id
      name
    }
  }
`;
