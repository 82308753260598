import { OFFER_STATUSES } from '@/shared/constants';

export const getStatusFIlter = ({ filter }: { filter: string }) => {
  if (filter === 'leased') {
    return {
      status: {
        equals: OFFER_STATUSES.leased,
      },
    };
  }

  if (filter === 'active') {
    return {
      status: {
        not_in: [
          OFFER_STATUSES.leased,
          OFFER_STATUSES.draft,
          OFFER_STATUSES.canceled,
          OFFER_STATUSES.closed,
          OFFER_STATUSES.ended,
        ],
      },
    };
  }

  return {};
};
