import React from 'react';

import { Dropdown, Icon, Menu } from 'antd';

import { useCurrentCompanyId, usePermissions } from '@/shared/hooks';
import { Community } from '@/shared/types/graphql';
import { Link } from '@/components';
import { dialogsContext } from '@/providers';
import { DRAFT_STATUS } from '@/shared/constants';
import { COMMUNITY_UPDATE_DIALOG, COMMUNITY_DELETE_DIALOG } from '@/dialogs';

export const CommunityActions: React.FC<{
  id: string;
  record: Community & {
    livoRentalsCount: number;
  };
}> = ({ id, record }) => {
  const { openDialog } = React.useContext(dialogsContext);
  const { companyId } = useCurrentCompanyId();
  const { isCompanyUser } = usePermissions();

  const openEditModal = React.useCallback(() => {
    openDialog({
      name: COMMUNITY_UPDATE_DIALOG,
      props: {
        options: {
          refetchQueries: ['PortfolioCommunitiesList', 'NavigationCommunitiesList'],
          awaitRefetchQueries: true,
        },
        id,
      },
    });
  }, [id, openDialog]);

  const openDeleteModal = React.useCallback(() => {
    openDialog({
      name: COMMUNITY_DELETE_DIALOG,
      props: {
        options: {
          refetchQueries: ['PortfolioCommunitiesList', 'NavigationCommunitiesList'],
          awaitRefetchQueries: true,
        },
        id,
      },
    });
  }, [id, openDialog]);

  const menu = (
    <Menu>
      <If condition={record?.status !== DRAFT_STATUS}>
        <Menu.Item>
          <Link to={`/management/${companyId}/communities/details/${id}`}>Details</Link>
        </Menu.Item>
      </If>
      <Menu.Item onClick={openEditModal}>Edit</Menu.Item>
      <If condition={!isCompanyUser}>
        <Menu.Item onClick={openDeleteModal}>Delete</Menu.Item>
      </If>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
      <Icon type="ellipsis" />
    </Dropdown>
  );
};
