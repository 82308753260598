import React from 'react';
import { useDialogState } from '@/shared/hooks';
import { Dialog } from './Dialog';

export const ONBOARDING_DIALOG = 'ONBOARDING_DIALOG';

export const OnboardingDialog = () => {
  const { close, isVisible } = useDialogState(ONBOARDING_DIALOG);

  if (!isVisible) {
    return null;
  }

  return <Dialog close={close} isVisible={isVisible} />;
};
