import React from 'react';
import { Modal } from 'antd';
import { toast } from 'react-toastify';
import { useQuery } from 'react-apollo';
import { Form } from '8base-react-sdk';

import { ModalFooter, Scrollable } from '@/components';
import { Offer } from '@/shared/types/graphql';
import { BID_DELETE_MUTATION, OFFER_BID_DELETE_QUERY } from './queries';
import { useMutationSafe } from '@/shared/hooks';

type DialogProps = {
  close: () => void;
  isVisible: boolean;
  options?: any;
  offerId?: string;
  bidId?: string;
};

export const Dialog: React.FC<DialogProps> = props => {
  const { bidId, offerId, options = {}, isVisible, close } = props;

  const { data: offerResponse, loading: offerLoading } = useQuery<{ offer: Offer }>(OFFER_BID_DELETE_QUERY, {
    variables: { bidId, offerId },
  });

  const isSingleBid = !offerResponse?.offer?.bid?.count;

  const [bidDelete] = useMutationSafe(BID_DELETE_MUTATION, {
    onCompleted: () => {
      close();
      toast.success('Offer successfully withdrawn');
      if (isSingleBid) {
        options.onCompleted && options.onCompleted();
      }
    },
  });

  const onSubmit = React.useCallback(async () => {
    await bidDelete({
      ...options,
      variables: { data: { id: bidId } },
    });
  }, [bidId, bidDelete, options]);

  return (
    <Form type="CREATE" tableSchemaName="Bid" onSubmit={onSubmit}>
      {({ handleSubmit: onSubmit, form, submitting }) => (
        <Modal
          title="Withdraw Offer"
          className="livo-modal"
          visible={isVisible}
          onCancel={close}
          afterClose={form.reset}
          centered
          footer={
            <ModalFooter
              cancelProps={{
                disabled: submitting || offerLoading,
                onClick: close,
              }}
              okProps={{
                text: 'Withdraw Offer',
                type: 'danger',
                htmlType: 'submit',
                disabled: submitting || offerLoading,
                loading: submitting,
                onClick: onSubmit,
              }}
            />
          }
        >
          {<Scrollable>Are you sure you want withdraw your offer?</Scrollable>}
        </Modal>
      )}
    </Form>
  );
};
