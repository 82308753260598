import React from 'react';
import { Col, Dropdown, Icon, Menu, Row, Table } from 'antd';
import styled from '@emotion/styled';
import { CustomCard, Link, PhoneLink, TableHeader, WebsiteLink } from '@/components';
import { ColumnProps } from 'antd/lib/table';
import { Community, Offer, SingleUnit } from '@/shared/types/graphql';
import { stringifyAddress } from '@/shared/utils';
import { DateTime } from 'luxon';
import { useCompanyTypeCheck, useCurrentCompany, useCurrentCompanyId, useDialogState } from '@/shared/hooks';
import { useHistory } from 'react-router-dom';
import { CreateEmptyTableData } from '@/shared/utils/createEmptyTableData';
import * as R from 'ramda';
import { CommunityStatusTag, ConditionalLink } from '@/pro';
import { COMPANY_TYPES, DRAFT_STATUS, OFFER_STATUSES, SINGLE_UNIT_STATUSES } from '@/shared/constants';
import { dialogsContext } from '@/providers';
import copy from 'copy-to-clipboard';
import { isOfferSwitchChecked } from '@/pro/CreateOfferSwitch';
import { getSingleRentalUrl } from '@/shared/constants/appRoutes';
import {
  OFFER_UPDATE_DIALOG,
  OFFER_DETAILS_DIALOG,
  OFFER_DELETE_DIALOG,
  SINGLE_UNIT_UPDATE_DIALOG,
  SINGLE_UNIT_DELETE_DIALOG,
  COMMUNITY_UPDATE_DIALOG,
  COMMUNITY_DELETE_DIALOG,
  INVITE_RENTERS_DIALOG,
} from '@/dialogs';

const Container = styled.div`
  grid-area: Content;
  display: grid;
  .ant-card-body {
    &::before,
    &::after {
      display: none;
    }
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 104px;
    justify-content: space-between;
    grid-template-areas: 'Table Table';
  }
`;

type SingleUnitsTableActionsProps = {
  singleUnit: SingleUnit;
};

const CommunityActions: React.FC<{
  id: string;
  record: any;
}> = ({ id, record }) => {
  const { openDialog } = React.useContext(dialogsContext);
  const { companyId } = useCurrentCompanyId();

  const openEditModal = React.useCallback(() => {
    openDialog({
      name: COMMUNITY_UPDATE_DIALOG,
      props: {
        options: {
          refetchQueries: ['PortfolioCommunitiesList'],
          awaitRefetchQueries: true,
        },
        id,
      },
    });
  }, [id, openDialog]);

  const openDeleteModal = React.useCallback(() => {
    openDialog({
      name: COMMUNITY_DELETE_DIALOG,
      props: {
        id,
        refetchQueries: ['PortfolioCommunitiesList'],
      },
    });
  }, [id, openDialog]);

  const menu = (
    <Menu>
      <If condition={record?.status !== DRAFT_STATUS}>
        <Menu.Item>
          <Link to={`/management/${companyId}/communities/details/${id}`}>Details</Link>
        </Menu.Item>
      </If>
      <Menu.Item onClick={openEditModal}>Edit</Menu.Item>
      <Menu.Item onClick={openDeleteModal}>Delete</Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
      <Icon type="ellipsis" />
    </Dropdown>
  );
};

const SingleUnitsOfferTableActions = (offer: any) => {
  const { openDialog } = React.useContext(dialogsContext);
  const { props: inviteRentersDialogProps } = useDialogState(INVITE_RENTERS_DIALOG);

  const offerId = offer?.id;
  const singleUnitId = offer?.activeOfferSingleUnit?.id;

  const onCopy = React.useCallback(() => {
    copy(`${window.location.origin}${getSingleRentalUrl(offerId)}`);
  }, [offerId]);

  const openDeleteModal = React.useCallback(() => {
    openDialog({
      name: OFFER_DELETE_DIALOG,
      props: { id: offer.id, refetchQueries: ['RentalsCommunitiesOffersList'] },
    });
  }, [openDialog, offer.id]);

  const openDetailsModal = React.useCallback(() => {
    openDialog({ name: OFFER_DETAILS_DIALOG, props: { offerId, singleUnitId } });
  }, [offerId, openDialog, singleUnitId]);

  const openUpdateModal = React.useCallback(() => {
    openDialog({ name: OFFER_UPDATE_DIALOG, props: { offerId, singleUnitId } });
  }, [offerId, openDialog, singleUnitId]);

  const menu = (
    <Menu>
      <Menu.Item onClick={openDetailsModal}>Details</Menu.Item>
      <If condition={offer?.status !== OFFER_STATUSES.leased && offer?.status !== OFFER_STATUSES.canceled}>
        <Menu.Item onClick={openUpdateModal}>Edit</Menu.Item>
      </If>
      <If condition={offer?.status !== OFFER_STATUSES.draft}>
        <Menu.Item onClick={onCopy}>Copy Public Link</Menu.Item>
        <Menu.Item
          onClick={() =>
            openDialog({
              name: INVITE_RENTERS_DIALOG,
              props: { ...inviteRentersDialogProps, offerId },
            })
          }
        >
          Invite Renters
        </Menu.Item>
      </If>
      <Menu.Item onClick={openDeleteModal}>Delete</Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
      <Icon type="ellipsis" />
    </Dropdown>
  );
};

const SingleUnitsTableActions = ({ singleUnit }: SingleUnitsTableActionsProps) => {
  const { openDialog } = React.useContext(dialogsContext);
  const { props: singleUnitUpdateDialogProps } = useDialogState(SINGLE_UNIT_UPDATE_DIALOG);
  const { props: singleUnitDeleteDialogProps } = useDialogState(SINGLE_UNIT_DELETE_DIALOG);
  const { props: inviteRentersDialogProps } = useDialogState(INVITE_RENTERS_DIALOG);

  const singleUnitId = singleUnit?.id;
  const offerId = singleUnit?.activeOffer?.id;

  const onCopy = React.useCallback(() => {
    copy(`${window.location.origin}${getSingleRentalUrl(offerId)}`);
  }, [offerId]);

  const { company } = useCurrentCompany();
  const companyType = company?.type;

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() =>
          openDialog({
            name: SINGLE_UNIT_UPDATE_DIALOG,
            props: {
              ...singleUnitUpdateDialogProps,
              singleUnitId,
              companyType,
              refetchQueries: ['NavigationSingleUnitsList', 'NavigationCommunitiesList'],
            },
          })
        }
      >
        Edit
      </Menu.Item>
      <If
        condition={singleUnit?.status !== SINGLE_UNIT_STATUSES.draft && isOfferSwitchChecked(singleUnit?.activeOffer)}
      >
        <Menu.Item onClick={onCopy}>Copy Public Link</Menu.Item>
        <Menu.Item
          onClick={() =>
            openDialog({
              name: INVITE_RENTERS_DIALOG,
              props: { ...inviteRentersDialogProps, offerId },
            })
          }
        >
          Invite Renters
        </Menu.Item>
      </If>
      <Menu.Item
        onClick={() =>
          openDialog({
            name: SINGLE_UNIT_DELETE_DIALOG,
            props: {
              ...singleUnitDeleteDialogProps,
              singleUnitId,
              refetchQueries: ['PortfolioSingleUnitsList', 'NavigationSingleUnitsList', 'NavigationCommunitiesList'],
            },
          })
        }
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
      <Icon type="ellipsis" />
    </Dropdown>
  );
};

export const PortfolioAndUpcomingTables = (props: any) => {
  const isMdu = useCompanyTypeCheck(COMPANY_TYPES.mdu);

  const portfolioData =
    (isMdu ? props.portfolioData?.communitiesList?.items : props.portfolioData?.singleUnitsList?.items) ?? [];
  const offersData = props.portfolioData?.offersList?.items ?? [];

  const portfolioDataSource = CreateEmptyTableData(portfolioData, 8);
  const offersDataSource = CreateEmptyTableData(offersData, 8);

  const { companyId } = useCurrentCompanyId();
  const history = useHistory();

  const portfolioColumns: ColumnProps<Community>[] = React.useMemo(
    () => [
      {
        title: 'Property Name',
        dataIndex: 'name',
        width: '25%',
        ellipsis: true,
        render: function renderCommunityLink(name: string, record) {
          if (name !== undefined) {
            return (
              <span>
                <ConditionalLink
                  to={
                    isMdu
                      ? `/management/${companyId}/communities/${record.id}/single-units`
                      : `/management/${companyId}/single-units/${record.id}/details`
                  }
                  condition={record.status !== DRAFT_STATUS}
                  text={name}
                />
                {record.status === DRAFT_STATUS && (
                  <CommunityStatusTag css={{ marginLeft: '10px' }} status={record?.status} />
                )}
              </span>
            );
          } else return '';
        },
      },
      {
        title: isMdu ? 'Unit Count' : 'Type',
        dataIndex: isMdu ? 'singleUnit.count' : 'type',
        width: '15%',
        ellipsis: true,
      },
      {
        title: 'Address',
        dataIndex: 'address',
        width: '20%',
        ellipsis: true,
        render: function renderAddress(address) {
          if (address !== undefined) {
            return stringifyAddress(address);
          } else return '';
        },
      },
      {
        title: isMdu ? 'Phone' : 'Website',
        dataIndex: isMdu ? 'phoneNumber' : 'website',
        width: '20%',
        ellipsis: true,
        render: isMdu
          ? function renderPhone(phone) {
              if (phone !== undefined) {
                return <PhoneLink phone={phone} />;
              } else return '';
            }
          : function renderWebsite(link) {
              if (link !== undefined) {
                return <WebsiteLink website={link} />;
              } else return '';
            },
      },
      {
        title: '',
        dataIndex: 'id',
        width: '43px',
        render: function renderSingleUnitsTableActions(id: string, record: any) {
          if (!R.isEmpty(record)) {
            return isMdu ? (
              <CommunityActions id={id} record={record} />
            ) : (
              <SingleUnitsTableActions singleUnit={record} />
            );
          } else return <span css={{ opacity: 0, cursor: 'default' }}>...</span>;
        },
      },
    ],
    [companyId, isMdu],
  );

  const transactionsColumns: ColumnProps<Offer>[] = React.useMemo(
    () =>
      isMdu
        ? [
            {
              title: 'Property Name',
              dataIndex: 'activeOfferSingleUnit',
              width: '24%',
              ellipsis: true,
              render: function renderCommunityLink(activeOfferSingleUnit) {
                if (activeOfferSingleUnit !== undefined) {
                  return (
                    <span>
                      <ConditionalLink
                        to={`/management/${companyId}/communities/${activeOfferSingleUnit?.community?.id}/single-units`}
                        condition={activeOfferSingleUnit.status !== DRAFT_STATUS}
                        text={activeOfferSingleUnit?.community?.name}
                      />
                      {activeOfferSingleUnit.status === DRAFT_STATUS && (
                        <CommunityStatusTag css={{ marginLeft: '10px' }} status={activeOfferSingleUnit?.status} />
                      )}
                    </span>
                  );
                } else return '';
              },
            },
            {
              title: 'Unit',
              dataIndex: 'activeOfferSingleUnit',
              width: '24%',
              ellipsis: true,
              render: function renderDetailsLink(value, { activeOfferSingleUnit, status, id }) {
                if (value !== undefined) {
                  return (
                    <ConditionalLink
                      condition={OFFER_STATUSES.draft !== status}
                      text={activeOfferSingleUnit?.name}
                      to={`/management/${companyId}/rentals/single-units/${id}`}
                    />
                  );
                } else return '';
              },
            },
            {
              title: 'Secure Now',
              dataIndex: 'secureNow',
              width: '24%',
              ellipsis: true,
              render: function renderSecureNow(secureNow) {
                if (secureNow !== undefined) {
                  return secureNow ? 'Enabled' : 'Disabled';
                } else return '';
              },
            },
            {
              title: 'Starts / Ends',
              dataIndex: 'endsIn',
              width: '24%',
              ellipsis: true,
              render: (value, offer) => {
                return value
                  ? DateTime.fromISO(offer?.startsIn).toFormat('D') +
                      ' - ' +
                      DateTime.fromISO(offer?.endsIn).toFormat('D')
                  : '';
              },
            },
            {
              title: '',
              dataIndex: '',
              width: '43px',
              render: function renderSingleUnitsTableActions(record) {
                if (!R.isEmpty(record)) {
                  return <SingleUnitsOfferTableActions {...record} />;
                } else return <span css={{ opacity: 0, cursor: 'default' }}>...</span>;
              },
            },
          ]
        : [
            {
              title: 'Unit',
              dataIndex: 'activeOfferSingleUnit',
              width: '24%',
              ellipsis: true,
              render: function renderDetailsLink(value, { activeOfferSingleUnit, status, id }) {
                if (value !== undefined) {
                  return (
                    <ConditionalLink
                      condition={OFFER_STATUSES.draft !== status}
                      text={activeOfferSingleUnit?.name}
                      to={`/management/${companyId}/rentals/single-units/${id}`}
                    />
                  );
                } else return '';
              },
            },
            {
              title: 'Secure Now',
              dataIndex: 'secureNow',
              width: '24%',
              ellipsis: true,
              render: function renderSecureNow(secureNow) {
                if (secureNow !== undefined) {
                  return secureNow ? 'Enabled' : 'Disabled';
                } else return '';
              },
            },
            {
              title: 'Starts / Ends',
              dataIndex: 'endsIn',
              width: '24%',
              ellipsis: true,
              render: (value, offer) => {
                return value
                  ? DateTime.fromISO(offer?.startsIn).toFormat('D') +
                      ' - ' +
                      DateTime.fromISO(offer?.endsIn).toFormat('D')
                  : '';
              },
            },
            {
              title: '',
              dataIndex: '',
              width: '43px',
              render: function renderSingleUnitsTableActions(record) {
                if (!R.isEmpty(record)) {
                  return <SingleUnitsOfferTableActions {...record} />;
                } else return <span css={{ opacity: 0, cursor: 'default' }}>...</span>;
              },
            },
          ],
    [companyId, isMdu],
  );

  const viewAllCommunities = () => {
    history.replace(isMdu ? `/management/${companyId}/communities` : `/management/${companyId}/single-units`);
  };

  const viewAllTransactions = () => {
    history.replace(`/management/${companyId}/rentals/single-units`);
  };

  return (
    <Container>
      <Row gutter={20}>
        <Col span={12}>
          <CustomCard
            padding="none"
            header={
              <TableHeader
                title="Portfolio"
                actionProps={{ text: 'View All', icon: { type: 'none' }, onClick: viewAllCommunities }}
              />
            }
          >
            <Table
              dataSource={portfolioDataSource}
              columns={portfolioColumns}
              pagination={false}
              loading={props.loading}
            />
          </CustomCard>
        </Col>
        <Col span={12}>
          <CustomCard
            padding="none"
            header={
              <TableHeader
                title="Upcoming Livo Transactions"
                actionProps={{ text: 'View All', icon: { type: 'none' }, onClick: viewAllTransactions }}
              />
            }
          >
            <Table
              loading={props.loading}
              pagination={false}
              columns={transactionsColumns}
              dataSource={offersDataSource}
            />
          </CustomCard>
        </Col>
      </Row>
    </Container>
  );
};
