import styled from '@emotion/styled';

// TODO: use colours from theme
export const Container = styled.div`
  padding: 0 20px;
  display: flex;
  .ant-avatar {
    border: 1px solid #f37a22;
  }
  .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
  .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
    border-radius: 8px;
    overflow: hidden;
  }
  .ant-skeleton-content {
    vertical-align: middle;
  }
  .ant-skeleton-header {
    padding-right: 9px;
  }
  .ant-skeleton-header .ant-skeleton-avatar-lg {
    height: 32px;
    width: 32px;
  }
`;

export const UserName = styled.div`
  color: #f37a22;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  display: flex;
  align-items: center;

  &:hover {
    color: #ff9d4d;
  }
`;

export const CompanyLogo = styled.img`
  height: 19px;
  margin-bottom: 7px;
`;

export const Inner = styled.div`
  display: flex;
  align-items: center;
`;
export const AvatarContainer = styled.div`
  margin-right: 9px;
`;

export const MenuContainer = styled.div`
  width: 100%;
`;

export const MenuHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 13px 15px 11px 15px;
  border-bottom: 1px solid #d0d7dd;
`;

export const CompanyType = styled.div`
  display: flex;
  align-items: center;
  height: 16px;
  padding: 0 4px;
  background-color: #ececec;
  border-radius: 2px;
  color: #939ea7;
  font-size: 9px;
  font-weight: 600;
  line-height: 13px;
  text-align: center;
`;
