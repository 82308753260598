import React from 'react';
import { useQuery } from 'react-apollo';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { useAuth } from '8base-react-sdk';
import { Divider } from 'antd';

import { CustomCard, Loader, MediaGallery, Tag, Link } from '@/components';
import { PropertyAmenities, PropertyDetails, PropertyOfferDetails } from '@/pro';
import { OFFER_COMBINED_STATUSES, OFFER_STATUSES } from '@/shared/constants';
import { useCurrentUser, usePermissions, useOfferBidCombinedStatus, useMutationSafe } from '@/shared/hooks';
import { File, Offer, SingleUnit } from '@/shared/types/graphql';
import { dialogsContext, RenterContext } from '@/providers';

import { OFFER_QUERY, USER_UPDATE_MUTATION } from './queries';
import { PropertyHeading, Container, Info } from './styled';
import { storeVisitedOffer } from '@/shared/utils/visitedUnits';
import { getSingleRentalBiddingUrl, AppRoutes } from '@/shared/constants/appRoutes';
import Store from '@/shared/utils/Store';
import { storageType, storageKey, redirectType } from '@/shared/types/store';
import { Actions } from './components';
import { BID_CREATE_DIALOG } from '@/dialogs';
import { useDialogState } from '@/shared/hooks';

type RedirectParams =
  | {
      offerId?: string;
      secureNow?: boolean;
      pathname?: string;
    }
  | undefined;

export const PageProperty = () => {
  const { isRenter } = usePermissions();
  const history = useHistory();

  const { id: offerId } = useParams<{ id: string }>();
  const { user } = useCurrentUser();
  const { isAuthorized } = useAuth();
  const { onLogin } = React.useContext(RenterContext);
  const { openDialog } = React.useContext(dialogsContext);
  const { close: closeBidCreateDialog } = useDialogState(BID_CREATE_DIALOG);

  const store = new Store({ storageType: storageType.Session, storageKey: storageKey.Redirect });

  if (history.location?.search.length !== 0) {
    history.replace({ search: '' });
  }

  const { data: offerResponse, loading: offerLoading } = useQuery<{ offer: Offer }>(OFFER_QUERY, {
    variables: { id: offerId },
    skip: !offerId,
    fetchPolicy: 'network-only',
  });

  const offer = offerResponse?.offer;
  const singleUnit = offer?.historyOfferSingleUnit;

  const [userUpdate] = useMutationSafe(USER_UPDATE_MUTATION);

  const singleUnitHasMedia = singleUnit?.singleUnitHasMedia?.items || [];
  const singleUnitMediaList = React.useMemo(() => singleUnitHasMedia.map(sum => sum?.media?.file), [
    singleUnitHasMedia,
  ]) as File[];

  const communityHasMedia = singleUnit?.community?.communityHasMedia?.items || [];
  const communityMediaList = React.useMemo(() => communityHasMedia.map(cm => cm?.media?.file), [
    communityHasMedia,
  ]) as File[];

  const mediaList = [...singleUnitMediaList, ...communityMediaList];

  const virtualTour = singleUnit?.virtualTour;
  const calendarLink = offer?.calendarLink;
  const bidsList = offer?.bid?.items || [];
  const userBidIndex = bidsList.findIndex(bid => bid?.renter?.user?.id === user?.id);
  const userBid = bidsList[userBidIndex];

  const isOfferLive = offer?.status === OFFER_STATUSES.live;

  const { kind: combinedStatusKind } = useOfferBidCombinedStatus(offer);

  const onBeatOffer = React.useCallback(
    () =>
      openDialog({
        name: BID_CREATE_DIALOG,
        props: {
          options: {
            refetchQueries: ['SingleUnitBidsOffer', 'SingleUnitOffer', 'BidsListQuery'],
            awaitRefetchQueries: true,
            onCompleted: () => {
              history.push(getSingleRentalBiddingUrl(offerId));
            },
          },
          termsOfLease: offer?.termsOfLease,
          rentAmount: offer?.rentAmount,
          incrementedBidAmount: offer?.incrementedBidAmount,
          offerId: offer?.id,
        },
      }),
    [history, offer, offerId, openDialog],
  );

  const [securing, setSecuring] = React.useState<boolean>(false);
  const onSecureNow = React.useCallback(() => {
    setSecuring(true);
    openDialog({
      name: BID_CREATE_DIALOG,
      props: {
        visible: true,
        options: {
          refetchQueries: ['SingleUnitBidsOffer', 'SingleUnitOffer', 'BidsListQuery'],
          awaitRefetchQueries: true,
          onError: () => {
            setSecuring(false);
          },
          onCompleted: () => {
            history.push(getSingleRentalBiddingUrl(offerId));
          },
        },
        incrementedBidAmount: offer?.incrementedBidAmount,
        securityDeposite: offer?.securityDeposite,
        securityDepositeEditable: offer?.securityDepositeEditable,
        offerId: offer?.id,
        minAmount: offer?.rentAmount,
        maxAmount: offer?.secureNowAmount,
        rentAmount: offer?.secureNowAmount,
        termsOfLease: offer?.termsOfLease,
        moveinDate: offer?.moveinDate,
        secureNow: true,
        title: 'SecureNow™',
      },
    });
  }, [history, offer, offerId, openDialog]);

  React.useEffect(() => {
    const redirect: RedirectParams = store.get(redirectType.afterLogin);

    const redirectOfferId = redirect?.offerId || '';
    const secureNow = redirect?.secureNow || false;

    if (offer && redirectOfferId && isAuthorized) {
      secureNow ? onSecureNow() : onBeatOffer();
      store.drop(redirectType.afterLogin);
    }

    if (singleUnit) {
      if (!isOfferLive && !securing) {
        closeBidCreateDialog();
      }

      if (isRenter && isAuthorized && user?.email && offerId) {
        userUpdate({
          variables: {
            data: {
              renter: {
                update: {
                  visitedOffers: {
                    connect: [{ id: offerId }],
                  },
                },
              },
            },
            filter: {
              email: user.email,
            },
          },
        });
      } else if (!isAuthorized && offerId) {
        storeVisitedOffer(offerId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerResponse, offerId, isRenter, isAuthorized, offer, securing]);

  if (offerLoading) {
    return <Loader stretch />;
  }

  if (combinedStatusKind === OFFER_COMBINED_STATUSES.none || combinedStatusKind === OFFER_COMBINED_STATUSES.draft) {
    return <Redirect to={AppRoutes.RENTER_NOT_FOUND_PAGE} />;
  }

  return (
    <CustomCard overflowBody="hidden" padding="none">
      <Container>
        <MediaGallery
          mediaList={mediaList}
          height={{ lg: '400px' }}
          virtualTour={virtualTour}
          calendarLink={calendarLink}
          offerId={offerId}
          withThumbs
        />
        <Info>
          <PropertyHeading level={2}>{singleUnit?.name}</PropertyHeading>
          <Choose>
            <When condition={isOfferLive}>
              <PropertyOfferDetails offer={offer as Offer} />
              <Choose>
                <When condition={!isAuthorized}>
                  <Actions
                    secureNow={!!offer?.secureNow}
                    onBeatOffer={() => onLogin && onLogin({ offerId })}
                    onSecureNow={() => onLogin && onLogin({ offerId, secureNow: true })}
                  />
                </When>
                <When condition={combinedStatusKind === OFFER_COMBINED_STATUSES.rejected}>
                  <Tag color="red" type="raised">
                    Your offer was rejected
                  </Tag>
                </When>
                <Otherwise>
                  <Choose>
                    <When condition={!!userBid}>
                      <Link to={getSingleRentalBiddingUrl(offerId)}>View LivoSecure Transaction</Link>
                    </When>
                    <Otherwise>
                      <Actions secureNow={!!offer?.secureNow} onBeatOffer={onBeatOffer} onSecureNow={onSecureNow} />
                    </Otherwise>
                  </Choose>
                </Otherwise>
              </Choose>
            </When>
            <When condition={combinedStatusKind === OFFER_COMBINED_STATUSES.leased}>
              <Tag color="red" type="raised">
                Property Leased
              </Tag>
            </When>
            <Otherwise>
              <Choose>
                <When
                  condition={
                    combinedStatusKind === OFFER_COMBINED_STATUSES['you-secured'] ||
                    combinedStatusKind === OFFER_COMBINED_STATUSES['you-won']
                  }
                >
                  <Link to={getSingleRentalBiddingUrl(offerId)}>View Checklist</Link>
                </When>
                <When condition={combinedStatusKind === OFFER_COMBINED_STATUSES['you-accept']}>
                  <Link to={getSingleRentalBiddingUrl(offerId)} data-e2e-id="accept-offer">
                    Accept Offer
                  </Link>
                </When>
                <When condition={combinedStatusKind === OFFER_COMBINED_STATUSES.pending}>
                  <Tag color="orange" type="raised">
                    Auction Upcoming
                  </Tag>
                </When>
                <Otherwise>
                  <Tag color="red" type="raised">
                    Auction Ended
                  </Tag>
                </Otherwise>
              </Choose>
            </Otherwise>
          </Choose>
          <Divider />
          <PropertyDetails singleUnit={singleUnit as SingleUnit} />
          <Divider />
          <PropertyAmenities singleUnit={singleUnit as SingleUnit} />
        </Info>
      </Container>
    </CustomCard>
  );
};
