import React from 'react';
import { styled } from '@/theme';
import { Icon } from 'antd';
import Maybe from 'graphql/tsutils/Maybe';

const EntityOptionTag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  font-weight: 500;

  & :not(:last-child) {
    margin-right: 4px;
  }
`;

const EntityOptionIcon = styled(Icon)`
  margin-right: 10px;
  font-size: 20px;
`;

const EntitySemiboldOptionLabel = styled.div`
  color: #929ea8;
  margin-right: 10px;
  line-height: 14px;
  font-weight: 500;

  @media (max-width: 767px) {
    font-size: 16px;
  }
`;

const EntitySemiboldOptionValue = styled.div`
  color: #323c47;
  line-height: 14px;
  font-weight: 500;

  @media (max-width: ${({ theme }) => theme.vars.breakpoints.sm.max}) {
    line-height: 1.5;
    font-size: 16px;
  }
`;

export const EntityUppercaseOptionLabel = styled.span`
  line-height: 16px;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.5px;
  color: #939ea7;
  font-weight: 600;
`;

export const EntityUppercaseOptionValue = styled.span`
  line-height: 16px;
  font-size: 12px;
  font-weight: 500;
  color: #323c47;
  text-transform: uppercase;

  @media (max-width: ${({ theme }) => theme.vars.breakpoints.sm.max}) {
    line-height: 1.5;
    font-size: 16px;
  }
`;

type EntityOptionProps = {
  type?: 'semibold' | 'uppercase' | 'uppercase-label';
  value?: React.ReactNode;
  label?: Maybe<string>;
  icon?: React.FunctionComponent;
};

export const EntityOption: React.FC<EntityOptionProps> = ({ label, value, icon, type = 'semibold' }) => {
  switch (type) {
    case 'semibold':
      return (
        <EntityOptionTag>
          {icon && <EntityOptionIcon component={icon} />}
          <EntitySemiboldOptionLabel>{label}:</EntitySemiboldOptionLabel>
          <EntitySemiboldOptionValue>{value}</EntitySemiboldOptionValue>
        </EntityOptionTag>
      );

    case 'uppercase':
      return (
        <EntityOptionTag>
          {icon && <EntityOptionIcon component={icon} />}
          <EntityUppercaseOptionLabel>{label}:</EntityUppercaseOptionLabel>
          <EntityUppercaseOptionValue>{value}</EntityUppercaseOptionValue>
        </EntityOptionTag>
      );

    case 'uppercase-label':
      return (
        <EntityOptionTag>
          {icon && <EntityOptionIcon component={icon} />}
          <EntityUppercaseOptionLabel>{label}:</EntityUppercaseOptionLabel>
          <EntitySemiboldOptionValue>{value}</EntitySemiboldOptionValue>
        </EntityOptionTag>
      );

    default:
      return null;
  }
};
