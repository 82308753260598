import gql from 'graphql-tag';

export const PORTFOLIO_SINGLE_UNITS_LIST_QUERY = gql`
  query PortfolioSingleUnitsList($first: Int, $skip: Int, $filter: SingleUnitFilter, $sort: [SingleUnitSort!]) {
    singleUnitsList(first: $first, skip: $skip, filter: $filter, sort: $sort) {
      items {
        id
        singleUnitId
        name
        type
        status
        leaseStart
        leaseEnd
        leaseRent
        address {
          state
          street1
          street2
          zip
          country
          city
        }
        updatedAt
        activeOffer {
          id
          status
          bid(sort: { rentAmount: DESC }, first: 1) {
            count
            items {
              id
              rentAmount
              createdAt
            }
          }
        }
      }
      count
    }
  }
`;
