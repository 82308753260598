import { ApolloLink } from 'apollo-link';
import { TOAST_SUCCESS_MESSAGE, HIDE_TOAST_ERROR_MESSAGE } from '@/shared/constants';
import { sendToSentry } from '@/shared/sentry';
import errorCodes from '@8base/error-codes';
import * as R from 'ramda';
import { errorResolver } from '@/shared/graphql/errorResolver';
import { toast } from 'react-toastify';

export const companyIdLink = new ApolloLink((operation, forward) => {
  const pathname = window.location.pathname;
  const [, rootRoute, id] = pathname.split('/');

  const isLocalhost = window.location.hostname === 'localhost';

  operation.setContext(({ headers }: any) => ({
    headers: {
      ...headers,
      'custom-8base': JSON.stringify({
        ...(rootRoute === 'management' && !!id ? { companyId: id } : {}),
        clientType: isLocalhost ? 'localhost' : 'desktop',
      }),
    },
  }));

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return forward(operation);
});

export const onRequestSuccess = ({ operation }: any) => {
  const context = operation.getContext();
  const message = context[TOAST_SUCCESS_MESSAGE];

  if (message) {
    toast.success(message, context.toastOpts);
  }
};

export const onRequestError = ({ graphQLErrors, operation }: any) => {
  const hasGraphQLErrors = Array.isArray(graphQLErrors) && graphQLErrors.length > 0;

  const context = operation.getContext();
  const hideToastErrorMessage = context[HIDE_TOAST_ERROR_MESSAGE];

  if (R.path([0, 'code'], graphQLErrors) === errorCodes.InternalErrorCode) {
    // eslint-disable-next-line no-console
    console.error(errorCodes.InternalErrorCode, graphQLErrors[0]);
  } else if (!hideToastErrorMessage && hasGraphQLErrors) {
    graphQLErrors.forEach((error: any) => {
      errorResolver(error);
    });
  }

  sendToSentry('graphQL-onRequestError', { graphQLErrors, operation });
};
