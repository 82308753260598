import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { FileInput, FileValue } from '8base-react-sdk';
import * as R from 'ramda';
import { Button } from 'antd';
import { css } from '@emotion/core';

import { FormField, ImagePreview } from '@/components';
import styled from '@/theme/styled';

const gapSize = 16;

const calculatePreviewWidth = (width: string) => {
  return width === '1/3' ? `calc(33.33% - ${(gapSize * 2) / 3}px)` : `calc(50% - ${gapSize / 2}px)`;
};

export const ImagePreviewContainer = styled.div<{ previewWidth?: string }>`
  display: grid;
  gap: ${gapSize}px;
  grid-template-columns: ${({ previewWidth }) =>
    previewWidth ? `repeat(auto-fit, ${calculatePreviewWidth(previewWidth)})` : 'repeat(auto-fit, 180px)'};
  margin-top: 16px;
`;

type MediaInputFieldProps = {
  label?: React.ReactNode;
  note?: string;
  input: any;
  buttonText: string;
  meta: any;
  maxFiles: number;
  isPublic?: boolean;
  accept?: string[];
  fromSources?: string[];
  previewProps?: Record<string, any>;
  previewWidth?: '1/3' | '1/2';
  'data-e2e-id'?: string;
};

export const HasMediaInputField = ({
  label,
  note,
  input,
  meta,
  buttonText,
  maxFiles,
  isPublic,
  accept = [],
  fromSources = ['local_file_system'],
  previewProps,
  previewWidth,
  ...rest
}: MediaInputFieldProps) => {
  const onChange = React.useCallback(
    (data: any) => {
      const newMedia = data.map((item: FileValue) => ({
        altName: item.filename,
        media: { file: item, name: item.filename },
      }));
      const wholeData = [...newMedia, ...input.value];
      // todo: reorder once per submit?
      const reordered = wholeData.map((item, index) => ({ ...item, order: index }));
      input.onChange(reordered);
    },
    [input],
  );

  const onDelete = React.useCallback(
    index => {
      const data = R.remove(index, 1, input.value);
      // todo: reorder once per submit?
      // eslint-disable-next-line @typescript-eslint/ban-types
      const reordered = data.map((item, index) => ({ ...(item as object), order: index }));
      input.onChange(reordered);
    },
    [input],
  );

  const move = React.useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const data = input.value;
      const swap = R.move(
        hoverIndex + (dragIndex > hoverIndex ? 1 : -1),
        dragIndex,
        R.move(dragIndex, hoverIndex, data),
      );

      // todo: reorder once per submit?
      // eslint-disable-next-line @typescript-eslint/ban-types
      swap[dragIndex] = { ...(swap[dragIndex] as object), order: dragIndex };
      // eslint-disable-next-line @typescript-eslint/ban-types
      swap[hoverIndex] = { ...(swap[hoverIndex] as object), order: hoverIndex };

      input.onChange(swap);
    },
    [input],
  );

  return (
    <FormField label={label} note={note} meta={meta} data-e2e-id={rest['data-e2e-id']}>
      <>
        <input
          type="text"
          css={css`
            display: none;
          `}
          name={input.name}
          onChange={event => onChange([{ fileId: event.target.value }])}
        />
        <FileInput onChange={onChange} value={input.value} maxFiles={maxFiles} public={isPublic}>
          {({ pick, value }) => (
            <>
              <Button type="default" size="small" onClick={() => pick({ accept, fromSources })} block>
                {buttonText}
              </Button>
              {!R.isEmpty(value) && (
                <ImagePreviewContainer previewWidth={previewWidth}>
                  {input.value.map((el: any, index: number) => (
                    <MediaItemPreview
                      key={el.id || el?.media?.file?.downloadUrl}
                      item={el}
                      index={index}
                      move={move}
                      onDelete={onDelete}
                    />
                  ))}
                </ImagePreviewContainer>
              )}
            </>
          )}
        </FileInput>
      </>
    </FormField>
  );
};

type DragItem = { id?: string; downloadUrl: string; index: number; type: 'hasMediaItemPreview' };

/**
 * Draggable media-item-preview;
 * If the preview is behaving weird on drag,
 * check for relatively positioned child-elements or
 * strictly enforce the size of the children:
 * https://github.com/react-dnd/react-dnd/issues/1608
 *
 * Also, cannot override the drag behavior of a link in Safari apparently:
 * https://github.com/react-dnd/react-dnd-html5-backend/issues/11
 */
function MediaItemPreview({ item, index, onDelete, move }: { item: any; index: number; onDelete: any; move: any }) {
  const file: FileValue = item.media.file;
  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: 'hasMediaItemPreview',
    hover(item: DragItem) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Time to actually perform the action
      move(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { id: item.id, downloadUrl: file.downloadUrl, index, type: 'hasMediaItemPreview' } as DragItem,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1, cursor: isDragging ? 'grabbing' : 'grab' }}>
      <ImagePreview value={file} handleDelete={onDelete.bind(null, index)} />
    </div>
  );
}
