import React from 'react';
import * as R from 'ramda';
import { Tag } from 'antd';
import { getAssignedProperties } from '@/shared/utils';
import { User } from '@/shared/types/graphql';
import { NOT_AVALIABLE } from '@/shared/constants';
import { Text, ButtonLink, Grid } from '@/components';
import { dialogsContext } from '@/providers';
import { OptionItem } from '@/components/SelectField/SelectField';
import { TEAM_MEMBER_UPDATE_DIALOG } from '@/dialogs';

interface ColumnAssignedPropertiesProps {
  record: User;
  rolesOptions?: any;
  propertiesOptions?: Array<OptionItem>;
}

export const ColumnAssignedProperties: React.FC<ColumnAssignedPropertiesProps> = props => {
  const { record, rolesOptions, propertiesOptions } = props;

  const assignedProperties = getAssignedProperties(record);
  const visibleAssignedProperties = R.slice(0, 3, assignedProperties);

  const { openDialog } = React.useContext(dialogsContext);

  const onEdit = React.useCallback(
    () =>
      openDialog({
        name: TEAM_MEMBER_UPDATE_DIALOG,
        props: {
          userId: record?.id,
          companyType: record?.company?.type,
          rolesOptions,
          propertiesOptions,
          onlyAssociateProperties: true,
        },
      }),

    [propertiesOptions, openDialog, record, rolesOptions],
  );

  const isMoreProperties = assignedProperties.length > visibleAssignedProperties.length;

  const morePropertiesCounter = assignedProperties.length - visibleAssignedProperties.length;

  return (
    <Grid.Layout css={{ display: 'flex', flexWrap: 'wrap' }}>
      {visibleAssignedProperties.map((el, index) => (
        <Tag key={index} css={{ marginRight: 0 }}>
          <Text css={{ margin: 0, padding: '5px 0', display: 'block' }} ellipsis={true}>
            {el?.name}
          </Text>
        </Tag>
      )) || NOT_AVALIABLE}

      {isMoreProperties && <ButtonLink onClick={onEdit} text={`${morePropertiesCounter} more...`} />}
    </Grid.Layout>
  );
};
