import React from 'react';

import { styled } from '@/theme';
import { Renter } from '@/shared/types/graphql';
import { InformationSection, EntityOption } from '@/pro';
import { Grid, ButtonLink, PhoneLink, EmailLink } from '@/components';
import { stringifyStateOfUSA, stringifyNA, stringifyAddressStreets } from '@/shared/utils';
import { dialogsContext } from '@/providers';
import { RENTER_DETAILS_DIALOG } from '@/dialogs';

const Container = styled.div`
  margin: 31px 16px;
`;

const Header = styled.div`
  margin-bottom: 16px;
  font-size: 1.4rem;
  color: #323c47;
`;

type LeadInformationProps = {
  renter: Renter;
};

export const LeadInformation = ({ renter }: LeadInformationProps) => {
  const { openDialog } = React.useContext(dialogsContext);

  return (
    <Container>
      <InformationSection header={<Header>Lead Information</Header>}>
        <Grid.Layout columns="repeat(2, 1fr)">
          <Grid.Layout>
            <EntityOption type="uppercase-label" label="Address" value={stringifyAddressStreets(renter?.address)} />
            <EntityOption type="uppercase-label" label="City" value={stringifyNA(renter?.address?.city)} />
            <EntityOption type="uppercase-label" label="Zip" value={stringifyNA(renter?.address?.zip)} />
            <EntityOption type="uppercase-label" label="State" value={stringifyStateOfUSA(renter?.address?.state)} />
          </Grid.Layout>
          <Grid.Layout autoRows="max-content">
            <EntityOption type="uppercase-label" label="Phone" value={<PhoneLink phone={renter?.phone} />} />
            <EntityOption type="uppercase-label" label="Email" value={<EmailLink email={renter?.user?.email} />} />
            <ButtonLink
              text="View Lead Details"
              css={{ fontSize: 12 }}
              onClick={() =>
                openDialog({
                  name: RENTER_DETAILS_DIALOG,
                  props: {
                    renterId: renter?.id,
                  },
                })
              }
            />
          </Grid.Layout>
        </Grid.Layout>
      </InformationSection>
    </Container>
  );
};
