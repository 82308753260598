import React from 'react';
import { Button, Col } from 'antd';

interface ActionButtonProps {
  onClick: () => void;
  isVisible?: boolean;
  text?: string;
  type?: 'default' | 'link' | 'ghost' | 'primary' | 'dashed' | 'danger';
  withoutPaddings?: boolean;
  md?: number;
}

export const ActionButton: React.FC<ActionButtonProps> = props => {
  const { type = 'default', md, text, withoutPaddings, children, isVisible = true, onClick } = props;

  if (!isVisible) {
    return null;
  }
  return (
    <Col span={24} md={md}>
      <Button type={type} css={withoutPaddings ? { padding: 0 } : null} block size="large" onClick={onClick}>
        {text || children}
      </Button>
    </Col>
  );
};
