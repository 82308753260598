import React from 'react';

import { Offer } from '@/shared/types/graphql';
import MediaGalleryPlaceholder from '@/static/MediaGalleryPlaceholder.png';
import { stringifyAddress, stringifySingleUnitType } from '@/shared/utils';
import { RowFlex, Text, ResumeNumbers } from '@/components';

import {
  OfferHeaderTag,
  OfferInfoColumnTag,
  OfferImageWrapperTag,
  OffersCountPositionTag,
  OfferImageTag,
} from './styled';
import { StatusTagPresentation } from './StatusTagPresentation';

interface HeaderProps {
  offer: Offer;
}
export const Header: React.FC<HeaderProps> = ({ offer }) => {
  const singleUnit = offer?.historyOfferSingleUnit;
  const mediaImage = singleUnit?.singleUnitHasMedia?.items[0]?.media?.file;

  return (
    <If condition={!!singleUnit}>
      <OfferHeaderTag>
        <OfferImageWrapperTag>
          <OfferImageTag src={mediaImage?.downloadUrl || MediaGalleryPlaceholder} />
          <OffersCountPositionTag>
            <StatusTagPresentation offer={offer} />
          </OffersCountPositionTag>
        </OfferImageWrapperTag>
        <OfferInfoColumnTag>
          <Text type="title" ellipsis={true}>
            {singleUnit?.name}
          </Text>
          <Text ellipsis={true}>{stringifyAddress(singleUnit?.address)}</Text>
          <Text type="uppercase" ellipsis={true}>
            {stringifySingleUnitType(singleUnit?.type)}
          </Text>
          <RowFlex align="center">
            <ResumeNumbers
              size="sm"
              beds={singleUnit?.bedrooms}
              baths={singleUnit?.bathrooms}
              squareFt={singleUnit?.squareFt}
            />
          </RowFlex>
        </OfferInfoColumnTag>
      </OfferHeaderTag>
    </If>
  );
};
