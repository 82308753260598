import React from 'react';
import { Tooltip } from 'antd';
import DOMPurify from 'dompurify';

import styled from '@/theme/styled';
import { CloseIcon } from '@/static/icons';
import { NOTIFICATION_DELETE_MUTATION, NOTIFICATION_UPDATE_MUTATION, NOTIFICATIONS_LIST_QUERY } from './queries';
import * as R from 'ramda';
import { formatDiffDate } from '@/shared/utils';
import { DateTime } from 'luxon';
import { NotificationListResponse, NotificationsQuery } from '@/shared/types/graphql';
import { useMutationSafe } from '@/shared/hooks';

const NotificationTag = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 16px;
  padding-right: 64px;
  border-radius: 5px;

  &:hover {
    background-color: #f4f7f9;
    cursor: pointer;

    .clear-btn {
      display: flex;
    }
  }
`;

const NotificationTitle = styled.p<{ read: boolean }>`
  font-weight: 600;
  color: #323c47;
`;

const NotificationMessage = styled.p`
  color: #a5afb6;
  font-size: 13px;
`;

const NotificationDate = styled.p`
  display: flex;
  font-size: 12px;
`;

const NotificationActions = styled.div`
  position: absolute;
  right: 16px;
  top: 18px;
  display: flex;
`;

const NotificationClearButton = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: none;
`;

const NotificationReadButton = styled.div<{ read: boolean }>`
  width: 16px;
  height: 16px;
  border-radius: 16px;
  border: 1px solid #d0d7dd;
  cursor: pointer;
  position: relative;

  &:after {
    content: ${({ read }) => (read ? 'none' : '""')};
    left: 3px;
    top: 3px;
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background-color: #f37a22;
  }
`;

type NotificationProps = {
  notification: any;
};

export const Notification: React.FC<NotificationProps> = ({ notification }) => {
  const [notificationDelete] = useMutationSafe(NOTIFICATION_DELETE_MUTATION);
  const [notificationUpdate] = useMutationSafe(NOTIFICATION_UPDATE_MUTATION);

  const onClear = React.useCallback(() => {
    notificationDelete({
      variables: {
        filter: { id: notification.id },
      },

      optimisticResponse: {
        __typename: 'Mutation',
        userNotificationDelete: { success: true, __typename: 'SuccessResponse' },
      },
      update: proxy => {
        let nextData = proxy.readQuery<{
          notifications: NotificationsQuery;
          userNotificationsList: NotificationListResponse;
        }>({ query: NOTIFICATIONS_LIST_QUERY });

        nextData = R.assocPath(
          ['userNotificationsList', 'count'],
          (nextData?.userNotificationsList?.count as number) + (notification.read ? 1 : -1),
          nextData,
        );

        nextData = R.over(
          R.lensPath(['notifications', 'userPreparedNotificationsList', 'items']),
          R.reject(R.propEq('id', notification.id)),
          nextData,
        );

        proxy.writeQuery({
          query: NOTIFICATIONS_LIST_QUERY,
          data: nextData,
        });
      },
    });
  }, [notificationDelete, notification.id, notification.read]);

  const onToggleRead = React.useCallback(() => {
    notificationUpdate({
      variables: { filter: { id: notification.id }, data: { read: !notification.read } },
      optimisticResponse: {
        __typename: 'Mutation',
        userNotificationUpdate: { id: notification.id, read: !notification.read, __typename: 'UserNotification' },
      },
      update: proxy => {
        let nextData = proxy.readQuery<{
          notifications: NotificationsQuery;
          userNotificationsList: NotificationListResponse;
        }>({ query: NOTIFICATIONS_LIST_QUERY });

        nextData = R.assocPath(
          ['userNotificationsList', 'count'],
          (nextData?.userNotificationsList?.count as number) + (notification.read ? 1 : -1),
          nextData,
        );

        const notificationIndex = R.findIndex(
          R.propEq('id', notification.id),
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          nextData?.notifications?.userPreparedNotificationsList?.items ?? [],
        );

        if (notificationIndex !== -1) {
          nextData = R.assocPath(
            ['notifications', 'userPreparedNotificationsList', 'items', notificationIndex, 'read'],
            !notification.read,
            nextData,
          );
        }

        proxy.writeQuery({
          query: NOTIFICATIONS_LIST_QUERY,
          data: nextData,
        });
      },
    });
  }, [notificationUpdate, notification]);

  return (
    <NotificationTag>
      <NotificationTitle
        read={notification?.read}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(notification?.title) }}
      />
      <NotificationMessage dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(notification?.message) }} />
      <NotificationDate>{formatDiffDate(DateTime.fromISO(notification.createdAt), true)} ago</NotificationDate>
      <NotificationActions>
        <NotificationClearButton className="clear-btn" onClick={onClear}>
          <Tooltip title="Clear notification">
            <CloseIcon />
          </Tooltip>
        </NotificationClearButton>
        <Tooltip title={notification?.read ? 'Mark as unread' : 'Mark as read'}>
          <NotificationReadButton read={notification?.read} onClick={onToggleRead} />
        </Tooltip>
      </NotificationActions>
    </NotificationTag>
  );
};
