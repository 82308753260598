import gql from 'graphql-tag';

export const EMPLOYEES_LIST_QUERY = gql`
  query EmployeesList($first: Int, $skip: Int, $filter: UserFilter, $sort: [UserSort!]) {
    usersList(first: $first, skip: $skip, filter: $filter, sort: $sort) {
      items {
        id
        firstName
        lastName
        email
        status
        roles {
          items {
            id
            name
          }
        }
        invitation {
          id
        }
        company {
          id
          type
        }
      }
      count
    }
  }
`;

export const INVITATION_SEND_MUTATION = gql`
  mutation EmployeeInvitationSendSecure($user: UserCreateInput!, $invitationRole: String!) {
    invitationSendSecure(user: $user, invitationRole: $invitationRole) {
      invitationId
    }
  }
`;

export const INVITATION_RESEND_MUTATION = gql`
  mutation EmployeeInvitationResendSecure($id: ID!) {
    invitationResendSecure(id: $id) {
      success
    }
  }
`;

export const INVITATION_CANCEL_MUTATION = gql`
  mutation EmployeeInvitationCancelSecure($id: ID!) {
    invitationCancelSecure(id: $id) {
      success
    }
  }
`;
