import { NOT_AVALIABLE } from '@/shared/constants';
import { Maybe } from '@/shared/types/graphql';

export const stringifyHref = (href?: Maybe<string>) => {
  if (!href) {
    return NOT_AVALIABLE;
  }

  return href.includes('http') ? href : 'http://' + href;
};
