import React from 'react';
import { Button } from 'antd';

import { Maybe, Bid, Offer } from '@/shared/types/graphql';
import { Grid } from '@/components';
import { dialogsContext } from '@/providers';
import { AnnouncingTitle } from '../styled';
import { stringifyHref } from '@/shared/utils';
import { AGENT_CONTACT_DIALOG, INFO_DIALOG } from '@/dialogs';

type ButtonUrlProps = {
  buttonText: string;
  leaseContractFile: string;
  leaseContractLink: string;
  openInfoDialog: () => void;
};

type OfferSignLeaseContractProps = {
  bid?: Maybe<Bid>;
  offer?: Maybe<Offer>;
};

const ButtonUrl: React.FC<ButtonUrlProps> = ({ buttonText, leaseContractFile, leaseContractLink, openInfoDialog }) => {
  const link = stringifyHref(leaseContractFile || leaseContractLink);

  return (
    <Button
      type="primary"
      size="large"
      onClick={leaseContractFile || leaseContractLink ? () => window.open(link) : openInfoDialog}
      data-e2e-id="button.signLeaseContract"
    >
      {buttonText}
    </Button>
  );
};

export const OfferSignLeaseContract: React.FC<OfferSignLeaseContractProps> = ({ bid, offer }) => {
  const { openDialog } = React.useContext(dialogsContext);

  const applicationFormLink = offer?.applicationLink;

  const onOpenApplicationForm = React.useCallback(() => {
    const href = stringifyHref(applicationFormLink);
    return window.open(href);
  }, [applicationFormLink]);

  const openInfoDialog = () => {
    openDialog({ name: INFO_DIALOG });
  };

  const openContactAgentDialog = React.useCallback(() => {
    if (bid?.id) {
      openDialog({ name: AGENT_CONTACT_DIALOG, props: { bidId: bid?.id } });
    }
  }, [bid, openDialog]);

  const leaseExternalUrl = offer?.leaseLink || '';
  const leaseDownloadUrl = offer?.leaseContract?.downloadUrl || '';

  return (
    <React.Fragment>
      <AnnouncingTitle css={{ paddingBottom: '52px' }}>Let&apos;s make it official!</AnnouncingTitle>
      <Grid.Layout columns="repeat(2, min-content)">
        <ButtonUrl
          buttonText="Sign Lease Contract"
          leaseContractFile={leaseDownloadUrl}
          leaseContractLink={leaseExternalUrl}
          openInfoDialog={openInfoDialog}
        />
        <Button size="large" onClick={openContactAgentDialog}>
          Contact Agent
        </Button>
        <Button size="large" type="primary" css={{ gridColumn: '1 / span 2' }} onClick={onOpenApplicationForm}>
          Complete Application and Screening Process
        </Button>
      </Grid.Layout>
    </React.Fragment>
  );
};
