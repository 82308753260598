import React from 'react';
import { useDialogState } from '@/shared/hooks';
import { Dialog } from './Dialog';

export const SCHEDULE_SHOWING_CONTACT_DIALOG = 'SCHEDULE_SHOWING_CONTACT_DIALOG';

export const ScheduleShowingContactDialog = () => {
  const { close, isVisible, props } = useDialogState(SCHEDULE_SHOWING_CONTACT_DIALOG);

  if (!isVisible) {
    return null;
  }

  return <Dialog {...props} close={close} isVisible={isVisible} />;
};
