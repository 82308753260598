import React from 'react';
import { Actions } from '@/routes/rentals/PageProperty/components';
import { useAuth } from '8base-react-sdk';
import { BID_CREATE_DIALOG } from '@/dialogs';
import { getSingleRentalBiddingUrl } from '@/shared/constants/appRoutes';
import { dialogsContext, RenterContext } from '@/providers';
import { useCurrentUser } from '@/shared/hooks';
import { Offer } from '@/shared/types/graphql';
import { useHistory } from 'react-router-dom';
import { RowFlex } from '@/components';

type OfferCardAction = {
  offer: Offer;
};

const OfferCardActions: React.FC<OfferCardAction> = ({ offer }) => {
  const { isAuthorized } = useAuth();
  const history = useHistory();
  const { user } = useCurrentUser();
  const isHasBidAlready = !!offer.bid?.items.find(bid => bid.renter?.user?.id === user?.id);
  const offerId = offer?.id;

  const { onLogin } = React.useContext(RenterContext);
  const { openDialog } = React.useContext(dialogsContext);

  const onBeatOffer = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      if (!isAuthorized) {
        onLogin && onLogin({ offerId });
        return;
      }

      openDialog({
        name: BID_CREATE_DIALOG,
        props: {
          options: {
            refetchQueries: ['SingleUnitBidsOffer', 'SingleUnitOffer', 'BidsListQuery'],
            awaitRefetchQueries: true,
            onCompleted: () => {
              history.push(getSingleRentalBiddingUrl(offerId));
            },
          },
          termsOfLease: offer?.termsOfLease,
          rentAmount: offer?.rentAmount,
          incrementedBidAmount: offer?.incrementedBidAmount,
          offerId: offer?.id,
        },
      });
    },
    [history, isAuthorized, offer, offerId, onLogin, openDialog],
  );

  const onSecureNow = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      if (!isAuthorized) {
        onLogin && onLogin({ offerId, secureNow: true });
        return;
      }

      openDialog({
        name: BID_CREATE_DIALOG,
        props: {
          visible: true,
          options: {
            refetchQueries: ['SingleUnitBidsOffer', 'SingleUnitOffer', 'BidsListQuery'],
            awaitRefetchQueries: true,
            onCompleted: () => {
              history.push(getSingleRentalBiddingUrl(offerId));
            },
          },
          incrementedBidAmount: offer?.incrementedBidAmount,
          securityDeposite: offer?.securityDeposite,
          securityDepositeEditable: offer?.securityDepositeEditable,
          offerId: offer?.id,
          minAmount: offer?.rentAmount,
          maxAmount: offer?.secureNowAmount,
          rentAmount: offer?.secureNowAmount,
          termsOfLease: offer?.termsOfLease,
          moveinDate: offer?.moveinDate,
          secureNow: true,
          title: 'SecureNow™',
        },
      });
    },
    [history, isAuthorized, offer, offerId, onLogin, openDialog],
  );

  return (
    <RowFlex align={'center'}>
      <If condition={!isHasBidAlready}>
        <Actions secureNow={!!offer?.secureNow} onBeatOffer={onBeatOffer} onSecureNow={onSecureNow} />
      </If>
    </RowFlex>
  );
};

export default OfferCardActions;
