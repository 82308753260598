import React from 'react';
import * as R from 'ramda';
import { useQuery } from 'react-apollo';
import { Dropdown, Icon, Menu, Tag } from 'antd';

import { EMPLOYEES_LIST_QUERY, INVITATION_CANCEL_MUTATION, INVITATION_RESEND_MUTATION } from './queries';
import { UseTableStateResponse, useTableState, TableState, useMutationSafe, usePermissions } from '@/shared/hooks';
import { ColumnProps } from 'antd/lib/table';
import { stringifyUserName, getApplicationRole } from '@/shared/utils';
import { User, UserListResponse } from '@/shared/types/graphql';
import { TOAST_SUCCESS_MESSAGE, NOT_AVALIABLE, APPLICATION_ROLES } from '@/shared/constants';
import { EmailLink, Table } from '@/components';
import { dialogsContext } from '@/providers';
import { EMPLOYEE_DELETE_DIALOG } from '@/dialogs';

const STATUSES_MAP = {
  active: { color: 'green', label: 'Active' },
  inactive: { color: '', label: 'Inactive' },
  invitationPending: { color: 'blue', label: 'Invited' },
};

const getSort = (queryParams: TableState) => {
  let sort: Record<string, any>[] = [];

  if (queryParams.sortBy && queryParams.sortOrder) {
    const field = queryParams.sortBy;
    const direction = queryParams.sortOrder === 'ascend' ? 'ASC' : 'DESC';

    if (field === 'name') {
      sort = [
        {
          firstName: direction,
        },
        {
          lastName: direction,
        },
      ];
    } else if (field && field !== 'role') {
      sort = [
        {
          [field]: direction,
        },
      ];
    }
  }

  return sort;
};

type EmployeesTableProps = {
  query: string;
};

export const EmployeesTable = ({ query }: EmployeesTableProps) => {
  const [{ page, sortBy, sortOrder, pageSize, pageSizeOptions }, onChange]: UseTableStateResponse = useTableState({
    query,
  });

  const [invitationResend] = useMutationSafe(INVITATION_RESEND_MUTATION, {
    context: {
      [TOAST_SUCCESS_MESSAGE]: 'Invitation Successfully Resent',
    },
  });

  const [invitationCancel, { loading: invitationCancelLoading }] = useMutationSafe(INVITATION_CANCEL_MUTATION, {
    context: {
      [TOAST_SUCCESS_MESSAGE]: 'Invitation Successfully Canceled',
    },
    refetchQueries: ['EmployeesList'],
    awaitRefetchQueries: true,
  });

  const EmployeesTableActions: React.FC<{ value: string; record: User }> = ({ value, record }) => {
    const { openDialog } = React.useContext(dialogsContext);

    const { isSuperAdmin } = usePermissions();

    const onResend = React.useCallback(() => invitationResend({ variables: { id: record?.invitation?.id } }), [record]);

    const onCancel = React.useCallback(() => invitationCancel({ variables: { id: record?.invitation?.id } }), [record]);

    const onDelete = React.useCallback(
      () =>
        openDialog({
          name: EMPLOYEE_DELETE_DIALOG,
          props: {
            userId: record?.id,
            firstName: record?.firstName,
            lastName: record?.lastName,
          },
        }),
      [openDialog, record],
    );

    const menu = (
      <Menu>
        {record.status !== 'invitationPending' && <Menu.Item onClick={onDelete}>Delete</Menu.Item>}
        {record.status === 'invitationPending' && <Menu.Item onClick={onResend}>Resend</Menu.Item>}
        {record.status === 'invitationPending' && <Menu.Item onClick={onCancel}>Cancel</Menu.Item>}
      </Menu>
    );

    if (!isSuperAdmin) return null;

    return (
      <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
        <Icon type="ellipsis" />
      </Dropdown>
    );
  };

  const livoAdminRoles = APPLICATION_ROLES.livoAdmin?.rolesList || [];
  const livoEmployeeRoles = APPLICATION_ROLES.livoEmployee?.rolesList || [];
  const roleNameList = Array.from(new Set([...livoAdminRoles, ...livoEmployeeRoles].map(({ roleName }) => roleName)));

  const { data, loading } = useQuery<{ usersList: UserListResponse }>(EMPLOYEES_LIST_QUERY, {
    variables: {
      filter: {
        AND: [
          {
            roles: {
              some: {
                name: {
                  in: roleNameList,
                },
              },
            },
          },
          query
            ? {
                OR: [
                  {
                    firstName: {
                      contains: query,
                    },
                  },
                  {
                    lastName: {
                      contains: query,
                    },
                  },
                  {
                    email: {
                      contains: query,
                    },
                  },
                ],
              }
            : {},
        ],
      },
      sort: getSort({ sortOrder, sortBy, page, pageSize }),
      first: pageSize,
      skip: (page - 1) * pageSize,
    },
  });

  const dataSource = data?.usersList?.items ?? [];
  const total = data?.usersList?.count ?? pageSize;

  const pagination = { current: page, pageSize, total, showSizeChanger: true, pageSizeOptions };

  const columns: ColumnProps<User>[] = React.useMemo(
    () => [
      {
        title: 'Employee Name',
        dataIndex: 'name',
        sorter: dataSource?.length !== 0,
        width: '20%',
        ellipsis: true,
        render: (_, record) => stringifyUserName(record),
        sortOrder: sortBy === 'name' ? sortOrder : undefined,
      },
      {
        title: 'Email Address',
        dataIndex: 'email',
        sorter: dataSource?.length !== 0,
        width: '40%',
        ellipsis: true,
        sortOrder: sortBy === 'email' ? sortOrder : undefined,
        render: function renderEmail(_, { email }) {
          return <EmailLink email={email} />;
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        sorter: dataSource?.length !== 0,
        width: '20%',
        ellipsis: true,
        sortOrder: sortBy === 'status' ? sortOrder : undefined,
        render: function StatusTag(value: string) {
          const color = R.pathOr('', [value, 'color'], STATUSES_MAP);
          const label = R.pathOr(value, [value, 'label'], STATUSES_MAP);

          return <Tag color={color}>{label}</Tag>;
        },
      },
      {
        title: 'Role',
        dataIndex: 'role',
        ellipsis: true,
        render: (_, record) => getApplicationRole(record)?.name || NOT_AVALIABLE,
      },
      {
        title: '',
        dataIndex: '',
        width: '43px',
        render: function UserActions(value: string, record: User) {
          return <EmployeesTableActions value={value} record={record} />;
        },
      },
    ],
    [dataSource, sortBy, sortOrder],
  );

  return (
    <Table
      rowKey="id"
      loading={loading || invitationCancelLoading}
      dataSource={dataSource}
      columns={columns}
      onChange={onChange}
      pagination={pagination}
    />
  );
};
