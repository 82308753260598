import React from 'react';
import { useLocation } from 'react-router-dom';
import { Menu, Icon } from 'antd';
import { useQuery } from 'react-apollo';

import { COMPANY_TYPES } from '@/shared/constants';
import { CommunityListResponse, SingleUnitListResponse } from '@/shared/types/graphql';
import { useCurrentCompany, useCurrentUser, useFilters, useCompanyTypeCheck } from '@/shared/hooks';
import { NavLink, NavMenuItem } from './components';
import { NAVIGATION_COMMUNITIES_QUERY, NAVIGATION_UNITS_QUERY, RENTALS_AMOUNT_QUERY } from './queries';
import { getMultiFamilyNavList, getSingleFamilyNavList, getSelfManagedNavList } from './constants';
import { offersGroupArrayToObject } from './utils';
import { NavParagraph, CountTag, CommunityCountTag } from '../../styled';
import { Text } from '@/components';
import { managementLayoutContext } from '@/providers';
import {
  useNavigationCommunitiesQueryFilter,
  useNavigationSingleUnitsQueryFilter,
  useRentalsAmountQueryFilter,
} from './helpers';
import { NavItemType } from './types';

const { SubMenu } = Menu;

export const Nav = () => {
  const { isSidebarOpen, isAdmin } = React.useContext(managementLayoutContext);

  const { company } = useCurrentCompany();
  const companyId = company?.id;
  const companyName = company?.name;
  const companyType = company?.type;

  const { user } = useCurrentUser();
  const navigationCommunitiesQueryFilter = useNavigationCommunitiesQueryFilter();
  const navigationSingleUnitsFilter = useNavigationSingleUnitsQueryFilter();
  const rentalsAmountQueryFilter = useRentalsAmountQueryFilter();

  const isMultiFamily = useCompanyTypeCheck(COMPANY_TYPES.mdu);

  const { data: offersData } = useQuery(RENTALS_AMOUNT_QUERY, {
    variables: {
      filter: rentalsAmountQueryFilter,
    },
  });

  const counts = {
    draft: 0,
    pending: 0,
    live: 0,
    ended: 0,
    canceled: 0,
    secured: 0,
    closed: 0,
    leased: 0,

    ...(offersData && offersGroupArrayToObject(offersData.offersList.groups)),
  };

  const { data: communitiesData } = useQuery<{ communitiesList: CommunityListResponse }>(NAVIGATION_COMMUNITIES_QUERY, {
    skip: !isMultiFamily,
    variables: {
      filter: navigationCommunitiesQueryFilter,
    },
    fetchPolicy: 'network-only',
  });

  const { data: singleUnitsData } = useQuery<{ singleUnitsList: SingleUnitListResponse }>(NAVIGATION_UNITS_QUERY, {
    skip: isMultiFamily,
    variables: {
      filter: navigationSingleUnitsFilter,
    },
  });

  const roles = user?.roles?.items ?? [];

  const navList = React.useMemo(() => {
    switch (companyType) {
      case COMPANY_TYPES.mdu: {
        return getMultiFamilyNavList({
          roles,
          companyId,
          communities: communitiesData?.communitiesList?.items ?? [],
          communitiesCount: communitiesData?.communitiesList?.count ?? 0,
          counts,
        });
      }
      case COMPANY_TYPES.singleFamily: {
        return getSingleFamilyNavList({
          roles,
          companyId,
          unitCount: singleUnitsData?.singleUnitsList?.count ?? 0,
          counts,
        });
      }
      case COMPANY_TYPES.selfManaged: {
        return getSelfManagedNavList({ roles, companyId, unitCount: singleUnitsData?.singleUnitsList?.count ?? 0 });
      }
      case COMPANY_TYPES.broker: {
        return getSelfManagedNavList({ roles, companyId, unitCount: singleUnitsData?.singleUnitsList?.count ?? 0 });
      }
      default:
        return [];
    }
  }, [companyType, roles, companyId, communitiesData, counts, singleUnitsData]);

  const { pathname } = useLocation();
  const [filter] = useFilters<any>();

  const customSelectedPathKeys = {
    [`/management/${companyId}/rentals/single-units`]: filter.status
      ? `/management/${companyId}/rentals/single-units?filter_status=${filter.status}`
      : `/management/${companyId}/rentals/single-units`,
  };

  const selectedKey = customSelectedPathKeys[pathname] || pathname;

  interface SingleMenuItemProps {
    navItem: NavItemType;
  }
  const SingleMenuItem: React.FC<SingleMenuItemProps> = ({ navItem }) => {
    if (!navItem) {
      return null;
    }

    return (
      <NavLink to={navItem.path ?? ''}>
        <>
          <Icon component={navItem.icon} style={{ fontSize: '2rem' }} />
          <span>{navItem.name}</span>
          {navItem?.count ? <CommunityCountTag>{navItem?.count}</CommunityCountTag> : null}
        </>
      </NavLink>
    );
  };

  return (
    <Menu className="livo-sidebar" selectedKeys={[selectedKey]} mode="inline" inlineCollapsed={!isSidebarOpen}>
      {isAdmin && (
        <NavParagraph isSidebarOpen={isSidebarOpen}>
          <Text ellipsis={true}>{companyName}</Text>
        </NavParagraph>
      )}
      {navList.map(navItem =>
        navItem.subNav && !!navItem.subNav.length ? (
          <SubMenu
            key={navItem.path}
            className={
              navItem.path === selectedKey
                ? 'ant-menu-sub-item-selected ant-menu-first-level-item'
                : 'ant-menu-first-level-item'
            }
            title={
              <NavMenuItem path={navItem.path}>
                <Icon component={navItem.icon} style={{ fontSize: '2rem', color: '#fff' }} />
                <span data-e2e-id={navItem['data-e2e-id']}>{navItem.name}</span>
                {navItem?.count ? <CommunityCountTag>{navItem?.count}</CommunityCountTag> : null}
              </NavMenuItem>
            }
          >
            {navItem.subNav.map(
              subNavItem =>
                subNavItem && (
                  <Menu.Item key={subNavItem.path}>
                    <NavLink
                      to={subNavItem.path}
                      css={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        display: 'flex !important',
                        alignItems: 'center',
                      }}
                    >
                      <span css={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{subNavItem.name}</span>
                      {navItem.rentalsSubNav && (
                        <CountTag isSidebarOpen={isSidebarOpen} isActive={filter.status === subNavItem.status}>
                          {subNavItem.count}
                        </CountTag>
                      )}
                    </NavLink>
                  </Menu.Item>
                ),
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={navItem.path}>
            <SingleMenuItem navItem={navItem} />
          </Menu.Item>
        ),
      )}
    </Menu>
  );
};
