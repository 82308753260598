import React, { useEffect } from 'react';
import { useAuth } from '8base-react-sdk';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { toast } from 'react-toastify';
import { useQueryParams } from '@/shared/hooks/useQueryParams';
import * as R from 'ramda';

import { HandleSendNotificationMutation } from '@/shared/graphql';
import {
  QueryCheckIsEmailVerifiedArgs,
  SuccessResponse,
  MutationHandleSendNotificationArgs,
} from '@/shared/types/graphql';
import { Loader } from '@/components';
import { useMutationSafe, usePermissions } from '@/shared/hooks';
import { CHECK_IS_USER_EMAIL_VERIFIED } from './query';

export const ConfirmedContainer = () => {
  const auth = useAuth();
  const history = useHistory();
  const [queryParams] = useQueryParams();

  const redirectUrl = R.pathOr('', ['redirectUrl'], queryParams);

  const { isRenter, isCompanyUser } = usePermissions();

  const { email } = auth.authClient.getState();

  const { data, loading: checkEmailLoading } = useQuery<
    { checkIsEmailVerified: SuccessResponse },
    QueryCheckIsEmailVerifiedArgs
  >(CHECK_IS_USER_EMAIL_VERIFIED, {
    variables: {
      email,
    },
    skip: !email,
    fetchPolicy: 'network-only',
  });

  const isUserEmailVerified = Boolean(data?.checkIsEmailVerified?.success);

  const [handleSendNotification] = useMutationSafe<
    { handleSendNotification: SuccessResponse },
    MutationHandleSendNotificationArgs
  >(HandleSendNotificationMutation);

  useEffect(() => {
    const confirmEmail = async () => {
      try {
        if (!checkEmailLoading) {
          if (isUserEmailVerified) {
            await auth.authClient.setState({ isEmailVerified: true });

            if (isRenter) {
              await handleSendNotification({
                variables: {
                  payload: {
                    notificationType: 'renterWelcome',
                  },
                },
              });
            } else if (isCompanyUser) {
              await handleSendNotification({
                variables: {
                  payload: {
                    notificationType: 'propertyManagerWelcome',
                  },
                },
              });
            }

            history.push(redirectUrl || '/');
          } else {
            history.replace(`/auth/confirmation`);
          }
        }
      } catch (e) {
        toast.error('Something went wrong during an email confirmation');
        // eslint-disable-next-line no-console
        console.error(e);
        history.push('/auth');
      }
    };

    confirmEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkEmailLoading, isUserEmailVerified]);

  return <Loader stretch />;
};
