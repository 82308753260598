import { Maybe, SmartAddress } from '@/shared/types/graphql';
import { NOT_AVALIABLE } from '@/shared/constants';

export const stringifyAddressStreets = (address?: Maybe<SmartAddress>) => {
  if (!address) {
    return NOT_AVALIABLE;
  }

  const { street1, street2 } = address;

  const value = [street1, street2]
    .filter(val => !!val)
    .join(', ')
    .trim();

  if (!value) {
    return NOT_AVALIABLE;
  }

  return value;
};
