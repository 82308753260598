import React from 'react';
import { Modal, Icon, Typography } from 'antd';

import { ModalFooter } from '@/components';
import { RedAlert } from '@/static/icons';
import { RANKING_LABELS } from '@/shared/constants';

type DialogProps = {
  close: () => void;
  isVisible: boolean;
  name?: string;
  confirmAction?: () => void;
};

export const Dialog: React.FC<DialogProps> = ({ isVisible, close, name, confirmAction }) => {
  const title = React.useMemo(
    () => (
      <>
        <Icon
          component={RedAlert}
          css={{ marginRight: '8px', fontSize: '20px', lineHeight: '22px', verticalAlign: 'middle' }}
        />
        Change Ranking Constant
      </>
    ),
    [],
  );

  return (
    <Modal
      title={title}
      visible={isVisible}
      onCancel={close}
      footer={
        <ModalFooter
          cancelProps={{
            onClick: close,
          }}
          okProps={{
            disabled: !confirmAction,
            onClick: () => {
              confirmAction && confirmAction();
              close();
            },
            type: 'danger',
            text: 'Ok',
          }}
        />
      }
      centered
    >
      <Typography.Paragraph style={{ textAlign: 'center' }}>
        Are you sure you want to change {RANKING_LABELS[name || '']}?
      </Typography.Paragraph>
    </Modal>
  );
};
