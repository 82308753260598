import { CompanyIcon, AssetsIcon, SoftwareIcon, ComplianceIcon } from '@/static/icons';
import { DemographicInformation, CorporateAssets, SoftwareAndServices, Compliance } from './components';
import { CompanySchemaWizardValidation } from '@/schemaValidators/index';
import { ONLINE_APPLICATION_PROCESS, SCREENING_PROCESS } from '@/shared/constants';
import { DemographicStepDescription } from '@/pro/CreateCompanyWizard/components/DemographicInformation';

const DemographicInformationStep = {
  title: 'Company Demographic Information',
  icon: CompanyIcon,
  description: DemographicStepDescription(),
  component: DemographicInformation,
  schema: CompanySchemaWizardValidation.demographicInformationSchema,
};

const CorporateAssetsStep = {
  title: 'Corporate Assets',
  icon: AssetsIcon,
  description:
    'Please upload all related documents and forms. These may include but not limited to: Livo MSA, Property List, Rent Rolls, Applications, Screening Docs and Waivers, and any other related docs and spreadsheets.',
  component: CorporateAssets,
  schema: CompanySchemaWizardValidation.corporateAssetsSchema,
};

const SoftwareAndServicesStep = {
  title: 'Software & Services',
  icon: SoftwareIcon,
  description: '',
  component: SoftwareAndServices,
  schema: CompanySchemaWizardValidation.softwareAndServicesSchema,
};

const ComplianceStep = {
  title: 'Compliance',
  icon: ComplianceIcon,
  description:
    'MITS is a set of data dictionaries that includes all the information fields populated in multifamily activities, such as filling out a lease, describing a property’s assets to a lender or prospective buyer or screening a prospective resident’s credit.',
  component: Compliance,
  schema: CompanySchemaWizardValidation.complianceSchema,
};

export const steps = [DemographicInformationStep, CorporateAssetsStep, SoftwareAndServicesStep, ComplianceStep];

export const initialValues = {
  contactPhoneNumber: {
    code: '1',
  },
  primaryAddress: {
    country: 'United States',
  },
  primaryPhoneNumber: {
    code: '1',
  },
  mits: false,
  isUsingPms: false,
  isUsingIls: false,
  screeningProcess: SCREENING_PROCESS.no,
  onlineApplicationProcess: ONLINE_APPLICATION_PROCESS.no,
};
