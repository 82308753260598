import React from 'react';
import styled from '@emotion/styled';
import { Card } from 'antd';

import { SummaryItem } from '../AdminSummary/SummaryItem';
import { Bid } from '@/shared/types/graphql';

const Container = styled.div`
  grid-area: LeadsSummary;
  display: grid;
  .ant-card-body {
    &::before,
    &::after {
      display: none;
    }
    display: grid;
    grid-auto-flow: columns;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 104px;
    justify-content: space-between;
  }
`;

export type SummaryItemProps = { value: number | null; description: string; isPercentage?: boolean };

export const LeadsSummary = (props: any) => {
  const bidsRentIncrease = props.bidsResultData?.map((bid: Bid) => {
    if (bid?.rentAmount && bid?.offer?.rentAmount) {
      return {
        bidIncrease: Math.round(((bid?.rentAmount - bid?.offer?.rentAmount) / bid?.offer?.rentAmount) * 100),
      };
    } else return 0;
  });

  const calculateIncrease = (increase: any) => {
    let summ = 0;
    increase?.forEach((el: any) => (summ += el?.bidIncrease));

    return summ;
  };

  const resultRentIncrease = calculateIncrease(bidsRentIncrease);

  const summaryData: Array<SummaryItemProps> = [
    { value: props.communitiesCount || null, description: 'New Leads in the 24 hours' },
    {
      value: resultRentIncrease || null,
      description: 'Conversion Rate Increase (last week)',
      isPercentage: true,
    },
    {
      value: resultRentIncrease || null,
      description: 'Conversion Rate Increase (last week)',
      isPercentage: true,
    },
    {
      value: resultRentIncrease || null,
      description: 'Conversion Rate Increase (last week)',
      isPercentage: true,
    },
  ];

  return (
    <Container>
      <Card title="Livo Leads">
        {summaryData.map((SummaryItemData, SummaryItemIndex) => (
          <SummaryItem key={SummaryItemIndex} {...SummaryItemData} />
        ))}
      </Card>
    </Container>
  );
};
