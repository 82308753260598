import React from 'react';
import { Divider } from 'antd';
import styled from '@emotion/styled';
import Maybe from 'graphql/tsutils/Maybe';

type ResumeNumbersSize = 'sm' | 'md';

export const Container = styled.div<{ size: ResumeNumbersSize }>`
  font-size: ${props => (props.size === 'sm' ? '12px' : '14px')};
`;

export const ResumeNumber = styled.span`
  font-weight: 600;
  color: #323c47;
`;

type ResumeNumbersProps = {
  beds: Maybe<number>;
  baths: Maybe<number>;
  squareFt: Maybe<number>;
  size?: ResumeNumbersSize;
};

export const ResumeNumbers = ({ beds, baths, squareFt, size = 'md' }: ResumeNumbersProps) => {
  return (
    <Container size={size}>
      <ResumeNumber>{beds}</ResumeNumber> bd
      <Divider type="vertical" />
      <ResumeNumber>{baths}</ResumeNumber> ba
      <Divider type="vertical" />
      <ResumeNumber>{squareFt}</ResumeNumber> sqft
    </Container>
  );
};
