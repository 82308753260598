import React from 'react';

import DOMPurify from 'dompurify';
import { SingleUnit } from '@/shared/types/graphql';
import { NOT_AVALIABLE } from '@/shared/constants';
import { Grid, ResumeNumbers, WebsiteLink, PhoneLink } from '@/components';
import { stringifySingleUnitType, stringifyAddress } from '@/shared/utils';
import MediaGalleryPlaceholder from '@/static/MediaGalleryPlaceholder.png';
import { DOMPURIFY_CONFIG } from 'livo-shared';
import { File } from '@/shared/types/graphql';
import {
  Container,
  NameAndFeaturesContainer,
  Name,
  Title,
  InformationList,
  InformationListItem,
  InformationListItemLabel,
  InformationListItemValue,
  SingleUnitMediaImage,
} from './styled';
import { PropertyDescription } from '@/components';

export const PropertyInfo = ({ singleUnit }: { singleUnit: SingleUnit }) => {
  const name = singleUnit?.name;
  const bedrooms = singleUnit?.bedrooms;
  const bathrooms = singleUnit?.bathrooms;
  const squareFt = singleUnit?.squareFt;
  const description = singleUnit?.description;
  const type = singleUnit?.type;
  const address = singleUnit?.address;
  const phoneNumber = !!singleUnit?.phone?.number ? singleUnit?.phone : singleUnit?.community?.phoneNumber;
  const website = singleUnit?.website || singleUnit?.community?.website;

  const communityName = singleUnit?.community?.name || NOT_AVALIABLE;

  const fullAddress = stringifyAddress(address);

  const singleUnitHasMedia = singleUnit?.singleUnitHasMedia?.items ?? [];
  const mediaList = React.useMemo(() => singleUnitHasMedia.map(sum => sum?.media?.file), [
    singleUnitHasMedia,
  ]) as File[];
  const sanitizedDescriptionHtml = React.useMemo(() => DOMPurify.sanitize(description as string, DOMPURIFY_CONFIG), [
    description,
  ]);

  return (
    <Container>
      <Title>Property Info</Title>
      <Grid.Layout columns="repeat(3, calc(33.3% - 5px))">
        <Choose>
          <When condition={mediaList.length > 0}>
            {mediaList.map(media => (
              <Grid.Box key={media.downloadUrl ?? ''}>
                <SingleUnitMediaImage src={media.downloadUrl ?? ''} alt={media.filename ?? ''} />
              </Grid.Box>
            ))}
          </When>
          <Otherwise>
            <Grid.Box>
              <SingleUnitMediaImage src={MediaGalleryPlaceholder} alt="unit placeholder" />
            </Grid.Box>
          </Otherwise>
        </Choose>
      </Grid.Layout>
      <NameAndFeaturesContainer>
        <Name>{name ? name : ''}</Name>
        <ResumeNumbers beds={bedrooms} baths={bathrooms} squareFt={squareFt} />
      </NameAndFeaturesContainer>
      {description && (
        <PropertyDescription>
          <div dangerouslySetInnerHTML={{ __html: sanitizedDescriptionHtml }} />
        </PropertyDescription>
      )}
      <InformationList>
        <InformationListItem>
          <InformationListItemLabel>TYPE:</InformationListItemLabel>
          <InformationListItemValue>{stringifySingleUnitType(type)}</InformationListItemValue>
        </InformationListItem>
        <InformationListItem>
          <InformationListItemLabel>PROPERTY:</InformationListItemLabel>
          <InformationListItemValue>{communityName}</InformationListItemValue>
        </InformationListItem>
        <InformationListItem>
          <InformationListItemLabel>ADDRESS:</InformationListItemLabel>
          <InformationListItemValue>{fullAddress ? fullAddress : NOT_AVALIABLE}</InformationListItemValue>
        </InformationListItem>
        <InformationListItem>
          <InformationListItemLabel>WEBSITE:</InformationListItemLabel>
          <InformationListItemValue>
            <WebsiteLink website={website} />
          </InformationListItemValue>
        </InformationListItem>
        <InformationListItem>
          <InformationListItemLabel>PHONE NUMBER:</InformationListItemLabel>
          <InformationListItemValue>
            <PhoneLink phone={phoneNumber} />
          </InformationListItemValue>
        </InformationListItem>
      </InformationList>
    </Container>
  );
};
