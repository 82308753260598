import React from 'react';
import { CustomCard, TableHeader } from '@/components';
import { AmenityGroupsTable } from './AmenityGroupsTable';

export const Amenities = () => {
  return (
    <CustomCard padding="none" header={<TableHeader title="Amenity Groups" />}>
      <AmenityGroupsTable />
    </CustomCard>
  );
};
