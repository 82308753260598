import { Maybe, SmartAddress } from '@/shared/types/graphql';
import { NOT_AVALIABLE } from '@/shared/constants';

export const stringifyAddress = (address?: Maybe<SmartAddress>) => {
  if (!address) {
    return NOT_AVALIABLE;
  }

  const { street1, street2, city, state, zip } = address;

  const value = [street1, street2, city, state]
    .filter(val => !!val)
    .join(', ')
    .trim();
  const formattedValue = value + ' ' + zip;

  if (!value || !formattedValue) {
    return NOT_AVALIABLE;
  }

  return formattedValue;
};
