import React from 'react';
import { Carousel } from 'antd';
import Maybe from 'graphql/tsutils/Maybe';
import { matchPath, useLocation } from 'react-router-dom';
import { File } from '@/shared/types/graphql';
import ImagesCarousel, { Modal, ModalGateway, ViewType } from 'react-images/lib/components';
import {
  CarouselImage,
  CarouselImageWrapper,
  CarouselWrapper,
  Sizes,
  CarouselThumbImageWrapper,
  CarouseThumbWrapper,
  CarouselContainer,
} from '@/components/MediaGallery/styled';
import { dialogsContext } from '@/providers';
import { Grid } from '@/components';
import { Button } from 'livo-components/src/components';
import { VideoIcon, CalendarFilledIcon } from '@/static/icons';
import MediaGalleryPlaceholder from '@/static/MediaGalleryPlaceholder.png';
import { getCompressedImageUrl } from '@/shared/utils';
import { VIRTUAL_TOUR_DIALOG, CALENDAR_DIALOG, SCHEDULE_SHOWING_CONTACT_DIALOG } from '@/dialogs';
import { AppRoutes } from '@/shared/constants/appRoutes';
import { isYoutubeLink } from 'livo-shared/src';

type MediaGalleryProps = {
  mediaList?: File[];
  width?: Sizes;
  height?: Sizes;
  objectFit?: string;
  withThumbs?: boolean;
  withModal?: boolean;
  virtualTour?: Maybe<string>;
  calendarLink?: Maybe<string>;
  offerId?: string;
  loading?: boolean;
};

interface Dialog {
  name: string;
  props?: any;
}

type VirtualTourButtonProps = {
  virtualTour: string;
  openDialog: (dialog: Dialog) => void;
};

const VirtualTourButton: React.FC<VirtualTourButtonProps> = ({ virtualTour, openDialog }) => (
  <Button
    type="primary"
    icon={VideoIcon}
    onClick={() => openDialog({ name: VIRTUAL_TOUR_DIALOG, props: { virtualTour } })}
  >
    Virtual Tour
  </Button>
);

const VirtualTourLink: React.FC<{ virtualTour: string }> = ({ virtualTour }) => (
  <a href={virtualTour} target="_blank" rel="noopener noreferrer">
    <Button type="primary" icon={VideoIcon}>
      Virtual Tour
    </Button>
  </a>
);

const useSliderRefs = () => {
  const [sliderSlick, setSliderSlick]: any = React.useState();
  const [thumbsSliderSlick, setThumbsSliderSlick]: any = React.useState();

  const sliderSlickRef: any = React.useRef();
  const thumbsSliderRef: any = React.useRef();

  const slick = sliderSlickRef?.current?.slick;
  const thumbsSlick = thumbsSliderRef?.current?.slick;

  React.useEffect(() => {
    setSliderSlick(sliderSlickRef?.current?.slick);
    setThumbsSliderSlick(thumbsSliderRef?.current?.slick);
  }, [slick, thumbsSlick]);

  return {
    slider: { slick: sliderSlick, ref: sliderSlickRef },
    thumbsSlider: { slick: thumbsSliderSlick, ref: thumbsSliderRef },
  };
};

export const MediaGallery: React.FC<MediaGalleryProps> = ({
  withThumbs = false,
  mediaList = [],
  width,
  height,
  objectFit = 'cover',
  withModal = true,
  virtualTour,
  calendarLink,
  offerId,
  loading,
}) => {
  const { slider, thumbsSlider } = useSliderRefs();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const { openDialog } = React.useContext(dialogsContext);

  const { pathname } = useLocation();
  const match = matchPath(pathname, {
    path: AppRoutes.MANAGEMENT,
  });

  const isManagementPath = Boolean(match);

  const sliderSlickSlickToGo = React.useCallback(
    (index: number) => () => {
      slider.slick.slickGoTo(index);
    },
    [slider.slick],
  );

  const handleOpenModal = React.useCallback(() => setModalIsOpen(true), [setModalIsOpen]);
  const handleCloseModal = React.useCallback(() => setModalIsOpen(false), [setModalIsOpen]);

  const handleOpenCalendly = () => openDialog({ name: CALENDAR_DIALOG, props: { calendarLink } });
  const handleOpenScheduleShowingContact = () =>
    openDialog({ name: SCHEDULE_SHOWING_CONTACT_DIALOG, props: { offerId, loading } });

  const IsCalendarLinkExist = Boolean(calendarLink);
  return (
    <CarouselContainer>
      <CarouselWrapper width={width} height={height}>
        <Carousel arrows swipeToSlide ref={slider.ref} asNavFor={thumbsSlider.slick}>
          <Choose>
            <When condition={mediaList?.length > 0}>
              {mediaList.map(item => {
                const compressedImageUrl = getCompressedImageUrl(item?.downloadUrl, 600);
                return (
                  <CarouselImageWrapper key={compressedImageUrl} width={width} height={height}>
                    <CarouselImage src={compressedImageUrl} objectFit={objectFit} onClick={handleOpenModal} />
                  </CarouselImageWrapper>
                );
              })}
            </When>
            <Otherwise>
              <CarouselImageWrapper width={width} height={height}>
                <CarouselImage src={MediaGalleryPlaceholder} objectFit={objectFit} />
              </CarouselImageWrapper>
            </Otherwise>
          </Choose>
        </Carousel>
      </CarouselWrapper>
      {withThumbs && (
        <CarouseThumbWrapper>
          <Carousel
            swipeToSlide
            ref={thumbsSlider.ref}
            slidesToShow={4}
            dots={false}
            focusOnSelect
            centerMode
            centerPadding="0"
            arrows
            infinite
          >
            {(mediaList.length >= 4 ? mediaList : [...mediaList, ...new Array(4 - mediaList.length)]).map(
              (item, index) => {
                const compressedImageUrl = getCompressedImageUrl(item?.downloadUrl, 200);
                return (
                  <CarouselThumbImageWrapper key={compressedImageUrl ?? index} width={width} height={height}>
                    {item && (
                      <CarouselImage
                        src={compressedImageUrl}
                        objectFit={objectFit}
                        onClick={sliderSlickSlickToGo(index)}
                      />
                    )}
                  </CarouselThumbImageWrapper>
                );
              },
            )}
          </Carousel>
        </CarouseThumbWrapper>
      )}
      {withModal && (
        <ModalGateway>
          {modalIsOpen && (
            <Modal onClose={handleCloseModal}>
              <ImagesCarousel
                views={
                  (mediaList.map(item => ({
                    src: item.downloadUrl,
                    thumbnail: item.downloadUrl,
                  })) as unknown) as ViewType[]
                }
                currentIndex={slider.slick?.innerSlider?.state?.currentSlide}
                trackProps={{
                  infinite: true,
                }}
              />
            </Modal>
          )}
        </ModalGateway>
      )}
      {!isManagementPath && (
        <Grid.Layout css={{ display: 'flex', justifyContent: 'center' }}>
          {virtualTour && (
            <div css={{ textAlign: 'center', marginTop: 25 }}>
              {isYoutubeLink(virtualTour) ? (
                <VirtualTourButton virtualTour={virtualTour} openDialog={openDialog} />
              ) : (
                <VirtualTourLink virtualTour={virtualTour} />
              )}
            </div>
          )}
          <div css={{ textAlign: 'center', marginTop: 25 }}>
            <Button
              type="primary"
              icon={CalendarFilledIcon}
              onClick={IsCalendarLinkExist ? handleOpenCalendly : handleOpenScheduleShowingContact}
            >
              Schedule Showing
            </Button>
          </div>
        </Grid.Layout>
      )}
    </CarouselContainer>
  );
};
