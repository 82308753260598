import { styled } from '@/theme';
import { ColumnFlex } from '@/components';
import { css } from '@emotion/core';

export const CardWrapper = styled.div`
  position: relative;
  & > * {
    height: 100%;
  }
`;

export const OfferHeaderTag = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns: 150px 1fr;
  grid-template-rows: minmax(120px, 1fr);
  background-color: #f4f7f9;
  cursor: pointer;

  border-bottom: 1px solid ${props => props.theme.vars.primaryBorderColor};

  @media (max-width: ${props => props.theme.vars.breakpoints.md.max}) {
    grid-template-columns: 100px 1fr;
    grid-template-rows: minmax(75px, 1fr);
  }
`;

export const OfferInfoColumnTag = styled(ColumnFlex)`
  padding: 16px;
  overflow: hidden;

  @media (max-width: 767px) {
    padding: 4px;
  }
`;

export const OfferImageWrapperTag = styled.div`
  position: relative;
`;

export const OffersCountPositionTag = styled.div`
  position: absolute;
  left: 8px;
  top: 8px;
  z-index: 1;
`;

export const OfferImageTag = styled.img`
  width: 100%;
  height: 100%;
  max-height: 172px;
  max-width: 150px;
  object-fit: cover;
`;

export const PropertyCardsTag = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: min-content;
  grid-gap: 20px;
  position: relative;
  height: 100%;

  @media (max-width: ${props => props.theme.vars.breakpoints.lg.max}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${props => props.theme.vars.breakpoints.sm.max}) {
    grid-template-columns: 1fr;
  }
`;

export const OffersCardsEmptyTag = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #929ea8;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  flex-direction: column;
`;

export const OffersCardsEmptyTitle = styled.div`
  max-width: 215px;
  text-align: center;
`;

export const OffersCardsEmptyLogo = css`
  margin-bottom: 30px;
  width: 228px;
  height: 52px;
  filter: grayscale(100%);
`;
