import gql from 'graphql-tag';

export const COMPANY_FRAGMENT = gql`
  fragment Company on Company {
    name
    clientId
    contactName
    contactEmail
    virtualShowings
    contactPhoneNumber {
      code
      number
    }
    status
    type
    primaryPhoneNumber {
      code
      number
    }
    primaryAddress {
      country
      street1
      street2
      zip
      city
      state
    }
    website
    companySize
    companyLogo {
      id
      downloadUrl
      filename
      meta
    }
    documents {
      items {
        id
        downloadUrl
        filename
        meta
      }
    }
    mdu {
      id
      type
      billing
      numberOfPropertiesManaged
    }
    mits
    listingServices {
      items {
        id
        name
        url
      }
    }
    propertyManagementSoftwareSystems {
      items {
        id
        name
        url
      }
    }
    companyPropertyManagementSoftwareSystems
    companyListingServices
    thirdPartyScreeningCompanyName
    thirdPartyOnlineApplicationName
    isUsingIls
    isUsingPms
    onlineApplicationProcess
    screeningProcess
  }
`;

export const COMPANY_CREATE_MUTATION = gql`
  mutation CompanyCreate($data: CompanyCreateInput!) {
    companyCreate(data: $data) {
      id
      ...Company
    }
  }

  ${COMPANY_FRAGMENT}
`;

export const COMPANY_UPDATE_MUTATION = gql`
  mutation CompanyUpdate($data: CompanyUpdateInput!, $force: Boolean) {
    companyUpdate(data: $data, force: $force) {
      id
      ...Company
    }
  }

  ${COMPANY_FRAGMENT}
`;

export const COMPANY_QUERY = gql`
  query CompanyInformation($id: ID!) {
    company(id: $id) {
      id
      ...Company
    }
  }

  ${COMPANY_FRAGMENT}
`;

export const CURRENT_USER_COMPANY_QUERY = gql`
  query CompanyInformation {
    user {
      id
      company {
        id
        ...Company
      }
    }
  }

  ${COMPANY_FRAGMENT}
`;
