import styled from '@/theme/styled';
import { css } from '@emotion/core';

import { LEFT_PADDING } from '../../constants';

export const defaultLogoStyles = (isSidebarOpen?: boolean) => css`
  opacity: ${isSidebarOpen ? 1 : 0};
  transition: 0.3s;
`;

export const smallLogoStyles = (isSidebarOpen?: boolean) => css`
  opacity: ${isSidebarOpen ? 0 : 1};
  transition: 0.3s;
`;

const LogoSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding-left: ${LEFT_PADDING}px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;

  > svg {
    position: absolute;
  }
`;

const NavParagraph = styled.p<{ isSidebarOpen?: boolean }>`
  line-height: 42px;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #929ea8;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  transform: translateX(30px);
  transition: 0.2s;
  max-width: 185px;

  ${({ isSidebarOpen }) =>
    !isSidebarOpen &&
    css`
      max-width: 50px;
      transform: translateX(18px);
    `}
`;

const CountTag = styled.div<{ isActive?: boolean; isSidebarOpen?: boolean }>`
  width: 20px;
  height: 15px;
  line-height: 15px;
  margin-left: auto;
  margin-right: ${({ isSidebarOpen }) => isSidebarOpen && '20px'};
  text-align: center;
  border-radius: 5px;
  font-size: 1rem;
  background-color: ${({ theme, isActive }) => (isActive ? theme.vars.primaryColor : '#fff')};
  color: ${({ theme }) => theme.vars.black};

  ${({ isSidebarOpen, theme, isActive }) =>
    isSidebarOpen
      ? css`
          background-color: ${isActive ? theme.vars.primaryColor : '#fff'};
          color: ${isActive ? '#fff' : theme.vars.black};
        `
      : css`
          background-color: ${isActive ? theme.vars.primaryColor : theme.vars.black};
          color: #fff;
        `}
`;

const CommunityCountTag = styled.span`
  width: 20px;
  height: 15px;
  display: inline-block;
  line-height: 15px;
  margin-left: 15px;
  margin-right: auto;
  text-align: center;
  border-radius: 5px;
  font-size: 1rem;
  color: ${({ theme }) => theme.vars.black};
  background-color: #fff;
  vertical-align: 0.27em;
`;

export { LEFT_PADDING, LogoSection, NavParagraph, CountTag, CommunityCountTag };
