import gql from 'graphql-tag';

export const OFFER_NAME_QUERY = gql`
  query OfferName($id: ID!) {
    offer(id: $id) {
      id
      historyOfferSingleUnit {
        id
        name
      }
    }
  }
`;
