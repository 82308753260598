import { useQuery } from 'react-apollo';
import { Company } from '@/shared/types/graphql';
import { useCurrentCompanyId } from '@/shared/hooks/useCurrentCompanyId';
import gql from 'graphql-tag';

const COMPANY_QUERY = gql`
  query CurrentCompany($id: ID!) {
    company(id: $id) {
      id
      name
      type
      user {
        items {
          id
          roles {
            items {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const useCurrentCompany = () => {
  const { companyId } = useCurrentCompanyId();

  const { data, ...rest } = useQuery<{ company: Company }>(COMPANY_QUERY, {
    skip: !companyId,
    variables: {
      id: companyId,
    },
  });

  return { company: data?.company, ...rest };
};
