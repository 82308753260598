import { Maybe } from '@/shared/types/graphql';

export const parseNumber = (value?: Maybe<string>, round = false) => {
  const withoutCommas = value && value.replace(/,/g, '');
  const number = Number(withoutCommas);

  if (Number.isNaN(number)) {
    return;
  }

  return round ? Math.round(number) : number;
};

export const parseNumberWithRound = (value?: Maybe<string>) => {
  return parseNumber(value, true);
};
