import React from 'react';
import { stringifyHref } from '@/shared/utils';
import { Link } from '@/components';
import { Maybe } from '@/shared/types/graphql';
import { NOT_AVALIABLE } from '@/shared/constants';

type WebsiteLinkProps = {
  text?: Maybe<string>;
  website?: Maybe<string>;
};

export const WebsiteLink: React.FC<WebsiteLinkProps> = ({ website, text }) => {
  if (!website) {
    return <>{NOT_AVALIABLE}</>;
  }

  const href = stringifyHref(website);

  const LIMIT_LINK_LENGTH = 25;
  const isLargeLink = href.length > LIMIT_LINK_LENGTH;
  const shortLink = isLargeLink ? `${href.slice(0, LIMIT_LINK_LENGTH)}...` : href;

  return (
    <Link href={href} target="_blank" title={isLargeLink ? href : ''}>
      {text ? text : shortLink}
    </Link>
  );
};
