import gql from 'graphql-tag';

export const RANKING_UPDATE_MODAL_QUERY = gql`
  query RankingConstantQuery($id: ID) {
    environmentVariable(id: $id) {
      id
      name
      value
    }
  }
`;

export const RANKING_UPDATE_MODAL_MUTATION = gql`
  mutation RankingConstantsUpdate($data: EnvironmentVariableUpdateInput!, $filter: EnvironmentVariableKeyFilter) {
    environmentVariableUpdate(data: $data, filter: $filter) {
      id
      name
      value
    }
  }
`;
