import { COMMUNITY_STATUSES } from '@/shared/constants';
import { usePermissions, useCurrentCompanyId, useCurrentCompany } from '@/shared/hooks';
import { Community, Maybe } from '@/shared/types/graphql';

export const useRentalsAmountQueryFilter = () => {
  const { companyId } = useCurrentCompanyId();
  const { isAdmin, isCompanyOwner, allowedCommunities } = usePermissions();

  const all: any = [
    {
      historyOfferSingleUnit: {
        company: {
          id: {
            equals: companyId,
          },
        },
      },
    },
    !(isAdmin || isCompanyOwner) && {
      historyOfferSingleUnit: {
        community: {
          id: {
            in: allowedCommunities.map(({ id }) => id),
          },
        },
      },
    },
  ].filter(Boolean);

  return {
    AND: all,
  };
};

export const useNavigationCommunitiesQueryFilter = () => {
  const { companyId } = useCurrentCompanyId();
  const { isAdmin, isCompanyOwner, allowedCommunities } = usePermissions();

  const all: any = [
    {
      status: {
        not_equals: COMMUNITY_STATUSES.draft,
      },
    },
    {
      company: {
        id: {
          equals: companyId,
        },
      },
    },
    !(isAdmin || isCompanyOwner) && {
      id: {
        in: allowedCommunities.map(({ id }) => id),
      },
    },
  ].filter(Boolean);

  return {
    AND: all,
  };
};

export const useNavigationSingleUnitsQueryFilter = () => {
  const { isAdmin, isCompanyOwner, allowedSingleUnits } = usePermissions();
  const { company } = useCurrentCompany();
  const companyId = company?.id;

  const all: any = [
    {
      status: {
        not_equals: COMMUNITY_STATUSES.draft,
      },
    },
    {
      company: {
        id: {
          equals: companyId,
        },
      },
    },
    !(isAdmin || isCompanyOwner) && {
      id: {
        in: allowedSingleUnits.map(({ id }) => id),
      },
    },
  ].filter(Boolean);

  return {
    AND: all,
  };
};

export const getCommunitiesLinks = ({
  communities,
  companyId,
}: {
  communities: Community[];
  companyId?: Maybe<string>;
}) => {
  if (communities.length > 10) {
    return undefined;
  }

  return communities.map(community => ({
    name: community.name ?? '',
    path: `/management/${companyId}/communities/${community.id}/single-units`,
  }));
};
