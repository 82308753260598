import React from 'react';
import { Link } from 'react-router-dom';
import styled from '@/theme/styled';
import { useAuth } from '8base-react-sdk';
import { AppRoutes } from '@/shared/constants/appRoutes';

const Logout = styled.span`
  font-size: 2rem;
  font-weight: 500;
  color: ${({ theme }) => theme.vars.black};
`;

const MobileNavLink = styled(Link)`
  font-size: 2rem;
  font-weight: 500;
  color: ${({ theme }) => theme.vars.black};
`;

const MobileNavListTag = styled.ul`
  position: fixed;
  z-index: 99;
  top: 60px;
  left: 0;
  width: 100%;
  height: calc(100vh - 60px);
  margin: 0 !important;
  padding: 24px;

  background-color: #fff;
`;

const MobileNavListItem = styled.li`
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
`;

type MobileNavListProps = {
  isRenter: boolean;
  handleToggleNav: () => void;
};

export const MobileNavList = ({ isRenter, handleToggleNav }: MobileNavListProps) => {
  const auth = useAuth();

  const handleLogout = async () => {
    await auth.authClient.logout();
    await auth.authClient.purgeState();
    handleToggleNav();
  };

  return (
    <MobileNavListTag>
      {isRenter ? (
        <>
          <MobileNavListItem>
            <MobileNavLink to={AppRoutes.RENTALS} onClick={handleToggleNav}>
              Livo Transactions
            </MobileNavLink>
          </MobileNavListItem>
          <MobileNavListItem>
            <MobileNavLink to={AppRoutes.OFFERS} onClick={handleToggleNav}>
              My Rentals
            </MobileNavLink>
          </MobileNavListItem>
        </>
      ) : (
        <MobileNavListItem>
          <MobileNavLink to="/management">Management</MobileNavLink>
        </MobileNavListItem>
      )}
      <MobileNavListItem>
        <MobileNavLink to={AppRoutes.ACCOUNT} onClick={handleToggleNav}>
          My Profile
        </MobileNavLink>
      </MobileNavListItem>
      <MobileNavListItem>
        <Logout onClick={handleLogout}>Logout</Logout>
      </MobileNavListItem>
    </MobileNavListTag>
  );
};
