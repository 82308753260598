import * as yup from 'yup';
import { AddressSchema, PhoneSchemaRequired, UrlSchema } from '../common';
import { SHOWING_TYPE } from '@/shared/constants';
import { trimSpaces } from '@/shared/validators';

export const SingleUnitSchemaValidation = yup.object({
  name: yup
    .string()
    .nullable()
    .test({
      test: trimSpaces,
      message: "Can't be empty",
    })
    .required('Name is required'),

  type: yup
    .string()
    .nullable()
    .required('Type is required'),

  description: yup
    .string()
    .test({
      test: trimSpaces,
      message: "Can't be empty",
    })
    .nullable()
    .required('Description is required'),

  website: UrlSchema,

  phone: PhoneSchemaRequired,
  address: AddressSchema,

  bedrooms: yup
    .number()
    .integer()
    .min(0)
    .nullable()
    .required('Bedrooms is required'),

  bathrooms: yup
    .number()
    .min(0)
    .test({
      test: value => value % 0.5 === 0,
      message: 'Should be multiple by 0.5',
    })
    .nullable()
    .required('Baths is required'),

  squareFt: yup
    .number()
    .integer()
    .min(0)
    .nullable()
    .required('Square Ft. is required'),

  virtualTour: yup
    .string()
    .url('URL is invalid')
    .nullable(),

  singleUnitHasMedia: yup
    .array()
    .of(yup.object())
    .nullable(),

  showingType: yup
    .array()
    .of(yup.string().oneOf(Object.keys(SHOWING_TYPE), 'There is no such type'))
    .nullable()
    .required('Showing Type is required'),
});

export const MduSingleUnitSchemaValidation = yup.object({
  name: yup
    .string()
    .nullable()
    .test({
      test: trimSpaces,
      message: "Can't be empty",
    })
    .required('Name is required'),

  type: yup
    .string()
    .nullable()
    .required('Type is required'),

  description: yup
    .string()
    .test({
      test: trimSpaces,
      message: "Can't be empty",
    })
    .nullable()
    .required('Description is required'),

  website: UrlSchema,

  phone: PhoneSchemaRequired,
  address: AddressSchema,

  bedrooms: yup
    .number()
    .integer()
    .min(0)
    .nullable()
    .required('Bedrooms is required'),

  bathrooms: yup
    .number()
    .min(0)
    .test({
      test: value => value % 0.5 === 0,
      message: 'Should be multiple by 0.5',
    })
    .nullable()
    .required('Baths is required'),

  squareFt: yup
    .number()
    .integer()
    .min(0)
    .nullable()
    .required('Square Ft. is required'),

  virtualTour: yup
    .string()
    .url('URL is invalid')
    .nullable(),

  singleUnitHasMedia: yup
    .array()
    .of(yup.object())
    .nullable(),

  showingType: yup
    .array()
    .of(yup.string().oneOf(Object.keys(SHOWING_TYPE), 'There is no such type'))
    .nullable()
    .required('Showing Type is required'),
});
