import gql from 'graphql-tag';

export const ALL_LEADS_RENTERS_LIST_QUERY = gql`
  query LeadsRentersListQuery($filter: RenterFilter, $first: Int, $skip: Int, $sort: [RenterSort!]) {
    rentersList(filter: $filter, first: $first, skip: $skip, sort: $sort) {
      items {
        id
        address {
          state
          zip
          street1
          street2
          city
        }
        phone {
          code
          number
        }
        user {
          id
          firstName
          lastName
          email
          isBlocked
        }
      }
      count
    }
  }
`;
