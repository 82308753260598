import React from 'react';
import { Dropdown, Icon, Menu } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useQuery } from 'react-apollo';
import { DateTime } from 'luxon';

import { Offer as OfferType, Bid } from '@/shared/types/graphql';
import { useTableState, TableState, UseTableStateResponse } from '@/shared/hooks';
import { getFullName, checkOfferChecklistComplete, formatAmount } from '@/shared/utils';
import { dialogsContext } from '@/providers';
import { OFFER_STATUSES } from '@/shared/constants';
import { Table } from '@/components';

import { RENTERS_QUERY } from '../queries';
import { BidStatusTag } from '@/pro';
import { BIDDER_AWARD_DIALOG, BIDDER_REJECT_DIALOG, RENTER_DETAILS_WITH_BID_DIALOG } from '@/dialogs';

const getSort = ({ sortBy, sortOrder }: TableState) => {
  let sort: Record<string, any>[] = [];

  if (sortBy && sortOrder) {
    const field = sortBy;
    const direction = sortOrder === 'ascend' ? 'ASC' : 'DESC';

    // TODO: Nested sorting wasn't working, wainting on server-side response
    if (field === 'name') {
      // sort = [
      //   {
      //     renter: {
      //       user: {
      //         firstName: direction,
      //       },
      //     },
      //   },
      //   {
      //     renter: {
      //       user: {
      //         lastName: direction,
      //       },
      //     },
      //   },
      // ];
    } else {
      sort = [
        {
          [field]: direction,
        },
      ];
    }
  }

  return sort;
};

const BidsTableActions = (bid: Bid) => {
  const { openDialog } = React.useContext(dialogsContext);

  const openBidderAwardModal = React.useCallback(() => {
    openDialog({
      name: BIDDER_AWARD_DIALOG,
      props: {
        bidderId: bid.id,
        refetchQueries: ['OfferBidsList'],
      },
    });
  }, [bid.id, openDialog]);

  const openRenterDetailsWithBidModal = React.useCallback(() => {
    openDialog({
      name: RENTER_DETAILS_WITH_BID_DIALOG,
      props: {
        renterId: bid?.renter?.id,
        offerId: bid?.offer?.id,
      },
    });
  }, [bid, openDialog]);

  const openBidderRejectModal = React.useCallback(() => {
    openDialog({
      name: BIDDER_REJECT_DIALOG,
      props: {
        bidderId: bid.id,
        refetchQueries: ['OfferBidsList'],
      },
    });
  }, [openDialog, bid.id]);

  const offerStatus = bid?.offer?.status;
  const statusIsLeased = offerStatus === OFFER_STATUSES.leased;
  const statusIsCanceled = offerStatus === OFFER_STATUSES.canceled;

  const checklistComplete = checkOfferChecklistComplete(bid);

  const menu = (
    <Menu>
      <Menu.Item onClick={openRenterDetailsWithBidModal}>View Details</Menu.Item>
      <If condition={checklistComplete && !statusIsLeased && !statusIsCanceled}>
        <Menu.Item onClick={openBidderAwardModal}>Award Lease</Menu.Item>
      </If>
      <If condition={!statusIsLeased && !statusIsCanceled}>
        <Menu.Item onClick={openBidderRejectModal}>Reject</Menu.Item>
      </If>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
      <Icon type="ellipsis" />
    </Dropdown>
  );
};

type BidsTableProps = {
  query?: string;
  offerId?: string | null;
};

export const BidsTable = ({ query, offerId }: BidsTableProps) => {
  const [{ page, sortBy, sortOrder, pageSize, pageSizeOptions }, onChange]: UseTableStateResponse = useTableState({
    query,
  });

  const filter = query
    ? {
        OR: [
          {
            renter: {
              user: {
                firstName: {
                  contains: query,
                },
              },
            },
          },
          {
            renter: {
              user: {
                lastName: {
                  contains: query,
                },
              },
            },
          },
        ],
      }
    : null;

  const { data: offerBidsListData, loading: offerBidsListLoading } = useQuery<{ offer: OfferType }>(RENTERS_QUERY, {
    variables: {
      id: offerId,
      filter,
      sort: getSort({ page, sortOrder, sortBy, pageSize }),
      first: pageSize,
      skip: (page - 1) * pageSize,
    },
    skip: !offerId,
  });

  const dataSource = offerBidsListData?.offer?.bid?.items || [];
  const total = offerBidsListData?.offer?.bid?.count;
  const pagination = { current: page, pageSize, total, showSizeChanger: true, pageSizeOptions };

  const columns: ColumnProps<Bid>[] = [
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      ellipsis: true,
      sorter: false,
      sortOrder: sortBy === 'name' && sortOrder,
      render: (value, { renter }) => getFullName(renter?.user?.firstName, renter?.user?.lastName),
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      ellipsis: true,
      sorter: dataSource?.length !== 0,
      sortOrder: sortBy === 'status' && sortOrder,
      render: function renderStatus(status) {
        return <BidStatusTag status={status} />;
      },
    },
    {
      key: 'moveinDate',
      title: 'Move-in',
      dataIndex: 'moveinDate',
      ellipsis: true,
      sorter: dataSource?.length !== 0,
      sortOrder: sortBy === 'moveinDate' && sortOrder,
      render: value => DateTime.fromISO(value).toFormat('D'),
    },
    {
      key: 'termsOfLease',
      title: 'Lease',
      dataIndex: 'termsOfLease',
      ellipsis: true,
      sorter: dataSource?.length !== 0,
      sortOrder: sortBy === 'termsOfLease' && sortOrder,
      render: value => `${value} Mo.`,
    },
    {
      key: 'rentAmount',
      title: 'Bid',
      dataIndex: 'rentAmount',
      ellipsis: true,
      sorter: dataSource?.length !== 0,
      sortOrder: sortBy === 'rentAmount' && sortOrder,
      render: value => `$${formatAmount(value)}`,
    },
    {
      key: 'actions',
      title: '',
      width: '48px',
      render: function renderBidsTableActions(record) {
        return <BidsTableActions {...record} />;
      },
    },
  ];

  return (
    <Table
      loading={offerBidsListLoading}
      columns={columns}
      dataSource={dataSource}
      pagination={pagination}
      onChange={onChange}
    />
  );
};
