import gql from 'graphql-tag';

export const AMENITY_GROUPS_QUERY = gql`
  query AmenityGroupsList($filter: AmenityGroupFilter) {
    amenityGroupsList(filter: $filter) {
      items {
        id
        key
      }
    }
  }
`;

export const DEFAULT_AMENITY_QUERY = gql`
  query DefaultAmenityQuery($id: ID!) {
    defaultAmenity(id: $id) {
      id
      label
      category {
        id
        key
      }
    }
  }
`;

export const DEFAULT_AMENITY_UPDATE_MUTATION = gql`
  mutation DefaultAmenityUpdate($data: DefaultAmenityUpdateInput!, $id: ID!) {
    defaultAmenityUpdate(data: $data, filter: { id: $id }) {
      id
      label
      category {
        id
        key
      }
    }
  }
`;
