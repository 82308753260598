import { styled } from '@/theme';

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg';

export const HideOn = styled.div<{ breakpoints: Breakpoint[] }>(({ theme, breakpoints }) => {
  let styles = ``;
  if (breakpoints.includes('xs')) {
    styles =
      styles +
      `
  @media (max-width: ${theme.vars.breakpoints.xs.max}) {
    display: none;
  }
`;
  }

  if (breakpoints.includes('sm')) {
    styles =
      styles +
      `
  @media (min-width: ${theme.vars.breakpoints.sm.min}) and (max-width: ${theme.vars.breakpoints.sm.max}) {
    display: none;
  }
`;
  }

  if (breakpoints.includes('md')) {
    styles =
      styles +
      `
  @media (min-width: ${theme.vars.breakpoints.md.min}) and (max-width: ${theme.vars.breakpoints.md.max}) {
    display: none;
  }
`;
  }

  if (breakpoints.includes('lg')) {
    styles =
      styles +
      `
  @media (min-width: ${theme.vars.breakpoints.lg.min}) {
    display: none;
  }
`;
  }

  return styles;
});

export const Responsive = {
  HideOn,
};
