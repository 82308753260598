import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';
import { Dropdown, Icon, Menu } from 'antd';
import copy from 'copy-to-clipboard';

import { PORTFOLIO_SINGLE_UNITS_LIST_QUERY } from './queries';
import {
  TableState,
  useTableState,
  UseTableStateResponse,
  useCurrentCompanyId,
  useCurrentCompany,
  useDialogState,
  usePermissions,
} from '@/shared/hooks';
import { stringifyAddress, stringifyDateTime, stringifySingleUnitType } from '@/shared/utils';
import { ColumnProps } from 'antd/lib/table';
import { dialogsContext } from '@/providers';
import { CreateOfferSwitch, Table } from '@/components';
import { SingleUnit, SingleUnitListResponse } from '@/shared/types/graphql';
import { DRAFT_STATUS, SINGLE_UNIT_STATUSES, OFFER_STATUSES } from '@/shared/constants';
import { SingleUnitStatusTag, ConditionalLink } from '@/pro';
import { isOfferSwitchChecked } from '@/pro/CreateOfferSwitch';
import { stringifyLeaseAmount } from '@/shared/utils';
import { getSingleRentalUrl } from '@/shared/constants/appRoutes';
import {
  OFFER_UPDATE_DIALOG,
  SINGLE_UNIT_UPDATE_DIALOG,
  SINGLE_UNIT_DELETE_DIALOG,
  INVITE_RENTERS_DIALOG,
} from '@/dialogs';

const getSort = (queryParams: TableState) => {
  let sort: Record<string, any>[] = [];

  if (queryParams.sortBy && queryParams.sortOrder) {
    const field = queryParams.sortBy;
    const direction = queryParams.sortOrder === 'ascend' ? 'ASC' : 'DESC';

    if (field === 'address') {
      sort = [
        {
          address: {
            street1: direction,
          },
        },
      ];
    } else if (field) {
      sort = [
        {
          [field]: direction,
        },
      ];
    }
  }

  return sort;
};

const SingleUnitsTableEnableLivo = (singleUnit: SingleUnit) => {
  return <CreateOfferSwitch singleUnit={singleUnit} refetchQueries={['PortfolioSingleUnitsList']} />;
};

type SingleUnitsTableActionsProps = {
  singleUnit: SingleUnit;
};

const SingleUnitsTableActions = ({ singleUnit }: SingleUnitsTableActionsProps) => {
  const { openDialog } = React.useContext(dialogsContext);
  const { props: singleUnitUpdateDialogProps } = useDialogState(SINGLE_UNIT_UPDATE_DIALOG);
  const { props: singleUnitDeleteDialogProps } = useDialogState(SINGLE_UNIT_DELETE_DIALOG);
  const { props: inviteRentersDialogProps } = useDialogState(INVITE_RENTERS_DIALOG);

  const singleUnitId = singleUnit?.id;
  const offerId = singleUnit?.activeOffer?.id;

  const bidsCount = singleUnit?.activeOffer?.bid?.count || 0;
  const noBids = bidsCount === 0;

  const { company } = useCurrentCompany();
  const companyType = company?.type;

  const offerStatusIsLeased = singleUnit?.activeOffer?.status === OFFER_STATUSES.leased;
  const offerStatusIsCanceled = singleUnit?.activeOffer?.status === OFFER_STATUSES.canceled;
  const singleUnitStatusIsDraft = singleUnit?.status === SINGLE_UNIT_STATUSES.draft;

  const onCopy = React.useCallback(() => {
    copy(`${window.location.origin}${getSingleRentalUrl(offerId)}`);
  }, [offerId]);

  const menu = (
    <Menu>
      <If condition={noBids}>
        <Menu.Item
          onClick={() =>
            openDialog({
              name: SINGLE_UNIT_UPDATE_DIALOG,
              props: {
                ...singleUnitUpdateDialogProps,
                singleUnitId,
                companyType,
                refetchQueries: ['NavigationSingleUnitsList', 'NavigationCommunitiesList'],
              },
            })
          }
        >
          Edit Unit
        </Menu.Item>
      </If>
      <If condition={!offerStatusIsLeased && !offerStatusIsCanceled && !singleUnitStatusIsDraft && noBids}>
        <Menu.Item
          onClick={() =>
            openDialog({
              name: OFFER_UPDATE_DIALOG,
              props: {
                singleUnitId,
                offerId,
                refetchQueries: ['NavigationSingleUnitsList', 'NavigationCommunitiesList'],
              },
            })
          }
        >
          Edit Livo Transaction
        </Menu.Item>
      </If>
      <If condition={!singleUnitStatusIsDraft && isOfferSwitchChecked(singleUnit?.activeOffer)}>
        <Menu.Item onClick={onCopy} data-e2e-id="menu.copy">
          Copy Public Link
        </Menu.Item>
        <Menu.Item
          onClick={() =>
            openDialog({
              name: INVITE_RENTERS_DIALOG,
              props: {
                ...inviteRentersDialogProps,
                offerId,
              },
            })
          }
        >
          Invite Renters
        </Menu.Item>
      </If>
      <Menu.Item
        onClick={() =>
          openDialog({
            name: SINGLE_UNIT_DELETE_DIALOG,
            props: {
              ...singleUnitDeleteDialogProps,
              singleUnitId,
              refetchQueries: ['PortfolioSingleUnitsList', 'NavigationSingleUnitsList', 'NavigationCommunitiesList'],
            },
          })
        }
        data-e2e-id="menu.delete"
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
      <Icon type="ellipsis" />
    </Dropdown>
  );
};

type SingleUnitsTableProps = {
  query: string;
  getSelectedRecords: (records: Array<string>) => void;
  setSelectedRecords: Array<string>;
};

export const SingleUnitsTable = ({ query, getSelectedRecords, setSelectedRecords }: SingleUnitsTableProps) => {
  const [{ page, sortBy, sortOrder, pageSize, pageSizeOptions }, onChange]: UseTableStateResponse = useTableState({
    query,
  });
  const { companyId } = useCurrentCompanyId();
  const { isAdmin, isCompanyOwner, allowedSingleUnits } = usePermissions();
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  useEffect(() => setSelectedRowKeys(setSelectedRecords), [selectedRowKeys, setSelectedRecords]);

  const { data, loading } = useQuery<{ singleUnitsList: SingleUnitListResponse }>(PORTFOLIO_SINGLE_UNITS_LIST_QUERY, {
    variables: {
      filter: {
        AND: [
          {
            company: {
              id: {
                equals: companyId,
              },
            },
          },
          !(isAdmin || isCompanyOwner) && {
            id: {
              in: allowedSingleUnits.map(({ id }) => id),
            },
          },
          query !== ''
            ? {
                name: {
                  contains: query,
                },
              }
            : {},
        ].filter(Boolean),
      },
      sort: getSort({ sortOrder, sortBy, page, pageSize }),
      first: pageSize,
      skip: (page - 1) * pageSize,
    },
  });

  const dataSource = data?.singleUnitsList?.items ?? [];
  const total = data?.singleUnitsList?.count ?? pageSize;

  const pagination = { current: page, pageSize, total, showSizeChanger: true, pageSizeOptions };

  const columns: ColumnProps<SingleUnit>[] = React.useMemo(
    () => [
      {
        title: 'id',
        dataIndex: 'singleUnitId',
        sorter: dataSource?.length !== 0,
        width: '10%',
        ellipsis: true,
        sortOrder: sortBy === 'singleUnitId' ? sortOrder : undefined,
      },
      {
        title: 'Unit / Name',
        dataIndex: 'name',
        sorter: dataSource?.length !== 0,
        width: '20%',
        ellipsis: true,
        sortOrder: sortBy === 'name' ? sortOrder : undefined,
        render: function renderSingleUnitLink(name, record) {
          return (
            <ConditionalLink
              to={`/management/${companyId}/single-units/${record.id}/details`}
              condition={record.status !== DRAFT_STATUS}
              text={name}
            />
          );
        },
      },
      {
        title: 'Type',
        dataIndex: 'type',
        sorter: dataSource?.length !== 0,
        width: '15%',
        ellipsis: true,
        sortOrder: sortBy === 'type' ? sortOrder : undefined,
        render: stringifySingleUnitType,
      },
      {
        title: 'Address',
        dataIndex: 'address',
        sorter: dataSource?.length !== 0,
        width: '15%',
        ellipsis: true,
        sortOrder: sortBy === 'address' ? sortOrder : undefined,
        render: stringifyAddress,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        sorter: dataSource?.length !== 0,
        width: '10%',
        ellipsis: true,
        render: function renderSingleUnitStatus(status?: string) {
          return <SingleUnitStatusTag status={status} />;
        },
      },
      {
        title: 'Last Updated',
        dataIndex: 'updatedAt',
        sorter: dataSource?.length !== 0,
        ellipsis: true,
        sortOrder: sortBy === 'updatedAt' ? sortOrder : undefined,
        render: stringifyDateTime,
      },
      {
        title: 'Lease Start',
        dataIndex: 'leaseStart',
        width: '10%',
        render: stringifyDateTime,
      },
      {
        title: 'Lease End',
        dataIndex: 'leaseEnd',
        width: '10%',
        render: stringifyDateTime,
      },
      {
        title: 'Lease Amount',
        dataIndex: 'leaseRent',
        width: '10%',
        render: stringifyLeaseAmount,
      },
      {
        title: 'Enable Livo Transaction',
        width: '10%',
        render: SingleUnitsTableEnableLivo,
      },
      {
        title: '',
        dataIndex: 'id',
        width: '43px',
        render: function renderSingleUnitActions(id: string, record) {
          return <SingleUnitsTableActions singleUnit={record} />;
        },
      },
    ],
    [companyId, dataSource, sortBy, sortOrder],
  );

  const onSelectChange = (selectedRowKeys: any) => {
    getSelectedRecords(selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    onChange: onSelectChange,
    selectedRowKeys,
    getCheckboxProps: (record: any) => ({
      disabled: !record.id,
      name: record.name,
    }),
  };

  return (
    <Table
      rowKey="id"
      loading={loading}
      rowSelection={{ type: 'checkbox', ...rowSelection }}
      dataSource={dataSource}
      columns={columns}
      onChange={onChange}
      pagination={pagination}
    />
  );
};
