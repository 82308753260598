import React from 'react';
import styled from '@emotion/styled';

import { StepData } from '../types';

const Container = styled.div`
  padding-top: 100px;
  text-align: center;
`;

const Img = styled.img`
  display: inline !important;
`;

// TODO: use colours and fonts from theme
const Title = styled.div`
  display: inline-block;
  width: 700px;
  max-width: 95%;
  margin-top: 33px;
  color: #323c47;
  font-size: 26px;
  line-height: 38px;
  font-weight: 500;
  letter-spacing: 0;
`;

// TODO: use colours and fonts from theme
const Description = styled.div`
  display: inline-block;
  width: 500px;
  margin-top: 13px;
  color: #878c93;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
`;

export const OnboardingStep = ({ title, description, image, step }: StepData) => {
  return (
    <Container>
      <Img css={step === 0 ? { maxWidth: '85%', height: 265, objectFit: 'contain' } : {}} src={image} alt={title} />
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Container>
  );
};
