import { useCurrentUser } from './useCurrentUser';
import { OFFER_STATUSES, BID_STATUSES, OFFER_COMBINED_STATUSES } from '@/shared/constants';
import { Offer, Maybe } from '@/shared/types/graphql';

const checkIsOfferEnded = (offer?: Maybe<Offer>) => {
  switch (offer?.status) {
    case OFFER_STATUSES.secured:
    case OFFER_STATUSES.closed:
    case OFFER_STATUSES.canceled:
    case OFFER_STATUSES.ended: {
      return true;
    }
    default: {
      return false;
    }
  }
};

interface OfferCombinedStatusInterface {
  kind: keyof typeof OFFER_COMBINED_STATUSES;
  offerCardDisabled: boolean;
}

export function useOfferBidCombinedStatus(offer?: Offer | null): OfferCombinedStatusInterface {
  const { user: currentUser } = useCurrentUser();

  if (!offer) {
    return { kind: OFFER_COMBINED_STATUSES.none, offerCardDisabled: true };
  }

  if (offer.status === OFFER_STATUSES.draft) {
    return { kind: OFFER_COMBINED_STATUSES.draft, offerCardDisabled: true };
  }

  const bidsList = offer?.bid?.items || [];
  const filteredBids = bidsList.filter(bid => bid?.status !== BID_STATUSES.rejected);
  const isOfferLeased = offer?.status === OFFER_STATUSES.leased;
  const isOfferPending = offer?.status === OFFER_STATUSES.pending;
  const isOfferEnded = checkIsOfferEnded(offer);
  const userBidIndex = bidsList.findIndex(bid => bid?.renter?.user?.id === currentUser?.id);
  const userBid = bidsList[userBidIndex];
  const userBidStatus = bidsList[userBidIndex]?.status;
  const bidderPlace = filteredBids.findIndex(bid => bid?.renter?.user?.id === currentUser?.id) + 1;
  const isUserLeader = bidderPlace === 1;
  const isUserWinner = isUserLeader && isOfferEnded;

  if (userBidStatus === BID_STATUSES.rejected) {
    return { kind: OFFER_COMBINED_STATUSES.rejected, offerCardDisabled: true };
  }

  if (userBidStatus === BID_STATUSES.canceled || offer?.status === OFFER_STATUSES.canceled) {
    return { kind: OFFER_COMBINED_STATUSES.canceled, offerCardDisabled: true };
  }

  if (isOfferLeased && isUserLeader) {
    return { kind: OFFER_COMBINED_STATUSES.awarded, offerCardDisabled: false };
  }

  if (isOfferLeased) {
    return { kind: OFFER_COMBINED_STATUSES.leased, offerCardDisabled: true };
  }

  if (isOfferPending) {
    return { kind: OFFER_COMBINED_STATUSES.pending, offerCardDisabled: true };
  }

  if (isUserWinner && isOfferEnded && !userBid?.offerAccepted) {
    return { kind: OFFER_COMBINED_STATUSES['you-accept'], offerCardDisabled: false };
  }

  if (isUserWinner && offer?.status === OFFER_STATUSES.secured && userBidStatus === BID_STATUSES.secured) {
    return { kind: OFFER_COMBINED_STATUSES['you-secured'], offerCardDisabled: false };
  }

  if (isUserWinner) {
    return { kind: OFFER_COMBINED_STATUSES['you-won'], offerCardDisabled: false };
  }

  if (isOfferEnded) {
    return { kind: OFFER_COMBINED_STATUSES['ended'], offerCardDisabled: true };
  }

  return { kind: OFFER_COMBINED_STATUSES.live, offerCardDisabled: false };
}
