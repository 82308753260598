import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '8base-react-sdk';

interface GuestRouteInterface {
  component: React.ElementType;
  exact?: boolean;
  path: string;
}

export const GuestRoute = ({ component: Component, ...rest }: GuestRouteInterface) => {
  const { isAuthorized } = useAuth();

  return (
    <Route
      {...rest}
      render={props =>
        !isAuthorized ? <Component {...props} /> : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      }
    />
  );
};
