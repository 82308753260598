import * as yup from 'yup';
import { AddressSchema, PhoneSchemaRequired, UrlSchema } from '../common';
import { trimSpaces } from '@/shared/validators';

const demographicInformationSchema = {
  name: yup
    .string()
    .nullable()
    .matches('.*[^ ].*', { message: 'Invalid Name' })
    .required('Name is required'),
  contactName: yup
    .string()
    .nullable()
    .test({
      test: trimSpaces,
      message: "Can't be empty",
    })
    .required('Contact Name is required'),
  contactEmail: yup
    .string()
    .email('Invalid Manager Email value')
    .nullable()
    .required('Contact Email is required'),
  contactPhoneNumber: PhoneSchemaRequired,
  type: yup
    .string()
    .nullable()
    .required('Market is required'),
  mdu: yup
    .object()
    .nullable()
    .when('type', {
      is: 'mdu',
      then: yup
        .object({
          type: yup
            .string()
            .nullable()
            .required('MDU type is required'),
          billing: yup
            .string()
            .nullable()
            .required('Billing is required'),
          numberOfPropertiesManaged: yup
            .number()
            .typeError('Value must be a number')
            .nullable()
            .required('Number of Properties Managed is required'),
        })
        .nullable()
        .required(),
      otherwise: yup
        .object()
        .nullable()
        .notRequired(),
    }),

  primaryPhoneNumber: PhoneSchemaRequired,

  primaryAddress: AddressSchema,

  website: UrlSchema,

  companySize: yup
    .number()
    .typeError('Value must be a number')
    .nullable()
    .required('Company size is required'),
};

const corporateAssetsSchema = {
  companyLogo: yup.object().nullable(),
  documents: yup
    .array()
    .of(yup.object())
    .nullable()
    .required('Please, provide documents'),
};

const softwareAndServicesSchema = {
  isUsingPms: yup
    .boolean()
    .nullable()
    .required('Filed is required'),
  isUsingIls: yup
    .boolean()
    .nullable()
    .required('Filed is required'),
  companyPropertyManagementSoftwareSystems: yup
    .array()
    .of(yup.string())
    .nullable()
    .when('isUsingPms', {
      is: true,
      then: yup
        .array()
        .of(yup.string())
        .nullable()
        .required('PMS is required'),
      otherwise: yup
        .array()
        .of(yup.string())
        .nullable()
        .notRequired(),
    }),
  companyListingServices: yup
    .array()
    .of(yup.string())
    .nullable()
    .when('isUsingIls', {
      is: true,
      then: yup
        .array()
        .of(yup.string())
        .nullable()
        .required('ILS/MLS is required'),
      otherwise: yup
        .array()
        .of(yup.string())
        .nullable()
        .notRequired(),
    }),
};

const complianceSchema = {
  mits: yup
    .bool()
    .nullable()
    .required('MITS is required'),
};

export const CompanySchemaWizardValidation = {
  demographicInformationSchema: yup.object(demographicInformationSchema),
  corporateAssetsSchema: yup.object({ ...demographicInformationSchema, ...corporateAssetsSchema }),
  softwareAndServicesSchema: yup.object({
    ...demographicInformationSchema,
    ...corporateAssetsSchema,
    ...softwareAndServicesSchema,
  }),
  complianceSchema: yup.object({
    ...demographicInformationSchema,
    ...corporateAssetsSchema,
    ...softwareAndServicesSchema,
    ...complianceSchema,
  }),
};

export const CompanySchemaValidation = yup.object({
  ...demographicInformationSchema,
  ...corporateAssetsSchema,
  ...softwareAndServicesSchema,
  ...complianceSchema,
});
