import styled from '@emotion/styled';

type GridLayoutProps = {
  inline?: boolean;
  gap?: string;
  columns?: string;
  rows?: string;
  stretch?: boolean;
  alignItems?: string;
  justifyItems?: string;
  justifyContent?: string;
  justifySelf?: string;
  alignSelf?: string;
  autoRows?: string;
  autoFlow?: string;
};

type GridBoxProps = {
  justifySelf?: string;
  justifyItems?: string;
  alignSelf?: string;
  alignItems?: string;
  direction?: string;
};

const GridLayout = styled.div<GridLayoutProps>`
  display: ${({ inline }) => (inline ? 'inline-grid' : 'grid')};
  grid-gap: ${({ gap = '10px' }) => gap};
  grid-template-columns: ${({ columns }) => columns};
  grid-template-rows: ${({ rows }) => rows};
  align-items: ${({ alignItems }) => alignItems};
  justify-items: ${({ justifyItems }) => justifyItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-self: ${({ alignSelf }) => alignSelf};
  justify-self: ${({ justifySelf }) => justifySelf};
  grid-auto-rows: ${({ autoRows }) => autoRows};
  grid-auto-flow: ${({ autoFlow }) => autoFlow};
  ${({ stretch }) =>
    stretch &&
    `
      width: 100%;
      height: 100%;
  `};
`;

const GridBox = styled.div<GridBoxProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'column'};
  align-self: ${({ alignSelf }) => alignSelf};
  justify-self: ${({ justifySelf }) => justifySelf};
  justify-items: ${({ justifyItems }) => justifyItems};
  align-items: ${({ alignItems }) => alignItems};
`;

export const Grid = {
  Layout: GridLayout,
  Box: GridBox,
};
