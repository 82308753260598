import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';
import { useParams } from 'react-router-dom';
import { Dropdown, Icon, Menu } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import { dialogsContext } from '@/providers';
import { CreateOfferSwitch, Table } from '@/components';
import {
  TableState,
  useTableState,
  UseTableStateResponse,
  useCurrentCompany,
  useDialogState,
  usePermissions,
} from '@/shared/hooks';
import { stringifyDateTime, stringifyUserName } from '@/shared/utils';
import { SingleUnit, SingleUnitListResponse } from '@/shared/types/graphql';
import { stringifyLeaseAmount } from '@/shared/utils';

import { COMMUNITY_SINGLE_UNIT_LIST_QUERY } from './queries';
import copy from 'copy-to-clipboard';
import { ConditionalLink, SingleUnitStatusTag } from '@/pro';
import { DRAFT_STATUS, SINGLE_UNIT_STATUSES } from '@/shared/constants';
import { OFFER_STATUSES } from 'livo-shared';
import { isOfferSwitchChecked } from '@/pro/CreateOfferSwitch';
import { getSingleRentalUrl } from '@/shared/constants/appRoutes';
import {
  OFFER_UPDATE_DIALOG,
  SINGLE_UNIT_UPDATE_DIALOG,
  SINGLE_UNIT_DELETE_DIALOG,
  INVITE_RENTERS_DIALOG,
} from '@/dialogs';

const getSort = (queryParams: TableState) => {
  let sort: Record<string, any>[] = [];

  if (queryParams.sortBy && queryParams.sortOrder) {
    const field = queryParams.sortBy;
    const direction = queryParams.sortOrder === 'ascend' ? 'ASC' : 'DESC';

    sort = [
      {
        [field]: direction,
      },
    ];
  }

  return sort;
};

type CommunitySingleUnitsTableActionsProps = {
  singleUnit: SingleUnit;
};

const SingleUnitsTableEnableLivo = (singleUnit: SingleUnit) => {
  return <CreateOfferSwitch singleUnit={singleUnit} refetchQueries={['CommunitySingleUnitList']} />;
};

const CommunitySingleUnitsTableActions = ({ singleUnit }: CommunitySingleUnitsTableActionsProps) => {
  const { openDialog } = React.useContext(dialogsContext);
  const { props: singleUnitUpdateDialogProps } = useDialogState(SINGLE_UNIT_UPDATE_DIALOG);
  const { props: singleUnitDeleteDialogProps } = useDialogState(SINGLE_UNIT_DELETE_DIALOG);
  const { props: inviteRentersDialogProps } = useDialogState(INVITE_RENTERS_DIALOG);
  const { isCompanyUser } = usePermissions();

  const communityId = singleUnit?.community?.id;
  const singleUnitId = singleUnit?.id;
  const offerId = singleUnit?.activeOffer?.id;

  const bidsCount = singleUnit?.activeOffer?.bid?.count || 0;
  const noBids = bidsCount === 0;

  const { company } = useCurrentCompany();
  const companyType = company?.type;

  const offerStatusIsLeased = singleUnit?.activeOffer?.status === OFFER_STATUSES.leased;
  const offerStatusIsCanceled = singleUnit?.activeOffer?.status === OFFER_STATUSES.canceled;
  const singleUnitStatusIsDraft = singleUnit?.status === SINGLE_UNIT_STATUSES.draft;

  const onCopy = React.useCallback(() => {
    copy(`${window.location.origin}${getSingleRentalUrl(offerId)}`);
  }, [offerId]);

  const menu = (
    <Menu>
      <If condition={noBids}>
        <Menu.Item
          onClick={() =>
            openDialog({
              name: SINGLE_UNIT_UPDATE_DIALOG,
              props: {
                ...singleUnitUpdateDialogProps,
                singleUnitId,
                communityId,
                companyType,
                refetchQueries: ['NavigationSingleUnitsList', 'NavigationCommunitiesList'],
              },
            })
          }
        >
          Edit Unit
        </Menu.Item>
      </If>
      <If condition={!offerStatusIsLeased && !offerStatusIsCanceled && !singleUnitStatusIsDraft && noBids}>
        <Menu.Item
          onClick={() =>
            openDialog({
              name: OFFER_UPDATE_DIALOG,
              props: {
                singleUnitId,
                offerId,
                refetchQueries: ['NavigationSingleUnitsList', 'NavigationCommunitiesList'],
              },
            })
          }
        >
          Edit Livo Transaction
        </Menu.Item>
      </If>
      <If condition={!singleUnitStatusIsDraft && isOfferSwitchChecked(singleUnit?.activeOffer)}>
        <Menu.Item onClick={onCopy}>Copy Public Link</Menu.Item>
        <Menu.Item
          onClick={() =>
            openDialog({
              name: INVITE_RENTERS_DIALOG,
              props: {
                ...inviteRentersDialogProps,
                offerId,
              },
            })
          }
        >
          Invite Renters
        </Menu.Item>
      </If>
      <If condition={!isCompanyUser}>
        <Menu.Item
          onClick={() =>
            openDialog({
              name: SINGLE_UNIT_DELETE_DIALOG,
              props: {
                ...singleUnitDeleteDialogProps,
                singleUnitId,
                refetchQueries: ['CommunitySingleUnitList', 'NavigationSingleUnitsList', 'NavigationCommunitiesList'],
              },
            })
          }
        >
          Delete
        </Menu.Item>
      </If>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
      <Icon type="ellipsis" />
    </Dropdown>
  );
};

type CommunitySingleUnitsTableProps = {
  query: string;
  getSelectedRecords: (records: Array<string>) => void;
  setSelectedRecords: Array<string>;
};

export const CommunitySingleUnitsTable = ({
  query,
  getSelectedRecords,
  setSelectedRecords,
}: CommunitySingleUnitsTableProps) => {
  const [{ page, sortBy, sortOrder, pageSize, pageSizeOptions }, onChange]: UseTableStateResponse = useTableState({
    query,
  });
  const { communityId } = useParams<{ communityId: string }>();

  const { company } = useCurrentCompany();
  const companyId = company?.id;

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  useEffect(() => setSelectedRowKeys(setSelectedRecords), [selectedRowKeys, setSelectedRecords]);

  const { data, loading } = useQuery<{ singleUnitsList: SingleUnitListResponse }>(COMMUNITY_SINGLE_UNIT_LIST_QUERY, {
    variables: {
      filter: {
        name: {
          contains: query,
        },
        community: {
          id: {
            equals: communityId,
          },
        },
      },
      sort: getSort({ sortOrder, sortBy, page, pageSize }),
      first: pageSize,
      skip: (page - 1) * pageSize,
    },
    fetchPolicy: 'network-only',
  });

  const dataSource = data?.singleUnitsList?.items;
  const total = data?.singleUnitsList?.count;

  const pagination = { current: page, pageSize, total, showSizeChanger: true, pageSizeOptions };

  const onSelectChange = (selectedRowKeys: any) => {
    getSelectedRecords(selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    onChange: onSelectChange,
    selectedRowKeys,
    getCheckboxProps: (record: any) => ({
      disabled: !record.id,
      name: record.name,
    }),
  };

  const columns: ColumnProps<SingleUnit>[] = React.useMemo(
    () => [
      {
        title: 'id',
        dataIndex: 'singleUnitId',
        sorter: dataSource?.length !== 0,
        ellipsis: true,
        width: '10%',
        sortOrder: sortBy === 'singleUnitId' ? sortOrder : undefined,
      },
      {
        title: 'Unit / Name',
        dataIndex: 'name',
        sorter: dataSource?.length !== 0,
        ellipsis: true,
        width: '15%',
        sortOrder: sortBy === 'name' ? sortOrder : undefined,
        render: function renderSingleUnitLink(name: string, record) {
          return (
            <ConditionalLink
              to={`/management/${companyId}/communities/${communityId}/single-units/${record.id}/details`}
              condition={record.status !== DRAFT_STATUS}
              text={name}
            />
          );
        },
      },
      {
        title: 'Created By',
        dataIndex: 'createdBy',
        ellipsis: true,
        width: '20%',
        render: stringifyUserName,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        sorter: dataSource?.length !== 0,
        width: '10%',
        ellipsis: true,
        render: function renderSingleUnitStatus(status?: string) {
          return <SingleUnitStatusTag status={status} />;
        },
      },
      {
        title: 'Last Updated',
        dataIndex: 'updatedAt',
        sorter: dataSource?.length !== 0,
        ellipsis: true,
        width: '25%',
        sortOrder: sortBy === 'updatedAt' ? sortOrder : undefined,
        render: stringifyDateTime,
      },
      {
        title: 'Lease Start',
        dataIndex: 'leaseStart',
        width: '10%',
        render: stringifyDateTime,
      },
      {
        title: 'Lease End',
        dataIndex: 'leaseEnd',
        width: '10%',
        render: stringifyDateTime,
      },
      {
        title: 'Lease Amount',
        dataIndex: 'leaseRent',
        width: '10%',
        render: stringifyLeaseAmount,
      },
      {
        title: 'Enable Livo Transaction',
        dataIndex: '',
        width: '10%',
        render: SingleUnitsTableEnableLivo,
      },
      {
        title: '',
        dataIndex: 'id',
        width: '43px',
        render: function renderCommunitySingleUnitsTableActions(id, record) {
          return <CommunitySingleUnitsTableActions singleUnit={record} />;
        },
      },
    ],
    [communityId, companyId, dataSource, sortBy, sortOrder],
  );

  return (
    <Table
      rowKey="id"
      loading={loading}
      dataSource={dataSource}
      rowSelection={{ type: 'checkbox', ...rowSelection }}
      columns={columns}
      onChange={onChange}
      pagination={pagination}
    />
  );
};
