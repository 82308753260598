import React from 'react';
import * as R from 'ramda';
import { Modal } from 'antd';
import { useQuery } from 'react-apollo';
import { Form, Field } from '8base-react-sdk';

import { Maybe, User } from '@/shared/types/graphql';
import { ModalFooter, AsyncContent, InputField, Grid, SelectField } from '@/components';
import { TOAST_SUCCESS_MESSAGE } from '@/shared/constants';
import { TEAM_MEMBER_UPDATE_MODAL_MUTATION, TEAM_MEMBER_UPDATE_MODAL_QUERY } from './queries';
import { composeValidators, email, required } from '@/shared/validators';
import { getApplicationRole } from '@/shared/utils';
import { useMutationSafe, usePermissions } from '@/shared/hooks';
import { getCommunities, createOptionsForUpdate, getSingleUnits } from '../helpers';
import { OptionItem } from '@/components/SelectField/SelectField';
import { COMPANY_TYPES } from 'livo-shared';

interface DialogProps {
  close: () => void;
  isVisible: boolean;
  userId?: Maybe<string>;
  companyType?: string;
  refetchQueries?: Array<string>;
  rolesAllowedToSet: any;
  propertiesOptions?: Array<OptionItem>;
  onlyAssociateProperties?: boolean;
}

export const Dialog: React.FC<DialogProps> = ({ close, isVisible, ...props }) => {
  const onlyAssociateProperties = props?.onlyAssociateProperties || false;
  const refetchQueries = props?.refetchQueries || [];
  const userId = props?.userId;
  const isMultyFamily = props.companyType === COMPANY_TYPES.mdu;

  const { isAdmin, isCompanyOwner } = usePermissions();
  const [editTeamMember, { loading: editTeamMemberLoading }] = useMutationSafe(TEAM_MEMBER_UPDATE_MODAL_MUTATION, {
    refetchQueries: ['UsersList', 'TeamMemberUpdateQuery', ...refetchQueries],
    awaitRefetchQueries: true,
    context: {
      [TOAST_SUCCESS_MESSAGE]: 'Team Member Successfully Updated',
    },
    onCompleted: close,
  });

  const { data: teamMemberResponse, loading: teamMemberLoading } = useQuery<{ user: User }>(
    TEAM_MEMBER_UPDATE_MODAL_QUERY,
    {
      variables: {
        id: userId,
      },
    },
  );

  const teamMember: User = R.pathOr({}, ['user'], teamMemberResponse);

  const applicationRole = getApplicationRole(teamMember);
  const coercedRole = applicationRole?.key;

  const initialValues = React.useMemo(
    () => ({
      ...teamMember,
      applicationRole: coercedRole,
      ...(isMultyFamily
        ? { communities: getCommunities(teamMember).map(community => community?.id) }
        : { singleUnits: getSingleUnits(teamMember).map(singleUnit => singleUnit?.id) }),
    }),
    [coercedRole, isMultyFamily, teamMember],
  );

  const onSubmit = async (data: any) => {
    const options = createOptionsForUpdate(data);

    await editTeamMember(options);
  };

  return (
    <Form type="UPDATE" onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, submitting, pristine, values }) => (
        <Modal
          title="Edit Team Member"
          visible={isVisible}
          onCancel={close}
          width="100%"
          style={{ maxWidth: 400 }}
          footer={
            <ModalFooter
              cancelProps={{
                onClick: close,
              }}
              okProps={{
                disabled: !userId || editTeamMemberLoading || pristine,
                loading: editTeamMemberLoading || submitting,
                onClick: event => handleSubmit(event),
                type: 'primary',
                text: 'Edit',
              }}
            />
          }
          centered
        >
          <AsyncContent loading={teamMemberLoading || editTeamMemberLoading}>
            <Grid.Layout gap="15px">
              <If condition={!onlyAssociateProperties}>
                <Grid.Box>
                  <Field name="firstName" label="First Name" validate={required} component={InputField} />
                </Grid.Box>
                <Grid.Box>
                  <Field name="lastName" label="Last Name" validate={required} component={InputField} />
                </Grid.Box>
                <Grid.Box>
                  <Field
                    name="email"
                    label="Email"
                    disabled
                    validate={composeValidators(required, email)}
                    component={InputField}
                  />
                </Grid.Box>
                <Grid.Box>
                  <Field
                    name="applicationRole"
                    label="Role"
                    validate={required}
                    component={SelectField}
                    stretch
                    options={props?.rolesAllowedToSet}
                  />
                </Grid.Box>
              </If>

              <Grid.Box>
                <Field
                  name={isMultyFamily ? 'communities' : 'singleUnits'}
                  label="Associate Properties"
                  component={SelectField}
                  stretch
                  options={props?.propertiesOptions}
                  mode="multiple"
                  disabled={!(isAdmin || isCompanyOwner)}
                />
              </Grid.Box>
            </Grid.Layout>
          </AsyncContent>
        </Modal>
      )}
    </Form>
  );
};
