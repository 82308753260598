import gql from 'graphql-tag';

export const BID_DELETE_MUTATION = gql`
  mutation BidDelete($data: BidDeleteInput!) {
    bidDelete(data: $data) {
      success
    }
  }
`;

export const OFFER_BID_DELETE_QUERY = gql`
  query OfferDeleteBidQuery($offerId: ID!, $bidId: ID!) {
    offer(id: $offerId) {
      id
      bid(filter: { id: { not_equals: $bidId } }) {
        count
      }
    }
  }
`;
