import React, { ChangeEvent, useCallback } from 'react';
import { Dropdown, Input } from 'antd';
import { Form, Field } from '8base-react-sdk';
import { useHistory } from 'react-router';
import * as R from 'ramda';
import { useTableState, UseTableStateResponse } from '@/shared/hooks';
import { ButtonLink, ColumnFlex, CustomCard, Grid, Heading, SelectField, RowFlex, Tag } from '@/components';
import { useSearch, useFilters } from '@/shared/hooks';
import { dialogsContext } from '@/providers';
import { CompaniesTable } from './CompaniesTable';
import { COMPANY_STATUSES, COMPANY_STATUS_LABELS } from '@/shared/constants';
import { COMPANY_TYPE_LABELS } from 'livo-shared';

import { FilterIcon } from '@/static/icons';
import { FiltersType, getQueryVariablesCompanyList } from './helpers';
import { Company, Maybe } from '@/shared/types/graphql';
import { ADMIN_COMPANYS_QUERY } from './queries';
import { COMPANY_CREATE_DIALOG } from '@/dialogs';

const TYPE_FILTER_OPTIONS = R.keys(COMPANY_TYPE_LABELS).map(companyTypeValue => ({
  value: companyTypeValue,
  label: COMPANY_TYPE_LABELS[companyTypeValue],
}));

const COMPANY_STATUSES_OPTIONS = R.keys(COMPANY_STATUS_LABELS).map(companyStatusValue => ({
  value: companyStatusValue,
  label: COMPANY_STATUS_LABELS[companyStatusValue],
}));

export const Companies = () => {
  const [{ search, throttledSearch }, setSearch] = useSearch();
  const { openDialog } = React.useContext(dialogsContext);

  const [filters, { setAllFilters, removeFilter, setFilter }] = useFilters<FiltersType>();
  const [isFilterVisible, changeFilterVisible] = React.useState(false);
  const history = useHistory();

  React.useEffect(() => {
    setFilter('status', COMPANY_STATUSES.active);
  }, [setFilter]);

  const onChange = React.useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearch(event.currentTarget.value);
    },
    [setSearch],
  );

  const [tableState]: UseTableStateResponse = useTableState({
    query: throttledSearch,
  });

  const openCompanyCreateModal = useCallback(() => {
    openDialog({
      name: COMPANY_CREATE_DIALOG,
      props: {
        closable: true,
        refetchQueries: [
          {
            query: ADMIN_COMPANYS_QUERY,
            variables: getQueryVariablesCompanyList(throttledSearch, filters, tableState),
          },
        ],
        onCompleted: (company?: Maybe<Company>) => {
          const companyId = company?.id;
          const companyStatus = company?.status;

          if (companyId && companyStatus !== COMPANY_STATUSES.draft) {
            history.push(`/management/${companyId}`);
          }
        },
      },
    });
  }, [filters, history, openDialog, tableState, throttledSearch]);

  const header = (
    <Grid.Layout gap="20px" columns="auto 400px 1fr auto" alignItems="center" justifyItems="flex-start" stretch>
      <Heading type="h1">Clients</Heading>
      <Input value={search} placeholder="Search by Client Name" onChange={onChange} />

      <Grid.Layout gap="16px" columns="1fr auto" justifySelf="flex-end">
        <RowFlex>
          <Tag visible={!!Boolean(filters.type)} closable onClose={() => removeFilter('type')}>
            {`Market: ${COMPANY_TYPE_LABELS[filters.type]}`}
          </Tag>
          <Tag visible={!!Boolean(filters.status)} closable onClose={() => removeFilter('status')}>
            {`Status: ${COMPANY_STATUS_LABELS[filters.status]}`}
          </Tag>
        </RowFlex>
        <Form onSubmit={setAllFilters} initialValues={filters}>
          {({ handleSubmit, form }) => (
            <Dropdown
              trigger={['click']}
              visible={isFilterVisible}
              onVisibleChange={changeFilterVisible}
              overlay={
                <CustomCard
                  padding="sm"
                  width="330px"
                  footer={
                    <RowFlex stretch justify="end" gap="md">
                      <ButtonLink
                        color="default"
                        onClick={() => {
                          changeFilterVisible(false);
                          form.reset();
                        }}
                      >
                        Cancel
                      </ButtonLink>
                      <ButtonLink
                        onClick={async () => {
                          await handleSubmit();
                          changeFilterVisible(false);
                          form.reset();
                        }}
                        data-e2e-id="form-filters.apply"
                      >
                        Apply filters
                      </ButtonLink>
                    </RowFlex>
                  }
                >
                  <ColumnFlex align="stretch">
                    <Field
                      stretch
                      label="Market"
                      name="type"
                      placeholder="Filter by"
                      options={TYPE_FILTER_OPTIONS}
                      component={SelectField}
                    />

                    <Field
                      stretch
                      label="Status"
                      name="status"
                      placeholder="Filter by"
                      options={COMPANY_STATUSES_OPTIONS}
                      component={SelectField}
                      data-e2e-id="form-filters.status"
                    />
                  </ColumnFlex>
                </CustomCard>
              }
            >
              <ButtonLink
                text="Filters"
                icon={<FilterIcon css={{ fill: !!filters.type ? 'currentColor' : 'none' }} />}
                data-e2e-id="form-filters"
              />
            </Dropdown>
          )}
        </Form>
      </Grid.Layout>
      <ButtonLink onClick={openCompanyCreateModal} text="Add New Client" iconProps={{ type: 'plus-circle' }} />
    </Grid.Layout>
  );

  return (
    <CustomCard padding="none" header={header}>
      <CompaniesTable query={throttledSearch} filters={filters} />
    </CustomCard>
  );
};
