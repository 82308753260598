import React from 'react';
import { useQuery, useSubscription } from 'react-apollo';
import { Dropdown } from 'antd';
import debounce from 'lodash.debounce';

import styled from '@/theme/styled';

import { NOTIFICATIONS_LIST_QUERY, NOTIFICATIONS_SUBSCRIPTION } from './queries';
import { NotificationsButton } from './NotificationsButton';
import { Notification } from './Notification';
import { NoNotificationsIcon } from '@/static/icons';
import { NotificationListResponse, NotificationsQuery } from '@/shared/types/graphql';

const NotificationsTag = styled.div`
  display: flex;
  flex-direction: column;
  width: 590px;
`;

const NotificationsTitleTag = styled.div`
  display: flex;
  padding: 0 24px;
  min-height: 60px;
  font-size: 16px;
  align-items: center;
  border-bottom: 1px solid #e9eff4;
  color: #323c47;
`;

const NotificationsEmptyContentTag = styled.div`
  display: flex;
  padding: 12px 24px;
  flex-direction: column;
  max-height: 500px;
  overflow: auto;
  align-items: center;
  justify-content: center;
  height: 300px;
  color: #dadee3;
  font-weight: 600;
  font-size: 13px;
`;

const NotificationsContentTag = styled.div`
  display: grid;
  padding: 12px 24px;
  flex-direction: column;
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const Notifications = () => {
  const [isOpen, setDropdown] = React.useState(false);

  const onVisibleChange = React.useCallback(() => setDropdown(!isOpen), [isOpen, setDropdown]);

  const { data, refetch } = useQuery<{
    notifications: NotificationsQuery;
    userNotificationsList: NotificationListResponse;
  }>(NOTIFICATIONS_LIST_QUERY);

  const debouncedRefetch = React.useMemo(
    () =>
      debounce(() => {
        refetch?.();
      }, 5000),
    [refetch],
  );

  const onSubscriptionData = React.useCallback(() => {
    debouncedRefetch();
  }, [debouncedRefetch]);

  useSubscription(NOTIFICATIONS_SUBSCRIPTION, { onSubscriptionData });

  const userPreparedNotificationsList = data?.notifications?.userPreparedNotificationsList?.items ?? [];

  const unreadNotificationsList = userPreparedNotificationsList.filter(({ read }) => !read);

  const count = unreadNotificationsList.length;

  const overlay = (
    <NotificationsTag>
      <NotificationsTitleTag>Notifications</NotificationsTitleTag>
      <NotificationsContentTag>
        {userPreparedNotificationsList.length === 0 ? (
          <NotificationsEmptyContentTag>
            <NoNotificationsIcon css={{ marginBottom: 10 }} /> You don’t have any notifications.
          </NotificationsEmptyContentTag>
        ) : (
          userPreparedNotificationsList.map((item: any) => <Notification key={item.id} notification={item} />)
        )}
      </NotificationsContentTag>
    </NotificationsTag>
  );

  return (
    <Dropdown
      overlay={overlay}
      trigger={['click']}
      placement="bottomRight"
      visible={isOpen}
      onVisibleChange={onVisibleChange}
    >
      <NotificationsButton notificationsCount={count} />
    </Dropdown>
  );
};
