import React from 'react';
import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import { ColumnFlex, Responsive, TimeLine } from '@/components';
import { Offer, Bid } from '@/shared/types/graphql';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';
import pluralize from 'pluralize';
import { BidOfferInfo } from '@/pro/BidOffersTimeline/BidOfferInfo';

type BidOffersTimelineProps = {
  offer?: Offer;
  size?: 'sm' | 'md';
  bids: Bid[];
  isUserRejected: boolean;
  actions?: (args: {
    userBidPlace: number;
    activeItemId: number;
    offer?: Offer;
    bids: Bid[];
    bid: Bid;
  }) => React.ReactNode;
};

export const BidOffersTimeline: React.FC<BidOffersTimelineProps> = ({
  size = 'md',
  isUserRejected,
  offer,
  bids,
  actions,
}) => {
  const { user } = useCurrentUser();
  const [activeItemId, setActive]: any = React.useState(1);
  const theme = useTheme<any>();

  React.useEffect(() => {
    if (activeItemId > bids.length) {
      setActive(bids.length);
    }
  }, [activeItemId, setActive, bids.length]);

  if (!user || bids.length === 0) {
    return <div>Have no offers</div>;
  }

  const userBidPlace = bids.findIndex(bid => bid?.renter?.user?.id === user?.id) + 1;

  const timelineItems = [];

  if (!!bids[0]) {
    const isOwner = userBidPlace === 1;

    timelineItems.push({
      id: 1,
      title: !isOwner ? '1' : 'You',
      content: (
        <BidOfferInfo
          size={size}
          offer={offer}
          bid={bids[0]}
          bids={bids}
          isUserRejected={isUserRejected}
          userBidPlace={userBidPlace}
          activeItemId={1}
          actions={actions}
        />
      ),
    });
  }

  if (!!bids[1]) {
    const isOwner = userBidPlace === 2;

    timelineItems.push({
      id: 2,
      title: !isOwner ? '2' : 'You',
      content: (
        <BidOfferInfo
          size={size}
          offer={offer}
          bid={bids[1]}
          bids={bids}
          isUserRejected={isUserRejected}
          userBidPlace={userBidPlace}
          activeItemId={2}
          actions={actions}
        />
      ),
    });
  }

  if (!!bids[2]) {
    const isOwner = userBidPlace === 3;

    timelineItems.push({
      id: 3,
      title: !isOwner ? '3' : 'You',
      content: (
        <BidOfferInfo
          size={size}
          offer={offer}
          bid={bids[2]}
          bids={bids}
          isUserRejected={isUserRejected}
          userBidPlace={userBidPlace}
          activeItemId={3}
          actions={actions}
        />
      ),
    });
  }

  if (!!bids[3]) {
    const isOwner = userBidPlace === 4;

    if (userBidPlace > 4) {
      const othersCount = userBidPlace - 4;

      timelineItems.push({
        id: 4,
        title: 'You',
        subTitle: `${userBidPlace} Pos.`,
        beforeText: `${othersCount} ${pluralize('other', othersCount)}`,
        content: (
          <BidOfferInfo
            size={size}
            offer={offer}
            bid={bids[userBidPlace - 1]}
            bids={bids}
            isUserRejected={isUserRejected}
            userBidPlace={userBidPlace}
            activeItemId={4}
            actions={actions}
          />
        ),
      });
    } else {
      timelineItems.push({
        id: 4,
        title: !isOwner ? '4' : 'You',
        content: (
          <BidOfferInfo
            size={size}
            offer={offer}
            bid={bids[3]}
            bids={bids}
            isUserRejected={isUserRejected}
            userBidPlace={userBidPlace}
            activeItemId={4}
            actions={actions}
          />
        ),
      });
    }
  }

  return (
    <ColumnFlex
      css={css`
        @media (max-width: ${theme.vars.breakpoints.md.max}) {
          margin: 0 auto;
        }
      `}
    >
      <TimeLine active={activeItemId} onActiveClick={setActive} items={timelineItems} size={size} />
      <Responsive.HideOn breakpoints={['md', 'lg']}>
        {actions && (
          <>
            <br />
            {actions({ userBidPlace, activeItemId, offer, bids, bid: bids[activeItemId - 1] })}
          </>
        )}
      </Responsive.HideOn>
    </ColumnFlex>
  );
};
