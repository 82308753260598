import React from 'react';
import { useDialogState } from '@/shared/hooks';
import { Dialog } from './Dialog';

export const SINGLE_UNITS_IMPORT_DIALOG = 'SINGLE_UNITS_IMPORT_DIALOG';

export const SingleUnitsImportDialog = () => {
  const { close, closeWithConfirm, isVisible, props } = useDialogState(SINGLE_UNITS_IMPORT_DIALOG);

  if (!isVisible) {
    return null;
  }

  return <Dialog {...props} close={close} closeWithConfirm={closeWithConfirm} isVisible={isVisible} />;
};
