import React from 'react';
import { Switch, Route, Redirect, useLocation, matchPath } from 'react-router-dom';
import { useAllowedRoles } from '8base-react-sdk';

import { useAuthState, useCurrentCompanyId, usePermissions, useRedirects } from '@/shared/hooks';
import { getCheckedAuthState } from '@/shared/utils';

import { ProtectedRoute } from '@/shared/ProtectedRoute';
import { RenterRoute } from '@/shared/RenterRoute';
import { RenterLayout } from '@/layouts/RenterLayout';
import { USER_ROLES } from 'livo-shared';

import { AppRoutes } from '@/shared/constants/appRoutes';
import Store from '@/shared/utils/Store';
import { storageType, storageKey, redirectType } from '@/shared/types/store';

import { Auth } from '@/routes/auth';
import { Account } from '@/routes/account';
import { Management } from '@/routes/management';
import { PageOffers } from '@/routes/offers';
import { PageProperty, PageProperties, PagePropertyBidding, PageCompanyLiveTransactions } from '@/routes/rentals';
import { StubBlocked, StubNotFound, StubSomethingWentWrong } from '@/routes/stubs';

type routeRentalBindingProps = { params: { id: string }; path: string; url: string } | null;

type locationType = {
  from: { pathname: string; search: string; hash: string };
};
export const Routes = () => {
  useRedirects();

  const { isAdmin, isRenter } = usePermissions();
  const isPropertyManager = useAllowedRoles([
    USER_ROLES.COMPANY_ADMIN.roleName,
    USER_ROLES.COMPANY_MANAGER.roleName,
    USER_ROLES.COMPANY_USER.roleName,
  ]);
  const { companyId } = useCurrentCompanyId();
  const currentUserAuthState = useAuthState();
  const location = useLocation<locationType>();

  const { isGuest, isBlocked, isAuthorizedNonVerified, isAuthorized } = getCheckedAuthState(currentUserAuthState);

  const store = new Store({ storageType: storageType.Session, storageKey: storageKey.Redirect });

  const routeRentalBinding: routeRentalBindingProps = matchPath(location.pathname, {
    path: AppRoutes.RENTALS_ID_BIDDING,
    exact: true,
  });

  const isMainPage = location.pathname === '/';
  const isRouteRentalBinding = Boolean(routeRentalBinding);

  if (isRouteRentalBinding && isGuest) {
    store.set(redirectType.toBidding, { pathname: routeRentalBinding?.url });
  }

  const isNotStaffRoute = location.pathname !== AppRoutes.AUTH && location.pathname !== AppRoutes.AUTH_CALLBACK;

  if (!isRenter && !isAuthorized && isNotStaffRoute) {
    store.set(redirectType.afterAuthCallback, { pathname: location.pathname });
  }

  const RedirectsRoutes = () => (
    <Choose>
      <When condition={isGuest}>
        <Redirect to={AppRoutes.AUTH} />
      </When>
      <When condition={isAdmin}>
        <Redirect to={AppRoutes.MANAGEMENT_DASHBOARD} />
      </When>
      <When condition={isPropertyManager}>
        <Redirect to={`/management/${companyId}/dashboard`} />
      </When>
      <When condition={isRenter && isMainPage}>
        <Redirect to={AppRoutes.OFFERS} />
      </When>
    </Choose>
  );

  return (
    <Switch>
      <Choose>
        <When condition={isBlocked}>
          <Route component={StubBlocked} />
        </When>

        <When condition={isAuthorizedNonVerified}>
          <Route path={AppRoutes.AUTH} component={Auth} />
          <Redirect to={AppRoutes.AUTH_CONFIRMATION} />
        </When>

        <Otherwise>
          <Route path={AppRoutes.AUTH} component={Auth} />
          <If condition={!isRenter && isAuthorized}>
            <ProtectedRoute
              path={AppRoutes.MANAGEMENT_COMPANY_ID}
              component={Management}
              redirectTo={location.pathname}
            />
          </If>

          <RenterLayout>
            <Switch>
              <Route exact path={AppRoutes.RENTER_LANDING}>
                Landing
              </Route>

              <RenterRoute exact path={AppRoutes.RENTALS} component={PageProperties} />
              <Route exact path={AppRoutes.RENTALS_ID} component={PageProperty} />
              <Route exact path={AppRoutes.RENTALS_ID_COMPANY_SINGLE_UNITS} component={PageCompanyLiveTransactions} />
              <RenterRoute exact path={AppRoutes.RENTALS_ID_BIDDING} component={PagePropertyBidding} />

              <RenterRoute exact path={AppRoutes.OFFERS} component={PageOffers} />
              <RenterRoute exact path={AppRoutes.ACCOUNT} component={Account} />

              <Route exact path={AppRoutes.RENTER_ERROR_PAGE} component={StubSomethingWentWrong} />

              <RedirectsRoutes />
              <Route path={['*', AppRoutes.RENTER_NOT_FOUND_PAGE]} component={StubNotFound} />
            </Switch>
          </RenterLayout>

          <RedirectsRoutes />
          <Route path={['*', AppRoutes.RENTER_NOT_FOUND_PAGE]} component={StubNotFound} />
        </Otherwise>
      </Choose>
    </Switch>
  );
};
