import React from 'react';

import { Table } from '@/components';
import { ColumnProps } from 'antd/lib/table';
import { Company } from '@/shared/types/graphql';
import { stringifyCompanyType, stringifyDateTime } from '@/shared/utils';
import { useMutationSafe, useTableState, UseTableStateResponse } from '@/shared/hooks';
import { MUTATION_EVENT, CompanyEventTypes } from '@/shared/graphql/eventResolver';
import { toast } from 'react-toastify';

import { useCompaniesList, useOffersList, getQueryVariablesCompanyList } from './helpers';
import {
  renderCompanyLink,
  renderCompanyActions,
  renderCompanyStatus,
  renderCompanyLogo,
  renderWebsite,
  renderClientId,
  renderProperties,
  renderTotalUnits,
} from './renders';

import { stringifyScreeningProcess, stringifyVirtualShowings } from '@/shared/utils';

type CompaniesColumnProps = Company & {
  livoRentalsCount: number;
};

export const TableWithClients = ({ hasColumns, scrollX, count, hasPagination, query, filters }: any) => {
  const [{ page, sortBy, sortOrder, pageSize, pageSizeOptions }, onChange]: UseTableStateResponse = useTableState({
    query,
  });

  const [sendEvent, { loading: loadingSendEvent }] = useMutationSafe(MUTATION_EVENT, {
    onCompleted: data => {
      const eventType = data?.eventResolver?.inner?.__eventType;
      const msg =
        eventType === CompanyEventTypes.ARCHIVE
          ? 'Archiving successful'
          : eventType === CompanyEventTypes.REVIVE
          ? 'Company successfully revived'
          : 'Event successfully processed';
      toast.success(msg);
    },
    refetchQueries: ['AdminCompanies'],
  });

  const { companiesList, companiesListCount, loadingCompaniesList } = useCompaniesList({
    query,
    filters,
    sortOrder,
    sortBy,
    page,
    pageSize: count || pageSize,
  });
  const { offersGroups, loadingOfferList } = useOffersList({ companiesList });

  const loading = loadingOfferList || loadingCompaniesList || loadingSendEvent;

  const dataSource = companiesList.map(company => {
    const livoRentalsCount = offersGroups.find(({ companyId }: any) => companyId === company?.id)?.offersCount || 0;

    return {
      ...company,
      key: company.id,
      livoRentalsCount,
    };
  });

  const pagination = { current: page, pageSize, total: companiesListCount, showSizeChanger: true, pageSizeOptions };

  const columns: ColumnProps<CompaniesColumnProps>[] = React.useMemo(() => {
    const orderColumns = [];

    const columnID = {
      title: 'ID',
      dataIndex: 'clientId',
      width: '100px',
      sorter: dataSource?.length !== 0,
      fixed: 'left',
      ellipsis: true,
      sortOrder: sortBy === 'clientId' ? sortOrder : undefined,
      render: renderClientId,
    };

    const companyLogo = {
      title: 'company logo',
      dataIndex: 'companyLogo',
      width: '120px',
      align: 'center',
      ellipsis: true,
      fixed: 'left',
      render: renderCompanyLogo,
    };

    const clientName = {
      title: 'Client Name',
      dataIndex: 'name',
      width: '220px',
      fixed: 'left',
      ellipsis: true,
      sortOrder: sortBy === 'name' ? sortOrder : undefined,
      render: renderCompanyLink,
    };

    const screeningProcess = {
      title: 'Screening Process',
      dataIndex: 'screeningProcess',
      width: '11%',
      ellipsis: true,
      render: stringifyScreeningProcess,
    };

    const status = {
      title: 'Status',
      dataIndex: 'status',
      width: '100px',
      ellipsis: true,
      sortOrder: sortBy === 'type' ? sortOrder : undefined,
      render: renderCompanyStatus,
    };

    const properties = {
      title: 'Properties',
      dataIndex: 'communities',
      width: '10%',
      ellipsis: true,
      render: renderProperties,
    };

    const market = {
      title: 'Market',
      dataIndex: 'type',
      width: '10%',
      ellipsis: true,
      sortOrder: sortBy === 'type' ? sortOrder : undefined,
      render: stringifyCompanyType,
    };

    const totalUnits = {
      title: 'Total Units',
      dataIndex: 'singleUnits',
      width: '10%',
      ellipsis: true,
      render: renderTotalUnits,
    };

    const livoRentals = {
      title: 'Livo Transactions',
      dataIndex: 'livoRentalsCount',
      width: '10%',
      ellipsis: true,
    };

    const website = {
      title: 'Website',
      dataIndex: 'website',
      width: '15%',
      ellipsis: true,
      render: renderWebsite,
    };

    const virtualShowings = {
      title: 'Virtual Showings',
      dataIndex: 'virtualShowings',
      width: '10%',
      ellipsis: true,
      render: stringifyVirtualShowings,
    };

    const lastUpdated = {
      title: 'Last Updated',
      dataIndex: 'updatedAt',
      width: '200px',
      ellipsis: true,
      sortOrder: sortBy === 'updatedAt' ? sortOrder : undefined,
      render: stringifyDateTime,
    };

    const actions = {
      title: '',
      dataIndex: 'id',
      width: '43px',
      render: (id: string, record: CompaniesColumnProps) =>
        renderCompanyActions(
          id,
          record,
          sendEvent,
          getQueryVariablesCompanyList(query, filters, { page, sortBy, sortOrder, pageSize: count || pageSize }),
        ),
    };

    hasColumns.ID && orderColumns.push(columnID);
    orderColumns.push(companyLogo);
    orderColumns.push(clientName);
    orderColumns.push(status);
    orderColumns.push(market);
    hasColumns.properties && orderColumns.push(properties);
    hasColumns.totalUnits && orderColumns.push(totalUnits);
    orderColumns.push(livoRentals);
    hasColumns.screeningProcess && orderColumns.push(screeningProcess);
    hasColumns.virtualShowings && orderColumns.push(virtualShowings);
    hasColumns.website && orderColumns.push(website);
    orderColumns.push(lastUpdated);
    orderColumns.push(actions);

    return orderColumns;
  }, [
    count,
    dataSource,
    filters,
    hasColumns.ID,
    hasColumns.properties,
    hasColumns.screeningProcess,
    hasColumns.totalUnits,
    hasColumns.virtualShowings,
    hasColumns.website,
    page,
    pageSize,
    query,
    sendEvent,
    sortBy,
    sortOrder,
  ]);

  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      loading={loading}
      pagination={hasPagination ? pagination : false}
      scroll={{ x: scrollX }}
      onChange={onChange}
    />
  );
};
