import { DateTime } from 'luxon';
import { formatDiffDate } from '@/shared/utils';

export const stringifyEndsIn = (endsInData: any) => {
  const endsInRaw = DateTime.fromISO(endsInData);
  const endsIn = endsInRaw.toFormat('D');
  const diff = formatDiffDate(endsInRaw);
  const isExpired = endsInRaw.diffNow().milliseconds < 0;
  return `${endsIn}${isExpired ? '' : ` (${diff})`}`;
};
