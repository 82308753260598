import React from 'react';
import { styled } from '@/theme';
import { Icon, Divider } from 'antd';
import * as R from 'ramda';

import { SingleUnit } from '@/shared/types/graphql';
import { NEW_AMENITY_GROUPS } from '@/shared/constants';
import { useDictionary } from '@/shared/hooks';

export const AmenitiesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;

  @media (max-width: ${({ theme }) => theme.vars.breakpoints.sm.max}) {
    grid-template-columns: none;
    grid-gap: 30px;
  }
`;

export const Amenity = styled.div`
  display: grid;
  grid-template-columns: 22px auto;
  grid-template-rows: auto 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 10px;
`;

export const SectionHeading = styled.h4`
  margin-bottom: 25px;
  font-size: 1.4rem;
  font-weight: 600;
  color: #323c47;
`;

export const SectionInfoValue = styled.span`
  line-height: 2.4rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: #323c47;
`;

export const SectionAmenitiesListContainer = styled.div`
  grid-column: -1/1;
`;

export const AmenityItem = styled.span`
  font-size: 1.2rem;
`;

type PropertyAmenitiesProps = {
  singleUnit: SingleUnit;
};

const getGroupedAmenities = (amenityListResponse?: any): Array<any> => {
  const filteredAmenities: any = { ...amenityListResponse };
  const groupedAmenities: any = [];
  for (const key in filteredAmenities) {
    if (!filteredAmenities[key] || !Array.isArray(filteredAmenities[key])) {
      delete filteredAmenities[key];
    } else {
      groupedAmenities.push({
        groupKey: key,
        groupName: NEW_AMENITY_GROUPS[key]?.label,
        groupIcon: NEW_AMENITY_GROUPS[key]?.icon,
        amenitiesList: filteredAmenities[key],
      });
    }
  }
  return groupedAmenities.filter(Boolean) || [];
};

export const PropertyAmenities: React.FC<PropertyAmenitiesProps> = ({ singleUnit }) => {
  const { singleUnit: singleUnitName } = useDictionary();

  const unitAmenities: any = singleUnit?.singleUnitAmenity;
  const communityAmenities: any = singleUnit?.community?.communityAmenity;

  const groupedUnitAmenities = getGroupedAmenities(unitAmenities);
  const groupedCommunityAmenities = getGroupedAmenities(communityAmenities);

  return (
    <React.Fragment>
      {!R.isEmpty(groupedCommunityAmenities) && (
        <>
          <SectionHeading>Property Amenities</SectionHeading>
          <AmenitiesContainer>
            {groupedCommunityAmenities.map(({ amenitiesList, groupIcon, groupName, groupKey }) => (
              <Amenity key={groupKey}>
                <Icon component={groupIcon} style={{ fontSize: '2.4rem', marginRight: 10 }} />
                <SectionInfoValue>{groupName}</SectionInfoValue>
                <SectionAmenitiesListContainer>
                  <AmenityItem>{amenitiesList.join(', ')}</AmenityItem>
                </SectionAmenitiesListContainer>
              </Amenity>
            ))}
          </AmenitiesContainer>
          <Divider />
        </>
      )}
      {!R.isEmpty(groupedUnitAmenities) && (
        <>
          <SectionHeading>{`${singleUnitName.single} Amenities`}</SectionHeading>
          <AmenitiesContainer>
            {groupedUnitAmenities.map(({ amenitiesList, groupIcon, groupName, groupKey }) => (
              <Amenity key={groupKey}>
                <Icon component={groupIcon} style={{ fontSize: '2.4rem', marginRight: 10 }} />
                <SectionInfoValue>{groupName}</SectionInfoValue>
                <SectionAmenitiesListContainer>
                  <AmenityItem>{amenitiesList.join(', ')}</AmenityItem>
                </SectionAmenitiesListContainer>
              </Amenity>
            ))}
          </AmenitiesContainer>
        </>
      )}
    </React.Fragment>
  );
};
