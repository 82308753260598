import { Bidders, ClientsIcon, HouseIcon, GearIcon, SpeedMeterIcon } from '@/static/icons';
import { NavItemType } from '../Nav/types';

export const NAV_ADMIN_LIST: Array<NavItemType> = [
  {
    name: 'Dashboard',
    path: `/management/dashboard`,
    icon: SpeedMeterIcon,
  },
  {
    name: 'Clients',
    path: `/management/companies`,
    icon: ClientsIcon,
  },
  {
    name: 'Livo Transactions',
    path: `/management/allrentals`,
    icon: HouseIcon,
  },
  {
    name: 'LivoLeads™',
    path: `/management/allleads`,
    icon: Bidders,
  },
  {
    name: 'Settings',
    icon: GearIcon,
    subNav: [
      {
        name: `Employees`,
        path: `/management/settings/employees`,
      },
      {
        name: `My Account`,
        path: `/management/settings/account`,
      },
      {
        name: 'Amenities',
        path: '/management/settings/amenities',
      },
      {
        name: 'Ranking',
        path: '/management/settings/ranking',
      },
    ],
  },
];

export const NAV_EMPLOYEE_LIST: Array<NavItemType> = [
  {
    name: 'Dashboard',
    path: `/management/dashboard`,
    icon: SpeedMeterIcon,
  },
  {
    name: 'Clients',
    path: `/management/companies`,
    icon: ClientsIcon,
  },
  {
    name: 'Livo Transactions',
    path: `/management/allrentals`,
    icon: HouseIcon,
  },
  {
    name: 'LivoLeads™',
    path: `/management/allleads`,
    icon: Bidders,
  },
  {
    name: 'Settings',
    icon: GearIcon,
    subNav: [
      {
        name: `Employees`,
        path: `/management/settings/employees`,
      },
      {
        name: `My Account`,
        path: `/management/settings/account`,
      },
      {
        name: 'Amenities',
        path: '/management/settings/amenities',
      },
    ],
  },
];
