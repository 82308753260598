import React, { CSSProperties } from 'react';
import { Icon } from 'antd';
import styled from '@/theme/styled';

import { CloseIcon } from '@/static/icons';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  height: 100%;
  margin-top: -5em;
`;

const Title = styled.div`
  width: 100%;
  color: #f37a22;
  font-size: 5rem;
  text-align: center;
  margin: -0.5rem 0;

  @media (max-width: ${props => props.theme.vars.breakpoints.md.max}) {
    font-size: 3rem;
    margin: 0;
  }
`;

const Description = styled.div`
  font-size: 1.8rem;
  margin: 1.5rem 0;
  text-align: center;
`;

const IconStyles: CSSProperties = {
  marginBottom: '20px',
  width: '100%',
  fontSize: '7rem',
  color: '#d8d8d8',
};

export const StubNotFound: React.FC = () => (
  <Wrapper>
    <Icon component={CloseIcon} style={IconStyles} />
    <Title>404</Title>
    <Title>Page not Found</Title>
    <Description>The requested URL was not found in our servers</Description>
  </Wrapper>
);
