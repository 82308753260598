import React from 'react';
import styled from '@/theme/styled';
import { css } from '@emotion/core';

const ellipsisStyles = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

type GeneralProps = {
  ellipsis?: boolean;
};

type TextProps = {
  type?: 'title' | 'subtitle' | 'uppercase' | 'common';
} & GeneralProps;

const TitleText = styled.span<GeneralProps>`
  height: 20px;
  color: #323c47;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  ${({ ellipsis }) => ellipsis && ellipsisStyles}
`;

const SubTitleText = styled.span<GeneralProps>`
  color: #323c47;
  font-weight: 600;
  font-size: 14px;
  margin-right: 16px;
  line-height: 22px;
  ${({ ellipsis }) => ellipsis && ellipsisStyles}
`;

const UppercaseText = styled.div<GeneralProps>`
  line-height: 16px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #929ea8;
  ${({ ellipsis }) => ellipsis && ellipsisStyles}
`;

const CommonText = styled.span<GeneralProps>`
  color: #929ea8;
  margin-right: 10px;
  line-height: 14px;
  font-weight: 500;
  font-size: 13px;
  ${({ ellipsis }) => ellipsis && ellipsisStyles}
`;

export const Text: React.FC<TextProps> = ({ children, type = 'common', ...rest }) => {
  switch (type) {
    case 'common':
      return <CommonText {...rest}>{children}</CommonText>;

    case 'title':
      return <TitleText {...rest}>{children}</TitleText>;

    case 'subtitle':
      return <SubTitleText {...rest}>{children}</SubTitleText>;

    case 'uppercase':
      return <UppercaseText {...rest}>{children}</UppercaseText>;

    default:
      return null;
  }
};
