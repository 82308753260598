import React from 'react';
import { Icon } from 'antd';
import styled from '@/theme/styled';

import { DeleteIcon } from '@/static/icons';

// TODO: use theme
const DeleteButton = styled.button`
  width: 19px;
  height: 19px;
  line-height: 1;
  border: 2px solid #cfd7de;
  border-radius: 4px;
  outline: none;
  color: #7d828c;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
`;

type DeletePreviewProps = {
  onClick: () => void;
};

export const DeletePreviewButton = ({ onClick, ...rest }: DeletePreviewProps) => (
  <DeleteButton onClick={onClick} {...rest}>
    <Icon component={DeleteIcon} style={{ fontSize: '1.5rem' }} />
  </DeleteButton>
);
