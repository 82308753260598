import React from 'react';
import styled from '@emotion/styled';
import { useAllowedRoles } from '8base-react-sdk';

import { USER_ROLES } from '@/shared/constants';
import { Card } from './Card';
import { getDashboardCards, getMduDashboardCards } from './constants';
import { useCurrentCompanyId } from '@/shared/hooks';

const Container = styled.div`
  display: grid;
  grid-template-rows: repeat(4, 180px);
  grid-gap: 24px;
  grid-area: CardsList;
`;

export const CardsList = () => {
  const mdu = useAllowedRoles([USER_ROLES.COMPANY_MDU.roleName]);
  const { companyId } = useCurrentCompanyId();
  const CARDS_DATA = React.useMemo(
    () => (mdu ? getMduDashboardCards({ companyId }) : getDashboardCards({ companyId })),
    [companyId, mdu],
  );

  return (
    <Container>
      {CARDS_DATA.map((cardData, cardIndex) => (
        <Card key={cardIndex} {...cardData} />
      ))}
    </Container>
  );
};
