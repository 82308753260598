import React from 'react';

import { OFFER_STATUSES } from '@/shared/constants';
import { ModalFooter } from '@/components';

interface FooterProps {
  pristine: boolean;
  submitting: boolean;
  closeWithConfirm: () => void;
  close: () => void;
  form: any;
  handleSubmit: any;
}

export const Footer: React.FC<FooterProps> = props => {
  const { pristine, submitting, closeWithConfirm, close, form, handleSubmit } = props;

  return (
    <ModalFooter
      leftActionProps={{
        text: 'Save as Draft',
        disabled: pristine || submitting,
        onClick: event => {
          form.change('status', OFFER_STATUSES.draft);

          return handleSubmit(event);
        },
      }}
      cancelProps={{
        disabled: submitting,
        onClick: pristine ? close : closeWithConfirm,
      }}
      okProps={{
        text: 'Enable Livo Transaction',
        type: 'primary',
        htmlType: 'submit',
        disabled: pristine,
        loading: submitting,
        onClick: event => {
          form.change('status', OFFER_STATUSES.pending);

          return handleSubmit(event);
        },
      }}
    />
  );
};
