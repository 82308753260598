import React from 'react';
import { Icon, Button as AntdButton } from 'antd';
import { ButtonProps as AntdButtonProps } from 'antd/lib/button/button';
import { IconProps as AntdIconProps } from 'antd/es/icon';
import styled from '@/theme/styled';

const Button = styled(AntdButton)<{ color: string }>`
  display: flex;
  align-items: center;
  line-height: 1;
  height: auto;
  border: none;
  font-size: 12px;
  letter-spacing: 0.2px;

  color: ${props => {
    switch (props.color) {
      case 'primary':
        return props.theme.vars.primaryColor;
      case 'default':
        return props.theme.vars.grayTextColor;
    }
  }};

  &:hover {
    color: ${props => {
      switch (props.color) {
        case 'default':
          return props.theme.vars.grayTextColor;
      }
    }};
  }
`;

const ButtonIcon = styled.span`
  font-size: 17px;
  margin-right: 8px;
`;

type ButtonProps = Omit<AntdButtonProps, 'icon' | 'href'> & {
  color?: 'primary' | 'default';
  iconProps?: AntdIconProps;
  icon?: any;
  text?: string;
};

export const ButtonLink = ({ iconProps, color = 'primary', text, children, icon, ...rest }: ButtonProps) => {
  return (
    <Button type="link" color={color} {...rest}>
      {iconProps && (
        <ButtonIcon>
          <Icon {...iconProps} />
        </ButtonIcon>
      )}
      {icon && <ButtonIcon>{icon}</ButtonIcon>}
      {text || children}
    </Button>
  );
};
