import React, { ChangeEvent } from 'react';
import { CustomCard, TableHeader } from '@/components';
import { useQuery } from 'react-apollo';
import * as R from 'ramda';

import { TeamTable } from './components';
import { INVITATIONS_COMMUNITIES_QUERY, INVITATIONS_SINGLE_UNITS_QUERY } from './queries';
import { dialogsContext } from '@/providers';
import { TOAST_SUCCESS_MESSAGE, COMPANY_TYPES, COMMUNITY_STATUSES } from '@/shared/constants';
import { TeamInvitationFormValues } from '@/shared/types';
import {
  useApplicationsRolesOptions,
  useCompanyTypeCheck,
  useCurrentCompanyId,
  useSearch,
  useCurrentUser,
  usePermissions,
  useMutationSafe,
  useDialogState,
} from '@/shared/hooks';
import { CommunityListResponse, SingleUnitListResponse } from '@/shared/types/graphql';
import { OptionItem } from '@/components/SelectField/SelectField';
import { MUTATION_EVENT, TeamMemberEventTypes } from '@/shared/graphql/eventResolver';
import { TEAM_MEMBER_INVITATION_DIALOG } from '@/dialogs';

const SINGLE_UNITS = 'singleUnits';
const COMMUNITIES = 'communities';

export const Team = () => {
  const [{ search, throttledSearch }, setSearch] = useSearch();
  const { openDialog } = React.useContext(dialogsContext);
  const { close } = useDialogState(TEAM_MEMBER_INVITATION_DIALOG);

  const { companyId } = useCurrentCompanyId();
  const { user } = useCurrentUser();
  const { isCompanyManager, applicationRole } = usePermissions();
  const allowedToInvite = applicationRole?.allowedToInvite;

  const regionalManagerCommunitiesList = user?.regionalManager?.items || [];

  const [addTeamMember] = useMutationSafe(MUTATION_EVENT, {
    onCompleted: close,
    context: {
      [TOAST_SUCCESS_MESSAGE]: 'Invitation Successfully Sent',
    },
    refetchQueries: ['UsersList'],
    awaitRefetchQueries: true,
  });

  const isMultiFamily = useCompanyTypeCheck(COMPANY_TYPES.mdu);

  const { data: communitiesData } = useQuery<{ communitiesList: CommunityListResponse }>(
    INVITATIONS_COMMUNITIES_QUERY,
    {
      skip: !isMultiFamily || isCompanyManager,
      variables: {
        filter: {
          AND: [
            {
              status: {
                not_equals: COMMUNITY_STATUSES.draft,
              },
            },
            {
              company: {
                id: {
                  equals: companyId,
                },
              },
            },
          ],
        },
      },
    },
  );

  const { data: singleUnitsData } = useQuery<{ singleUnitsList: SingleUnitListResponse }>(
    INVITATIONS_SINGLE_UNITS_QUERY,
    {
      skip: isMultiFamily || isCompanyManager,
      variables: {
        filter: {
          AND: [
            {
              status: {
                not_equals: COMMUNITY_STATUSES.draft,
              },
            },
            {
              company: {
                id: {
                  equals: companyId,
                },
              },
            },
          ],
        },
      },
    },
  );

  const rolesOptions = useApplicationsRolesOptions();
  const clientRoleOptions = rolesOptions.filter(({ isClientRole }) => isClientRole);

  const communitiesOptions: Array<OptionItem> =
    (isCompanyManager ? regionalManagerCommunitiesList : communitiesData?.communitiesList?.items)?.map(
      ({ name, id }) => ({
        label: name ?? '',
        value: id ?? '',
      }),
    ) || [];

  const singleUnitsOptions: Array<OptionItem> =
    singleUnitsData?.singleUnitsList?.items?.map(({ name, id }) => ({
      label: name ?? '',
      value: id ?? '',
    })) || [];

  const propertiesOptions = isMultiFamily ? communitiesOptions : singleUnitsOptions;
  const propertyFieldName = isMultiFamily ? COMMUNITIES : SINGLE_UNITS;

  const openModal = () =>
    openDialog({
      name: TEAM_MEMBER_INVITATION_DIALOG,
      props: {
        visible: true,
        rolesOptions: clientRoleOptions,
        propertyFieldName,
        propertiesOptions,
        companyId,
        onSubmit: async (data: TeamInvitationFormValues) => {
          await addTeamMember({
            variables: {
              event: {
                type: TeamMemberEventTypes.ADD,
                payload: { data, companyId },
              },
            },
          });
        },
      },
    });

  const onSearchChange = React.useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearch(event.currentTarget.value);
    },
    [setSearch],
  );

  const actionProps = !R.isEmpty(allowedToInvite)
    ? { text: 'Invite Members to this Team', icon: { type: 'plus-circle' }, onClick: openModal }
    : undefined;

  return (
    <CustomCard
      padding="none"
      header={
        <TableHeader
          title="Team Members"
          searchProps={{ query: search, placeholder: 'Search by Member Name', onChange: onSearchChange }}
          actionProps={actionProps}
        />
      }
    >
      <TeamTable query={throttledSearch} rolesOptions={rolesOptions} propertiesOptions={propertiesOptions} />
    </CustomCard>
  );
};
