import React from 'react';
import { Field, Form } from '8base-react-sdk';
import { Modal } from 'antd';
import { AsyncContent, Grid, InputField, ModalFooter, SelectField } from '@/components';
import { NEW_AMENITY_GROUPS_LABELS } from '@/shared/constants';
import { validateEntityDataByDraft } from '@/shared/utils';
import { AmenitySchemaValidation } from '@/schemaValidators';
import { useQuery } from 'react-apollo';
import { AmenityGroupListResponse, DefaultAmenity } from '@/shared/types/graphql';
import { toast } from 'react-toastify';
import { useMutationSafe } from '@/shared/hooks';
import { AMENITY_GROUPS_QUERY, DEFAULT_AMENITY_QUERY, DEFAULT_AMENITY_UPDATE_MUTATION } from './queries';

interface DialogProps {
  closeWithConfirm: () => void;
  close: () => void;
  isVisible: boolean;
  refetchQueries?: Array<string>;
  amenityId?: string;
}

export const Dialog: React.FC<DialogProps> = ({ close, closeWithConfirm, isVisible, ...props }) => {
  const amenityId = props?.amenityId;
  const refetchQueries = props?.refetchQueries || [];

  const { data: amenityGroupsData, loading: amenityGroupsLoading } = useQuery<{
    amenityGroupsList: AmenityGroupListResponse;
  }>(AMENITY_GROUPS_QUERY, {
    variables: {
      filter: {
        masterAmenity: {
          id: {
            is_not_empty: true,
          },
        },
      },
    },
  });

  const { data: amenityData, loading: amenityLoading } = useQuery<{
    defaultAmenity: DefaultAmenity;
  }>(DEFAULT_AMENITY_QUERY, {
    variables: {
      id: amenityId,
    },
  });

  const [defaultAmenityUpdate, { loading: defaultAmenityUpdateLoading }] = useMutationSafe(
    DEFAULT_AMENITY_UPDATE_MUTATION,
    {
      refetchQueries: [...refetchQueries, 'DefaultAmenitiesList', 'DefaultAmenityQuery'],
      onCompleted: () => {
        close();
        toast.success('Successfully Updated');
      },
    },
  );

  const groupsList = amenityGroupsData?.amenityGroupsList?.items || [];

  const options = React.useMemo(
    () => groupsList.map((el: any) => ({ value: el?.id, label: NEW_AMENITY_GROUPS_LABELS[el?.key] })),
    [groupsList],
  );

  const onSubmit = async (data: any) => {
    await defaultAmenityUpdate({
      variables: {
        data: { category: { reconnect: { id: data?.category } }, label: data?.label },
        id: amenityId,
      },
    });
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Form
      type="UPDATE"
      onSubmit={onSubmit}
      validate={values => {
        return validateEntityDataByDraft(AmenitySchemaValidation, values);
      }}
    >
      {({ handleSubmit, pristine, submitting }) => (
        <Modal
          title="Update Amenity"
          visible={isVisible}
          onCancel={() => (pristine ? close() : closeWithConfirm())}
          width="100%"
          style={{ maxWidth: 500 }}
          footer={
            <ModalFooter
              cancelProps={{
                onClick: () => (pristine ? close() : closeWithConfirm()),
                disabled: defaultAmenityUpdateLoading || submitting,
              }}
              okProps={{
                disabled: pristine || submitting,
                loading: defaultAmenityUpdateLoading || submitting,
                onClick: event => handleSubmit(event),
                type: 'primary',
                text: 'Update',
              }}
            />
          }
          centered
        >
          <AsyncContent loading={amenityLoading}>
            <Grid.Layout gap="25px">
              <Grid.Box>
                <Field
                  label="Label"
                  initialValue={amenityData?.defaultAmenity?.label}
                  name="label"
                  component={InputField}
                  stretch
                />
              </Grid.Box>
              <Grid.Box>
                <Field
                  label="Group"
                  name="category"
                  disabled={amenityGroupsLoading}
                  options={options}
                  initialValue={amenityData?.defaultAmenity?.category?.id}
                  loading={amenityGroupsLoading}
                  component={SelectField}
                  stretch
                />
              </Grid.Box>
            </Grid.Layout>
          </AsyncContent>
        </Modal>
      )}
    </Form>
  );
};
