import React from 'react';

import { Breadcrumbs, RowFlex, Grid } from '@/components';
import { BurgerButton, UserInfo } from './components';
import { Notifications } from '@/pro';
import { managementLayoutContext } from '@/providers';

export const Header = () => {
  const { isSidebarOpen, handleSidebarToggle } = React.useContext(managementLayoutContext);

  return (
    <RowFlex align="center" gap="none" css={{ backgroundColor: '#fff', borderBottom: '1px solid #f4f5f6' }}>
      <BurgerButton handleSidebarToggle={handleSidebarToggle} isSidebarOpen={isSidebarOpen} />
      <Grid.Layout columns="1fr auto" stretch alignItems="center">
        <Grid.Box>
          <Breadcrumbs hasPaddingLeft />
        </Grid.Box>
        <Grid.Box direction="row" justifySelf="flex-end" alignItems="center">
          <Notifications />
          <UserInfo />
        </Grid.Box>
      </Grid.Layout>
    </RowFlex>
  );
};
