import React from 'react';
import { Modal, Typography } from 'antd';
import { ModalFooter } from '@/components';

type DialogProps = {
  close: () => void;
  isVisible: boolean;
};

export const Dialog: React.FC<DialogProps> = ({ isVisible, close }) => {
  return (
    <Modal
      className="livo-modal"
      visible={isVisible}
      onCancel={close}
      width="100%"
      style={{ maxWidth: 600 }}
      centered
      footer={
        <ModalFooter
          okProps={{
            text: 'I got it',
            type: 'primary',
            htmlType: 'submit',
            onClick: close,
          }}
        />
      }
    >
      <Typography.Paragraph style={{ padding: 35, paddingBottom: 15 }}>
        The Property Manager has not yet uploaded the lease contract. You will be notified via email as soon as the
        contract is ready to be signed.
      </Typography.Paragraph>
    </Modal>
  );
};
