import React from 'react';
import { ButtonLink, Grid } from '@/components';

interface AddProperties {
  openImportModal: () => void;
  openCreateModal: () => void;
}

export const AddProperties: React.FC<AddProperties> = props => {
  const { openImportModal, openCreateModal } = props;

  return (
    <Grid.Layout columns="auto auto" css={{ marginLeft: 'auto' }}>
      <ButtonLink onClick={openImportModal} text="Import Properties" iconProps={{ type: 'plus-circle' }} />
      <ButtonLink onClick={openCreateModal} text="Add New Property" iconProps={{ type: 'plus-circle' }} />
    </Grid.Layout>
  );
};
