import { CSVIcon, XLSIcon, DOCIcon, PPTIcon, PDFIcon, TXTIcon, UnknownFileIcon } from '@/static/icons';

const CSV = 'text/csv';
const XLS = 'application/vnd.ms-excel';
const XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const DOC = 'application/msword';
const DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
const PPT = 'application/vnd.ms-powerpoint';
const PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
const PDF = 'application/pdf';
const TXT = 'text/plain';

type Props = {
  type: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

type MimetypeDictionary = Record<string, Props>;

type FileType = {
  mimetype?: string;
  meta?: {
    mimetype: string;
  };
};

const documentInfoByMimetype: MimetypeDictionary = {
  [CSV]: {
    type: 'csv',
    icon: CSVIcon,
  },
  [XLS]: {
    type: 'xls',
    icon: XLSIcon,
  },
  [XLSX]: {
    type: 'xlsx',
    icon: XLSIcon,
  },
  [DOC]: {
    type: 'doc',
    icon: DOCIcon,
  },
  [DOCX]: {
    type: 'docx',
    icon: DOCIcon,
  },
  [PPT]: {
    type: 'ppt',
    icon: PPTIcon,
  },
  [PPTX]: {
    type: 'pptx',
    icon: PPTIcon,
  },
  [PDF]: {
    type: 'pdf',
    icon: PDFIcon,
  },
  [TXT]: {
    type: 'txt',
    icon: TXTIcon,
  },
};

export const getIconByMimetype = (file: FileType) => {
  return file.mimetype
    ? documentInfoByMimetype[file.mimetype].icon
    : file?.meta?.mimetype
    ? documentInfoByMimetype[file.meta.mimetype].icon
    : UnknownFileIcon;
};

export const getDocumentTypeByMimetype = (file: FileType) => {
  return file.mimetype
    ? documentInfoByMimetype[file.mimetype].type
    : file?.meta?.mimetype
    ? documentInfoByMimetype[file.meta.mimetype].type
    : '';
};
