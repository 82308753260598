import React from 'react';
import { Modal } from 'antd';
import { Form, Field } from '@8base-react/forms';

import { APPLICATION_ROLE_KEYS } from 'livo-shared';
import { Grid, InputField, SelectField, ModalFooter } from '@/components';
import { useCheckTeamMemberEmail, useDialogState } from '@/shared/hooks';
import { composeValidators, email, required } from '@/shared/validators';

export const TEAM_MEMBER_INVITATION_DIALOG = 'TEAM_MEMBER_INVITATION_DIALOG';

export const TeamMemberInvitationDialog: React.FC = () => {
  const { close, closeWithConfirm, isVisible, props } = useDialogState(TEAM_MEMBER_INVITATION_DIALOG);

  const { checkEmail, checkTeamMemberEmailLoading } = useCheckTeamMemberEmail();

  if (!isVisible) {
    return null;
  }

  return (
    <Form onSubmit={props?.onSubmit as any}>
      {({ handleSubmit, submitting, pristine, form, values }) => {
        const isCompanyManager = values?.applicationRole === APPLICATION_ROLE_KEYS.companyManager;
        const isCompanyUser = values?.applicationRole === APPLICATION_ROLE_KEYS.companyUser;

        const canSeeProperties = isCompanyManager || isCompanyUser;

        return (
          <Modal
            title="Invite Members"
            visible={isVisible}
            okText="Invite"
            onCancel={pristine ? close : closeWithConfirm}
            afterClose={form.reset}
            centered
            footer={
              <ModalFooter
                okProps={{
                  type: 'primary',
                  htmlType: 'submit',
                  text: 'Invite',
                  loading: submitting || checkTeamMemberEmailLoading,
                  disabled: pristine,
                  onClick: handleSubmit,
                }}
              />
            }
          >
            <Grid.Layout>
              <Grid.Box>
                <Field
                  name="fullName"
                  label="Full Name"
                  validate={required}
                  component={InputField}
                  data-e2e-id="fullname"
                />
              </Grid.Box>
              <Grid.Box>
                <Field
                  name="user.email"
                  label="Email"
                  validate={composeValidators(required, email, checkEmail)}
                  component={InputField}
                  data-e2e-id="email"
                />
              </Grid.Box>
              <Grid.Box>
                <Field
                  name="applicationRole"
                  label="Role"
                  validate={required}
                  component={SelectField}
                  stretch
                  options={props?.rolesOptions}
                  data-e2e-id="applicationRole"
                />
              </Grid.Box>
              <If condition={canSeeProperties}>
                <Grid.Box>
                  <Field
                    name={props?.propertyFieldName}
                    label="Associate Properties"
                    component={SelectField}
                    stretch
                    options={props?.propertiesOptions}
                    mode="multiple"
                  />
                </Grid.Box>
              </If>
            </Grid.Layout>
          </Modal>
        );
      }}
    </Form>
  );
};
