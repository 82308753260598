import moment, { Moment } from 'moment';
import { Maybe } from '@/shared/types/graphql';

interface DateOptions {
  disabledAfterDate?: string;
  disabledBeforeDate?: string;
  minutesCorrection: number;
  value: string;
}

export const disabledHours = (props: DateOptions): number[] => {
  const { value, disabledAfterDate, disabledBeforeDate, minutesCorrection } = props;
  const disabledHours: Array<number> = [];

  if (!Boolean(disabledAfterDate) && !Boolean(disabledBeforeDate)) {
    return disabledHours;
  }

  if (Boolean(value)) {
    for (let i = 0; i < 24; i++) {
      const date = moment(value);

      date.hour(i);

      if (disabledDate({ currentDate: date, minutesCorrection, value })) {
        disabledHours.push(i);
      }
    }
  }

  return disabledHours;
};

interface DisabledMinutes extends DateOptions {
  selectedHour: number;
}

export const disabledMinutes = (props: DisabledMinutes): number[] => {
  const { selectedHour, value, disabledAfterDate, disabledBeforeDate, minutesCorrection } = props;

  const disabledMinutes: Array<number> = [];

  if (!Boolean(disabledAfterDate) && !Boolean(disabledBeforeDate)) {
    return disabledMinutes;
  }

  if (Boolean(value)) {
    for (let i = 0; i < 60; i++) {
      const date = moment(value);

      date.hour(selectedHour);
      date.minute(i);

      if (disabledDate({ currentDate: date, minutesCorrection, value })) {
        disabledMinutes.push(i);
      }
    }
  }

  return disabledMinutes;
};

interface DisabledDate extends DateOptions {
  currentDate: Maybe<Moment>;
}

export const disabledDate = (props: DisabledDate): boolean => {
  const { currentDate, disabledAfterDate, disabledBeforeDate, value, minutesCorrection } = props;

  if (!currentDate && !disabledAfterDate && !disabledBeforeDate) {
    return false;
  }

  if (currentDate && (disabledBeforeDate || disabledAfterDate)) {
    const afterDate = moment(disabledAfterDate);
    const beforeDate = moment(disabledBeforeDate);

    if (Boolean(disabledBeforeDate) && Boolean(disabledAfterDate)) {
      return currentDate.valueOf() < beforeDate.valueOf() || currentDate.valueOf() > afterDate.valueOf();
    }

    if (Boolean(disabledBeforeDate)) {
      if (!value) {
        currentDate.hours(beforeDate.hours()).minutes(beforeDate.minutes() + minutesCorrection);
      }

      currentDate.second(0);

      return currentDate.valueOf() < beforeDate.valueOf();
    } else {
      if (!value) {
        currentDate.hours(afterDate.hours()).minutes(afterDate.minutes() - minutesCorrection);
      }

      currentDate.second(0);

      return currentDate.valueOf() > afterDate.valueOf();
    }
  }

  return false;
};
