import * as yup from 'yup';
import { AddressSchema, PhoneSchemaRequired, UrlSchema } from '../common';
import { trimSpaces } from '@/shared/validators';

export const CommunitySchemaValidation = yup.object({
  name: yup
    .string()
    .test({
      test: trimSpaces,
      message: "Can't be empty",
    })
    .nullable()
    .required('Name is required'),

  managerName: yup
    .string()
    .test({
      test: trimSpaces,
      message: "Can't be empty",
    })
    .nullable()
    .required('Contact Name is required'),

  managerPhoneNumber: PhoneSchemaRequired,

  managerEmail: yup
    .string()
    .email('Invalid value')
    .nullable(),

  logo: yup
    .mixed()
    .nullable()
    .notRequired(),

  website: UrlSchema,

  phoneNumber: PhoneSchemaRequired,

  address: AddressSchema,

  type: yup
    .string()
    .nullable()
    .required('Type is required'),
});
