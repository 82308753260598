import React from 'react';
import styled from '@/theme/styled';

import { Link } from '@/components';
import { ReactComponent as PoweredByLogo } from '@/static/PoweredBy.svg';

export const PoweredByTag = styled.footer`
  height: ${({ theme }) => theme.vars.poweredByHeight};
  font-size: 1.3rem;
  font-weight: 500;
`;

export const Powered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.vars.black};
`;

export const Rights = styled.div`
  text-align: center;
`;

export const PoweredBy = () => {
  const year = new Date().getFullYear();

  return (
    <PoweredByTag>
      <Powered>
        <Link href="https://8base.com" target="_blank">
          <PoweredByLogo width={130} height={16} />
        </Link>
      </Powered>
      <Rights>&#169; {year} Livo Inc. All Rights Reserved.</Rights>
    </PoweredByTag>
  );
};
