import React from 'react';
import { useDialogState } from '@/shared/hooks';
import { Dialog } from './Dialog';

export const COMMUNITIES_IMPORT_DIALOG = 'COMMUNITIES_IMPORT_DIALOG';

export const CommunitiesImportDialog = () => {
  const { close, closeWithConfirm, isVisible, props } = useDialogState(COMMUNITIES_IMPORT_DIALOG);

  if (!isVisible) {
    return null;
  }

  return <Dialog {...props} close={close} closeWithConfirm={closeWithConfirm} isVisible={isVisible} />;
};
