import React from 'react';
import { Button } from 'antd';
import { ActionsContainer } from '../styled';

type ActionsProps = {
  secureNow: boolean;
  onBeatOffer: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onSecureNow: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const Actions: React.FC<ActionsProps> = props => {
  const { secureNow, onBeatOffer, onSecureNow } = props;

  return (
    <ActionsContainer>
      <Button type="primary" size="large" onClick={onBeatOffer} data-e2e-id="make-offer">
        Make an Offer
      </Button>
      {secureNow && (
        <Button type="default" size="large" onClick={onSecureNow}>
          SecureNow™
        </Button>
      )}
    </ActionsContainer>
  );
};
