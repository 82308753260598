import React from 'react';
import { Switch } from 'antd';
import { SwitchProps } from 'antd/lib/switch';

import { FormField } from '@/components';
import { MetaType, InputType } from '@/shared/types';
import { omit } from 'ramda';

type SwitchFieldProps = SwitchProps & {
  label?: React.ReactNode;
  note?: string;
  input: InputType;
  meta: MetaType;
  'data-e2e-id'?: string;
};

export const SwitchField = ({ label, note, input, meta, ...rest }: SwitchFieldProps) => {
  const { value, onChange } = input;

  return (
    <FormField label={label} note={note} meta={meta} data-e2e-id={rest['data-e2e-id']}>
      <Switch checked={value} onChange={onChange} {...omit(['data-e2e-id'], rest)} />
    </FormField>
  );
};
