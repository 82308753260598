import React from 'react';
import { Checkbox } from 'antd';

import { Bid, Maybe } from '@/shared/types/graphql';
import { Grid, WebsiteLink } from '@/components';
import { InformationSection } from '@/pro';
import { styled } from '@/theme';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { stringifyHref, westernReportingXMLResponseHasNoErrors } from '@/shared/utils';
import { toast } from 'react-toastify';
import { CHECK_SCREENING_LINK } from './queries';
import { getTimeToApproveOffer } from '@/shared/utils';
import { useMutationSafe } from '@/shared/hooks';

const Label = styled.span`
  color: #323c47;
`;

const Announcement = styled.div`
  font-size: 13px;
`;

const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.vars.primaryColor};
  border-radius: 4px;
  padding: 15px 22px;
`;

export const BEGIN_WR_SCREENING = gql`
  mutation beginWRScreening($data: BeginWesternReportingScreeningInput!) {
    beginWesternReportingScreening(data: $data) {
      result
    }
  }
`;

export const WR_SCREENING_MUTATION = gql`
  mutation screeningInfo($data: WesternReportingScreeningCreateInput!) {
    westernReportingScreeningCreate(data: $data) {
      id
      screeningID
      screeningLink
    }
  }
`;

type OfferChecklistViewProps = { bid?: Maybe<Bid> };

export const OfferChecklistView = ({ bid }: OfferChecklistViewProps) => {
  const [
    beginWesternReportingScreening,
    { data: beginWesternReportingScreeningResponse, loading: beginWesternReportingScreeningResponseLoading },
  ] = useMutationSafe(BEGIN_WR_SCREENING, {
    refetchQueries: ['CheckWrScreeningLink'],
  });

  // eslint-disable-next-line
  const livoManagesScreening = bid?.offer?.livoManagesScreening;
  // eslint-disable-next-line
  const thirdPartyScreeningLink = stringifyHref(bid?.offer?.screeningLink);

  const beginScreeningResult = beginWesternReportingScreeningResponse?.beginWesternReportingScreening?.result;

  const companyWesternReporting = bid?.offer?.activeOfferSingleUnit?.company?.companyWesternReportingAccount;
  // eslint-disable-next-line
  const isUsingWesternReporting = companyWesternReporting?.isUsingWesternReporting || false;

  const companyId = bid?.offer?.activeOfferSingleUnit?.company?.id || '';

  const { data: screeningData } = useQuery(CHECK_SCREENING_LINK, {
    variables: {
      renterFilter: {
        renter: {
          id: {
            equals: bid?.renter?.id,
          },
        },
        company: {
          id: {
            equals: companyId,
          },
        },
      },
    },
  });
  // eslint-disable-next-line
  const screeningLink = screeningData?.westernReportingScreeningsList?.items[0]?.screeningLink || undefined;

  const [westernReportingScreenings] = useMutationSafe(WR_SCREENING_MUTATION, {
    refetchQueries: ['CheckWrScreeningLink'],

    onError: error => {
      toast.error(`Error: ${error}`);
    },
  });

  const westernReportingScreeningMutate = React.useCallback(
    async (link: string) => {
      if (beginScreeningResult && westernReportingXMLResponseHasNoErrors(beginScreeningResult)) {
        const rawScreeningFormResult = beginScreeningResult;
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(rawScreeningFormResult, 'text/xml');
        const rawScreeningId = xmlDoc?.querySelector('OrderId');
        const rawScreeningStatus = xmlDoc?.querySelector('OrderStatus');

        const renterId = bid?.renter?.id || '';
        const cleanScreeningId = rawScreeningId?.innerHTML || '';
        const cleanScreeningStatus = rawScreeningStatus?.innerHTML || '';

        await westernReportingScreenings({
          variables: {
            data: {
              status: cleanScreeningStatus,
              screeningID: cleanScreeningId,
              screeningLink: link,
              renter: {
                connect: {
                  id: renterId,
                },
              },
              company: {
                connect: {
                  id: companyId,
                },
              },
            },
          },
        });
      }
    },
    [beginScreeningResult, bid, companyId, westernReportingScreenings],
  );

  const validateScreeningLink = React.useCallback(() => {
    if (beginScreeningResult && westernReportingXMLResponseHasNoErrors(beginScreeningResult)) {
      const rawScreeningFormResult = beginScreeningResult;
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(rawScreeningFormResult, 'text/xml');
      const rawScreeningFormLink = xmlDoc?.querySelector('ApplicantInterfaceURL');
      const cleanLink =
        rawScreeningFormLink?.innerHTML
          .replace('<![CDATA[', '')
          .replace(']]>', '')
          .split('amp;')
          .join('') || '';

      if (cleanLink.length === 0) {
        return toast.error('Something went wrong');
      } else {
        westernReportingScreeningMutate(cleanLink);
      }
    }
  }, [beginScreeningResult, westernReportingScreeningMutate]);

  React.useEffect(() => {
    if (beginWesternReportingScreeningResponse && !beginWesternReportingScreeningResponseLoading) {
      validateScreeningLink();
    }
  }, [
    beginWesternReportingScreeningResponse,
    beginWesternReportingScreeningResponseLoading,
    validateScreeningLink,
    westernReportingScreeningMutate,
  ]);

  // eslint-disable-next-line
  const onBeginScreening = async () => {
    const renterId = bid?.renter?.id || '';
    const companyId = bid?.offer?.activeOfferSingleUnit?.company?.id || '';

    await beginWesternReportingScreening({
      variables: {
        data: {
          renterId: renterId,
          companyId: companyId,
        },
      },
    });
  };

  const timeToApproveOffer = getTimeToApproveOffer(bid?.offer);

  return (
    <Container>
      <InformationSection header="Pending items checklist">
        <Grid.Layout gap="15px">
          <Announcement>
            <Label>Important: </Label>
            You have {timeToApproveOffer} left to complete all items of this list. After that, the property manager will
            award you the lease.
          </Announcement>
          <Grid.Layout gap="12px">
            <Checkbox
              disabled
              checked={bid?.offerAccepted || false}
              className="livo-checkbox-circle livo-checkbox-circle-not-editable"
            >
              Offer accepted
            </Checkbox>
            <Checkbox
              disabled
              checked={bid?.applicationFormComplete || false}
              className="livo-checkbox-circle livo-checkbox-circle-not-editable"
            >
              Application and screening completed
              <span css={{ marginLeft: 20 }}>
                <WebsiteLink website={bid?.offer?.applicationLink || 'livo.com'} text={'Begin Process'} />
              </span>
            </Checkbox>
            {/*<Checkbox*/}
            {/*  disabled*/}
            {/*  checked={bid?.screeningComplete || false}*/}
            {/*  className="livo-checkbox-circle livo-checkbox-circle-not-editable"*/}
            {/*>*/}
            {/*  Screening complete*/}
            {/*  <If condition={!!livoManagesScreening}>*/}
            {/*    {isUsingWesternReporting && !screeningLink ? (*/}
            {/*      <ButtonLink css={{ display: 'inline-block', marginLeft: '20px' }} onClick={onBeginScreening}>*/}
            {/*        Get Screening Link*/}
            {/*      </ButtonLink>*/}
            {/*    ) : (*/}
            {/*      <Link css={{ marginLeft: 20 }} target="_blank" href={screeningLink}>*/}
            {/*        Begin Screening*/}
            {/*      </Link>*/}
            {/*    )}*/}
            {/*  </If>*/}
            {/*  <If condition={!livoManagesScreening && !!thirdPartyScreeningLink}>*/}
            {/*    <Link css={{ marginLeft: 20 }} target="_blank" href={thirdPartyScreeningLink}>*/}
            {/*      Begin Screening*/}
            {/*    </Link>*/}
            {/*  </If>*/}
            {/*</Checkbox>*/}
            {/*<Checkbox*/}
            {/*  disabled*/}
            {/*  checked={bid?.depositCheck || false}*/}
            {/*  className="livo-checkbox-circle livo-checkbox-circle-not-editable"*/}
            {/*>*/}
            {/*  Deposit check*/}
            {/*</Checkbox>*/}
            <Checkbox
              disabled
              checked={bid?.leaseSigned || false}
              className="livo-checkbox-circle livo-checkbox-circle-not-editable"
            >
              Lease signed
            </Checkbox>
          </Grid.Layout>
        </Grid.Layout>
      </InformationSection>
    </Container>
  );
};
