import React from 'react';
import { Modal, Divider } from 'antd';
import { useQuery } from 'react-apollo';

import { ModalFooter, PropertyInfo, Loader, Scrollable } from '@/components';
import { dialogsContext } from '@/providers';
import { Offer, SingleUnit } from '@/shared/types/graphql';
import { OfferDetails } from '@/pro';
import { OFFER_STATUSES } from '@/shared/constants';
import { OkProps } from '@/components/ModalFooter/ModalFooter';
import { useDialogState } from '@/shared/hooks';

import { OFFER_DETAILS_QUERY } from './queries';
import { OFFER_UPDATE_DIALOG, OFFER_CANCEL_CONFIRM_DIALOG } from '../../index';

export const OFFER_DETAILS_DIALOG = 'OFFER_DETAILS_DIALOG';

export const OfferDetailsDialog = () => {
  const { close, isVisible, props } = useDialogState(OFFER_DETAILS_DIALOG);
  const { close: closeUpdateDialog, props: propsUpdateDialog } = useDialogState(OFFER_UPDATE_DIALOG);

  const { openDialog } = React.useContext(dialogsContext);

  const offerId = props.offerId;
  const singleUnitId = props.singleUnitId;

  const { loading, data } = useQuery<{ offer: Offer }>(OFFER_DETAILS_QUERY, {
    variables: { id: offerId },
    skip: !offerId,
  });

  const singleUnit = data?.offer?.historyOfferSingleUnit as SingleUnit;
  const offer = data?.offer;

  const bidsCount = offer?.bid?.count || 0;
  const noBids = bidsCount === 0;

  const canEditOffer =
    offer?.status === OFFER_STATUSES.pending ||
    offer?.status === OFFER_STATUSES.draft ||
    (offer?.status === OFFER_STATUSES.live && noBids);

  const okProps =
    offer?.status !== OFFER_STATUSES.leased && offer?.status !== OFFER_STATUSES.canceled
      ? ({
          text: 'Edit Livo Transaction',
          type: 'primary',
          disabled: !canEditOffer,
          onClick: () => {
            close();

            openDialog({
              name: OFFER_UPDATE_DIALOG,
              props: {
                ...propsUpdateDialog,
                singleUnitId,
                offerId,
              },
            });
          },
        } as OkProps)
      : undefined;

  const openEndOfferModal = React.useCallback(
    () =>
      openDialog({
        name: OFFER_UPDATE_DIALOG,
        props: {
          offerId,
          onCompletedAction: closeUpdateDialog,
        },
      }),

    [closeUpdateDialog, offerId, openDialog],
  );

  const openofferCancelConfirmModal = React.useCallback(
    () =>
      openDialog({
        name: OFFER_CANCEL_CONFIRM_DIALOG,
        props: {
          offerId,
          singleUnitId,
          status: offer?.status,
        },
      }),
    [offer, offerId, openDialog, singleUnitId],
  );

  const endOfferButtonRemoved =
    offer?.status === OFFER_STATUSES.ended ||
    offer?.status === OFFER_STATUSES.closed ||
    offer?.status === OFFER_STATUSES.canceled ||
    offer?.status === OFFER_STATUSES.leased ||
    offer?.status === OFFER_STATUSES.secured ||
    !canEditOffer;

  const modalTitle = `Livo transaction ${offer?.numberId ? `#${offer?.numberId}` : ''}`;

  if (!isVisible) {
    return null;
  }

  return (
    <Modal
      title={modalTitle}
      visible={isVisible}
      className="livo-modal"
      width="100%"
      style={{ maxWidth: 635 }}
      onCancel={close}
      centered
      footer={
        <ModalFooter
          showLeftAction={!loading && !endOfferButtonRemoved}
          leftActionProps={{
            text:
              offer?.status === OFFER_STATUSES.ended || offer?.status === OFFER_STATUSES.closed
                ? 'Cancel Livo Offer'
                : 'End Livo Offer',
            onClick:
              offer?.status === OFFER_STATUSES.ended || offer?.status === OFFER_STATUSES.closed
                ? openofferCancelConfirmModal
                : openEndOfferModal,
            color: '#ff3100',
          }}
          cancelProps={{
            onClick: close,
          }}
          okProps={okProps}
        />
      }
    >
      {loading ? (
        <Loader stretch height="594px" />
      ) : (
        <Scrollable>
          <PropertyInfo singleUnit={singleUnit} />
          <Divider />
          <OfferDetails offer={offer} />
        </Scrollable>
      )}
    </Modal>
  );
};
