import React from 'react';
import styled from '@emotion/styled';
import { Button } from 'antd';

import { Dots } from '../components';
import { OnboardingFooterProps } from './types';

// TODO: use colours from theme
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 800px;
  padding: 24px;
  border-top: 1px solid #e8e8e8;
`;

export const OnboardingFooter = ({
  handleOnboardingClose,
  dotsFunctions,
  currentStepNumber,
  setNextStep,
}: OnboardingFooterProps) => {
  const isLastStep = currentStepNumber === dotsFunctions.length - 1;

  return (
    <Container>
      <Dots dotsFunctions={dotsFunctions} currentStepNumber={currentStepNumber} />
      <div>
        {!isLastStep && <Button onClick={handleOnboardingClose}>Skip</Button>}
        <Button style={{ marginLeft: 12 }} type="primary" onClick={isLastStep ? handleOnboardingClose : setNextStep}>
          {isLastStep ? 'Start' : 'Next'}
        </Button>
      </div>
    </Container>
  );
};
