import React from 'react';
import styled from '@emotion/styled';
import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { CardProps } from './types';

const Container = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 159px 136px;
  grid-template-rows: 37px 1fr 1fr;
  grid-column-gap: 8px;
  padding: 19px 25px 19px 30px;
  grid-template-areas: 'Title Img' 'Description Img' 'Link Img';
  box-shadow: 0 1px 6px 0 rgba(50, 50, 93, 0.12), 0 4px 8px 0 rgba(112, 157, 199, 0.06);
`;
// TODO: take colours values from theme
const Title = styled.div`
  grid-area: Title;
  height: 18px;
  color: #323c47;
  font-size: 18px;
  letter-spacing: 0.5px;
  line-height: 18px;
  margin-top: 19px;
`;
// TODO: take colours values from theme
const Description = styled.div`
  grid-area: Description;
  color: #929da9;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0;
  margin: 9px 0 7px 1px;
`;

const ImgPosition = styled.div`
  grid-area: Img;
  display: grid;
  align-items: center;
  justify-content: center;
`;

const LinkPosition = styled.div`
  grid-area: Link;
  .ant-dropdown-link {
    font-size: 12px;
    letter-spacing: 0.18px;
    line-height: 20px;
    display: block;
  }
`;

export const Card = ({ title, description, links, img: Image, imgStyles }: CardProps) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <LinkPosition>
        {links.map(({ text, url }, index) => {
          if (!url) {
            return (
              <Link key={index} className="ant-dropdown-link" to="#">
                <Tooltip title="Feature in Development">{text}</Tooltip>
              </Link>
            );
          }
          return (
            <Link key={index} className="ant-dropdown-link" to={url}>
              {text}
            </Link>
          );
        })}
      </LinkPosition>
      <ImgPosition style={imgStyles}>
        <Image />
      </ImgPosition>
    </Container>
  );
};
