import gql from 'graphql-tag';

export const BIDS_LIST_BY_RENTER_QUERY = gql`
  query BidsListByRenter($filter: BidFilter) {
    bidsList(filter: $filter) {
      items {
        id
        rentAmount
        status
        moveinDate
        termsOfLease
        offer {
          id
          endsIn
          status
          historyOfferSingleUnit {
            id
            name
          }
        }
      }
    }
  }
`;
