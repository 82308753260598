import React from 'react';

import { PoweredBy } from '@/components';
import { Container, Main, Content, InnerContent } from './styled';

import { Sidebar } from './Sidebar';
import { Header } from './Header';
import { managementLayoutContext } from '@/providers';

export const Layout = ({ children }: { children: React.ReactChild }) => {
  const { isSidebarOpen } = React.useContext(managementLayoutContext);

  return (
    <Container isSidebarOpen={isSidebarOpen}>
      <Sidebar />
      <Main>
        <Header />
        <Content>
          <InnerContent>{children}</InnerContent>
          <PoweredBy />
        </Content>
      </Main>
    </Container>
  );
};
