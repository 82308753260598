import React from 'react';
import { Modal, Icon, Typography } from 'antd';

import { ModalFooter } from '@/components';
import { RedAlert } from '@/static/icons';
import { TOAST_SUCCESS_MESSAGE } from '@/shared/constants';
import { TEAM_MEMBER_DELETE_MODAL_MUTATION } from './queries';
import { useMutationSafe, useDialogState } from '@/shared/hooks';

export const TEAM_MEMBER_DELETE_DIALOG = 'TEAM_MEMBER_DELETE_DIALOG';

export const TeamMemberDeleteDialog = () => {
  const { close, isVisible, props } = useDialogState(TEAM_MEMBER_DELETE_DIALOG);

  const [deleteTeamMember, { loading: deleteTeamMemberLoading }] = useMutationSafe(TEAM_MEMBER_DELETE_MODAL_MUTATION, {
    refetchQueries: ['UsersList', 'CurrentCompany', ...(props?.refetchQueries || [])],
    awaitRefetchQueries: true,
    context: {
      [TOAST_SUCCESS_MESSAGE]: 'Team Member Successfully Deleted',
    },
    onCompleted: close,
  });
  const userName = props?.firstName || '';
  const userLastName = props?.lastName || '';

  const title = React.useMemo(
    () => (
      <>
        <Icon
          component={RedAlert}
          css={{ marginRight: '8px', fontSize: '20px', lineHeight: '22px', verticalAlign: 'middle' }}
        />
        Delete Team Member
      </>
    ),
    [],
  );

  if (!isVisible) {
    return null;
  }

  return (
    <Modal
      title={title}
      visible={isVisible}
      onCancel={close}
      width="100%"
      style={{ maxWidth: 400 }}
      footer={
        <ModalFooter
          cancelProps={{
            onClick: close,
          }}
          okProps={{
            disabled: !props?.userId || deleteTeamMemberLoading,
            loading: deleteTeamMemberLoading,
            onClick: () => {
              deleteTeamMember({
                variables: {
                  userId: props?.userId,
                },
              });
            },
            type: 'danger',
            text: 'Delete',
          }}
        />
      }
      centered
    >
      <Typography.Paragraph>{`Are you sure you want to delete ${userName} ${userLastName} from this team?`}</Typography.Paragraph>
    </Modal>
  );
};
