import React from 'react';

import { Offer } from '@/shared/types/graphql';
import { Card } from 'antd';
import { BidOffersTimeline } from '@/pro';
import { BID_STATUSES, OFFER_COMBINED_STATUSES } from '@/shared/constants';
import { useOfferBidCombinedStatus } from '@/shared/hooks';
import { CardWrapper } from './styled';
import { ButtonCard } from './ButtonCard';
import { Header } from './Header';

type OfferCardProps = {
  offer: Offer;
};

export const OfferCard: React.FC<OfferCardProps> = ({ offer }) => {
  const bidsList = offer?.bid?.items || [];
  const filteredBids = bidsList.filter(bid => bid?.status !== BID_STATUSES.rejected);

  const offerBidCombinedStatus = useOfferBidCombinedStatus(offer);

  const offerStatusIsLeased = offerBidCombinedStatus.kind === OFFER_COMBINED_STATUSES.leased;
  const offerStatusIsCanceled = offerBidCombinedStatus.kind === OFFER_COMBINED_STATUSES.canceled;
  const offerStatusIsRejected = offerBidCombinedStatus.kind === OFFER_COMBINED_STATUSES.rejected;
  const offerStatusIsEnded = offerBidCombinedStatus.kind === OFFER_COMBINED_STATUSES.ended;

  return (
    <CardWrapper disabled={offerBidCombinedStatus.offerCardDisabled}>
      <Card cover={<Header offer={offer} />}>
        <BidOffersTimeline
          offer={offer}
          bids={filteredBids}
          isUserRejected={offerStatusIsRejected}
          size="sm"
          actions={
            offerStatusIsLeased || offerStatusIsCanceled || offerStatusIsRejected || offerStatusIsEnded
              ? undefined
              : () => <ButtonCard offer={offer} />
          }
        />
      </Card>
    </CardWrapper>
  );
};
