import React from 'react';
import { useHistory } from 'react-router-dom';
import { dialogsContext } from '@/providers';
import { Offer, Bid, Maybe } from '@/shared/types/graphql';
import { getSingleRentalUrl } from '@/shared/constants/appRoutes';
import { BID_CREATE_DIALOG, BID_DELETE_DIALOG } from '@/dialogs';

interface SharedDialogProps {
  offer?: Maybe<Offer>;
  bid: Bid;
  userBid?: Bid;
}

export const useSharedDialogProps = ({ bid, offer, userBid }: SharedDialogProps) =>
  React.useMemo(
    () => ({
      visible: true,
      options: {
        refetchQueries: ['SingleUnitOffer', 'SingleUnitBidsOffer', 'BidsListQuery', 'SingleUnitOfferQuery'],
        awaitRefetchQueries: true,
      },
      incrementedBidAmount: offer?.incrementedBidAmount,
      securityDeposite: bid?.securityDeposite || offer?.securityDeposite,
      offerId: offer?.id,
      minAmount: offer?.rentAmount,
      maxAmount: offer?.secureNowAmount,
      minimumLeaseLength: offer?.minimumLeaseLength,
      maximumLeaseLength: offer?.maximumLeaseLength,
      userBidId: userBid?.id,
    }),
    [bid, offer, userBid],
  );

interface BidCreateDialogProps {
  offer?: Maybe<Offer>;
  bid: Bid;
  sharedModalProps: any;
  rentAmount: number;
  offerMoveinDate?: string;
  termsOfLease?: number;
}

export const useBidCreateDialog = ({
  offer,
  bid,
  sharedModalProps,
  offerMoveinDate,
  rentAmount,
  termsOfLease,
}: BidCreateDialogProps) => {
  const { openDialog } = React.useContext(dialogsContext);

  return React.useCallback(
    () =>
      openDialog({
        name: BID_CREATE_DIALOG,
        props: {
          ...sharedModalProps,
          securityDepositeEditable: offer?.securityDepositeEditable,
          rentAmount,
          termsOfLease,
          moveinDate: bid?.moveinDate,
          moveinDateEditable: offer?.moveinDateEditable,
          offerMoveinDate,
        },
      }),
    [openDialog, sharedModalProps, offer, rentAmount, bid, offerMoveinDate, termsOfLease],
  );
};

interface BidDeleteDialogProps {
  offer?: Maybe<Offer>;
  bid: Bid;
  offerId: any;
}

export const useBidDeleteDialog = ({ offer, bid, offerId }: BidDeleteDialogProps) => {
  const { openDialog } = React.useContext(dialogsContext);
  const history = useHistory();

  return React.useCallback(
    () =>
      openDialog({
        name: BID_DELETE_DIALOG,
        props: {
          options: {
            refetchQueries: ['SingleUnitOffer', 'SingleUnitBidsOffer', 'BidsListQuery', 'SingleUnitOfferQuery'],
            awaitRefetchQueries: true,
            onCompleted: () => {
              history.push(getSingleRentalUrl(offerId));
            },
          },
          visible: true,
          bidId: bid?.id,
          offerId: offer?.id,
        },
      }),
    [openDialog, bid, offer, history, offerId],
  );
};
