import React from 'react';
import { NOT_AVALIABLE, OFFER_STATUS_LABELS } from '@/shared/constants';
import { Tag } from '@/components';
import { Maybe } from '@/shared/types/graphql';

const COLOR_BY_TYPE: Record<keyof typeof OFFER_STATUS_LABELS, string> = {
  draft: '',
  ended: 'red',
  secured: 'red',
  closed: 'red',
  live: 'orange',
  pending: 'blue',
  leased: 'purple',
  canceled: '',
};

type OfferStatusTagProps = {
  status?: Maybe<string>;
  withColors?: boolean;
};

export const OfferStatusTag: React.FC<OfferStatusTagProps> = ({ status, withColors = true }) => {
  if (!status || !(OFFER_STATUS_LABELS as any)[status]) {
    return <>{NOT_AVALIABLE}</>;
  }

  return <Tag color={withColors && (COLOR_BY_TYPE as any)[status]}>{(OFFER_STATUS_LABELS as any)[status]}</Tag>;
};
