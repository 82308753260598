import React from 'react';
import { Empty } from 'antd';

import { Loader, ResumeNumbers, CreateOfferSwitch } from '@/components';
import { SingleUnit } from '@/shared/types/graphql';
import { Container, List, ListItem, Column } from './styled';
import { useCurrentCompanyId } from '@/shared/hooks';
import { SINGLE_UNIT_STATUSES } from '@/shared/constants';
import { ConditionalLink } from '@/pro';

type SingleUnitsListProps = {
  communityId?: string;
  loading: boolean;
  units: SingleUnit[];
};

export const SingleUnitsList = ({ communityId, loading, units }: SingleUnitsListProps) => {
  const { companyId } = useCurrentCompanyId();

  return (
    <Container alignItemsCenter={loading || !units?.length}>
      {loading ? (
        <Loader stretch />
      ) : (
        <List>
          {units?.length ? (
            units?.map(item => (
              <ListItem key={item?.id as string | number | undefined}>
                <Column>
                  <ConditionalLink
                    condition={item?.status !== SINGLE_UNIT_STATUSES.draft}
                    to={`/management/${companyId}/communities/${communityId}/single-units/${item.id}/details`}
                    text={item.name}
                  />
                </Column>
                <Column>
                  <ResumeNumbers beds={item?.bedrooms} baths={item?.bathrooms} squareFt={item?.squareFt} />
                </Column>
                <Column>
                  <If condition={item?.status !== SINGLE_UNIT_STATUSES.draft}>
                    <CreateOfferSwitch singleUnit={item} refetchQueries={['CommunityCard']} />
                  </If>
                </Column>
              </ListItem>
            ))
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </List>
      )}
    </Container>
  );
};
