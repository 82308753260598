import React from 'react';
import styled from '@emotion/styled';
import pluralize from 'pluralize';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'antd';

import { Tag } from '@/components';
import { RentIcon, TimeIcon, CalendarIcon, SqftIcon, BedsIcon, BathsIcon } from '@/static/icons';
import { EntityOption } from '@/pro/EntityOption';
import { LeftTime } from '@/pro/LeftTime';

import { formatAmount, stringifyAddress } from '@/shared/utils';
import { NOT_AVALIABLE } from '@/shared/constants';
import { getSingleRentalUrl } from '@/shared/constants/appRoutes';
import { useOfferBidCombinedStatus } from '@/shared/hooks';

import { Offer } from '@/shared/types/graphql';

const PropertyCardTag = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #e8e8e8;
  overflow: hidden;
  border-radius: 4px;
  height: 230px;
  min-width: 660px;

  @media (max-width: 757px) {
    flex-direction: column;
    height: auto;
    min-width: auto;
  }
`;

const PropertyCardImageTag = styled.div<{ mediaUrl?: string | null }>`
  display: flex;
  flex: 1;
  height: 100%;
  background: #fafafa;
  background-image: ${({ mediaUrl }) => (mediaUrl ? `url(${mediaUrl})` : 'none')};
  background-position: center;
  background-size: cover;
  position: relative;
  min-width: 330px;

  @media (max-width: 757px) {
    min-width: 100%;
    min-height: 300px;
  }
`;

const PropertyCardImageAddressTag = styled.div`
  display: flex;
  position: absolute;
  left: 10px;
  top: 10px;
  color: #fff;
  width: 130px;
  font-size: 12px;
  font-weight: 600;
  text-shadow: 0 0 2px rgba(50, 60, 71, 1);
`;

const PropertyCardImageOffersTag = styled.div`
  display: flex;
  position: absolute;
  right: 10px;
  top: 10px;
`;

const PropertyCardImageFooterTag = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 40px;
  background-color: rgba(50, 60, 71, 0.8);
  width: 100%;
  color: #fff;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 17px;
`;

const PropertyCardInfoTag = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  padding: 20px 30px;
  width: 100%;

  & > *:not(:first-child) {
    margin-top: 10px;
  }
`;

const PropertyCardInfoTitleTag = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #323c47;
`;

const PropertyCardInfoOffferTag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

type PropertyCardProps = {
  offer: Offer;
};

export const PropertyCard: React.FC<PropertyCardProps> = ({ offer }) => {
  const singleUnit = offer?.historyOfferSingleUnit;
  const offerId = offer?.id;
  const squareFt = singleUnit?.squareFt;
  const bedrooms = singleUnit?.bedrooms;
  const bathrooms = singleUnit?.bathrooms;
  const address = singleUnit?.address;
  const name = singleUnit?.name;
  const mediaUrl: string | undefined | null = singleUnit?.singleUnitHasMedia?.items[0]?.media?.file?.downloadUrl;
  const bidsCount = offer?.bid?.count || 0;
  const securityDeposit = formatAmount(offer?.securityDeposite);
  const termsOfLease = offer?.termsOfLease;
  const rentAmount = formatAmount(offer?.rentAmount);
  const moveinDate = offer?.moveinDate;
  const endsIn = offer?.endsIn;

  const { kind: combinedOfferStatus } = useOfferBidCombinedStatus(offer);

  return (
    <PropertyCardTag>
      <PropertyCardImageTag mediaUrl={mediaUrl}>
        <PropertyCardImageAddressTag>{address ? stringifyAddress(address) : NOT_AVALIABLE}</PropertyCardImageAddressTag>
        <PropertyCardImageOffersTag>
          <Choose>
            <When condition={combinedOfferStatus === 'leased'}>
              <Tag color="red" type="raised">
                Property Leased
              </Tag>
            </When>
            <When condition={combinedOfferStatus === 'ended'}>
              <Tag color="red" type="raised">
                Auction Ended
              </Tag>
            </When>
            <When condition={combinedOfferStatus === 'pending'}>
              <Tag color="orange" type="raised">
                Auction Upcoming
              </Tag>
            </When>
            <When condition={combinedOfferStatus === 'canceled'}>
              <Tag color="red" type="raised">
                Auction Canceled
              </Tag>
            </When>
            <Otherwise>
              <Tag color="green" type="raised">
                {bidsCount} {pluralize('offer', bidsCount)}
              </Tag>
            </Otherwise>
          </Choose>
        </PropertyCardImageOffersTag>
        <PropertyCardImageFooterTag>
          <span css={{ fontWeight: 600 }}>${rentAmount}</span>/mo&nbsp;&nbsp;&nbsp;
          <Icon component={SqftIcon} css={{ fontSize: 16 }} />
          &nbsp;&nbsp;
          <span css={{ fontWeight: 600 }}>{squareFt}</span>&nbsp;SQ.FT&nbsp;&nbsp;&nbsp;
          <Icon component={BedsIcon} css={{ fontSize: 16 }} />
          &nbsp;&nbsp;
          <span css={{ fontWeight: 600 }}>{bedrooms}</span>&nbsp;&nbsp;&nbsp;
          <Icon component={BathsIcon} css={{ fontSize: 16 }} />
          &nbsp;&nbsp;
          <span css={{ fontWeight: 600 }}>{bathrooms}</span>
        </PropertyCardImageFooterTag>
      </PropertyCardImageTag>
      <PropertyCardInfoTag>
        <PropertyCardInfoTitleTag>{name}</PropertyCardInfoTitleTag>
        <PropertyCardInfoOffferTag>
          {combinedOfferStatus === 'live' && <LeftTime size="sm" endsDate={DateTime.fromISO(endsIn)} />}
        </PropertyCardInfoOffferTag>
        <EntityOption
          icon={TimeIcon}
          label="Preferred Terms"
          value={termsOfLease ? `${termsOfLease} ${pluralize('month', termsOfLease)}` : NOT_AVALIABLE}
        />
        <EntityOption
          icon={CalendarIcon}
          label="Preferred Move-in Date"
          value={moveinDate ? DateTime.fromISO(moveinDate).toFormat('DD') : NOT_AVALIABLE}
        />
        <EntityOption
          icon={RentIcon}
          label="Security Deposit"
          value={securityDeposit ? `$${securityDeposit}` : NOT_AVALIABLE}
        />
        <div>
          <Link to={getSingleRentalUrl(offerId)}>
            <Button type="primary" size="large">
              Apply and Submit Offer
            </Button>
          </Link>
        </div>
      </PropertyCardInfoTag>
    </PropertyCardTag>
  );
};
