import gql from 'graphql-tag';

export const SINGLE_UNITS_IMPORT_MODAL_MUTATION = gql`
  mutation SingleUnitsImportModalMutation($data: [SingleUnitCreateManyInput]!, $validationOnly: Boolean) {
    singleUnitImport(data: $data, validationOnly: $validationOnly) {
      validationSuccess
      importSuccess
    }
  }
`;
