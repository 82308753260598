import { TableState } from '@/shared/hooks';

import { COMPANY_STATUSES } from '@/shared/constants';
import { CompanyStatuses, CompanyTypes } from '@/shared/constants/company';

export type FiltersType = {
  type: CompanyTypes;
  status: CompanyStatuses;
};

export const getSort = (queryParams: TableState) => {
  let sort: Record<string, any>[] = [];

  if (queryParams.sortBy && queryParams.sortOrder) {
    const field = queryParams.sortBy;
    const direction = queryParams.sortOrder === 'ascend' ? 'ASC' : 'DESC';

    if (field === 'address') {
      sort = [
        {
          address: {
            street1: direction,
          },
        },
      ];
    } else if (field) {
      sort = [
        {
          [field]: direction,
        },
      ];
    }
  }

  return sort;
};

export const getQueryVariablesCompanyList = (query: string, filters: FiltersType, tableState: TableState) => {
  const { page, sortBy, sortOrder, pageSize } = tableState;

  const filterQueryAND = {
    type: {
      equals: filters.type,
    },
    status: {
      equals: filters.status,
    },
    isBlocked: {
      equals: false,
    },
  };

  const blockedStatusQueryAND = {
    type: {
      equals: filters.type,
    },
    isBlocked: {
      equals: true,
    },
  };

  return {
    filter: {
      AND: [
        filters.status === COMPANY_STATUSES.blocked ? blockedStatusQueryAND : filterQueryAND,
        query !== ''
          ? {
              name: {
                contains: query,
              },
            }
          : {},
      ],
    },
    sort: getSort({ sortOrder, sortBy, page, pageSize }),
    first: pageSize,
    skip: (page - 1) * pageSize,
  };
};
