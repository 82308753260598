import gql from 'graphql-tag';

export const PORTFOLIO_OFFERS_BY_COMMUNITY_QUERY = gql`
  query PortfolioOffersByCommunity($filter: OfferFilter) {
    offersList(
      filter: $filter
      groupBy: {
        query: {
          id: { as: "offersCount", fn: { aggregate: COUNT } }
          activeOfferSingleUnit: { community: { id: { as: "communityId" } } }
        }
      }
    ) {
      groups {
        offersCount: Int
        communityId: String
      }
    }
  }
`;

export const PORTFOLIO_COMMUNITIES_QUERY = gql`
  query PortfolioCommunitiesList($first: Int, $skip: Int, $filter: CommunityFilter, $sort: [CommunitySort!]) {
    communitiesList(first: $first, skip: $skip, filter: $filter, sort: $sort) {
      items {
        id
        communityId
        name
        managerName
        managerEmail
        managerPhoneNumber {
          code
          number
        }
        phoneNumber {
          code
          number
        }
        status
        singleUnit {
          items {
            id
            status
          }
        }
        address {
          street1
          street2
          city
          state
          zip
        }
      }
      count
    }
  }
`;
