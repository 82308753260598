import styled from '@/theme/styled';

export const OfferInfo = styled.div`
  padding: 16px 0;

  > *:not(:first-of-type) {
    margin-top: 8px;
  }
`;

export const OfferInfoTitle = styled.div`
  color: #f37a22;
  font-weight: 600;
  font-size: 14px;
`;

export const OfferInfoSubtitle = styled.div`
  font-size: 14px;
`;

export const OfferInfoText = styled.div`
  font-size: 12px;
  font-weight: 600;
`;

export const OfferInfoLabel = styled.span`
  font-size: 12px;
  color: #939ea7;
`;

export const OfferInfoContacts = styled.div`
  color: #939ea7;
`;
