import gql from 'graphql-tag';

export const BIDDER_REJECT_MODAL_BID_MUTATION = gql`
  mutation BidderRejectModalBidMutation($filter: BidKeyFilter, $data: BidUpdateInput!) {
    bidUpdate(filter: $filter, data: $data) {
      id
    }
  }
`;

export const BIDDER_REJECT_MODAL_BIDDER_QUERY = gql`
  query BidderRejectModalBidderQuery($id: ID!) {
    bidder(id: $id) {
      id
      firstName
      lastName
    }
  }
`;
