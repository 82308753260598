import { useCurrentUser } from '@/shared/hooks';

export const useAllowedCommunities = () => {
  const { user } = useCurrentUser();

  const communityManager = user?.communityManager?.items || [];

  const allowedCommunities = [...communityManager].filter(Boolean);

  return allowedCommunities;
};
