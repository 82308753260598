import { BreadcrumbsRoutes } from './types';
import { useQuery } from 'react-apollo';
import { Community, SingleUnit, Offer } from '@/shared/types/graphql';
import { COMMUNITY_NAME, SINGLE_UNIT_NAME, OFFER_NAME_QUERY } from '@/shared/graphql';
import { useDictionary, useCurrentCompany, usePermissions } from '@/shared/hooks';
import { NEW_AMENITY_GROUPS_LABELS } from '@/shared/constants';

type BreadcrumbNameProps = {
  match: Record<string, any>;
};

function SingleUnitBreadcrumb({ match }: BreadcrumbNameProps) {
  const singleUnitId = match?.params?.singleUnitId;

  const { data, loading } = useQuery<{ singleUnit: SingleUnit }>(SINGLE_UNIT_NAME, {
    variables: {
      id: singleUnitId,
    },
    skip: !singleUnitId,
  });

  return loading ? '...' : data?.singleUnit?.name ?? '';
}

function CompanyBreadcrumb() {
  const { company, loading } = useCurrentCompany();
  return loading ? '...' : company?.name ?? '';
}

function CommunityBreadcrumb({ match }: BreadcrumbNameProps) {
  const communityId = match?.params?.communityId;

  const { data, loading } = useQuery<{ community: Community }>(COMMUNITY_NAME, {
    variables: {
      id: communityId,
    },
    skip: !communityId,
  });

  return loading ? '...' : data?.community?.name ?? '';
}

function OfferBreadcrumb({ match }: BreadcrumbNameProps) {
  const offerId = match?.params?.offerId;

  const { data, loading } = useQuery<{ offer: Offer }>(OFFER_NAME_QUERY, {
    variables: {
      id: offerId,
    },
    skip: !offerId,
  });

  return loading ? '...' : data?.offer?.historyOfferSingleUnit?.name ?? '';
}

function AmenityGroupBreadcrumb({ match }: BreadcrumbNameProps) {
  return match ? NEW_AMENITY_GROUPS_LABELS[match?.params?.amenityKey] : '';
}

export const useBreadcrumbsRoutes = () => {
  const { singleUnit } = useDictionary();

  const { isAdmin } = usePermissions();

  const ADMIN_BREADCRUMBS_ROUTES: BreadcrumbsRoutes = [
    {
      path: '/management/:companyId',
      breadcrumbName: CompanyBreadcrumb,
      matchOptions: { exact: true },
    },
    {
      path: '/management/settings/amenities',
      breadcrumbName: 'Amenity Groups',
      matchOptions: { exact: true },
    },
    {
      path: '/management/settings/amenities/:amenityKey',
      breadcrumbName: AmenityGroupBreadcrumb,
      matchOptions: { exact: true },
    },
  ];

  const BREADCRUMBS_ROUTES: BreadcrumbsRoutes = [
    {
      path: '/management/:companyId/dashboard',
      breadcrumbName: 'Dashboard',
      matchOptions: { exact: true },
    },
    {
      path: '/management/companies',
      breadcrumbName: 'Clients',
      matchOptions: { exact: true },
    },
    {
      path: '/management/:companyId/single-units',
      breadcrumbName: singleUnit.plural,
      matchOptions: { exact: true },
    },
    {
      path: '/management/:companyId/single-units/:singleUnitId',
      breadcrumbName: SingleUnitBreadcrumb,
      matchOptions: { exact: true },
    },
    {
      path: '/management/:companyId/communities',
      breadcrumbName: 'Properties',
      matchOptions: { exact: true },
    },
    {
      path: '/management/:companyId/communities/details/:communityId',
      breadcrumbName: CommunityBreadcrumb,
      matchOptions: { exact: true },
    },
    {
      path: '/management/:companyId/communities/:communityId/single-units',
      breadcrumbName: CommunityBreadcrumb,
      matchOptions: { exact: true },
    },
    {
      path: '/management/:companyId/communities/:communityId/single-units/:singleUnitId',
      breadcrumbName: SingleUnitBreadcrumb,
      matchOptions: { exact: true },
    },
    {
      path: '/management/:companyId/rentals/communities',
      breadcrumbName: 'Livo Transactions',
      matchOptions: { exact: true },
    },
    {
      path: '/management/:companyId/rentals/communities/:communityId',
      breadcrumbName: CommunityBreadcrumb,
      matchOptions: { exact: true },
    },
    {
      path: '/management/:companyId/rentals/single-units',
      breadcrumbName: 'Livo Transactions',
      matchOptions: { exact: true },
    },
    {
      path: '/management/:companyId/rentals/single-units/:offerId',
      breadcrumbName: OfferBreadcrumb,
      matchOptions: { exact: true },
    },
    {
      path: '/management/:companyId/rentals/single-units/:offerId/renters',
      breadcrumbName: 'Renters',
      matchOptions: { exact: true },
    },
    {
      path: '/management/:companyId/leads',
      breadcrumbName: 'LivoLeads™',
      matchOptions: { exact: true },
    },
    {
      path: '/management/:companyId/profile',
      breadcrumbName: 'Company Profile',
      matchOptions: { exact: true },
    },
    {
      path: '/management/:companyId/settings',
      breadcrumbName: 'Settings',
      matchOptions: { exact: true },
    },
    {
      path: '/management/:companyId/settings/integrations',
      breadcrumbName: 'Integrations',
      matchOptions: { exact: true },
    },
    {
      path: '/management/:companyId/settings/team',
      breadcrumbName: 'Team Members',
      matchOptions: { exact: true },
    },
    {
      path: '/management/:companyId/settings/account',
      breadcrumbName: 'Account',
      matchOptions: { exact: true },
    },
    {
      path: '/properties/',
      breadcrumbName: 'Properties',
      matchOptions: { exact: true },
    },
    {
      path: '/properties/:singleUnitId',
      breadcrumbName: SingleUnitBreadcrumb,
      matchOptions: { exact: true },
    },
    {
      path: '/properties/:singleUnitId/active-offer',
      breadcrumbName: 'Current Top Offer',
      matchOptions: { exact: true },
    },
  ];

  return isAdmin ? [...ADMIN_BREADCRUMBS_ROUTES, ...BREADCRUMBS_ROUTES] : BREADCRUMBS_ROUTES;
};
