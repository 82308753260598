import React from 'react';
import { Input } from 'antd';

import { FormField } from '@/components';
import { MetaType, InputType } from '@/shared/types';
import { TextAreaProps } from 'antd/es/input';

type TextAreaFieldProps = TextAreaProps & {
  label?: React.ReactNode;
  input: InputType;
  meta: MetaType;
  placeholder?: string;
  disabled?: boolean;
  'data-e2e-id'?: string;
};

export const TextAreaField = ({ label, input, meta, placeholder, disabled, ...rest }: TextAreaFieldProps) => {
  const { name, value, onChange, onBlur, onFocus } = input;

  return (
    <FormField label={label} meta={meta}>
      <Input.TextArea
        {...rest}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={disabled}
      />
    </FormField>
  );
};
