import React from 'react';
import { css } from '@emotion/core';
import { Tooltip, Icon } from 'antd';
import { InfoIcon } from '@/static/icons';

const iconStyles = css`
  margin-left: 5px;
  font-size: 1.5rem;
  cursor: help;
`;

type InfoProps = {
  title: string;
};

export const Info = ({ title }: InfoProps) => (
  <Tooltip overlayStyle={{ maxWidth: 400 }} title={title}>
    <Icon component={InfoIcon} css={iconStyles} />
  </Tooltip>
);
