import React from 'react';
import { OperationVariables } from 'react-apollo';
import { Dropdown, Icon, Menu } from 'antd';
import { Link } from '@/components';

import { Company } from '@/shared/types/graphql';
import { CompanyEventTypes } from '@/shared/graphql/eventResolver';

import { COMPANY_TYPES } from 'livo-shared';
import { COMPANY_STATUSES } from '@/shared/constants';
import { dialogsContext } from '@/providers';

import { COMMUNITY_CREATE_DIALOG, COMPANY_UPDATE_DIALOG, SINGLE_UNIT_CREATE_DIALOG } from '@/dialogs';

type CompaniesTableActionsProps = {
  company: Company;
  sendEvent: any;
  variablesForRefetch: OperationVariables;
};

export const CompaniesTableActions = ({ company, sendEvent, variablesForRefetch }: CompaniesTableActionsProps) => {
  const { openDialog } = React.useContext(dialogsContext);

  const isMdu = COMPANY_TYPES.mdu === company.type;

  const openCompanyUpdateDialog = React.useCallback(() => {
    openDialog({ name: COMPANY_UPDATE_DIALOG, props: { closable: true, companyId: company.id } });
  }, [company.id, openDialog]);

  const openCommunityCreateDialog = () =>
    openDialog({
      name: COMMUNITY_CREATE_DIALOG,
      props: {
        options: {
          refetchQueries: ['PortfolioCommunitiesList', 'NavigationCommunitiesList'],
          awaitRefetchQueries: true,
        },
        companyId: company.id,
      },
    });

  const openSingleUnitCreateModal = () =>
    openDialog({
      name: SINGLE_UNIT_CREATE_DIALOG,
      props: {
        refetchQueries: ['PortfolioSingleUnitsList', 'NavigationSingleUnitsList', 'NavigationCommunitiesList'],
        awaitRefetchQueries: true,
        companyId: company.id,
        companyType: company.type,
      },
    });

  const archiveCompany = () => {
    sendEvent({
      variables: {
        event: {
          type: CompanyEventTypes.ARCHIVE,
          payload: { id: company.id },
        },
      },
    });
  };

  const reviveCompany = () => {
    sendEvent({
      variables: {
        event: {
          type: CompanyEventTypes.REVIVE,
          payload: { id: company.id },
        },
      },
    });
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={openCompanyUpdateDialog}>Edit</Menu.Item>

      <If condition={company.status !== COMPANY_STATUSES.draft}>
        <Menu.Item>
          <Link to={`/management/${company.id}`}>View Details</Link>
        </Menu.Item>
        <Menu.Item onClick={isMdu ? openCommunityCreateDialog : openSingleUnitCreateModal}>Add Property</Menu.Item>
      </If>

      <If condition={company.isBlocked === true}>
        <Menu.Item onClick={reviveCompany}>Revive</Menu.Item>
      </If>

      <If condition={company.isBlocked === false && company.status !== COMPANY_STATUSES.draft}>
        <Menu.Item onClick={archiveCompany} data-e2e-id="menu.archive">
          Archive
        </Menu.Item>
      </If>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
      <Icon type="ellipsis" />
    </Dropdown>
  );
};
