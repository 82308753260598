import { DRAFT_STATUS } from '@/shared/constants';
import { validateDataByYup } from './validateDataByYup';
import { ObjectSchema } from 'yup';

export const validateEntityDataByDraft = async <T extends { status: string }>(
  yupSchema: ObjectSchema<T>,
  entityData: T,
) => {
  if (entityData.status !== DRAFT_STATUS) {
    return await validateDataByYup(yupSchema, entityData);
  }
};
