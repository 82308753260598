import gql from 'graphql-tag';

export const RENTAL_OFFERS_LIST_QUERY = gql`
  query RentlaOfferList($first: Int, $skip: Int, $filter: OfferFilter, $sort: [OfferSort!]) {
    offersList(first: $first, skip: $skip, filter: $filter, sort: $sort) {
      items {
        id
        securityDeposite
        termsOfLease
        rentAmount
        moveinDate
        endsIn
        status
        bid(sort: [{ rank: DESC }, { updatedAt: ASC }]) {
          items {
            id
            status
            rentAmount
            termsOfLease
            moveinDate
            offerAccepted
            applicationFormComplete
            screeningComplete
            leaseSigned
            depositCheck
            offer {
              id
              status
            }
            renter {
              id
              user {
                id
                firstName
                lastName
              }
            }
            rank
            debugPresentDaySystem
            debugV1
            debugV2
            debugV3
          }
          count
        }
        historyOfferSingleUnit {
          id
          name
          type
          address {
            street1
            street2
            city
            state
            zip
          }
          bedrooms
          bathrooms
          squareFt
          singleUnitHasMedia(sort: { order: ASC }) {
            items {
              id
              altName
              order
              media {
                id
                name
                file {
                  id
                  fileId
                  filename
                  downloadUrl
                  meta
                }
              }
            }
          }
        }
      }
      count
    }
  }
`;
