import styled from '@emotion/styled';

export const BodyContainer = styled.div<{ withAvatar: boolean }>`
  display: grid;
  grid-template-columns: ${({ withAvatar = true }) => (withAvatar ? '240px 1fr' : '1fr')};
  grid-column-gap: 84px;

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
    gap: 25px;
  }
`;

export const PersonalInforamtion = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 77px;

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
    gap: 25px;
    text-align: center;
  }
`;

export const PersonalInformationColumn = styled.div`
  display: grid;
  grid-auto-rows: 56px;
  grid-row-gap: 16px;
`;

export const FormContainer = styled.form`
  display: grid;
`;
