import React from 'react';
import styled from '@/theme/styled';

const BtnTag = styled.button<{ toggled: boolean }>`
  position: relative;
  background-color: transparent;
  border: none;
  outline: none;

  > span {
    &:first-of-type,
    &:last-of-type {
      transform-origin: left center;
      opacity: ${({ toggled }) => (toggled ? 0 : 1)};
    }

    &:first-of-type {
      transform: ${({ toggled }) => (toggled ? 'translateY(5px)' : 'translateY(0)')};
    }

    &:last-of-type {
      transform: ${({ toggled }) => (toggled ? 'translateY(-5px)' : 'translateY(0)')};
    }

    &:nth-of-type(2) {
      transform: ${({ toggled }) => toggled && 'rotate(45deg)'};
    }

    &:nth-of-type(3) {
      transform: ${({ toggled }) => toggled && 'rotate(-45deg)'};
    }
  }
`;

const BtnLine = styled.span`
  display: block;
  width: 20px;
  height: 2px;
  background-color: ${({ theme }) => theme.vars.primaryColor};
  transition: 0.3s;

  &:not(:last-of-type) {
    margin-bottom: 4px;
  }

  &:nth-of-type(2) {
    position: absolute;
  }
`;

type BurgerProps = {
  toggled: boolean;
  onClick: () => void;
};

export const Burger = ({ toggled, onClick }: BurgerProps) => (
  <BtnTag toggled={toggled} onClick={onClick}>
    <BtnLine />
    <BtnLine />
    <BtnLine />
    <BtnLine />
  </BtnTag>
);
