import React from 'react';
import { Field } from '@8base-react/forms';

import { Grid, LogoInputField, DocumentInputField } from '@/components';

// TODO: use theme variables for gap
export const CorporateAssets = () => {
  return (
    <Grid.Layout gap="16px">
      <Grid.Box>
        <Field
          name="companyLogo"
          label="Company Logo"
          buttonText="Select File (PNG, JPEG, SVG)"
          component={LogoInputField}
          accept={['.png', '.jpg', '.jpeg', '.svg']}
          data-e2e-id="client.companyLogo"
        />
      </Grid.Box>
      <Grid.Box>
        <Field
          name="documents"
          label="Corporate and Signed Documents*"
          buttonText="Select multiple files (PDF, DOC, DOCX, PPT, PPTX, XLS, XLSX, CSV, TXT)"
          maxFiles={3}
          component={DocumentInputField}
          accept={['.pdf', '.doc', '.docx', '.ppt', '.pptx', '.xls', '.xlsx', '.csv', '.txt']}
          previewProps={{
            output: { f: 'jpg', p: 1 },
            resize: { width: 150 },
          }}
          previewWidth="1/3"
          isPublic
          data-e2e-id="client.documents"
        />
      </Grid.Box>
    </Grid.Layout>
  );
};
