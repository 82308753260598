import React from 'react';
import { styled } from '@/theme';
import { checkError } from 'livo-shared';
import { FormField } from '@/components';
import { DescriptionEditor } from './DescriptionEditor';
import { EditorState, convertToRaw, ContentState, Modifier } from 'draft-js';
import { FieldInputProps, FieldMetaState } from 'react-final-form';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { formatFontFamily } from './helpers';
import DOMPurify from 'dompurify';
import { DOMPURIFY_CONFIG } from 'livo-shared';

type DescriptionEditorFieldProps = {
  label?: React.ReactNode;
  input: FieldInputProps<any>;
  meta: FieldMetaState<any>;
  maxTextLength?: number;
  height?: number;
  note?: string;
};

const Wrapper = styled.div<{ error: boolean; focus: boolean }>`
  border: 1px solid;
  border-color: ${props => {
    if (props.error) return '#fe4b3d';
    if (props.focus) return '#ff9d4d';
    return '#d9d9d9';
  }};
  border-radius: 4px;
  max-width: 555px;
  transition: all 0.3s;
  box-shadow: ${props => (props.focus ? '0 0 0 2px rgb(243 122 34 / 20%)' : 'none')};

  &:hover {
    border-color: ${props => (props.error ? '#fe4b3d' : '#ff9d4d')};
  }
`;

const createNewEditorState = (pastedText: string, editorState: EditorState): EditorState => {
  const { contentBlocks, entityMap } = htmlToDraft(pastedText);

  const pastedBlocks = ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap();

  const newState = Modifier.replaceWithFragment(
    editorState.getCurrentContent(),
    editorState.getSelection(),
    pastedBlocks,
  );

  return EditorState.push(editorState, newState, 'insert-fragment');
};

export const DescriptionEditorField: React.FC<DescriptionEditorFieldProps> = ({ input, meta, label }) => {
  const { onChange, onBlur, onFocus } = input;
  const { initial } = meta;

  const anyError = checkError(meta);

  const [focus, setFocus] = React.useState<boolean>(false);
  const [editorState, setEditorState] = React.useState<EditorState>(EditorState.createEmpty());

  React.useEffect(() => {
    if (initial) {
      const { contentBlocks, entityMap } = htmlToDraft(initial);
      const initialEditorState = EditorState.createWithContent(
        ContentState.createFromBlockArray(contentBlocks, entityMap),
      );

      setEditorState(initialEditorState);
    }
  }, [initial]);

  const onEditorStateChange = React.useCallback(
    (editorState: EditorState) => {
      const isEditorHasText = editorState.getCurrentContent().hasText();

      setEditorState(editorState);

      if (!isEditorHasText) {
        onChange('');
      } else {
        const convertedState = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        onChange(convertedState);
      }
    },
    [onChange],
  );

  const handlePastedText = React.useCallback((text, html, editorState: EditorState) => {
    if (!html && text) {
      const newEditorState = createNewEditorState(text, editorState);

      setEditorState(newEditorState);
    } else {
      const formatedHtml = formatFontFamily(html);
      const sanitizedHtml = DOMPurify.sanitize(formatedHtml, DOMPURIFY_CONFIG);

      const newEditorState = createNewEditorState(sanitizedHtml, editorState);

      setEditorState(newEditorState);
    }

    return true;
  }, []);

  const handleOnFocus = React.useCallback(
    e => {
      onFocus(e);
      setFocus(val => !val);
    },
    [onFocus],
  );

  const handleOnBlur = React.useCallback(
    e => {
      onBlur(e);
      setFocus(val => !val);
    },
    [onBlur],
  );

  return (
    <FormField meta={meta} label={label}>
      <Wrapper error={anyError} focus={focus}>
        <DescriptionEditor
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          handlePastedText={handlePastedText}
        />
      </Wrapper>
    </FormField>
  );
};
