import React from 'react';
import * as R from 'ramda';
import { FileInput } from '@8base-react/file-input';
import styled from '@/theme/styled';
import { css } from '@emotion/core';
import { Avatar, Spin } from 'antd';

import { ButtonLink } from '@/components';

const AvatarFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AvatarContainer = styled.div`
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  .ant-avatar {
    border: 2px solid #f37a22;
  }
  .ant-avatar-string {
    font-size: 10rem;
  }
  margin-bottom: 24px;

  button {
    position: absolute;
    bottom: -46px;
    left: 0;
    width: 100%;
    height: 46px;

    color: #fff;
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 20px;
    font-weight: 600;

    cursor: pointer;

    background-color: ${({ theme }) => `${theme.vars.primaryColor}00`};
    transition-duration: 0.3s;
  }

  &&:hover {
    button {
      bottom: 0;
      background-color: ${({ theme }) => `${theme.vars.primaryColor}cc`};
    }
  }
`;

const ChangeButton = styled.button``;

type AvatarFieldProps = {
  placeholder?: string;
  note?: string;
  input: any;
  buttonText: string;
  meta: any;
  isPublic?: boolean;
  accept?: string[];
  fromSources?: string[];
  previewProps?: Record<string, any>;
  loading: boolean;
};

export const AvatarField = ({
  placeholder,
  input,
  isPublic = true,
  accept = [],
  fromSources = ['local_file_system'],
  loading,
}: AvatarFieldProps) => {
  const { value, onChange } = input;

  const avatarSrc = R.pathOr('', ['downloadUrl'], value) as string;

  return (
    <FileInput onChange={onChange} value={value} maxFiles={1} public={isPublic}>
      {({ pick }) => (
        <AvatarFieldContainer>
          <AvatarContainer>
            <Spin size="large" spinning={loading}>
              <Avatar size={240} src={avatarSrc} alt={placeholder}>
                {placeholder}
              </Avatar>
              <ChangeButton type="button" onClick={() => pick({ accept, fromSources })}>
                Change
              </ChangeButton>
            </Spin>
          </AvatarContainer>
          <ButtonLink
            css={css`
              color: #ff3100 !important;
            `}
            onClick={() => input.onChange()}
            text="Clear Profile Image"
            disabled={loading || !avatarSrc}
          />
        </AvatarFieldContainer>
      )}
    </FileInput>
  );
};
