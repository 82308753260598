import gql from 'graphql-tag';

export const NAVIGATION_COMMUNITIES_QUERY = gql`
  query NavigationCommunitiesList($first: Int, $skip: Int, $filter: CommunityFilter, $sort: [CommunitySort!]) {
    communitiesList(first: $first, skip: $skip, filter: $filter, sort: $sort) {
      items {
        id
        name
      }
      count
    }
  }
`;

export const NAVIGATION_UNITS_QUERY = gql`
  query NavigationSingleUnitsList($first: Int, $skip: Int, $filter: SingleUnitFilter, $sort: [SingleUnitSort!]) {
    singleUnitsList(first: $first, skip: $skip, filter: $filter, sort: $sort) {
      count
    }
  }
`;

export const RENTALS_AMOUNT_QUERY = gql`
  query OffersGroupedByStatus($filter: OfferFilter) {
    offersList(
      groupBy: { query: { status: { as: "offerStatus" }, id: { as: "offersCount", fn: { aggregate: COUNT } } } }
      filter: $filter
    ) {
      groups {
        offerStatus: String
        offersCount: Int
      }
    }
  }
`;
