import React from 'react';
import { Route, Redirect, useLocation, RouteProps } from 'react-router-dom';
import { useAuth, useAllowedRoles } from '8base-react-sdk';

import { renderContent } from './routerUtils';

export interface ProtectedRouteProps extends RouteProps {
  roles?: string[];
  redirectTo?: string;
}

export const ProtectedRoute = ({
  component,
  children,
  render,
  redirectTo,
  roles = [],
  ...rest
}: ProtectedRouteProps) => {
  const auth = useAuth();

  const location = useLocation();

  const isAllowed = useAllowedRoles(roles) || roles.length === 0;

  const { isEmailVerified } = auth.authClient.getState();
  if (auth.isAuthorized && isAllowed) {
    if (!isEmailVerified) {
      return <Redirect to={{ pathname: '/auth/confirmation', state: { from: location } }} />;
    } else {
      return <Route {...rest} render={props => renderContent({ component, render, children, props })} />;
    }
  }

  return <Redirect to={{ pathname: redirectTo || '/auth', state: { from: location } }} />;
};
