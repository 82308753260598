import React from 'react';
import { Modal, Icon, Typography, Radio } from 'antd';
import { Form, Field } from '8base-react-sdk';
import { toast } from 'react-toastify';
import * as R from 'ramda';

import { RadioGroupField, SelectField, TextAreaField, Grid, ModalFooter } from '@/components';
import { END_OFFER_REASONS, END_OFFER_TYPES, OFFER_STATUSES } from '@/shared/constants';
import { RedAlert } from '@/static/icons';

import { MUTATION_EVENT, OfferUpdateByEventTypes } from '@/shared/graphql/eventResolver';
import { OfferEndDataType } from './types';
import { useMutationSafe, useDialogState } from '@/shared/hooks';

const { Paragraph, Text } = Typography;

const INITIAL_VALUES = {
  status: OFFER_STATUSES.closed,
  endingReason: 'unavailable',
};

export const OFFER_END_DIALOG = 'OFFER_END_DIALOG';

export const OfferEndDialog = () => {
  const { close, isVisible, props } = useDialogState(OFFER_END_DIALOG);

  const coercedEndOfferReasons = React.useMemo(
    () => Object.entries(END_OFFER_REASONS).map(([value, label]) => ({ value, label })),
    [],
  );

  const [endCloseOffer, { loading }] = useMutationSafe(MUTATION_EVENT, {
    onCompleted: () => {
      toast.success('Offer successfully ended');
      close();

      if (props?.onCompletedAction) {
        props?.onCompletedAction();
      }
    },
    refetchQueries: props?.refetchQueries,
  });

  const handleSubmit = (data: OfferEndDataType) => {
    if (data.endingReason !== END_OFFER_TYPES.other && data.otherReason) {
      data = R.omit(['otherReason'], data);
    }

    endCloseOffer({
      variables: {
        event: {
          type: OfferUpdateByEventTypes.OFFER_END_CLOSE,
          payload: {
            ...data,
            id: props.offerId,
          },
        },
      },
      refetchQueries: props.refetchQueries || [],
      awaitRefetchQueries: props.awaitRefetch,
    });
  };

  const title = React.useMemo(
    () => (
      <>
        <Icon
          component={RedAlert}
          css={{ marginRight: '8px', fontSize: '20px', lineHeight: '22px', verticalAlign: 'middle' }}
        />
        End Livo Offer
      </>
    ),
    [],
  );

  if (!isVisible) {
    return null;
  }

  return (
    <Form type="UPDATE" tableSchemaName="Offer" onSubmit={handleSubmit} initialValues={INITIAL_VALUES}>
      {({ handleSubmit, values, form }) => (
        <Modal
          title={title}
          footer={
            <ModalFooter
              cancelProps={{
                text: 'Go Back',
                onClick: close,
                disabled: loading,
              }}
              okProps={{
                text: 'End Offer',
                type: 'danger',
                onClick: handleSubmit,
                disabled: loading,
              }}
            />
          }
          visible={isVisible}
          closable={true}
          maskClosable={false}
          onCancel={close}
          afterClose={form.reset}
        >
          <Paragraph>Do you want to cancel offer or close it?</Paragraph>
          <Paragraph>
            <Text strong>Canceling a Livo Offer</Text> will remove all current bids. No units will be leased
          </Paragraph>
          <Paragraph>
            <Text strong>Ending a Livo Offer</Text> means the auction stops and no new bids can be submitted. Still, you
            can award a lease between current prospects.
          </Paragraph>
          <Field name="status" component={RadioGroupField}>
            <Radio value={OFFER_STATUSES.canceled}>Cancel Offer</Radio>
            <Radio value={OFFER_STATUSES.closed}>End Offer</Radio>
          </Field>
          <Grid.Layout gap="16px" css={{ marginTop: 24 }}>
            <Grid.Box>
              <Field
                name="endingReason"
                label="Reason for ending the Livo Offer"
                component={SelectField}
                options={coercedEndOfferReasons}
                stretch
              />
            </Grid.Box>
            {values.endingReason === END_OFFER_TYPES.other && (
              <Grid.Box>
                <Field
                  name="otherReason"
                  label="Other Reason"
                  component={TextAreaField}
                  style={{ resize: 'none', height: '90px' }}
                />
              </Grid.Box>
            )}
          </Grid.Layout>
        </Modal>
      )}
    </Form>
  );
};
