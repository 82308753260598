import React, { ChangeEvent } from 'react';
import * as R from 'ramda';
import { useQuery } from 'react-apollo';
import { Form, Field } from '8base-react-sdk';
import { Dropdown, Input } from 'antd';

import { ButtonLink, CustomCard, Grid, Heading, SelectField, RowFlex, Tag, Loader } from '@/components';
import { CopyIcon, FilterIcon } from '@/static/icons';
import { useFilters, useSearch, useCurrentCompanyId, useCompanyTypeCheck } from '@/shared/hooks';
import { OFFER_STATUS_LABELS, NOT_AVALIABLE, COMPANY_TYPES } from '@/shared/constants';
import { CommunityListResponse, Community } from '@/shared/types/graphql';

import { SingleUnitsTable, FiltersType } from './SingleUnitsTable';
import { COMMUNITIES_LIST_QUERY } from './queries';
import { useUserFilterPermissions } from '@/routes/management/company/entity/dashboard/root/utils';
import copy from 'copy-to-clipboard';
import { getPublicSingleUnitsUrl } from '@/shared/constants/appRoutes';

const CopyPublicLink = () => {
  const { companyId } = useCurrentCompanyId();

  const onCopy = React.useCallback(() => {
    copy(`${window.location.origin}${getPublicSingleUnitsUrl(companyId)}`);
  }, [companyId]);

  return (
    <ButtonLink onClick={onCopy} icon={<CopyIcon css={{ fill: 'currentColor' }} />}>
      Copy Public Page
    </ButtonLink>
  );
};

const useCommunitiesListQueryFilter = () => {
  const { companyId } = useCurrentCompanyId();
  const { isAdmin, isCompanyOwner, allowedCommunitiesIds, allowedSingleUnitsIds, isMdu } = useUserFilterPermissions();

  const allowedPropertiesQuery = isMdu ? allowedCommunitiesIds : allowedSingleUnitsIds;

  const all: any = [
    {
      company: {
        id: {
          equals: companyId,
        },
      },
    },
    !(isAdmin || isCompanyOwner) && {
      id: {
        in: allowedPropertiesQuery,
      },
    },
  ].filter(Boolean);

  return {
    AND: all,
  };
};

const STATUS_FILTER_OPTIONS = R.keys(OFFER_STATUS_LABELS).map(offerStatusValue => ({
  value: offerStatusValue,
  label: OFFER_STATUS_LABELS[offerStatusValue],
}));

export const SingleUnits = () => {
  const [{ search, throttledSearch }, setSearch] = useSearch();
  const [filters, { setAllFilters, removeFilter }] = useFilters<FiltersType>();
  const [isFilterVisible, changeFilterVisible] = React.useState(false);
  const isMdu = useCompanyTypeCheck(COMPANY_TYPES.mdu);

  const communitiesListQueryFilter = useCommunitiesListQueryFilter();

  const { data, loading } = useQuery<{ communitiesList: CommunityListResponse }>(COMMUNITIES_LIST_QUERY, {
    variables: {
      filter: communitiesListQueryFilter,
    },
  });

  const onChange = React.useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearch(event.currentTarget.value);
    },
    [setSearch],
  );

  if (loading) {
    return (
      <CustomCard padding="none">
        <Loader stretch />
      </CustomCard>
    );
  }

  const communitiesList = data?.communitiesList.items as Community[];

  const COMMUNITY_FILTER_OPTIONS = communitiesList.map(community => ({
    value: community.id,
    label: community.name,
  }));

  const communityName = communitiesList.find(community => community.id === filters.communityId)?.name || NOT_AVALIABLE;

  const header = (
    <Grid.Layout gap="20px" columns="auto 400px 1fr" alignItems="center" justifyItems="flex-start" stretch>
      <Heading type="h1">Livo Transactions</Heading>
      <Input value={search} placeholder="Search by Property name, Address, Livo transaction #" onChange={onChange} />

      <Grid.Layout gap="16px" columns="1fr auto" justifySelf="flex-end" autoFlow="column">
        <RowFlex>
          <Tag visible={!!filters.status} closable onClose={() => removeFilter('status')}>
            {`Offer status: ${OFFER_STATUS_LABELS[filters.status]}`}
          </Tag>
          <Tag visible={!!filters.communityId} closable onClose={() => removeFilter('communityId')}>
            {`Community: ${communityName}`}
          </Tag>
        </RowFlex>
        <Form onSubmit={setAllFilters} initialValues={filters}>
          {({ handleSubmit, form }) => (
            <Dropdown
              trigger={['click']}
              visible={isFilterVisible}
              onVisibleChange={changeFilterVisible}
              overlay={
                <CustomCard
                  padding="sm"
                  width="330px"
                  footer={
                    <RowFlex stretch justify="end" gap="md">
                      <ButtonLink
                        color="default"
                        onClick={() => {
                          changeFilterVisible(false);
                          form.reset();
                        }}
                      >
                        Cancel
                      </ButtonLink>
                      <ButtonLink
                        onClick={async () => {
                          await handleSubmit();
                          changeFilterVisible(false);
                          form.reset();
                        }}
                      >
                        Apply filters
                      </ButtonLink>
                    </RowFlex>
                  }
                >
                  <Grid.Layout gap="20px">
                    <Grid.Box>
                      <Field
                        stretch
                        label="Offer status"
                        name="status"
                        placeholder="Filter by"
                        options={STATUS_FILTER_OPTIONS}
                        component={SelectField}
                      />
                    </Grid.Box>
                    {isMdu && (
                      <Grid.Box>
                        <Field
                          stretch
                          label="Property"
                          name="communityId"
                          placeholder="Filter by"
                          options={COMMUNITY_FILTER_OPTIONS}
                          component={SelectField}
                        />
                      </Grid.Box>
                    )}
                  </Grid.Layout>
                </CustomCard>
              }
            >
              <ButtonLink
                text="Filters"
                icon={<FilterIcon css={{ fill: !!filters.status ? 'currentColor' : 'none' }} />}
              />
            </Dropdown>
          )}
        </Form>
        <CopyPublicLink />
      </Grid.Layout>
    </Grid.Layout>
  );

  return (
    <CustomCard padding="none" header={header} data-e2e-id="rentals.singleUnits">
      <SingleUnitsTable query={throttledSearch} filters={filters} />
    </CustomCard>
  );
};
