import * as yup from 'yup';
import { DateTime } from 'luxon';
import { urlRegexp, urlRegexpCalendly } from '../common';
import { plusOffsetByTime, parseDatetimeFromISO } from '../../shared/utils';

export const OfferSchemaValidation = yup.object().shape(
  {
    secureNow: yup
      .bool()
      .nullable()
      .required('SecureNow™ is required'),

    secureNowAmount: yup
      .number()
      .nullable()
      .when('secureNow', {
        is: true,
        then: yup
          .number()
          .nullable()
          .required('SecureNow™ Rent Amount is required'),
        otherwise: yup.number().notRequired(),
      }),

    rentAmount: yup
      .mixed()
      .nullable()
      .when(['secureNowAmount', 'secureNow'], (secureNowAmount, secureNow, schema) => {
        if (secureNowAmount) {
          return schema.test({
            test: rentAmount => !secureNow || rentAmount < secureNowAmount,
            message: 'Should be lower than SecureNow™ Amount',
          });
        }
      })
      .required('Rent Amount is required'),

    termsOfLease: yup
      .number()
      .nullable()
      .when('minimumLeaseLength', (minimumLeaseLength, schema) => {
        if (minimumLeaseLength) {
          return schema.test({
            test: termsOfLease => termsOfLease >= minimumLeaseLength,
            message: 'Should be equal or higher than Minimum Length of Lease',
          });
        }
      })
      .when('maximumLeaseLength', (maximumLeaseLength, schema) => {
        if (maximumLeaseLength) {
          return schema.test({
            test: termsOfLease => termsOfLease <= maximumLeaseLength,
            message: 'Should be equal or lower than Maximum Length of Lease',
          });
        }
      })
      .required('Lease Terms is required'),

    minimumLeaseLength: yup
      .number()
      .nullable()
      .when('maximumLeaseLength', (maximumLeaseLength, schema) => {
        return schema.test({
          test: minimumLeaseLength => {
            if (maximumLeaseLength && minimumLeaseLength) {
              return minimumLeaseLength <= maximumLeaseLength;
            }
            return true;
          },
          message: 'Should be equal or lower than Maximum Length of Lease',
        });
      }),

    securityDeposite: yup
      .number()
      .nullable()
      .required('Security Deposit is required'),

    moveinDate: yup
      .string()
      .nullable()
      .when(['endsIn', 'timezone'], (endsIn, timezone, schema) => {
        return schema.test({
          test: endsIn => {
            const nowDate = DateTime.local().setZone(timezone);
            const endsInDate = parseDatetimeFromISO(endsIn, timezone);
            const diff = endsInDate.diff(nowDate, ['days', 'hours']).toObject();
            const isLaterByDays = diff.days >= 0;
            const isLaterByHours = diff.hours >= 0;

            return isLaterByDays && isLaterByHours;
          },
          message: 'Should be greater than the current date',
        });
      })
      .required('Move-in Date is required'),

    incrementedBidAmount: yup
      .number()
      .nullable()
      .required('Incremental Bid Amount is required'),

    moveinDateEditable: yup
      .boolean()
      .nullable()
      .required('Move-in Date Editable is required'),

    startsIn: yup
      .string()
      .nullable()
      .when('startImmediate', (startImmediate, schema) => {
        return schema.test({
          test: startsIn => {
            if (startImmediate) {
              return true;
            }
            if (!startImmediate && startsIn) {
              return true;
            }
            return false;
          },
          message: 'Livo Transaction Start Date is required',
        });
      }),
    endsIn: yup
      .string()
      .nullable()
      .when('timezone', (timezone, schema) => {
        return schema.test({
          test: endsIn => {
            const nowDate = DateTime.local().setZone(timezone);
            const endsInDate = parseDatetimeFromISO(endsIn, timezone);
            const diff = endsInDate.diff(nowDate, ['days', 'hours']).toObject();
            const isLaterByDays = diff.days >= 0;
            const isLaterByHours = diff.hours >= 0;

            return isLaterByDays && isLaterByHours;
          },
          message: 'Should be greater than the current date',
        });
      })
      .when(['startsIn', 'timezone'], (startsIn, timezone, schema) => {
        return schema.test({
          test: endsIn => {
            if (startsIn && endsIn) {
              const parsedEndsIn = parseDatetimeFromISO(endsIn, timezone);
              const parsedStartsIn = parseDatetimeFromISO(startsIn, timezone);
              const offsetEndsIn = plusOffsetByTime(parsedEndsIn, parsedStartsIn);

              const diffDates = offsetEndsIn.diff(parsedStartsIn, ['days', 'hours']).toObject().days;
              return Math.floor(diffDates) >= 0;
            }
            return true;
          },
          message: 'Should be higher than Livo Transaction Start Date',
        });
      })
      .required('Livo Transaction End Date is required'),

    reenableOffer: yup
      .bool()
      .nullable()
      .required('Re-enable Transaction is required'),

    timezone: yup
      .string()
      .nullable()
      .required('Timezone is required'),

    livoManagesScreening: yup
      .boolean()
      .nullable()
      .required('Filed is required'),

    applicationLink: yup
      .string()
      .nullable()
      .required('Application Link is required')
      .matches(urlRegexp, 'Not a valid URL'),

    contact: yup
      .string()
      .nullable()
      .required('Contact is required'),

    calendarLink: yup
      .string()
      .nullable()
      .test('check calendly link', 'Not a valid Calendly URL', value => {
        if (Boolean(value)) {
          return urlRegexpCalendly.test(value);
        }

        return true;
      }),

    timeframeToSignContract: yup
      .number()
      .nullable()
      .required('Timeframe to Sign Contract is required'),
  },
  [],
);
