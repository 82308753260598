import React from 'react';
import { Modal } from 'antd';
import { useQuery } from 'react-apollo';

import { AsyncContent, Scrollable } from '@/components';
import { RenterInformation } from '@/pro';
import { getFullName } from '@/shared/utils';
import { Renter } from '@/shared/types/graphql';

import { RENTER_DETAILS_MODAL_RENTER_QUERY } from './queries';

type DialogProps = {
  renterId?: string;
  close: () => void;
  isVisible: boolean;
};

export const Dialog: React.FC<DialogProps> = ({ isVisible, close, renterId }) => {
  const { data: renterData, loading: renterLoading } = useQuery<{ renter: Renter }>(RENTER_DETAILS_MODAL_RENTER_QUERY, {
    skip: !renterId,
    variables: {
      id: renterId,
    },
  });

  const firstName = renterData?.renter?.user?.firstName;
  const lastName = renterData?.renter?.user?.lastName;
  const fullName = getFullName(firstName, lastName);

  return (
    <Modal
      title={fullName}
      className="livo-modal"
      visible={isVisible}
      onCancel={close}
      width="100%"
      style={{ maxWidth: 635 }}
      centered
      footer={null}
    >
      <AsyncContent loading={renterLoading} height="367px">
        <Scrollable padding="24px 54px 47px 54px">
          <RenterInformation renter={renterData?.renter} />
        </Scrollable>
      </AsyncContent>
    </Modal>
  );
};
