import React from 'react';
import { Modal } from 'antd';
import { formatVirtualTourSrc } from 'livo-shared/src';

interface DialogProps {
  close: () => void;
  isVisible: boolean;
  virtualTour?: string;
}

export const Dialog: React.FC<DialogProps> = ({ close, isVisible, virtualTour = '' }) => {
  const virtualTourSrc = formatVirtualTourSrc(virtualTour);

  const videoHeight = 350;

  if (!isVisible) {
    return null;
  }

  return (
    <Modal
      className="virtual-tour-modal"
      visible={isVisible}
      onCancel={close}
      footer={null}
      maskClosable={false}
      style={{ height: videoHeight }}
      width={600}
      centered
    >
      <iframe width="100%" height={videoHeight} src={virtualTourSrc} frameBorder="0" title="Virtual Tour" />
    </Modal>
  );
};
