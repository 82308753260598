import React, { ChangeEvent } from 'react';
import { useQuery } from 'react-apollo';
import { DefaultAmenity, DefaultAmenityListResponse } from '@/shared/types/graphql';
import { dialogsContext } from '@/providers';
import { Dropdown, Icon, Menu } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { NEW_AMENITY_GROUPS_LABELS } from '@/shared/constants';
import { AsyncContent, CustomCard, Table, TableHeader } from '@/components';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { useMutationSafe, useSearch } from '@/shared/hooks';
import { toast } from 'react-toastify';
import { DEFAULT_AMENITY_CREATE_DIALOG, DEFAULT_AMENITY_UPDATE_DIALOG, DELETE_CONFIRM_DIALOG } from '@/dialogs';

const DEFAULT_AMENITIES_QUERY = gql`
  query DefaultAmenitiesList($amenitiesFilter: DefaultAmenityFilter) {
    defaultAmenitiesList(filter: $amenitiesFilter) {
      items {
        id
        label
        category {
          id
          key
        }
      }
    }
  }
`;

const DEFAULT_AMENITY_DELETE_MUTATION = gql`
  mutation DefaultAmenityDelete($id: ID!) {
    defaultAmenityDelete(filter: { id: $id }, force: true) {
      success
    }
  }
`;

type UseDefaultAmenitiesQueryFilterParams = {
  query: string;
};

const useDefaultAmenitiesQueryFilter = ({ query }: UseDefaultAmenitiesQueryFilterParams) => {
  const { amenityKey } = useParams();

  const all: any = [
    {
      category: {
        key: {
          equals: amenityKey,
        },
      },
    },
    query && {
      label: {
        contains: query,
      },
    },
  ].filter(Boolean);

  return {
    AND: all,
  };
};

export const AmenitiesTable = () => {
  const { amenityKey } = useParams();
  const [{ search, throttledSearch }, setSearch] = useSearch({ sortBy: 'name' });
  const { openDialog } = React.useContext(dialogsContext);

  const onChange = React.useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearch(event.currentTarget.value);
    },
    [setSearch],
  );
  const defaultAmenitiesQueryFilter = useDefaultAmenitiesQueryFilter({ query: throttledSearch });

  const { data, loading } = useQuery<{ defaultAmenitiesList: DefaultAmenityListResponse }>(DEFAULT_AMENITIES_QUERY, {
    variables: {
      amenitiesFilter: defaultAmenitiesQueryFilter,
    },
    fetchPolicy: 'cache-and-network',
  });

  const [deleteDefaultAmenity, { loading: deleteDefaultAmenityLoading }] = useMutationSafe(
    DEFAULT_AMENITY_DELETE_MUTATION,
    {
      refetchQueries: ['DefaultAmenitiesList'],
      onCompleted: () => toast.success('Successfully Deleted'),
    },
  );

  const dataSource = data?.defaultAmenitiesList?.items;

  const AmenitiesTableActions: React.FC<{ record: any }> = ({ record }) => {
    const onEdit = React.useCallback(
      () => openDialog({ name: DEFAULT_AMENITY_UPDATE_DIALOG, props: { amenityId: record?.id } }),

      [record],
    );

    const onDelete = React.useCallback(
      () =>
        openDialog({
          name: DELETE_CONFIRM_DIALOG,
          props: {
            entityName: 'Amenity',
            confirmAction: async () =>
              await deleteDefaultAmenity({
                variables: {
                  id: record?.id,
                },
              }),
          },
        }),
      [record],
    );

    const menu = (
      <Menu>
        <Menu.Item onClick={onEdit}>Edit</Menu.Item>
        <Menu.Item onClick={onDelete}>Delete</Menu.Item>
      </Menu>
    );

    return (
      <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
        <Icon type="ellipsis" />
      </Dropdown>
    );
  };

  const columns: ColumnProps<DefaultAmenity>[] = React.useMemo(
    () => [
      {
        title: 'Label',
        dataIndex: 'label',
      },
      {
        title: '',
        dataIndex: '',
        width: '43px',
        render: function UserActions(value: string, record: any) {
          return <AmenitiesTableActions record={record} />;
        },
      },
    ],
    [],
  );

  const addNewAmenity = () =>
    openDialog({ name: DEFAULT_AMENITY_CREATE_DIALOG, props: { currentAmenityGroup: amenityKey } });

  return (
    <CustomCard
      padding="none"
      header={
        <TableHeader
          actionProps={{ text: 'Add New Amenity', icon: { type: 'plus-circle' }, onClick: addNewAmenity }}
          searchProps={{ query: search, placeholder: 'Search by Label', onChange }}
          title={NEW_AMENITY_GROUPS_LABELS[amenityKey]}
        />
      }
    >
      <AsyncContent loading={deleteDefaultAmenityLoading}>
        <Table rowKey="id" dataSource={dataSource} columns={columns} loading={loading} />
      </AsyncContent>
    </CustomCard>
  );
};
