import React from 'react';
import { css } from '@emotion/core';
import DOMPurify from 'dompurify';

import { DOMPURIFY_CONFIG } from 'livo-shared';
import { PhoneLink, ResumeNumbers, WebsiteLink, FlexLayout } from '@/components';
import { SingleUnit, Maybe } from '@/shared/types/graphql';
import { stringifyAddress, stringifyNA, stringifySingleUnitType } from '@/shared/utils';
import { NOT_AVALIABLE, COMPANY_TYPES } from '@/shared/constants';
import { useCompanyTypeCheck, useDictionary } from '@/shared/hooks';
import { PropertyDescription } from '@/components';

import { Resume, UppercaseLabel, SectionHeading, SectionInfo, SectionInfoHalf, PropertyLogo } from './styled';
import { EntityOption } from '@/pro';

type PropertyDetailsProps = {
  singleUnit?: Maybe<SingleUnit>;
};

export const PropertyDetails: React.FC<PropertyDetailsProps> = ({ singleUnit }) => {
  const type = singleUnit?.type ? stringifySingleUnitType(singleUnit.type) : NOT_AVALIABLE;
  const beds = singleUnit?.bedrooms;
  const baths = singleUnit?.bathrooms;
  const squareFt = singleUnit?.squareFt;
  const communityLogo = singleUnit?.community?.logo;
  const companyLogo = singleUnit?.company?.companyLogo;
  const description = singleUnit?.description;

  const isMdu = useCompanyTypeCheck(COMPANY_TYPES.mdu);
  const { singleUnit: singleUnitName } = useDictionary();

  const sanitizedDescriptionHtml = React.useMemo(() => DOMPurify.sanitize(description as string, DOMPURIFY_CONFIG), [
    description,
  ]);

  return (
    <React.Fragment>
      <SectionHeading>{`${singleUnitName.single} Details`}</SectionHeading>
      <Resume>
        <FlexLayout align="center" gap="lg">
          <UppercaseLabel>{type}</UppercaseLabel>
          <ResumeNumbers beds={beds} baths={baths} squareFt={squareFt} />
        </FlexLayout>
        {communityLogo?.downloadUrl ? (
          <FlexLayout align="center">
            <UppercaseLabel>listed by</UppercaseLabel>
            <PropertyLogo src={communityLogo?.downloadUrl} />
          </FlexLayout>
        ) : companyLogo?.downloadUrl ? (
          <FlexLayout align="center">
            <UppercaseLabel>listed by</UppercaseLabel>
            <PropertyLogo src={companyLogo?.downloadUrl} />
          </FlexLayout>
        ) : null}
      </Resume>
      <PropertyDescription
        css={css`
          margin-bottom: 25px !important;
          word-wrap: break-word;
        `}
      >
        <div dangerouslySetInnerHTML={{ __html: stringifyNA(sanitizedDescriptionHtml) }} />
      </PropertyDescription>
      <SectionInfo>
        <SectionInfoHalf>
          <EntityOption type="uppercase-label" label="Address" value={stringifyAddress(singleUnit?.address)} />
          {isMdu && (
            <EntityOption type="uppercase-label" label="Property" value={stringifyNA(singleUnit?.community?.name)} />
          )}
        </SectionInfoHalf>
        <SectionInfoHalf>
          <EntityOption
            type="uppercase-label"
            label="Phone Number"
            value={<PhoneLink phone={singleUnit?.community?.phoneNumber || singleUnit?.phone} />}
          />
          <EntityOption
            type="uppercase-label"
            label="Website"
            value={<WebsiteLink website={singleUnit?.community?.website || singleUnit?.website} />}
          />
        </SectionInfoHalf>
      </SectionInfo>
    </React.Fragment>
  );
};
