import React from 'react';
import { Modal } from 'antd';

import { AgentInformation } from '@/pro';
import { Scrollable } from '@/components';

type DialogProps = {
  close: () => void;
  isVisible: boolean;
  bidId?: string;
};

export const Dialog: React.FC<DialogProps> = ({ isVisible, bidId, close }) => {
  return (
    <Modal
      title="Contact Agent"
      className="livo-modal"
      visible={isVisible}
      onCancel={close}
      width="100%"
      style={{ maxWidth: 600 }}
      centered
      footer={null}
    >
      <Scrollable padding="30px 60px">
        <AgentInformation bidId={bidId} />
      </Scrollable>
    </Modal>
  );
};
