import React from 'react';
import { useDialogState } from '@/shared/hooks';
import { Dialog } from './Dialog';

export const BID_DELETE_DIALOG = 'BID_DELETE_DIALOG';

export const BidDeleteDialog = () => {
  const { close, isVisible, props } = useDialogState(BID_DELETE_DIALOG);

  if (!isVisible) {
    return null;
  }

  return <Dialog {...props} close={close} isVisible={isVisible} />;
};
