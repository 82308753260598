import React from 'react';
import { Modal } from 'antd';
import { useQuery } from 'react-apollo';
import { toast } from 'react-toastify';

import { ModalFooter, AsyncContent, Grid, Scrollable, PhoneLink, EmailLink } from '@/components';
import { InformationSection, EntityOption } from '@/pro';
import { Bid } from '@/shared/types/graphql';
import {
  getFullName,
  stringifyNA,
  stringifyRentAmount,
  stringifyDate,
  stringifyTermsOfLease,
  stringifyAddress,
} from '@/shared/utils';
import { BID_STATUSES } from '@/shared/constants';

import { BIDDER_AWARD_MODAL_BID_QUERY, BIDDER_AWARD_MODAL_BID_MUTATION } from './queries';
import { useMutationSafe } from '@/shared/hooks';

type DialogProps = {
  refetchQueries?: Array<string>;
  close: () => void;
  isVisible: boolean;
  bidderId?: string;
};

export const Dialog: React.FC<DialogProps> = ({ isVisible, close, bidderId, refetchQueries }) => {
  const { data: bidResponse, loading: bidLoading } = useQuery<{ bid: Bid }>(BIDDER_AWARD_MODAL_BID_QUERY, {
    skip: !bidderId,
    variables: {
      id: bidderId,
    },
  });

  const bid = bidResponse?.bid;

  const renter = bid?.renter;

  const user = renter?.user;
  const firstName = user?.firstName;
  const lastName = user?.lastName;
  const fullName = getFullName(firstName, lastName);

  const offer = bid?.offer;
  const singleUnit = offer?.historyOfferSingleUnit;

  const [bidUpdate, { loading: bidUpdateLoading }] = useMutationSafe(BIDDER_AWARD_MODAL_BID_MUTATION, {
    refetchQueries: refetchQueries,
    onCompleted: () => {
      toast.success('Successfully awarded');
      close();
    },
  });

  const handleBidAward = () => {
    bidUpdate({
      variables: {
        filter: {
          id: bidderId,
        },
        data: {
          status: BID_STATUSES.winner,
        },
      },
    });
  };

  return (
    <Modal
      title="Award Lease - Confirmation"
      closable={!bidUpdateLoading}
      maskClosable={!bidUpdateLoading}
      className="livo-modal"
      visible={isVisible}
      onCancel={close}
      width="100%"
      style={{ maxWidth: 635 }}
      centered
      footer={
        <ModalFooter
          cancelProps={{
            onClick: close,
            disabled: bidUpdateLoading,
          }}
          okProps={{
            type: 'primary',
            text: 'Award Lease',
            disabled: bidUpdateLoading,
            loading: bidUpdateLoading,
            onClick: handleBidAward,
          }}
        />
      }
    >
      <Scrollable padding="27px 24px 32px 24px">
        <AsyncContent loading={bidLoading} height="382px">
          <Grid.Layout gap="57px">
            <InformationSection header="Offer Details">
              <Grid.Layout columns="1fr 200px">
                <Grid.Layout gap="20px">
                  <EntityOption label="Property name" value={stringifyNA(singleUnit?.name)} type="uppercase-label" />
                  <EntityOption
                    label="Rent amount"
                    value={stringifyRentAmount(bid?.rentAmount)}
                    type="uppercase-label"
                  />
                </Grid.Layout>
                <Grid.Layout>
                  <EntityOption label="Move in date" value={stringifyDate(bid?.moveinDate)} type="uppercase-label" />
                  <EntityOption label="Lease" value={stringifyTermsOfLease(bid?.termsOfLease)} type="uppercase-label" />
                </Grid.Layout>
              </Grid.Layout>
            </InformationSection>
            <InformationSection header="Renter Information">
              <Grid.Layout columns="1fr 200px" alignItems="start">
                <Grid.Layout gap="20px">
                  <EntityOption label="Name" value={fullName} type="uppercase-label" />
                  <EntityOption label="Email" value={<EmailLink email={user?.email} />} type="uppercase-label" />
                  <EntityOption label="Phone" value={<PhoneLink phone={renter?.phone} />} type="uppercase-label" />
                </Grid.Layout>
                <Grid.Layout>
                  <EntityOption label="Address" value={stringifyAddress(renter?.address)} type="uppercase-label" />
                </Grid.Layout>
              </Grid.Layout>
            </InformationSection>
          </Grid.Layout>
        </AsyncContent>
      </Scrollable>
    </Modal>
  );
};
