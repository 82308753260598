import React from 'react';
import { Modal } from 'antd';
import { Form, Field } from '8base-react-sdk';
import { useQuery } from 'react-apollo';

import { addAmenities, normalizePhone, validateEntityDataByDraft } from '@/shared/utils';
import {
  Grid,
  InputField,
  InputNumberField,
  SelectField,
  ModalFooter,
  Scrollable,
  AsyncContent,
  AddressFields,
  HasMediaInputField,
  AmenitiesSelectField,
  DescriptionEditorField,
} from '@/components';
import {
  US_STATES_LIST,
  SINGLE_UNIT_TYPE_LABELS,
  SINGLE_UNIT_MDU_TYPE_LABELS,
  TOAST_SUCCESS_MESSAGE,
  DRAFT_STATUS,
  SINGLE_UNIT_STATUSES,
  COMPANY_TYPES,
  SHOWING_TYPE_LABELS,
} from '@/shared/constants';
import { Community, SingleUnit, SingleUnitUpdateInput } from '@/shared/types/graphql';
import { UPDATE_SINGLE_UNIT_MODAL_QUERY, UPDATE_SINGLE_UNIT_MUTATION, UPDATE_SINGLE_COMMUNITY_QUERY } from './queries';
import { SingleUnitSchemaValidation } from '@/schemaValidators';
import { omit } from 'ramda';
import { useCompanyTypeCheck, useMutationSafe, useDialogState } from '@/shared/hooks';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const merge = require('deepmerge');

export const SINGLE_UNIT_UPDATE_DIALOG = 'SINGLE_UNIT_UPDATE_DIALOG';

export const SingleUnitUpdateDialog = () => {
  const { close, closeWithConfirm, isVisible, props } = useDialogState(SINGLE_UNIT_UPDATE_DIALOG);

  const isMdu = useCompanyTypeCheck(COMPANY_TYPES.mdu);
  const refetchQueries = props?.refetchQueries;

  const coercedSingleUnitTypes = React.useMemo(
    () =>
      isMdu
        ? Object.entries(SINGLE_UNIT_MDU_TYPE_LABELS).map(([value, label]) => ({ value, label }))
        : Object.entries(SINGLE_UNIT_TYPE_LABELS).map(([value, label]) => ({ value, label })),
    [isMdu],
  );

  const coercedStatesOfUSA = React.useMemo(
    () => Object.entries(US_STATES_LIST).map(([value, label]) => ({ value, label: `${value} - ${label}` })),
    [],
  );

  const coercedShowingTypes = React.useMemo(
    () => Object.entries(SHOWING_TYPE_LABELS).map(([value, label]) => ({ value, label })),
    [],
  );

  const { data: singleUnitData, loading: singleUnitLoading } = useQuery<{ singleUnit: SingleUnit }>(
    UPDATE_SINGLE_UNIT_MODAL_QUERY,
    {
      variables: {
        id: props.singleUnitId,
      },
      skip: !props.singleUnitId,
    },
  );

  const amenitiesValues: any = singleUnitData?.singleUnit?.singleUnitAmenity || [];

  const getGroupedAmenities = () => {
    return (
      Object.entries(amenitiesValues)?.map(([groupKey, amenity]) => {
        return {
          groupKey,
          amenity,
        };
      }) || []
    );
  };

  const allAmenities: any = getGroupedAmenities() || [];
  const filteredAmenities = allAmenities.filter((el: any) => el?.amenity && Array.isArray(el?.amenity));

  let INITIAL_VALUES = omit(['numberId'], singleUnitData?.singleUnit || {});
  if (!INITIAL_VALUES?.phone?.code) {
    INITIAL_VALUES = merge(INITIAL_VALUES, {
      phone: {
        code: '1',
      },
    });
  }

  const { data, loading } = useQuery<{ community: Community }>(UPDATE_SINGLE_COMMUNITY_QUERY, {
    variables: {
      id: props.communityId,
    },
    skip: !props.communityId,
  });

  const [updateSingleUnit] = useMutationSafe(UPDATE_SINGLE_UNIT_MUTATION, {
    context: {
      [TOAST_SUCCESS_MESSAGE]: 'Successfully Updated',
    },
    onCompleted: close,
    refetchQueries: refetchQueries ? [...refetchQueries, 'UpdateSingleUnitModal'] : ['UpdateSingleUnitModal'],
    awaitRefetchQueries: true,
  });

  const onSubmit = React.useCallback(
    async (data: SingleUnitUpdateInput) => {
      await updateSingleUnit({
        variables: {
          data: {
            ...data,
            ...(props.communityId && {
              community: {
                reconnect: {
                  id: props.communityId,
                },
              },
            }),
          },
        },
      });
    },
    [updateSingleUnit, props.communityId],
  );

  const title = !!props.communityId ? `Edit in ${data?.community?.name}` : 'Edit';

  const isDraft = singleUnitData?.singleUnit?.status === DRAFT_STATUS;

  const afterFormatQueryData = (values: any) => {
    const formattedValues: any = values;
    formattedValues.amenities = filteredAmenities ? filteredAmenities : [];
    return formattedValues;
  };

  const onFormat = (data: any) => {
    const resultValues = addAmenities(data, 'singleUnit');
    return { ...resultValues };
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Form
      type="UPDATE"
      tableSchemaName="SingleUnit"
      initialValues={INITIAL_VALUES}
      onSubmit={onSubmit as any}
      afterFormatQueryData={afterFormatQueryData}
      beforeFormatDataForMutation={onFormat}
      validate={values => validateEntityDataByDraft(SingleUnitSchemaValidation, values)}
    >
      {({ handleSubmit, submitting, pristine, form, values }) => (
        <Modal
          visible={isVisible}
          closable={props.closable}
          onCancel={pristine ? close : closeWithConfirm}
          afterClose={form.reset}
          width="100%"
          style={{ maxWidth: 620 }}
          maskClosable={false}
          title={title}
          className="livo-modal"
          footer={
            <ModalFooter
              showLeftAction={isDraft && !loading}
              leftActionProps={{
                text: 'Save as Draft',
                disabled: (pristine && !isDraft) || submitting,
                onClick: event => {
                  form.change('status', SINGLE_UNIT_STATUSES.draft);

                  return handleSubmit(event);
                },
              }}
              cancelProps={{
                onClick: pristine ? close : closeWithConfirm,
                disabled: submitting,
              }}
              okProps={{
                type: 'primary',
                text: isDraft ? 'Add' : 'Save Changes',
                loading: submitting,
                disabled: pristine && !isDraft,
                onClick: event => {
                  form.change('status', SINGLE_UNIT_STATUSES.active);

                  return handleSubmit(event);
                },
              }}
            />
          }
          centered
        >
          <AsyncContent loading={singleUnitLoading} height="500px">
            <Scrollable height="75vh">
              <Grid.Layout gap="16px">
                <Grid.Box css={{ color: '#f37a22', fontWeight: 600 }}>#{singleUnitData?.singleUnit?.numberId}</Grid.Box>
                <Grid.Box>
                  <Field name="name" label="Name*" autoComplete="disabled" component={InputField} />
                </Grid.Box>
                <Grid.Box>
                  <Field name="type" label="Type*" component={SelectField} options={coercedSingleUnitTypes} stretch />
                </Grid.Box>
                <Grid.Box>
                  <Field name="description" label="Description*" component={DescriptionEditorField} rows={5} />
                </Grid.Box>
                <Grid.Box>
                  <Field
                    name="amenities"
                    label="Amenities"
                    companyType={props?.companyType}
                    unitType="singleUnit"
                    component={AmenitiesSelectField}
                    stretch
                  />
                </Grid.Box>
                <Grid.Box>
                  <Grid.Layout gap="16px" columns="1fr 1fr 1fr" inline>
                    <Grid.Box>
                      <Field
                        name="bedrooms"
                        component={InputNumberField}
                        prefix="Beds:"
                        prefixPadding={55}
                        min={0}
                        stretch
                      />
                    </Grid.Box>
                    <Grid.Box>
                      <Field
                        name="bathrooms"
                        component={InputNumberField}
                        prefix="Baths:"
                        prefixPadding={58}
                        min={0}
                        step={0.5}
                        stretch
                      />
                    </Grid.Box>
                    <Grid.Box>
                      <Field
                        name="squareFt"
                        component={InputNumberField}
                        prefix="Square ft:"
                        prefixPadding={83}
                        min={1}
                        stretch
                      />
                    </Grid.Box>
                  </Grid.Layout>
                </Grid.Box>
                <Grid.Box>
                  <Field
                    name="phone.number"
                    label="Phone number*"
                    autoComplete="disabled"
                    component={InputField}
                    mask="(999) 999-9999"
                    addonBefore={`+${values.phone?.code || 1}`}
                    parse={normalizePhone}
                  />
                </Grid.Box>
                <Grid.Box>
                  <AddressFields
                    street1="address.street1"
                    street2="address.street2"
                    city="address.city"
                    state="address.state"
                    zip="address.zip"
                    country="address.country"
                    stateOptions={coercedStatesOfUSA}
                    addressNote="At this time Livo.io only supports U.S. based companies."
                  />
                </Grid.Box>
                {!props.communityId && (
                  <Grid.Box>
                    <Field
                      label="Website"
                      name="website"
                      autoComplete="disabled"
                      placeholder="e.g. https://www.livo.io, http://livo.com, etc."
                      component={InputField}
                    />
                  </Grid.Box>
                )}
                <Grid.Box>
                  <Field
                    label="Virtual Tour Link (YouTube)"
                    note="Please add the link for your virtual tour"
                    name="virtualTour"
                    placeholder="e.g. https://www.youtube.com/watch?v=jNQXAC9IVRa"
                    autoComplete="disabled"
                    component={InputField}
                    stretch
                  />
                </Grid.Box>
                <Grid.Box>
                  <Field
                    name="showingType"
                    label="Select Showing Type(s)*"
                    component={SelectField}
                    options={coercedShowingTypes}
                    mode="multiple"
                    stretch
                    data-e2e-id="modal.singleUnit.create.type"
                  />
                </Grid.Box>
                <Grid.Box>
                  <Field
                    label="Media (Floor plans / site plans / pictures / drawings / videos)"
                    name="singleUnitHasMedia"
                    buttonText="Select multiple files* (PNG, JPEG, JPG)"
                    accept={['.png', '.jpg', '.jpeg']}
                    maxFiles={100}
                    component={HasMediaInputField}
                    previewWidth="1/3"
                    isPublic
                  />
                </Grid.Box>
              </Grid.Layout>
            </Scrollable>
          </AsyncContent>
        </Modal>
      )}
    </Form>
  );
};
