import React from 'react';
import { ReactComponent as LivoSecureLogo } from '@/static/LivoSecureColor.svg';
import { Offer } from '@/shared/types/graphql';
import { OfferCard } from '@/routes/rentals/PageCompanyLiveOffers/components/OfferCard';
import { PropertyCardsTag, OffersCardsEmptyTag, OffersCardsEmptyLogo, OffersCardsEmptyTitle } from './styled';

type OfferCardGrid = {
  offers: Offer[];
};

export const OfferCardGrid: React.FC<OfferCardGrid> = ({ offers }) => {
  return (
    <PropertyCardsTag>
      <Choose>
        <When condition={!offers.length}>
          <OffersCardsEmptyTag>
            <LivoSecureLogo css={OffersCardsEmptyLogo} />
            <OffersCardsEmptyTitle>
              Sorry! We have no live transactions to show you at this moment
            </OffersCardsEmptyTitle>
          </OffersCardsEmptyTag>
        </When>
        <Otherwise>
          {offers.map(offer => (
            <OfferCard key={offer.id!} offer={offer} />
          ))}
        </Otherwise>
      </Choose>
    </PropertyCardsTag>
  );
};
