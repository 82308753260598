import { UserAuthStates } from '@/shared/constants';

interface CheckedUserStates {
  isGuest: boolean;
  isBlocked: boolean;
  isAuthorizedNonVerified: boolean;
  isAuthorized: boolean;
}

export const getCheckedAuthState = (currentUserAuthState: UserAuthStates): CheckedUserStates => {
  const isGuest = currentUserAuthState === UserAuthStates.Guest;
  const isBlocked = currentUserAuthState === UserAuthStates.Blocked;
  const isAuthorizedNonVerified = currentUserAuthState === UserAuthStates.AuthorizedNonVerified;
  const isAuthorized = currentUserAuthState === UserAuthStates.Authorized;

  return { isGuest, isBlocked, isAuthorizedNonVerified, isAuthorized };
};
