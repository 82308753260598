import gql from 'graphql-tag';

export const COMMUNITIES_LIST_QUERY = gql`
  query CommunitiesListQuery($filter: CommunityFilter) {
    communitiesList(filter: $filter) {
      items {
        id
        name
      }
    }
  }
`;

export const OFFERS_LIST_QUERY = gql`
  query RentalsSingleUnitsOffersList($first: Int, $skip: Int, $filter: OfferFilter, $sort: [OfferSort!]) {
    offersList(first: $first, skip: $skip, filter: $filter, sort: $sort) {
      items {
        id
        offerId
        numberId
        status
        timezone
        activeOfferSingleUnit {
          id
          activeOffer {
            id
            status
          }
        }
        historyOfferSingleUnit {
          id
          name
          bedrooms
          bathrooms
          squareFt
          address {
            street1
            street2
            state
            country
            zip
            city
          }
          phone {
            code
            number
          }
        }
        secureNow
        secureNowAmount
        incrementedBidAmount
        livoManagesScreening
        screeningLink
        status
        moveinDate
        rentAmount
        securityDeposite
        startsIn
        endsIn
        termsOfLease
        bid(first: 1, sort: [{ rentAmount: DESC }], filter: { status: { not_equals: "rejected" } }) {
          items {
            id
            rentAmount
          }
          count
        }
        updatedAt
      }
      count
    }
  }
`;
