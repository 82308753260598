import React from 'react';
import { useQuery } from 'react-apollo';
import { css } from '@emotion/core';
import { Button } from 'antd';

import { Loader, CustomCard } from '@/components';
import { dialogsContext } from '@/providers';
import { CURRENT_USER_COMPANY_QUERY } from '@/shared/graphql';
import { User } from '@/shared/types/graphql';
import { usePermissions } from '@/shared/hooks';
import Text from 'antd/es/typography/Text';
import { CompanyProfileInfo } from '@/pro/CompanyProfileInfo';
import { COMPANY_UPDATE_DIALOG } from '@/dialogs';

export const CompanyProfile = () => {
  const { openDialog } = React.useContext(dialogsContext);

  const { loading, data } = useQuery<{ user: User }>(CURRENT_USER_COMPANY_QUERY);

  const { isAdmin, isCompanyAdmin } = usePermissions();

  if (loading) {
    return <Loader />;
  }

  const company = data?.user?.company;
  const name = company?.name ?? '';
  const id = company?.clientId ?? '';

  return (
    <CustomCard
      header={
        <>
          <h1
            css={css`
              font-size: 1.8rem;
              font-weight: 400;
              letter-spacing: 0.5px;
              color: #323c47;
            `}
          >
            {name}
            <Text type="secondary"> ({id})</Text>
          </h1>
        </>
      }
      footer={
        (isAdmin || isCompanyAdmin) && (
          <div
            css={css`
              display: flex;
              justify-content: flex-end;
              width: 100%;
            `}
          >
            <Button
              type="primary"
              size="large"
              onClick={() => {
                openDialog({
                  name: COMPANY_UPDATE_DIALOG,
                  props: { closable: true },
                });
              }}
            >
              Edit Client Information
            </Button>
          </div>
        )
      }
      css={css`
        .ant-avatar {
          border: 2px solid #f37a22;
        }
      `}
    >
      <CompanyProfileInfo company={company} />
    </CustomCard>
  );
};
