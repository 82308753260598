import { DateTime } from 'luxon';
import pluralize from 'pluralize';

export const formatDiffDate = (endDate: DateTime, abs = false) => {
  // if diff is bigger than a day - return N days and N hours
  // otherwise reurn N hours and N minutes
  let diff = endDate.diffNow(['days', 'hours', 'minutes']);

  const { days: d, hours: h, minutes: m } = diff.toObject();

  if (abs && ((d && d < 0) || (h && h < 0) || (m && m < 0))) {
    diff = diff.negate();
  }

  const days = pluralize('day', Math.floor(d as number));
  const hours = pluralize('hour', Math.floor(h as number));
  const minutes = pluralize('minute', Math.floor(m as number));

  let formattedDiff = `${diff.toFormat(`m '${minutes}'`)}`;

  if ((d && Math.abs(d) > 0) || (m && Math.abs(m) > 24 * 60) || (h && Math.abs(h) > 24)) {
    formattedDiff = `${diff.toFormat(`d '${days}', h '${hours}'`)}`;
  } else if ((h && Math.abs(h) > 0) || (m && Math.abs(m) > 60)) {
    formattedDiff = `${diff.toFormat(`h '${hours}', m '${minutes}'`)}`;
  }

  return formattedDiff;
};
