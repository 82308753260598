import React from 'react';
import { useDialogState } from '@/shared/hooks';
import { Dialog } from './Dialog';

export const COMMUNITY_UPDATE_DIALOG = 'COMMUNITY_UPDATE_DIALOG';

export const CommunityUpdateDialog = () => {
  const { close, closeWithConfirm, isVisible, props } = useDialogState(COMMUNITY_UPDATE_DIALOG);

  if (!isVisible) {
    return null;
  }

  return <Dialog {...props} close={close} isVisible={isVisible} closeWithConfirm={closeWithConfirm} />;
};
