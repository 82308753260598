import { useLazyQuery } from 'react-apollo';
import * as yup from 'yup';
import { assocPath } from 'ramda';

import { CHECK_TEAM_MEMBER_EMAIL_QUERY } from '@/shared/graphql';
import { UserListResponse } from '@/shared/types/graphql';

const validateEmail = async (email?: string) => {
  const shema = yup.object({
    email: yup
      .string()
      .email('Invalid Email value')
      .nullable(),
  });

  const errors = await shema
    .validate(
      {
        email,
      },
      {
        abortEarly: false,
      },
    )
    .then(() => [])
    .catch(({ inner }) => {
      return inner.reduce((accum: any, error: any) => {
        return assocPath(error.path.split('.'), error.errors[0], accum);
      }, {});
    });

  return errors?.email;
};

const ERROR_TEXT = 'Team Member with such Email already connected to other company';

export const useCheckTeamMemberEmail = () => {
  const [checkEmailQuery, { loading, data }] = useLazyQuery<{
    usersList: UserListResponse;
  }>(CHECK_TEAM_MEMBER_EMAIL_QUERY);

  const checkEmail = async (email?: string) => {
    if (email) {
      const error = await validateEmail(email);
      if (error) {
        return error;
      }

      await checkEmailQuery({
        variables: {
          email,
        },
      });

      const isEmailExist = !!data?.usersList?.items[0]?.company;

      if (isEmailExist) {
        return ERROR_TEXT;
      }
    }
  };

  return { checkEmail, checkTeamMemberEmailLoading: loading, checkTeamMemberEmailData: data };
};
