import gql from 'graphql-tag';

export const WR_STATUS_CHECK = gql`
  mutation WesternReportingStatusCheck($data: WesternReportingStatusCheckInput!) {
    westernReportingStatusCheck(data: $data) {
      result
    }
  }
`;

export const WR_STATUS_UPDATE = gql`
  mutation WesternReportingScreeningsMutation(
    $data: WesternReportingScreeningUpdateInput!
    $idFilter: WesternReportingScreeningKeyFilter
  ) {
    westernReportingScreeningUpdate(data: $data, filter: $idFilter) {
      status
      reportURL
      renter {
        id
      }
    }
  }
`;
