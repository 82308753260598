import React, { useCallback } from 'react';
import { useQuery } from 'react-apollo';
import { SELECTOR_COMPANIES_QUERY } from './quieries';
import { CompanyListResponse } from '@/shared/types/graphql';
import { Select } from 'antd';
import { useCurrentCompanyId } from '@/shared/hooks';
import styled from '@/theme/styled';
import { ClassNames } from '@emotion/core';
import { RowFlex } from '@/components';
// eslint-disable-next-line @typescript-eslint/ban-types
type CompanySelectorProps = {};

const CurrentTag = styled.div`
  display: flex;
  align-items: center;
  border-radius: 2px;
  background-color: #eff5f9;
  padding: 4px 8px;
  color: #939ea7;
  font-size: 12px;
  line-height: 1;
`;

export const CompanySelector: React.FC<CompanySelectorProps> = ({ children }) => {
  const { data: companiesData, loading } = useQuery<{ companiesList: CompanyListResponse }>(SELECTOR_COMPANIES_QUERY);
  const { companyId, changeCompany } = useCurrentCompanyId();
  const companies = companiesData?.companiesList?.items ?? [];

  const selectCompany = useCallback(
    value => {
      changeCompany(value);
    },
    [changeCompany],
  );

  return (
    <ClassNames>
      {({ css }) => (
        <Select
          showSearch
          onChange={selectCompany}
          value={loading ? null : companyId}
          loading={loading}
          placeholder="Company"
          optionLabelProp="label"
          filterOption={(inputValue, option) => {
            return (option.props.label as string).toLowerCase().includes(inputValue.toLowerCase());
          }}
        >
          {companies.map(
            company =>
              company.id && (
                <Select.Option
                  className={css`
                    &.ant-select-dropdown-menu-item-selected {
                      background-color: #f37a22 !important;
                      color: white !important;
                    }
                  `}
                  key={company.id}
                  value={company.id}
                  label={company.name}
                >
                  <RowFlex align="center">
                    <span>{company.name}</span>
                    <If condition={company.id === companyId}>
                      <CurrentTag>Current</CurrentTag>
                    </If>
                  </RowFlex>
                </Select.Option>
              ),
          )}
        </Select>
      )}
    </ClassNames>
  );
};
