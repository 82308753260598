import React from 'react';
import { Logo, Nav, AdminNav } from './components';

import { SidebarContainer } from './styled';
import { managementLayoutContext } from '@/providers';

export const Sidebar = () => {
  const { isSidebarOpen } = React.useContext(managementLayoutContext);

  return (
    <SidebarContainer isSidebarOpen={isSidebarOpen}>
      <Logo />
      <AdminNav />
      <Nav />
    </SidebarContainer>
  );
};
