import gql from 'graphql-tag';

export const OFFERS_LIST_QUERY = gql`
  query OffersList($first: Int, $skip: Int, $filter: OfferFilter) {
    offersList(first: $first, skip: $skip, filter: $filter) {
      items {
        id
        securityDeposite
        termsOfLease
        rentAmount
        moveinDate
        endsIn
        status
        bid(filter: { status: { not_equals: "rejected" } }) {
          count
        }
        historyOfferSingleUnit {
          id
          name
          type
          address {
            state
            street1
            street2
            zip
            country
            city
          }
          squareFt
          bedrooms
          bathrooms
          singleUnitHasMedia(sort: { order: ASC }) {
            items {
              id
              altName
              order
              media {
                id
                name
                file {
                  id
                  fileId
                  filename
                  downloadUrl
                  meta
                }
              }
            }
          }
          updatedAt
        }
      }
      count
    }
  }
`;
