import React from 'react';
import { Table } from '@/components';
import { useQuery } from 'react-apollo';
import { EnvironmentVariableListResponse, User } from '@/shared/types/graphql';
import gql from 'graphql-tag';
import { ColumnProps } from 'antd/lib/table';
import { dialogsContext } from '@/providers';
import { Dropdown, Icon, Menu } from 'antd';
import { RANKING_LABELS } from '@/shared/constants';
import { RANKING_UPDATE_DIALOG } from '@/dialogs';

const RANKING_CONSTANTS_QUERY = gql`
  query RankingConstants($filter: EnvironmentVariableFilter) {
    environmentVariablesList(filter: $filter) {
      items {
        id
        name
        value
      }
    }
  }
`;

export const RankingTable = () => {
  const { data, loading } = useQuery<{ environmentVariablesList: EnvironmentVariableListResponse }>(
    RANKING_CONSTANTS_QUERY,
    {
      variables: {
        filter: {
          name: {
            in: [
              'RANKING_WEIGHTING_FOR_LONGER_STAY',
              'RANKING_WEIGHTING_FOR_SHORTER_STAY',
              'RANKING_WEIGHTING_FOR_RENTAL_INCREASE',
              'RANKING_ANNUAL_RENTAL_INCREASE',
            ],
          },
        },
      },
    },
  );

  const RankingTableActions: React.FC<{ record: any }> = ({ record }) => {
    const { openDialog } = React.useContext(dialogsContext);

    const onEdit = React.useCallback(
      () =>
        openDialog({
          name: RANKING_UPDATE_DIALOG,
          props: {
            constantId: record?.id,
          },
        }),
      [openDialog, record],
    );

    const menu = (
      <Menu>
        <Menu.Item onClick={onEdit}>Edit</Menu.Item>
      </Menu>
    );

    return (
      <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
        <Icon type="ellipsis" />
      </Dropdown>
    );
  };

  const columns: ColumnProps<User>[] = React.useMemo(
    () => [
      {
        title: 'Constant Name',
        dataIndex: 'name',
        render: function GetLables(value: string) {
          return <>{RANKING_LABELS[value]}</>;
        },
      },
      {
        title: 'Value',
        dataIndex: 'value',
      },
      {
        title: '',
        dataIndex: '',
        width: '43px',
        render: function UserActions(value: string, record: any) {
          return <RankingTableActions record={record} />;
        },
      },
    ],
    [],
  );
  const dataSource = data?.environmentVariablesList?.items;
  return <Table rowKey="id" dataSource={dataSource} columns={columns} loading={loading} />;
};
