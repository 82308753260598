import { User, Community, SingleUnit } from '@/shared/types/graphql';
import { getApplicationRole } from '@/shared/utils';
import { checkRole } from 'livo-shared';
import * as R from 'ramda';
import { APPLICATION_ROLES, COMPANY_TYPES } from 'livo-shared';

export const getCommunities = (user: User): Community[] => {
  const applicationRole = getApplicationRole(user);

  const roleName = R.pathOr('', ['key'], applicationRole);

  const { isCompanyAdmin, isCompanyManager } = checkRole(roleName);

  if (isCompanyAdmin || isCompanyManager) {
    const communities: Community[] = R.pathOr([], ['communityManager', 'items'], user);
    return communities;
  }

  return [];
};

export const getSingleUnits = (user: User): SingleUnit[] => {
  const applicationRole = getApplicationRole(user);

  const roleName = R.pathOr('', ['key'], applicationRole);

  const { isCompanyAdmin, isCompanyManager } = checkRole(roleName);

  if (isCompanyAdmin || isCompanyManager) {
    const singleUnits: SingleUnit[] = R.pathOr([], ['singleUnitAgent', 'items'], user);
    return singleUnits;
  }

  return [];
};

const createReconnect = (nameRelation: string, propertiesIds: any) => {
  return {
    [nameRelation]: { reconnect: propertiesIds.map((id: string) => ({ id })) },
  };
};

export const createOptionsForUpdate = (data: any) => {
  const isMultyFamily = data?.company?.type === COMPANY_TYPES.mdu;

  const nameRelation = isMultyFamily ? 'communityManager' : 'singleUnitAgent';

  const communitiesIds = data.communities ?? [];
  const singleUnitsIds = data.singleUnits ?? [];

  const propertiesIds = isMultyFamily ? communitiesIds : singleUnitsIds;

  const roleName = R.pathOr('', ['applicationRole'], data);

  const { isCompanyAdmin, isCompanyManager } = checkRole(roleName);

  return {
    variables: {
      data: {
        id: data?.id,
        firstName: data?.firstName,
        lastName: data?.lastName,
        roles: {
          reconnect: APPLICATION_ROLES[data?.applicationRole]?.rolesList?.map((role: any) => ({
            name: role?.roleName,
          })),
        },
        ...createReconnect(nameRelation, isCompanyAdmin || isCompanyManager ? propertiesIds : []),
      },
    },
  };
};
