import { storageType, storageKey } from '@/shared/types/store';

interface Options {
  storageType?: storageType;
  storageKey: storageKey;
}

interface Storage {
  options?: Options;
  get(key: string): any;
  set(key: string, value: any): void;
  drop(key: string): boolean;
}

export default class Store implements Storage {
  private storage = localStorage;
  private storageKey: string;

  constructor(options: Options) {
    if (options?.storageType === storageType.Session) {
      this.storage = sessionStorage;
    }
    this.storageKey = options.storageKey;
  }

  private load(): any {
    const serializedData = this.storage.getItem(this.storageKey);
    if (serializedData === null) return null;

    return JSON.parse(serializedData);
  }

  private save(data: any): void {
    const serializedData = JSON.stringify(data);

    this.storage.setItem(this.storageKey, serializedData);
  }

  set(key: string, value: any) {
    const store = this.load();

    this.save({ ...store, [key]: value });
  }
  get(key: string) {
    const store = this.load();

    if (Boolean(store)) {
      return store[key];
    }

    return null;
  }
  drop(key: string): boolean {
    const store = this.load();

    if (!store) {
      return false;
    }

    if (key in store) {
      delete store[key];

      this.save(store);
      return true;
    }

    return false;
  }
}
