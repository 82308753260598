import { Offer } from '@/shared/types/graphql';
import React from 'react';
import {
  CardWrapper,
  OfferHeaderTag,
  OfferImageTag,
  OfferImageWrapperTag,
  OfferInfoColumnTag,
  OffersCountPositionTag,
} from './styled';
import { Card } from 'antd';
import { useHistory } from 'react-router-dom';
import { getSingleRentalUrl } from '@/shared/constants/appRoutes';
import MediaGalleryPlaceholder from '@/static/MediaGalleryPlaceholder.png';
import { ResumeNumbers, RowFlex, Tag, Text } from '@/components';
import { stringifyAddress, stringifySingleUnitType } from '@/shared/utils';
import OfferCardActions from '@/routes/rentals/PageCompanyLiveOffers/components/OfferCardActions';

type OfferCardProps = {
  offer: Offer;
};

export const OfferCard: React.FC<OfferCardProps> = ({ offer }) => {
  const history = useHistory();
  const offerId = offer?.id;
  const singleUnit = offer?.historyOfferSingleUnit;
  const mediaImage = singleUnit?.singleUnitHasMedia?.items[0]?.media?.file;
  const singleUnitPagePropertyUrl = getSingleRentalUrl(offerId);

  const onGoToOfferDetailsPage = () => history.push(singleUnitPagePropertyUrl);

  return (
    <CardWrapper>
      <Card bodyStyle={{ padding: 0, height: '100%' }}>
        <OfferHeaderTag onClick={onGoToOfferDetailsPage}>
          <If condition={!!singleUnit}>
            <OfferImageWrapperTag>
              <OfferImageTag src={mediaImage?.downloadUrl || MediaGalleryPlaceholder} />
              <OffersCountPositionTag>
                <Tag color="orange" type="raised">
                  {offer?.status}
                </Tag>
              </OffersCountPositionTag>
            </OfferImageWrapperTag>
            <OfferInfoColumnTag>
              <Text type="title" ellipsis={true}>
                {singleUnit?.name}
              </Text>
              <Text ellipsis={true}>{stringifyAddress(singleUnit?.address)}</Text>
              <Text type="uppercase" ellipsis={true}>
                {stringifySingleUnitType(singleUnit?.type)}
              </Text>
              <RowFlex align="center">
                <ResumeNumbers
                  size="sm"
                  beds={singleUnit?.bedrooms}
                  baths={singleUnit?.bathrooms}
                  squareFt={singleUnit?.squareFt}
                />
              </RowFlex>
              <OfferCardActions offer={offer} />
            </OfferInfoColumnTag>
          </If>
        </OfferHeaderTag>
      </Card>
    </CardWrapper>
  );
};
