import React, { ChangeEvent } from 'react';
import { CustomCard, TableHeader } from '@/components';

import { EmployeesTable } from './EmployeesTable';
import { INVITATION_SEND_MUTATION } from './queries';
import { dialogsContext } from '@/providers';
import { TOAST_SUCCESS_MESSAGE } from '@/shared/constants';
import { TeamInvitationFormValues } from '@/shared/types';
import {
  useApplicationsRolesOptions,
  useSearch,
  usePermissions,
  useCurrentCompanyId,
  useMutationSafe,
  useDialogState,
} from '@/shared/hooks';
import { TEAM_MEMBER_INVITATION_DIALOG } from '@/dialogs';

export const Employees = () => {
  const [{ search, throttledSearch }, setSearch] = useSearch();
  const { openDialog } = React.useContext(dialogsContext);
  const { close } = useDialogState(TEAM_MEMBER_INVITATION_DIALOG);

  const { isSuperAdmin } = usePermissions();
  const { companyId } = useCurrentCompanyId();

  const [invitationSend] = useMutationSafe(INVITATION_SEND_MUTATION, {
    onCompleted: close,
    context: {
      [TOAST_SUCCESS_MESSAGE]: 'Invitation Successfully Sent',
    },
    refetchQueries: ['EmployeesList'],
    awaitRefetchQueries: true,
  });

  const rolesOptions = useApplicationsRolesOptions();
  const employeeRolesOptions = rolesOptions.filter(({ isEmployeeRole }) => isEmployeeRole);

  const openModal = () =>
    openDialog({
      name: TEAM_MEMBER_INVITATION_DIALOG,
      props: {
        visible: true,
        rolesOptions: employeeRolesOptions,
        companyId,
        onSubmit: async (data: TeamInvitationFormValues) => {
          const [firstName, lastName] = data.fullName.split(' ');

          await invitationSend({
            variables: {
              invitationRole: data.applicationRole,
              user: {
                ...data.user,
                firstName,
                lastName,
              },
            },
          });
        },
      },
    });

  const onSearchChange = React.useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearch(event.currentTarget.value);
    },
    [setSearch],
  );

  const actionProps = isSuperAdmin
    ? {
        text: 'Invite Employee',
        icon: { type: 'plus-circle' },
        onClick: openModal,
      }
    : undefined;

  return (
    <CustomCard
      padding="none"
      header={
        <TableHeader
          title="Employees"
          searchProps={{ query: search, placeholder: 'Search by Employee Name', onChange: onSearchChange }}
          actionProps={actionProps}
        />
      }
    >
      <EmployeesTable query={throttledSearch} />
    </CustomCard>
  );
};
