import React from 'react';
import * as R from 'ramda';
import {
  OfferCreateDialog,
  OfferUpdateDialog,
  OfferEndDialog,
  OfferDetailsDialog,
  OfferCancelConfirmDialog,
  OfferAwardDialog,
  OfferDeleteDialog,
  OfferRejectDialog,
  ClosingConfirmDialog,
  SingleUnitCreateDialog,
  SingleUnitUpdateDialog,
  SingleUnitDeleteDialog,
  SingleUnitsImportDialog,
  VirtualTourDialog,
  CalendarDialog,
  ImportConfirmDialog,
  DeleteConfirmDialog,
  CommunityUpdateDialog,
  CommunityCreateDialog,
  CommunityDeleteDialog,
  CommunitiesImportDialog,
  CompanyCreateDialog,
  CompanyUpdateDialog,
  DefaultAmenityUpdateDialog,
  DefaultAmenityCreateDialog,
  InviteRentersDialog,
  TeamMemberUpdateDialog,
  TeamMemberInvitationDialog,
  TeamMemberDeleteDialog,
  TeamMemberConfirmDialog,
  OnboardingDialog,
  AgentContactDialog,
  BulkDeleteDialog,
  EmployeeDeleteDialog,
  BidderAwardDialog,
  ScheduleShowingContactDialog,
  BidderRejectDialog,
  RenterDetailsDialog,
  RenterDetailsWithBidDialog,
  RenterDetailsWithBidsListDialog,
  RankingConfirmDialog,
  RankingUpdateDialog,
  BidCreateDialog,
  BidDeleteDialog,
  InfoDialog,
} from '@/dialogs';

interface Dialog {
  name: string;
  visible?: boolean;
  props?: any;
}

interface Dialogs {
  openDialog: (dialog: Dialog) => void;
  closeDialog: (name: string) => void;
  currentOpenDialog: Dialog | undefined;
  isDialogVisible: (name: string) => boolean;
  getDialogProps: (name: string) => any;
}
const dialogsContext = React.createContext<Dialogs>(null!);

const DialogsProvider: React.FC = ({ children }) => {
  const [queueDialogs, setQueueDialogs] = React.useState<Dialog[]>([]);

  const openDialog = (dialog: Dialog) => {
    setQueueDialogs((prevState: Dialog[]) => {
      const newState = [...prevState, { visible: true, ...dialog }];
      return newState;
    });
  };

  const closeDialog = (name: string) => {
    setQueueDialogs((prevState: Dialog[]) => {
      const newState = R.reject(R.propEq('name', name), prevState);
      return newState;
    });
  };

  const getDialogProps = (name: string) => {
    const dialog = R.find(R.propEq('name', name), queueDialogs);
    const props = R.pathOr({}, ['props'], dialog);
    return props;
  };

  const getCurrentOpenDialog = React.useCallback(() => R.last(queueDialogs), [queueDialogs]);

  const currentOpenDialog = getCurrentOpenDialog();

  const isDialogVisible = (name: string) => {
    const dialog = R.find(R.propEq('name', name), queueDialogs);
    const isVisible = R.pathOr(false, ['visible'], dialog);
    return isVisible;
  };

  return (
    <dialogsContext.Provider
      value={{
        openDialog,
        closeDialog,
        currentOpenDialog,
        isDialogVisible,
        getDialogProps,
      }}
    >
      {children}

      <OfferUpdateDialog />
      <OfferDetailsDialog />
      <OfferEndDialog />
      <OfferAwardDialog />
      <OfferDeleteDialog />
      <OfferRejectDialog />
      <OfferCancelConfirmDialog />
      <OfferCreateDialog />
      <SingleUnitCreateDialog />
      <SingleUnitUpdateDialog />
      <SingleUnitDeleteDialog />
      <SingleUnitsImportDialog />
      <ClosingConfirmDialog />
      <VirtualTourDialog />
      <CalendarDialog />
      <ImportConfirmDialog />
      <DeleteConfirmDialog />
      <CommunityCreateDialog />
      <CommunityUpdateDialog />
      <CommunityDeleteDialog />
      <CommunitiesImportDialog />
      <CompanyCreateDialog />
      <CompanyUpdateDialog />
      <DefaultAmenityUpdateDialog />
      <DefaultAmenityCreateDialog />
      <InviteRentersDialog />
      <TeamMemberUpdateDialog />
      <TeamMemberInvitationDialog />
      <TeamMemberDeleteDialog />
      <TeamMemberConfirmDialog />
      <OnboardingDialog />
      <AgentContactDialog />
      <BulkDeleteDialog />
      <EmployeeDeleteDialog />
      <ScheduleShowingContactDialog />
      <BidderAwardDialog />
      <BidderRejectDialog />
      <RenterDetailsDialog />
      <RenterDetailsWithBidDialog />
      <RenterDetailsWithBidsListDialog />
      <RankingConfirmDialog />
      <RankingUpdateDialog />
      <BidDeleteDialog />
      <BidCreateDialog />
      <InfoDialog />
    </dialogsContext.Provider>
  );
};

DialogsProvider.displayName = 'DialogsProvider';

export { DialogsProvider, dialogsContext };
