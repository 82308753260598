import { ImportValidationErrorsList, ImportValidationError, ImportValidationErrorDetails } from '@/shared/types';

export const parseImportErrors = (value?: ImportValidationErrorDetails): ImportValidationErrorsList | undefined => {
  if (!value) {
    return;
  }

  const x = Object.entries(value).reduce((result, [key, value]) => {
    const entityOrder = Number(key);

    const entityErrors = Object.entries(value).reduce((result, [entityName, value]) => {
      if (typeof value === 'string') {
        result[entityName] = value;
        return result;
      } else {
        const newErrors = {} as ImportValidationError;

        Object.entries(value).forEach(([entityOption, value]) => {
          newErrors[`${entityName}.${entityOption}`] = value;
        });

        return {
          ...result,
          ...newErrors,
        };
      }
    }, {} as ImportValidationError);

    result[entityOrder] = entityErrors;

    return result;
  }, {} as ImportValidationErrorsList);

  return x;
};
