import gql from 'graphql-tag';

export const SINGLE_UNIT_QUERY = gql`
  query SingleUnit($id: ID!) {
    singleUnit(id: $id) {
      id
      singleUnitId
      name
      type
      description
      bedrooms
      bathrooms
      squareFt
      singleUnitAmenity {
        id
        sports
        swimSpa
        children
        family
        pets
        leisureSocial
        creative
        laundry
        transportation
        security
        onsite
        technology
        common
        misc
        accessibility
      }
      address {
        country
        street1
        street2
        zip
        city
        state
      }
      phone {
        number
        code
      }
      community {
        id
        name
        website
        features
        phoneNumber {
          code
          number
        }
        communityAmenity {
          id
          sports
          swimSpa
          children
          family
          pets
          leisureSocial
          creative
          laundry
          transportation
          security
          onsite
          technology
          common
          misc
          accessibility
        }
        communityHasMedia(sort: { order: ASC }) {
          items {
            id
            altName
            order
            media {
              id
              name
              file {
                id
                fileId
                filename
                downloadUrl
                meta
              }
            }
          }
        }
      }
      website
      features
      virtualTour
      singleUnitHasMedia(sort: { order: ASC }) {
        items {
          id
          altName
          order
          media {
            id
            name
            file {
              id
              fileId
              filename
              downloadUrl
              meta
            }
          }
        }
      }
      activeOffer {
        id
        startsIn
        endsIn
        rentAmount
        timezone
        contact {
          id
          lastName
          firstName
          email
          phone {
            code
            number
          }
        }
        bid(sort: { rentAmount: DESC }, first: 1) {
          count
          items {
            id
            rentAmount
            createdAt
          }
        }
        status
        securityDeposite
        termsOfLease
        moveinDate
        secureNowAmount
      }
    }
  }
`;

export const OFFERS_SINGLE_UNIT_QUERY = gql`
  query OffersSingleUnitQuery($id: ID!) {
    offer(id: $id) {
      id
      startsIn
      endsIn
      rentAmount
      timezone
      contact {
        id
        lastName
        firstName
        email
        phone {
          code
          number
        }
      }
      bid(sort: { rentAmount: DESC }, first: 1) {
        count
        items {
          id
          rentAmount
          createdAt
        }
      }
      status
      securityDeposite
      termsOfLease
      moveinDate
      secureNowAmount
      historyOfferSingleUnit {
        id
        singleUnitId
        name
        type
        description
        bedrooms
        bathrooms
        squareFt
        amenity(groupBy: { query: { group: { key: { as: "groupKey" } }, _group: { as: "amenityKeysList" } } }) {
          groups {
            groupKey: String
            amenityKeysList: GroupIds
          }
          items {
            id
            key
            group {
              id
              key
              masterAmenity {
                id
                key
              }
            }
          }
        }
        address {
          country
          street1
          street2
          zip
          city
          state
        }
        phone {
          number
          code
        }
        activeOffer {
          id
          startsIn
          endsIn
          rentAmount
          contact {
            id
            lastName
            firstName
            email
            phone {
              code
              number
            }
          }
          bid(sort: { rentAmount: DESC }, first: 1) {
            count
            items {
              id
              rentAmount
              createdAt
            }
          }
          status
          securityDeposite
          termsOfLease
          moveinDate
          secureNowAmount
        }
        community {
          id
          name
          website
          features
          phoneNumber {
            code
            number
          }
          amenity(groupBy: { query: { group: { key: { as: "groupKey" } }, _group: { as: "amenityKeysList" } } }) {
            groups {
              groupKey: String
              amenityKeysList: GroupIds
            }
            items {
              id
              key
            }
          }
          communityHasMedia(sort: { order: ASC }) {
            items {
              id
              altName
              order
              media {
                id
                name
                file {
                  id
                  fileId
                  filename
                  downloadUrl
                  meta
                }
              }
            }
          }
        }
        website
        features
        virtualTour
        singleUnitHasMedia(sort: { order: ASC }) {
          items {
            id
            altName
            order
            media {
              id
              name
              file {
                id
                fileId
                filename
                downloadUrl
                meta
              }
            }
          }
        }
      }
    }
  }
`;

export const RENTERS_AMOUNT_QUERY = gql`
  query RentersQuery($id: ID!) {
    singleUnit(id: $id) {
      id
      activeOffer {
        id
        bid {
          count
        }
      }
    }
  }
`;

export const RENTERS_AMOUNT_BY_OFFER_QUERY = gql`
  query RentersQueryByOffer($id: ID!) {
    offer(id: $id) {
      id
      bid {
        count
      }
    }
  }
`;

export const UNIT_DETAILS_VIA_OFFER = gql`
  query SingleUnitDetailsViaOffer($id: ID!) {
    offer(id: $id) {
      id
      bid {
        count
      }
      historyOfferSingleUnit {
        id
        company {
          id
          type
        }
        community {
          id
        }
      }
    }
  }
`;

export const RENTERS_QUERY = gql`
  query RentersQuery($id: ID!, $first: Int, $skip: Int, $filter: BidFilter, $sort: [BidSort!]) {
    offer(id: $id) {
      id
      bid(first: $first, skip: $skip, filter: $filter, sort: $sort) {
        count
        items {
          id
          status
          moveinDate
          termsOfLease
          rentAmount
          offerAccepted
          applicationFormComplete
          screeningComplete
          leaseSigned
          depositCheck
          offer {
            id
            status
          }
          renter {
            id
            user {
              id
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;
