export const SORT_OPTIONS = [
  {
    label: 'Sort by: Time Asc',
    value: {
      sortBy: 'createdAt',
      sortOrder: 'ASC',
    },
  },
  {
    label: 'Sort by: Time Desc',
    value: {
      sortBy: 'createdAt',
      sortOrder: 'DESC',
    },
  },
];

export const FILTER_OPTIONS = [
  {
    label: 'View: Active Offers',
    value: 'active',
  },
  {
    label: 'View: Leased Offers',
    value: 'leased',
  },
  {
    label: 'View: All Offers',
    value: 'all',
  },
];
