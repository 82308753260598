import React from 'react';
import { usePermissions } from '@/shared/hooks';

interface ManagementLayoutContextProps {
  isSidebarOpen: boolean;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  handleSidebarToggle: () => void;
}

const managementLayoutContext = React.createContext<ManagementLayoutContextProps>(undefined!);

type LoadingProviderProps = {
  children: React.ReactChild | React.ReactChild[];
};

const ManagementLayoutProvider: React.FC<LoadingProviderProps> = ({ children }) => {
  const [isSidebarOpen, setSidebarToggle] = React.useState(localStorage.getItem('sidebar') !== 'false');
  const { isAdmin, isSuperAdmin } = usePermissions();

  React.useEffect(() => {
    window.addEventListener('storage', () => {
      setSidebarToggle(localStorage.getItem('sidebar') !== 'false');
    });
  });

  const handleSidebarToggle = React.useCallback(() => {
    const value = localStorage.getItem('sidebar') === 'false';

    localStorage.setItem('sidebar', String(value));

    setSidebarToggle(value);

    window.dispatchEvent(new Event('storage'));
  }, []);

  return (
    <managementLayoutContext.Provider value={{ isSidebarOpen, handleSidebarToggle, isAdmin, isSuperAdmin }}>
      {children}
    </managementLayoutContext.Provider>
  );
};

ManagementLayoutProvider.displayName = 'ManagementLayoutProvider';

export { ManagementLayoutProvider, managementLayoutContext };
