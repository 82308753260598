import {
  parsePhone,
  parseSingleUnitType,
  parseNumber,
  parseNumberWithRound,
  parseDate,
  stringToUppercase,
} from '@/shared/utils';
import { TableCsvPreviewColumn } from '@/components/TableCsvPreview/TableCsvPreview';

export const IMPORT_TABLE_COLUMNS: Array<TableCsvPreviewColumn> = [
  { key: 'name', title: 'Property Name' },
  { key: 'lotSize', title: 'Lot Size' },
  { key: 'zoning', title: 'Zoning' },
  { key: 'floorplan', title: 'Floorplan' },
  { key: 'unitDesignation', title: 'Unit Designation' },
  { key: 'unitLeaseStatus', title: 'Unit/Lease Status' },
  { key: 'moveInMoveOut', title: 'Move-In/Move-Out' },
  { key: 'leaseStart', title: 'Lease Start', parser: parseDate },
  { key: 'leaseEnd', title: 'Lease End', parser: parseDate },
  { key: 'marketRate', title: 'Market Rate', parser: parseNumber },
  { key: 'marketRateWithAdditional', title: 'Market Rate + Additional', parser: parseNumber },
  { key: 'transCode', title: 'Trans Code' },
  { key: 'leaseRent', title: 'Lease Rent', parser: parseNumber },
  { key: 'otherChargesCredits', title: 'Other Charges/Credits', parser: parseNumber },
  { key: 'totalBilling', title: 'Total Billing', parser: parseNumber },
  { key: 'depOnHand', title: 'Dep On Hand', parser: parseNumber },
  { key: 'balance', title: 'Balance', parser: parseNumber },
  { key: 'address.street1', title: 'Address 1' },
  { key: 'address.street2', title: 'Address 2' },
  { key: 'address.city', title: 'City' },
  { key: 'address.state', title: 'State', parser: stringToUppercase },
  { key: 'address.zip', title: 'Zip' },
  { key: 'type', title: 'Type', parser: parseSingleUnitType },
  { key: 'description', title: 'Description' },
  { key: 'phone', title: 'Phone Number', parser: parsePhone },
  { key: 'website', title: 'Website' },
  { key: 'bedrooms', title: '# of bedrooms', parser: parseNumber },
  { key: 'bathrooms', title: '# of baths', parser: parseNumber },
  { key: 'squareFt', title: '# Square ft.', parser: parseNumberWithRound },
];

export const MDU_IMPORT_TABLE_COLUMNS: Array<TableCsvPreviewColumn> = [
  { key: 'name', title: 'Unit' },
  { key: 'floorplan', title: 'Floorplan' },
  { key: 'unitDesignation', title: 'Unit Designation' },
  { key: 'unitLeaseStatus', title: 'Unit/Lease Status' },
  { key: 'tenantName', title: 'Tenant Name' },
  { key: 'moveInMoveOut', title: 'Move-In/Move-Out' },
  { key: 'leaseStart', title: 'Lease Start', parser: parseDate },
  { key: 'leaseEnd', title: 'Lease End', parser: parseDate },
  { key: 'marketRate', title: 'Market Rate', parser: parseNumber },
  { key: 'marketRateWithAdditional', title: 'Market Rate + Additional', parser: parseNumber },
  { key: 'transCode', title: 'Trans Code' },
  { key: 'leaseRent', title: 'Lease Rent', parser: parseNumber },
  { key: 'otherChargesCredits', title: 'Other Charges/Credits', parser: parseNumber },
  { key: 'totalBilling', title: 'Total Billing', parser: parseNumber },
  { key: 'depOnHand', title: 'Dep On Hand', parser: parseNumber },
  { key: 'balance', title: 'Balance', parser: parseNumber },
  { key: 'address.street1', title: 'Address 1' },
  { key: 'address.street2', title: 'Address 2' },
  { key: 'address.city', title: 'City' },
  { key: 'address.state', title: 'State', parser: stringToUppercase },
  { key: 'address.zip', title: 'Zip' },
  { key: 'type', title: 'Type', parser: parseSingleUnitType },
  { key: 'description', title: 'Description' },
  { key: 'phone', title: 'Phone Number', parser: parsePhone },
  { key: 'bedrooms', title: '# of bedrooms', parser: parseNumber },
  { key: 'bathrooms', title: '# of baths', parser: parseNumber },
  { key: 'squareFt', title: '# Square ft.', parser: parseNumberWithRound },
];
