import React from 'react';
import { useQuery } from 'react-apollo';
import { Dropdown, Icon, Menu } from 'antd';
import { PORTFOLIO_COMMUNITIES_QUERY } from './queries';
import { stringifyDateTime, stringifyUserName } from '@/shared/utils';
import { ColumnProps } from 'antd/lib/table';
import { CommunityListResponse, SingleUnitListResponse, Community } from '@/shared/types/graphql';
import { WebsiteLink, Table } from '@/components';
import { useTableState, TableState, UseTableStateResponse, useCurrentCompanyId } from '@/shared/hooks';
import { dialogsContext } from '@/providers';
import { CommunityStatusTag, ConditionalLink } from '@/pro';
import { DRAFT_STATUS } from '@/shared/constants';
import { COMMUNITY_UPDATE_DIALOG } from '@/dialogs';

type CommunitiesTableProps = {
  query: string;
};

const getSort = ({ sortBy, sortOrder }: TableState) => {
  let sort: Record<string, any>[] = [];

  if (sortBy && sortOrder) {
    const field = sortBy;
    const direction = sortOrder === 'ascend' ? 'ASC' : 'DESC';

    sort = [
      {
        [field]: direction,
      },
    ];
  }

  return sort;
};

export const CommunitiesTable = ({ query }: CommunitiesTableProps) => {
  const [{ page, sortBy, sortOrder, pageSize, pageSizeOptions }, onChange]: UseTableStateResponse = useTableState({
    query,
  });
  const { companyId } = useCurrentCompanyId();
  const { openDialog } = React.useContext(dialogsContext);

  const openEditModal = React.useCallback(
    (id: string) =>
      openDialog({
        name: COMMUNITY_UPDATE_DIALOG,
        props: {
          options: {
            refetchQueries: ['RentalCommunitiesList'],
            awaitRefetchQueries: true,
          },
          id,
        },
      }),
    [openDialog],
  );

  const { data, loading } = useQuery<{ communitiesList: CommunityListResponse }>(PORTFOLIO_COMMUNITIES_QUERY, {
    variables: {
      filter: {
        AND: [
          {
            company: {
              id: {
                equals: companyId,
              },
            },
          },
          query !== ''
            ? {
                name: {
                  contains: query,
                },
              }
            : {},
        ],
      },
      sort: getSort({ sortOrder, sortBy, page, pageSize }),
      first: pageSize,
      skip: (page - 1) * pageSize,
    },
  });

  const dataSource = data?.communitiesList?.items ?? [];
  const total = data?.communitiesList?.count;

  const pagination = { current: page, pageSize, total, showSizeChanger: true, pageSizeOptions };

  const columns: ColumnProps<Community>[] = React.useMemo(
    () => [
      {
        title: 'Property Name',
        dataIndex: 'name',
        sorter: dataSource?.length !== 0,
        width: '20%',
        ellipsis: true,
        sortOrder: sortBy === 'name' ? sortOrder : undefined,
        render: function renderCommunityLink(name: string, record) {
          return (
            <ConditionalLink
              to={`/management/${companyId}/rentals/communities/${record.id}`}
              condition={record.status !== DRAFT_STATUS}
              text={name}
            />
          );
        },
      },
      {
        title: 'Rentals',
        dataIndex: 'singleUnit',
        width: '10%',
        render: function CommunityRentalCell(singleUnits: SingleUnitListResponse) {
          return singleUnits?.count ?? 0;
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        sorter: dataSource?.length !== 0,
        width: '10%',
        ellipsis: true,
        sortOrder: sortBy === 'status' ? sortOrder : undefined,
        render: function renderCommunityStatus(status?: string) {
          return <CommunityStatusTag status={status} />;
        },
      },
      {
        title: 'Website',
        dataIndex: 'website',
        sorter: dataSource?.length !== 0,
        width: '20%',
        ellipsis: true,
        sortOrder: sortBy === 'name' ? sortOrder : undefined,
        render: function CommunityWebsitLinkCell(website?: string) {
          return <WebsiteLink website={website} />;
        },
      },
      {
        title: 'Created By',
        dataIndex: 'createdBy',
        width: '20%',
        ellipsis: true,
        render: stringifyUserName,
      },
      {
        title: 'Last Updated',
        dataIndex: 'updatedAt',
        sorter: dataSource?.length !== 0,
        ellipsis: true,
        sortOrder: sortBy === 'updatedAt' ? sortOrder : undefined,
        render: stringifyDateTime,
      },
      {
        title: '',
        dataIndex: 'id',
        width: '43px',
        render: function CommunityActions(id: string) {
          const menu = (
            <Menu>
              <Menu.Item onClick={() => openEditModal(id)}>Edit</Menu.Item>
              <Menu.Item>Delete</Menu.Item>
            </Menu>
          );

          return (
            <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
              <Icon type="ellipsis" />
            </Dropdown>
          );
        },
      },
    ],
    [dataSource, sortBy, sortOrder, companyId, openEditModal],
  );

  return (
    <Table
      rowKey="id"
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      onChange={onChange}
      pagination={pagination}
    />
  );
};
