export enum AppRoutes {
  RENTER_LANDING = '/landing',
  RENTER_NOT_FOUND_PAGE = '/not-found',
  RENTER_ERROR_PAGE = '/something-went-wrong',

  MANAGEMENT = '/management',
  MANAGEMENT_DASHBOARD = '/management/dashboard',
  MANAGEMENT_COMPANY_ID = '/management/:companyId',

  RENTALS = '/rentals',
  RENTALS_ID = '/rentals/:id',
  RENTALS_ID_BIDDING = '/rentals/:id/bidding',
  RENTALS_ID_COMPANY_SINGLE_UNITS = '/rentals/:id/single-units',

  OFFERS = '/offers',
  ACCOUNT = '/account',

  AUTH = '/auth',
  AUTH_CONFIRMATION = '/auth/confirmation',
  AUTH_CALLBACK = '/auth/callback',
}

export function getSingleRentalUrl(offerId?: string | null): string {
  return `${AppRoutes.RENTALS}/${offerId}`;
}

export function getSingleRentalBiddingUrl(offerId?: string | null): string {
  return `${AppRoutes.RENTALS}/${offerId}/bidding`;
}

export function getPublicSingleUnitsUrl(companyId: string): string {
  return `${AppRoutes.RENTALS}/${companyId}/single-units`;
}
