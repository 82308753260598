import gql from 'graphql-tag';

export const TEAM_MEMBER_UPDATE_MODAL_MUTATION = gql`
  mutation TeamMemberUpdateMutation($data: UserUpdateInput!) {
    userUpdate(data: $data) {
      id
      roles {
        items {
          id
          name
        }
      }
    }
  }
`;

export const TEAM_MEMBER_UPDATE_MODAL_QUERY = gql`
  query TeamMemberUpdateQuery($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      company {
        id
        type
      }
      communityManager {
        items {
          id
          name
        }
      }
      singleUnitAgent {
        items {
          id
          name
        }
      }
      roles {
        items {
          id
          name
        }
      }
    }
  }
`;
