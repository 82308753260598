import gql from 'graphql-tag';

export const AMENITY_GROUPS_QUERY = gql`
  query AmenityGroupsList($filter: AmenityGroupFilter) {
    amenityGroupsList(filter: $filter) {
      items {
        id
        key
      }
    }
  }
`;

export const DEFAULT_AMENITY_CREATE_MUTATION = gql`
  mutation DefaultAmenityCreate($data: DefaultAmenityCreateInput!) {
    defaultAmenityCreate(data: $data) {
      id
    }
  }
`;
