import React from 'react';
import { Modal, Typography } from 'antd';
import { ModalFooter } from '@/components';
import { useDialogState } from '@/shared/hooks';

export const IMPORT_CONFIRM_DIALOG = 'IMPORT_CONFIRM_DIALOG';

export const ImportConfirmDialog = () => {
  const { close, isVisible, props } = useDialogState(IMPORT_CONFIRM_DIALOG);
  const confirmAction = props?.confirmAction;

  if (!isVisible) {
    return null;
  }

  return (
    <Modal
      title="Confirm Import"
      visible={isVisible}
      onCancel={close}
      footer={
        <ModalFooter
          cancelProps={{
            onClick: close,
          }}
          okProps={{
            disabled: !confirmAction,
            onClick: () => {
              confirmAction && confirmAction();
              close();
            },
            type: 'primary',
            text: 'Continue',
          }}
        />
      }
      centered
    >
      <Typography.Paragraph>
        You are about to import bulk data, please make sure all information is accurate before upload
      </Typography.Paragraph>
    </Modal>
  );
};
