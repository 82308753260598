import React from 'react';
import styled from '@emotion/styled';

const H1 = styled.h1`
  font-size: 1.8rem;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #323c47;
`;

type HeadingProps = {
  type: 'h1' | 'h2' | 'h3';
};

export const Heading: React.FC<HeadingProps> = ({ children, type, ...rest }) => {
  switch (type) {
    case 'h1':
      return <H1 {...rest}>{children}</H1>;
    case 'h2':
    case 'h3':
    default:
      return null;
  }
};
