import React from 'react';

import { LogoSection, defaultLogoStyles, smallLogoStyles } from './styled';

import { ReactComponent as LogoIcon } from '@/static/LivoSecureWhite.svg';
import { ReactComponent as LogoSmallIcon } from '@/static/LivoSecureWhiteSmall.svg';
import { managementLayoutContext } from '@/providers';

export const Logo: React.FC = () => {
  const { isSidebarOpen } = React.useContext(managementLayoutContext);

  return (
    <LogoSection css={{ position: 'relative' }}>
      <LogoIcon css={defaultLogoStyles(isSidebarOpen)} />
      <LogoSmallIcon css={smallLogoStyles(isSidebarOpen)} />
    </LogoSection>
  );
};
