import React from 'react';
import { useLazyQuery } from 'react-apollo';
import * as yup from 'yup';
import { assocPath } from 'ramda';

import { CHECK_USER_EMAIL_QUERY } from '@/shared/graphql';
import { UserListResponse } from '@/shared/types/graphql';
import { useCurrentCompanyId } from '@/shared/hooks/useCurrentCompanyId';
import { loadingContext } from '@/providers';

const validateEmail = async (email?: string) => {
  const shema = yup.object({
    email: yup
      .string()
      .email('Invalid Email value')
      .nullable(),
  });

  const errors = await shema
    .validate(
      {
        email,
      },
      {
        abortEarly: false,
      },
    )
    .then(() => [])
    .catch(({ inner }) => {
      return inner.reduce((accum: any, error: any) => {
        return assocPath(error.path.split('.'), error.errors[0], accum);
      }, {});
    });

  return errors?.email;
};

const ERROR_TEXT = 'User with such Email already exist';

export const useCheckUserEmail = () => {
  const { setLoading } = React.useContext(loadingContext);

  const [errorEmail, setErrorEmail] = React.useState<string | undefined>(undefined);

  const query = CHECK_USER_EMAIL_QUERY;
  const { companyId } = useCurrentCompanyId();
  const [checkEmailQuery, { loading, data }] = useLazyQuery<{
    usersList: UserListResponse;
  }>(query);

  setLoading(loading);

  const checkEmail = async (email?: string) => {
    if (email) {
      const error = await validateEmail(email);
      if (error) {
        return error;
      }

      await checkEmailQuery({
        variables: {
          email,
          companyId,
        },
      });

      const isEmailExist = !!data?.usersList?.count;
      if (isEmailExist) {
        return ERROR_TEXT;
      }
    }
  };

  const validateCheckEmail = (value: string) => {
    checkEmail(value).then(error => setErrorEmail(error));
    return errorEmail;
  };

  return { validateCheckEmail, loading, data };
};
