import { User } from '@/shared/types/graphql';

export const stringifyUserName = (user?: User) => {
  let result = '';

  if (user?.firstName) {
    result += user.firstName;
  }

  if (user?.lastName) {
    result += ` ${user.lastName}`;
  }

  return result;
};
