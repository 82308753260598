import { usePermissions } from '@/shared/hooks';
import { TableState } from '@/shared/hooks';

type UseCommunitiesQueryFilterParams = {
  query: string;
  companyId: string;
};

export const useCommunitiesQueryFilter = ({ query, companyId }: UseCommunitiesQueryFilterParams) => {
  const { isAdmin, isCompanyOwner, allowedCommunities } = usePermissions();

  const all: any = [
    {
      company: {
        id: {
          equals: companyId,
        },
      },
    },
    query &&
      query !== '' && {
        name: {
          contains: query,
        },
      },
    !(isAdmin || isCompanyOwner) && {
      id: {
        in: allowedCommunities.map(({ id }) => id),
      },
    },
  ].filter(Boolean);

  return {
    AND: all,
  };
};

export const getSort = ({ sortOrder, sortBy }: TableState) => {
  let sort: Record<string, any>[] = [];

  if (sortBy && sortOrder) {
    const field = sortBy;
    const direction = sortOrder === 'ascend' ? 'ASC' : 'DESC';

    sort = [
      {
        [field]: direction,
      },
    ];
  }

  return sort;
};
