import { usePermissions } from '@/shared/hooks';
import { APPLICATION_ROLES } from 'livo-shared';

export const useApplicationsRolesOptions = () => {
  const { applicationRole } = usePermissions();

  const options = (applicationRole?.allowedToInvite || []).reduce((result: any, appRoleKey: any) => {
    const appRole = APPLICATION_ROLES[appRoleKey];

    return [
      ...result,
      {
        label: appRole.name,
        value: appRole.key,
        isClientRole: !!appRole.clientRole,
        isEmployeeRole: !!appRole.employeeRole,
      },
    ];
  }, [] as Array<{ label: string; value: string; isClientRole: boolean; isEmployeeRole: boolean }>);

  return options;
};
