import React from 'react';

import { BellIcon } from '@/static/icons/';

import styled from '@/theme/styled';

type NotificationsStickerProps = {
  notificationsCount: number;
};

const NotificationsStickerTag = styled.div`
  height: 16px;
  width: 16px;
  position: absolute;
  top: -2px;
  right: -6px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 9px;
  line-height: 13px;
  text-align: center;
  font-weight: 600;
  background-color: ${props => props.theme.vars.green};
`;

const NotificationsSticker = ({ notificationsCount }: NotificationsStickerProps) => (
  <NotificationsStickerTag>{notificationsCount}</NotificationsStickerTag>
);

const NotificationsStickerPositionTag = styled.div`
  position: relative;
`;

const NotificationsButtonTag = styled.button`
  display: block;
  font-size: 0;
  outline: none;
  height: 100%;
  width: 36px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
  background: transparent;
  position: relative;
`;

type NotificationsButtonProps = {
  notificationsCount: number;
};

export const NotificationsButton = ({ notificationsCount, ...rest }: NotificationsButtonProps) => (
  <NotificationsButtonTag {...rest}>
    <NotificationsStickerPositionTag>
      <BellIcon />
      {notificationsCount && <NotificationsSticker notificationsCount={notificationsCount} />}
    </NotificationsStickerPositionTag>
  </NotificationsButtonTag>
);
