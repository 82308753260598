import gql from 'graphql-tag';

const COMMUNITY_FRAGMENT = gql`
  fragment Community on Community {
    id
    name
    numberOfUnits
    managementCompany
    managerTitle
    managerName
    managerEmail
    managerPhoneNumber {
      code
      number
    }
    logo {
      id
      fileId
      filename
      downloadUrl
      meta
    }
    company {
      id
      type
    }
    description
    address {
      country
      state
      zip
      street1
      street2
      city
    }
    phoneNumber {
      number
      code
    }
    website
    propertyManagementSoftwareSystems {
      items {
        id
        name
      }
    }
    communityHasMedia(sort: { order: ASC }) {
      items {
        id
        altName
        order
        media {
          id
          name
          file {
            id
            fileId
            filename
            downloadUrl
            meta
          }
        }
      }
    }
    type
    status
    communityManagementSoftwareSystem
    communityAmenity {
      id
      sports
      swimSpa
      children
      family
      pets
      leisureSocial
      creative
      laundry
      transportation
      security
      onsite
      technology
      common
      misc
      accessibility
    }
    amenity(groupBy: { query: { group: { key: { as: "groupKey" } }, _group: { as: "amenityKeysList" } } }) {
      items {
        id
        key
      }
      groups {
        groupKey: String
        amenityKeysList: GroupIds
      }
    }
  }
`;

export const COMMUNITY_QUERY = gql`
  query Community($id: ID!) {
    community(id: $id) {
      ...Community
    }

    pmsList: propertyManagementSoftwareSystemsList {
      items {
        id
        name
      }
    }
  }

  ${COMMUNITY_FRAGMENT}
`;

export const COMMUNITY_UPDATE_MUTATION = gql`
  mutation CommunityUpdate($data: CommunityUpdateInput!) {
    communityUpdate(data: $data, force: true) {
      ...Community
    }
  }

  ${COMMUNITY_FRAGMENT}
`;
