import React from 'react';
import { Modal } from 'antd';
import { stringifyHref } from '@/shared/utils';

interface DialogProps {
  close: () => void;
  isVisible: boolean;
  calendarLink?: string;
}

export const Dialog: React.FC<DialogProps> = ({ close, isVisible, calendarLink }) => {
  const iframeHeight = 650;

  if (!isVisible) {
    return null;
  }

  const hrefCalendarLink = stringifyHref(calendarLink);

  return (
    <Modal
      className="virtual-tour-modal"
      visible={isVisible}
      onCancel={close}
      footer={null}
      maskClosable={false}
      style={{ height: iframeHeight }}
      width={1000}
      centered
    >
      <iframe width="100%" height={iframeHeight} src={hrefCalendarLink} frameBorder="0" title="calendly schedule" />
    </Modal>
  );
};
