import React from 'react';
import { Modal, Icon } from 'antd';
import styled from '@emotion/styled';
import { useQuery } from 'react-apollo';
import { toast } from 'react-toastify';

import { ModalFooter, Loader } from '@/components';
import { RedAlert } from '@/static/icons';
import { Bidder } from '@/shared/types/graphql';
import { getFullName } from '@/shared/utils';
import { BID_STATUSES } from '@/shared/constants';

import { BIDDER_REJECT_MODAL_BIDDER_QUERY, BIDDER_REJECT_MODAL_BID_MUTATION } from './queries';
import { useMutationSafe } from '@/shared/hooks';

const Container = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  padding: 24px 76px 8px 76px;
  height: 150px;
`;

type DialogProps = {
  refetchQueries?: Array<string>;
  close: () => void;
  isVisible: boolean;
  bidderId?: string;
};

export const Dialog: React.FC<DialogProps> = ({ isVisible, close, bidderId, refetchQueries }) => {
  const { data: bidderData, loading: bidderLoading } = useQuery<{ bidder: Bidder }>(BIDDER_REJECT_MODAL_BIDDER_QUERY, {
    skip: !bidderId,
    variables: {
      id: bidderId,
    },
  });

  const firstName = bidderData?.bidder?.firstName;
  const lastName = bidderData?.bidder?.lastName;
  const fullName = getFullName(firstName, lastName);

  const [bidUpdate, { loading: bidUpdateLoading }] = useMutationSafe(BIDDER_REJECT_MODAL_BID_MUTATION, {
    refetchQueries: refetchQueries,
    onCompleted: () => {
      toast.success('Successfully rejected');
      close();
    },
  });

  const handleBidReject = () => {
    bidUpdate({
      variables: {
        filter: {
          id: bidderId,
        },
        data: {
          status: BID_STATUSES.rejected,
        },
      },
    });
  };

  const title = (
    <div>
      <Icon
        component={RedAlert}
        css={{ marginRight: '8px', fontSize: '20px', lineHeight: '22px', verticalAlign: 'middle' }}
      />
      Reject Renter
    </div>
  );

  return (
    <Modal
      title={title}
      closable={!bidUpdateLoading}
      maskClosable={!bidUpdateLoading}
      className="livo-modal"
      visible={isVisible}
      onCancel={close}
      width="100%"
      style={{ maxWidth: 400 }}
      centered
      footer={
        <ModalFooter
          cancelProps={{
            onClick: close,
            disabled: bidUpdateLoading,
          }}
          okProps={{
            type: 'danger',
            text: 'Yes, Reject',
            disabled: bidUpdateLoading,
            loading: bidUpdateLoading,
            onClick: handleBidReject,
          }}
        />
      }
    >
      {bidderLoading ? (
        <Loader height="150px" stretch />
      ) : (
        <Container>Are you sure you want to reject offer of {fullName}?</Container>
      )}
    </Modal>
  );
};
