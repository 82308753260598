import * as React from 'react';
import { Table, Icon, Tooltip } from 'antd';
import Papa from 'papaparse';
import * as R from 'ramda';

import { RedAlert } from '@/static/icons';
import { Maybe } from '@/shared/types/graphql';
import { ImportValidationErrorsList } from '@/shared/types';
import { Loader } from '@/components';
import { useTableState, UseTableStateResponse } from '@/shared/hooks';
import { PreviewTableContainer, PreviewTableBodyContainer, ErrorIconStyles, LoaderContainer } from './styles';
import { toast } from 'react-toastify';

export type TableCsvPreviewColumn = {
  key: string;
  title: string;
  parser?: (value?: Maybe<string>) => any;
};

type TableCsvPreviewProps = {
  hasHeader: boolean;
  fileCSV: any;
  importErrors?: Maybe<ImportValidationErrorsList>;
  tableColumns: Array<TableCsvPreviewColumn>;
  onParseComplete: (results?: Papa.ParseResult<any>) => void;
  loading?: boolean;
};

const TableCsvPreviewCell = ({ children, className, error, ...restProps }: any) => {
  if (error) {
    className = className + ' validation-error';
  }
  return (
    <td className={className} {...restProps}>
      {children}
      <If condition={!!error}>
        <Tooltip title={error}>
          <Icon component={RedAlert} css={ErrorIconStyles} />
        </Tooltip>
      </If>
    </td>
  );
};

const prepareTableDataSource = (
  parsedData: Array<Array<any>>,
  hasHeader: boolean,
  tableColumns: Array<TableCsvPreviewColumn>,
  importErrors?: Maybe<ImportValidationErrorsList>,
) => {
  const dataSource: Array<any> = parsedData.slice(hasHeader ? 1 : 0).map((row: Array<any>, rowIndex) => {
    return row.reduce(
      (result, current, columnIndex) => {
        result[columnIndex + 1] = current;
        return result;
      },
      {
        0: rowIndex + 1,
      },
    );
  });

  const columns: Array<any> = [
    {
      title: '',
      dataIndex: 0,
      className: 'cutomFirsCell',
      width: '50px',
    },
    ...parsedData[0].map((value: string, columnIndex) => ({
      title: hasHeader ? value : tableColumns[columnIndex]?.title ?? `Column ${columnIndex + 1}`,
      dataIndex: columnIndex + 1,
      width: '300px',
      ellipsis: true,
      onCell: (record: Array<number | string>) => {
        const entityIndex = Number(record[0]) - 1;
        const key = tableColumns[columnIndex]?.key;

        let currentError: string | undefined;

        if (importErrors) {
          const entityErrors = importErrors[entityIndex];
          currentError = R.prop(key, entityErrors);

          if (!currentError && entityErrors) {
            const errorFields = Object.keys(entityErrors).filter(objKey => objKey.startsWith(key));

            if (!R.isEmpty(errorFields)) {
              currentError = errorFields.reduce((msg, objKey) => {
                return `${msg}${entityErrors[objKey]}, `;
              }, '');
            }
          }
        }

        return {
          entityIndex,
          key,
          error: currentError,
        };
      },
    })),
  ];

  return { dataSource, columns };
};

const TableCsvPreview = ({
  hasHeader,
  fileCSV,
  tableColumns,
  importErrors,
  onParseComplete,
  loading,
}: TableCsvPreviewProps) => {
  const [, onChange]: UseTableStateResponse = useTableState();
  const [parsed, setParsed]: [
    Papa.ParseResult<any> | undefined,
    (arg: Papa.ParseResult<any> | undefined) => void,
  ] = React.useState();

  React.useEffect(() => {
    if (fileCSV) {
      Papa.parse(fileCSV, {
        skipEmptyLines: true,
        complete: (results?: Papa.ParseResult<any>) => {
          if (!results?.data || results.data.length === 0) {
            toast.error('Your CSV file is empty. Please fill it in and try again');
          } else {
            setParsed(results);
          }
          onParseComplete(results);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileCSV]);

  const previewData = parsed?.data || [];

  if (R.isEmpty(previewData)) {
    return <Loader stretch />;
  }

  const { dataSource, columns } = prepareTableDataSource(previewData, hasHeader, tableColumns, importErrors);

  return (
    <PreviewTableContainer>
      <Table className="importTableHeader" rowKey="id" columns={columns} bordered />

      <PreviewTableBodyContainer>
        <Table
          components={{
            body: {
              cell: TableCsvPreviewCell,
            },
          }}
          className="importTableBody"
          rowKey="id"
          dataSource={dataSource}
          columns={columns}
          onChange={onChange}
          bordered
        />
      </PreviewTableBodyContainer>
      <If condition={!!loading}>
        <LoaderContainer>
          <Loader stretch />
        </LoaderContainer>
      </If>
    </PreviewTableContainer>
  );
};

export { TableCsvPreview };
