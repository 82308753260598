import React, { useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { useAllowedRoles } from '8base-react-sdk';
import styled from '@emotion/styled';

import { useCurrentCompanyId, useCurrentUser } from '@/shared/hooks';
import { BID_STATUSES, COMMUNITY_STATUSES, OFFER_STATUSES } from '@/shared/constants';
import { USER_ROLES } from 'livo-shared';
import { dialogsContext } from '@/providers';

import { Summary } from './components';
import { PortfolioAndUpcomingTables } from '@/routes/management/company/entity/dashboard/root/components/TablesLayout';
import { DASHBOARD_COMPANY_QUERY } from '@/routes/management/company/entity/dashboard/root/components/Summary/queries';
import { RENTALS_AMOUNT_QUERY } from '@/layouts/ManagementLayout/Sidebar/components/Nav/queries';
import { offersGroupArrayToObject } from '@/layouts/ManagementLayout/Sidebar/components/Nav/utils';
import { ONBOARDING_DIALOG } from '@/dialogs';
import { useUserFilterPermissions } from '@/routes/management/company/entity/dashboard/root/utils';

const Container = styled.div`
  display: grid;
  grid-template-areas: 'Summary' 'Content';
  grid-gap: 24px;
  grid-template-columns: 1fr;
  grid-template-rows: 212px 1fr;
`;

const useRentalsAmountQueryFilter = () => {
  const { companyId } = useCurrentCompanyId();
  const { isAdmin, isCompanyOwner, allowedCommunitiesIds, allowedSingleUnitsIds, isMdu } = useUserFilterPermissions();

  const allowedPropertiesQuery = isMdu
    ? {
        activeOfferSingleUnit: {
          community: {
            id: {
              in: allowedCommunitiesIds,
            },
          },
        },
      }
    : {
        activeOfferSingleUnit: {
          agent: {
            singleUnitAgent: {
              some: {
                id: {
                  in: allowedSingleUnitsIds,
                },
              },
            },
          },
        },
      };

  const all: any = [
    {
      activeOfferSingleUnit: {
        company: {
          id: {
            equals: companyId,
          },
        },
      },
    },
    !(isAdmin || isCompanyOwner) && allowedPropertiesQuery,
  ].filter(Boolean);

  return {
    AND: all,
  };
};

const useCommunityDashboardFilter = () => {
  const { companyId } = useCurrentCompanyId();
  const { isAdmin, isCompanyOwner, allowedCommunitiesIds, allowedSingleUnitsIds, isMdu } = useUserFilterPermissions();
  const allowedPropertiesQuery = isMdu ? allowedCommunitiesIds : allowedSingleUnitsIds;

  const all: any = [
    {
      company: {
        id: {
          equals: companyId,
        },
      },
      status: {
        not_equals: COMMUNITY_STATUSES.draft,
      },
    },
    !(isAdmin || isCompanyOwner) && {
      id: {
        in: allowedPropertiesQuery,
      },
    },
  ].filter(Boolean);

  return {
    AND: all,
  };
};

const useOffersDashboardFilter = () => {
  const { companyId } = useCurrentCompanyId();
  const { isAdmin, isCompanyOwner, allowedCommunitiesIds, allowedSingleUnitsIds, isMdu } = useUserFilterPermissions();

  const allowedPropertiesQuery = isMdu
    ? {
        activeOfferSingleUnit: {
          community: {
            id: {
              in: allowedCommunitiesIds,
            },
          },
        },
      }
    : {
        activeOfferSingleUnit: {
          agent: {
            singleUnitAgent: {
              some: {
                id: {
                  in: allowedSingleUnitsIds,
                },
              },
            },
          },
        },
      };

  const all: any = [
    {
      activeOfferSingleUnit: {
        company: {
          id: {
            equals: companyId,
          },
        },
      },
    },
    !(isAdmin || isCompanyOwner) && allowedPropertiesQuery,
    {
      status: {
        equals: OFFER_STATUSES.pending,
      },
    },
  ].filter(Boolean);

  return {
    AND: all,
  };
};

const useBidsDashboardFilter = () => {
  const { companyId } = useCurrentCompanyId();
  const { isAdmin, isCompanyOwner, allowedCommunitiesIds, allowedSingleUnitsIds, isMdu } = useUserFilterPermissions();

  const allowedPropertiesQuery = isMdu
    ? {
        offer: {
          activeOfferSingleUnit: {
            community: {
              id: {
                in: allowedCommunitiesIds,
              },
            },
          },
        },
      }
    : {
        offer: {
          activeOfferSingleUnit: {
            agent: {
              singleUnitAgent: {
                some: {
                  id: {
                    in: allowedSingleUnitsIds,
                  },
                },
              },
            },
          },
        },
      };

  const all: any = [
    {
      offer: {
        activeOfferSingleUnit: {
          company: {
            id: {
              equals: companyId,
            },
          },
        },
      },
    },
    { status: { equals: BID_STATUSES.winner } },
    !(isAdmin || isCompanyOwner) && allowedPropertiesQuery,
  ].filter(Boolean);

  return {
    AND: all,
  };
};

const useSingleUnitsDashboardFilter = () => {
  const { companyId } = useCurrentCompanyId();

  const { isAdmin, isCompanyOwner, allowedCommunitiesIds, allowedSingleUnitsIds, isMdu } = useUserFilterPermissions();
  const allowedPropertiesQuery = isMdu ? allowedCommunitiesIds : allowedSingleUnitsIds;

  const all: any = [
    {
      company: {
        id: {
          equals: companyId,
        },
      },
      status: {
        not_equals: COMMUNITY_STATUSES.draft,
      },
    },
    !(isAdmin || isCompanyOwner) && {
      id: {
        in: allowedPropertiesQuery,
      },
    },
  ].filter(Boolean);

  return {
    AND: all,
  };
};

export const Dashboard = () => {
  const isAdmin = useAllowedRoles([USER_ROLES.ADMIN_LIVO.roleName]);
  const { openDialog } = React.useContext(dialogsContext);
  const { user } = useCurrentUser();

  useEffect(() => {
    if (!isAdmin && user?.firstTimer) {
      openDialog({ name: ONBOARDING_DIALOG });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const communityDashboardFilter = useCommunityDashboardFilter();
  const offersDashboardFilter = useOffersDashboardFilter();
  const bidsDashboardFilter = useBidsDashboardFilter();
  const singleUnitsDashboardFilter = useSingleUnitsDashboardFilter();

  const { data: dashboardInfo, loading: dashboardInfoLoading } = useQuery(DASHBOARD_COMPANY_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      communityFilter: communityDashboardFilter,
      offersFilter: offersDashboardFilter,
      bidsFilter: bidsDashboardFilter,
      singleUnitsFilter: singleUnitsDashboardFilter,
      first: 8,
    },
  });

  const rentalsAmountQueryFilter = useRentalsAmountQueryFilter();

  const { data: offersData } = useQuery(RENTALS_AMOUNT_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      filter: rentalsAmountQueryFilter,
    },
  });

  const offersResultData = offersGroupArrayToObject(offersData?.offersList?.groups || []);

  return (
    <Container>
      <Summary offersResultData={offersResultData} dashboardInfo={dashboardInfo} />
      <PortfolioAndUpcomingTables portfolioData={dashboardInfo} loading={dashboardInfoLoading} />
    </Container>
  );
};
