import { DRAFT_STATUS, NOT_AVALIABLE, TOAST_SUCCESS_MESSAGE, HIDE_TOAST_ERROR_MESSAGE, ERROR_CODES } from './common';
import { USER_ROLES, APPLICATION_ROLES, APPLICATION_ROLE_KEYS, COMPANY_TYPE_LABELS } from 'livo-shared';
import { COMPANY_TYPES, COMPANY_STATUS_LABELS } from 'livo-shared';
import moment from 'moment';

export * from './offer';
export * from './user';
export * from './singleUnit';
export * from './community';
export * from './amenities';
export { US_STATES_LIST } from './usStatesList';
export {
  DRAFT_STATUS,
  NOT_AVALIABLE,
  TOAST_SUCCESS_MESSAGE,
  HIDE_TOAST_ERROR_MESSAGE,
  ERROR_CODES,
  USER_ROLES,
  APPLICATION_ROLES,
  APPLICATION_ROLE_KEYS,
  COMPANY_TYPES,
  COMPANY_TYPE_LABELS,
  COMPANY_STATUS_LABELS,
};

export const MDU_TYPES: Record<string, any> = {
  ownerOperator: 'Owner Operator',
  thirdPartyManaged: 'Third Party Managed',
  seniorHousing: 'Senior Housing',
  studentHousing: 'Student Housing',
  mixedPortfolio: 'Mixed Portfolio',
  shortTermRentals: 'Short Term Rentals',
};

export const BILLING_TYPES: Record<string, any> = {
  corporate: { label: 'Corporate', value: 'corporate' },
  community: { label: 'Community', value: 'community' },
};

export const OFFER_STATUS_LABELS = {
  [DRAFT_STATUS]: 'Draft',
  pending: 'Upcoming',
  live: 'Live',
  leased: 'Awarded',
  ended: 'Ended',
  secured: 'Secured',
  canceled: 'Canceled',
  closed: 'Closed',
};

export type OfferStatus = keyof typeof OFFER_STATUS_LABELS;

export const OFFER_STATUSES: Record<OfferStatus, OfferStatus> = {
  draft: DRAFT_STATUS,
  pending: 'pending',
  live: 'live',
  leased: 'leased',
  ended: 'ended',
  secured: 'secured',
  canceled: 'canceled',
  closed: 'closed',
};

export const OFFER_COMBINED_STATUS_LABELS = {
  none: 'None',
  draft: 'Draft',
  pending: 'Auction Upcoming',
  rejected: 'Your offer was rejected',
  canceled: 'Auction Canceled',
  leased: 'Property Leased',
  ended: 'Auction Ended',
  awarded: 'Property Awarded',
  live: 'Live',
  'you-accept': 'Accept Offer',
  'you-secured': 'You won',
  'you-won': 'You won',
};

export type OfferCombinedStatus = keyof typeof OFFER_COMBINED_STATUS_LABELS;

export const OFFER_COMBINED_STATUSES: Record<OfferCombinedStatus, OfferCombinedStatus> = {
  none: 'none',
  draft: 'draft',
  pending: 'pending',
  rejected: 'rejected',
  canceled: 'canceled',
  leased: 'leased',
  ended: 'ended',
  awarded: 'awarded',
  live: 'live',
  'you-accept': 'you-accept',
  'you-secured': 'you-secured',
  'you-won': 'you-won',
};

export const BID_STATUSES_LABELS = {
  bidder: 'Prospect',
  winner: 'Winner',
  rejected: 'Rejected',
  secured: 'Secured',
  canceled: 'Canceled',
};

export const WESTERN_REPORTING_STATUSES_LABELS = {
  'x:app_pending': 'Applicant Pending',
  'x:applicant-pending': 'Applicant Pending',
  'x:applicant-ready': 'Applicant Ready',
  'x:draft': 'Draft',
  'x:canceled': 'Canceled',
  'x:client-message': 'Client Message',
  'x:error': 'Error',
  'x:partial': 'Partial',
  'x:pending': 'Report Pending',
  'x:ready': 'Report Ready',
  'x:archived': 'Archived',
};

export const TERMS_OF_LEASE_MAX_AMOUNT = 60;
export const TERMS_OF_LEASE_MIN_AMOUNT = 12;

export const COLOR_BY_BID_STATUS: Record<string, string> = {
  bidder: 'orange',
  winner: 'green',
  rejected: 'red',
  secured: 'orange',
};

export const BID_STATUSES: Record<string, keyof typeof BID_STATUSES_LABELS> = {
  bidder: 'bidder',
  winner: 'winner',
  rejected: 'rejected',
  secured: 'secured',
  canceled: 'canceled',
};

export const USER_STATUS_LABELS = {
  active: 'Active',
  inactive: 'Inactive',
  invitationPending: 'Invited',
};

export const USER_STATUSES = {
  active: 'active',
  inactive: 'inactive',
  invitationPending: 'invitationPending',
};

export const RENTER_STATUS_LABELS = {
  pre: 'Pre-Qualified',
  qualified: 'Qualified',
  rejected: 'Rejected',
};

export const RENTER_STATUSES: Record<string, keyof typeof RENTER_STATUS_LABELS> = {
  pre: 'pre',
  qualified: 'qualified',
  rejected: 'rejected',
};

export const COMPANY_STATUSES: Record<string, keyof typeof COMPANY_STATUS_LABELS> = {
  draft: DRAFT_STATUS,
  active: 'active',
  inactive: 'inactive',
  blocked: 'blocked',
};

export const TIMEZONE_NAMES = moment.tz.names();

export const TIMEZONE_OPTIONS = [
  { label: 'Eastern Standard', value: 'US/Eastern' },
  { label: 'Central', value: 'US/Central' },
  { label: 'Mountain', value: 'US/Mountain' },
  { label: 'Pacific', value: 'US/Pacific' },
  { label: 'Alaska', value: 'US/Alaska' },
];

export const END_OFFER_TYPES = {
  unavailable: 'unavailable',
  timeIsUp: 'timeIsUp',
  other: 'other',
};

type endOfferTypes = keyof typeof END_OFFER_TYPES;

export const END_OFFER_REASONS: Record<endOfferTypes, string> = {
  unavailable: 'Property became unavailable',
  timeIsUp: 'Bidding time has expired',
  other: 'Other',
};

type SinglePluralPair = {
  single: string;
  plural: string;
};

export const SINGLE_UNIT_NAMES: Record<string, SinglePluralPair> = {
  singleUnit: {
    single: 'Single Unit',
    plural: 'Single Units',
  },
  unit: {
    single: 'Unit',
    plural: 'Units',
  },
  property: {
    single: 'Property',
    plural: 'Properties',
  },
  doorsManaged: {
    single: 'Door Managed',
    plural: 'Doors Managed',
  },
};

export type Dictionary = {
  singleUnit: SinglePluralPair;
};

export const DICTIONARY_BY_COMPANY_TYPE: Record<string, Dictionary> = {
  [COMPANY_TYPES.selfManaged]: {
    singleUnit: SINGLE_UNIT_NAMES.property,
  },
  [COMPANY_TYPES.broker]: {
    singleUnit: SINGLE_UNIT_NAMES.property,
  },
  [COMPANY_TYPES.mdu]: {
    singleUnit: SINGLE_UNIT_NAMES.unit,
  },
  [COMPANY_TYPES.singleFamily]: {
    singleUnit: SINGLE_UNIT_NAMES.property,
  },
};

export const VIRTUAL_SHOWINGS_LABELS = {
  livo: 'Livo',
  thirdParty: 'Third-Party',
  none: 'None',
};

export const SCREENING_PROCESS_LABELS = {
  no: 'No',
  inHouse: 'In-House',
  thirdParty: 'Third-Party',
};

export const ONLINE_APPLICATION_PROCESS_LABELS = {
  no: 'No',
  inHouse: 'In-House',
  thirdParty: 'Third-Party',
};

export const ILS_LIST = [
  'Zillow',
  'Apartments.com',
  'Rent.com',
  'HotPads',
  'Apartment Guide',
  'Forrent.com',
  'Tenant Turner',
  'Craigslist',
];

export const PMS_LIST = [
  'Yardi RENTCafe',
  'Entrata',
  'AppFolio',
  'Yardi Voyager',
  'Buildium',
  'ResMan',
  'Rent Manager',
  'RealPage',
  'MRI',
];

export type ScreeningProcess = keyof typeof SCREENING_PROCESS_LABELS;

export const SCREENING_PROCESS: Record<ScreeningProcess, ScreeningProcess> = {
  no: 'no',
  inHouse: 'inHouse',
  thirdParty: 'thirdParty',
};

export type OnlineApplicationProcess = keyof typeof ONLINE_APPLICATION_PROCESS_LABELS;

export const ONLINE_APPLICATION_PROCESS: Record<OnlineApplicationProcess, OnlineApplicationProcess> = {
  no: 'no',
  inHouse: 'inHouse',
  thirdParty: 'thirdParty',
};

export type VirtualShowings = keyof typeof VIRTUAL_SHOWINGS_LABELS;

export const VIRTUAL_SHOWINGS: Record<VirtualShowings, string> = {
  livo: 'livo',
  thirdParty: 'thirdParty',
  none: 'none',
};

export const SHOWING_TYPE_LABELS = {
  inPerson: 'In Person Showing',
  selfShowing: 'Self Showing',
  virtualShowing: 'Virtual Showing',
};

export type ShowingType = keyof typeof SHOWING_TYPE_LABELS;

export const SHOWING_TYPE: Record<ShowingType, ShowingType> = {
  inPerson: 'inPerson',
  selfShowing: 'selfShowing',
  virtualShowing: 'virtualShowing',
};

export const RANKING_LABELS: Record<string, string> = {
  RANKING_WEIGHTING_FOR_LONGER_STAY: 'Weighting For Longer Stay',
  RANKING_WEIGHTING_FOR_SHORTER_STAY: 'Weighting For Shorter Stay',
  RANKING_WEIGHTING_FOR_RENTAL_INCREASE: 'Weighting For Rental Increase',
  RANKING_ANNUAL_RENTAL_INCREASE: 'Annual Rental Increase',
};
