import React, { useEffect, useState } from 'react';
import { ModalFooter } from '@/components';
import { Icon, Modal, Row } from 'antd';
import { RedAlert } from '@/static/icons';
import styled from '@emotion/styled';
import { COMMUNITY_DELETE_MUTATION } from './queries';
import { SINGLE_UNIT_DELETE_MUTATION } from './queries';
import { toast } from 'react-toastify';
import { useMutationSafe } from '@/shared/hooks';

type RecordType = 'property' | 'singleUnit';

type DialogProps = {
  records?: Array<string>;
  recordType?: RecordType;
  close: () => void;
  isVisible: boolean;
  options?: {
    refetchQueries?: Array<string>;
    awaitRefetchQueries?: boolean;
  };
};

const Container = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  padding: 24px 56px 8px 56px;
  height: 130px;
  justify-content: center;
`;

export const Dialog: React.FC<DialogProps> = ({ records, recordType, close, isVisible, options = {} }) => {
  const [deleteCounter, setDeleteCounter] = useState(0);
  useEffect(() => {
    if (records?.length === deleteCounter) {
      toast.success('Successfully Deleted');
      setDeleteCounter(0);
      close();
    }
  }, [records, deleteCounter, recordType, close]);

  const [communityDelete, { loading: communityDeleting }] = useMutationSafe(COMMUNITY_DELETE_MUTATION, {
    onCompleted: () => setDeleteCounter(deleteCounter + 1),
    onError: error => toast.error(error),
  });

  const [singleUnitDelete, { loading: singleUnitDeleting }] = useMutationSafe(SINGLE_UNIT_DELETE_MUTATION, {
    onCompleted: () => setDeleteCounter(deleteCounter + 1),
    onError: error => toast.error(error),
  });

  const deleting = singleUnitDeleting || communityDeleting;

  const onDelete = () => {
    records?.map(async (id: string) => {
      switch (recordType) {
        case 'property':
          await communityDelete({
            variables: {
              id,
            },
            ...options,
          });
          return true;
        case 'singleUnit':
          await singleUnitDelete({
            variables: {
              id,
            },
            ...options,
          });
          return true;
        default:
          return false;
      }
    });
  };

  const title = (
    <Row>
      <Icon
        component={RedAlert}
        css={{ marginRight: '8px', fontSize: '20px', lineHeight: '22px', verticalAlign: 'middle' }}
      />
      Delete Records
    </Row>
  );

  return (
    <Modal
      title={title}
      closable={!deleting}
      maskClosable={!deleting}
      className="livo-modal"
      visible={isVisible}
      onCancel={close}
      width="100%"
      style={{ maxWidth: 500 }}
      centered
      footer={
        <ModalFooter
          cancelProps={{
            onClick: close,
            disabled: deleting,
          }}
          okProps={{
            type: 'danger',
            text: 'Yes, Delete',
            disabled: deleting,
            loading: deleting,
            onClick: onDelete,
          }}
        />
      }
    >
      <Container>
        <span>Are you sure you want to delete selected records?</span>
      </Container>
    </Modal>
  );
};
