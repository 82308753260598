import React from 'react';

import * as R from 'ramda';
import {
  Maybe,
  SingleUnitCreateManyInput,
  SingleUnitImportResponse,
  MutationSingleUnitImportArgs,
} from '@/shared/types/graphql';
import { parseString } from '@/shared/utils';
import { ACTIVE_STATUS } from 'livo-shared';
import { toast } from 'react-toastify';
import { ImportValidationErrorsList, ImportValidationErrorDetails } from '@/shared/types';
import { parseImportErrors } from '@/shared/utils';
import { HIDE_TOAST_ERROR_MESSAGE, ERROR_CODES } from '@/shared/constants';
import { useMutationSafe } from '@/shared/hooks';
import { SINGLE_UNITS_IMPORT_MODAL_MUTATION } from './queries';
import { PrepareImportDataArgs } from './types';

export const prepareImportData = ({ hasHeader, parsed, tableColumns, communityId }: PrepareImportDataArgs) => {
  return parsed?.data.slice(hasHeader ? 1 : 0).map(value => {
    return tableColumns.reduce(
      (result, column, index) => {
        const { key, parser } = column;

        const path = key.split('.');

        const resultValue = parser ? parser(parseString(value[index])) : parseString(value[index]);

        return R.assocPath(path, resultValue, result);
      },
      {
        status: ACTIVE_STATUS,
        address: { country: 'United States' },
        ...(communityId
          ? {
              community: {
                connect: {
                  id: communityId,
                },
              },
            }
          : {}),
      } as SingleUnitCreateManyInput,
    );
  }) as Maybe<SingleUnitCreateManyInput>[];
};

export const useImportUnits = () => {
  const [importErrors, setImportErrors] = React.useState<Maybe<ImportValidationErrorsList> | undefined>(null);
  const [importedUnits, setImportedUnits] = React.useState(0);

  const [singleUnitImport, { loading }] = useMutationSafe<
    { singleUnitImport: SingleUnitImportResponse },
    MutationSingleUnitImportArgs
  >(SINGLE_UNITS_IMPORT_MODAL_MUTATION, {
    context: {
      [HIDE_TOAST_ERROR_MESSAGE]: true,
    },
    onCompleted: ({ singleUnitImport }) => {
      const { validationSuccess, importSuccess } = singleUnitImport;
      if (validationSuccess && !importSuccess) {
        setImportErrors(null);
        toast.success('Your CSV file successfully passed the validation');
      }
      if (importSuccess) {
        setImportedUnits(importedUnits + 1);
      }
    },
    onError: error => {
      const errorCode = R.path(['graphQLErrors', 0, 'code'], error);
      if (errorCode === ERROR_CODES.ImportValidationErrorCode) {
        const details = R.path(['graphQLErrors', 0, 'details'], error) as ImportValidationErrorDetails | undefined;
        const importErrors = parseImportErrors(details);
        setImportErrors(importErrors);
        toast.error('Your CSV file has validation errors. Fix them and try again.');
      } else {
        toast.error('Your CSV file has an unknown errors.');
      }
    },
  });

  return {
    singleUnitImport,
    importErrors,
    importedUnits,
    singleUnitImportLoading: loading,
  };
};
