import moment from 'moment';

import { Maybe } from '@/shared/types/graphql';

export const parseDate = (value?: Maybe<string>) => {
  if (!value) {
    return undefined;
  }
  const date = moment(value);

  return date.isValid() ? date.format('YYYY-MM-DD') : undefined;
};
