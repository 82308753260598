import React from 'react';
import { Field } from '@8base-react/forms';

import { Grid, InputField, RadioGroupField, SelectField } from '@/components';
import {
  ILS_LIST,
  ONLINE_APPLICATION_PROCESS,
  ONLINE_APPLICATION_PROCESS_LABELS,
  PMS_LIST,
  SCREENING_PROCESS,
  SCREENING_PROCESS_LABELS,
  VIRTUAL_SHOWINGS_LABELS,
} from '@/shared/constants';
import { Radio } from 'antd';

export const SoftwareAndServices = ({ values }: any) => {
  const pmsList = React.useMemo(() => PMS_LIST.map(el => ({ value: el, label: el })), []);

  const ilsList = React.useMemo(() => ILS_LIST.map(el => ({ value: el, label: el })), []);

  const coercedVirtualShowings = React.useMemo(
    () => Object.entries(VIRTUAL_SHOWINGS_LABELS).map(([value, label]) => ({ value, label })),
    [],
  );

  const coercedOnlineApplicationProcess = React.useMemo(
    () => Object.entries(ONLINE_APPLICATION_PROCESS_LABELS).map(([value, label]) => ({ value, label })),
    [],
  );

  const coercedApplicationsScreening = React.useMemo(
    () => Object.entries(SCREENING_PROCESS_LABELS).map(([value, label]) => ({ value, label })),
    [],
  );

  return (
    <Grid.Layout gap="16px" css={{ marginBottom: 16 }}>
      <Grid.Box>
        <Field
          label="Do You Use Property Management Software Systems?*"
          name="isUsingPms"
          component={RadioGroupField}
          stretch
          initialValue={false}
        >
          <Radio value={false}>No</Radio>
          <Radio value={true}>Yes</Radio>
        </Field>
        <If condition={values?.isUsingPms}>
          <Field
            label="Property Management Software System*"
            name="companyPropertyManagementSoftwareSystems"
            placeholder="Select"
            options={pmsList}
            component={SelectField}
            mode="tags"
            format={value => value && value.map((el: string) => el)}
            stretch
          />
        </If>
      </Grid.Box>
      <Grid.Box>
        <Field label="Do You Use ILS/MLS?*" name="isUsingIls" component={RadioGroupField} stretch initialValue={false}>
          <Radio value={false}>No</Radio>
          <Radio value={true}>Yes</Radio>
        </Field>
        <If condition={values?.isUsingIls}>
          <Field
            label="ILS/MLS*"
            name="companyListingServices"
            placeholder="Select"
            options={ilsList}
            component={SelectField}
            mode="tags"
            format={value => value && value.map((el: string) => el)}
            stretch
          />
        </If>
      </Grid.Box>
      <Grid.Box>
        <Field
          label="Do You Use Online Application Process?"
          name="onlineApplicationProcess"
          component={RadioGroupField}
          stretch
        >
          {coercedOnlineApplicationProcess.map(el => (
            <Radio key={el.value} value={el.value}>
              {el.label}
            </Radio>
          ))}
        </Field>
        <If condition={values.onlineApplicationProcess === ONLINE_APPLICATION_PROCESS.thirdParty}>
          <Field
            label="Third-Party Online Application Name"
            name="thirdPartyOnlineApplicationName"
            placeholder="Type application name"
            autoComplete="disabled"
            component={InputField}
            stretch
          />
        </If>
      </Grid.Box>
      <Grid.Box>
        <Field label="Do You Use Screening Process?" name="screeningProcess" component={RadioGroupField} stretch>
          {coercedApplicationsScreening.map(el => (
            <Radio key={el.value} value={el.value}>
              {el.label}
            </Radio>
          ))}
        </Field>
        <If condition={values.screeningProcess === SCREENING_PROCESS.thirdParty}>
          <Field
            label="Third-Party screening company name"
            name="thirdPartyScreeningCompanyName"
            placeholder="Type company name"
            autoComplete="disabled"
            component={InputField}
            stretch
          />
        </If>
      </Grid.Box>
      <Grid.Box>
        <Field
          label="Virtual Showings"
          name="virtualShowings"
          placeholder="Select"
          options={coercedVirtualShowings}
          component={SelectField}
          stretch
          allowClear
        />
      </Grid.Box>
    </Grid.Layout>
  );
};
