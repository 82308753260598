import React from 'react';
import { Button } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import { useAuth } from '8base-react-sdk';

import { usePermissions } from '@/shared/hooks';
import { PoweredBy } from '@/components';
import styled from '@/theme/styled';
import { ReactComponent as LivoSecureColoredLogo } from '@/static/LivoSecureColor.svg';
import { RenterContext } from '@/providers';
import { Navigation } from './Navigation';
import Store from '@/shared/utils/Store';
import { storageType, storageKey, redirectType } from '@/shared/types/store';

export const RenterLayoutTag = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
  background-color: #f4f5f6;
`;

export const RenderHeaderTag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: ${({ theme }) => `${theme.vars.headerHeight}`};
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  background-color: #fff;
  border-bottom: 1px solid #f4f5f6;
`;

export const RenderHeaderNavTag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;

  & > *:not(:first-child) {
    margin-left: 16px;
  }

  @media (max-width: ${({ theme }) => theme.vars.breakpoints.sm.max}) {
    & > *:not(:first-child) {
      margin-left: 8px;
    }
  }
`;

export const RenterLogo = styled(LivoSecureColoredLogo)`
  display: flex;
  flex-direction: row;
  height: 32px;
  width: 141px;
  color: #323c47;
`;

export const RenderLogInButton = styled(Button)`
  height: 40px;
  border: 1px solid #f37a22;
  color: #f37a22;
`;

export const RenderContentTag = styled.div`
  display: grid;
  grid-template-rows: 1fr ${({ theme }) => `${theme.vars.poweredByHeight}`};
  padding: 24px 24px 0 24px;
  width: 100%;
  min-height: ${({ theme }) => `calc(100vh - ${theme.vars.headerHeight})`};

  @media (max-width: ${props => props.theme.vars.breakpoints.sm.max}) {
    padding: 0;
  }
`;

export const RenterInnerContentTag = styled.div`
  display: grid;
  padding-bottom: 24px;
`;

type RenterLayoutProps = {
  children: React.ReactNode;
};

export const RenterLayout: React.FC<RenterLayoutProps> = ({ children }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const store = new Store({ storageType: storageType.Session, storageKey: storageKey.Redirect });

  const onLogin = React.useCallback(
    (params?: { secureNow?: boolean; offerId?: string }) => {
      store.set(redirectType.afterLogin, { ...params, pathname });
      history.replace('/auth');
    },
    [pathname, history, store],
  );

  const { isAuthorized } = useAuth();

  const { isRenter } = usePermissions();

  return (
    <RenterLayoutTag>
      <RenterContext.Provider value={{ onLogin }}>
        <RenderHeaderTag>
          <RenterLogo />
          <RenderHeaderNavTag>
            {isAuthorized ? (
              <Navigation isRenter={isRenter} />
            ) : (
              <RenderLogInButton onClick={() => onLogin()}>Log In</RenderLogInButton>
            )}
          </RenderHeaderNavTag>
        </RenderHeaderTag>
        <RenderContentTag>
          <RenterInnerContentTag>{children}</RenterInnerContentTag>
          <PoweredBy />
        </RenderContentTag>
      </RenterContext.Provider>
    </RenterLayoutTag>
  );
};
