import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { Modal, Divider, Radio } from 'antd';
import { toast } from 'react-toastify';
import { useQuery } from 'react-apollo';
import { Field, Form } from '8base-react-sdk';

import { dialogsContext } from '@/providers';
import { DRAFT_STATUS, OFFER_STATUSES } from '@/shared/constants';
import { TIMEZONE_OPTIONS } from 'livo-shared';
import { OfferUpdateInput, Offer, Maybe } from '@/shared/types/graphql';
import {
  InputNumberField,
  SelectField,
  SelectUser,
  DatePickerField,
  SwitchField,
  PropertyInfo,
  ModalFooter,
  DateAndTimePickerField,
  Scrollable,
  AsyncContent,
  RadioGroupField,
  Info,
  InputField,
} from '@/components';
import { UPDATE_OFFER_MODAL_OFFER_UPDATE, UPDATE_OFFER_MODAL_OFFER_QUERY } from './queries';
import { FormContainer, FormTitle, FullRowBlock, FieldWrapper } from './styled';
import { validateEntityDataByDraft } from '@/shared/utils';
import { OfferSchemaValidation } from '@/schemaValidators';
import { omit } from 'ramda';
import { useMutationSafe, useDialogState } from '@/shared/hooks';
import { OFFER_END_DIALOG, OFFER_CANCEL_CONFIRM_DIALOG } from '../../index';
import { useDataSingleUnit } from '../helpers';

export const OFFER_UPDATE_DIALOG = 'OFFER_UPDATE_DIALOG';

export const OfferUpdateDialog = () => {
  const { close, closeWithConfirm, isVisible, props } = useDialogState(OFFER_UPDATE_DIALOG);
  const { openDialog } = React.useContext(dialogsContext);

  const offerId = props?.offerId as Maybe<string>;
  const singleUnitId = props?.singleUnitId as Maybe<string>;

  const [offerUpdate] = useMutationSafe(UPDATE_OFFER_MODAL_OFFER_UPDATE, {
    refetchQueries: [...(props?.refetchQueries || []), 'UpdateOfferModalOffer'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      close();
      toast.success('Successfully updated');
    },
  });

  const openEndOfferModal = React.useCallback(
    () =>
      openDialog({
        name: OFFER_END_DIALOG,
        props: { offerId, onCompletedAction: close },
      }),
    [close, offerId, openDialog],
  );

  const { data: offerData, loading: offerLoading } = useQuery<{ offer: Offer }>(UPDATE_OFFER_MODAL_OFFER_QUERY, {
    skip: !offerId,
    variables: {
      id: offerId,
    },
  });

  const { singleUnit, singleUnitLoading } = useDataSingleUnit({ singleUnitId });

  const offer = offerData?.offer || {};

  const openofferCancelConfirmModal = React.useCallback(
    () =>
      openDialog({
        name: OFFER_CANCEL_CONFIRM_DIALOG,
        props: { offerId, singleUnitId, status: offer?.status },
      }),
    [offer, offerId, openDialog, singleUnitId],
  );

  const offerCompanyUsers = React.useMemo(() => singleUnit?.company?.user?.items || [], [singleUnit]);

  const onSubmit = async (data: OfferUpdateInput) => {
    if (data.moveinDate) {
      data.moveinDate = moment(data.moveinDate).format('YYYY-MM-DD');
    }

    if (!data.secureNow) {
      data.secureNowAmount = null;
    }

    if (data?.contact?.connect?.id) {
      const [userId] = data.contact.connect.id.split(',');
      data.contact.connect.id = userId;
    }

    await offerUpdate({
      variables: {
        filter: {
          id: offerId,
        },
        data,
      },
    });
  };

  const initialValues = React.useMemo(() => {
    return omit(['numberId'], offer);
  }, [offer]);

  const isDraft = singleUnit?.activeOffer?.status === DRAFT_STATUS;

  const showEndOfferButton =
    offer?.status === OFFER_STATUSES.live ||
    offer?.status !== OFFER_STATUSES.ended ||
    offer?.status !== OFFER_STATUSES.closed ||
    offer?.status !== OFFER_STATUSES.secured;

  if (!isVisible) {
    return null;
  }

  return (
    <Form
      type="UPDATE"
      tableSchemaName="Offer"
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={values => validateEntityDataByDraft(OfferSchemaValidation, values)}
    >
      {({ handleSubmit, form, pristine, modified, values, submitting }) => {
        const showStartsIn =
          !values.startImmediate ||
          (values.startImmediate &&
            !(offer?.status === OFFER_STATUSES.pending || offer?.status === OFFER_STATUSES.draft));

        if (!Boolean(values.leaseContractSelect)) {
          values.leaseContractSelect = offer?.leaseLink ? 'link' : 'upload';
        }

        return (
          <Modal
            title="Edit Livo Transaction"
            className="livo-modal"
            visible={isVisible}
            onCancel={pristine ? close : closeWithConfirm}
            width="635px"
            afterClose={form.reset}
            centered
            footer={
              <ModalFooter
                showLeftAction={!singleUnitLoading && (isDraft || showEndOfferButton)}
                leftActionProps={
                  isDraft
                    ? {
                        text: 'Save as Draft',
                        disabled: (pristine && !isDraft) || submitting,
                        onClick: event => {
                          form.change('status', OFFER_STATUSES.draft);

                          return handleSubmit(event);
                        },
                      }
                    : {
                        text:
                          offer?.status === OFFER_STATUSES.ended || offer?.status === OFFER_STATUSES.closed
                            ? 'Cancel Livo Offer'
                            : 'End Livo Offer',
                        onClick:
                          offer?.status === OFFER_STATUSES.ended || offer?.status === OFFER_STATUSES.closed
                            ? openofferCancelConfirmModal
                            : openEndOfferModal,
                        color: '#ff3100',
                        disabled: submitting,
                      }
                }
                cancelProps={{
                  disabled: submitting,
                  onClick: pristine ? close : closeWithConfirm,
                }}
                okProps={{
                  type: 'primary',
                  text: isDraft ? 'Enable Livo Transaction' : 'Save Changes',
                  loading: submitting,
                  onClick: event => {
                    if (isDraft) {
                      form.change('status', OFFER_STATUSES.pending);
                    }

                    return handleSubmit(event);
                  },
                }}
              />
            }
          >
            <AsyncContent loading={singleUnitLoading || offerLoading} height="500px">
              <Scrollable>
                <PropertyInfo singleUnit={singleUnit} />
                <Divider />
                <FormContainer>
                  <FullRowBlock>
                    <FormTitle>Livo Transaction #{offer.numberId}</FormTitle>
                  </FullRowBlock>
                  <Field
                    label="Rent Amount*"
                    name="rentAmount"
                    component={InputNumberField}
                    type="number"
                    stretch
                    min={1}
                    prefix="$"
                    prefixPadding={19}
                  />
                  <Field
                    label="Incremental Bid Amount*"
                    name="incrementedBidAmount"
                    component={InputNumberField}
                    stretch
                    min={1}
                    prefix="$"
                    prefixPadding={19}
                  />
                  <Field
                    label="Security Deposit*"
                    name="securityDeposite"
                    component={InputNumberField}
                    stretch
                    min={1}
                    prefix="$"
                    prefixPadding={19}
                  />
                  <Field
                    label="Allow Renter to increase Security Deposit"
                    name="securityDepositeEditable"
                    component={RadioGroupField}
                    stretch
                  >
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Field>
                  <FieldWrapper>
                    <Field
                      label="Move-in Date*"
                      name="moveinDate"
                      component={DatePickerField}
                      stretch
                      placeholder="MM/DD/YYYY"
                      displayFormat="MM/DD/YYYY"
                      timezone={values.timezone}
                    />
                  </FieldWrapper>
                  <Field
                    label="Allow Renter to change Move-in Date"
                    name="moveinDateEditable"
                    component={RadioGroupField}
                    stretch
                  >
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Field>
                  <Field
                    label="Timezone"
                    name="timezone"
                    component={SelectField}
                    options={TIMEZONE_OPTIONS}
                    showSearch
                    stretch
                  />
                  <Field
                    label="Lease Terms*"
                    name="termsOfLease"
                    component={InputNumberField}
                    min={1}
                    prefix="Months:"
                    prefixPadding={70}
                    stretch
                  />
                  <Field
                    label="Minimum length of lease*"
                    name="minimumLeaseLength"
                    component={InputNumberField}
                    min={1}
                    prefix="Months:"
                    prefixPadding={70}
                    stretch
                  />
                  <Field
                    label="Maximum length of lease*"
                    name="maximumLeaseLength"
                    component={InputNumberField}
                    min={1}
                    prefix="Months:"
                    prefixPadding={70}
                    stretch
                  />
                  {(offer?.status === OFFER_STATUSES.pending || offer?.status === OFFER_STATUSES.draft) && (
                    <FullRowBlock>
                      <Field
                        label="Livo Transaction Start Date*"
                        name="startImmediate"
                        stretch
                        data-e2e-id="modal.offer.create.immediate"
                        render={({ input, ...restProps }) => {
                          const handleOnChange = ({ target: { value } }: any) => {
                            if (!value) {
                              form.change(
                                'startsIn',
                                moment
                                  .tz(offer.startsIn, values.timezone)
                                  .tz(moment.tz.guess(), true)
                                  .toISOString(),
                              );
                            } else {
                              form.change('startsIn', undefined);
                            }
                            input.onChange(value);
                          };
                          return (
                            <RadioGroupField {...restProps} input={{ ...input, onChange: handleOnChange }}>
                              <Radio value={true}>Immediate</Radio>
                              <Radio value={false}>Set Livo Transaction Start Date</Radio>
                            </RadioGroupField>
                          );
                        }}
                      />
                    </FullRowBlock>
                  )}
                  {showStartsIn && (
                    <FullRowBlock>
                      <Field
                        name="startsIn"
                        component={DateAndTimePickerField}
                        datePickerProps={{
                          label: 'Livo Transaction Start Date*',
                          stretch: true,
                          placeholder: 'MM/DD/YYYY',
                          format: 'MM/DD/YYYY',
                        }}
                        timePickerProps={{
                          label: 'Livo Transaction Start Time*',
                          stretch: true,
                          use12Hours: true,
                          format: 'h:mm A',
                        }}
                        timezone={values.timezone}
                      />
                    </FullRowBlock>
                  )}
                  <FullRowBlock>
                    <Field
                      name="endsIn"
                      component={DateAndTimePickerField}
                      datePickerProps={{
                        label: 'Livo Transaction End Date*',
                        stretch: true,
                        placeholder: 'MM/DD/YYYY',
                        format: 'MM/DD/YYYY',
                      }}
                      timePickerProps={{
                        label: 'Livo Transaction End Time*',
                        stretch: true,
                        use12Hours: true,
                        format: 'h:mm A',
                      }}
                      timezone={values.timezone}
                    />
                  </FullRowBlock>
                  <Field
                    label={
                      <span css={{ marginTop: '15px', display: 'block' }}>
                        SecureNow™
                        <Info title="SecureNow™ is the quickest way to secure your new home. If you would like the bidding process to end and be awarded the lease, simply click SecureNow™ and start the leasing signing process." />
                      </span>
                    }
                    name="secureNow"
                    component={SwitchField}
                  />
                  <Field
                    label={
                      <span css={{ marginTop: '15px', display: 'block' }}>
                        Re-enable Transaction
                        <Info title="Re-enable automatically if no bids have been placed" />
                      </span>
                    }
                    stretch
                    name="reenableOffer"
                    component={SwitchField}
                    data-e2e-id="modal.offer.create.reenableOffer"
                  />
                  {values.secureNow && (
                    <FullRowBlock>
                      <Field
                        label="SecureNow™ Rent Amount*"
                        name="secureNowAmount"
                        component={InputNumberField}
                        stretch
                        min={1}
                        prefix="$"
                        prefixPadding={19}
                      />
                    </FullRowBlock>
                  )}
                  <FullRowBlock>
                    <FormTitle css={{ marginTop: '19px' }}>Renter screening</FormTitle>
                  </FullRowBlock>
                  <FullRowBlock>
                    <Field
                      label="Let Livo manage the rental application and screening process?"
                      name="livoManagesScreening"
                      component={RadioGroupField}
                      stretch
                      data-e2e-id="modal.offer.create.livoManagesScreening"
                    >
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No, I want to use my own screening</Radio>
                    </Field>
                  </FullRowBlock>
                  <FullRowBlock>
                    <Field
                      name="applicationLink"
                      label="Application Form link*"
                      autoComplete="disabled"
                      component={InputField}
                      data-e2e-id="modal.offer.create.screeningLink"
                      placeholder="e.g. https://www.livo.io, http://livo.com, etc."
                    />
                  </FullRowBlock>
                  <FullRowBlock>
                    <FormTitle css={{ marginTop: '19px' }}>Contact</FormTitle>
                  </FullRowBlock>
                  <FullRowBlock>
                    <Field
                      label="Please select responsible contact person*"
                      name="contact"
                      placeholder="Select contact"
                      stretch
                      showSearch
                      render={({ input, ...rest }) => {
                        const setValue = input.onChange;

                        const handleOnChange = (value: string) => {
                          setValue(value);

                          const [userId] = value.split(',');
                          const user = R.find(R.propEq('id', userId), offerCompanyUsers as Array<any>);
                          const calendarLink = R.pathOr('', ['calendarLink'], user);

                          form.change('calendarLink', calendarLink);
                        };

                        input.onChange = handleOnChange;

                        return <SelectUser input={input} users={offerCompanyUsers} {...rest} />;
                      }}
                    />
                  </FullRowBlock>

                  <If condition={values.contact}>
                    <FullRowBlock>
                      <FormTitle css={{ marginTop: '19px' }}>Calendar Link (to schedule showings)</FormTitle>
                    </FullRowBlock>
                    <FullRowBlock>
                      <Field name="calendarLink" autoComplete="disabled" component={InputField} />
                    </FullRowBlock>
                  </If>

                  <Field
                    label="Timeframe for Renter to sign lease*"
                    name="timeframeToSignContract"
                    component={InputNumberField}
                    stretch
                    min={1}
                    defaultValue={5}
                    prefix="Days:"
                    prefixPadding={55}
                    data-e2e-id="modal.offer.create.rentAmount"
                  />
                </FormContainer>
              </Scrollable>
            </AsyncContent>
          </Modal>
        );
      }}
    </Form>
  );
};
