const applyUnit = (size, unit = 'px') => `${size}${unit}`;

const primaryColor = '#f37a22';
const errorColor = '#ff2a25';
const grayTextColor = '#939ea7';

const primaryBorderColor = '#d0d7dd';

const btnDangerBg = errorColor;
const btnDangerBorder = errorColor;

const inputHeightBase = applyUnit(36);

const btnHeightSm = applyUnit(36);
const btnFontSizeLg = applyUnit(14);
const btnFontWeight = 400;

const radioSize = applyUnit(18);

const headerHeight = applyUnit(60);
const poweredByHeight = applyUnit(65);

// maybe change some colors in antd and remove these later (dont forget to remove types as well)
const green = '#3AC430';
const black = '#323c47';
const black3 = '#384450';

module.exports = {
  primaryColor,
  errorColor,
  grayTextColor,

  btnDangerBg,
  btnDangerBorder,

  primaryBorderColor,

  inputHeightBase,

  btnHeightSm,
  btnFontSizeLg,
  btnFontWeight,

  radioSize,

  green,
  black,
  black3,

  headerHeight,
  poweredByHeight,

  breakpoints: {
    xs: {
      min: '0px',
      max: '575px',
    },
    sm: {
      min: '576px',
      max: '767px',
    },
    md: {
      min: '768px',
      max: '991px',
    },
    lg: {
      min: '992px',
      max: '1200px',
    },
    xl: {
      min: '1201px',
    },
  },
};
