import React, { useState } from 'react';
import { useAuth } from '8base-react-sdk';
import { Menu, Dropdown, Icon, Avatar, Skeleton } from 'antd';

import { Link } from '@/components';
import { ChevronUp, ChevronDown } from '@/static/icons';
import { Container, UserName, Inner, AvatarContainer, MenuContainer } from './styled';
import { getInitials } from '@/shared/utils';
import { useCurrentUser, usePermissions } from '@/shared/hooks';

const HeaderDropdownMenu = ({ closeDropdown }: { closeDropdown: () => void }) => {
  const auth = useAuth();

  return (
    <MenuContainer>
      <Menu>
        <Menu.Item>
          <Link to="/account">My Profile</Link>
        </Menu.Item>
        <Menu.Item
          key="2"
          onClick={async () => {
            await auth.authClient.logout();

            await auth.authClient.purgeState();

            closeDropdown();
          }}
        >
          Logout
        </Menu.Item>
      </Menu>
    </MenuContainer>
  );
};

export const UserInfo = () => {
  const [isOpen, setDropdown] = useState(false);
  const { user, loading } = useCurrentUser();

  const firstName = user?.firstName ?? '';
  const lastName = user?.lastName ?? '';
  const avatarSrc = user?.avatar?.downloadUrl ?? '';

  const initials = getInitials(firstName, lastName);
  const fullName = `${firstName}${firstName ? ` ${lastName}` : lastName}`;

  const { isRenter } = usePermissions();
  const withAvatar = !isRenter;

  return (
    <Container>
      <Dropdown
        overlay={<HeaderDropdownMenu closeDropdown={() => setDropdown(false)} />}
        trigger={['click']}
        visible={isOpen}
        onVisibleChange={() => setDropdown(!isOpen)}
      >
        <Inner data-e2e-id="user-dropdown">
          {!loading && withAvatar && (
            <AvatarContainer>
              <Avatar src={avatarSrc}>{initials}</Avatar>
            </AvatarContainer>
          )}
          <UserName>
            {loading ? (
              <Skeleton active={true} avatar={true} title={false} paragraph={{ width: 65, rows: 1 }} />
            ) : (
              fullName
            )}
            {<Icon component={isOpen ? ChevronUp : ChevronDown} style={{ marginLeft: '5px' }} />}
          </UserName>
        </Inner>
      </Dropdown>
    </Container>
  );
};
