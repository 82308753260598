import React from 'react';
import styled from '@emotion/styled';

import { OpenMenuIcon, CloseMenuIcon } from '@/static/icons';

export const Button = styled.button`
  cursor: pointer;
  font-size: 0;
  height: 100%;
  width: 40px;
  height: 40px;
  margin-left: 24px;
  border: 1px solid #cfd7de;
  border-radius: 5px;
  outline: none;
  background: transparent;
`;

export const BurgerButton = ({
  handleSidebarToggle,
  isSidebarOpen,
}: {
  handleSidebarToggle: () => void;
  isSidebarOpen: boolean;
}) => {
  return <Button onClick={handleSidebarToggle}>{isSidebarOpen ? <CloseMenuIcon /> : <OpenMenuIcon />}</Button>;
};
