import React from 'react';
import { useQuery } from 'react-apollo';
import {
  AmenityGroup,
  AmenityGroupListResponse,
  DefaultAmenity,
  DefaultAmenityListResponse,
} from '@/shared/types/graphql';
import { ColumnProps } from 'antd/lib/table';
import { Link, Table, Tag } from '@/components';
import gql from 'graphql-tag';
import { MASTER_AMENITIES_GROUP_LABELS, NEW_AMENITY_GROUPS_LABELS } from '@/shared/constants';

const AMENITY_GROUPS_QUERY = gql`
  query AmenityGroupsList($filter: AmenityGroupFilter, $defaultAmenityFirst: Int) {
    amenityGroupsList(filter: $filter) {
      items {
        id
        key
        defaultAmenity(first: $defaultAmenityFirst) {
          items {
            id
            label
          }
        }
        masterAmenity {
          id
          key
        }
      }
    }
  }
`;

export const AmenityGroupsTable = () => {
  const { data, loading } = useQuery<{ amenityGroupsList: AmenityGroupListResponse }>(AMENITY_GROUPS_QUERY, {
    variables: {
      filter: {
        masterAmenity: {
          id: {
            is_not_empty: true,
          },
        },
      },
      defaultAmenityFirst: 3,
    },
  });

  const AmenitiesTags: React.FC<{ value: DefaultAmenityListResponse }> = ({ value }) => {
    const defaultAmenities = value?.items;

    return (
      <>
        {defaultAmenities.map((amenity: DefaultAmenity, index: number) => (
          <Tag css={{ margin: 5 }} key={index}>
            {amenity?.label}
          </Tag>
        ))}
        {defaultAmenities?.length === 3 && <>...</>}
      </>
    );
  };

  const columns: ColumnProps<AmenityGroup>[] = React.useMemo(
    () => [
      {
        title: 'Category',
        dataIndex: 'key',
        render: function GetLables(value: string) {
          return <Link to={`/management/settings/amenities/${value}`}>{NEW_AMENITY_GROUPS_LABELS[value]}</Link>;
        },
      },
      {
        title: 'Master Amenity',
        dataIndex: 'masterAmenity.key',
        render: function GetLables(value: string) {
          return <>{MASTER_AMENITIES_GROUP_LABELS[value]}</>;
        },
      },
      {
        title: 'Amenities',
        dataIndex: 'defaultAmenity',
        width: '20%',
        render: function DefaultAmenitiesRender(value: DefaultAmenityListResponse) {
          return <AmenitiesTags value={value} />;
        },
      },
    ],
    [],
  );
  const dataSource = data?.amenityGroupsList?.items;
  return (
    <Table rowKey="id" pagination={{ pageSize: 15 }} dataSource={dataSource} columns={columns} loading={loading} />
  );
};
