function getStoredVisitedOffers() {
  let offers: string[] = [];
  try {
    offers = JSON.parse(window.localStorage.getItem('offers') || '[]');
  } catch {}
  return offers;
}

export function popStoredVisitedOffers() {
  const offers = getStoredVisitedOffers();
  window.localStorage.removeItem('offers');
  return offers;
}

export function storeVisitedOffer(id: string) {
  const offers = getStoredVisitedOffers();
  if (!offers.includes(id)) {
    offers.push(id);
  }
  window.localStorage.setItem('offers', JSON.stringify(offers));
}
